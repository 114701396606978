import React from 'react';
import { CrownSvgType3 } from '../svg/common/CrownSvgType3';
import { useAction } from '../../hooks/useAction.js';


export const StatisticsItem = ({ statistic, premiumAppAction}) => {
  const action=useAction()
  const handleAction = () => {
    if(!statistic?.locked){
      return
    }
    action(premiumAppAction)
  }

  return <div onClick={handleAction} className='flex justify-between w-full items-center '>
    <p className='text-gray_4 text-[12px] font-[500] font-dana'>
      {statistic?.title}
    </p>
    {
      statistic?.locked ?
        <div className='flex gap-1 items-center'>
          <CrownSvgType3/>
          <p className='text-primary text-[10px] font-[500] font-dana'>
           خرید اشتراک
          </p>
        </div>
        :
        <p className='text-white text-[14px] font-[500] font-dana'>
          {statistic?.value}
        </p>

    }

  </div>;
};

