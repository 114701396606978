import React, { useEffect, useState,useRef } from 'react';
import SwipeBottomSheet from '../bottomSheets/SwipeBottomSheet.jsx';
import BottomSheetHeader from '../bottomSheets/BottomSheetHeader.jsx';
import { CardActionArea } from '@mui/material';
import { ToggleButton } from '../common/ToggleButton.jsx';
import { HeaderThreeDot } from '../common/HeaderThreeDot.jsx';
import {
  USER_COLLECTION_ACTIONS,
  USER_COLLECTION_IN_PLAYLIST_OPTIONS, USER_COLLECTION_OPTIONS,
} from './EditUserPlaylistOptions.jsx';
import FullPageNoteLoading from '../loading/FullPageNoteLoading.jsx';
import ConfirmDialogs from '../dialogs/ConfirmDialogs.jsx';
import TrashType2Svg from '../svg/playList/TrashType2Svg.jsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteUserCollection, privatizeUserCollection, publicizeUserCollection } from '../../services/ApiClient.js';
import { deleteUserCollectionItem, setDragList } from '../../redux/globalData/globalDataSlice.js';
import { mainToast } from '../../helper/ToastsEmitter.jsx';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditUserPlaylistBottomSheet from '../playlist/EditUserPlaylistBottomSheet';
import { BooleanToggleButton } from '../common/BooleanToggleButton.jsx';
import { IconWrapper } from '../common/stories/IconWrapper.jsx';
import { setCustomDialog } from '../../redux/dialog/DialogSlice.js';
import { useShare } from '../../hooks/useShare.js';
import UserPlaylistShareLimit from '../svg/userPlaylist/UserPlaylistShareLimit.jsx';
import { shareTypes } from '../../consts/shareTypes.js';
import MusicNoteLoading from '../loading/MusicNoteLoading.jsx';
import PlaceHolderWithTitle from '../common/PlaceHolderWithTitle.jsx';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage.jsx';
import { useUserData } from '../../helper/ReduxSelectorContext.js';
import { useNativeBack } from '../../hooks/useNativeBack.js';

const errorDialogIcon = <div className='w-full flex items-center justify-center'>
  <UserPlaylistShareLimit/>
</div>

export const UserPlaylistBottomSheetEditOptions = ({ isPrivate,userCollectionData,handleUpdateCollectionData,inCollectionList=false}) => {
  const [openProfileOption, setOpenProfileOption] = useState(false);
  const [toggleIsPrivate, setToggleIsPrivate] = useState(isPrivate);
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const editPlaylistRef = useRef(null)
  const deletePlaylistRef = useRef(null)
  const privatizeUserCollectionRef = useRef(null)
  const userData = useUserData()
  useEffect(() => {
    setToggleIsPrivate(isPrivate)
  },[isPrivate]);
  const handleCloseBottomSheet = () => {
    setOpenProfileOption(false);
  };
  const {generateLinkLoading,handleGetShareLink} = useShare(userCollectionData?.id, shareTypes.USER_COLLECTION,errorDialogIcon,handleCloseBottomSheet)

  const toggleBottomSheet = newOpen => () => {
    setOpenProfileOption(newOpen);
  };
  const enableEditMode = () => {
    // Add the #edit hash to the current route
    navigate('#edit',{state:{playlistData:userCollectionData}})
  };
  useNativeBack(openProfileOption, 'edit-playlist-options', handleCloseBottomSheet);


  const handleClickOptions = item => {
    const  id  = item?.id
    switch (id) {
      case USER_COLLECTION_ACTIONS.EDIT_NAME:
        editPlaylistRef.current.openEditPlaylist()
        break;
      case USER_COLLECTION_ACTIONS.MOVE_ITEM:
        enableEditMode()
        break;
      case USER_COLLECTION_ACTIONS.CHANGE_PRIVATE:
        privatizeUserCollectionRef.current.openDialog()
        break;
      case USER_COLLECTION_ACTIONS.DELETE_ITEM:
        openDeletePlaylist()
        break;
      case USER_COLLECTION_ACTIONS.SHARE_ITEM:
        handleGetShareLink()
        break;
    }


    if (id !== USER_COLLECTION_ACTIONS.SHARE_ITEM && id!==USER_COLLECTION_ACTIONS.CHANGE_PRIVATE) {
      handleCloseBottomSheet();
    }
  };
  const deleteUserPlaylistMutation = useMutation(() => deleteUserCollection({user_collection_id: userCollectionData?.id}), {
    onSuccess: (response) => {
        console.log(response,'suceess request....');
      if(response?.data?.done){
      mainToast('با موفقیت حذف شد.')
      if(!inCollectionList){
        navigate(-1)
      }else {
        queryClient.invalidateQueries([`user-playlists`, userData?.social_token])
      }
      dispatch(deleteUserCollectionItem({id: userCollectionData?.id}))
      }
    }
  })
  const privatizeUserCollectionMutation = useMutation(() => privatizeUserCollection({user_collection_id:userCollectionData?.id}),{
    onSuccess:(response) => {
      if(response?.data?.done){
        handleUpdateCollectionData({...userCollectionData,is_private:true})
      }else {
        if(response?.data?.error?.custom_dialog){
          dispatch(
            setCustomDialog({
              custom_dialog: response?.data?.error?.custom_dialog,
            }),
          );
        }
      }

    },
    onSettled:(response) => {
      handleCloseBottomSheet();
    }
  })
  const publicizeUserCollectionMutation = useMutation(() => publicizeUserCollection({user_collection_id:userCollectionData?.id}),{
    onSuccess:(response) => {
      if(response?.data?.done){

        if(response?.data?.result?.custom_dialog){
          dispatch(
            setCustomDialog({
              custom_dialog: response?.data?.result?.custom_dialog,
            }),
          );
          return
        }
        // handleUpdateCollectionData({...userCollectionData,is_private:false})
      }else {
        if(response?.data?.error?.custom_dialog){
          dispatch(
            setCustomDialog({
              custom_dialog: response?.data?.error?.custom_dialog,
            }),
          );
        }

      }
    },
    onSettled:(response) => {
      handleCloseBottomSheet();
    }
  })
  const handleDeletePlaylist = () => {
    deleteUserPlaylistMutation.mutate()
  }
  const openDeletePlaylist = () => {
    deletePlaylistRef.current.openDialog()
  }
  const handlePrivateStateOfCollection = () => {
    if(toggleIsPrivate){
      publicizeUserCollectionMutation.mutate()
    }else {
      privatizeUserCollectionMutation.mutate()
    }

  }

  const togglePrivateDialogText = toggleIsPrivate?'آیا میخواهید پلی‌لیست خود را عمومی کنید؟':'آیا میخواهید پلی‌لیست خود را خصوصی کنید؟'
  const optionsList = inCollectionList? USER_COLLECTION_IN_PLAYLIST_OPTIONS:USER_COLLECTION_OPTIONS

  const BottomSheetTitleElement = <div className='flex gap-2 items-center'>
    <div className="w-[48px] min-w-[48px] h-[48px] min-h-[48px] rounded-[6px] overflow-hidden relative">
        <span className="relative top-0 left-0 w-full bottom-0 h-full !p-0 !m-0  flex justify-center items-center">
            <PlaceHolderWithTitle style={"!rounded-[5px]"} title={""} />
          </span>
      <CustomLazyLoadImage image={userCollectionData?.image} />
    </div>
    <div className='flex flex-col gap-2 '>
      <p className="font-dana font-[500] text-[14px] text-white">{userCollectionData?.title}</p>
      <div className='flex gap-1 items-center'>
      <p className="font-dana font-[500] text-[12px] text-gray_4">{userCollectionData?.tracks_count}</p>
      <p className="font-dana font-[500] text-[12px] text-gray_4">  آهنگ </p>

      </div>

    </div>
  </div>

  return (
    <>

      <IconWrapper buttonPadding="!p-[8px]" containerScale="w-[4px] h-[20px] min-w-[4px]"  hasRipple={false}  onClick={() => setOpenProfileOption(true)} >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="4"
          height="20"
          fill="none"
          viewBox="0 0 4 20"
        >
          <path
            fill="#fff"
            d="M0 2a2 2 0 1 0 4 0 2 2 0 0 0-4 0M0 10a2 2 0 1 0 4 0 2 2 0 0 0-4 0M0 18a2 2 0 1 0 4 0 2 2 0 0 0-4 0"
          ></path>
        </svg>
      </IconWrapper>
      {(deleteUserPlaylistMutation?.isLoading) &&
        <FullPageNoteLoading/>
      }
        <EditUserPlaylistBottomSheet collection={userCollectionData} ref={editPlaylistRef} handleUpdateCollectionData={handleUpdateCollectionData}/>

      <ConfirmDialogs
        title={toggleIsPrivate?'عمومی کردن پلی‌لیست':'خصوصی کردن‌ پلی‌لیست'}
        text={togglePrivateDialogText}
        buttonDirection={"dir-ltr"}
        confirmText={toggleIsPrivate?'عمومی کردن':'خصوصی کردن'}
        confirmTextColor={"text-primary"}
        confirmBGColor={"bg-transparent"}
        confirmBorderColor={"border-primary"}
        confirmAction={handlePrivateStateOfCollection}
        cancelText={"لغو"}
        cancelTextColor={"text-secondary"}
        cancelBGColor={"bg-primary"}
        cancelBorderColor={"transparent"}
        ref={privatizeUserCollectionRef}
      />
      <ConfirmDialogs
        dialogIcon={<TrashType2Svg style={'!w-full !h-full'} fill={'#fff'}/>}
        title={"حذف"}
        text={`پلی لیست 
                 ${userCollectionData?.title}
                 را
                 حذف میکنید؟ `}
        buttonDirection={"dir-ltr"}
        confirmText={"حذف"}
        confirmTextColor={"text-primary"}
        confirmBGColor={"bg-transparent"}
        confirmBorderColor={"border-primary"}
        confirmAction={handleDeletePlaylist}
        cancelText={"لغو"}
        cancelTextColor={"text-secondary"}
        cancelBGColor={"bg-primary"}
        cancelBorderColor={"transparent"}
        ref={deletePlaylistRef}
      />
      <SwipeBottomSheet
        keepMount={false}
        open={openProfileOption}
        toggleDrawer={toggleBottomSheet}
      >
        <BottomSheetHeader
          dividerLine={true}
          closeBottomSheet={handleCloseBottomSheet}
          title="گزینه ها"
          titleElement={BottomSheetTitleElement}
        />
        <div dir="rtl" className=" w-full h-full flex flex-col relative">
          {(generateLinkLoading ||  privatizeUserCollectionMutation?.isLoading || publicizeUserCollectionMutation?.isLoading) && (
            <div className="absolute bg-secondary opacity-80 z-[10] top-0 left-0 w-full h-full flex justify-center items-center">
              <div className="w-[120px] h-[120px]">
                <MusicNoteLoading speed={2} />
              </div>
            </div>
          )}
          {optionsList?.map(item => (
            <CardActionArea
              onClick={() => handleClickOptions(item)}
              key={item?.id}
              className="!p-4"
            >
              <div className=" flex flex-row justify-between items-center gap-2">
                <div className=" flex flex-row justify-between items-center gap-2">
                  {item?.icon}
                  <span className="font-dana font-medium text-sm text-white">
                    {item?.title}
                  </span>
                </div>
                {item?.id == USER_COLLECTION_ACTIONS.CHANGE_PRIVATE ? (
                  <div className=" relative w-[108px] h-[24px]">
                    <BooleanToggleButton
                      // setStatus={setToggleIsPrivate}
                      status={toggleIsPrivate}
                      enableText='خصوصی'
                      disableText='عمومی'
                    />
                  </div>
                ) : null}
              </div>
            </CardActionArea>
          ))}
        </div>
      </SwipeBottomSheet>
    </>
  );
};


