import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import DialogHeader from './DialogHeader';
import { CardActionArea } from '@mui/material';
import { FireBaseConf } from '../../helper/FireBaseConf';
import notifAllowImg from '../../assets/intro/notif-permision-intro.png';
import notifImg from '../../assets/intro/notify-img.png';
import CustomImage from '../common/CustomImage';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';
import ChevronLeft from '../svg/common/ChevronLeft';
import ChevronNavigateBack from '../common/ChevronNavigateBack';
import { getNotifyPermisionDialogStatus } from '../../helper/ReduxSelectorContext';
import { useDispatch } from 'react-redux';
import { setNotifyPerimisionDialog } from '../../redux/dialog/DialogSlice';
import {
  setIsPlaying,
  setSwipePlayerStatus,
} from '../../redux/player/PlayMusicSlice';
import { useNativeBack } from '../../hooks/useNativeBack';

function NotifyPermision() {
  const notifyDialogStatus = getNotifyPermisionDialogStatus();
  const dispatch = useDispatch();
  const { getFcmToken } = FireBaseConf();

  const setDialogStatus = status => {
    dispatch(setNotifyPerimisionDialog({ permisionStatus: status }));
  };

  const CloseCustomDialog = () => {
    setDialogStatus(false);
    keepPlayingMusic();
  };
  const keepPlayingMusic = () => {
    const mainPlayer = document.querySelector('#playerDDD audio');
    dispatch(setSwipePlayerStatus({ status: true }));
    mainPlayer.play();
    dispatch(setIsPlaying({ isPlay: true }));
  };

  const handleButtonAction = () => {
    requestNotificationPermission();
  };

  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      getFcmToken();
      CloseCustomDialog();
    } catch (error) {
      CloseCustomDialog();
    }
  };
  useEffect(() => {
    return () => setDialogStatus(false);
  }, []);

   useNativeBack(notifyDialogStatus, 'permision-dialog', CloseCustomDialog);

  const dialogData = [
    {
      number: '1-',
      title: (
        <span className="text-white font-dana text-sm font-normal leading-6 text-right">
          برای اینکه پخش موزیک به خوبی انجام شود و شما به شکل زیر کنترل کامل روی
          پخش موزیک داشته باشید:
        </span>
      ),
      image: notifImg,
    },
    {
      number: '2-',
      title: (
        <span className="text-white font-dana text-sm font-normal leading-6 text-right">
          با زدن دکمه مجوز دسترسی صفحه زیر برای شما باز می‌‌شود که باید روی
          <mark className="bg-transparent text-primary ">
            {' '}
            اجازه دادن (Allow){' '}
          </mark>
          کلیک کنید.
        </span>
      ),
      image: notifAllowImg,
    },
  ];
  return (
    <Transition appear show={notifyDialogStatus} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={CloseCustomDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto  font-dana ">
          <div className="flex w-full h-full items-center justify-center  text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className="w-full flex flex-col justify-between h-full max-w-[520px] shadow-dialog-shadow  transform overflow-hidden  bg-primary_dark   text-left align-middle  transition-all"
              >
                <div className=" w-full h-fit p-4 bg-primary_dark top-0  flex flex-row justify-between items-center shadow-navShadow">
                  <div></div>
                  <div className="text-white font-dana font-normal">
                    نیاز به دسترسی اعلان ها
                  </div>
                  <div onClick={CloseCustomDialog}>
                    <ChevronLeft />
                  </div>
                </div>
                <div className="w-full h-full relative overflow-scroll">
                  <div className="w-full h-fit ">
                    <div className="bg-gray_1 rounded-[10px] m-4 p-4 flex flex-col gap-8 overflow-scroll h-4/5">
                      {dialogData?.map((item, index) => (
                        <div className="flex flex-col w-full gap-3">
                          <div className="flex flex-row gap-2">
                            <span className="text-[28px] font-dana text-primary font-[600]">
                              {item?.number}
                            </span>
                            <>{item?.title}</>
                          </div>
                          <CustomLazyLoadImage
                            image={item?.image}
                            appendBaseUrl={false}
                            imageClass="!relative rounded-lg"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-center mt-6  !h-14 !px-4 w-full  permision-dialog-button">
                  <CardActionArea className=" !rounded-full  ">
                    <div className="w-full   mx-auto flex items-center justify-center">
                      <button
                        onClick={handleButtonAction}
                        type="button"
                        className="flex flex-row  items-center gap-2 !h-12 text-black text-base bg-primary w-full justify-center rounded-full border border-transparent bg-blue-100 px-4 focus:outline-none"
                      >
                        <p className="font-semibold">مجوز دسترسی و ادامه</p>
                        <span className="button-anim">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M19 12H5"
                              stroke="#404040"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 19L5 12L12 5"
                              stroke="#404040"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </CardActionArea>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default React.memo(NotifyPermision);
