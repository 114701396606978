import React from 'react';

function PlusIcon({ fill = '#fff', width = '20', height = '20' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <g fill={fill} clipPath="url(#clip0_1_4514)">
        <path d="M10 20a.977.977 0 0 1-.977-.977V.977a.977.977 0 1 1 1.954 0v18.046A.977.977 0 0 1 10 20"></path>
        <path d="M19.023 10.977H.977a.976.976 0 1 1 0-1.954h18.046a.977.977 0 0 1 0 1.954"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_4514">
          <path fill="#fff" d="M0 0h20v20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default PlusIcon;
