import { Tab } from '@headlessui/react';
import { useSwiper } from 'swiper/react';

function SwiperTabs({
  slideIndex,
  setSlideIndex,
  tabContents = { first: null, second: null },
  className,
  hasTabLine=false,
  handleChangeSlide
}) {
  const swiper = useSwiper();
  console.log('main slide', slideIndex);
  function handleChange(value) {
    console.log('inner slide', slideIndex);
    console.log(value);
    if (swiper) swiper.slideTo(value);
    setSlideIndex(value);
    if (handleChangeSlide) handleChangeSlide(value);
  }
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <div className={'w-[calc(100%_-_32px)] mx-auto'}>
      <Tab.Group selectedIndex={slideIndex} onChange={handleChange} dir="ltr">
        <Tab.List
          className={`flex justify-around items-center min-h-[42px]  text-white w-full mx-auto  mt-6 z-[20] bg-[#1d1d1d] rounded-[5px] ${className}`}
        >
          <Tab
            key={'first'}
            className={({}) =>
              classNames(
                'flex flex-col justify-center items-center w-1/2 font-dana  focus-visible:!outline-none focus-visible:!ring-none py-[10px]',
                slideIndex === 0
                  ? 'text-primary after:flex after:h-[2px] after:w-[calc(50%_-_16px)] after:absolute after:mt-[39.5px] after:left-[17px] after:bg-primary after:rounded-full'
                  : '',
                hasTabLine ? 'border-b border-gray_1' : '',
              )
            }
          >
            {tabContents.first}
          </Tab>
          <Tab
            key={'second'}
            className={({}) =>
              classNames(
                'flex flex-col justify-center items-center w-1/2 font-dana border-b border-gray_1 focus-visible:!outline-none focus-visible:!ring-none py-[10px]',
                slideIndex === 1
                  ? 'text-primary after:flex after:h-[2px] after:w-[calc(50%_-_16px)] after:absolute after:mt-[39.5px] after:right-[17px] after:bg-primary after:rounded-full'
                  : '',
                hasTabLine ? 'border-b border-gray_1' : '',
              )
            }
          >
            {tabContents.second}
          </Tab>
        </Tab.List>
      </Tab.Group>
    </div>
  );
}

export default SwiperTabs;
