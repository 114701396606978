import React from "react";
import { useEffect, Fragment } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectBannedDialog,
} from "../../redux/dialog/DialogSlice";
import { useAction } from "../../hooks/useAction";
import { Dialog, Transition } from "@headlessui/react";
// import DangerSignSvg from "../svg/common/DangerSignSvg";
import DialogHeader from "./DialogHeader.jsx";
import {CardActionArea} from "@mui/material";
function BannedDialog() {
  const [open, setOpen] = useState(false);
  const dialogData = useSelector(selectBannedDialog);
  const ButtonAction1 = useAction();
  const ButtonAction2 = useAction();
  useEffect(() => {
    if (dialogData) setOpen(true);
  }, [dialogData]);

  const handleButtonOneAction = () => {
    ButtonAction1(dialogData?.btn_1_action);
    setOpen(false);
  };
  const handleButtonTwoAction = () => {
    ButtonAction2(dialogData?.btn_2_action);
    setOpen(false);
  };
  const handleCloseBannedDialog = () => {
    setOpen(false);
  };
  return (
    <Transition
      appear
      show={open}
      as={Fragment}
      onClose={handleCloseBannedDialog}
    >
      <Dialog as="div" className="relative z-40">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 " />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center font-dana">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className=" w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-primary_dark  text-left align-middle shadow-xl transition-all"
              >
                <DialogHeader onCloseDialog={handleCloseBannedDialog} />
                {/*<DialogHeader header_text={'ارسال گزارش خطا'} header_icon={<DangerSignSvg />} />*/}
                <div className="px-6">
                  <p
                    className="text-white text-base text-center mb-4 mt-8"
                    dangerouslySetInnerHTML={{ __html: dialogData?.text }}
                  ></p>
                </div>

                <div className="mt-8 flex items-center justify-center gap-3">
                  <CardActionArea className="!rounded-[100px] !w-[40%]">
                    <button
                      onClick={handleButtonOneAction}
                      type="button"
                      className="inline-flex text-gray_1 text-base bg-primary !w-full justify-center rounded-[100px] border border-transparent px-4 py-3 focus:outline-none"
                    >
                      {dialogData?.btn_1_text}
                    </button>
                  </CardActionArea>
                  <CardActionArea className="!rounded-[100px] !w-[60%]">
                    <button
                      onClick={handleButtonTwoAction}
                      type="button"
                      className="inline-flex text-gray_3 text-base bg-transparent text-primary !w-full border-[1px] border-primary justify-center rounded-[100px] px-4 py-3 focus:outline-none"
                    >
                      {dialogData?.btn_2_text}
                    </button>
                  </CardActionArea>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default BannedDialog;
