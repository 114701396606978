import React from 'react';
import CommonHeader from '../../../components/common/CommonHeader.jsx';
import Container from '../../../components/PageParentContainer/Container.jsx';
import { ListTabs } from '../../../components/meloPost/list/ListTabs';
import { useMeloposts } from '../../../hooks/useMeloposts.js';
import { ListHashtags } from '../../../components/meloPost/list/ListHashtags.jsx';
import { RenderMelopostList } from '../../../components/meloPost/list/RenderMelopostList';
import { NoteMusicLoadingContainer } from '../../../components/loading/NoteMusicLoadingContainer.jsx';
import { FloatCreateMelopostButton } from '../../../components/meloPost/FloatCreateMelopostButton.jsx';

export const MelopostList = props => {
  const {
    setSlideIndex,
    slideIndex,
    handleSwiperChange,
    handleTabChange,
    clearSelectedHashtags,
    handleSelectHashtags,
    hashtags,
    checkIsHashtagSelected,
    activePlayerIndex,
    handlePlay,
    getMeloPostgHasNextPage,
    getMeloPostgIsLoading,
    getMeloPostIsFetchingNextPage,
    getMeloPostIsFetchNextPage,
    getMeloPostNextPage,
    getMelopostsFlatMap,
    isLoadingHashtags,
    refetch,
  } = useMeloposts();
  return (
    <Container
      style="bg-black"
      shadow={false}
      header={
        <CommonHeader title="ملودیفای" startAdornmentClassName="hidden" />
      }
    >
      <FloatCreateMelopostButton />
      <div className="flex flex-col  ">
        <ListTabs handleTabChange={handleTabChange} slideIndex={slideIndex} />
        <div className="flex flex-col gap-5">
          <ListHashtags
            isLoadingHashtags={isLoadingHashtags}
            hashtags={hashtags}
            handleSelectHashtags={handleSelectHashtags}
            handleClearHashtags={clearSelectedHashtags}
            checkIsHashtagSelected={checkIsHashtagSelected}
          />
          <RenderMelopostList
            refetch={refetch}
            handlePlay={handlePlay}
            getMeloPostgIsLoading={getMeloPostgIsLoading}
            activePlayerIndex={activePlayerIndex}
            getMeloPostgHasNextPage={getMeloPostgHasNextPage}
            getMeloPostIsFetchingNextPage={getMeloPostIsFetchingNextPage}
            getMeloPostNextPage={getMeloPostNextPage}
            getMelopostsFlatMap={getMelopostsFlatMap}
          />
        </div>
      </div>
    </Container>
  );
};
