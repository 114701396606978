import React from "react";
import HomeMetaDataWithTitle from "../common/HomeMetaDataWithTitle";
import HomeExclusiveTracksSlider from "./HomeExclusiveTracksSlider";
import HomeExclusiveTracksBanner from "./HomeExclusiveTracksBanner";

function HomeExclusiveTracksSection({ item }) {
  console.log('asfasasdffasf', item?.element_data);
  
  return (
    <div className=" z-20">
      <div dir="rtl" className="flex flex-col gap-1 bg-background ">
        {/* <div className="w-full  pr-3">
          <HomeMetaDataWithTitle listData={item?.exclusive_tracks_items} metaData={item?.exclusive_tracks_meta_data} />
        </div> */}
        <div className="flex flex-col gap-3  ">
          <HomeExclusiveTracksSlider item={item?.element_data} />
          {/* {item?.exclusive_tracks_items[1]?.banner && (
            <div className="pr-4 pl-4">
              <HomeExclusiveTracksBanner
                banner={item?.exclusive_tracks_items[1]?.banner}
              />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default React.memo(HomeExclusiveTracksSection);
