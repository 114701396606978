import { useInfiniteQuery } from '@tanstack/react-query';
import CategoryCard from '../../components/playlist/CategoryCard';
import { getFollowedCollections } from '../../services/ApiClient';
import { useNavigate } from 'react-router';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../components/loading/Loading';
import NoneFollowedPlayList from '../../assets/myMelodify/NoneFollowedPlayList.webp';
import Container from '../../components/PageParentContainer/Container';
import CommonHeader from '../../components/common/CommonHeader';
import AarrowLeft from '../../components/svg/common/AarrowLeft';
import { VirtuosoGrid } from 'react-virtuoso';
import { useEffect, useMemo, useState } from 'react';
import ScrollTopButton from '../../components/common/ScrollTopButton';
import SongListLoading from '../../components/loading/SongListLoading';
import CollectionGridShimmer from '../../components/Shimmers/CollegtionGridShimmer.jsx';
import CustomImage from '../../components/common/CustomImage';

function FollowedPlayList() {
  const navigate = useNavigate();
  const [HasLoaded, setHasLoaded] = useState(false);
  const getFollowedPlayLists = useInfiniteQuery(
    ['getFollowedPlayLists'],
    ({ pageParam = 0 }) => getFollowedCollections({ offset: pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.result?.end) return null;
        const allData = allPages?.flatMap(
          page => page?.data?.result?.collections,
        );
        return allData?.length;
      },
    },
  );
  useEffect(() => {
    setHasLoaded(true);
  }, []);

  const collections = useMemo(
    () =>
      getFollowedPlayLists?.data?.pages?.flatMap(
        page => page.data.result.collections,
      ),
    [getFollowedPlayLists],
  );

  const handleLoadMore = () => {
    getFollowedPlayLists.fetchNextPage();
  };

  const openCollection = item => {
    navigate(`/playlist-songs/${item?.id}`, {
      state: { collectionObject: item },
    });
  };
  const handleClickPlayList = () => {
    navigate('/playlist');
  };
  return (
    <Container
      header={<CommonHeader title="پلی‌لیست های دنبال شده" />}
      style=" !overflow-hidden"
    >
      <ScrollTopButton id={'category-page'} />
      <div
        id="category-page"
        className=" relative overflow-scroll h-full hide-scroll-bar z-10  "
        dir="rtl"
      >
        <div className="h-full  w-full mb-10 ">
          <div className="flex gap-3  flex-col mt-4 ">
            {getFollowedPlayLists.isLoading ? (
              <CollectionGridShimmer count={20} />
            ) : collections.length ? (
              <InfiniteScroll
                key={collections.length}
                className="!overflow-hidden mb-16"
                scrollableTarget="category-page"
                dataLength={getFollowedPlayLists?.data?.pages?.length}
                next={handleLoadMore}
                hasMore={getFollowedPlayLists?.hasNextPage}
                loader={
                  <div className="w-full grid justify-center items-center mt-4">
                    <SongListLoading />
                  </div>
                }
              >
                <VirtuosoGrid
                  data={collections}
                  customScrollParent={document.getElementById('category-page')}
                  listClassName="!grid !grid-cols-2 !gap-4 !gap-y-[min(10vw,52px)] !px-4 !pt-[min(4.2vw,22px)]"
                  itemContent={(index, collection) => (
                    <div
                      key={'collection' + index + collection.id}
                      onClick={() => openCollection(collection)}
                      className="flex justify-center items-center w-full h-full mx-auto z-50"
                    >
                      <CategoryCard height={true} scale={true} item={collection} />
                    </div>
                  )}
                />
              </InfiniteScroll>
            ) : (
              <div className="flex !flex-col !items-center !justify-center relative top-[25vh]  ">
                <div>
                  <CustomImage
                    appendBaseUrl={false}
                    src={NoneFollowedPlayList}
                    className="w-[100px] h-[100px] fill-gray_2"
                  />
                </div>
                <p className="text-gray_3 bg-transparent text-center mt-6 font-dana font-medium text-lg">
                  هنوز پلی‌لیستی را دنبال نکرده‌اید
                </p>
                <div
                  onClick={handleClickPlayList}
                  className="flex mt-3 justify-around items-center  w-[130px] mx-auto"
                >
                  <span className="text-primary font-medium font-dana text-lg leading-6">
                    پلی‌لیست‌ها
                  </span>
                  <div className="w-fit">
                    <AarrowLeft className="w-[24px] h-[24px] stroke-primary" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default FollowedPlayList;
