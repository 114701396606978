import { useState, useCallback } from 'react';
import axios from 'axios';
import FileSaver from 'file-saver';
import * as Sentry from '@sentry/react';

const useSingleFileDownload = () => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const startDownload = useCallback(async (url,fileName) => {
    if (!url) return;

    let controller = new AbortController();
    let signal = controller.signal;

    try {
      setLoading(true);
      setProgress(0);
      setError(null);

      const response = await axios.get(url, {
        responseType: 'blob',
        signal,
        onDownloadProgress: (event) => {
          if (event.total) {
            setProgress(Math.round((event.loaded / event.total) * 100));
          }
        },
      });

      saveDownloadedFile(fileName, response.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
      setError(err.message);
      Sentry.captureMessage(err, { level: 'error' });
    } finally {
      setLoading(false);
    }
  }, []);

  return { progress, loading, error, startDownload };
};

const saveDownloadedFile = (name, blob) => {
  try {
    FileSaver.saveAs(blob, name);
  } catch (error) {
    Sentry.captureMessage(error, { level: 'log' });
  }
};

export default useSingleFileDownload;
