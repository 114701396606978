import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import { CardActionArea } from '@mui/material';
import HashtagSvg from '../../svg/common/HashtagSvg.jsx';

import CloseDialogButton from '../../svg/common/CloseDialogButton.jsx';

function CreateHashtagLimitationDialog({open,setOpen}) {



  const closeCustomDialog = () => {
    setOpen(false);
  };
  return (
    <Transition  appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[1300]" onClose={() => {setOpen(false)}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 " />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto font-dana">
          <div className="flex min-h-full items-center justify-center p-4 pt-0  text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className=" w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-primary_dark p-4  text-left align-middle shadow-xl transition-all"
              >
                <div className='flex h-[43px]  w-full justify-end'>
                  <div className="cursor-pointer w-4 h-4" onClick={closeCustomDialog}>
                    <CloseDialogButton stroke='#828282' />
                  </div>
                </div>
                <div className='flex flex-col gap-6 items-center '>
                <HashtagSvg/>
                  <div>
                    <p className="text-gray_5 text-[16px] fa-format-700 text-center">
                      حداکثر 3 هشتگ میتوانید انتخاب کنید.
                    </p>
                  </div>
                  <div className={'flex items-center justify-center w-full '}>
                    <CardActionArea className=" !h-[36px] !rounded-[10px] w-full">
                      <div className="w-full flex items-center justify-center">
                        <button
                          onClick={closeCustomDialog}
                          type="button"
                          className="inline-flex justify-center items-center !h-[36px] text-gray_1 text-[16px] fa-format-500 bg-primary w-full justify-center rounded-[10px]   focus:outline-none"
                        >
                          متوجه شدم
                        </button>
                      </div>
                    </CardActionArea>
                  </div>
                </div>



              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default CreateHashtagLimitationDialog;
