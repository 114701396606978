import React from 'react';
import UserAvatar from '../../common/UserAvatar';
import { BoldText } from '../../svg/common/BoldText';
import ClickReaction from '../../../helper/ClickReaction';
import CustomImage from '../../common/CustomImage';
import PlusIcon from '../../svg/common/PlusIcon';
import { useNavigate } from 'react-router-dom';

export const MuliRequestViewType = ({ item }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/follow-requests');
  };
  return (
    <div
      dir="rtl"
      className="bg-notifiy-granient w-full h-[40px] flex flex-row  justify-between items-center "
      onClick={handleNavigate}
    >
      <div className="flex flex-row justify-start gap-1 items-center">
        <div className="w-[34px] h-[34px] border border-gray_2 rounded-full flex justify-center items-center">
          <CustomImage src={item?.image} />
        </div>
        <div className=" flex flex-row items-center gap-1 flex-wrap">
          <div className="text-gray_4 font-dana text-xs ">
            {item?.item?.bold ? (
              <BoldText keyword={item?.bold} text={item?.message} />
            ) : (
              item?.message
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-row items-center gap-2">
        {item?.social_profiles?.length > 5 ? (
          <span className="w-[7px] h-[7px]">
            <PlusIcon fill="#F2C94C" width="7" height="7" />
          </span>
        ) : null}

        <div className=" flex flex-row  h-full items-center">
          {item?.social_profiles?.slice(0, 5)?.map((profile, index) => (
            <div className="w-[32px] h-[32px] border border-gray_2 rounded-full flex justify-center items-center  mx-[-7px]">
              <UserAvatar
                avatar={profile?.avatar}
                hasAction={false}
                className={
                  '!w-full !h-full !object-cover border border-primary_dark rounded-full'
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
