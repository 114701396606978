export const BoldText = ({ text, keyword }) => {
  // Create a regex to find the keyword, ignoring case.
  // The parentheses capture the matching keyword in the result array.
  const parts = text.split(new RegExp(`(${keyword})`, 'gi'));

  return (
    <div>
      {parts.map((part, index) =>
        // Compare ignoring case.
        part.toLowerCase() === keyword.toLowerCase() ? (
          <strong key={index}>{part}</strong>
        ) : (
          part
        ),
      )}
    </div>
  );
};
