import React from 'react';

function BellIconSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#fff"
        d="m18.608 14.755-1.221-1.757a1.43 1.43 0 0 1-.258-.814V7.142a7.142 7.142 0 0 0-14.283 0v5.042c0 .29-.09.575-.257.814l-1.221 1.757a1.428 1.428 0 0 0 1.057 2.385h4.064a3.57 3.57 0 0 0 6.998 0h4.064a1.43 1.43 0 0 0 1.057-2.386m-8.62 3.813a2.14 2.14 0 0 1-2.014-1.428h4.028a2.14 2.14 0 0 1-2.014 1.428m-7.563-2.857a.6.6 0 0 0 .086-.1l1.25-1.8a2.86 2.86 0 0 0 .514-1.627V7.142a5.713 5.713 0 0 1 11.426 0v5.042c.002.582.18 1.15.514 1.628l1.25 1.8a.6.6 0 0 0 .086.1z"
      ></path>
    </svg>
  );
}

export default BellIconSvg;
