import {Dialog, Transition} from '@headlessui/react'
import {forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {selectInitialData} from "../../redux/initialize/InitializeSlice";
import DangerSignSvg from "../svg/common/DangerSignSvg.jsx";
import {selectUserData} from "../../redux/login/LoginSlice.js";
import SwipeBottomSheet from "../bottomSheets/SwipeBottomSheet.jsx";
import BottomSheetHeader from "../bottomSheets/BottomSheetHeader.jsx";
import ErrorReportFlag from "../svg/artists/ErrorReportFlag.jsx";
import ConfirmDialogs from "../dialogs/ConfirmDialogs.jsx";
import {mainToast, toastWithSvg} from "../../helper/ToastsEmitter.jsx";
import RedHeartSvg from "../svg/toast/RedHeartSvg.jsx";
import {useMutation} from "@tanstack/react-query";
import {lyricReport} from "../../services/ApiClient.js";
import {CardActionArea} from "@mui/material";
import {useNativeBack} from "../../hooks/useNativeBack.js";
import LoadingOnSheet from "../common/loadingOnSheet.jsx";



function LyricReportBottomSheet({trackData}, ref) {
    const [isOpen, setIsOpen] = useState(false);
    const [reportItem, setReportItem] = useState(null)
    const [selectedError, setSelectedError] = useState(null)
    const dialogRef = useRef(null)
    const closeModal = () => setIsOpen(false);
    const dispatch = useDispatch()
    const user = useSelector(selectUserData);
    const initialData = useSelector(selectInitialData)
    const lyric_report_types=initialData?.lyric_report_types

    const filterLyricReport = () => {
        const isOnlyFroSync = trackData?.lyricData?.is_sync_lyric

        const newItems = lyric_report_types?.filter(item => item?.only_for_sync === isOnlyFroSync);
        setReportItem(newItems)
    }
    const sendLyricReportMutation = useMutation((data) => lyricReport(data),{
        onSuccess:(res) => {
            mainToast(<div dir='rtl' className='text-center flex'>
                    <span>گزارش ارسال شد.ممنونیم.</span>
                    <RedHeartSvg/>
                </div>
            )
        }
    });
    const handelSendReport = () => {

        const requestData = {
            lyric_id: trackData?.lyricData?.id,
            type_id: selectedError?.id,
        };
        sendLyricReportMutation.mutateAsync(requestData).then(()=>{
        handelModalClose()

        })

    }

    const handelModalClose = (e) => {
        closeModal()

    };
    const toggleBugReportBottomSheet = (newOpen) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(newOpen)
    };

    const handelSelectItem = (event, data) => {
        event.stopPropagation()
        event.preventDefault()
        setSelectedError(data)
        dialogRef?.current?.openDialog()


    }
    useImperativeHandle(ref, () => ({
        openModal() {
            if (trackData && lyric_report_types) {
                setIsOpen(true)
            }

        },

    }));
    useEffect(() => {
        filterLyricReport()
    }, [trackData])
    const   closeSheet = () => {
        setIsOpen(false)
    }
    useNativeBack(isOpen,'lyric-report-sheet',closeSheet)
    return (
        <>
            <ConfirmDialogs
                title={"گزارش خطای متن"}
                text={selectedError?.title}
                buttonDirection={"dir-rtl"}
                confirmText={"ارسال گزارش"}
                confirmTextColor={"text-secondary"}
                confirmBGColor={"bg-primary"}
                confirmBorderColor={"transparent"}
                confirmAction={handelSendReport}
                cancelText={"لغو"}
                cancelTextColor={"text-primary"}
                cancelBGColor={"bg-transparent"}
                cancelBorderColor={"border-primary"}
                ref={dialogRef}
                confirmButtonWidth={'!w-[300px]'}

            />
            <SwipeBottomSheet
                open={isOpen}
                toggleDrawer={toggleBugReportBottomSheet}
            >
                <BottomSheetHeader HeaderIcon={<ErrorReportFlag/>} dividerLine={true}
                                   closeBottomSheet={handelModalClose} title="گزارش خطا متن آهنگ"/>
                <div onClick={(e) => e.stopPropagation()} dir='rtl'
                     className='flex flex-col gap-4 w-full  pb-4 pt-4 '>
                    {sendLyricReportMutation?.isLoading &&
                    <LoadingOnSheet/>
                    }
                    <div className=" flex flex-col gap-6">
                        <p className="text-white font-dana font-medium text-[14px] lg-1440:text-f14 flex text-start px-4">
                            کدام خطا را در متن این آهنگ مشاهده میکنید؟
                        </p>
                        <div className="flex flex-col  ">
                            {reportItem?.map((item) => (
                             <CardActionArea>
                                 <p
                                     onClick={(event) => {
                                         handelSelectItem(event, item);
                                     }}
                                     className=" font-dana font-normal text-[14px] lg-1440:text-f16 flex  text-primary cursor-pointer py-4 px-4">
                                     {item?.title}
                                 </p>
                             </CardActionArea>

                            ))}
                        </div>
                    </div>
                </div>

            </SwipeBottomSheet>

        </>
    );

}

export default forwardRef(LyricReportBottomSheet)
