import React from 'react';

function RemovePlaylistIteIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g fill="#BDBDBD" clipPath="url(#clip0_1_79785)">
        <rect width="18" height="1.518" y="2" rx="0.759"></rect>
        <rect width="12" height="1.518" y="7.059" rx="0.759"></rect>
        <rect width="8" height="1.518" y="12.117" rx="0.759"></rect>
        <rect width="6.07" height="1.518" y="17.176" rx="0.759"></rect>
        <rect width="8" height="1.5" x="11" y="15" rx="0.75"></rect>
      </g>
      <defs>
        <clipPath id="clip0_1_79785">
          <path fill="#fff" d="M0 0h20v20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default RemovePlaylistIteIcon;
