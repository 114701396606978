import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import DialogHeader from '../dialogs/DialogHeader.jsx';
import { CardActionArea } from '@mui/material';
import UploadErrorDialogIcon from '../svg/meloPost/UploadErrorDialogIcon.jsx';

function SelectFileErrorDialog({ handleCloseDialog,handleConfirmDialog,openState,error }) {
  return (
    <Transition appear show={openState} as={Fragment}>
      <Dialog
        as="div"
        onClose={handleCloseDialog}
        className="relative z-[1400]"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 " />
        </Transition.Child>

        <div
          onClick={e => e.stopPropagation()}
          className="fixed inset-0 overflow-y-auto font-dana"
        >
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className=" w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-primary_dark text-left align-middle shadow-xl transition-all"
              >
                <DialogHeader onCloseDialog={handleCloseDialog} />
                <div className="flex justify-center w-full    ">
                  <UploadErrorDialogIcon />
                </div>

                <div className="px-2 flex flex-col gap-3 pt-4">
                  <p className="text-gray_5 font-[700] text-[16px] text-center  ">
                    {error?.title}
                  </p>
                  <p className="text-gray_4 font-[400] text-[14px] text-center  ">
                    {error?.subtitle}
                  </p>
                </div>
                <div
                  className={`rtl mt-4 flex items-center justify-center gap-3`}
                >
                  <CardActionArea className={`!rounded-[100px] !w-[50%]  `}>
                    <button
                      onClick={() => handleConfirmDialog()}
                      type="button"
                      className={`bg-primary  text-center inline-flex text-[16px] font-semibold w-full justify-center rounded-[10px]  px-4 py-3 focus:outline-none`}
                    >
                      انتخاب مجدد
                    </button>
                  </CardActionArea>
                  <CardActionArea className="!rounded-[100px] !w-[50%]">
                    <button
                      onClick={handleCloseDialog}
                      type="button"
                      className={` text-center inline-flex text-[16px] text-primary font-semibold w-full border border-primary justify-center rounded-[10px] px-4 py-3 focus:outline-none`}
                    >
                      لغو
                    </button>
                  </CardActionArea>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default SelectFileErrorDialog;
