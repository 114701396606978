import React, { useState } from 'react';
import { VoiceVisualizer } from 'react-voice-visualizer';
import { convertTime } from '../../helper/ConvertPlayerTIme.js';
import { TogglePlayGrayButton } from '../common/TogglePlayGrayButton.jsx';
import { MeloPostPlayer } from './MeloPostPlayer.jsx';


export const  MelopostFilePlaySection =({ isEdit,melopostItem,isPausedRecordedAudio,togglePauseResume,isProcessingRecordedAudio,recorderControls,duration,currentAudioTime }) => {

  const [activePlayerIndex, setActivePlayerIndex] = useState(null);
  const handlePlay = index => {
    if (activePlayerIndex === index) {
      setActivePlayerIndex(null); // If the same item is clicked again, stop it
    } else {
      setActivePlayerIndex(index); // Set active player to the clicked item
    }
  };

  return (
    <div className='flex bg-primary_dark rounded-[8px] overflow-hidden py-2 px-4 gap-3 items-center  '>
      {
        isEdit?
          <div className='w-full' dir="ltr">
            <MeloPostPlayer
              item={melopostItem}
              index={0}
              onPlay={handlePlay}
              activePlayerIndex={activePlayerIndex}
              hasDuration
              playerButtonClassName='h-[32px] w-[32px] min-w-[32px]'
            />
          </div>

          :
          <>
            <div className=' w-full h-[56px] flex flex-col gap-1 relative'>
              { isProcessingRecordedAudio ?   <div className='flex absolute h-[40px] w-full top-0 justify-center items-center bg-primary_dark  z-[10]'>
                <p className="text-primary text-[16px] fa-format-500 ">در حال بارگذاری فایل صوتی...</p>
              </div> :null}
              <VoiceVisualizer mainBarColor='#F2C94C' height={40}  controls={recorderControls} progressIndicatorTimeClassName='!bg-red' isControlPanelShown={true} />
              <div className='flex justify-between w-full'>
                <p className='flex fa-format-500 text-[10px] text-gray_3'>{convertTime(duration)}</p>
                <p className='flex fa-format-500 text-[10px] text-gray_3'>{convertTime(currentAudioTime??0)}</p>
              </div>
            </div>
            <TogglePlayGrayButton isPlaying={!isPausedRecordedAudio} startPlay={togglePauseResume} pausePlay={togglePauseResume}/>
          </>

      }

    </div>
  );
}

