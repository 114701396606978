import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectInactiveSession } from "../redux/autherror/AuthErrorSlice";
import { selectUserData, selectUserToken } from "../redux/login/LoginSlice";
import { selectDropDown } from "../redux/player/DropDownSlice.js";
import { selectGlobalData } from "../redux/globalData/globalDataSlice.js";
import { selectNavigation } from "../redux/navigation/NavigationSlice.js";
import {
  selectEmitTrackEnd,
  selectHeaderPlayData,
  selectLyricFullScreenStatus, selectMute,
  selectPlay,
  selectPlayList,
  selectPlayListClone,
  selectPlayListStatic,
  selectPlayMusic,
  selectPlayedFrom,
  selectPlayedPlaylist,
  selectPlayerLoop,
  selectPlayerShuffle,
  selectPlayerStatus, selectQueueList,
  selectRecentlyPlays,
  selectSeekTime,
  selectTrackDuration, selectVolume,
  selectWeightCount,
  setMusic, selectPlayerPaginationData, selectPlayerPaginationLoader,
  selectQueueStatus,
} from "../redux/player/PlayMusicSlice";
import { selectDownloadData } from "../redux/download/downloadSlice.js";
import { selectLimitation } from "../redux/limitation/LimitationSlice";
import {
  selectAdvertiseData,
  selectAdvertiseStatusCode,
  selectEmitAdvertise,
  selectHaveAdvertise,
  selectSingleAdveItem,
  selectWaitForAdv,
} from "../redux/player/AdvertiseSlice";
import {
  selectAdvLimitationData,
  selectInitialData,
  selectReelLimitationData,
} from "../redux/initialize/InitializeSlice";
import {
  getSelectedTab, selectAllSearchResult, selectCommitSearch,
  selectCommitedSearchQuery,
  selectSearchAlbumResult,
  selectSearchArtistResult,
  selectSearchCacheResult,
  selectSearchHistory,
  selectSearchLoading,
  selectSearchPlaylistResult,
  selectSearchQuery,
  selectSearchQueryForShowMore,
  selectSearchResult,
} from "../redux/search/SearchSlice";
import { selectNewTrackDialog, selectNotifyPermisionDialogStatus, selectNotifySettingPermisionIntroDialogStatus, selectUpdateDialog } from "../redux/dialog/DialogSlice";
import { selectUpkeep } from "../redux/upKeepSlice.js";
import { selectEmtyAudioStatus, selectPlayPushActionData, selectPlayerTimer } from "../redux/player/PlayerSlice.js";
import { selectSelectedMode } from "../redux/adToPlaylist/AddToPlaylistSlice.js";
import { useLocation } from 'react-router-dom';
import { ScrollPositionsOffset } from '../consts/ScrollPositionsOffset.js';
import { selectScrollPosition } from '../redux/scrollManageStore/scrollManageSlice.js';
import { useHashedPath } from '../hooks/useHashedPath.js';
import { selectCurrentReelIndex, selectReelPlayerStatus, selectReelsList, selectReelWeightCount } from "../redux/reelPlayer/ReelsPlayerSlice.js";
export function useUserData() {
  const userData = useSelector(selectUserData);

  return userData;
}
export function countDownObjectData() {
  const { countDownObject: countDownData, activeCountDown } =
    useSelector(selectDropDown);
  return [countDownData, activeCountDown];
}
export function followedArtistsData() {
  const { followedArtists } = useSelector(selectGlobalData);
  return followedArtists;
}
export function followingCollectionsData() {
  const { followingCollections } = useSelector(selectGlobalData);
  return followingCollections;
}
export function followedUserCollections() {
  const { followedUserCollections } = useSelector(selectGlobalData);
  return followedUserCollections;
}
export const navigationData = () => useSelector(selectNavigation);

export const getInactiveSessionData = () => useSelector(selectInactiveSession);
export const getUserToken = () => useSelector(selectUserToken);

export const getPlayerMusic = () => useSelector(selectPlayMusic);
export const getIsPlaying = () => useSelector(selectPlay);
export const getQueueList = () => useSelector(selectQueueList);
export const getPlayList = () => useSelector(selectPlayList);
export const getPlayerPaginationData = () => useSelector(selectPlayerPaginationData);
export const getSelectPlayerPaginationLoader = () => useSelector(selectPlayerPaginationLoader);
export const getVolume = () => useSelector(selectVolume);
export const getMute = () => useSelector(selectMute);
export const getPlayedFrom = () => useSelector(selectPlayedFrom);
export const getPlayerSeekTime = () => useSelector(selectSeekTime);

export const globalData = () => useSelector(selectGlobalData);
export const contextMenuData = () => useSelector(selectDropDown);
export const getPlayListClone = () => useSelector(selectPlayListClone);
export const getPlayerLoop = () => useSelector(selectPlayerLoop);
export const getPlayerShuffle = () => useSelector(selectPlayerShuffle);
export const getEmitTrackEnd = () => useSelector(selectEmitTrackEnd);
export const getPlayedPlaylist = () => useSelector(selectPlayedPlaylist);
export const downloadData = () => useSelector(selectDownloadData);
export const getLimitations = () => useSelector(selectLimitation);
export const getRecentlyPlays = () => useSelector(selectRecentlyPlays);
export const getInitialData = () => useSelector(selectInitialData);
export const getCommitSearch = () => useSelector(selectCommitSearch);
export const getSearchQuery = () => useSelector(selectSearchQuery);
export const getSearchLoading = () => useSelector(selectSearchLoading);
export const getSearchCashResult = () => useSelector(selectSearchCacheResult);
export const getSearchHistory = () => useSelector(selectSearchHistory);

export const getTrackResultData = () => useSelector(selectSearchResult);
export const getAlllSearchData = () => useSelector(selectAllSearchResult);
export const getPlayListResultData = () =>
  useSelector(selectSearchPlaylistResult);
export const getArtistResultData = () => useSelector(selectSearchArtistResult);
export const getAlbumResultData = () => useSelector(selectSearchAlbumResult);
export const getHeaderPlayData = () => useSelector(selectHeaderPlayData);
export const getSearchQueryForShowMore = () =>
  useSelector(selectSearchQueryForShowMore);
export const getUpdateDialog = () => useSelector(selectUpdateDialog);

export const getHaveAdvertise = () => useSelector(selectHaveAdvertise);
export const getAdvertiseData = () => useSelector(selectAdvertiseData);
export const getAdvLimitationData = () => useSelector(selectAdvLimitationData);
export const getWeightCount = () => useSelector(selectWeightCount);
export const getEmitAdvertise = () => useSelector(selectEmitAdvertise);
export const getWaitForAdv = () => useSelector(selectWaitForAdv);
export const upKeepData = () => useSelector(selectUpkeep);
export const geSearchSelectedTab = () => useSelector(getSelectedTab);
export const getSwipePlayerStatus = () => useSelector(selectPlayerStatus);
export const getTrackDuration = () => useSelector(selectTrackDuration);
export const getLyricFullScreen = () => useSelector(selectLyricFullScreenStatus);
export const getPlayerTimer = () => useSelector(selectPlayerTimer);
export const getEmtyAudioStatus = () => useSelector(selectEmtyAudioStatus);
export const addToPlaylistData = () => useSelector(selectSelectedMode)
export const getCommitedSearchQuery = () => useSelector(selectCommitedSearchQuery)
export const getSingleAdveItem = () => useSelector(selectSingleAdveItem)
export const getNewTrackDialog = () => useSelector(selectNewTrackDialog)
export const getAdvertiseStatusCode = () => useSelector(selectAdvertiseStatusCode)
export const getPlayPushActionData = () => useSelector(selectPlayPushActionData)
export const getPlayListStatic = () => useSelector(selectPlayListStatic)
export const useSelectScrollPosition = () => {
  const { pathname, search } = useLocation();
  const { hashedScrollKey } = useHashedPath()
  const storedScrollPosition = useSelector(selectScrollPosition(hashedScrollKey));
  const scrollPositionOffset = ScrollPositionsOffset?.filter((scroll) => scroll?.path === pathname)[0]?.offset;
  return totalScrollPosition(storedScrollPosition, scrollPositionOffset);
};

const totalScrollPosition = (storedScrollPosition = 0, scrollPositionOffset = 0) => {
  return storedScrollPosition > 0 ? storedScrollPosition - scrollPositionOffset : 0;
};
export const getNotifyPermisionDialogStatus = () => useSelector(selectNotifyPermisionDialogStatus)
export const getNotifySettingPermisionIntroDialogStatus = () => useSelector(selectNotifySettingPermisionIntroDialogStatus)


export const getReelsPlayerStatus = () => useSelector(selectReelPlayerStatus)
export const getReelsPlayerQueue = () => useSelector(selectReelsList)
export const getCurrentReelIndex = () => useSelector(selectCurrentReelIndex)
export const getQueueStatus = () => useSelector(selectQueueStatus)
export const getReelSettingData = () => useSelector(selectReelLimitationData)
export const getReelWeightCount = () => useSelector(selectReelWeightCount)
