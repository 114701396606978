import React from 'react';
import { ReelPlayerContainer } from './ReelPlayerContainer';
import { Drawer } from '@mui/material';
import { useNativeBack } from '../../hooks/useNativeBack';
import { getReelsPlayerStatus } from '../../helper/ReduxSelectorContext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { closeReelPlayer } from '../../redux/reelPlayer/ReelsPlayerSlice';

export const ReelDrawerContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reelsPlayerStatus = getReelsPlayerStatus();

  const handleClosePlayer = () => {
    dispatch(closeReelPlayer());
    navigate(-1);
  };
  useNativeBack(reelsPlayerStatus, 'reels-player-status', handleClosePlayer);
  const handleOpenReelPlayer = () => {
    setOpenReelPlayer(true);
  };

  return (
    <Drawer
      anchor="bottom"
      open={reelsPlayerStatus}
      //   onClose={reelsPlayerStatus}
      onOpen={handleOpenReelPlayer}
      swipeAreaWidth={70}
      className="!relative !z-[99]"
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '520px',
          height: '100%',
          margin: 'auto',
          borderRadius: 0,
          backgroundColor: '#171717',
          // borderRadius: "20px",
        },
      }}
      transitionDuration={{
        enter: 200,
        exit: 200,
        appear: 1000,
      }}
      disableSwipeToOpen={true}
      ModalProps={{
        keepMounted: false,
      }}
    >
      <ReelPlayerContainer />
    </Drawer>
  );
};
