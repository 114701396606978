import React from 'react';

function PlaylistGrayLockIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
    >
      <circle cx='10' cy='10' r='10' fill='#4F4F4F'></circle>
      <path
        fill='#0E0E0E'
        d='M13.5 8.212V7.5a3.5 3.5 0 0 0-7 0v.712A2.5 2.5 0 0 0 5 10.5v3A2.503 2.503 0 0 0 7.5 16h5a2.503 2.503 0 0 0 2.5-2.5v-3a2.5 2.5 0 0 0-1.5-2.288m-3 4.288a.5.5 0 0 1-1 0v-1a.5.5 0 0 1 1 0zm2-4.5h-5v-.5a2.5 2.5 0 0 1 5 0z'
      ></path>
    </svg>
  );
}

export default PlaylistGrayLockIcon;
