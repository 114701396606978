import React, { useEffect, useState } from 'react';
import { getMedals } from '../../../services/ApiClient';
import Container from '../../PageParentContainer/Container';
import CommonHeader from '../../common/CommonHeader';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { MedalCardItem } from './MedalCardItem';
import { useUserData } from '../../../helper/ReduxSelectorContext';
import { NoteMusicLoadingContainer } from '../../loading/NoteMusicLoadingContainer';

export const ProfileMedals = () => {
  const location = useLocation();
  const [medalsList, setMedalsList] = useState([]);
  const useData = useUserData();

  const { data, refetch, isFetching } = useQuery(
    ['profile-medals', location?.state?.social_token],
    () => getMedals(location?.state?.social_token),
    {
      onSuccess: res => {
        setMedalsList(res?.data?.result?.medals);
      },
    },
  );
  useEffect(() => {
    if (location?.state?.medals) {
      setMedalsList(location?.state?.medals);
    }
  }, [location]);

  return (
    <Container shadow={false} header={<CommonHeader title="مدال ها" />}>
      {isFetching ? <NoteMusicLoadingContainer /> : null}
      <div className="w-full h-full flex flex-col gap-3 p-4  ">
        {medalsList?.map((item, index) => (
          <div className="last:!pb-28">
            <MedalCardItem key={index} item={item} />
          </div>
        ))}
      </div>
    </Container>
  );
};
