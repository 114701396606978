import React from 'react';

function SuccessChecked(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="66"
      fill="none"
      viewBox="0 0 66 66"
    >
      <g clipPath="url(#clip0_2113_64314)">
        <path
          fill="#2ACB6B"
          d="M63.989 7.963a6.887 6.887 0 0 1 0 9.729L32.075 49.606a4.55 4.55 0 0 1-3.224 1.333 4.55 4.55 0 0 1-3.223-1.333L10.115 34.093a6.887 6.887 0 0 1 0-9.728 6.887 6.887 0 0 1 9.729 0l9.007 9.005 25.41-25.407a6.86 6.86 0 0 1 4.864-2.012c1.76 0 3.524.672 4.864 2.012m-2.494 7.235a3.36 3.36 0 0 0 0-4.741 3.36 3.36 0 0 0-4.742 0L31.087 36.123a3.14 3.14 0 0 1-2.236.926 3.13 3.13 0 0 1-2.234-.926l-9.266-9.265a3.34 3.34 0 0 0-2.37-.98c-.86 0-1.718.326-2.372.98a3.36 3.36 0 0 0 0 4.742L28.12 47.112a1.035 1.035 0 0 0 1.46 0z"
        ></path>
        <path
          fill="#000"
          d="M61.496 10.457a3.36 3.36 0 0 1 0 4.741L29.582 47.113a1.034 1.034 0 0 1-1.46 0L12.609 31.6a3.36 3.36 0 0 1 0-4.742 3.34 3.34 0 0 1 2.372-.98 3.34 3.34 0 0 1 2.37.98l9.266 9.265c.596.598 1.39.926 2.235.926a3.14 3.14 0 0 0 2.236-.926l25.666-25.666a3.36 3.36 0 0 1 4.742 0"
        ></path>
        <path
          fill="#E0E0E0"
          d="M57.972 30.918c.049.684.074 1.384.074 2.083 0 16.002-13.02 29.022-29.022 29.022C13.02 62.023 0 49.003 0 33S13.02 3.98 29.024 3.98c6.337 0 12.358 2.006 17.408 5.801a1.762 1.762 0 1 1-2.118 2.82c-4.437-3.333-9.723-5.095-15.29-5.095C14.964 7.505 3.527 18.943 3.527 33s11.437 25.496 25.497 25.496c14.058 0 25.495-11.438 25.495-25.496 0-.613-.023-1.229-.065-1.828a1.765 1.765 0 0 1 3.518-.254"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2113_64314">
          <path fill="#fff" d="M0 0h66v66H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SuccessChecked;
