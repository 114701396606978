import React from 'react';

function CloseHashtagIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <circle cx="8" cy="8" r="8" fill="#4F4F4F"></circle>
      <path
        fill="#212121"
        d="M4.842 11.8a.342.342 0 0 1-.242-.584L10.916 4.9a.342.342 0 0 1 .484.483L5.084 11.7a.34.34 0 0 1-.242.1"
      ></path>
      <path
        fill="#212121"
        d="M11.158 11.8a.34.34 0 0 1-.242-.1L4.6 5.383a.342.342 0 1 1 .484-.483l6.316 6.316a.341.341 0 0 1-.242.584"
      ></path>
    </svg>
  );
}

export default CloseHashtagIcon;
