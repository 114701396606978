import React from 'react';
import CustomImage from '../../common/CustomImage';
import { useNavigate } from 'react-router-dom';

export const UserMedalSection = ({ item }) => {
  const navigate = useNavigate();

  const handleNavigateToMedals = () => {
    navigate('/profile/medals', {
      state: {
        medals: item?.medals,
        social_token: item?.social_profile?.token,
      },
    });
  };
  return (
    <div onClick={handleNavigateToMedals} className="flex flex-col gap-4  ">
      <div className=" flex flex-row justify-between gap-4">
        <span className="text-primary font-[600]  text-[10px] font-dana">
          مدال ها
        </span>
        <div
        
          className=" flex flex-row gap-1 items-center cursor-pointer"
        >
          <span className="text-gray_5 font-[600]  text-[10px] font-dana">
            نمایش لیست
          </span>
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2113_66798)">
              <path
                d="M0.104935 3.74317C-0.0349786 3.88308 -0.0349786 4.11684 0.104935 4.25711L3.33324 7.49369C3.47494 7.6354 3.70476 7.6354 3.8461 7.49369C3.98781 7.35199 3.98781 7.12182 3.8461 6.98011L0.874252 3.99998L3.84646 1.0202C3.98817 0.878134 3.98817 0.648322 3.84646 0.506255C3.70476 0.364548 3.47494 0.364548 3.3336 0.506255L0.104935 3.74317Z"
                fill="#E0E0E0"
              />
            </g>
            <defs>
              <clipPath id="clip0_2113_66798">
                <rect width="8" height="8" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div className="flex flex-row gap-2">
        {item?.medals?.map(medal => (
          <div className="w-[27px] h-[27px] ">
            <CustomImage src={medal?.image} />
          </div>
        ))}
      </div>
    </div>
  );
};
