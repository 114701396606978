import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectFaqs } from "../../redux/support/SupportSlice.js";
import {
  selectCategory,
  setCategory,
} from "../../redux/support/SelectedCategorySlice.js";
import GreenCheck from "../svg/common/GreenCheck.jsx";
import { useNavigate } from "react-router-dom";
import SupportCategoryDialogSvg from "../svg/support/SupportCategoryDialogSvg.jsx";
import SwipeBottomSheet from "../bottomSheets/SwipeBottomSheet.jsx";
import BottomSheetHeader from "../bottomSheets/BottomSheetHeader.jsx";
import TicketCategory from "../svg/tickets/TicketCategory.jsx";

// openDialog,closeDialog
export default function SelectCategoryBottomSheet({ open, setOpen }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const faqs = useSelector(selectFaqs);
  const activeCategory = useSelector(selectCategory);

  const toggleCategoryBottomSheet = (newOpen) => () => {
    setOpen(newOpen);
  };
  const handleSelectCategory = (item) => {
    dispatch(setCategory({ category: item }));
    if (item.direct_ticket) {
      navigate("/support/create-ticket", { replace: true, state: { item } });
    } else {
      navigate("/support/ticket-category", { replace: true });
    }

    setOpen(false);
  };
  return (


    <SwipeBottomSheet open={open} toggleDrawer={toggleCategoryBottomSheet}>
      <BottomSheetHeader
        dividerLine
        closeBottomSheet={() => setOpen(false)}
        title="دسته بندی"
        HeaderIcon={
          <TicketCategory className="fill-gray_6 w-[25px] h-[25px]" />
        }
      />
      <div
        dir="rtl"
        className="px-6 flex flex-col gap-8 py-6 "
      >
        {faqs.map((item, index) => (
          <div
            onClick={() => {
              handleSelectCategory(item);
            }}
            key={index}
            className="flex gap-6 items-center"
          >
            <div className="flex gap-4 items-center">
              <div
                className={`flex h-[11px]  ${
                  item.title === activeCategory.title
                    ? "opacity-100"
                    : "opacity-0"
                }`}
                style={{
                  aspectRatio: 16 / 11,
                }}
              >
                <GreenCheck className="stroke-primary" />
              </div>
              <div className="flex gap-2 items-center cursor-pointer">
                <div className="flex gap-1 items-center">
                  <span
                    className={` text-primary font-dana font-[600] text-sm leading-6`}
                  >
                    {item?.title}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </SwipeBottomSheet>
  );
}
