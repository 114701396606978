import { useDispatch } from "react-redux";
import {addTrackToQueueList, clearQueueList, removeTrackFromQueueList} from "../redux/player/PlayMusicSlice";

export const useQueue = () => {
    const dispatch = useDispatch()
    const addTrackToQueue = (track) => {

        let Qid = track.id + "" + Math.floor(Math.random() * 1000000).toFixed()
        dispatch(addTrackToQueueList({ track: { ...track, Qid } }))
    }
    const removeTrackFromQueue = (track) => {
      dispatch(removeTrackFromQueueList({Qid:track?.Qid}))
    }

    const clearAllQueueItems=()=>{
         dispatch(clearQueueList());
    }
  return { addTrackToQueue, removeTrackFromQueue, clearAllQueueItems }
}
