import CustomImage from '../common/CustomImage.jsx';
import pauseIcon from '../../assets/meloPost/record-stop-icon.svg';
import micIcon from '../../assets/meloPost/mic-icon.svg';
import { useEffect, useState } from 'react';
import { getInitialData } from '../../helper/ReduxSelectorContext.js';


export const MeloPostRecordingControl = ({isRecordingInProgress,stopRecording,startRecording,formattedRecordingTime,recordingTime})=>{
  const [error,setError] = useState(false)
  const [remainingTime,setRemainingTime] = useState(null)
  const initialData =  getInitialData()
  const melopostSettings = initialData?.melopost_settings
  const maxRecordingTime = melopostSettings?.max_seconds
  const errorRemainingTime = 60

  useEffect(()=>{
    const recordingTimeINSecond =Math.round((recordingTime /1000))
    if(Number(recordingTimeINSecond) === Number(maxRecordingTime)){
      setError(false)
      stopRecording()
      return
    }
    if(recordingTimeINSecond >=(maxRecordingTime-errorRemainingTime)){
      setError(true)
      setRemainingTime(maxRecordingTime-recordingTimeINSecond)

    }


  },[recordingTime])

  return (
    <div dir='rtl' className='flex flex-col gap-5 items-center '>
      {error ?
        <div className='flex justify-center w-full  '>
          <p className="text-[16px] font-dana font-[500] text-red flex flex-row">
            زمان باقی مانده:

            <span className='flex w-[30px] min-w-[30px] justify-center'>
              {remainingTime}
            </span>
          </p>
        </div>
      :null
      }
      <div>
          {
            isRecordingInProgress ? (
              <div className="cursor-pointer" onClick={() => stopRecording()}>
                <CustomImage
                  appendBaseUrl={false}
                  className={`w-[105px] h-[105px] `}
                  src={pauseIcon}
                />
              </div>
            ) : (
              <div className="cursor-pointer" onClick={() => startRecording()}>
                <CustomImage
                  appendBaseUrl={false}
                  className={`w-[105px] h-[105px] `}
                  src={micIcon}
                />
              </div>
            )
          }
        </div>
    </div>
  )
}
