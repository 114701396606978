import React from 'react';

function AddGroupPlaylistIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='#BDBDBD'
      viewBox='0 0 20 20'
    >
      <path
        d='M10.77 6.924a.77.77 0 0 0-1.539 0v2.307H6.924a.77.77 0 0 0 0 1.539h2.307v2.307a.77.77 0 0 0 1.539 0V10.77h2.307a.77.77 0 0 0 0-1.539H10.77z'></path>
      <path
        fillRule='evenodd'
        d='M14.578.127C13.407 0 11.93 0 10.046 0h-.092C8.07 0 6.593 0 5.422.127c-1.195.129-2.164.398-2.991.999a5.9 5.9 0 0 0-1.305 1.305c-.601.827-.87 1.796-.999 2.991C0 6.593 0 8.07 0 9.954v.092c0 1.884 0 3.361.127 4.532.129 1.195.398 2.164.999 2.991.364.501.804.941 1.305 1.305.827.601 1.796.87 2.991.999C6.593 20 8.07 20 9.954 20h.093c1.883 0 3.36 0 4.531-.127 1.195-.129 2.164-.398 2.991-.999a5.9 5.9 0 0 0 1.305-1.305c.601-.827.87-1.796.999-2.991C20 13.407 20 11.93 20 10.047v-.093c0-1.884 0-3.361-.127-4.532-.129-1.195-.398-2.164-.999-2.991a5.9 5.9 0 0 0-1.305-1.305c-.827-.601-1.796-.87-2.991-.999M3.335 2.371c.521-.379 1.188-.599 2.253-.715C6.667 1.54 8.06 1.538 10 1.538s3.333.002 4.412.118c1.065.116 1.732.336 2.253.715.37.269.695.594.964.964.379.521.599 1.188.715 2.253.117 1.079.118 2.472.118 4.412s-.001 3.333-.118 4.412c-.116 1.065-.336 1.732-.715 2.253-.269.37-.594.695-.964.964-.521.379-1.188.599-2.253.715-1.079.117-2.472.118-4.412.118s-3.333-.001-4.412-.118c-1.065-.116-1.732-.336-2.253-.715a4.4 4.4 0 0 1-.964-.964c-.379-.521-.599-1.188-.715-2.253-.116-1.079-.118-2.472-.118-4.412s.002-3.333.118-4.412c.116-1.065.336-1.732.715-2.253.269-.37.594-.695.964-.964'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default AddGroupPlaylistIcon;
