import { Dialog, Transition } from '@headlessui/react';
import { CardActionArea } from '@mui/material';
import React, { Fragment, useState } from 'react';
import DialogHeader from '../../../dialogs/DialogHeader';
import { useNavigate } from 'react-router-dom';

export const PremiumAvatarDialog = ({ setOpen, open }) => {
  const navigate = useNavigate();


  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleGoToPlans = () => {
    navigate('/plans');
  };
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[20]" onClose={handleCloseDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto  font-dana">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className="w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-primary_dark   text-left align-middle shadow-xl transition-all"
              >
                <DialogHeader
                  header_text={'محدودیت آواتار'}
                  onCloseDialog={handleCloseDialog}
                />
                <div className="px-6 flex flex-col items-center gap-6">
                  <p className="text-gray_6 text-base text-center w-[273px] mx-auto">
                    این آواتار مخصوص کاربران ویژه می‌باشد
                  </p>
                </div>

                <div
                  className={
                    'flex items-center justify-center mt-6   mb-4 gap-2 px-4'
                  }
                >
                  <CardActionArea className=" !h-10 !rounded-[10px] !w-[180px]">
                    <div className="w-full flex items-center justify-center">
                      <button
                        onClick={handleGoToPlans}
                        type="button"
                        className="inline-flex justify-center items-center !h-10  text-base bg-transparent text-primary !border !border-primary w-full rounded-[10px]
                          border-transparent bg-blue-100 px-4 focus:outline-none"
                      >
                        <p className="font-medium">خرید اشتراک</p>
                      </button>
                    </div>
                  </CardActionArea>
                  <CardActionArea className="mt-4 !h-10 !rounded-[10px] !w-[180px]">
                    <div className="w-full flex items-center justify-center">
                      <button
                        onClick={handleCloseDialog}
                        type="button"
                        className="inline-flex justify-center items-center !h-10 text-gray_1 text-base bg-primary w-full 
                        rounded-[10px] border border-transparent bg-blue-100 px-4 focus:outline-none"
                      >
                        <p className="font-dana font-medium"> لغو</p>
                      </button>
                    </div>
                  </CardActionArea>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
