import React from 'react';
import CustomImage from '../../common/CustomImage';
import ClickReaction from '../../../helper/ClickReaction';
import { CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const TicketViewTypes = ({ item }) => {
  const navigate = useNavigate();

  const handleNavigateToTicket = () => {
    navigate('/tickets');
  };

  return (
    <CardActionArea>
      <div
        dir="rtl"
        className="bg-notifiy-granient w-full h-[40px] flex flex-row  justify-between items-center "
      >
        <div className="flex flex-row justify-start gap-1 items-center ">
          <div className="w-[34px] h-[34px] border border-gray_2 rounded-full flex justify-center items-center">
            <CustomImage src={item?.image} />
          </div>
          <span className="text-gray_4 font-dana text-xs">
            {' '}
            {item?.message}
          </span>
        </div>
        <ClickReaction>
          <div
            onClick={handleNavigateToTicket}
            className=" w-[65px] cursor-pointer h-full border border-gray_4 rounded-md bg-transparent text-gray_5 font-medium text-[10px] font-dana flex flex-row justify-center items-center"
          >
            مشاهده
            <div className="h-[12px] w-[12px] bg-red rounded-full absolute -top-1 -right-1"></div>
          </div>
        </ClickReaction>
      </div>
    </CardActionArea>
  );
};
