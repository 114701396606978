import React from 'react';
import CustomImage from '../../common/CustomImage';
import CssShimmer from '../../common/CssShimmer.jsx';
import { useReels } from '../../../consts/useReels.js';

export const PlaylistReels = ({ reelData, isLoading, model_id }) => {
  const { playReelById } = useReels();
  if (isLoading) {
    return <CssShimmer classNames="h-[46px] w-[30px] rounded-[6px]" />;
  }

  if (!reelData?.gif) {
    return null;
  }

  const handlePlayReels = () => {
    playReelById({
      model_type: reelData?.action?.model_type,
      model_id: model_id,
    });
  };
  return (
    <div
      onClick={handlePlayReels}
      className="flex h-[46px] w-[30px] rounded-[6px] border-[2px] border-gray_5 overflow-hidden cursor-pointer"
    >
      <CustomImage src={reelData?.gif} />
    </div>
  );
};
