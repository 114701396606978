import React from 'react';
import { useNavigate } from 'react-router-dom';
import { USER_FOLLOW_STATUS } from '../../consts/FollowStatusConst';
import { useSocialProfile } from '../../hooks/useSocialProfile';

export const UserStatistics = ({ data }) => {
  const navigate = useNavigate();
  const { isUserSocialProfile } = useSocialProfile(data?.social_profile?.token);

  const navigateToFollowingList = () => {
    if (
      data?.follow_status === USER_FOLLOW_STATUS.not_followed &&
      data?.is_private &&
      !isUserSocialProfile
    )
      return;
    navigate('/profile/following', {
      state: { social_token: data?.social_profile?.token },
    });
  };
  const navigateToFollowersList = () => {
    if (
      data?.follow_status === USER_FOLLOW_STATUS.not_followed &&
      data?.is_private &&
      !isUserSocialProfile
    )
      return;
    navigate('/profile/followers', {
      state: { social_token: data?.social_profile?.token },
    });
  };
  return (
    <div className="w-full flex flex-row gap-20 justify-center items-center mt-4">
      <div
        onClick={navigateToFollowersList}
        className="flex flex-row gap-1 items-center"
      >
        <span className="font-dana text-white text-sm font-bold">
          {data?.followers_count}
        </span>
        <span className="font-dana text-gray_5 text-xs">دنبال کننده</span>
      </div>
      <div
        onClick={navigateToFollowingList}
        className="flex flex-row gap-1 items-center"
      >
        <span className="font-dana text-white text-sm font-bold">
          {data?.followings_count}
        </span>
        <span className="font-dana text-gray_5 text-xs">دنبال شونده</span>
      </div>
    </div>
  );
};
