import React from 'react';

export const CrownSvgType3 =({ className='w-[16px] h-[16px] min-w-[16px]' }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 17">
      <path
        fill="#FFD961"
        d="M13.077 11.393H2.922a.505.505 0 0 1-.488-.375L1.017 5.7a.505.505 0 0 1 .714-.582L5.61 7.057l1.939-3.878a.505.505 0 0 1 .904 0l1.939 3.878 3.878-1.94a.505.505 0 0 1 .714.583l-1.418 5.318a.505.505 0 0 1-.489.375"
      ></path>
      <path
        fill="#FFB300"
        d="M13.564 11.018 14.982 5.7a.505.505 0 0 0-.714-.582L10.39 7.057 8.451 3.179A.5.5 0 0 0 8 2.899v8.494h5.076c.229 0 .43-.154.488-.375M12.325 13.6h-8.65c-.228 0-.428-.086-.487-.21l-.746-1.575h11.116l-.746 1.575c-.058.124-.259.21-.487.21"
      ></path>
      <path
        fill="#FF8D00"
        d="m12.812 13.39.746-1.575H8v1.786h4.324c.229 0 .429-.087.488-.211"
      ></path>
    </svg>
  );
}

    