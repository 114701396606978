import React ,{Fragment} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CircleProgress } from './CircleProgress.jsx';
import CloseDialogButton from '../svg/common/CloseDialogButton.jsx';

export const SubmitMelopostProgressDialog = ({ open, handleCLoseDialog,progressPercent}) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        onClose={()=>{}}
        className="relative z-[1400]"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 " />
        </Transition.Child>

        <div
          onClick={e => e.stopPropagation()}
          className="fixed inset-0 overflow-y-auto font-dana"
        >
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className=" w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-primary_dark p-4  text-left align-middle shadow-xl transition-all"
              >
                <div className='flex flex-col '>
                  <div className='flex h-[43px]  w-full justify-end'>
                    <div className="w-4 h-4 cursor-pointer" onClick={handleCLoseDialog}>
                      <CloseDialogButton stroke='#828282' />
                    </div>
                  </div>
                  <div className='flex flex-col gap-6 items-center justify-center'>
                    <div className='w-[114px] h-[114px]'>
                      <CircleProgress percent={progressPercent}/>
                    </div>
                    <div className='flex flex-col w-full items-center'>
                      <p className="text-gray_5 text-[16px] fa-format-700    ">
                      در حال بارگذاری...
                      </p>
                      <p className="text-gray_4 text-[14px] fa-format-500   ">
                        لطفا کمی صبر کنید.
                      </p>

                    </div>
                  </div>

                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

