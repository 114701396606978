import React from 'react';

import { getIsPlaying, getPlayedPlaylist } from '../../../helper/ReduxSelectorContext';
import { useMutation } from '@tanstack/react-query';
import {getCollectionTracks, getPlayerPaginationTrack, getUserCollectionTracks} from '../../../services/ApiClient';
import { usePlayedTrack } from '../../../hooks/usePlayedTrack';
import { setIsPlaying, setPlayedPlaylist, setPlayerPaginationData } from '../../../redux/player/PlayMusicSlice';
import { useDispatch } from 'react-redux';
import { PlayListType } from '../../../consts/PlayListType';

function PlayListPlaySvg({
  item,
  url = '/getCollectionTracks',
  payload = {
    collection_id: item?.id,
    sort: 'melodify',
  },
  fromPlaylist,
}) {
  const [currentTrack, setPlayerTrack] = usePlayedTrack();
  const currentPlaylist = getPlayedPlaylist();
  let isPlaying = getIsPlaying();
  const dispatch = useDispatch();
  const getCollectionTrackMutation = useMutation(
    () =>
      fromPlaylist
        ? getUserCollectionTracks({
            user_collection_id: item?.id,
            sort: 'melodify',
            offset: 0,
          })
        : getPlayerPaginationTrack(url, { ...payload, offset: 0 }),
    {
      onSuccess: res => {
        if (!res?.data?.result?.tracks?.length) {
          return;
        }
        dispatch(setPlayedPlaylist({ playlist: item }));
        setPlayerTrack(
          res?.data?.result?.tracks[0],
          res?.data?.result?.tracks,
          PlayListType.PLAYLIST,
        );
        dispatch(
          setPlayerPaginationData({
            playerPaginationData: {
              url: url,
              payload: payload,
              end: res?.data?.result?.end,
            },
          }),
        );
      },
    },
  );

  const handlePlayPlaylist = (e, play) => {
    e.stopPropagation();
    if (currentPlaylist?.id != item?.id) {
      getCollectionTrackMutation.mutate();

      dispatch(setIsPlaying({ isPlay: false }));
    } else {
      dispatch(setIsPlaying({ isPlay: play }));
    }
  };

  const buttonStyle =
    '!absolute -top-[12%]  left-[50%] translate-x-[-50%] aspect-[1]  w-[24%]';
  return (
    <>
      {currentPlaylist?.id === item?.id &&
      isPlaying &&
      !getCollectionTrackMutation.isLoading ? (
        <svg
          onClick={e => handlePlayPlaylist(e, false)}
          className={buttonStyle}
          fill="none"
          viewBox="0 0 40 40"
          key={`playlist-card-item-play-${item?.id}`}
        >
          <circle cx="20" cy="20" r="20" fill="#242429"></circle>
          <circle
            className="animate-pulse"
            cx="20"
            cy="20"
            r="17.5"
            stroke="#F2C94C"
            strokeDasharray="5 5"
          ></circle>
          <g
            fill="#F2C94C"
            clipPath="url(#clip0_143_12)"
            filter="url(#filter0_d_143_12)"
          >
            <path d="M18.687 24.04a1.56 1.56 0 01-3.12 0v-8.08a1.56 1.56 0 013.12 0v8.08zM24.541 24.04a1.56 1.56 0 01-3.12 0v-8.08a1.56 1.56 0 013.12 0v8.08z"></path>
          </g>
          <defs></defs>
        </svg>
      ) : (
        <svg
          onClick={e => handlePlayPlaylist(e, true)}
          className={buttonStyle}
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          key={`playlist-card-item-pause-${item?.id}`}
        >
          <circle cx="20" cy="20" r="20" fill="#242429" />
          <path
            className={`${
              getCollectionTrackMutation.isLoading && 'animate-pulse'
            }`}
            d="M25.6761 18.581C27.1534 19.4339 27.1534 21.5661 25.6761 22.419L19.5739 25.9422C18.0966 26.7951 16.25 25.729 16.25 24.0231L16.25 16.9768C16.25 15.271 18.0966 14.2049 19.5739 15.0578L25.6761 18.581Z"
            fill="#F2C94C"
          />
        </svg>
      )}
    </>
  );
}

export default PlayListPlaySvg;
