import React from 'react';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage.jsx';
import GrayPlayIcon from '../svg/common/GrayPlayIcon.jsx';
import { RenderReelLike } from './RenderReelLike.jsx';
import { useReels } from '../../consts/useReels.js';

export const LikedReelsItem = ({ item}) => {



  return (
    <div className="rounded-[5px] rounded-bl-[13px] aspect-[26/46] w-full relative overflow-hidden  flex flex-col justify-between">
      <CustomLazyLoadImage
        imageClass={` block absolute w-full h-full z-[10]  `}
        image={item?.image}
      />

      <div className="  relative z-[10] pr-2 pt-2 w-fit ">
        <RenderReelLike id={item?.id} height="h-[12px]" />
      </div>
      <div className="flex justify-between pr-2 w-full max-w-full overflow-hidden relative z-[10] items-end">
        <div className="flex flex-col gap-1 pb-1  w-[calc(100%_-_29px)] ">
          <p className="flex font-[700] font-dana text-[10px] text-white line-clamp-1">
            {item?.track?.title}
          </p>
          <p className="flex font-[400] font-dana text-[8px] text-white line-clamp-1">
            {item?.track?.artists?.map((artist, index) => {
              return (
                <span key={index}>
                  {artist?.name}{' '}
                  {index !== item?.track?.artists?.length - 1 ? '، ' : ''}
                </span>
              );
            })}
          </p>
        </div>

        <div className="absolute left-[-2px] bottom-[-2px] flex w-fit bg-background p-[2px] min-w-[29px] rounded-full  z-[10]">
          <div className="w-[25px] min-w-[25px] h-[25px] ">
            <GrayPlayIcon />
          </div>
        </div>
      </div>
    </div>
  );
};



