import React from 'react';
import CssShimmer from '../common/CssShimmer';

export const ProfileLoadingShimmer = () => {
  return (
    <div dir="rtl" className=" flex flex-col">
      <div className="flex flex-col gap-4">
        <div
          dir="rtl"
          className="flex flex-row justify-between items-center bg-profile-gradient px-4 py-2"
        >
          <CssShimmer
            classNames={`!w-[72px] !h-[72px]  !border !border-gray_1 !rounded-full`}
          />
          <CssShimmer classNames={`w-[200px] h-[38px]   rounded-[8px]`} />
        </div>

        <div className="flex flex-col gap-2 px-4">
          <span className=" font-dana text-white font-medium text-base">
            <CssShimmer classNames={`w-[200px] h-[20px]   rounded-[8px]`} />
          </span>{' '}
          <span className=" font-dana text-gray_4 font-normal text-xs">
            <CssShimmer classNames={`w-[100px] h-[20px]   rounded-[8px]`} />
          </span>
        </div>
      </div>
      <div className="w-full flex flex-row gap-20 justify-center items-center mt-4">
        <div className="flex flex-row gap-1 items-center">
          <CssShimmer classNames={`w-[100px] h-[20px]   rounded-[8px]`} />
        </div>
        <div className="flex flex-row gap-1 items-center">
          <CssShimmer classNames={`w-[100px] h-[20px]   rounded-[8px]`} />
        </div>
      </div>
      <div className="w-full mt-3 px-4"> 
        <CssShimmer classNames={`w-full h-[46px]    rounded-[30px]`} />
      </div>
    </div>
  );
};
