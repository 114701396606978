import React from "react";
import {useEffect} from "react";
import {useState} from "react";
import {useSelector} from "react-redux";
import {
    selectIntroductionDialog,
} from "../../redux/dialog/DialogSlice";
import {Dialog, Transition} from "@headlessui/react";
import {Fragment} from "react";
import {playStoreUrl} from "../../consts/GlobalConsts.js";
import {initialData} from "../../redux/initialize/InitializeSlice.js";
import {useUserData} from "../../helper/ReduxSelectorContext.js";
import DialogHeader from "./DialogHeader.jsx";
import {CardActionArea} from "@mui/material";
import GiftSVG from "../../components/svg/common/GiftSVG.jsx";

function IntroductionDialog() {
    const [open, setOpen] = useState(false);
    const dialogData = useSelector(selectIntroductionDialog);
    const {direct_link, slogan} = initialData();
    const user = useUserData();
    const doShareInfo = () => {
        window.navigator
            .share({
                title: "اشتراک با",
                text:
                    slogan +
                    "\n\nدریافت از پلی استور\n\n" +
                    playStoreUrl +
                    "\n\nدریافت به صورت مستقیم\n\n" +
                    direct_link +
                    "\n\nکد معرف : " +
                    user?.ref_number,
            })
            .then(() => {
                console.log("Yay, you shared it :)")
                closeCustomDialog()
            })
            .catch((error) => console.log("Oh noh! You couldnt"));
    };
    const closeCustomDialog = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (dialogData) setOpen(true);
    }, [dialogData]);


    return (
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeCustomDialog}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 " />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto  font-dana">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  dir="rtl"
                  className=" font-dana w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-primary_dark  text-left align-middle shadow-xl transition-all"
                >
                  <DialogHeader onCloseDialog={closeCustomDialog} />
                  <div className="px-6 flex flex-col items-center">
                    <div className="flex flex-col gap-4 mt-5">
                      <div className="w-[50px] mx-auto relative">
                        <GiftSVG />
                      </div>
                      <div className="flex flex-col mt-3">
                        <p className="text-primary text-2xl font-semibold text-center ">
                          تبریک!
                        </p>
                        <p className="text-white text-base text-center font-semibold">
                          {dialogData?.introductions} کاربر جدید
                          <br /> با کد شما به ما پیوست.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={'flex items-center justify-center mt-4'}>
                    <CardActionArea className="mt-4 !h-10 !rounded-[100px] !w-[180px]">
                      <div className="w-[180px] flex items-center justify-center">
                        <button
                          onClick={doShareInfo}
                          type="button"
                          className="inline-flex justify-center items-center !h-10 text-black text-base bg-primary w-full justify-center rounded-full border border-transparent bg-blue-100 px-4 focus:outline-none"
                        >
                          <p className="font-semibold">اشتراک گذاری</p>
                        </button>
                      </div>
                    </CardActionArea>
                  </div>
                  <p className="flex flex-col items-center justify-center text-primary font-semibold text-base text-right mt-2 ">
                    <span className={'red-glow text-white'}>
                      {dialogData?.remaining}
                      <span className={'text-primary no-glow'}> کاربر </span>
                    </span>
                    <span>دیگر برای دریافت یک ماه رایگان!</span>
                  </p>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
}

export default IntroductionDialog;
