import React from 'react';
import 'swiper/css';
import { FreeMode } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useNavigate } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import { useInView } from 'react-intersection-observer';
import { IsIosDevice } from '../../helper/CheckDevice.js';
import CategoryCard from '../playlist/CategoryCard.jsx';
import UserAvatar from '../common/UserAvatar.jsx';
import { useAction } from '../../hooks/useAction.js';
import { HOME_TYPES } from '../../types/HomeTypes.js';

export const HomeUserPlayList = ({ item, cardStyle,elementViewType }) => {
  const navigate = useNavigate();
  const action = useAction()
  const openCollection = item => {
    if(elementViewType === HOME_TYPES.HORIZONTAL_USER_COLLECTIONS){
      handleOpenUserPlaylist(item)
    }else {
      navigate(`/playlist-songs/${item?.id}`, {
        state: { collectionObject: item },
      });
    }

  };
  const handleOpenUserPlaylist = (collection) => {

    const actionData = {
      target_type: "open_user_playlist",
      playlistData: collection,
    };
    action(actionData);
  }

  const Column = ({ data, index, style }) => {
    return (
      <div style={{ ...style, right: style.right + 7 + 'px' }}>
        <div className="!flex !items-baseline !justify-start  z-30 ">
          <div
            className="!w-fit !pb-6 last:!pl-4 first:!pr-1"
            key={data[index]?.id}
          >
            <div
              className={`w-full h-full ${cardStyle}`}
              onClick={() => openCollection(data[index])}
            >
              <div className="flex flex-col gap-6">
                <CategoryCard
                  fromMyMelodify
                  item={data[index]}
                  scale={true}
                  fromPlaylist
                />
                <div className="flex flex-col gap-1">
                  <span className="text-white font-normal font-dana text-xs line-clamp-1 max-w-[130px]">
                    {data[index]?.title}
                  </span>
                  <div className=" flex flex-row gap-1 items-center">
                    <UserAvatar
                      avatar={data[index]?.social_profile?.avatar}
                      is_premium={data[index]?.social_profile?.is_premium}
                      className="!w-[20px] !h-[20px]"
                    />
                    <span className="text-gray_4 font-normal font-dana text-[10px] line-clamp-1 max-w-[110px]">
                      {data[index]?.social_profile?.name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  return (
    <div dir="rtl" className="flex flex-col gap-4">
      <div ref={ref} className="slider">
        {IsIosDevice() ? (
          <div
            className={`!w-full !h-fit flex overflow-scroll  !select-none new-song-swiper`}
          >
            {inView ? (
              <List
                direction="rtl"
                height={210}
                itemCount={item?.length}
                itemSize={146}
                layout="horizontal"
                width={400}
                style={{ width: '100% !important' }}
                itemData={item}
                className="!pr-[14px] "
              >
                {Column}
              </List>
            ) : (
              <div className="h-[145px] w-full"></div>
            )}
          </div>
        ) : (
          <Swiper
            // lazy={true}
            slidesPerView={'auto'}
            spaceBetween={16}
            cssMode={IsIosDevice()}
            modules={[FreeMode]}
            freeMode={true}
            className={`!w-full !h-fit flex flex-col-reverse  !select-none new-song-swiper`}
          >
            {item?.map((collectionItem, index) => (
              <div
                key={index + 'HomeUserPlayList'}
                className="!flex !items-baseline !justify-start  z-30 "
              >
                <SwiperSlide
                  className="!w-fit !pb-6 last:!pl-4 first:!pr-4"
                  key={collectionItem?.id}
                >
                  <div className="flex flex-col gap-6">
                    <div
                      className={`w-full h-full ${cardStyle}`}
                      onClick={() => openCollection(collectionItem)}
                    >
                      <CategoryCard
                        fromMyMelodify
                        item={collectionItem}
                        scale={true}
                        fromPlaylist
                      />{' '}
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="text-white font-normal font-dana text-xs line-clamp-1 max-w-[130px]">
                        {collectionItem?.title}
                      </span>
                      <div className=" flex flex-row gap-1 items-center">
                        <UserAvatar
                          avatar={collectionItem?.social_profile?.avatar}
                          is_premium={
                            collectionItem?.social_profile?.is_premium
                          }
                          className="!w-[20px] !h-[20px]"
                        />
                        <span className="text-gray_4 font-normal font-dana text-[10px] line-clamp-1 max-w-[110px]">
                          {collectionItem?.social_profile?.name}
                        </span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};
