import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { ShareMelopostPlayIcon } from '../../svg/meloPost/ShareMelopostPlayIcon';
import ClickReaction from '../../../helper/ClickReaction';
import { ShareMelopostPauseIcon } from '../../svg/meloPost/ShareMelopostPauseIcon';
import { dispatchIsPlaying } from '../../../redux/globalstore/GlobalStore';

function ShareVideoPlayer({ heightRelation, videoUrl, cover }) {
  const reelPlayerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
    handlePlayPauseTrackPlayer();
    // reelPlayerRef?.current?.player?.player?.pause();
    // setPausePlayer(true);
  };

  const handlePlayPauseTrackPlayer = () => {
    dispatchIsPlaying(false);
  };
  return (
    <div
      id="reel-player"
      className="w-full rounded-[8px] border-[1px] border-gray_2 bg-black relative overflow-hidden "
      style={{ aspectRatio: heightRelation }}
    >
      <div className="w-full h-full absolute flex flex-row justify-center items-center top-0 left-0 right-0 bottom-0 z-10">
        <ClickReaction style=" !w-[65px] !h-[65px]">
          <div
            onClick={handlePlayPause}
            className="rounded-full size-[65px] bg-primary bg-opacity-50 p-4"
          >
            {isPlaying ? (
              <div className="w-[32px] h-[32px] flex justify-center items-center">
                <ShareMelopostPauseIcon />
              </div>
            ) : (
              <div className="w-[32px] h-[32px] flex justify-center items-center">
                <ShareMelopostPlayIcon />
              </div>
            )}
          </div>
        </ClickReaction>
      </div>
      <ReactPlayer
        playing={isPlaying}
        ref={reelPlayerRef}
        url={videoUrl}
        preload="auto"
        wrapper={'span'}
        config={{
          uiWebview: {
            allowsInlineMediaPlayback: true,
            mediaPlaybackRequiresUserAction: false,
            backgroundMode: true,
          },
          attributes: {
            playsinline: true,
            playsInline: true,
            'webkit-playsinline': true,
          },
          file: {
            forceVideo: true,
          },
        }}
      />
    </div>
  );
}

export default ShareVideoPlayer;
