import { useInfiniteQuery } from "@tanstack/react-query";
import { getExclusiveTracks } from '../services/ApiClient.js';
import { djMixSortTypes } from '../consts/MenuItemsConsts.js';
import { useState } from 'react';



const useExclusiveTracksQuery = (trackTypes) => {

  const [sortType, setSortType] = useState(djMixSortTypes[0]);
  const changeSort = type => {
    setSortType(type);
  };
  const {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data
  } = useInfiniteQuery(
    [`getExclusiveTracks${trackTypes}${sortType?.type}`],
    ({ pageParam = 0 }) => getExclusiveTracks({
      tracks_type: trackTypes,
      offset: pageParam,
      sort:sortType?.type
    }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const allData = allPages?.flatMap((page) => page.data.result.tracks);
        return lastPage?.data?.result?.end ? false : allData?.length;
      },
      refetchOnWindowFocus: false
    }
  );

  return {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    tracks: data?.pages?.flatMap((page) => page.data.result.tracks),
    sortType,
    changeSort,

  };
};

export default useExclusiveTracksQuery;
