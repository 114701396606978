import { useInfiniteQuery } from '@tanstack/react-query';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getFollowRequests } from '../../services/ApiClient';
import CommonHeader from '../common/CommonHeader';
import SongListLoading from '../loading/SongListLoading';
import Container from '../PageParentContainer/Container';
import { FollowRequestViewType } from './ViewTypes/FollowRequestViewType';
import { NoteMusicLoadingContainer } from '../loading/NoteMusicLoadingContainer';
import { EmptyNotifeView } from './EmptyNotifeView';

export const FollowRequestPage = () => {
  const {
    isLoading,
    isFetchingNextPage,
    fetchNextPage: getFollowersIsFetchNextPage,
    hasNextPage: getFollowersHasNextPage,
    data: geFollowersData,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    [`getFollowRequests`],
    ({ pageParam = 0 }) =>
      getFollowRequests({
        offset: pageParam,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const allData = allPages?.flatMap(
          page => page.data.result.follow_requests,
        );
        return lastPage?.data?.result?.end ? false : allData?.length;
      },
      refetchOnWindowFocus: false,
    },
  );

  const flattenedFollowedRequest =
    geFollowersData?.pages?.flatMap(page => page.data.result.follow_requests) ??
    [];

  const fetchNextPage = () => {
    getFollowersIsFetchNextPage();
  };
  const handleRefetchRequests = () => {
    refetch();
  };

  return (
    <Container
      style="p-4"
      shadow={false}
      header={<CommonHeader title="درخواست‌های دوستی" />}
    >
      {flattenedFollowedRequest?.length == 0 && !isLoading ? (
        <div
          dir="rtl"
          className="w-full h-full flex flex-col !overflow-y-scroll !webkit-overflow-scrolling-touch"
        >
          <EmptyNotifeView title="شما هیچ درخواست دوستی ندارید" />
        </div>
      ) : null}
      {isLoading ? <NoteMusicLoadingContainer /> : null}
      <div
        id="follow-request"
        className="w-full h-full flex flex-col !overflow-y-scroll !webkit-overflow-scrolling-touch mt-3"
      >
        <InfiniteScroll
          className="!overflow-hidden"
          next={fetchNextPage}
          hasMore={hasNextPage}
          dataLength={flattenedFollowedRequest?.length}
          loader={
            isFetchingNextPage && (
              <div className="w-full flex justify-center items-start h-[50px]">
                <SongListLoading />
              </div>
            )
          }
          scrollableTarget="follow-request"
        >
          <div className="w-full flex flex-col gap-6">
            {flattenedFollowedRequest?.map((item, index) => (
              <FollowRequestViewType
                key={`followed-page-${index}`}
                item={{ ...item, message: item?.name }}
                callBack={handleRefetchRequests}
              />
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </Container>
  );
};
