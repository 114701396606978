import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";
import { updateFirebaseToken } from "../services/ApiClient";
const firebaseConfigDev = {
    apiKey: "AIzaSyD5-HfOOTft0-KZCYg35KoyQNKD4Ti2Av4",
    authDomain: "melodify-58d0a.firebaseapp.com",
    projectId: "melodify-58d0a",
    storageBucket: "melodify-58d0a.appspot.com",
    messagingSenderId: "553019406678",
    appId: "1:553019406678:web:3cc22edf70c964cd613ef7",
    measurementId: "G-Z3DJBRXTVJ",
};
const firebaseConfig = {
    apiKey: "AIzaSyDQY65QQdxgSi2-GV250lei7yV9Br-I75g",
    authDomain: "melodify-a339f.firebaseapp.com",
    projectId: "melodify-a339f",
    storageBucket: "melodify-a339f.appspot.com",
    messagingSenderId: "873595747413",
    appId: "1:873595747413:web:061575003d03b2a26028dc",
    measurementId: "G-6192V31Q4K"
};

const FC_TOKEN_DEV = "BCd9kh0VxlS4-D4Ik7qi7GvKhmgDTHlUQkIyEnobE2R_BtflywBjddePAfN-F8RN1W56gLL6cRdpYrT7kZ-TZUE"
const FC_TOKEN = "BL7GpA87lwG9h0Oa1MNEO5sS24ubNFJQXG9MvifbxpuE61y_12sdbFIe7yVHJhWDgSv1y-ZdShv_sfAMWptS668"
export const FireBaseConf = () => {
    const app = initializeApp(firebaseConfigDev);
    const messaging = getMessaging(app);
    const getFcmToken = async () => {
        const registration = await navigator.serviceWorker.ready;
        try {
            // First, delete the existing token
            const fcmToken = await getToken(messaging, {
                serviceWorkerRegistration: registration,
                vapidKey: FC_TOKEN_DEV
            });

            if (fcmToken) {
                updateFirebaseToken({ firebase_token: fcmToken }); // Replace with your method to store the token
                console.log('Firebase token revalidated successfully:', fcmToken);
            } else {
                console.log('No new registration token available. Request permission to generate one.');
            }
        } catch (error) {
            console.error('An error occurred while revalidating token:', error);
        }
    }

    const RemoveFcmToken = async () => {
        const registration = await navigator.serviceWorker.ready;
        try {
            // First, delete the existing token
            const currentToken = await getToken(messaging, {
                serviceWorkerRegistration: registration,
                vapidKey: FC_TOKEN_DEV
            });

            if (currentToken) {
                await deleteToken(messaging); // Delete the current token
                console.log('Token deleted successfully. Re-fetching token...');
            }


        } catch (error) {
            console.error('An error occurred while revalidating token:', error);
        }
    }
    return { getFcmToken, RemoveFcmToken }

}