import React from 'react';

function UserPlaylistStatisticsIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='21'
      fill='none'
      viewBox='0 0 16 21'
    >
      <path
        fill='#E0E0E0'
        stroke='#E0E0E0'
        strokeWidth='0.5'
        d='M.825 19.073v.25h2.542V14.35H.825v4.723Zm-.287-5.395h3.117c.139 0 .287.13.288.336v5.645c0 .206-.15.335-.288.335H.538c-.14 0-.288-.13-.288-.335v-5.645c0-.206.149-.336.288-.336ZM8.896 19.327h.25V11.2H6.605v8.128h2.291Zm-2.579-8.8h3.117c.14 0 .288.13.288.336v8.8c0 .206-.149.336-.288.336H6.317c-.14 0-.288-.13-.288-.336v-8.8c0-.206.149-.335.288-.335ZM14.684 19.329h.25V6.435h-2.542V19.33h2.292Zm-2.58-13.565h3.118c.139 0 .287.13.287.336v13.564c0 .206-.148.336-.287.336h-3.117c-.14 0-.288-.13-.288-.336V6.1c0-.206.148-.336.288-.336ZM6.44 4.043l.123.07 2.236 1.262a.25.25 0 0 0 .256-.004l4.98-3.028.595-.362-.229.658-.191.55c-.066.191.029.384.171.443a.3.3 0 0 0 .095.019c.104 0 .218-.07.267-.21zm0 0-.124.07m.123-.07-.123.07m0 0L.7 7.33a.25.25 0 0 1-.126.035.28.28 0 0 1-.251-.172c-.086-.178-.016-.385.125-.466l5.864-3.36a.25.25 0 0 1 .25 0l2.229 1.258.127.072.126-.076 4.731-2.876.535-.326-.612-.132-.555-.12zM15.19.928l-.016-.004-1.927-.418c-.134-.03-.297.062-.333.26-.038.203.086.37.229.4zm0 0 .017.001M15.19.928l.017.001m0 0a.28.28 0 0 1 .229.163zm.248.465-.712 2.046.693-2.348a.4.4 0 0 1 .039.147v.002a.4.4 0 0 1-.02.153Z'
      ></path>
    </svg>
  );
}

export default UserPlaylistStatisticsIcon;
