import React from 'react';

export const Checkbox = ({isSelect}) => {

    if(isSelect){
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
        >
          <rect width="20" height="20" fill="#F2C94C" rx="3"></rect>
          <path
            stroke="#4F4F4F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.563"
            d="M13.6 7.303 8.901 12 6.666 9.764"
          ></path>
        </svg>
      )
    }else {
      return  (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
        >
          <rect
            width="19"
            height="19"
            x="0.5"
            y="0.5"
            fill="#303030"
            stroke="#828282"
            rx="2.5"
          ></rect>
        </svg>
      )

    }

};


