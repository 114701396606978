import { useNavigate } from "react-router-dom";
import { dispatchIsPlaying } from "../redux/globalstore/GlobalStore";
import { GET_REEL_TYPES } from "./MeloReelsConst";
import { useDispatch } from "react-redux";
import { pauseReelPlayer, settPlayingReel } from "../redux/reelPlayer/ReelsPlayerSlice";

export const useReels = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const handlePlayPause = () => {
        dispatchIsPlaying(false);
    };

    const playReel = ({ index, lists, model_type = GET_REEL_TYPES.HOME }) => {
        handlePlayPause();
        navigate('#reels', { state: { model_type: model_type } });
        dispatch(
            settPlayingReel({
                reelPlayerStatus: true,
                reelsList: lists,
                currentReelIndex: index,
            }),
        );
    };

    const playReelById = ({ model_type = GET_REEL_TYPES.HOME, model_id }) => {
        handlePlayPause();
        navigate('#reels', { state: { model_type: model_type, model_id: model_id } });
        dispatch(
            settPlayingReel({
                reelPlayerStatus: true,
                reelsList: [],
                currentReelIndex: 0,
            }),
        );
    };

    const handleCloseReels=()=>{
         dispatch(pauseReelPlayer({ status: true }));
    }

    
    return { playReel, playReelById, handleCloseReels }
}