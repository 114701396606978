import React from 'react';

import clsx from 'clsx';
import { PrivateProfileIcon } from '../svg/profile/PrivateProfileIcon.jsx';

export const PrivateProfileView = ({className}) => {
  return (
    <div
      dir="rtl"
      className={clsx(
        'flex flex-col  gap-[28px] w-full items-center justify-center ',
        className,
      )}
    >
      <div>
        <PrivateProfileIcon />
      </div>
      <div className='flex flex-col gap-2 justify-center text-center'>

      <p className="text-[14px] font-[800] text-gray_3 font-dana">
        حساب این کاربر خصوصی است.
      </p>
      <p className="text-[10px] font-[800] text-gray_3 font-dana">
        برای مشاهده محتوای این کاربر او را دنبال کنید.
      </p>
      </div>
    </div>
  );
};
