import React from 'react';
import clsx from 'clsx'
import GrayPauseIcon from '../svg/common/GrayPauseIcon.jsx';
import GrayPlayIcon from '../svg/common/GrayPlayIcon.jsx';
import ClickReaction from '../../helper/ClickReaction';
export const TogglePlayGrayButton = ({ isPlaying,startPlay,pausePlay,className='!h-[32px] !w-[32px] !min-w-[32px]' }) => {


  return (

    <div className={clsx('flex', className)}>
    <ClickReaction>
      {isPlaying ?
        <div onClick={pausePlay} className='w-full h-full '>
          <GrayPauseIcon/>
        </div>
      :
        <div onClick={startPlay} className='w-full h-full '>
          <GrayPlayIcon/>
        </div>
      }
        <div>
        </div>
    </ClickReaction>
    </div>
  );
};

