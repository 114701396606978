export const MELOPOST_STATUS = {
  OWNER: 'owner',
  VIEWER: 'viewer',
};

export const MELOPOST_OWNER_OPTIONS = [
  {
    id: 1,
    title: 'ویرایش ',
    textColor:'#FFFFFF',
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2113_184573)">
          <path
            d="M16.0938 20H3.90625C1.7523 20 0 18.2477 0 16.0938V3.90625C0 1.75234 1.7523 0 3.90625 0H11.4856C11.9171 0 12.2668 0.349766 12.2668 0.78125C12.2668 1.21273 11.9171 1.5625 11.4856 1.5625H3.90625C2.61391 1.5625 1.5625 2.61391 1.5625 3.90625V16.0938C1.5625 17.3861 2.61391 18.4375 3.90625 18.4375H16.0938C17.3861 18.4375 18.4375 17.3861 18.4375 16.0938V8.51441C18.4375 8.08293 18.7873 7.73316 19.2188 7.73316C19.6502 7.73316 20 8.08293 20 8.51441V16.0938C20 18.2477 18.2477 20 16.0938 20Z"
            fill="#BDBDBD"
          />
          <path
            d="M18.7623 1.23854C17.5438 0.0200976 15.5613 0.0200976 14.3428 1.23854L5.83857 9.74283C4.40622 11.1751 3.78826 13.2161 4.18552 15.2024C4.24736 15.5117 4.48908 15.7534 4.79833 15.8153C6.77509 16.2106 8.8188 15.6014 10.258 14.1622L18.7623 5.65791C19.9807 4.43951 19.9807 2.45697 18.7623 1.23854ZM17.6574 4.55311L9.1531 13.0574C8.22029 13.9903 6.93958 14.4571 5.64329 14.3575C5.54349 13.0615 6.01048 11.7806 6.94341 10.8477L13.5616 4.22947L14.1141 4.78193C14.4192 5.08701 14.9138 5.08709 15.2189 4.78193C15.524 4.47686 15.5241 3.98217 15.219 3.67709L14.6665 3.12463L15.4477 2.34338C16.057 1.73416 17.0483 1.73416 17.6574 2.34338C18.2667 2.9526 18.2667 3.94389 17.6574 4.55311Z"
            fill="#BDBDBD"
          />
        </g>
        <defs>
          <clipPath id="clip0_2113_184573">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    id: 2,
    title: 'حذف',
    textColor:'#EB5757',
    icon: (
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2638_97348)">
          <path d="M17.8125 3.625H13.9062V2.84375C13.9062 1.55141 12.8548 0.5 11.5625 0.5H8.4375C7.14516 0.5 6.09375 1.55141 6.09375 2.84375V3.625H2.1875C1.75602 3.625 1.40625 3.97477 1.40625 4.40625C1.40625 4.83773 1.75602 5.1875 2.1875 5.1875C2.61828 5.1875 2.96875 5.53797 2.96875 5.96875V18.1562C2.96875 19.4486 4.02016 20.5 5.3125 20.5H14.6875C15.9798 20.5 17.0312 19.4486 17.0312 18.1562V5.96875C17.0312 5.53797 17.3817 5.1875 17.8125 5.1875C18.244 5.1875 18.5938 4.83773 18.5938 4.40625C18.5938 3.97477 18.244 3.625 17.8125 3.625ZM7.65625 2.84375C7.65625 2.41297 8.00672 2.0625 8.4375 2.0625H11.5625C11.9933 2.0625 12.3438 2.41297 12.3438 2.84375V3.625H7.65625V2.84375ZM15.4688 5.96875V18.1562C15.4688 18.587 15.1183 18.9375 14.6875 18.9375H5.3125C4.88172 18.9375 4.53125 18.587 4.53125 18.1562V5.96875C4.53125 5.69488 4.48359 5.43203 4.39687 5.1875H15.6032C15.5164 5.43203 15.4688 5.69488 15.4688 5.96875Z" fill="#EB5757"/>
          <path d="M6.875 6.75C6.44352 6.75 6.09375 7.09977 6.09375 7.53125V16.5938C6.09375 17.0252 6.44352 17.375 6.875 17.375C7.30648 17.375 7.65625 17.0252 7.65625 16.5938V7.53125C7.65625 7.09977 7.30648 6.75 6.875 6.75Z" fill="#EB5757"/>
          <path d="M10 6.75C9.56852 6.75 9.21875 7.09977 9.21875 7.53125V16.5938C9.21875 17.0252 9.56852 17.375 10 17.375C10.4315 17.375 10.7812 17.0252 10.7812 16.5938V7.53125C10.7812 7.09977 10.4315 6.75 10 6.75Z" fill="#EB5757"/>
          <path d="M12.3438 7.53125V16.5938C12.3438 17.0252 12.6935 17.375 13.125 17.375C13.5565 17.375 13.9062 17.0252 13.9062 16.5938V7.53125C13.9062 7.09977 13.5565 6.75 13.125 6.75C12.6935 6.75 12.3438 7.09977 12.3438 7.53125Z" fill="#EB5757"/>
        </g>
        <defs>
          <clipPath id="clip0_2638_97348">
            <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
          </clipPath>
        </defs>
      </svg>
    ),
  },
];
export const MELOPOST_VIEWER_OPTIONS = [
  {
    id: 1,
    title: 'مشاهده پروفایل کاربر ',
    textColor:'#FFFFFF',
    icon: (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2638_97311)">
          <path d="M10 20C7.32891 20 4.8177 18.9598 2.92891 17.0711C1.0402 15.1823 0 12.6711 0 10C0 7.32891 1.0402 4.8177 2.92891 2.92891C4.8177 1.0402 7.32891 0 10 0C12.6711 0 15.1823 1.0402 17.0711 2.92891C18.9598 4.8177 20 7.32891 20 10C20 12.6711 18.9598 15.1823 17.0711 17.0711C15.1823 18.9598 12.6711 20 10 20ZM10 1.5625C5.34754 1.5625 1.5625 5.34754 1.5625 10C1.5625 14.6525 5.34754 18.4375 10 18.4375C14.6525 18.4375 18.4375 14.6525 18.4375 10C18.4375 5.34754 14.6525 1.5625 10 1.5625ZM9.96094 10.7422C7.89316 10.7422 6.21094 9.05996 6.21094 6.99219C6.21094 4.92441 7.89316 3.24219 9.96094 3.24219C12.0287 3.24219 13.7109 4.92441 13.7109 6.99219C13.7109 9.05996 12.0287 10.7422 9.96094 10.7422ZM9.96094 4.80469C8.75473 4.80469 7.77344 5.78602 7.77344 6.99219C7.77344 8.19836 8.75473 9.17969 9.96094 9.17969C11.1671 9.17969 12.1484 8.19836 12.1484 6.99219C12.1484 5.78602 11.1671 4.80469 9.96094 4.80469ZM14.9106 15.2919C15.2445 15.0185 15.2934 14.5262 15.02 14.1924C13.9153 12.8438 12.283 12.0703 10.5416 12.0703H9.61465C7.87328 12.0703 6.24098 12.8438 5.13629 14.1925C4.86289 14.5262 4.91184 15.0185 5.24566 15.2919C5.57945 15.5654 6.07168 15.5163 6.34512 15.1826C7.15176 14.1977 8.34348 13.6328 9.61465 13.6328H10.5416C11.8128 13.6328 13.0045 14.1977 13.8112 15.1825C13.9657 15.3711 14.1899 15.4688 14.416 15.4688C14.5902 15.4688 14.7654 15.4108 14.9106 15.2919Z" fill="#BDBDBD"/>
        </g>
        <defs>
          <clipPath id="clip0_2638_97311">
            <rect width="20" height="20" fill="white"/>
          </clipPath>
        </defs>
      </svg>

    ),
  },
  {
    id: 2,
    title: 'گزارش خطا',
    textColor:'#EB5757',
    icon: (
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2638_97381)">
          <path d="M10 20.5C4.47293 20.5 0 16.0275 0 10.5C0 4.97293 4.47254 0.5 10 0.5C15.5271 0.5 20 4.97254 20 10.5C20 16.0271 15.5275 20.5 10 20.5ZM10 2.0625C5.33652 2.0625 1.5625 5.83621 1.5625 10.5C1.5625 15.1635 5.33621 18.9375 10 18.9375C14.6635 18.9375 18.4375 15.1638 18.4375 10.5C18.4375 5.83652 14.6638 2.0625 10 2.0625ZM10.7812 11.3465V6.31551C10.7812 5.88402 10.4315 5.53426 10 5.53426C9.56852 5.53426 9.21875 5.88402 9.21875 6.31551V11.3465C9.21875 11.778 9.56852 12.1277 10 12.1277C10.4315 12.1277 10.7812 11.778 10.7812 11.3465ZM10 13.0843C9.4175 13.0843 8.94531 13.5565 8.94531 14.139C8.94531 14.7215 9.4175 15.1937 10 15.1937C10.5825 15.1937 11.0547 14.7215 11.0547 14.139C11.0547 13.5565 10.5825 13.0843 10 13.0843Z" fill="#EB5757"/>
        </g>
        <defs>
          <clipPath id="clip0_2638_97381">
            <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
          </clipPath>
        </defs>
      </svg>
    ),
  },

];
