import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDropDown,
  setActiveIndex,
  showBugReportDialog,
} from '../../redux/player/DropDownSlice.js';
import { useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { getUserCollections } from '../../services/ApiClient.js';
import {
  selectGlobalData,
  setUserCollections,
} from '../../redux/globalData/globalDataSlice.js';
import { mainToast } from '../../helper/ToastsEmitter.jsx';
import { useDownload } from '../../hooks/useDownload.js';
import { removeFromDownloaded } from '../../redux/download/downloadSlice.js';
import { deleteDownloadedFile } from '../../indexDB/dbQuery.js';
import { db } from '../../indexDB/DB.js';
import FileSaver from 'file-saver';
import { useLike } from '../../helper/useLike.js';
import { useTelegram } from '../../hooks/useTelegram.jsx';
import { useUserData } from '../../helper/ReduxSelectorContext.js';
import { ContextMenuKeys } from '../../consts/MenuItemsConsts.js';
import ConfirmDialogs from '../dialogs/ConfirmDialogs.jsx';
import TrashSVG from '../svg/common/TrashSVG.jsx';
import { useSelectTrackItem } from '../../hooks/useSelectTrackItem.js';
import { usePlayedTrack } from '../../hooks/usePlayedTrack.js';
import { resetPlayer } from '../../redux/player/PlayMusicSlice.js';
import QueueSvg from '../svg/common/QueueSvg.jsx';
import { useQueue } from '../../hooks/useQueue.js';

function DropDownItem({
  title,
  secondTitle,
  defaultConfig,
  subItem,
  Icon,
  SecondIcon,
  index,
  data,
  position,
  closeDropDown,
  isDownloaded,
  itemKey,
  textColor,
  iconColor,
}) {
  const dispatch = useDispatch();
  const {addTrackToQueue} = useQueue();
  const removeDownloadFileDialogRef = useRef(null);
  const { streaming_quality } = useUserData()||{};
  const telegramRequestData = {
    track_id: data?.id,
    quality: streaming_quality,
    is_demo: data?.is_demo,
  };
  const { connectToTelegramLoading, connectToTelegram } = useTelegram();
  const navigate = useNavigate();
  const [isLiked, toggleLikes] = useLike(data?.id);
  const [currentTrack, setPlayerTrack] = usePlayedTrack();
  const { userCollections } = useSelector(selectGlobalData);
  const { activeIndex, inPlayer } = useSelector(selectDropDown);
  const { isSelected, selectItem, deSelectItem } = useSelectTrackItem(
    inPlayer,
    data,
  );
  const [, , , , downloadedItem] = useDownload(data);
  // todo Constant link not should not bee her
  const handleOpenAddToPlayList = () => {
    if (userCollections) {
      dispatch(setActiveIndex({ index }));
      getUserCollectionMutation.mutate();
    } else {
      getUserCollectionMutation.mutate(null, {
        onSuccess: () => {
          dispatch(setActiveIndex({ index }));
        },
      });
    }
  };
  const handleGetBlobFile = async item => {
    const data = await db?.downloadedFiles?.get(item?.id);
    if (data?.blob) return data?.blob;
  };
  const handleSaveFileInDevice = () => {
    handleGetBlobFile(data).then(blob => {
      FileSaver.saveAs(blob, data?.download_title + '.mp3');
      mainToast(
        <div dir="rtl" className="text-center">
          موزیک
          <span className="text-[#ffc000] mx-[2px]   "> {data?.title} </span>
          ذخیره شد.
        </div>,
      );
      closeDropDown();
    });
  };
  const handleToggleShowBugReportDialog = () => {
    dispatch(showBugReportDialog());
  };
  const handleRemoveDownloadFile = async () => {
    if (currentTrack?.id === data?.id) {
      dispatch(resetPlayer());
    }
    await dispatch(removeFromDownloaded({ id: data?.id }));
    await deleteDownloadedFile(data?.id);
    dispatch(showBugReportDialog());
  };
  const handleDropDownItemSelect = e => {
    e?.stopPropagation();
    e?.preventDefault();
    if (itemKey === ContextMenuKeys.REMOVE_FROM_DOWNLOAD) {
      removeDownloadFileDialogRef.current.openDialog();
      dispatch(showBugReportDialog());
    }
    if (itemKey === ContextMenuKeys.SAVE_IN_DEVICE) {
      handleSaveFileInDevice();
    }
    if (itemKey === ContextMenuKeys.SEND_TO_TELEGRAM) {
      connectToTelegram(telegramRequestData, data?.title);
    }
    if (itemKey === ContextMenuKeys.ADD_TO_PLAYLIST) {
      selectItem(data);
      closeDropDown();
    }
    if (itemKey === ContextMenuKeys.ADD_TO_QUEUE) {
     addTrackToQueue(data);
      closeDropDown();
    }
  };
  const getUserCollectionMutation = useMutation(() => getUserCollections(), {
    onSuccess: res => {
      dispatch(
        setUserCollections({
          collections: res?.data?.result?.user_collections,
        }),
      );
    },
  });
  const renderItems = () => {
    if (itemKey === ContextMenuKeys.LIKE_TRACK) {
      return (
        <div
          onClick={toggleLikes}
          className="flex gap-3 lg-1440:gap-r12 items-center w-full"
        >
          <div className="aspect-square h-[20px]  min-[1440px]:h-[1.3889vw]">
            <Icon id={data?.id} fill={'#fff'} />
          </div>
          <p
            style={{ color: index === activeIndex ? '#F2C94C' : '#E5E5E6' }}
            className="fa-format-350  text-[14px] lg-1440:text-f14"
          >
            {isLiked ? secondTitle : title}
          </p>
        </div>
      );
    } else if (itemKey === ContextMenuKeys.SEND_TO_TELEGRAM) {
      return (
        <div className="flex gap-3  items-center ">
          {connectToTelegramLoading ? (
            <div className="aspect-square h-[20px]">
              {<SecondIcon id={data?.id} fill={'#fff'} />}
            </div>
          ) : (
            <div className="aspect-square h-[20px]  ">
              {<Icon id={data?.id} fill={'#fff'} />}
            </div>
          )}
          <p
            style={{ color: index === activeIndex ? '#F2C94C' : '#E5E5E6' }}
            className="fa-format-350  text-[14px] "
          >
            {connectToTelegramLoading ? secondTitle : title}
          </p>
        </div>
      );
    } else if (itemKey === ContextMenuKeys.ADD_TO_QUEUE) {
      return (
        <div className="flex gap-3  items-center ">
          <div className="aspect-square h-[20px]  ">
            <QueueSvg className="w-[20px] h-[20px]" />
          </div>

          <p
            style={{ color: index === activeIndex ? '#F2C94C' : '#E5E5E6' }}
            className="fa-format-350  text-[14px] "
          >
            افزودن به لیست پخش
          </p>
        </div>
      );
    } else
      return (
        <div className="flex gap-3 items-center w-full">
          <div className="aspect-square w-[20px] h-[20px] ">
            <Icon fill={iconColor ? iconColor : '#A7A7A9'} />
          </div>
          <p
            style={{ color: textColor ? textColor : '#E5E5E6' }}
            className="fa-format-350  text-[14px] lg-1440:text-f14"
          >
            {title}
          </p>
        </div>
      );
  };
  return (
    <>
      <ConfirmDialogs
        dialogIcon={<TrashSVG fill={'#EB5757'} />}
        title={'حذف فایل'}
        text={'مطمئن هستید که میخواهیداین فایل را از لیست دانلود ها حذف کنید؟'}
        buttonDirection={'dir-ltr'}
        confirmText={'بلی'}
        confirmTextColor={'text-secondary'}
        confirmBGColor={'bg-primary'}
        confirmBorderColor={'transparent'}
        confirmAction={handleRemoveDownloadFile}
        cancelText={'خیر'}
        cancelAction={handleToggleShowBugReportDialog}
        cancelTextColor={'text-primary'}
        cancelBGColor={'bg-transparent'}
        cancelBorderColor={'border-primary'}
        ref={removeDownloadFileDialogRef}
      />

      <div
        onClick={e => handleDropDownItemSelect(e)}
        className="flex w-full justify-between py-[14px] 1 items-center px-[21px]  cursor-pointer"
      >
        {renderItems()}
      </div>
    </>
  );
}

export default DropDownItem;
