import React, { useRef, useState } from 'react';
import BottomSheetHeader from '../../bottomSheets/BottomSheetHeader.jsx';

import { CardActionArea } from '@mui/material';
import SwipeBottomSheet from '../../bottomSheets/SwipeBottomSheet.jsx';
import {
  MELOPOST_OWNER_OPTIONS,
  MELOPOST_VIEWER_OPTIONS,
} from './EditMelopostOptions.jsx';
import { useNavigate } from 'react-router-dom';
import ConfirmDialogs from '../../dialogs/ConfirmDialogs.jsx';
import TrashType2Svg from '../../svg/playList/TrashType2Svg.jsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteMelopost, melopostUnlike } from '../../../services/ApiClient.js';
import { NoteMusicLoadingContainer } from '../../loading/NoteMusicLoadingContainer.jsx';
import { SOCIAL_REPORT_TYPES } from '../../../consts/SocialReportTypes.js';

export const EditMelopostBottomsheet = ({
  open,
  setOpen,
  handleCloseBottomSheet,
  isUserSocialProfile,
  selectedMelopost,
  refetch,
}) => {
  const navigate = useNavigate();
  const [deleteLoading,setDeleteLoading]=useState(false)
  const toggleBottomSheetState = newOpen => () => {
    setOpen(newOpen);
  };

  const handleClickOptions = item => {
    if (!isUserSocialProfile && item?.id === 1) {
      navigate(`/profile/${selectedMelopost?.social_profile?.token}`);
    } else if (!isUserSocialProfile && item?.id === 2) {
      navigate('/melopost-report',{state:{modelId:selectedMelopost?.id,modelType:SOCIAL_REPORT_TYPES.MELOPOST}})
    } else if (item?.id === 1) {
        navigate('/melopost/upload',{state:{isEdit:true,melopostItem:selectedMelopost}})
    } else if (item?.id === 2) {
      deleteMelopostRef?.current?.openDialog()
      return
    }
    handleCloseBottomSheet();
  };
  const handleDeleteMelopost = () => {
    setDeleteLoading(true)
    deleteMelopostMutation.mutate()
  }
  const deleteMelopostRef = useRef(null)
  const deleteMelopostMutation = useMutation(() => deleteMelopost({id:selectedMelopost?.id}), {
    onSettled:()=>{
      setDeleteLoading(false)
      refetch()
    },
    onSuccess:(response)=>{
      if(response?.data?.done){
        handleCloseBottomSheet();
      }
    }
  });
  const list = isUserSocialProfile
    ? MELOPOST_OWNER_OPTIONS
    : MELOPOST_VIEWER_OPTIONS;

  return (
    <>
      <ConfirmDialogs
        dialogIcon={<TrashType2Svg style={'!w-full !h-full'} stroke={'#EB5757'}/>}
        // title={"حذف"}
        text={`آیا از حذف ملوپست خود اطمینان دارید؟`}
        buttonDirection={"dir-rtl"}
        confirmText={"حذف"}
        confirmTextColor={"text-white"}
        confirmBGColor={"bg-red !border-transparent"}
        confirmBorderColor={""}
        confirmAction={handleDeleteMelopost}
        cancelText={"لغو"}
        cancelTextColor={"text-white"}
        cancelBGColor={"bg-transparent"}
        cancelBorderColor={"border-red"}
        isDeleteDialog={true}
        ref={deleteMelopostRef}
      />
      <SwipeBottomSheet
        keepMount={false}
        open={open}
        toggleDrawer={toggleBottomSheetState}
      >
        <BottomSheetHeader
          dividerLine={true}
          closeBottomSheet={handleCloseBottomSheet}
          title="گزینه ها"
        />

        <div dir="rtl" className=" w-full h-full flex flex-col relative">
          {deleteLoading?


              <NoteMusicLoadingContainer className='!bg-primary_dark !bg-opacity-50' />

            :
            null
          }
          {list?.map(item => (
            <CardActionArea
              onClick={() => handleClickOptions(item)}
              key={item?.id}
              className="!p-4"
            >
              <div className=" flex flex-row justify-between items-center gap-2">
                <div className=" flex flex-row justify-between items-center gap-2">
                  {item?.icon}
                  <span
                    style={{ color: item?.textColor }}
                    className="font-dana font-medium text-sm "
                  >
                  {item?.title}
                </span>
                </div>
              </div>
            </CardActionArea>
          ))}
        </div>
      </SwipeBottomSheet>

    </>

  );
};

