import React from 'react';
import HomeMetaDataWithTitle from '../common/HomeMetaDataWithTitle';
import HomeGeneralCollectionSlider from './HomeGeneralCollectionSlider.jsx';
import HomeGeneralCollectionsBanner from './HomeGeneralCollectionsBanner.jsx';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage.jsx';

function HomeGeneralCollectionSection({ item }) {
  return (
    <div className="z-20 ">
      <div dir="rtl" className="flex flex-col gap-1 bg-background ">
        {/* <div className="w-full  pr-3">
          <HomeMetaDataWithTitle
            listData={item?.general_collections_items}
            metaData={item?.general_collections_meta_data}
          />
        </div> */}
        <div className="flex flex-row  relative">
          <div className="flex justify-center items-center pl-4">
            <div className="bg-secondary h-[52px] !w-[56px] rounded-l-[8px] flex flex-col justify-center items-center  gap-1 ">
              <CustomLazyLoadImage
                imageClass="!static !w-[20px] !h-[20px]"
                image={item?.element_meta?.icon}
              />
              <p
                style={{
                  color: item?.element_meta?.title_color,
                }}
                className=" font-dana text-[10px] font-[500] "
              >
                {item?.element_meta?.title}
              </p>
            </div>
          </div>
          <div className="w-[calc(100%_-_64px)] pt-2">
            <HomeGeneralCollectionSlider item={item?.element_data} />
          </div>

          {/* {item?.general_collections_items[1]?.banner && (
            <div className="pr-4 pl-4">
              <HomeGeneralCollectionsBanner
                banner={item?.general_collections_items[1]?.banner}
              />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default React.memo(HomeGeneralCollectionSection);
