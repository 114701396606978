import Container from '../../components/PageParentContainer/Container.jsx';
import CommonHeader from '../../components/common/CommonHeader.jsx';
import MeloPostIcon from '../../assets/meloPost/melo-post-main-icon.svg';

import CustomImage from '../../components/common/CustomImage.jsx';
import { CreateMeloPostOptions } from '../../components/meloPost/CreateMeloPostOptions';
import { getInitialData } from '../../helper/ReduxSelectorContext.js';
import { useEffect } from 'react';
import { dispatchIsPlaying } from '../../redux/globalstore/GlobalStore.js';

export const MeloPost =()=>{
  const initialData =  getInitialData()
  const melopostSettings = initialData?.melopost_settings

  useEffect(()=>{
    dispatchIsPlaying(false);
  },[])

  return (
    <Container
      style=" bg-primary_dark p-4"
      header={<CommonHeader title="ملوپست جدید" />}
    >
      <div dir='rtl' className='flex flex-col pt-[58px] w-full items-center gap-9'>
        <div className='flex flex-col gap-[30px] items-center'>
          <CustomImage
            appendBaseUrl={false}
            className={`size-[70px]`}
            src={MeloPostIcon}
          />
          <p className="text-[16px] font-dana font-[700] text-gray_5">
            {melopostSettings?.title}
          </p>
        </div>
         <CreateMeloPostOptions/>
      </div>

    </Container>
  )
}
