import React from 'react';
import { useNavigate } from 'react-router-dom';
import SettingIconSvg from '../svg/myMelodify/SettingIconSvg';
import { useSelector } from 'react-redux';
import { selectInitialData } from '../../redux/initialize/InitializeSlice.js';
import BellIconSvg from '../svg/common/BellIconSvg.jsx';
import HeaderSettingIcon from '../svg/common/HeaderSettingIcon.jsx';
import {
  getInitialData,
  useUserData,
} from '../../helper/ReduxSelectorContext.js';
import logo from '../../assets/logo/melodify-logo-by-text.webp';
import CustomImage from './CustomImage.jsx';
import ArtistProfilePlaceHolderSvg from '../svg/common/ArtistProfilePlaceHolderSvg.jsx';
import UserAvatar from './UserAvatar.jsx';

export const HeaderActionSection = ({ props }) => {
  const userData = useUserData();

  console.log('SDfdsgsdgsdgdsg', userData);

  const isUserPremium = userData?.is_premium;
  const userAvatar = userData?.social_avatar;
  const socialToken = userData?.social_token;
  const navigate = useNavigate();
  const openTickets = () => {
    navigate('/system-notification');
  };
  const openSettings = () => {
    navigate('/setting');
  };

  return (
    <div className="flex  items-center gap-4 ">
      <div className="relative" onClick={openTickets}>
        {userData?.has_in_app_notifications ? (
          <div className="w-2 h-2 rounded-full bg-red absolute -top-1 -right-1"></div>
        ) : null}
        <BellIconSvg />
      </div>
      <div onClick={openSettings} className="flex size-5 min-w-5">
        <HeaderSettingIcon />
      </div>
      <UserAvatar
        is_premium={isUserPremium}
        avatar={userAvatar}
        social_token={socialToken}
        premium_crown
      />
    </div>
  );
};
