import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import GrayPauseIcon from '../svg/common/GrayPauseIcon';
import GrayPlayIcon from '../svg/common/GrayPlayIcon';
import ClickReaction from '../../helper/ClickReaction';
import { dispatchIsPlaying } from '../../redux/globalstore/GlobalStore';
import {
  convertTime,
  convertTimeMiliSecond,
} from '../../helper/ConvertPlayerTIme';
import { TogglePlayGrayButton } from '../common/TogglePlayGrayButton.jsx';
import { createReelCdnLink } from '../../helper/createMusicCdnLink.js';
import {
  getIsPlaying,
  getUserToken,
  useUserData,
} from '../../helper/ReduxSelectorContext.js';
import { usePlayedTrack } from '../../hooks/usePlayedTrack.js';
import { PlayListType } from '../../consts/PlayListType.js';
import {
  selectSeekTime,
  setSeekTime,
} from '../../redux/player/PlayMusicSlice.js';
import { useSelector, useDispatch } from 'react-redux';
import { GetPlayerRef } from '../../helper/GetPlayerRef.jsx';

export const MeloPostPlayer = ({
  item,
  index,
  onPlay,
  activePlayerIndex,
  hasDuration = false,
  playerButtonClassName = 'w-[40px] h-[40px] min-w-[40px]',
  playlist = [],
}) => {
  const [currentTrack, setPlayerTrack] = usePlayedTrack(false);
  let seekTime = useSelector(selectSeekTime);

  let isPlaying = getIsPlaying();
  const dispatch = useDispatch();
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);

  const [getPlayer] = GetPlayerRef();
  const mainPlayer = getPlayer();

  const userData = useUserData();
  const userToken = getUserToken();

  useEffect(() => {
    if (!waveformRef) return;
    wavesurferRef.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: '#F2F2F2',
      progressColor: '#F2C94C',
      barWidth: 1.3,
      barGap: 3,
      height: 28,
      peaks: [item?.amplitude],
      duration: item?.duration || 30,
      cursorWidth: '0',
      hideScrollbar: true,
      normalize: true,
      interact: currentTrack?.id === item?.id,
      // url: createReelCdnLink(item?.url, userData, userToken),
      autoplay: false,
      responsive: true,
    });

    // Load waveform based on peaks (no audio file needed)
    wavesurferRef.current.load('', item?.amplitude, item?.amplitude.length);

    wavesurferRef.current.on('click', relativeX => {
      const durationMs = wavesurferRef.current.getDuration();
      const currentTime = (durationMs / 1000) * relativeX; // Convert duration to seconds

      dispatch(setSeekTime({ seekTime: currentTime }));

      if (mainPlayer) {
        mainPlayer.currentTime = currentTime; // HTML audio expects seconds
      }

      wavesurferRef.current.seekTo(relativeX); // normalized value (0 to 1)
    });
    // Cleanup listener on unmount
    return () => {
      wavesurferRef.current.destroy();
    };
  }, [item, currentTrack]);

  const handlePlay = () => {
    setPlayerTrack(item, playlist, PlayListType.PLAYLIST, false, null, true);

    // handlePlayPause();
    // if (activePlayerIndex === index) {
    //   wavesurferRef.current.play();
    // } else {
    //   onPlay(index);
    // }
    // setIsPlaying(true);
  };

  const handlePause = () => {
    dispatchIsPlaying(false);
  };

  useEffect(() => {
    if (currentTrack?.id!==item?.id)return;
      if (wavesurferRef.current) {
        const durationMs = wavesurferRef.current.getDuration(); // duration in milliseconds
        const durationSec = durationMs / 1000; // convert to seconds
        console.log('duration (seconds):', durationSec);
        console.log('seekTime (seconds):', seekTime);

        if (durationSec) {
          wavesurferRef.current.seekTo(seekTime / durationSec);
        }
      }
  }, [seekTime]);

  return (
    <div className="flex flex-col w-full gap-1 items-end">
      <div className="w-full flex flex-row gap-2 items-center">
        <TogglePlayGrayButton
          className={playerButtonClassName}
          isPlaying={currentTrack?.id === item.id && isPlaying}
          startPlay={handlePlay}
          pausePlay={handlePause}
        />
        <div
          ref={waveformRef}
          className="w-full h-full flex flex-col overflow-scroll"
          id={`waveform-${index}`}
        ></div>
      </div>
      {hasDuration ? (
        <div className="flex flex-row justify-between items-center w-[calc(100%_-_50px)]">
          <span className="text-gray_3 text-[10px] fa-format-500">
            {convertTime(seekTime)}
          </span>
          <span className="text-gray_3 text-[10px] fa-format-500">
            {convertTimeMiliSecond(item?.duration)}
          </span>
        </div>
      ) : null}
    </div>
  );
};
