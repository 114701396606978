import React, { useEffect, useState } from 'react';
import { MeloPostHomeCardItem } from '../meloPost/MeloPostHomeCardItem';
import { useInView } from 'react-intersection-observer';
import { IsIosDevice } from '../../helper/CheckDevice';
import { FixedSizeList as List } from 'react-window';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FreeMode } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';

export const HomeMelopostContainer = ({ item }) => {
  const [activePlayerIndex, setActivePlayerIndex] = useState(null);

  const handlePlay = index => {

    if (activePlayerIndex === index) {
      setActivePlayerIndex(null); // If the same item is clicked again, stop it
    } else {
      setActivePlayerIndex(index); // Set active player to the clicked item
    }
  };
  const Column = ({ data, index, style }) => (
    <div style={{ ...style, right: style.right + 16 + 'px' }}>
      <MeloPostHomeCardItem
        key={`${data.id}-melopost`}
        item={data[index]}
        index={index}
        onPlay={handlePlay}
        activePlayerIndex={activePlayerIndex}
        playlist={data}
      />
    </div>
  );
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: [0, 1],
  });

  useEffect(() => {
    console.log(inView, 'ref is here list');
    return () => {};
  }, [inView]);

  return (
    <div dir="ltr" className="flex flex-col">
      {IsIosDevice() ? (
        <div ref={ref} className="flex flex-col ">
          <div>
            {inView ? (
              <List
                direction="rtl"
                height={175}
                itemCount={item?.element_data?.length}
                itemSize={258}
                layout="horizontal"
                width={400}
                style={{ width: '100% !important' }}
                itemData={item?.element_data}
                className="!pr-[17px] "
              >
                {Column}
              </List>
            ) : (
              <div className="h-[189px] w-full"></div>
            )}
          </div>
        </div>
      ) : (
        <div dir="rtl" className="flex flex-col ">
          <div className="slider">
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={16}
              // lazy={true}
              modules={[FreeMode]}
              freeMode={true}
              className="!w-full !h-fit flex flex-col-reverse !select-none new-song-swiper  !overflow-visible"
              cssMode={IsIosDevice()}
            >
              {item?.element_data?.map((melo_post, index) => (
                <div className="" key={index}>
                  <SwiperSlide
                    className="!w-fit last:!pl-4 first:!pr-4"
                    key={index}
                  >
                    <div className="flex flex-col ">
                      {/* <!-- persian --> */}
                      <MeloPostHomeCardItem
                        item={melo_post}
                        index={index}
                        onPlay={handlePlay}
                        activePlayerIndex={activePlayerIndex}
                        playlist={item?.element_data}
                      />
                    </div>
                  </SwiperSlide>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
};
