import React from 'react';
import { BellSvg } from '../svg/notification/BellSvg';

export const EmptyNotifeView = ({ title = ' شما هیچ اعلانی ندارید!' }) => {
  return (
    <div className="flex w-full h-full  flex-col justify-center items-center gap-4   mt-8">
      <BellSvg />
      <span
        className={'text-gray_2 font-[600] font-dana text-[20px] leading-6  '}
      >
        {title}
      </span>
    </div>
  );
};
