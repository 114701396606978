import React from 'react';
import { createReelCdnLink } from '../../helper/createMusicCdnLink';
import { getUserToken, useUserData } from '../../helper/ReduxSelectorContext';
import { ReelPlayer } from './player/ReelPlayer';

export const ReelPlayerCard = ({ item, isActive, setMute, mute }) => {
  return (
    <div className=" w-full h-full flex flex-row justify-center items-center relative overflow-hidden">
      {isActive ? (
        <ReelPlayer
          item={item}
          isActive={isActive}
          setMute={setMute}
          mute={mute}
        />
      ) : null}
    </div>
  );
};
