import React from 'react';
import { useAction } from '../../hooks/useAction';
import { HomeSectionBackgroundOverlay } from './HomeSectionBackgroundOverlay';

export const ATitle = ({ item }) => {
  const action = useAction();
  const actionData = () => {
    console.log('item...',item);
    if (item?.element_meta?.show_more_action?.type === 'open_album') {
      return item?.element_meta?.show_more_action;
    }
    //TODO CHECK THIS ALIREZA AGHAEE
    if (
      item?.element_meta?.show_more_action?.target_type === 'exclusive_tracks'
    ) {
      return item?.element_meta?.show_more_action
    }
    if (item?.element_meta?.show_more_action === 'home_collection') {
      return item?.element_meta?.show_more_action;
    } else {
      return item?.element_meta?.show_more_action;
    }
  };

  const handleAction = () => {
    action(actionData());
  };

  return (
    <div className="w-full flex justify-between items-center px-4 relative">
      <HomeSectionBackgroundOverlay
        background_color={item?.element_meta?.background_color}
      />
      <div className="static z-20">
        {item?.element_meta?.show_more_visibility ? (
          <div
            onClick={() =>
              item?.element_meta?.show_more_visibility && handleAction()
            }
            className="flex flex-row items-center justify-center gap-1 cursor-pointer "
          >
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.174892 5.57199C-0.0582977 5.80518 -0.0582977 6.19477 0.174892 6.42856L5.5554 11.8229C5.79157 12.059 6.17459 12.059 6.41017 11.8229C6.64635 11.5867 6.64635 11.2031 6.41017 10.9669L1.45709 6L6.41077 1.03371C6.64695 0.79693 6.64695 0.413911 6.41077 0.177133C6.17459 -0.0590461 5.79157 -0.059046 5.55599 0.177133L0.174892 5.57199Z"
                fill="#BDBDBD"
              />
            </svg>

            <p
              style={{
                color: item?.element_meta?.show_more_color,
                fontSize: item?.element_meta?.show_more_font_size,
              }}
              className=" font-dana font-[500] "
            >
              {item?.element_meta?.show_more_text}
            </p>
          </div>
        ) : null}
      </div>
      <div className=" flex flex-row items-center justify-center gap-2 static z-20">
        <p
          style={{
            color: item?.element_meta?.subtitle_color,
            fontSize: item?.element_meta?.subtitle_font_size,
          }}
          className=" font-kalameh font-[500] "
        >
          {item?.element_meta?.subtitle}
        </p>
        <p
          style={{
            color: item?.element_meta?.title_color,
            fontSize: item?.element_meta?.title_font_size,
          }}
          className=" font-kalameh font-[600] "
        >
          {item?.element_meta?.title}
        </p>
      </div>
    </div>
  );
};
