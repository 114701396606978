import AboutSvg from "../components/svg/setting/AboutSvg";
import BroadcastQualitySvg from "../components/svg/setting/BroadcastQualitySvg";
import DownloadQualitySvg from "../components/svg/setting/DownloadQualitySvg";
import FreePremiumSvg from "../components/svg/setting/FreePremiumSvg";
import HeadsetSvg from "../components/svg/setting/HeadsetSvg";
import SendAppSvg from "../components/svg/setting/SendAppSvg";
import TelegramSvg from "../components/svg/setting/TelegramSvg";
import ManageActiveAccount from "../components/svg/setting/manageActiveAccount";
import ExitSvg from '../components/setting/ExitSvg.jsx';

const settingItems = [
  {
    icon: <HeadsetSvg />,

    label: "درخواست پشتیبانی",
    link: "/tickets",
    key: "supportRequest",
  },
  {
    icon: <TelegramSvg />,
    label: "اتصال به تلگرام من",
    link: "/setting/connect-to-telegram",
    key: "connectTelegram",
  },
  {
    icon: <BroadcastQualitySvg />,
    label: "کیفیت پخش",
    link: null,
    key: "broadcastQuality",
  },
  {
    icon: <DownloadQualitySvg />,
    label: "کیفیت دانلود",
    link: null,
    key: "downloadQuality",
  },
  {
    icon: <FreePremiumSvg />,
    label: "حساب ویژه رایگان",
    link: "free-premium",
    key: "freePremium",
  },
  {
    icon: <ManageActiveAccount />,
    label: "مدیریت دستگاه های فعال",
    link: "/setting/manage-devices",
    key: "manageActiveAccount",
  },
  {
    icon: <AboutSvg />,
    label: "درباره",
    link: "/about-us",
    key: "about",
  },
  {
    icon: <SendAppSvg />,
    label: "ارسال اپلیکیشن",
    link: null,
    key: "sendApp",
  },
  {
    icon:  <div className=" w-[18px] h-[18px]"><ExitSvg  stroke='#EB5757' /></div>,
    label: "خروج از حساب کاربری",
    link: null,
    key: "logout",
  },
];
export default settingItems;
