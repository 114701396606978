import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import React, { useEffect } from 'react';
import { BottomNavigationItems } from '../../consts/BottomNavigationItems';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BottomNavigationTabs } from '../../consts/BottomNavigationInvisibleRoutes';
import { getInitialData } from '../../helper/ReduxSelectorContext';
import CustomImage from '../common/CustomImage';

function BottomNavigationComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const initialData = getInitialData();
  const [activeItem, serActiveItem] = useState(
    BottomNavigationTabs[location.pathname]?.id,
  );

  useEffect(() => {
    const path = location?.pathname?.split('/')[1];
    if (path?.startsWith(BottomNavigationTabs[path]?.route)) {
      serActiveItem(BottomNavigationTabs[path]?.id);
    } else {
      serActiveItem(null);
    }
  }, [location, activeItem]);
  const handleNavigateTab = item => {
    if (location.pathname === item.link) {
    } else {
      navigate(item.link);
    }
  };

  const getThumbImage = item => {
    return initialData?.navbar_items?.filter(f => f?.type === item?.type)?.[0]
      ?.image;
  };
  return (
    <div className="w-[101%]  bg-primary_dark   fixed bottom-0 max-w-[520px] left-[50%] translate-x-[-50%] z-[1]  button-navigation-position">
      <Box>
        <BottomNavigation
          elevation={5}
          showLabels
          className={` focus:bg-gray3  button-navigation !bg-primary_dark !h-[66px]  !flex  !w-full `}
          value={activeItem}
          onChange={(event, selectedItem) => {
            serActiveItem(selectedItem);
          }}
        >
          {BottomNavigationItems.map((item, index) => (
            <BottomNavigationAction
              key={index}
              sx={{ width: '60px' }}
              className={`!rounded-md  !min-w-[40px] !max-w-[60px] !py-[5px] !px-[calc((100%-300px)/10)] !box-content ${
                index === 0 && '  !rounded-tl-[0px] !rounded-bl-[0px]'
              } ${
                index === BottomNavigationItems?.length - 1 &&
                '  !rounded-tr-[0px] !rounded-br-[0px]'
              }`}
              onClick={e => handleNavigateTab(item)}
              icon={
                <div className="relative">
                  {initialData?.unread_ticket_count && item?.id === 0 ? (
                    <div className="w-2 h-2 absolute bg-red rounded-full -right-[6px] top-[6px]"></div>
                  ) : (
                    ''
                  )}
                  {item.icon({
                    color: activeItem === item.id ? '#F2C94C' : '#828282',
                  })}
                  {getThumbImage(item) ? (
                    <div className="absolute  -left-4 top-[50%] translate-y-[-50%] z-10 ">
                      <CustomImage src={getThumbImage(item)} />
                    </div>
                  ) : null}
                </div>
              }
              label={
                <p
                  className={` whitespace-nowrap font-dana  text-[0.8rem] text-center   ${
                    activeItem === item.id ? ' text-primary' : 'text-gray_3'
                  } `}
                >
                  {item.name}
                </p>
              }
            />
          ))}
        </BottomNavigation>
      </Box>
    </div>
  );
}

export default React.memo(BottomNavigationComponent);
