import React from 'react';
import HomeMetaDataWithTitle from '../common/HomeMetaDataWithTitle';
import HomeArtistSlider from './HomeArtistSlider';

function HomeArtistsView({ item }) {
  console.log('Asfasfsafasfasf', item);

  return (
    <div className="w-full   bg-background z-20">
      {/* <div className="w-full   pr-3    z-10  mt-2 relative">
        <HomeMetaDataWithTitle
          metaData={item?.artist_meta_data}
          eventClassName={{
            prev: 'prev-button-artist',
            next: 'next-button-artist',
          }}
        />
      </div> */}

      <div className="flex flex-col w-full  gap-[14px] ">
        <HomeArtistSlider
          key={'HomeArtistSlider'}
          item={item?.element_data}
        />
      </div>
    </div>
  );
}

export default HomeArtistsView;
