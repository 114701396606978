import React from 'react';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';

function PlayerSwipeContainer({
  openBottomSheet,
  toggleSheet,
  children,
  zIndex = '!z-[100]',
}) {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={openBottomSheet}
      onClose={toggleSheet}
      onOpen={toggleSheet}
      swipeAreaWidth={70}
      className={`!relative  ${zIndex}`}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '520px',
          height: '100%',
          margin: 'auto',
          borderRadius: 0,
          backgroundColor: '#171717',
          // borderRadius: "20px",
        },
      }}
      transitionDuration={{
        enter: 200,
        exit: 200,
        appear: 1000,
      }}
      disableSwipeToOpen={true}
      ModalProps={{
        keepMounted: true,
      }}
    >
      {/* Content of the bottom sheet */}
      {children}
    </SwipeableDrawer>
  );
}

export default PlayerSwipeContainer;
