import React from 'react';

function MeloPostMicIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="41"
      fill="none"
      viewBox="0 0 27 41"
    >
      <path
        fill="#F2C94C"
        d="M25.512 21.781c-.664 0-1.201.538-1.201 1.201 0 5.961-4.85 10.811-10.811 10.811-5.96 0-10.81-4.85-10.81-10.81a1.201 1.201 0 0 0-2.403 0c0 6.88 5.287 12.548 12.012 13.157v2.458H8.695a1.201 1.201 0 0 0 0 2.402h9.61a1.201 1.201 0 0 0 0-2.402H14.7V36.14c6.725-.609 12.012-6.277 12.012-13.158 0-.663-.538-1.2-1.201-1.2"
      ></path>
      <path
        fill="#F2C94C"
        d="M13.5 0C8.864 0 5.092 3.772 5.092 8.408v14.574c0 4.637 3.772 8.409 8.408 8.409s8.408-3.772 8.408-8.409V8.408C21.908 3.772 18.136 0 13.5 0m6.006 22.982a6.013 6.013 0 0 1-6.006 6.006 6.013 6.013 0 0 1-6.006-6.006V8.408A6.013 6.013 0 0 1 13.5 2.402a6.013 6.013 0 0 1 6.006 6.006z"
      ></path>
    </svg>
  );
}

export default MeloPostMicIcon;
