import React, { useEffect, useState } from 'react';
import { useUserPlaylists } from '../../../hooks/useUserPlaylists.js';
import { PrivateProfileView } from '../../userProfle/PrivateProfileView.jsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import SongListLoading from '../../loading/SongListLoading.jsx';
import NoUserPlaylists from '../../MyMelodify/NoUserPlaylists.jsx';
import { VirtuosoGrid } from 'react-virtuoso';
import CategoryCard from '../../playlist/CategoryCard.jsx';
import UserAvatar from '../UserAvatar.jsx';
import UserPlaylistList from '../../MyMelodify/UserPlaylistList.jsx';
import { useAction } from '../../../hooks/useAction.js';

const PlaylistGridItem = ({ item }) => (
  <div className="flex flex-col gap-2">
    <div className="!w-full !h-full">
      <CategoryCard fromPlaylist item={item} scale={true} />
    </div>
    <div className="flex flex-col gap-1">
      <span className="text-white font-normal font-dana text-xs">
        {item?.title}
      </span>
      <div className="flex flex-row gap-1 items-center">
        <UserAvatar
          avatar={item?.social_profile?.avatar}
          is_premium={item?.social_profile?.is_premium}
          className="!w-[20px] !h-[20px]"
        />
        <span className="text-gray_4 font-normal font-dana text-[10px]">
          {item?.social_profile?.name}
        </span>
      </div>
    </div>
  </div>
);
export const UserPlaylistRender = ({
  social_token,
  isGrid,
  scrollableTarget,
  shimmer,
}) => {
  const [loaded, setLoaded] = useState(false);
  const action = useAction();
  const {
    isPrivate,
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
  } = useUserPlaylists(social_token);

  const playlists = data?.pages?.flatMap(
    page => page.data.result.user_collections,
  );
  const handleLoadMore = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };
  useEffect(() => {
    setLoaded(true);
  });
  if (isPrivate) {
    return <PrivateProfileView className="mt-8" />;
  }

  const handleOpenUserPlaylist = collection => {
    const actionData = {
      target_type: 'open_user_playlist',
      playlistData: collection,
    };
    action(actionData);
  };
  return (
    <InfiniteScroll
      className="!overflow-hidden"
      next={handleLoadMore}
      hasMore={hasNextPage}
      dataLength={playlists?.length || 0}
      loader={
        isFetchingNextPage && (
          <div className="w-full flex justify-center items-start h-[50px]">
            <SongListLoading />
          </div>
        )
      }
      scrollableTarget={scrollableTarget}
    >
      {shimmer && isLoading ? (
        shimmer
      ) : playlists?.length === 0 ? (
        <div className="flex flex-col gap-4 justify-center items-center mt-8">
          <NoUserPlaylists />
          <span className="text-gray_3 font-medium font-dana text-[16px] leading-6">
            هنوز هیچ پلی‌لیستی ایجاد نکرده‌اید!
          </span>
        </div>
      ) : isGrid ? (
        <div className="w-full h-full !overflow-hidden mt-3 pb-28">
          <VirtuosoGrid
            id="container"
            dir="rtl"
            style={{ overflowY: 'hidden !important' }}
            customScrollParent={document.getElementById(scrollableTarget)}
            data={playlists}
            listClassName="!grid !grid-cols-2 !gap-4 !w-full !mt-4"
            itemContent={(index, item) => (
              <div onClick={() => handleOpenUserPlaylist(item)}>
                <PlaylistGridItem key={index} item={item} />
              </div>
            )}
          />
        </div>
      ) : (
        <div className="w-full h-full !overflow-hidden">
          <UserPlaylistList
            collections={playlists}
            targetId={scrollableTarget}
          />
        </div>
      )}
    </InfiniteScroll>
  );
};
