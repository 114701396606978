import React from 'react';
import clsx from 'clsx'
import CloseHashtagIcon from '../../svg/common/CloseHashtagIcon.jsx';
export const HashtagItem = ({ onSelect,isSelected,title,viewCount,hasDeleteIcon,onDelete}) => {
  return (
    <div
      dir='rtl'
      onClick={onSelect}
      className={clsx(`flex items-center bg-primary_dark w-fit
       rounded-[100px] gap-[5px] px-4 h-[32px] border border-[1px] `
        ,isSelected?' border-primary':'border-transparent')}>
      {hasDeleteIcon?
        <div className='w-4 h-4 min-w-4' onClick={onDelete}>
             <CloseHashtagIcon/>
        </div>
      :null
      }

      <div className=' flex items-center gap-4'>
        <div className='flex '>
          <p className={clsx(`text-[14px] fa-format-500  `,isSelected?'text-primary':'text-gray_3')}>
            #
          </p>
          <p className="text-[14px] fa-format-500 text-gray_3 ">
            {title}
          </p>
        </div>

        <p className="text-[12px] fa-format-500 text-gray_3 ">
          {viewCount}
        </p>
      </div>
 <div>

 </div>
  </div>);
};

