import React from 'react';
import CustomLazyLoadImage from './CustomLazyLoadImage';
import { convertAndroidARGBtoWebRGBA } from '../../helper/utils';
import { HomeSectionBackgroundOverlay } from './HomeSectionBackgroundOverlay';

export const CTtitle = ({ item }) => {
  console.log('asfasfsafasfasfafas', item?.element_meta?.background_color);

  return (
    <div className="w-full flex flex-row justify-end items-center gap-3 p-4 relative">
       <HomeSectionBackgroundOverlay
            background_color={item?.element_meta?.background_color}
          />
      <div className=" flex flex-col items-end gap-1 static z-20">
        <p
          style={{
            color: item?.element_meta?.title_color,
            fontSize: item?.element_meta?.title_font_size,
          }}
          className=" font-kalameh font-[600] "
        >
          {item?.element_meta?.title}
        </p>

        <p
          style={{
            color: item?.element_meta?.subtitle_color,
            fontSize: item?.element_meta?.subtitle_font_size,
          }}
          className=" font-dana font-[500] "
        >
          {item?.element_meta?.subtitle}
        </p>
      </div>
      <div className="w-[56px] h-[56px]">
        <CustomLazyLoadImage
          imageClass="!static"
          image={item?.element_meta?.icon}
        />
      </div>
    </div>
  );
};
