import React from "react";

function CollectionCardPlaceHolder({ scale = true, children }) {
  return (
    <div
      className={`overflow-hidden rounded-lg ${
        scale ? "h-full" : "h-[130px]"
      } relative`}
    >
      {/* Define the clip-path in an SVG */}
      <svg className="absolute w-0 h-0">
        <defs>
          <clipPath id="clipPathId" clipPathUnits="objectBoundingBox">
            <path d="M0.707 0C0.674 0 0.648 0.029 0.635 0.061C0.612 0.113 0.56 0.15 0.5 0.15C0.44 0.15 0.388 0.113 0.365 0.061C0.352 0.029 0.326 0 0.293 0H0.061C0.027 0 0 0.027 0 0.061V0.939C0 0.973 0.027 1 0.061 1H0.939C0.973 1 1 0.973 1 0.939V0.061C1 0.027 0.973 0 0.939 0H0.707Z" />
          </clipPath>
        </defs>
      </svg>

      {/* Background shape */}
      <svg
        viewBox="0 0 130 130"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute inset-0 w-full h-full"
        preserveAspectRatio="none"
      >
        <path
          fill="transparent"
          d="M90 67.5a2.5 2.5 0 00-2.5-2.5h-35a2.5 2.5 0 000 5h35a2.5 2.5 0 002.5-2.5z"
        />
      </svg>

      {/* Clipped content */}
      <div
        className="absolute inset-0 w-full h-full"
        style={{ clipPath: "url(#clipPathId)" }}
      >
        {children}
      </div>
    </div>
  );
}

export default CollectionCardPlaceHolder;
