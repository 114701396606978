import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useVoiceVisualizer } from "react-voice-visualizer";
import { useLocation, useNavigate } from 'react-router-dom';
import { ADD_NEW_MELOPOST_VALIDATION_SCHEMA } from '../consts/Validation.js';
import {
  attachFileToMelopost,
  completeMelopost,
  createMelopost,
  editMelopost,
  getSuggestedHashtags,
} from '../services/ApiClient.js';
import { useNativeBack } from './useNativeBack.js';
import { getInitialData } from '../helper/ReduxSelectorContext.js';




let controller = new AbortController();
export const useSubmitMeloPost = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const initialData =  getInitialData()
  const melopostSettings = initialData?.melopost_settings
  const melopostFile = location?.state?.file;
  const melopostItem = location?.state?.melopostItem;
  const isEditMelopost = location?.state?.isEdit;
  const [openHashtag, setOpenHashtag] = useState(false);
  const [progressDialog,setProgressDialog] = useState(false);
  const [editLoading,setEditLoading] = useState(false);
  const [selectedHashtags, setSelectedHashtags] = useState([]);
  const [createHashtagLimitStatus, setCreateHashtagLimitStatus] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [uploadError,setUploadError] = useState(null)
  const navigate = useNavigate()
  useEffect(() => {
    if (melopostFile) {
      setPreloadedAudioBlob(melopostFile);
    }
  }, [melopostFile]);

  const recorderControls = useVoiceVisualizer();
  const {
    setPreloadedAudioBlob,
    isPausedRecordedAudio,
    togglePauseResume,
    formattedDuration,
    currentAudioTime,
    duration,
    isProcessingRecordedAudio
  } = recorderControls;

  const formik = useFormik({
    initialValues: {
      title: "",
      caption: "",
      hashtags: [],
    },
    enableReinitialize: true,
    validationSchema: ADD_NEW_MELOPOST_VALIDATION_SCHEMA,
    validateOnChange: false,
    onSubmit: (data) => {
      if (loading) {
        return;
      }

      const hashtagsArray = data?.hashtags.map(hashtag => hashtag?.title);
      if(isEditMelopost){
        setEditLoading(true)
        editMelopostMutation.mutate({ id:melopostItem?.id,...data,hashtags:hashtagsArray });
      }else {
        setLoading(true);
        setProgressDialog(true)
        setUploadError(false)
      createMelopostMutation.mutate({ ...data,hashtags:hashtagsArray });


      }
    },
  });

  const handlePercent = (event) => {
    const percentage = Math.round((100 * event.loaded) / event.total);
    if(percentage<1){
      setPercentage(1)
      return
    }
    if(percentage>99){
      setPercentage(99)
      return;
    }
    setPercentage(percentage);
  };

  const handleRequestError = (isCancel=false)=>{
    if(!isCancel){
    setUploadError(true)
    }
    setLoading(false)
    setProgressDialog(false)
    setPercentage(0)
  }

  const createMelopostMutation = useMutation((data) => createMelopost(data), {
    onSuccess: (res) => {
      if(res?.data?.done){
        setPercentage(1)
        handleUploadFile(res?.data?.result?.id)
      }else {
        handleRequestError()
      }

    },
    onError:()=>{
      handleRequestError()
    }
  });

  const editMelopostMutation = useMutation((data) => editMelopost(data), {
    onSuccess: (res) => {
      if(res?.data?.done){
        navigate(-1)
      }else {
        handleRequestError()
      }
    },
    onSettled:()=>{
      setEditLoading(false)
    },
    onError:()=>{
      handleRequestError()
    }
  });
  const attachFileToMelopostMutation = useMutation(
    ({ id, file, signal }) =>
      attachFileToMelopost(id, { file }, (e) => handlePercent(e), signal),
  );

  const completeMelopostMutation = useMutation((data) => completeMelopost(data), {
    onSuccess:(res)=>{
      if(res?.data?.done){
        setPercentage(100)
        setProgressDialog(false)
        navigate('/melopost/successful-create',{
          replace: true,
        })
      }else {
        handleRequestError()
      }
    },
    onError:()=>{
      handleRequestError()
    }
  });


  const handleUploadFile = async (id) => {
      const formData = new FormData();
      formData.append('file', melopostFile, 'melopost.mp3');
      await attachFileToMelopostMutation
        .mutateAsync({ id, file: formData, signal: controller.signal })
        .then((res) => {
          if (res?.data?.done) {
            completeMelopostMutation?.mutateAsync({id})
          } else {
               handleRequestError()
          }

        })
        .catch((err) => {

          handleRequestError(err.code === "ERR_CANCELED")

          controller = new AbortController();
        });




  };

  const toggleBugReportBottomSheet = (newOpen) => () => {
    setOpenHashtag(newOpen);
  };
  useNativeBack(openHashtag, "melopost-hashtag", () => setOpenHashtag(false));
  const handleSelectHashtags = (item) => {
    const isHashtagExistInList = selectedHashtags?.find(
      (selectedItem) => selectedItem?.title === item?.title
    );
    if (!isHashtagExistInList && selectedHashtags && selectedHashtags?.length > ((melopostSettings?.max_hashtags_allowed)-1)) {
      setCreateHashtagLimitStatus(true);
      return;
    }
    if (isHashtagExistInList) {
      const list = selectedHashtags;
      const newList = list?.filter(
        (selectedItem) => selectedItem?.title !== item?.title
      );
      setSelectedHashtags(newList);
    } else {
      setSelectedHashtags((prevState) => {
        return [...prevState, item];
      });
    }
  };
  const getSuggestedHashtagsQuery = useQuery(
    ["getSuggestHashtags"],
    () => getSuggestedHashtags(),
    {
      onSuccess: (res) => {},
      refetchOnWindowFocus: false,
    }
  );

  const removeHashtagOfList = (item) => {
    const list = formik.values?.hashtags;
    const newList = list?.filter(
      (selectedItem) => selectedItem?.title !== item?.title
    );
    formik.setFieldValue("hashtags", newList);
  };
  const setFinalHashtagsList = () => {
    formik.setFieldValue("hashtags", selectedHashtags);
    setOpenHashtag(false);
  };
  const handleCloseUploadDialog = () => {
    controller.abort()
    setProgressDialog(false)
  }

  useEffect(() => {

   if(melopostItem){
     formik.setFieldValue("hashtags", melopostItem?.hashtags);
     formik.setFieldValue("title", melopostItem?.title);
     formik.setFieldValue("caption", melopostItem?.caption);
   }
  }, [melopostItem]);

  return {
    loading,
    formik,
    openHashtag,
    setOpenHashtag,
    selectedHashtags,
    createHashtagLimitStatus,
    recorderControls,
    duration,
    currentAudioTime,
    isPausedRecordedAudio,
    togglePauseResume,
    toggleBugReportBottomSheet,
    handleSelectHashtags,
    getSuggestedHashtagsQuery,
    removeHashtagOfList,
    setFinalHashtagsList,
    setCreateHashtagLimitStatus,
    setSelectedHashtags,
    setProgressDialog,
    progressDialog,
    percentage,
    uploadError,
    handleCloseUploadDialog,
    isProcessingRecordedAudio,
    melopostItem,
    isEditMelopost,
    editLoading
  };
};
