import React from 'react';

export const HomeSectionBackgroundOverlay = ({ background_color }) => {

  return (
    <div
      style={{
        backgroundColor: background_color,
      }}
      className=" absolute w-full h-full opacity-10 top-0 left-0 bottom-0"
    ></div>
  );
};
