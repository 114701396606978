import React from 'react';

export function UserNameSection({ social_profile }) {
  console.log('Asfasfasfasf', social_profile);

  return (
    <div className="flex flex-col gap-2 px-4">
      <span className=" font-dana text-white font-medium text-base">
        {social_profile?.name}
      </span>{' '}
      <span className=" font-dana text-gray_4 font-normal text-xs">
        {social_profile?.username}@
      </span>
    </div>
  );
}
