import React, { useEffect, useMemo, useRef, useState } from 'react';
import PlayerSwipeContainer from '../player/PlayerSwipeContainer';
import ReactPlayer from 'react-player';
import {
  createMusicCdnLink,
  createReelCdnLink,
} from '../../helper/createMusicCdnLink';
import {
  getCurrentReelIndex,
  getReelsPlayerQueue,
  getReelsPlayerStatus,
  getUserToken,
  useUserData,
} from '../../helper/ReduxSelectorContext';
import { ReelPlayerCard } from './ReelPlayerCard';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { A11y, Autoplay, Navigation, Pagination, Virtual } from 'swiper';
import { Drawer } from '@mui/material';
import { useNativeBack } from '../../hooks/useNativeBack';
import { useDispatch } from 'react-redux';
import { closeReelPlayer } from '../../redux/reelPlayer/ReelsPlayerSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { IsIosDevice } from '../../helper/CheckDevice';
import { getReels } from '../../services/ApiClient';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import Loading from '../loading/Loading';
import { GET_REEL_TYPES } from '../../consts/MeloReelsConst';
import HeaderMelodifySvg from '../svg/common/HeaderMelodifySvg';

export const ReelPlayerContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [mute, setMute] = useState(false);

  const reelsPlayerList = getReelsPlayerQueue();
  const currentReelIndex = getCurrentReelIndex();
  const [activeSlide, setActiveSlide] = useState(currentReelIndex);

  const swiperRef = useRef(null);
  const getReelsQueryInfiniteQuery = useInfiniteQuery(
    ['getReelsQuery', location?.state?.model_type, location?.state?.model_id],
    ({ pageParam = reelsPlayerList?.length }) =>
      getReels({
        offset: pageParam,
        model_type: location?.state?.model_type || GET_REEL_TYPES.HOME,
        model_id: location?.state?.model_id,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.result?.end) return null;
        const allData = allPages?.flatMap(page => page.data.result?.reels);

        return allData?.length + reelsPlayerList?.length;
      },
      refetchOnWindowFocus: false,
    },
  );

  const reels = useMemo(
    () =>
      getReelsQueryInfiniteQuery?.data?.pages?.flatMap(
        page => page.data.result.reels,
      ) || [],
    [getReelsQueryInfiniteQuery?.data],
  );

  const handleLoadMore = () => {
    getReelsQueryInfiniteQuery.fetchNextPage();
  };

  const combinedReels = useMemo(() => {
    return [...reelsPlayerList, ...reels];
  }, [reelsPlayerList, reels]);

  const handleSlideChange = swiper => {
    setActiveSlide(swiper.activeIndex);
    const totalSlides = swiper.slides.length; // Get total number of slides
    const currentSlideIndex = swiper.realIndex; // Get the current slide index

    // Check if we're on the second-to-last slide
    if (currentSlideIndex === totalSlides - 3) {
      // Trigger the action for loading a new page or fetching new data
      if(getReelsQueryInfiniteQuery?.hasNextPage){
      handleLoadMore();
      }
      // Example: fetchNewPage();
    }
  };

  useEffect(() => {
    setActiveSlide(currentReelIndex);
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(currentReelIndex);
    }
  }, [currentReelIndex, swiperRef]);

  const handleCloseReelsPlayer = () => {
    navigate(-1);
  };

  return (
    <>
      {getReelsQueryInfiniteQuery?.isFetching ? (
        <div className="absolute bottom-2 translate-x-[-50%] left-[50%] z-40">
          <Loading bgColor="#ffffff" />
        </div>
      ) : null}
      <div className="absolute z-30 top-4 left-0 flex w-full justify-between items-center px-6">
        <div
          onClick={handleCloseReelsPlayer}
          className="bg-white bg-opacity-20  rounded-full flex flex-row justify-center items-center p-2 gap-2 !w-[40px] !h-[40px]   cursor-pointer "
        >
          <svg
            width="10"
            height="18"
            viewBox="0 0 10 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.262338 8.35798C-0.0874465 8.70777 -0.0874465 9.29216 0.262338 9.64284L8.33309 17.7343C8.68736 18.0886 9.26189 18.0886 9.61526 17.7343C9.96953 17.38 9.96953 16.8046 9.61526 16.4503L2.18563 9L9.61616 1.55056C9.97043 1.1954 9.97043 0.620866 9.61616 0.265701C9.26189 -0.0885681 8.68736 -0.0885681 8.33399 0.265701L0.262338 8.35798Z"
              fill="white"
            />
          </svg>
        </div>
        <HeaderMelodifySvg />
      </div>

      <Swiper
        ref={swiperRef}
        className="!w-full"
        // Install Swiper modules
        modules={[Navigation, Pagination, A11y, Virtual]}
        // Set direction to vertical
        direction="vertical"
        // Space between slides in px
        spaceBetween={0}
        // Number of slides per view
        slidesPerView={1}
        // Allow touch gestures
        allowTouchMove={true}
        // Set height to full viewport height
        style={{ height: '100vh' }}
        onSlideChange={handleSlideChange}
        // cssMode={IsIosDevice()}
      >
        {/* <SwiperSlide>
          <div className="w-full h-full bg-red">s</div>
        </SwiperSlide>{' '}
        <SwiperSlide>
          <div className="w-full h-full bg-red">s</div>
        </SwiperSlide>{' '}
        <SwiperSlide>
          <div className="w-full h-full bg-red">s</div>
        </SwiperSlide> */}
        {combinedReels?.map((item, index) => (
          <SwiperSlide virtual key={'reel' + index} virtualIndex={index}>
            <ReelPlayerCard
              key={index}
              item={item}
              isActive={index === activeSlide}
              setMute={setMute}
              mute={mute}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
