import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUpdateCollectionDialog } from '../../redux/dialog/DialogSlice';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useAction } from '../../hooks/useAction';
import DialogHeader from './DialogHeader.jsx';
import { CardActionArea } from '@mui/material';
import { notificationDB } from '../../indexDB/DB';
import CustomImage from '../common/CustomImage';

function UpdateCollectionDialog() {
  const [open, setOpen] = useState(false);
  const dialogData = useSelector(selectUpdateCollectionDialog);
  const action = useAction();

  const handleCloseDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (dialogData) setOpen(true);
    return () => {
      notificationDB.notif.clear();
    };
  }, [dialogData]);

  const handleButtonAction = () => {
    action({
      target_type: 'collection',
      view_type: 'playlist',
      collection: dialogData?.collection,
    });
    handleCloseDialog();
  };
  const closeDialog = () => {
    setOpen(false);
  };
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 " />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto font-dana">
          <div className="flex min-h-full items-center justify-center p-4 text-center pb-12">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className=" w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-primary_dark  text-left align-middle shadow-xl transition-all"
              >
                <DialogHeader
                  header_text={'یک پلی لیست به روز شد'}
                  onCloseDialog={closeDialog}
                />
                <div className="px-2 flex flex-col items-center ">
                  <div className="relative">
                    <div className="text-white font-medium rounded-full w-[66px] h-[66px] bg-black bg-opacity-50 absolute right-0 bottom-0 flex justify-center items-center flex-col">
                      <p className="flex leading-6">0</p>
                      <p>جدید</p>
                    </div>

                    <CustomImage
                      src={dialogData?.collection?.image_large}
                      className="w-[200px] h-[200px] rounded-[5px] !rounded-br-[33px]"
                      alt=""
                    />
                  </div>
                </div>
                <div className={'flex items-center justify-center mt-6   mb-4'}>
                  <CardActionArea className="mt-4 !h-12 !rounded-[22px] !w-[180px]">
                    <div className="w-[180px] flex items-center justify-center">
                      <button
                        onClick={handleButtonAction}
                        type="button"
                        className="inline-flex justify-center items-center !h-12 text-black text-base bg-primary w-full justify-center rounded-[22px] border border-transparent bg-blue-100 px-4 focus:outline-none"
                      >
                        <p className="font-semibold text-gray_1">مشاهده</p>
                      </button>
                    </div>
                  </CardActionArea>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default UpdateCollectionDialog;
