import React from 'react';

function FollowIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='21'
      fill='none'
      viewBox='0 0 21 21'
    >
      <g fill='#fff' clipPath='url(#clip0_2113_68782)'>
        <rect width='18' height='1.518' x='0.109' y='2.5' rx='0.759'></rect>
        <path
          d='M19.31 14.7h-2.4v-2.4a.8.8 0 0 0-1.6 0v2.4h-2.4a.8.8 0 0 0 0 1.6h2.4v2.4a.8.8 0 0 0 1.6 0v-2.4h2.4a.8.8 0 0 0 0-1.6'></path>
        <rect width='12' height='1.518' x='0.109' y='7.559' rx='0.759'></rect>
        <rect width='8' height='1.518' x='0.109' y='12.617' rx='0.759'></rect>
        <rect width='6.07' height='1.518' x='0.109' y='17.675' rx='0.759'></rect>
      </g>
      <defs>
        <clipPath id='clip0_2113_68782'>
          <path fill='#fff' d='M.11.5h20v20h-20z'></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default FollowIcon;
