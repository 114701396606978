import React from 'react';

function AddToPlaylistIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g fill="#BDBDBD" clipPath="url(#clip0_1_79782)">
        <rect width="18" height="1.518" y="2" rx="0.759"></rect>
        <path d="M19.2 14.2h-2.4v-2.4a.8.8 0 0 0-1.6 0v2.4h-2.4a.8.8 0 0 0 0 1.6h2.4v2.4a.8.8 0 0 0 1.6 0v-2.4h2.4a.8.8 0 0 0 0-1.6"></path>
        <rect width="12" height="1.518" y="7.059" rx="0.759"></rect>
        <rect width="8" height="1.518" y="12.117" rx="0.759"></rect>
        <rect width="6.07" height="1.518" y="17.176" rx="0.759"></rect>
      </g>
      <defs>
        <clipPath id="clip0_1_79782">
          <path fill="#fff" d="M0 0h20v20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default AddToPlaylistIcon;
