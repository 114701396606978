import { IconButton } from '@mui/material';
import React from 'react';

export const HeaderThreeDot = ({onClick}) => {
  return (
    <IconButton
      onClick={onClick}
      className={``}
      //   disableRipple={disableRipple}
    >
      <svg
        width="20"
        height="20"
        className='min-w-[20px]'
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2113_68935)">
          <path
            d="M16 2.00001C16 3.10459 16.8954 4.00003 18 4.00003C19.1046 4.00003 20 3.10459 20 2.00001C20 0.895437 19.1046 -3.91408e-08 18 -8.74234e-08C16.8954 -1.35706e-07 16 0.895437 16 2.00001Z"
            fill="white"
          />
          <path
            d="M16 10C16 11.1046 16.8954 12 18 12C19.1046 12 20 11.1046 20 10C20 8.89544 19.1046 8 18 8C16.8954 8 16 8.89544 16 10Z"
            fill="white"
          />
          <path
            d="M16 18C16 19.1046 16.8954 20 18 20C19.1046 20 20 19.1046 20 18C20 16.8954 19.1046 16 18 16C16.8954 16 16 16.8954 16 18Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_2113_68935">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(20) rotate(90)"
            />
          </clipPath>
        </defs>
      </svg>
    </IconButton>
  );
};
