import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Payment from '../pages/Plans/Payment';
import Plans from '../pages/Plans/Plans';
import Test from '../Test';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import GetNumber from '..//pages/login/GetNumber.jsx';
import GetOtp from '../pages/login/GetOtp.jsx';
import Register from '../pages/login/Register.jsx';
import AboutUs from '../pages/aboutUs/AboutUs';
import Support from '../pages/support/Support';
import TicketCategory from '../pages/support/TicketCategory';
import CreateTicket from '../pages/support/CreateTicket';
import ShowMessage from '../pages/support/ShowMessage';
import PlayList from '../pages/playList/PlayList';
import CollectionSubCategory from '../pages/playList/CollectionSubCategory.jsx';
import CollectionCategory from '../pages/playList/CollectionCategory';
import PopularSongs from '../pages/popularSongs/PopularSongs';
import Artist from '../pages/artists/Artist';
import PlayListSongs from '../pages/playList/PlayListSongs';
import MyMelodify from '../pages/myMelodify/MyMelodify';
import ArtistsProfile from '../pages/artists/ArtistsProfile';
import Home from '../pages/Home/Home';
import Splash from '../components/splash/Splash';
import Tickets from '../pages/support/Tickets';
import SingleAbout from '../pages/aboutUs/SingleAbout.jsx';
import MyMelodifyCategory from '../pages/myMelodify/MyMelodifyCategory';
import FollowedArtists from '../pages/myMelodify/FollowedArtists';
import FollowedPlayList from '../pages/myMelodify/FollowedPlayList';
import ArtistsGroup from '../pages/artistGroup/ArtistsGroup.jsx';
import NewestSongs from '../pages/popularSongs/NewestSongs';
import AllNewestArtistsSong from '../pages/artists/AllNewestArtistsSong';
import Download from '../pages/download/Download.jsx';
import GetPhoneMergeNumber from '../pages/mergeNumber/GetPhoneMergeNumber';
import GetOtpMergeNumber from '../pages/mergeNumber/GetOtpMergeNumber';
import PaymentStatusDialog from '../pages/Plans/PaymentStatusDialog.jsx';
import RecentlyPlaysMyMelodify from '../pages/myMelodify/RecentlyPlaysMyMelodify';
import UserPlaylist from '../components/playlist/UserPlaylist.jsx';
import AlbumTracks from '../pages/album/AlbumTracks.jsx';
import AlbumList from '../pages/album/AlbumsList';
import ArtistPage from '../pages/artists/ArtistPage.jsx';
import Setting from '../pages/setting/Setting';
import FreePremium from '../components/setting/FreePremium';
import ManageActiveDevices from '../components/setting/ManageActiveDevices.jsx';
import UserAccount from '../pages/setting/UserAccount';
import ConnectToTelegram from '../pages/setting/ConnectToTelegram';
import Search from '../pages/search/Search';
import SearchResult from '../pages/search/SearchResult';
import NeedMergePage from '../pages/mergeNumber/NeedMergePage.jsx';
import ShowAllRecentlyHeardPlaylist from '../pages/myMelodify/ShowAllRecentlyHeardPlaylist';
import ShowAllRecentlyHeardSingers from '../pages/myMelodify/ShowAllRecentlyHeardSingers';
import PremiumPage from '../components/common/PremiumPage.jsx';
import UserPlaylists from '../pages/myMelodify/UserPlaylists.jsx';
import ExclusiveTracks from '../pages/Exclusive/ExclusiveTracks.jsx';
import GeneralCollection from "../pages/playList/GeneralCollection.jsx";
import UserPublished from '../pages/myMelodify/UserPublished.jsx';
import CollectionTrackList from '../pages/trackList/CollectionTrackList.jsx';
import BuyGift from '../pages/gift/BuyGift';
import EditGift from '../pages/gift/EditGift';
import ManageGift from '../pages/gift/ManageGift';
import PlansGift from '../pages/gift/PlansGift';
import TutorialGift from '../pages/gift/TutorialGift';
import ShareGift from '../pages/gift/ShareGift';
import BuyingGiftSuccessfully from '../pages/gift/BuyingGiftSuccessfully';
import GeneralCollectionPage from '../pages/generalCollection/GeneralCollectionPage.jsx';
import { MeloPost } from '../pages/meloPost/MeloPost';
import { RecordMeloPost } from '../pages/meloPost/RecordMeloPost';
import { SuccessfulMelopostCreate } from '../pages/meloPost/SuccessfulMelopostCreate.jsx';
import { SubmitMeloPost } from '../pages/meloPost/SubmitMeloPost';
import { UserProfile } from '../components/userProfle/UserProfile.jsx';
import { MelopostList } from '../pages/meloPost/list/MelopostList.jsx';
import { EditSocialProfile } from '../components/userProfle/options/editProfile/EditSocialProfile.jsx';
import { EditName } from '../components/userProfle/options/editProfile/EditName.jsx';
import { EditSocialUserName } from '../components/userProfle/options/editProfile/EditSocialUserName.jsx';
import { UpdateAvatar } from '../components/userProfle/options/editProfile/UpdateAvatar.jsx';
import MelopostReport from '../pages/meloPost/MelopostReport.jsx';
import ShareMelopost from '../pages/meloPost/share/ShareMelopost.jsx';
import HashtagMelopostList from '../pages/meloPost/list/HashtagMelopostList.jsx';
import { ProfileFollowingPage } from '../pages/socialProfile/ProfileFollowingPage.jsx';
import { ProfileFollowersPage } from '../pages/socialProfile/ProfileFollowersPage.jsx';
import { ProfileMedalsPage } from '../pages/socialProfile/ProfileMedalsPage.jsx';
import UserPlaylistStatistics from '../pages/myMelodify/userPlaylist/UserPlaylistStatistics.jsx';
import { SystemNotificationPage } from '../pages/SystemNotification/SystemNotificationPage.jsx';
import { FollowRequestPage } from '../components/SystemNotification/FollowRequestPage.jsx';
import LikedReels from '../pages/myMelodify/LikedReels.jsx';
import DjExclusiveTracks from '../pages/Exclusive/DjExclusiveTracks.jsx';
import UsersCollectionsPage from '../pages/usersCollectionsPage/UsersCollectionsPage.jsx';
import UsersCollectionsListByType from '../pages/usersCollectionsPage/UsersCollectionsListByType.jsx';


function RouteSystem() {
  // const Home = lazy(() => import("../pages/Home/Home"));
  return (
    <>
      <Routes>
        <Route
          path="/my-melodify/ddddd"
          element={
            <PrivateRoute>
              <Test />
            </PrivateRoute>
          }
        />{' '}
        <Route
          path="/test"
          element={
            <PrivateRoute>
              <Test />
            </PrivateRoute>
          }
        />
        <Route
          path="/login/get-number"
          element={
            <PublicRoute>
              <GetNumber />
            </PublicRoute>
          }
        />
        <Route
          path="/login/verify-code"
          element={
            <PublicRoute>
              <GetOtp />
            </PublicRoute>
          }
        />
        <Route
          path="/merge-number"
          element={
            <PrivateRoute>
              <GetPhoneMergeNumber />
            </PrivateRoute>
          }
        />
        <Route
          path="/need-merge"
          element={
            <PrivateRoute>
              <NeedMergePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/update-phone"
          element={
            <PrivateRoute>
              <GetOtpMergeNumber />
            </PrivateRoute>
          }
        />
        <Route
          path="/login/register"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route path="/" element={<Splash />} />{' '}
        <Route
          path="/home"
          element={
            <PrivateRoute enableRedirect={localStorage.getItem('homeR') == 1}>
              {/* <Suspense fallback={<HomeShimmer />}> */}
              {/* <Suspense> */}
              <Home />
              {/* </Suspense> */}
            </PrivateRoute>
          }
        />
        <Route
          path="/premium-page"
          element={
            <PrivateRoute>
              <PremiumPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-melodify"
          element={
            <PrivateRoute>
              <MyMelodify />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-melodify/recently-heard-playlist"
          element={
            <PrivateRoute>
              <ShowAllRecentlyHeardPlaylist />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-melodify/recently-heard-singers"
          element={
            <PrivateRoute>
              <ShowAllRecentlyHeardSingers />
            </PrivateRoute>
          }
        />
        <Route
          path="/playlist"
          element={
            <PrivateRoute>
              <PlayList />
            </PrivateRoute>
          }
        />
        <Route
          path="/downloads"
          element={
            <PrivateRoute>
              <Download />
            </PrivateRoute>
          }
        />
        <Route
          path="/likes"
          element={
            <PrivateRoute>
              <Test />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-playlist"
          element={
            <PrivateRoute>
              <Test />
            </PrivateRoute>
          }
        />
        <Route
          path="/about-us"
          element={
            <PrivateRoute>
              <AboutUs />
            </PrivateRoute>
          }
        />
        {/* <Route path="/about-us/:pageKey" element={<SingleAbout/>}/> */}
        <Route
          path="/about-us/p/:pageKey"
          element={
            <PrivateRoute>
              <SingleAbout />
            </PrivateRoute>
          }
        />
        <Route
          path="/plans"
          element={
            <PrivateRoute>
              <Plans />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          }
        />
        <Route
          path="/setting"
          element={
            <PrivateRoute>
              {/* <Setting /> */}
              <Setting />
            </PrivateRoute>
          }
        />
        <Route
          path="/collection-subcategory"
          element={
            <PrivateRoute>
              <CollectionSubCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="/collection-category"
          element={
            <PrivateRoute>
              <CollectionCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="/support"
          element={
            <PrivateRoute>
              <Support />
            </PrivateRoute>
          }
        />
        <Route
          path="support/ticket-category"
          element={
            <PrivateRoute>
              <TicketCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="support/create-ticket"
          element={
            <PrivateRoute>
              <CreateTicket />
            </PrivateRoute>
          }
        />
        <Route
          path="/support/show-messages"
          element={
            <PrivateRoute>
              <ShowMessage />
            </PrivateRoute>
          }
        />
        <Route
          path="/search-result"
          element={
            <PrivateRoute>
              <SearchResult />
            </PrivateRoute>
          }
        />
        <Route
          path="/search"
          element={
            <PrivateRoute>
              <Search />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/search-results"
          element={
            <PrivateRoute>
              <RenderSearchResult />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/popular-songs"
          element={
            <PrivateRoute>
              <PopularSongs />
            </PrivateRoute>
          }
        />
        <Route
          path="/newest-songs"
          element={
            <PrivateRoute>
              <NewestSongs />
            </PrivateRoute>
          }
        />
        <Route
          path="/album-songs"
          element={
            <PrivateRoute>
              <AlbumTracks />
            </PrivateRoute>
          }
        />
        <Route
          path="/albums-list"
          element={
            <PrivateRoute>
              <AlbumList />
            </PrivateRoute>
          }
        />
        <Route
          path="artists-page/artists"
          element={
            <PrivateRoute>
              <Artist />
            </PrivateRoute>
          }
        />
        <Route
          path="/artists-group"
          element={
            <PrivateRoute>
              <ArtistsGroup />
            </PrivateRoute>
          }
        />
        {/*<Route*/}
        {/*  path="/artists-page"*/}
        {/*  element={*/}
        {/*    <PrivateRoute>*/}
        {/*      <ArtistPage />*/}
        {/*    </PrivateRoute>*/}
        {/*  }*/}
        {/*/>*/}
        <Route
          path="/my-melodify"
          element={
            <PrivateRoute>
              <Artist />
            </PrivateRoute>
          }
        />
        <Route
          path="/playlist-songs/:id"
          element={
            <PrivateRoute>
              <PlayListSongs />
            </PrivateRoute>
          }
        />
        <Route
          path="/artist-profile/:id"
          element={
            <PrivateRoute>
              <ArtistsProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/tickets"
          element={
            <PrivateRoute>
              <Tickets />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-melodify/:key"
          element={
            <PrivateRoute>
              <MyMelodifyCategory />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-melodify/user-published"
          element={
            <PrivateRoute>
              <UserPublished />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-melodify/recently_listened"
          element={
            <PrivateRoute>
              <RecentlyPlaysMyMelodify />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-melodify/followed-artists"
          element={
            <PrivateRoute>
              <FollowedArtists />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-melodify/followed-playLists"
          element={
            <PrivateRoute>
              <FollowedPlayList />
            </PrivateRoute>
          }
        />
        <Route
          path="/artist-newest-song/:id"
          element={
            <PrivateRoute>
              <AllNewestArtistsSong />
            </PrivateRoute>
          }
        />
        <Route
          path="/user-playlist/:id"
          element={
            <PrivateRoute>
              <UserPlaylist />
            </PrivateRoute>
          }
        />
        <Route
          path="/setting/manage-devices"
          element={
            <PrivateRoute>
              <ManageActiveDevices />
            </PrivateRoute>
          }
        />
        <Route
          path="/setting/edit-profile"
          element={
            <PrivateRoute>
              <UserAccount />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/:social_token"
          element={
            <PrivateRoute>
              <UserProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/following"
          element={
            <PrivateRoute>
              <ProfileFollowingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/followers"
          element={
            <PrivateRoute>
              <ProfileFollowersPage />
            </PrivateRoute>
          }
        />{' '}
        <Route
          path="/profile/medals"
          element={
            <PrivateRoute>
              <ProfileMedalsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/edit"
          element={
            <PrivateRoute>
              <EditSocialProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/edit/name"
          element={
            <PrivateRoute>
              <EditName />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/edit/usename"
          element={
            <PrivateRoute>
              <EditSocialUserName />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/edit/update-profile"
          element={
            <PrivateRoute>
              <UpdateAvatar />
            </PrivateRoute>
          }
        />
        <Route
          path="/setting/connect-to-telegram"
          element={
            <PrivateRoute>
              <ConnectToTelegram />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-melodify/user-playlists"
          element={
            <PrivateRoute>
              <UserPlaylists />
            </PrivateRoute>
          }
        />
        <Route
          path="/home/exclusive-tracks"
          element={
            <PrivateRoute>
              <ExclusiveTracks />
            </PrivateRoute>
          }
        />
        <Route
          path="/home/dj-tracks"
          element={
            <PrivateRoute>
              <DjExclusiveTracks />
            </PrivateRoute>
          }
        />
        <Route
          path="/general-collection/:collection_id"
          element={
            <PrivateRoute>
              <GeneralCollection />
            </PrivateRoute>
          }
        />
        <Route path="/payment/:status" element={<PaymentStatusDialog />} />
        <Route
          path="setting/free-premium"
          element={
            <PrivateRoute>
              <FreePremium />
            </PrivateRoute>
          }
        />
        <Route
          path="/manage-gift"
          element={
            <PrivateRoute>
              <ManageGift />
            </PrivateRoute>
          }
        />
        <Route
          path="/tutorial-gift"
          element={
            <PrivateRoute>
              <TutorialGift />
            </PrivateRoute>
          }
        />
        <Route
          path="/share-gift"
          element={
            <PrivateRoute>
              <ShareGift />
            </PrivateRoute>
          }
        />
        <Route
          path="/buy-gift"
          element={
            <PrivateRoute>
              <BuyGift />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-gift/:gift_id"
          element={
            <PrivateRoute>
              <EditGift />
            </PrivateRoute>
          }
        />
        <Route
          path="/gift_plan"
          element={
            <PrivateRoute>
              <PlansGift />
            </PrivateRoute>
          }
        />
        <Route
          path="/gift_successfully-buying"
          element={
            <PrivateRoute>
              <BuyingGiftSuccessfully />
            </PrivateRoute>
          }
        />
        <Route
          path="/track-list/:id"
          element={
            <PrivateRoute>
              <CollectionTrackList />
            </PrivateRoute>
          }
        />
        <Route
          path="/generalCollectionGroup/:id"
          element={
            <PrivateRoute>
              <GeneralCollectionPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/melopost"
          element={
            <PrivateRoute>
              <MeloPost />
            </PrivateRoute>
          }
        />
        <Route
          path="/melopost/record"
          element={
            <PrivateRoute>
              <RecordMeloPost />
            </PrivateRoute>
          }
        />
        <Route
          path="/melopost/upload"
          element={
            <PrivateRoute>
              <SubmitMeloPost />
            </PrivateRoute>
          }
        />
        <Route
          path="/melopost/successful-create"
          element={
            <PrivateRoute>
              <SuccessfulMelopostCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="/meloposts"
          element={
            <PrivateRoute>
              <MelopostList />
            </PrivateRoute>
          }
        />
        <Route
          path="/melopost-report"
          element={
            <PrivateRoute>
              <MelopostReport />
            </PrivateRoute>
          }
        />
        <Route
          path="/melopost/share"
          element={
            <PrivateRoute>
              <ShareMelopost />
            </PrivateRoute>
          }
        />
        <Route
          path="/meloposts-hashtag-list/:hashtag"
          element={
            <PrivateRoute>
              <HashtagMelopostList />
            </PrivateRoute>
          }
        />
        <Route
          path="/user-playlist-statistics"
          element={
            <PrivateRoute>
              <UserPlaylistStatistics />
            </PrivateRoute>
          }
        />
        <Route
          path="/system-notification"
          element={
            <PrivateRoute>
              <SystemNotificationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/follow-requests"
          element={
            <PrivateRoute>
              <FollowRequestPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-melodify/favorite-reels"
          element={
            <PrivateRoute>
              <LikedReels />
            </PrivateRoute>
          }
        />
        <Route
          path="/playlist/users-collections"
          element={
            <PrivateRoute>
              <UsersCollectionsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/playlist/users-collections/list"
          element={
            <PrivateRoute>
              <UsersCollectionsListByType />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

export default RouteSystem;
