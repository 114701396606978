import React, { useEffect, useRef, useState } from 'react';
import PlayerSwipeContainer from './PlayerSwipeContainer';
import RoundedDrawerHeader from '../svg/common/RoundedDrawerHeader';
import MinPlayer from './MinPlayer';
import {
  addToPlaylistData,
  getAdvertiseData,
  getHaveAdvertise,
  getPlayerMusic,
  getPlayerPaginationData,
  getPlayList,
  getSwipePlayerStatus,
  getPlayListStatic,
  getReelsPlayerStatus,
} from '../../helper/ReduxSelectorContext';
import { useDispatch } from 'react-redux';
import {
  setPlayerPaginationData,
  setPlayList,
  setSwipePlayerStatus,
} from '../../redux/player/PlayMusicSlice';
import TrackActionsBottom from './TrackActionsBottom';
import ScrollTopButton from '../common/ScrollTopButton';
import AdsPlayer from './avertiseds/AdsPlayer';
import AddToPlaylistModeButton from '../playlist/AddToPlaylistModeButton.jsx';
import { disablePlayerListSelectMode } from '../../redux/adToPlaylist/AddToPlaylistSlice.js';
import { useNativeBack } from '../../hooks/useNativeBack.js';
import PlayerTrackList from './PlayerTrackList.jsx';
import { useMutation } from '@tanstack/react-query';
import { getPlayerPaginationTrack } from '../../services/ApiClient.js';
import SongListLoading from '../loading/SongListLoading.jsx';
import { usePlayerPagination } from '../../hooks/usePlayerPagination.js';
import { playerPaginationKey } from '../../consts/PlayerPaginationKey.js';
import useCountdown from '../../hooks/useCountdown.jsx';
import { PlayerHeader } from './PlayerHeader.jsx';
import { dispatchIsPlaying } from '../../redux/globalstore/GlobalStore.js';

function SwipePlayer() {
  let playedTrackData = getPlayerMusic();
  let playList = getPlayList();
  const reelsPlayerStatus = getReelsPlayerStatus();

  const dispatch = useDispatch();
  let havAdvertise = getHaveAdvertise();
  const playerSwipeStatus = getSwipePlayerStatus();
  let advertiseData = getAdvertiseData();
  const playerRef = useRef(null);

  const { start, clear } = useCountdown();
  const addToPlaylistDataState = addToPlaylistData();
  const checkClearSelectionOfTracks = openState => {
    if (
      !openState &&
      addToPlaylistDataState?.playerListSelectedItems?.length > 0
    ) {
      dispatch(disablePlayerListSelectMode());
    }
  };
  const toggleSheet = newOpen => {
    if (reelsPlayerStatus) {
      dispatchIsPlaying(false);
    }
    checkClearSelectionOfTracks(newOpen);
    dispatch(setSwipePlayerStatus({ status: newOpen }));
  };
  const closeSwipePlayer = () => {
    dispatch(setSwipePlayerStatus({ status: false }));
  };
  useNativeBack(playerSwipeStatus, 'swipe-player-status', closeSwipePlayer);
  const scrollTop = () => {
    const page = document.getElementById('playlist-item');
    page?.scrollTo({ top: 0 });
  };
  useEffect(() => {
    scrollTop();
  }, [playerSwipeStatus]);
  return (
    <>
      <div>
        <PlayerSwipeContainer
          openBottomSheet={playerSwipeStatus}
          toggleSheet={toggleSheet}
        >
          <AddToPlaylistModeButton
            inPlayer={true}
            EnableEditMode={false}
          />
          <ScrollTopButton player={true} id={'playlist-item'} />
          <div
            ref={playerRef}
            id="playlist-item"
            className=" w-full mx-auto h-full relative flex flex-col  overflow-scroll"
          >
            <div
              className="absolute top-0 z-10 w-full h-full"
              style={{
                background:
                  // "linear-gradient(180deg,rgba(33,33,33,.3) .5%,rgba(33,33,33,.3) 20%,rgba(33,33,33,.7) 30%,rgba(33,33,33,.9) 40%,#212121 49%)",
                  // ' linear-gradient(180deg, rgba(14, 14, 14, 0.2) 0%, rgba(14, 14, 14, 0.85) 100%)',
                  // "linear-gradient(rgba(33, 33, 33, 0.3) 0.5%, rgba(33, 33, 33, 0.3) 10%, rgba(33, 33, 33, 0.7) 35%, rgba(33, 33, 33, 0.9) 52%, rgb(33, 33, 33) 60%)",
                  'linear-gradient(180deg, rgba(14, 14, 14, 0.8) 0%, rgba(14, 14, 14, 0.9) 100%)',
              }}
            ></div>
            {havAdvertise && advertiseData ? (
              <div
                className="absolute top-0 z-0 w-full h-full "
                style={{ background: advertiseData[0]?.background_color }}
              ></div>
            ) : (
              <div
                className="absolute top-0 z-0 w-full h-full "
                style={{ background: playedTrackData?.background_color }}
              ></div>
            )}
            <div className="w-full h-full relative overflow-hidden shadow-player-shadow  ">
              <div className="z-30 top-0 absolute w-full h-full ">
                <div className="!w-full mx-auto justify-center z flex bg-transparent  items-center">
                  <PlayerHeader handleBack={() => toggleSheet(false)} />
                  {/* <RoundedDrawerHeader /> */}
                </div>
                <div
                  dir="rtl"
                  style={{ overscrollBehavior: 'none' }}
                  className="bg-transparent px-4 py-[6px] h-full overflow-hidden z-30 text-black text-[16px] font-dana font-[600] leading-6 flex justify-center items-center   "
                >
                  {/* player Content */}
                  {havAdvertise && <AdsPlayer />}

                  <MinPlayer adv={havAdvertise} />
                </div>
              </div>
            </div>
            {/* Player bottoms */}
            <div className="w-full h-[10%] relative rounded-b-[50px] z-10 ">
              {havAdvertise ? (
                <div className="w-full pt-4 pb-6  text-white font-kalameh text-[14px] font-[500] flex justify-center items-center">
                  {advertiseData && advertiseData[0]?.bottom_text}
                </div>
              ) : (
                <TrackActionsBottom start={start} clear={clear} />
              )}

            </div>
          </div>
        </PlayerSwipeContainer>
      </div>
    </>
  );
}

export default SwipePlayer;
