import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import CompleteSongList from '../../components/ArtistProfile/CompleteSongList.jsx';

import SortSvg from '../svg/playList/SortSvg.jsx';
import CollectionCategoryHeader from './CollectionCategoryHeader.jsx';
import Container from '../PageParentContainer/Container.jsx';
import ScrollTopButton from '../common/ScrollTopButton.jsx';
import DragDropContainer from '../svg/playList/DragDropContainer.jsx';
import UserCollectionHintDialog from '../dialogs/UserCollectionHintDialog.jsx';
import SongListLoading from '../loading/SongListLoading.jsx';
import { UserPlaylistInfoBox } from '../userPlaylist/UserPlaylistInfoBox.jsx';
import { useUserPlaylist } from '../../hooks/useUserPlaylist.js';

function UserPlaylist() {

const {userCollectionData,getUserCollectionDataLoading,isDragModeActive,userCollectionId,userCollectionTrackList,changeSort,hasNextPage,reFetchData,collectionQueryData,getCollectionTracksLoading,handleUpdateCollectionData,setTrackListQueryData,sortType,sortList,isUserSocialProfile} = useUserPlaylist()


  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);
  return (
    <Container
      style="transition-all"
      containerStyle={{
        background: userCollectionData?.background_color ?? '',
      }}
    >
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-background bg-opacity-80  "></div>
`
      {isDragModeActive && <UserCollectionHintDialog />}
      <CollectionCategoryHeader
        hasSearch={false}
        title={userCollectionData?.title}
        backgroundId="user-playlist-background"
        pageId="user-playlist-song"
      />
      <div
        dir="rtl"
        id="user-playlist-song"
        className="w-full h-full overflow-scroll hide-scroll-bar pb-10"
      >
        <UserPlaylistInfoBox
          userCollectionData={userCollectionData}
          isDragModeActive={isDragModeActive}
          getUserCollectionDataLoading={getUserCollectionDataLoading}
          list={userCollectionTrackList}
          requestPayload={collectionQueryData}
          endOfPagination={!hasNextPage}
          handleUpdateCollectionData={handleUpdateCollectionData}
        />
        <ScrollTopButton id={'user-playlist-song'} />
        <div
          id="page-infinite-scroll-user"
          className="relative px-4 flex flex-col gap-5 pt-7  w-full"
        >
          {isDragModeActive ? (
            <DragDropContainer
              CollectionId={userCollectionId}
              list={userCollectionTrackList}
              setTrackListQueryData={setTrackListQueryData}
            />
          ) : (
            <InfiniteScroll
              next={reFetchData}
              hasMore={hasNextPage}
              loader={
                <div className=" mt-2  flex w-full justify-center text-yellow  ">
                  <SongListLoading />
                </div>
              }
              scrollableTarget="user-playlist-song"
              dataLength={userCollectionTrackList?.length || 0}
            >
              <div className="">
                <CompleteSongList
                  scrollId={'user-playlist-song'}
                  onChangeSort={changeSort}
                  list={userCollectionTrackList}
                  pageId={userCollectionId}
                  userPlaylist={true}
                  trackCount={userCollectionData?.tracks_count}
                  title={' آهنگ ها'}
                  titleUnderLine={false}
                  collectionObject={userCollectionData}
                  sortTypes={sortList}
                  sortButton={activeSortText => (
                    <div className="flex items-center gap-1">
                      <div className="flex gap-2   items-center">
                        <div className="flex w-[22px] h-[16px]  ">
                          <SortSvg />
                        </div>
                        <p className="text-primary font-dana text-[14px] font-medium">
                          ترتیب نمایش:
                        </p>
                      </div>
                      <p className="text-gray_3 font-dana text-[12px] font-medium">
                        {activeSortText}
                      </p>
                    </div>
                  )}
                  apiUrl={'/getUserCollectionTracks'}
                  requestPayload={collectionQueryData}
                  endOfPagination={!hasNextPage}
                  loading={
                    getCollectionTracksLoading && !userCollectionTrackList
                  }
                  isInUserPlaylist={isUserSocialProfile}
                  selectedSort={sortType}
                />
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </Container>
  );
}

export default UserPlaylist;
