import React from 'react';

import { useLocation } from 'react-router-dom';
import CommonHeader from '../../common/CommonHeader';
import Container from '../../PageParentContainer/Container';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getFollowers, getFollowings } from '../../../services/ApiClient';
import { useInfiniteQuery } from '@tanstack/react-query';
import SongListLoading from '../../loading/SongListLoading';
import { FollowingCartItem } from './FollowingCartItem';
import NoUserPlaylists from '../../MyMelodify/NoUserPlaylists';
import { NoFollowingSvg } from '../../svg/profile/NoFollowingSvg';
import { NoteMusicLoadingContainer } from '../../loading/NoteMusicLoadingContainer';

export const ProfileFollowers = () => {
  const location = useLocation();
  const social_token = location?.state?.social_token;
  const {
    isLoading: getFollowersIsLoading,
    isFetchingNextPage: getFollowersIsFetchingNextPage,
    fetchNextPage: getFollowersIsFetchNextPage,
    hasNextPage: getFollowersHasNextPage,
    data: geFollowersData,
    isFetching,
  } = useInfiniteQuery(
    [`user-followers`, social_token],
    ({ pageParam = 0 }) =>
      getFollowers({
        offset: pageParam,
        social_token: social_token,
        hashtag: null,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const allData = allPages?.flatMap(page => page.data.result.followers);
        return lastPage?.data?.result?.end ? false : allData?.length;
      },
      refetchOnWindowFocus: false,
    },
  );

  const getFollowersFlatMap = geFollowersData?.pages?.flatMap(
    page => page.data.result.followers,
  );
  const getMeloPostNextPage = () => {
    if (getFollowersHasNextPage && !getFollowersIsFetchingNextPage) {
      getFollowersIsFetchNextPage();
    }
  };

  return (
    <Container shadow={false} header={<CommonHeader title="دنبال کننده" />}>
      <div
        id="following-page"
        dir="rtl"
        class="w-full h-full bg-black overflow-scroll hide-scroll-bar"
      >
        {isFetching ? (
          <NoteMusicLoadingContainer />
        ) : getFollowersFlatMap ? (
          <InfiniteScroll
            className={'!overflow-hidden'}
            next={getMeloPostNextPage}
            hasMore={getFollowersHasNextPage}
            dataLength={getFollowersFlatMap?.length || 0}
            loader={
              getFollowersIsFetchingNextPage && (
                <div className="w-full flex justify-center items-start h-[50px]   ">
                  <SongListLoading />
                </div>
              )
            }
            scrollableTarget="following-page"
          >
            {/* <div className={'w-full h-full !overflow-hidden  mt-3'}>
            <Virtuoso
              style={{ overflowY: 'hidden !important' }}
              customScrollParent={document.getElementById(
                'scrollbar-user-social',
              )}
              data={getMelopostsFlatMap}
              itemContent={(index, item) => (
                <div className={'flex flex-col  pb-5 '}>
                  <MeloPostFullCard
                    index={index}
                    item={item}
                    key={'melofullcard' + index}
                    handlePlay={handlePlay}
                    activePlayerIndex={activePlayerIndex}
                  />
                
                </div>
              )}
            />
          </div> */}

            <div className={'w-full h-full !overflow-hidden  mt-3 gap-5'}>
              {getFollowersFlatMap?.map((item, index) => (
                <div className={'flex flex-col  pb-5  text-primary'}>
                  <FollowingCartItem item={item} />
                  {/* <MeloPostFullCard
                        index={index}
                        item={item}
                        key={'melofullcard' + index}
                        handlePlay={handlePlay}
                        activePlayerIndex={activePlayerIndex}
                    /> */}
                </div>
              ))}
            </div>
          </InfiniteScroll>
        ) : (
          <div className="flex w-full h-full  flex-col justify-center items-center gap-4   mt-8">
            <NoFollowingSvg />
            <span
              className={
                'text-gray_2 font-[600] font-dana text-[20px] leading-6  '
              }
            >
              دنبال کننده‌ای وجود ندارد!
            </span>
          </div>
          // <div className={'flex flex-col gap-3 mt-2'}>
          //   {Array.from(
          //     [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => (
          //       <SongsDetailCardShimmer />
          //     )),
          //   )}
          // </div>
        )}
        {/* <ScrollTopButton show={slideIndex === 0} id={'scrollbarWeekNew'} /> */}
      </div>
    </Container>
  );
};
