import React from 'react';

function InfoIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2113_184805)">
        <path d="M7 0C3.13111 0 0 3.13075 0 7C0 10.8688 3.13075 14 7 14C10.8689 14 14 10.8693 14 7C14 3.13116 10.8693 0 7 0ZM7 13.0233C3.67877 13.0233 0.976746 10.3213 0.976746 7C0.976746 3.67875 3.67877 0.976746 7 0.976746C10.3212 0.976746 13.0233 3.67875 13.0233 7C13.0233 10.3213 10.3212 13.0233 7 13.0233Z" fill="#F2C94C"/>
        <path d="M7.0005 5.83508C6.58586 5.83508 6.29102 6.01019 6.29102 6.26818V9.77874C6.29102 9.99989 6.58586 10.221 7.0005 10.221C7.39672 10.221 7.71918 9.99989 7.71918 9.77874V6.26813C7.71918 6.01016 7.39672 5.83508 7.0005 5.83508Z" fill="#F2C94C"/>
        <path d="M6.99968 3.6698C6.57582 3.6698 6.24414 3.97386 6.24414 4.324C6.24414 4.67416 6.57585 4.98744 6.99968 4.98744C7.41432 4.98744 7.74605 4.67416 7.74605 4.324C7.74605 3.97386 7.41429 3.6698 6.99968 3.6698Z" fill="#F2C94C"/>
      </g>
      <defs>
        <clipPath id="clip0_2113_184805">
          <rect width="14" height="14" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
}

export default InfoIcon;
