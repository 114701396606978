import React from "react";
import HomeSlider from "./HomeSlider";
import HomeCustomCollection from "./HomeSuggestion";

function HomeSectionOne({item}) {
  console.log('Afasfasadsasfasf', item?.element_data);
  
  return (
    <>
      <div class="w-full flex flex-row gap-4 ">
        <div class="w-full">
          <HomeSlider
            heightRelation={item?.element_meta?.height_relation}
            metaData={item?.element_meta}
            data={item?.element_data}
            slideItemContainerClass={'w-[90%]'}
            slideItemContentClass={'rounded-[20px]'}
            slideItemActionAreaClass={'!rounded-[20px]'}
          />
        </div>
      </div>
    </>
  );
}

export default React.memo(HomeSectionOne);
