import React from 'react';
import HomeChildMetaData from '../common/HomeChildMetaData';
import HomeSongCardSliderType2 from './HomeSongCardSliderType2';

function HomeWeekNew({
  item,
  type,
  artistCollection = false,
  metaData,
  fromMyMelodify,
  cardStyle,
}) {
  
  return (
    <div dir="rtl" class="flex flex-col static z-20">
      {/* {!fromMyMelodify &&
            <div className="flex justify-between mb-3 lg-1440:mb-r12  items-center relative overflow-visible px-6 ">
                {item?.collection_items_meta_data?.title && (
                    <HomeChildMetaData metaData={item?.collection_items_meta_data} />
                )}
            </div>
        } */}
      <HomeSongCardSliderType2
        cardStyle={cardStyle}
        type={type}
        item={item}
        artistCollection={artistCollection}
        fromMyMelodify={fromMyMelodify}
        metaData={metaData}
      />
    </div>
  );
}

export default HomeWeekNew;
