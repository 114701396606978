import { createSlice } from "@reduxjs/toolkit";
import { LOOP_STATUS } from "../../consts/PlayListType";
import { mainToast, notifyAddToQueue } from "../../helper/ToastsEmitter";

export const PlayMusicSlice = createSlice({
    name: "playMusic",
    initialState: {
        playMusic: null,
        duration: 0,
        volume: 1,
        seekTime: 0,
        isPlaying: false,
        mute: false,
        playList: [],
        playListStatic: [],
        playListClone: [],
        queueList: [],
        playedFrom: "",
        playerSeek: null,
        loop: LOOP_STATUS.OFF,
        isShuffle: false,
        onTrackEnd: null,
        playlistPlayed: null,
        recentlyListen: [],
        playerPaginationData: null,
        playerPaginationLoader: false,
        HeaderPlayData: {
            profileObject: null,
            trackList: null,
        },
        weightCount: 0,
        swipePlayerStatus: false,
        lyricFullScreenStatus: false,
        QueuePage: false,
    },
    reducers: {
        setMusic: (state, action) => {
            const { track } = action.payload;
            console.log("Set Track For Playing", track);
            state.playMusic = track;

            //filter playedMusic From Queue
            let filterPlayedItem = state.queueList.filter(
                (item) => item?.Qid != track?.Qid
            );
            state.queueList = filterPlayedItem;

            //add Track To Recently Played
            if (!track?.amplitude)
                handleStoreRecentlyPlayed(state, track);
        },
        changeTrackQuality: (state, action) => {
            const { cdn_url } = action.payload;
            let trackCopy = { ...state.playMusic, cdn_url };
            state.playMusic = trackCopy;
        },
        setVolume: (state, action) => {
            const { volume } = action.payload;
            state.volume = volume;
        },
        setPlayList: (state, action) => {
            const { playList } = action.payload;
            state.playList = playList;
        },
        setPlayListstatic: (state, action) => {
            const { playListStatic } = action.payload;
            state.playListStatic = playListStatic;
        },
        setMutePlayer: (state, action) => {
            const { mute } = action.payload;
            state.mute = mute;
        },
        setTrackDuration: (state, action) => {
            const { duration } = action.payload;
            state.duration = duration;
        },
        setSeekTime: (state, action) => {
            const { seekTime } = action.payload;
            state.seekTime = seekTime;
        },
        setIsPlaying: (state, action) => {
            const { isPlay } = action.payload;
            state.isPlaying = isPlay;
        },
        addTrackToQueueList: (state, action) => {
            const { track } = action.payload;
            state.queueList.push(track);

            mainToast(`آهنگ ${track?.title} به لیست پخش اضافه شد.`)
        },
        removeTrackFromQueueList: (state, action) => {
            const { Qid } = action.payload;
            state.queueList = state.queueList.filter((item) => item.Qid != Qid);
        },
        setPlayFrom: (state, action) => {
            const { from } = action.payload;
            state.playedFrom = from;
        },
        setPlayerSeek: (state, action) => {
            const { playerSeek } = action.payload;
            state.playerSeek = playerSeek;
        },
        setPlayedTrackLyric: (state, action) => {
            const { lyric } = action.payload;
            state.playMusic = { ...state.playMusic, lyricData: lyric };
        },
        clearQueueList: (state, action) => {
            state.queueList = [];
        },

        setPlayListShuffle: (state, action) => {
            const { playList, clone } = action.payload;
            if (clone) {
                if (!state.isShuffle) state.playListClone = state.playList;
                state.playList = playList;
            } else {
                state.playList = state.playListStatic;
            }
        },
        setPlayerLoop: (state, action) => {
            const { loop } = action.payload;
            state.loop = loop;
        },
        setPlayerShuffle: (state, action) => {
            state.isShuffle = !state.isShuffle;
        },
        emitTrackEnd: (state, action) => {
            state.onTrackEnd = Date.now();
        },
        setPlayedPlaylist: (state, action) => {
            const { playlist } = action.payload;
            state.playlistPlayed = playlist;
        },
        setHeaderTrackList: (state, action) => {
            const { profileObject, trackList, clear } = action.payload;
            if (clear) {
                state.HeaderPlayData.profileObject = null;
                state.HeaderPlayData.trackList = null;
            } else {
                state.HeaderPlayData.profileObject = profileObject
                    ? profileObject
                    : state.HeaderPlayData.profileObject;
                state.HeaderPlayData.trackList = trackList
                    ? trackList
                    : state.HeaderPlayData.trackList;
            }
        },
        setWeightCount: (state, action) => {
            const { trackCount } = action.payload;
            state.weightCount = (
                Number(state.weightCount) + Number(trackCount)
            ).toFixed(2);
        },
        resetWeightCount: (state, action) => {
            state.weightCount = 0;
        },
        setSwipePlayerStatus: (state, action) => {
            const { status } = action.payload;
            state.swipePlayerStatus = status;
        },
        toggleLyricFullScreen: (state, action) => {
            state.lyricFullScreenStatus = !state.lyricFullScreenStatus;
        },
        setLyricFullScreenStatus: (state, action) => {
            const { status } = action.payload;
            state.lyricFullScreenStatus = status;
        },

        toggleQueuePage: (state, action) => {
            state.QueuePage = !state.QueuePage;
        },
        setQueuePageStatus: (state, action) => {
            const { status } = action.payload;
            state.QueuePage = status;
        },

        setPlayerPaginationData: (state, action) => {
            const { playerPaginationData } = action.payload;
            state.playerPaginationData = playerPaginationData
        },
        enablePlayerPaginationLoader: (state, action) => {
            state.playerPaginationLoader = true
        },
        disablePlayerPaginationLoader: (state, action) => {
            state.playerPaginationLoader = false
        },
        resetPlayer: (state) => {
            (state.playMusic = null),
                (state.duration = 0),
                // state.volume = 0.5,
                (state.seekTime = 0),
                (state.isPlaying = false),
                (state.mute = false),
                (state.playList = []),
                (state.playListClone = []),
                (state.queueList = []),
                (state.playedFrom = ""),
                (state.playerSeek = null),
                // state.loop= LOOP_STATUS.OFF,
                // state.isShuffle= false,
                (state.onTrackEnd = null),
                (state.playlistPlayed = null),
                // state.recentlyListen = [],
                (state.HeaderPlayData.trackList = null);
            state.HeaderPlayData.trackList = null;

            (state.swipePlayerStatus = false),
                (state.lyricFullScreenStatus = false),
                (state.QueuePage = false)
        },
        resetRecentlyListen: state => {
            state.recentlyListen = [];
        },
    },
});

const handleStoreRecentlyPlayed = (state, track) => {
    const sameItem = state.recentlyListen.filter(
        (item) => item?.id === track?.id
    );
    // handle same Item
    if (sameItem.length !== 0) {
        let newRecentlyListen = [...state.recentlyListen];
        let index = newRecentlyListen.indexOf(sameItem[0]);
        if (index === -1) return;
        newRecentlyListen.splice(index, 1);

        newRecentlyListen = [...newRecentlyListen, track];
        state.recentlyListen = newRecentlyListen;
        return;
    }
    // handle Limit Recently
    if (state.recentlyListen.length === 100) {
        let newRecentlyListen = [...state.recentlyListen];
        newRecentlyListen.splice(0, 1);

        newRecentlyListen = [...newRecentlyListen, track];
        state.recentlyListen = newRecentlyListen;
    } else {
        state.recentlyListen = [...state.recentlyListen, track];
    }
};
export const {
    setMusic,
    setSeekTime,
    setIsPlaying,
    setPlayList,
    addTrackToQueueList,
    removeTrackFromQueueList,
    setVolume,
    setMutePlayer,
    setPlayFrom,
    setPlayerSeek,
    setPlayedTrackLyric,
    clearQueueList,
    setPlayListShuffle,
    setPlayerLoop,
    emitTrackEnd,
    setPlayerShuffle,
    changeTrackQuality,
    setPlayedPlaylist,
    setHls,
    setHeaderTrackList,
    setWeightCount,
    resetWeightCount,
    setSwipePlayerStatus,
    setTrackDuration,
    toggleLyricFullScreen,
    resetPlayer,
    setLyricFullScreenStatus,
    setPlayListstatic,
    setPlayerPaginationData,
    disablePlayerPaginationLoader,
    enablePlayerPaginationLoader,
    resetRecentlyListen,
    setQueuePageStatus,
    toggleQueuePage
} = PlayMusicSlice.actions;

export const selectPlayMusic = (state) => state.playMusic.playMusic;
export const selectSeekTime = (state) => state.playMusic.seekTime;
export const selectPlay = (state) => state.playMusic.isPlaying;
export const selectQueueList = (state) => state.playMusic.queueList;
export const selectPlayList = (state) => state.playMusic.playList;
export const selectVolume = (state) => state.playMusic.volume;
export const selectMute = (state) => state.playMusic.mute;
export const selectPlayedFrom = (state) => state.playMusic.playedFrom;
export const selectPlayerSeek = (state) => state.playMusic.playerSeek;
export const selectPlayListClone = (state) => state.playMusic.playListClone;
export const selectPlayerLoop = (state) => state.playMusic.loop;
export const selectPlayerShuffle = (state) => state.playMusic.isShuffle;
export const selectEmitTrackEnd = (state) => state.playMusic.onTrackEnd;
export const selectPlayedPlaylist = (state) => state.playMusic.playlistPlayed;
export const selectRecentlyPlays = (state) => state.playMusic.recentlyListen;
export const selectHeaderPlayData = (state) => state.playMusic.HeaderPlayData;
export const selectWeightCount = (state) => state.playMusic.weightCount;
export const selectPlayerStatus = (state) => state.playMusic.swipePlayerStatus;
export const selectTrackDuration = (state) => state.playMusic.duration;
export const selectPlayListStatic = (state) => state.playMusic.playListStatic;
export const selectPlayerPaginationData = (state) => state.playMusic.playerPaginationData;
export const selectPlayerPaginationLoader = (state) => state.playMusic.playerPaginationLoader;
export const selectLyricFullScreenStatus = (state) =>
    state.playMusic.lyricFullScreenStatus;
export const selectQueueStatus = (state) => state.playMusic.QueuePage;

export default PlayMusicSlice.reducer;
