import { FollowRequestViewType } from '../components/SystemNotification/ViewTypes/FollowRequestViewType';
import { LikedMelopostViewType } from '../components/SystemNotification/ViewTypes/LikedMelopostViewType';
import { MuliRequestViewType } from '../components/SystemNotification/ViewTypes/MuliRequestViewType';
import { TicketViewTypes } from '../components/SystemNotification/ViewTypes/TicketViewTypes';
import { SYSTEM_NOTIFY_TYPE } from '../types/SystemNotifyType';

export const SystemNotifyItemViewType = {
  [SYSTEM_NOTIFY_TYPE.TICKETS]: {
    component: item => <TicketViewTypes item={item} />,
  },
  [SYSTEM_NOTIFY_TYPE.MEDAL]: {
    component: item => <LikedMelopostViewType item={item} />,
  },
  [SYSTEM_NOTIFY_TYPE.MELOPOST_LIKED_TYPE]: {
    component: item => <LikedMelopostViewType item={item} />,
  },
  [SYSTEM_NOTIFY_TYPE.FOLLOW]: {
    component: item => <LikedMelopostViewType item={item} />,
  },
  [SYSTEM_NOTIFY_TYPE.SINGLE_FOLLOW_REQUEST]: {
    component: item => (
      <FollowRequestViewType
        item={{ ...item, token: item?.social_profiles?.[0]?.token }}
      />
    ),
  },
  [SYSTEM_NOTIFY_TYPE.MULTI_FOLLOW_REQUESTS]: {
    component: item => <MuliRequestViewType item={item} />,
  },

  [SYSTEM_NOTIFY_TYPE.USER_COLLECTION_VISITED]: {
    component: item => <LikedMelopostViewType item={item} />,
  },
};
