import React from 'react';
import CommonHeader from '../common/CommonHeader';
import Container from '../PageParentContainer/Container';
import { NoFollowingSvg } from '../svg/profile/NoFollowingSvg';
import { BellSvg } from '../svg/notification/BellSvg';
import { EmptyNotifeView } from './EmptyNotifeView';
import { getInAppNotifications } from '../../services/ApiClient';
import { useQuery } from '@tanstack/react-query';
import { RenderNotifViewType } from './RenderNotifViewType';
import { NoteMusicLoadingContainer } from '../loading/NoteMusicLoadingContainer';

export const SystemNotification = () => {
  const { data, isFetching } = useQuery(
    ['getInAppNotificationsQuery'],
    () => getInAppNotifications(),
    { refetchOnWindowFocus: true },
  );

  const { dynamic: dynamicItem, static: staticItem } =
    data?.data?.result?.in_app_notifications || [];

  const allEmpty = [!dynamicItem?.length, !staticItem?.length].every(Boolean);
  return (
    <Container
      style="p-4"
      shadow={false}
      header={<CommonHeader title="اعلان ها" />}
    >
      {isFetching ? <NoteMusicLoadingContainer /> : null}
      {allEmpty && !isFetching ? (
        <div
          dir="rtl"
          className="w-full h-full flex flex-col !overflow-y-scroll !webkit-overflow-scrolling-touch"
        >
          <EmptyNotifeView />
        </div>
      ) : (
        <div className=" w-full flex flex-col gap-4 mt-2">
          {staticItem?.map((item, index) => (
            <RenderNotifViewType item={item} key={`staic-item-${index}`} />
          ))}
          <div className="w-full h-[1px] bg-primary_dark"></div>
          {dynamicItem?.map((item, index) => (
            <RenderNotifViewType item={item} key={`dynamic-item-${index}`} />
          ))}
        </div>
      )}
    </Container>
  );
};
