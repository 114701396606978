import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FreeMode } from "swiper";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import PopularSongsCard from "../popularSong/PopularSongsCard";
import HomeChildMetaData from "../common/HomeChildMetaData";
import { IsIosDevice } from "../../helper/CheckDevice";
import { FixedSizeList as List } from "react-window";
import { useInView } from "react-intersection-observer";

const Column = ({ data, index, style }) => (
  <div style={{ ...style, right: style.right + 7 + "px" }}>
    <PopularSongsCard
      key={`${data.id}popular`}
      item={data[index]}
      queueList={data}
    />
  </div>
);
function HomeTrackCardItem({ item }) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: [0, 1],
  });

  useEffect(() => {
    console.log(inView, "ref is here list");
    return () => {};
  }, [inView]);
  return IsIosDevice() ? (
    <div ref={ref} className="flex flex-col ">
      {/* <div className="w-full px-3">
        <HomeChildMetaData metaData={item} />
      </div> */}
      <div>
        {inView ? (
          <List
            direction="rtl"
            height={189}
            itemCount={item?.length}
            itemSize={140}
            layout="horizontal"
            width={400}
            style={{ width: "100% !important" }}
            itemData={item}
            className="!pr-[17px] "
          >
            {Column}
          </List>
        ) : (
          <div className="h-[189px] w-full"></div>
        )}
      </div>
    </div>
  ) : (
    <div className="flex flex-col ">
      <div className="slider">
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={16}
          // lazy={true}
          modules={[FreeMode]}
          freeMode={true}
          className="!w-full !h-fit flex flex-col-reverse !select-none new-song-swiper  !overflow-visible"
          cssMode={IsIosDevice()}
        >
          {/* <div className="w-full px-3">
            <HomeChildMetaData metaData={item?.track_items_meta_data} />
          </div> */}

          {item?.map((trackList, index) => (
            <div className="" key={index}>
              <SwiperSlide
                className="!w-fit last:!pl-4 first:!pr-4"
                key={index}
              >
                <div className="flex flex-col ">
                  {/* <!-- persian --> */}
                  <PopularSongsCard item={trackList} queueList={item} />
                </div>
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default HomeTrackCardItem;
