import React from 'react';

export const ShareMelopostPauseIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.60559 0C6.10368 0 4.88672 1.21751 4.88672 2.71887V22.2811C4.88672 23.7836 6.10368 25 7.60559 25C9.10749 25 10.3245 23.783 10.3245 22.2811V2.71887C10.3245 1.21697 9.10804 0 7.60559 0Z"
        fill="white"
      />
      <path
        d="M17.3907 0C15.8888 0 14.6719 1.21751 14.6719 2.71887V22.2811C14.6719 23.783 15.8888 25 17.3907 25C18.8926 25 20.1096 23.783 20.1096 22.2811V2.71887C20.1096 1.21751 18.8926 0 17.3907 0Z"
        fill="white"
      />
    </svg>
  );
};
