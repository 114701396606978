import React, { memo, useEffect, useState } from 'react';
import {useInfiniteQuery, useMutation} from "@tanstack/react-query";
import {getArtistTracks, getUserPublishedTracks,} from "../../services/ApiClient.js";
import InfiniteScroll from "react-infinite-scroll-component";
import ScrollTopButton from "../../components/common/ScrollTopButton.jsx";
import Container from "../../components/PageParentContainer/Container.jsx";
import SongListLoading from "../../components/loading/SongListLoading.jsx";
import CommonHeader from "../../components/common/CommonHeader.jsx";
import UserPublishedSongList from "../../components/playlist/UserPublishedSongList.jsx";
import ExclusiveTrackPageBanner from "../../components/Exclusive/ExclusiveTrackPageBanner.jsx";
import {useLocation} from "react-router-dom";
import HomeSlider from '../../components/home/HomeSlider.jsx';

function UserPublished() {
    const location = useLocation()
    const bannerData = location?.state?.item?.bannerData
    const title = location?.state?.item?.title
    const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
        useInfiniteQuery(
            ["getUserPublishedTracksQuery"],
            ({ pageParam = 0 }) =>
                getUserPublishedTracks({offset: pageParam}),
            {
                getNextPageParam: (lastPage, allPages) => {
                    const allData = allPages?.flatMap(
                        (page) => page?.data?.result?.tracks
                    );
                    return lastPage?.data?.result?.end ? false : allData?.length;
                },
                refetchOnWindowFocus: false,
            }
        );
    const tracksList = data?.pages?.flatMap((page) => page?.data?.result?.tracks);
    const reFetchData = () => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    };


    return (
        <Container header={ <CommonHeader  title={title} hasChevronNavigateBack={true} />} shadow={true} style='bg-primary_dark'>

            <div
                dir="rtl"
                id="user-published"
                className="w-full h-full overflow-scroll hide-scroll-bar pb-10 px-4 bg-background"
            >
                <ScrollTopButton id={"user-published"}/>
                <div className='w-full mt-4 mb-2  '>
                  {bannerData?.banners[0] &&
                    <HomeSlider
                      heightRelation={bannerData?.banner_setting?.height_relation}
                      plan={true}
                      data={bannerData?.banners}
                      metaData={{auto_slide_time: 5}}
                      slideItemContainerClass={'w-full'}
                      slideItemContentClass={'rounded-[10px] '}
                      slideItemActionAreaClass={'!rounded-[10px]'}
                    />


                  }
                </div>
                <InfiniteScroll
                    className='!overflow-visible'
                    next={reFetchData}
                    hasMore={hasNextPage}
                    loader={

                        isFetchingNextPage &&
                            <div className=" mt-2 lg-1440:mt-r8 flex w-full justify-center text-yellow   ">
                                <SongListLoading />
                            </div>

                    }
                    scrollableTarget="user-published"
                    dataLength={tracksList?.length || 0}
                >
                    <div>
                        <UserPublishedSongList
                            scrollId={'user-published'}
                            list={tracksList}
                            titleUnderLine={false}
                            loading={
                                isLoading && !tracksList
                            }
                            apiUrl='/getUserPublishedTracks'
                            endOfPagination={!hasNextPage}
                        />
                    </div>
                </InfiniteScroll>
            </div>
        </Container>
    );
}

export default memo(UserPublished);
