import React from 'react'

export const PrivateProfileIcon = ({height='101',width='80',fill='#4f4f4f',className=''}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M68.2353 37.4682H61.1692V21.3447C61.1692 9.909 51.6694 0.605469 39.9927 0.605469C28.3161 0.605469 18.8163 9.909 18.8163 21.3447V37.4682H11.7647C5.27765 37.4682 0 42.7459 0 49.2329V89.2329C0 95.72 5.27765 100.998 11.7647 100.998H68.2353C74.7224 100.998 80 95.72 80 89.2329V49.2329C80 42.7459 74.7224 37.4682 68.2353 37.4682ZM24.5783 21.3447C24.5783 14.2337 32.6408 6.54109 39.9927 6.54109C47.3447 6.54109 55.4217 14.2337 55.4217 21.3447V37.4682H24.5783V21.3447ZM75.1807 89.2329C75.1807 91.3953 70.3976 96.1796 68.2353 96.1796H11.7647C9.60235 96.1796 5.30121 91.3953 5.30121 89.2329V49.2329C5.30121 47.0706 9.60235 42.6857 11.7647 42.6857H68.2353C70.3976 42.6857 75.1807 47.0706 75.1807 49.2329V89.2329Z"
        fill={fill}
      />
      <path
        d="M40.2845 63C37.3659 63 35 65.3659 35 68.2845C35 70.1481 35.9655 71.7852 37.4227 72.7261V78.9964C37.4227 80.5739 38.7016 81.8529 40.2793 81.8529C41.8568 81.8529 43.1358 80.5739 43.1358 78.9964V72.7328C44.5989 71.793 45.5691 70.1527 45.5691 68.2845C45.5691 65.3659 43.2032 63 40.2845 63Z"
        fill={fill}
      />
    </svg>
  );
}
