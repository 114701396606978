import React from 'react';
import { MelopostReportItem } from './MelopostReportItem';

export const MelopostReportList = ({ list,selectedList,handleSelectItem}) => {
  const isSelected =(item)=>{
    return selectedList?.find((selectedId)=>selectedId===item?.id)
  }

  return (
    <div className='flex flex-col gap-2'>
      {list?.map((item,index)=>{

        return <MelopostReportItem key={`melopost-report-item${item?.id}`} item={item} onClick={()=>handleSelectItem(item?.id)} isSelected={isSelected(item)} />
      })}

  </div>);
};

