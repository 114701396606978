import React, { useState } from 'react';
import ExclusiveTrackCardShimmer from './ExclusiveTrackCardShimmer.jsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import SongListLoading from '../loading/SongListLoading.jsx';
import ExclusiveTrackList from './ExclusiveTrackList.jsx';
import useExclusiveTracksQuery from '../../hooks/useExclusiveTracksQuery.js';

import { djMixSortTypes } from '../../consts/MenuItemsConsts.js';
import SwipeBottomSheet from '../bottomSheets/SwipeBottomSheet.jsx';
import BottomSheetHeader from '../bottomSheets/BottomSheetHeader.jsx';
import SortSvg from '../svg/playList/SortSvg.jsx';
import SortDropDownItem from '../common/SortDropDownItem.jsx';
import clsx from 'clsx';


export const RenderExclusiveTracksList = ({ trackTypes,hasSort = false,hasMarginClass=true,sortMargin=false }) => {
  const [openSortBottomSheet, setOpenSortBottomSheet] = useState();
  const { tracks, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage,sortType,changeSort } =
    useExclusiveTracksQuery(trackTypes);
  const getExclusiveTracksNextPage = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };
  const handleBottomSheetState = (state) => {
    setOpenSortBottomSheet(state);
  };
  const toggleSortTypeBottomSheet = (newOpen) => () => {
    setOpenSortBottomSheet(newOpen);
  };
  const handleSelectSort = (type)=>{
    changeSort(type)
    handleBottomSheetState(false)
  }
  const requestUrl = '/getExclusiveTracks'
  return (
    <div className='flex flex-col gap-4 relative '>
      {hasSort ?
        <div className={clsx('flex w-full justify-between items-center', sortMargin?'!-mt-[17%]':'')}>
          <SwipeBottomSheet
            open={openSortBottomSheet}
            toggleDrawer={toggleSortTypeBottomSheet}
          >
            <BottomSheetHeader
              dividerLine={true}
              HeaderIcon={
                <div className="flex w-[22px] h-[16px]  ">
                  <SortSvg fill={"#FFFFFF"}/>
                </div>
              }
              closeBottomSheet={() => {
                handleBottomSheetState(false);
              }}
              title="ترتیب نمایش "
            />
            <div>
              {djMixSortTypes?.map((item, index) => (
                <div
                  className={`${index === 0 && "pt-[21px]  "}`}
                >
                  <SortDropDownItem
                    key={index}
                    title={item.title}
                    subtitle={item.subtitle}
                    Icon={item.icon}
                    index={item.id}
                    type={item?.type}
                    selectSort={() => {
                      handleSelectSort(item);
                    }}
                  />
                </div>
              ))}
            </div>
          </SwipeBottomSheet>
          <div
            onClick={() => {
              handleBottomSheetState(true);
            }}
          >

            <div className="flex items-center gap-1 px-4">
              <div className="flex gap-2   items-center">
                <div className="flex w-[22px] h-[16px]  ">
                  <SortSvg />
                </div>
                <p className="text-primary font-dana text-[14px] font-medium">
                  ترتیب نمایش:
                </p>
              </div>
              <p className="text-gray_3 font-dana text-[12px] font-medium">
                {sortType?.title}
              </p>
            </div>
          </div>
        </div>
      :
       null
      }

      {isLoading ? (
        <div className={clsx('flex flex-col gap-2 px-2 ',hasMarginClass?'!-mt-[17%]':'')}>
          {Array.from(
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => (
              <ExclusiveTrackCardShimmer />
            )),
          )}
        </div>
      ) : tracks ? (
        <InfiniteScroll
          className={clsx('!overflow-visible',hasMarginClass?'!-mt-[17%]':'')}
          next={getExclusiveTracksNextPage}
          hasMore={hasNextPage}
          dataLength={tracks?.length || 0}
          loader={
            isFetchingNextPage && (
              <div className="w-full flex justify-center items-center -mt-8">
                <SongListLoading />
              </div>
            )
          }
          scrollableTarget="exclusive-playlist-song"
        >
          <div className="pb-10">
            <ExclusiveTrackList
              apiUrl={requestUrl}
              requestPayload={{
                tracks_type: trackTypes,
              }}
              endOfPagination={!hasNextPage}
              pageId={'exclusive-playlist-song'}
              list={tracks}
            />
          </div>
        </InfiniteScroll>
      ) : null}
    </div>
  );
};

