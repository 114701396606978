export const BottomNavigationInvisibleRoutes = [
  '/setting/**',
  '/support/**',
  '/tickets',
  '/about-us',
  '/plans',
  '/payment/**',
  '/merge-number',
  '/premium-page',
  '/gift_successfully-buying',
  '/gift_plan',
  '/edit-gift/**',
  '/buy-gift',
  '/share-gift',
  '/tutorial-gift',
  '/manage-gift',
  '/about-us/p/about-terms',
  '/melopost',

  '/melopost/record',
  '/melopost/upload',
  '/melopost-report',
  '/melopost/share',
  '/meloposts-hashtag-list/**',
];

export const BottomNavigationTabs = {
  'my-melodify': { id: 0, route: 'my-melodify' },
  playlist: { id: 1, route: 'playlist' },
  home: { id: 2, route: 'home' },
  search: { id: 3, route: 'search' },
  downloads: { id: 4, route: 'downloads' },
};
