import { useLike } from '../../helper/useLike.js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  PLAYER_OPTIONS_KEYS,
  TrackOptionsItems,
} from '../../components/common/trackOptions/TrackOptionsItems.jsx';
import { useAction } from '../useAction.js';
import { useQueue } from '../useQueue.js';
import { useShare } from '../useShare.js';
import { shareTypes } from '../../consts/shareTypes.js';
import { useDownload } from '../useDownload.js';
import { db } from '../../indexDB/DB.js';
import { mainToast } from '../../helper/ToastsEmitter.jsx';
import { showBugReportDialog } from '../../redux/player/DropDownSlice.js';
import { resetPlayer } from '../../redux/player/PlayMusicSlice.js';
import { removeFromDownloaded } from '../../redux/download/downloadSlice.js';
import { deleteDownloadedFile } from '../../indexDB/dbQuery.js';
import { useDispatch, useSelector } from 'react-redux';
import { usePlayedTrack } from '../usePlayedTrack.js';
import FileSaver from 'file-saver';
import { useLocation, useParams } from 'react-router-dom';
import { ACTIONS } from '../../consts/ActionsConstants.js';
import { removeUserCollectionTrack } from '../../services/ApiClient.js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setCustomDialog } from '../../redux/dialog/DialogSlice.js';
import { useTelegram } from '../useTelegram.jsx';
import { useUserData } from '../../helper/ReduxSelectorContext.js';
import { selectInitialData } from '../../redux/initialize/InitializeSlice.js';
import { addItemToPlayerSelectedListItem } from '../../redux/adToPlaylist/AddToPlaylistSlice.js';
import { useSelectTrackItem } from '../useSelectTrackItem.js';

export const useTrackOptions = ({
  trackItem,
  isInQueueList,
  isUserPlaylistPage = false,
  pageSort,
                                  inPlayer
}) => {
  const [isLiked, toggleLike] = useLike(trackItem?.id);
  const [multiSelect,setMultiSelect] = useState(false)
  const [openState, setOpenState] = useState(false);
  const addToPlaylistButtonRef = useRef(null);
  const goToArtistBottomSheetRef = useRef(null);
  const [, isDownloaded, , ,] = useDownload(trackItem);
  const [currentTrack, setPlayerTrack] = usePlayedTrack();
  const { id: userCollectionId } = useParams();
  const dispatch = useDispatch();
  const { addTrackToQueue, removeTrackFromQueue } = useQueue();
  const { handleGetShareLink } = useShare(trackItem?.id, shareTypes?.TRACK);
  const { connectToTelegramLoading, connectToTelegram } = useTelegram();
  const { telegram_data } = useSelector(selectInitialData)||{};
  const { streaming_quality } = useUserData() || {};
  const { isSelected, selectItem, deSelectItem } = useSelectTrackItem(
    inPlayer,
    trackItem,
  );
  const action = useAction();
  const location = useLocation();
  const toggleBottomSheetState = newOpen => () => {
    setOpenState(newOpen);
  };
  const handleOpenOptions = e => {
    e?.preventDefault();
    e?.stopPropagation();
    setOpenState(true);
  };
  const handleCloseOptions = e => {
    setOpenState(false);
  };
  const handleGetBlobFile = async item => {
    const data = await db?.downloadedFiles?.get(item?.id);
    if (data?.blob) return data?.blob;
  };
  const handleSaveFileInDevice = () => {
    handleGetBlobFile(trackItem).then(blob => {
      FileSaver.saveAs(blob, trackItem?.download_title + '.mp3');
      mainToast(
        <div dir="rtl" className="text-center">
          موزیک
          <span className="text-[#ffc000] mx-[2px]   ">
            {' '}
            {trackItem?.title}{' '}
          </span>
          ذخیره شد.
        </div>,
      );
    });
  };
  const queryClient = useQueryClient();
  const handleRemoveDownloadFile = async () => {
    if (currentTrack?.id === trackItem?.id) {
      dispatch(resetPlayer());
    }
    await dispatch(removeFromDownloaded({ id: trackItem?.id }));
    await deleteDownloadedFile(trackItem?.id);
    dispatch(showBugReportDialog());
  };
  const telegramRequestData = {
    track_id: trackItem?.id,
    quality: streaming_quality,
    is_demo: trackItem?.is_demo,
  };

  const excludedKeys = useMemo(() => {
    const keys = new Set();
    isLiked
      ? keys.add(PLAYER_OPTIONS_KEYS.LIKE_TRACK)
      : keys.add(PLAYER_OPTIONS_KEYS.DISLIKE_TRACK);
    if (!isInQueueList) keys.add(PLAYER_OPTIONS_KEYS.REMOVE_FROM_QUEUE);
    if (!isDownloaded) {
      keys.add(PLAYER_OPTIONS_KEYS.REMOVE_FROM_DOWNLOAD);
      keys.add(PLAYER_OPTIONS_KEYS.SAVE_IN_DEVICE);
    }
    if (!isUserPlaylistPage) {
      keys.add(PLAYER_OPTIONS_KEYS.DRAG_AND_DROP);
      keys.add(PLAYER_OPTIONS_KEYS.REMOVE_FROM_PLAYLIST);
    }
    return keys;
  }, [isLiked, isInQueueList, isDownloaded, isUserPlaylistPage]);
  const trackOptions = useMemo(() =>
      TrackOptionsItems.filter(({ key }) => !excludedKeys.has(key)),
    [excludedKeys]
  );
  const renderItemInfo = key => {
    if (
      key === PLAYER_OPTIONS_KEYS.SEND_TO_TELEGRAM &&
      telegram_data?.connected_title
    ) {
      return <span dir="ltr">{telegram_data?.connected_title}</span>;
    }
  };

  const handleGoToArtist = () => {
    goToArtistBottomSheetRef?.current?.openGoToArtistBottomSheet();
  };
  const handleAddGroupToPlaylist = () => {
    setMultiSelect(true)
    selectItem(trackItem);
  };
  const handleAddToPlaylist = () => {
    setMultiSelect(false)
    dispatch(addItemToPlayerSelectedListItem({ selectedItem: trackItem }));
    addToPlaylistButtonRef.current.openAddToPlaylistSheet();
  };
  const removeUserCollectionTrackMutation = useMutation(
    data => removeUserCollectionTrack(data),
    {
      onSuccess: response => {
        if (response?.data?.done) {
          queryClient.refetchQueries([
            'getUserCollectionData' + userCollectionId,
          ]);
          queryClient.refetchQueries([
            'getUserPlaylistTracks' + pageSort + userCollectionId,
          ]);
        } else {
          if (response?.data?.error?.custom_dialog) {
            dispatch(
              setCustomDialog({
                custom_dialog: response?.data?.error?.custom_dialog,
              }),
            );
          }
        }
      },
      onSettled: () => {},
    },
  );

  const handleClickItem = (event, item) => {
    event?.stopPropagation();
    event?.preventDefault();
    handleItemAction(item);
    handleCloseOptions(event);
  };
  const handleDragAndDrop = () => {
    action({ target_type: ACTIONS.ENABLE_DRAG_DROP });
  };
  const handleRemoveFromPlaylist = () => {
    if (!userCollectionId) {
      return;
    }
    removeUserCollectionTrackMutation.mutateAsync({
      track_id: trackItem?.id,
      user_collection_id: userCollectionId,
    });
  };


  const handleItemAction = useCallback((item) => {
    const actions = {
      [PLAYER_OPTIONS_KEYS.LIKE_TRACK]: toggleLike,
      [PLAYER_OPTIONS_KEYS.DISLIKE_TRACK]: toggleLike,
      [PLAYER_OPTIONS_KEYS.ADD_TO_QUEUE]: () => addTrackToQueue(trackItem),
      [PLAYER_OPTIONS_KEYS.SHARE]: handleGetShareLink,
      [PLAYER_OPTIONS_KEYS.REMOVE_FROM_QUEUE]: () => removeTrackFromQueue(trackItem),
      [PLAYER_OPTIONS_KEYS.SAVE_IN_DEVICE]: handleSaveFileInDevice,
      [PLAYER_OPTIONS_KEYS.REMOVE_FROM_DOWNLOAD]: handleRemoveDownloadFile,
      [PLAYER_OPTIONS_KEYS.DRAG_AND_DROP]: handleDragAndDrop,
      [PLAYER_OPTIONS_KEYS.REMOVE_FROM_PLAYLIST]: handleRemoveFromPlaylist,
      [PLAYER_OPTIONS_KEYS.SEND_TO_TELEGRAM]: () => connectToTelegram(telegramRequestData, trackItem?.title),
      [PLAYER_OPTIONS_KEYS.ADD_TO_PLAYLIST]: handleAddToPlaylist,
      [PLAYER_OPTIONS_KEYS.ADD_GROUP_TO_PLAYLIST]: handleAddGroupToPlaylist,
      [PLAYER_OPTIONS_KEYS.GO_TO_ARTIST]: handleGoToArtist,
    };

    actions[item?.key]?.();
  }, [
    toggleLike,
    addTrackToQueue,
    removeTrackFromQueue,
    handleGetShareLink,
    handleSaveFileInDevice,
    handleRemoveDownloadFile,
    handleDragAndDrop,
    handleRemoveFromPlaylist,
    connectToTelegram,
    telegramRequestData,
    trackItem,
    handleAddToPlaylist,
    handleAddGroupToPlaylist,
    handleGoToArtist,
  ]);




  return {
    trackOptions,
    handleClickItem,
    openState,
    handleOpenOptions,
    handleCloseOptions,
    toggleBottomSheetState,
    deleteTrackLoading: removeUserCollectionTrackMutation.isLoading,
    renderItemInfo,
    addToPlaylistButtonRef,
    goToArtistBottomSheetRef,
    multiSelect
  };
};
