import React from 'react';

function TwoColorAttachIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className='w-full h-full'
      fill="none"
      viewBox="0 0 18 14"
    >
      <path
        fill="#F2C94C"
        d="m14.272 3.739-4.024-.016-.023.002a4.1 4.1 0 0 1 1.31 1.644l2.73.01A1.64 1.64 0 0 1 15.9 7.027a1.64 1.64 0 0 1-1.648 1.634l-4.024-.016c-.912-.004-1.637-.775-1.634-1.648a.82.82 0 0 0-.817-.823l-.704-.003c-.07.263-.118.535-.12.82-.006 1.718 1.398 3.272 3.246 3.292l.023.002 4.024.016a3.281 3.281 0 0 0 .026-6.562"
      ></path>
      <path
        fill="#E0E0E0"
        d="m6.475 8.63-2.74-.011a1.64 1.64 0 0 1-1.634-1.647 1.64 1.64 0 0 1 1.647-1.634l4.033.016c.912.003 1.638.774 1.634 1.647a.82.82 0 0 0 .817.823l.705.003c.069-.263.118-.535.119-.82.007-1.715-1.392-3.271-3.246-3.292l-.022-.002-4.033-.016a3.281 3.281 0 1 0-.027 6.562l4.033.016.023-.002A4.1 4.1 0 0 1 6.475 8.63"
      ></path>
    </svg>
  );
}

export default TwoColorAttachIcon;
