import React, { useState } from 'react';
import ShareLoading from '../../svg/common/ShareLoading.jsx';
import ShareInstagramIcon from '../../svg/meloPost/ShareInstagramIcon.jsx';
import { useNavigate } from 'react-router-dom';


export const ShareInstagramSection = ({melopostUrl,downloadTitle}) => {

  const [shareInstagramLoading,setShareInstagramLoading] = useState(false)
  const navigate = useNavigate()
  const handleShareToInstagram = () => {
    const imageUrl = 'https://example.com/your-image.png';
    const deepLink = `instagram://share?source_image=${encodeURIComponent(imageUrl)}`;

    let hasNavigated = false;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        hasNavigated = true; // User left the page (Instagram opened)
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Attempt to open Instagram
    window.location.href = deepLink;

    // Fallback if Instagram doesn't open within 2 seconds
    setTimeout(() => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (!hasNavigated) {
        window.location.href = 'https://instagram.com'; // Redirect to Instagram website
      }
    }, 2000);
  };


  return (
    <div onClick={handleShareToInstagram} className='flex rounded-[100px] border-[1px] border-gray_2 h-[37px] gap-[6px] w-[184px] items-center pr-[2px]'>
      <div >
        <div className='flex items-center justify-center w-[30px] h-[30px]'>
          {shareInstagramLoading ?
            <div className='w-full h-full'>
              <ShareLoading/>
            </div>
            :

            <ShareInstagramIcon/>

          }
        </div>
      </div>
      <p className='font-dana text-gray_5 text-[12px] font-[500]'>اشتراک‌گذاری در اینستاگرام</p>
    </div>
  );
};

