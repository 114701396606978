import React, { useState } from 'react';

import CustomLazyLoadImage from '../common/CustomLazyLoadImage';
import ArtistPlaceHolderSvg from '../svg/common/ArtistPlaceHolderSvg';
import TextMarquee from '../common/TextMarquee.jsx';
import { setLanguageDirection } from '../../helper/LanguageDetection.js';
import { CardActionArea } from '@mui/material';
import { useFollow } from '../../helper/useFollow.js';
import useLongPress from '../../hooks/useLongPress.js';
import { useNavigate } from 'react-router-dom';
import { textFontBasedLanguage } from '../../helper/TextFontBasedLanguage.js';
import clsx from 'clsx';
function HomeArtistCard({
  item,
  style = null,
  hasLongPress = false,
  imageSize = 'w-[130px] h-[130px] ',
}) {
  const navigate = useNavigate();
  const [isFollow, toggleFollow] = useFollow(
    item?.id,
    'getFollowedArtistInfiniteQuery',
  );

  const onLongPress = async () => {
    await toggleFollow();
  };

  const onClick = () => {
    openArtist(item);
    console.log('click is triggered');
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };
  const longPressEvent = useLongPress(
    hasLongPress,
    onLongPress,
    onClick,
    defaultOptions,
  );
  const openArtist = item => {
    navigate(`/artist-profile/${item?.id}`, { state: { artistObject: item } });
  };

  return (
    <div {...longPressEvent}>
      <div class="flex flex-col item-center justify-center cursor-pointer">
        <div
          // className=''
          className={clsx(
            imageSize,
            ` aspect-square relative overflow-hidden self-center border-2 border-gray_2  rounded-full flex justify-center items-center`,
          )}
        >
          <CardActionArea className="!z-20 !w-full !h-full ">
            <div className="object-cover z-20 rounded-full w-full h-full overflow-hidden ">
              <CustomLazyLoadImage image={item?.image} />
            </div>
          </CardActionArea>
          <span className="absolute top-0 left-0">
            <ArtistPlaceHolderSvg />
          </span>
        </div>
        <p
          dir={setLanguageDirection(item?.name)}
          class={`text-gray_5 max-w-[100px] line-clamp-1 self-center  font-[500] w-full text-center text-[14px] lg-1440:text-f14  mt-[12px] lg-1440:mt-r12 ${textFontBasedLanguage(
            item?.name,
          )} `}
        >
          {item?.name}
        </p>
      </div>
    </div>
  );
}

export default React.memo(HomeArtistCard);
