import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';
import { HashtagItem } from '../hashtag/HashtagItem.jsx';
import CssShimmer from '../../common/CssShimmer.jsx';
import { HashtagItemShimmer } from './HashtagItemShimmer';

export const ListHashtags = ({ hashtags,handleSelectHashtags,handleClearHashtags,checkIsHashtagSelected,isLoadingHashtags}) => {


  return (
    <Swiper
      dir="rtl"
      className="flex    pr-4 !w-full !select-none  last:pl-2"
      freeMode
      modules={[FreeMode]}
      slidesPerView={'auto'}
      spaceBetween={12}
    >
      <SwiperSlide key='all' className="w-fit">
        <div onClick={handleClearHashtags} className='flex items-center justify-center w-[42px] min-w-[42px] h-[32px ] min-h-[32px] bg-primary_dark rounded-[100px] '>
          <p className="text-[10px] fa-format-400 text-gray_3 ">
            همه
          </p>
        </div>
      </SwiperSlide>
      {isLoadingHashtags ?
        [...Array(5).keys()].map((item,index) => (
            <SwiperSlide key={`shimmer${index}`} className="w-fit">
              <HashtagItemShimmer/>
            </SwiperSlide>
        )
        )
      :
        hashtags?.map((hashtag, index) => (

          <SwiperSlide key={hashtag?.title} className="w-fit">
            <HashtagItem
              title={hashtag?.title}
              isSelected={checkIsHashtagSelected(hashtag)}
              viewCount={hashtag?.used_count}
              hasDeleteIcon={false}
              onSelect={() => handleSelectHashtags(hashtag)}
            />
          </SwiperSlide>
        ))
      }

    </Swiper>
  );
};

