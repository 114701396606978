import React from 'react'

export const ShareMelopostPlayIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.6993 14.8204C31.0126 15.5939 31.0126 17.4934 29.6993 18.2669L11.6853 28.8774C10.352 29.6628 8.67025 28.7015 8.67025 27.1542V5.93317C8.67025 4.38582 10.352 3.42459 11.6853 4.20989L29.6993 14.8204Z"
        fill="white"
      />
    </svg>
  );
}
