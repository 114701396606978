import React, { useRef, useState } from 'react';
import Container from '../PageParentContainer/Container';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperTabs from '../../pages/popularSongs/SwiperTabs';
import AllNewTracksSVG from '../svg/newestSongs/AllNewTracksSVG';
import { isDesktop } from 'react-device-detect';
import { UserMeloPosts } from './UserMeloPosts';
import { UserPlaylists } from './UserPlaylists';
import { useSocialProfile } from '../../hooks/useSocialProfile';
import { EditMelopostBottomsheet } from '../meloPost/list/EditMelopostBottomsheet.jsx';
import clsx from 'clsx';

export const UserSocialData = ({
  social_token,
  setSlideIndex,
  slideIndex,
  refetch,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const { isUserSocialProfile } = useSocialProfile(
    selectedItem?.social_profile?.token,
  );
  const [openMelopostOption, setOpenMelopostOption] = useState(false);
  const swiperRef = useRef(null);

  const tabContents = {
    first: (
      <span className="text-gray_5 font-medium text-sm font-dana">
        {isUserSocialProfile ? 'ملوپست‌های من' : 'ملوپست‌ها'}
      </span>
    ),
    second: (
      <span className="text-gray_5 font-medium text-sm font-dana">
        {isUserSocialProfile ? ' پلی‌لیست‌های من' : 'پلی‌لیست‌ها'}
      </span>
    ),
  };

  const handleChange = value => {
    swiperRef.current?.slideTo(value);
    setSlideIndex(value);
  };

  const handleOpenEdit = item => {
    setSelectedItem(item);
    setOpenMelopostOption(true);
  };

  return (
    <div className="sticky top-0 z-20" slot="container-start">
      <EditMelopostBottomsheet
        refetch={refetch}
        open={openMelopostOption}
        handleOpenEdit={handleOpenEdit}
        handleCloseBottomSheet={() => setOpenMelopostOption(false)}
        setOpen={setOpenMelopostOption}
        isUserSocialProfile={isUserSocialProfile}
        selectedMelopost={selectedItem}
      />

      <SwiperTabs
        slideIndex={slideIndex}
        setSlideIndex={setSlideIndex}
        handleChangeSlide={handleChange}
        className="!bg-background"
        hasTabLine
        tabContents={tabContents}
      />

      <Swiper
        onSwiper={swiper => (swiperRef.current = swiper)}
        cssMode={!isDesktop}
        className="w-full"
        onSlideChange={swiper => setSlideIndex(swiper.activeIndex)}
        initialSlide={1}
      >
        <SwiperSlide
          className={clsx(
            'webkit-overflow-scrolling-touch min-h-[50vh] w-fit overflow-hidden',
            slideIndex === 1 ? 'px-4' : 'px-0',
          )}
        >
          <UserMeloPosts
            social_token={social_token}
            handleOpenEdit={handleOpenEdit}
          />
        </SwiperSlide>

        <SwiperSlide className="webkit-overflow-scrolling-touch min-h-[50vh] w-fit overflow-hidden px-4">
          <div className="h-full overflow-y-scroll overflow-x-hidden webkit-overflow-scrolling-touch">
            <UserPlaylists social_token={social_token} />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
