import CommonHeader from '../../components/common/CommonHeader.jsx';
import Container from '../../components/PageParentContainer/Container.jsx';
import { getInitialData, useUserData } from '../../helper/ReduxSelectorContext.js';
import { useNavigate } from 'react-router-dom';
import SuccessChecked from '../../components/svg/meloPost/SuccessChecked.jsx';
import { CardActionArea } from '@mui/material';


export const SuccessfulMelopostCreate = ()=>{
  const initialData =  getInitialData()
  const melopostSettings = initialData?.melopost_settings
  const navigate = useNavigate()
  const userDataStored = useUserData();
  const openProfile = () => {
    if (userDataStored?.social_token) navigate(`/profile/${userDataStored?.social_token}`);
  };

  return (
    <Container
      style=" bg-background p-4"
      header={<CommonHeader title="ملوپست جدید" />}
    >
      <div dir='rtl' className="flex flex-col gap-[58px] items-center mt-[60px] ">
        <div className="flex flex-col gap-[30px] items-center  ">
          <SuccessChecked />
          <div className="flex flex-col gap-5 items-center">
            <p className="text-gray_5 fa-format-700 text-[16px]">
              {melopostSettings?.success_h1_text}
            </p>
            <div className="flex flex-col gap-[6px] ">
              <p className="text-gray_5 fa-format-700 text-[14px]">
                {melopostSettings?.success_h2_text}
              </p>
              <p  className="text-gray_5 fa-format-700 text-[14px]" >{melopostSettings?.success_h3_text}</p>
            </div>
          </div>
        </div>
        <CardActionArea className="!rounded-[100px] w-[253px] !flex justify-center ">
          <button
            onClick={openProfile}
            type="button"
            className={` text-center items-center inline-flex text-[16px] mx-auto text-primary fa-format-500  border border-primary w-[253px] justify-center rounded-[10px] h-[36px] focus:outline-none !rounded-[100px]`}
          >
            {melopostSettings?.success_button_text}
          </button>
        </CardActionArea>
      </div>
    </Container>
  );
}
