import React, {useEffect, useRef} from 'react';
import AddToPlaylistButtonSvg from "../svg/myMelodify/AddToPlaylistButtonSvg.jsx";
import CreatePlayListBottomSheet from "./CreatePlayListBottomSheet";
import {useLimitation} from "../../hooks/useLimitation.js";
import {setLimitationDialogData} from "../../redux/globalData/globalDataSlice.js";
import {useDispatch} from "react-redux";
import {addToPlaylistData, getLimitations, globalData} from "../../helper/ReduxSelectorContext.js";
import playlistLimitDialogImage from "../../assets/play-list/playlistLimitDialogImage.webp";
import PlusIcon from '../svg/common/PlusIcon.jsx';

function AddToPlaylistYellowBox({inBottomSheet=false,onCloseBottomSheet}) {
    const dispatch=useDispatch()
    const limitations = getLimitations();
    const globalDataObject = globalData()
    const userCollections = globalDataObject?.userCollections
    const createPlaylistBottomSheetRef = useRef(null)
    const {hasPlaylistCountLimit, hasPlayListTrackCountLimit} = useLimitation();
    const openBottomSheetHandler = () => {
        if (hasPlaylistCountLimit()) {
            dispatch(
                setLimitationDialogData({
                    limitationData: {
                        message:limitations?.create_user_playlist?.message,
                        boxAlert:'اتمام ظرفیت ساخت پلی لیست',
                        image:playlistLimitDialogImage,
                    },
                })
            );
        } else {
            createPlaylistBottomSheetRef.current.openCreatePlayList()

        }
    }
    useEffect(() => {
      if(userCollections?.length===0 && inBottomSheet){
          openBottomSheetHandler()
      }
    }, []);

    return (
        <>
            <CreatePlayListBottomSheet closeSheet={onCloseBottomSheet} inBottomSheet={inBottomSheet} ref={createPlaylistBottomSheetRef}/>
            <div onClick={openBottomSheetHandler}
                 className='w-full h-[64px] min-h-[64px] flex rounded-[10px] overflow-hidden cursor-pointer'>
                <div className=" w-[64px] h-[64px] bg-secondary rounded-[10px] !min-h-[64px] aspect-square flex items-center justify-center">
                    <PlusIcon/>
                </div>
                <div className="w-[calc(100%_-_32px)] flex h-full pr-2 items-center">
                    <p className="text-white leading-6  text-[16px] font-normal text-center font-dana  ">
                        افزودن پلی‌لیست
                    </p>
                </div>
            </div>
        </>

    );
}

export default AddToPlaylistYellowBox;
