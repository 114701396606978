import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import SongListLoading from '../../loading/SongListLoading.jsx';
import { MeloPostFullCard } from '../MeloPostFullCard.jsx';
import { NoteMusicLoadingContainer } from '../../loading/NoteMusicLoadingContainer.jsx';
import { MelopostListEmptyView } from './MelopostListEmptyView';
import { EditMelopostBottomsheet } from './EditMelopostBottomsheet.jsx';
import { useSocialProfile } from '../../../hooks/useSocialProfile.js';


export const RenderMelopostList = ({ getMeloPostgIsLoading,getMelopostsFlatMap,getMeloPostNextPage,getMeloPostgHasNextPage,getMeloPostIsFetchingNextPage,handlePlay,activePlayerIndex,refetch }) => {

  const [selectedItem, setSelectedItem] = useState(null)
  const {isUserSocialProfile}  =useSocialProfile(selectedItem?.social_profile?.token)
  const [openMelopostOption, setOpenMelopostOption] = useState(false);
  const handleCloseBottomSheet = ()=>{
    setOpenMelopostOption(false)
  }
  const handleOpenEdit = (item)=>{
    setSelectedItem(item)
    setOpenMelopostOption(true)
  }

   if(getMeloPostgIsLoading){
     return  (
       <div className='relative h-[50vh] bg-black'>
         <NoteMusicLoadingContainer />
       </div>
     )

   }else if(getMelopostsFlatMap && getMelopostsFlatMap?.length>0){
     return (
       <>
         {getMelopostsFlatMap ? (
           <InfiniteScroll
             className={'!overflow-hidden w-full'}
             next={getMeloPostNextPage}
             hasMore={getMeloPostgHasNextPage}
             dataLength={getMelopostsFlatMap?.length || 0}
             loader={
               getMeloPostIsFetchingNextPage && (
                 <div className="w-full flex justify-center items-start h-[50px]   ">
                   <SongListLoading />
                 </div>
               )
             }
             scrollableTarget="page-header"
           >
             <EditMelopostBottomsheet
               refetch={refetch}
               open={openMelopostOption}
               handleOpenEdit={handleOpenEdit}
               handleCloseBottomSheet={handleCloseBottomSheet}
               setOpen={setOpenMelopostOption}
               isUserSocialProfile={isUserSocialProfile}
               selectedMelopost={selectedItem}
             />
             <div className={'w-full h-full !overflow-hidden  mt-3 gap-5'}>
               {getMelopostsFlatMap?.map((item, index) => (
                 <div className={'flex flex-col  pb-5 '}>
                   <MeloPostFullCard
                     index={index}
                     item={item}
                     key={'meloPostFullCard' + index}
                     handlePlay={handlePlay}
                     activePlayerIndex={activePlayerIndex}
                     handleOpenEdit={handleOpenEdit}
                     meloPostItems={getMelopostsFlatMap}
                   />
                 </div>
               ))}
             </div>
           </InfiniteScroll>
         ) : (
           <></>
         )}
       </>
     );


   }else {
     return  <MelopostListEmptyView/>
   }

};

