import React from 'react';
import CustomImage from './CustomImage.jsx';
import { useSelector } from 'react-redux';
import { selectInitialData } from '../../redux/initialize/InitializeSlice.js';
import { useUserData } from '../../helper/ReduxSelectorContext.js';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { CrownSvgType3 } from '../svg/common/CrownSvgType3.jsx';
export const UserAvatar = ({
  className = 'w-[32px] h-[32px] min-w-8',
  crownClassName = 'w-[16px] h-[16px] min-w-[16px]',
  hasAction = true,
  avatar,
  is_premium,
  social_token,
  is_online,
  premium_crown = false,
}) => {
  const initialDataSelector = useSelector(selectInitialData);

  const navigate = useNavigate();
  const openProfile = () => {
    if (social_token) navigate(`/profile/${social_token}`);
  };

  const defaultAvatars = initialDataSelector?.default_avatars;

  const userDefaultAvatar = is_premium
    ? defaultAvatars?.find(avatar => avatar?.type === 'premium')?.image
    : defaultAvatars?.find(avatar => avatar?.type === 'free')?.image;
  const imageUrl = avatar ?? userDefaultAvatar;

  return (
    <div
      onClick={hasAction ? openProfile : null}
      className={clsx(`relative flex `, className)}
    >
      {is_premium && premium_crown ? (
        <span className="absolute right-[-8px] top-[-8px] z-10 bg-black  p-[2px] rounded-full">
          <CrownSvgType3 className={crownClassName} />
        </span>
      ) : null}
      {is_online ? (
        <div className="w-[16px] h-[16px] rounded-full border border-secondary  absolute bottom-0 right-0 z-20 bg-[#2ACB6B]"></div>
      ) : null}
      <div
        className={clsx(
          'w-full h-full relative  !overflow-hidden !rounded-full object-cover',
          is_premium ? 'border border-primary' : '',
        )}
      >
        <CustomImage
          className={'!w-full !h-full'}
          appendBaseUrl={false}
          src={imageUrl}
          alt="profile"
        />
      </div>
    </div>
  );
};

export default UserAvatar;
