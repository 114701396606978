import {createSlice} from "@reduxjs/toolkit";


const globalDataSlice = createSlice({
    name: 'globalData',
    initialState: {
        userActivity: null,
        likedTracks: [],
        likedMeloposts: [],
        likedReels: [],
        followedArtists: [],
        followedUserCollections: [],
        userCollections: null,
        followingCollections: [],
        limitationDialogData: null,
        limitationDialogState: false,
        unfollowDialogState: false,
        unfollowItemId: null,
        playlistUpdateDialogShowed: false,
        expireSoonDialog: false,
        isDragging: false,
        draggingList: [],
        editPlaylistHintDialogShowed: false,
        openModalList:[],
        backClicked:false,
        showedSurvey:null,
        showSurveyDialogState:false,
        followArtistLoading:false,
        followCollectionLoading:false,
        generatedLink:null,
        redirectToTelegramOpenState:false,
    },
    reducers: {
        setLikedTracks: (state, action) => {
            const {likedTracks} = action.payload;
            state.likedTracks = likedTracks;
        },
        setLikedMeloposts: (state, action) => {
            const {likedMeloposts} = action.payload;
            state.likedMeloposts = likedMeloposts;
        },
        setLikedReels: (state, action) => {
            const {likedReels} = action.payload;
            state.likedReels = likedReels;
        },
        setFollowingArtists: (state, action) => {
            const {followedArtists} = action.payload;
            state.followedArtists = followedArtists;
        },
        setFollowUserCollection: (state, action) => {
            const {followedUserCollections} = action.payload;
            state.followedUserCollections = followedUserCollections;
        },
        likeSong: (state, action) => {
            const {id} = action.payload;
            state.likedTracks?.push(id);
        },
        likeMelopost: (state, action) => {
            const {id} = action.payload;
            state.likedMeloposts?.push(id);
        },
        likeReel: (state, action) => {
            const {id} = action.payload;
            state.likedReels?.push(id);
        },
        followArtist: (state, action) => {
            const {id} = action.payload;
            state.followedArtists?.push(id);
        },
        followUserCollection: (state, action) => {
            const {id} = action.payload;
            state.followedUserCollections?.push(id);
        },
        disLikeSong: (state, action) => {
            const {trackIndex} = action.payload;
            state.likedTracks = state.likedTracks.filter((item, index) => index != trackIndex)
        },
        unLikeMelopost: (state, action) => {
            const {melopostIndex} = action.payload;
            state.likedMeloposts = state.likedMeloposts.filter((item, index) => index != melopostIndex)
        },
        dislikeReel: (state, action) => {
            const {reelIndex} = action.payload;
            state.likedReels = state.likedReels.filter((item, index) => index != reelIndex)
        },
        unFollowArtist: (state, action) => {
            const {artistIndex} = action.payload;
            state.followedArtists = state.followedArtists.filter((item, index) => index != artistIndex)
        },
        unFollowUserCollection: (state, action) => {
            const {collectionIndex} = action.payload;
            state.followedUserCollections = state.followedUserCollections.filter((item, index) => index != collectionIndex)
        },
        setUserCollections: (state, action) => {
            const {collections} = action.payload;
            state.userCollections = collections
        },
        deleteUserCollectionItem: (state, action) => {
            const {id} = action.payload;
            state.userCollections = state.userCollections.filter((item, index) => item.id != id)
        },
        editUserCollectionItem: (state, action) => {
            const {collection, id} = action.payload;
            state.userCollections = state.userCollections?.map((item) =>
                item.id === id ? collection : item
            );
        },
        addUserCollection: (state, action) => {
            const {collection} = action.payload;
            state.userCollections = [collection, ...state.userCollections]
        },
        setFollowingCollections: (state, action) => {
            const {collections} = action.payload;
            state.followingCollections = collections;
        },
        followCollection: (state, action) => {
            const {id} = action.payload;
            state.followingCollections?.push(id);
        },
        unFollowCollection: (state, action) => {
            const {collectionIndex} = action.payload;
            state.followingCollections = state.followingCollections.filter((item, index) => index != collectionIndex)
        },
        setLimitationDialogData: (state, action) => {
            const {limitationData} = action.payload;
            state.limitationDialogData = limitationData
            state.limitationDialogState = true;

        },
        clearLimitationDialogData: (state) => {
            state.limitationDialogState = false;
        },
        showUnfollowDialog: (state, action) => {
            const {itemObject} = action.payload
            state.unfollowItem = itemObject;
            state.unfollowDialogState = true;
        },
        hideUnfollowDialog: (state, action) => {
            state.unfollowDialogState = false;
        },
        setPlaylistUpdateDialogShowed: (state, action) => {
            state.playlistUpdateDialogShowed = true;
        },
        showExpireSoonDialog: (state, action) => {
            state.expireSoonDialog = true;
        },
        hideExpireSoonDialog: (state, action) => {
            state.expireSoonDialog = false;
        },
        toggleDragging: (state, action) => {
            const {dragState} = action.payload
            state.isDragging = dragState;
        },
        setDragList: (state, action) => {
            const {list} = action.payload
            state.dragList = list
        },
        deleteItemOfDragList: (state, action) => {
            const {id} = action.payload
            state.dragList = state.dragList.filter((item) => item?.id != id)
        },
        toggleEditDialogHint: (state, action) => {
            state.editPlaylistHintDialogShowed = true
        },
        addItemToOpenModalList: (state, action) => {
            const {item}=action.payload
            state.openModalList.push(item)
        },
        removeItemOfOpenModalList: (state, action) => {
            const {id}=action.payload
            state.openModalList=state.openModalList.filter((item)=>item?.id!==id)
        },
        enableBackClicked: (state, action) => {
          state.backClicked=true
        },
        disableBackClicked: (state, action) => {
          state.backClicked=false
        },
        showSurveyDialog: (state ) => {
            state.showSurveyDialogState = true
            state.showedSurvey =true
        } ,
        hideSurveyDialog: (state) => {
            state.showSurveyDialogState = false
        },
        enableFollowArtistLoading: (state) => {
            state.followArtistLoading = true
        } ,
        disableFollowArtistLoading: (state) => {
            state.followArtistLoading = false
        },
        enableFollowCollectionLoading: (state) => {
            state.followCollectionLoading = true
        },
        disableFollowCollectionLoading: (state) => {
            state.followCollectionLoading = false
        },
        showRedirectToTelegramDialog: (state,action) => {
          const {generatedLink} =action.payload
          state.generatedLink=generatedLink
          state.redirectToTelegramOpenState = true;
        },
        hideRedirectToTelegramDialog: state => {
        state.redirectToTelegramOpenState = false;
        state.generatedLink =null;
        },



    }

})
export const {
    setLikedTracks,
    likeSong,
    disLikeSong,
    setUserCollections,
    setFollowingArtists,
    followArtist,
    unFollowArtist,
    setFollowingCollections,
    followCollection,
    unFollowCollection,
    addUserCollection,
    setLimitationDialogData,
    clearLimitationDialogData,
    hideUnfollowDialog,
    showUnfollowDialog,
    setPlaylistUpdateDialogShowed,
    showExpireSoonDialog,
    hideExpireSoonDialog,
    deleteUserCollectionItem,
    editUserCollectionItem,
    toggleDragging,
    setDragList,
    deleteItemOfDragList,
    toggleEditDialogHint,
    addItemToOpenModalList,
    removeItemOfOpenModalList,
    disableBackClicked,
    enableBackClicked,
    showSurveyDialog,
    hideSurveyDialog,
    enableFollowArtistLoading,
    disableFollowArtistLoading,
    disableFollowCollectionLoading,
    enableFollowCollectionLoading,
    showRedirectToTelegramDialog,
    hideRedirectToTelegramDialog,
    likeMelopost,
    likeReel,
    unLikeMelopost,
  setLikedMeloposts,
  followUserCollection,
  setFollowUserCollection,
  unFollowUserCollection,
    dislikeReel,
    setLikedReels
} = globalDataSlice.actions;
export const selectGlobalData = (state) => state.globalData
export default globalDataSlice.reducer
