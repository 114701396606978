import React, { useEffect, useImperativeHandle, useState } from "react";
import SwipeBottomSheet from '../bottomSheets/SwipeBottomSheet.jsx';
import BottomSheetHeader from '../bottomSheets/BottomSheetHeader.jsx';
import { useFormik } from 'formik';
import {
  addTrackToUserCollection,
  createUserCollection,
} from '../../services/ApiClient.js';
import { CREATE_COLLECTION_VALIDATION_SCHEMA } from '../../consts/Validation.js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  addUserCollection,
  setLimitationDialogData,
} from '../../redux/globalData/globalDataSlice.js';
import { useDispatch } from 'react-redux';
import MusicNoteLoading from '../loading/MusicNoteLoading.jsx';
import { mainToast } from '../../helper/ToastsEmitter.jsx';
import playlistLimitDialogImage from '../../assets/play-list/playlistLimitDialogImage.webp';
import { addToPlaylistData, useUserData } from '../../helper/ReduxSelectorContext.js';
import { useLimitation } from '../../hooks/useLimitation.js';
import { toggleAddToPlaylistLoader } from '../../redux/adToPlaylist/AddToPlaylistSlice.js';
import { useNativeBack } from '../../hooks/useNativeBack.js';

function CreatePlayListBottomSheet({ inBottomSheet, closeSheet }, ref) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const userData = useUserData();
  const [openCreatePlaylist, setOpenCreatePlaylist] = useState(false);
  const [serverValidationError, setServerValidationError] = useState(null);
  const createUserCollectionMutation = useMutation(data =>
    createUserCollection(data),
  );
  const addToPlaylistDataState = addToPlaylistData();
  const { hasPlaylistCountLimit, hasPlayListTrackCountLimit } = useLimitation();
  const trackList =
    addToPlaylistDataState?.playerListSelectedItems?.length > 0
      ? addToPlaylistDataState?.playerListSelectedItems
      : addToPlaylistDataState?.listSelectItems;
  const tracksIds = [];
  trackList.map((track, index) => tracksIds.push(track?.id));
  const handleClickAction = collection => {
    if (inBottomSheet) {
      if (hasPlayListTrackCountLimit()) {
        mainToast(
          'شما کاربر عادی هستید برای افزودن ترک های بیشتر به یک پلی لیست ویژه شوید.',
        );
        return;
      }
      dispatch(toggleAddToPlaylistLoader());
        addToCollectionMutation.mutate({
            user_collection_id: collection?.id,
            added_tracks: tracksIds,
        });
    }
  };
    const addToCollectionMutation = useMutation(
        (addToCollectionData) => addTrackToUserCollection(addToCollectionData),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(["getUserCollectionQuery"]);

                closeSheet();

                if (data?.data?.done)
                    mainToast(
                        <div dir="rtl" className="text-center">
                            به
                            <span className="!text-[#5081a0] mx-[2px]">
                {" "}
                                {data?.data?.result?.user_collection?.title}{" "}
              </span>
                            در پلی‌لیست‌های من اضافه شد.
                        </div>
                    );
                else mainToast(data?.data?.error?.message);
            },
            onSettled: () => {
                dispatch(toggleAddToPlaylistLoader());
            },
        }
    );
  const handleCreatUserCollection = data => {
    createUserCollectionMutation.mutate(data, {
      onSuccess: res => {
        if (
          res?.data?.error?.message ===
          'collection with this name already exists!'
        ) {
            formik.setErrors({ title: "نام پلی لیست تکراریست!" });
            return;
        }
        if (+res?.data?.error?.code === 491) {
          dispatch(
            setLimitationDialogData({
              limitationData: {
                message: res?.data?.error?.message,
                boxAlert: 'اتمام ظرفیت ساخت پلی لیست',
                image: playlistLimitDialogImage,
              },
            }),
          );
          return;
        }
        queryClient.invalidateQueries([`user-playlists`, userData?.social_token])
        dispatch(
          addUserCollection({ collection: res?.data?.result?.user_collection }),
        );

        closeCreatePlayList();
        handleClickAction(res?.data?.result?.user_collection);
      },
    });
  };
  const formik = useFormik({
    initialValues: {
      title: '',
    },

    validationSchema: CREATE_COLLECTION_VALIDATION_SCHEMA,
    onSubmit: data => {
      if (createUserCollectionMutation.isLoading) {
        return;
      }
      handleCreatUserCollection(data);
    },
  });
  useEffect(() => {
    formik.resetForm();
  }, [openCreatePlaylist]);

  useImperativeHandle(ref, () => ({
    openCreatePlayList() {
      setOpenCreatePlaylist(true);
    },
  }));

  function closeCreatePlayList() {
    setOpenCreatePlaylist(false);
  }

  const toggleCreatePlaylistBottomSheet = newOpen => () => {
    setOpenCreatePlaylist(newOpen);
  };
  useNativeBack(openCreatePlaylist, "create-playlist", closeCreatePlayList);
  return (
    <SwipeBottomSheet
      keepMount={false}
      open={openCreatePlaylist}
      toggleDrawer={toggleCreatePlaylistBottomSheet}
    >
      <BottomSheetHeader
        dividerLine={true}
        closeBottomSheet={closeCreatePlayList}
        title="پلی‌لیست جدید"
      />

      <form
        dir="rtl"
        className="flex flex-col  w-full px-4 pb-4 pt-3 relative "
        onSubmit={formik.handleSubmit}
      >
        {createUserCollectionMutation?.isLoading && (
          <div className="absolute bg-secondary opacity-80 top-0 left-0 w-full h-full flex justify-center items-center">
            <div className="w-[120px] h-[120px]">
              <MusicNoteLoading speed={2} />
            </div>
          </div>
        )}
        <div className="flex flex-col  w-full">
          <input
            id="title"
            value={formik.values.title}
            onChange={formik?.handleChange}
            className={`bg-transparent font-dana font-[500] w-full  !border-[1.5px]   rounded-[100px] text-gray_3 text-[16px]  placeholder:text-right  placeholder:text-gray_3 focus:outline-none h-[52px] px-4  mb-4  `}
            placeholder="نام پلی لیست را بنویسید"
          />
          {formik?.errors?.title && (
            <div className="!text-red font-dana text-[12px] font-[400] w-full flex justify-center">
              <p className="!text-red ">{formik.errors.title}</p>
            </div>
          )}
        </div>
        <button
          type="submit"
          className="bg-primary text-[16px] text-title_box font-dana font-[500] leading-6 flex justify-center items-center rounded-[100px] h-[48px] mt-4"
        >
          تایید
        </button>
      </form>
    </SwipeBottomSheet>
  );
}

export default React.forwardRef(CreatePlayListBottomSheet);
