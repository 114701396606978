import React, { useState } from 'react';
import { UserPlaylistRender } from '../common/user/UserPlaylistRender.jsx';


export const UserPlaylists = ({ social_token }) => {

  return (
    <UserPlaylistRender isGrid={true} social_token={social_token} scrollableTarget='scrollbar-user-social' />

  );
};
