import React from 'react';
import MusicPlaceholderSvg from '../svg/common/MusicPlaceholderSvg.jsx';
import PlayingLottieAnim from '../player/PlayingLottieAnim.jsx';
import ClickReaction from '../../helper/ClickReaction.jsx';
import { getIsPlaying } from '../../helper/ReduxSelectorContext.js';
import { usePlayedTrack } from '../../hooks/usePlayedTrack.js';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage.jsx';

function SongCardImageSection({ item, ShowItemIsPlaying }) {
  const isPlaying = getIsPlaying();
  const [currentTrack, setPlayerTrack] = usePlayedTrack();
  return (
    <div className="relative flex justify-center items-center  h-[56px] w-[56px]  min-w-[56px]">
      {item?.image && (
        <CustomLazyLoadImage
          hasEffect={true}
          imageClass="object-cover w-full h-full rounded-[5px]"
          image={item?.image}
        />
      )}
      <div className="rounded-[5px]  overflow-hidden h-[56px] ">
        <MusicPlaceholderSvg className="absolute top-0 left-0" />
      </div>
      {currentTrack?.id == item?.id && ShowItemIsPlaying && (
        <div
          className=" w-full transition-all ease-linear duration-200 absolute flex flex-row rounded-[5px]  overflow-hidden justify-center items-center h-full"
          style={{ backgroundColor: 'rgba(36, 36, 41, 0.4)' }}
        >
          {isPlaying && (
            <div className=" w-[50%] h-[50%]  ">
              <PlayingLottieAnim />
            </div>
          )}
          <>
            {!isPlaying &&
              currentTrack?.id == item?.id &&
              ShowItemIsPlaying && (
                <ClickReaction>
                  <div className=" w-full h-full flex justify-center items-center  ">
                    <svg
                      className="h-[18px]   w-[18px] "
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 11 13"
                    >
                      <path
                        fill="#F2C94C"
                        d="M9.676 4.581c1.477.853 1.477 2.985 0 3.838l-6.102 3.523C2.097 12.795.25 11.73.25 10.023V2.977c0-1.706 1.847-2.772 3.324-1.92l6.102 3.524z"
                      ></path>
                    </svg>
                  </div>
                </ClickReaction>
              )}
          </>
        </div>
      )}
    </div>
  );
}

export default React.memo(SongCardImageSection);
