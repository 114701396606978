import React from 'react';
import { getInitialData } from '../../../helper/ReduxSelectorContext';
import { CardActionArea } from '@mui/material';
import ClickReaction from '../../../helper/ClickReaction';
import lyricIcon from '../../../assets/demo/lyric-demo-icon.webp';
import crownIcon from '../../../assets/demo/crown.svg';
import {
  setSwipePlayerStatus,
  toggleLyricFullScreen,
} from '../../../redux/player/PlayMusicSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomImage from '../../common/CustomImage';

function LyricDemoBox({ fullScreenLyric }) {
  const initalData = getInitialData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleByAccountButtonFullScreen = () => {
    dispatch(setSwipePlayerStatus({ status: false }));
    dispatch(toggleLyricFullScreen());
    navigate('/plans', { state: { openPlayerWhenBack: true } });
  };
  const handleByAccountButton = () => {
    dispatch(setSwipePlayerStatus({ status: false }));
    navigate('/plans', { state: { openPlayerWhenBack: true } });
  };


  return fullScreenLyric ? (
    <div className="w-[92%] absolute z-20 bottom-0  left-[50%] translate-x-[-50%]  mx-auto h-2/4 bg-lyric-demo  rounded-[15px]  flex flex-col justify-end pb-2 items-center px-2 gap-1">
      <div className="p-4 bg-primary bg-opacity-40 rounded-full flex flex-row justify-center items-center">
        <CustomImage
          appendBaseUrl={false}
          className="w-[28px] h-[28px]"
          src={crownIcon}
        />
      </div>
      <span
        dir="rtl"
        className="font-[500] px-2 font-dana text-xs w-[320px] mx-auto text-center leading-5 text-white  "
      >
        {initalData?.lyric_limitations_data?.full_screen_text}
      </span>
      <div className="w-[160px] mt-3">
        <ClickReaction style="w-full self-center ">
          <CardActionArea
            onClick={() => handleByAccountButtonFullScreen()}
            className="!w-full !rounded-[25px]"
          >
            <button className="w-full bg-primary text-black rounded-[6px] h-[40px] text-base font-dana font-[500]  ">
              خرید اشتراک
            </button>
          </CardActionArea>
        </ClickReaction>
      </div>
    </div>
  ) : (
    <div className="w-[92%] mx-auto h-[64px] bg-[#F2C94C4D] rounded-[15px]  flex flex-row justify-between items-center px-2 gap-1">
      <div className="w-1/2">
        <ClickReaction style="w-full self-center ">
          <CardActionArea
            onClick={() => handleByAccountButton()}
            className="!w-full !rounded-[25px]"
          >
            <button className="w-full bg-primary text-black rounded-[25px] h-[40px] text-base font-dana font-[500]  shadow-lyric_demo">
              خرید اشتراک
            </button>
          </CardActionArea>
        </ClickReaction>
      </div>
      <span
        dir="rtl"
        className="font-[400] font-dana text-xs w-1/2 text-white "
      >
        {/* {initalData?.lyric_limitations_data?.full_screen_text} */}
        {initalData?.lyric_limitations_data?.player_text}
      </span>
    </div>
  );
}

export default LyricDemoBox;
