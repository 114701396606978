import { mainToast } from '../helper/ToastsEmitter.jsx';



export const useCopyToClipboard = (globalData) => {
  const globalText = globalData?.text;
  const globalMessage = globalData?.message || 'کپی شد';
  const showToast = globalData?.showToast ?? true;

  const copyToClipboard = async () => {
    console.log(globalData?.text,'data...')
    let text = globalText;
    let message = globalMessage;


    console.log(text,'data...');
    if (text === undefined) {
      mainToast('متنی برای کپی وجود ندارد.');
      return;
    }

    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(text.toString());
        if (showToast) {
          mainToast(message);
        }
      } catch (error) {
        console.error('Error in clipboard operation:', error);
      }
    } else {
      mainToast('پشتیبانی نمیشود.');
    }
  };

  const shareContent = (localData) => {
    let text = globalText;
    let message = globalMessage;

    if (localData !== undefined) {
      if (typeof localData === 'string' || typeof localData === 'number') {
        text = localData;
      } else {
        text = localData.text;
        message = localData.message || message;
      }
    }

    if (text === undefined) {
      mainToast('متنی برای اشتراک گذاری وجود ندارد.');
      return;
    }

    if (navigator.share) {
      try {
        navigator.share({
          title: 'کپی اطلاعت گیرنده',
          text: text.toString(),
        });
      } catch (error) {
        console.error('Error in clipboard operation:', error);
      }
    } else {
      mainToast('پشتیبانی نمیشود.');
    }
  };

  return { copyToClipboard, shareContent };
};
