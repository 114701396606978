import React from 'react';

function LessListenIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      class="w-full h-full"
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill='#BDBDBD'
        d='M18.828 10.687V10c0-4.846-3.96-8.79-8.828-8.79S1.172 5.155 1.172 10v.687A1.76 1.76 0 0 0 0 12.344v4.687c0 .97.789 1.758 1.758 1.758h2.344a.586.586 0 0 0 .585-.586v-7.031a.586.586 0 0 0-.585-.586H2.344V10c0-4.2 3.434-7.617 7.656-7.617S17.656 5.8 17.656 10v.586h-1.758a.586.586 0 0 0-.585.586v7.031c0 .324.262.586.585.586h2.344A1.76 1.76 0 0 0 20 17.031v-4.687a1.76 1.76 0 0 0-1.172-1.657m-15.312 6.93H1.758a.587.587 0 0 1-.586-.586v-4.687c0-.323.263-.586.586-.586h1.758zm15.312-.586a.587.587 0 0 1-.586.586h-1.758v-5.86h1.758c.323 0 .586.264.586.587z'
      ></path>
      <path
        fill='#BDBDBD'
        d='M13.944 12.781c-.113-.341-.378-.562-.674-.562h-.973v-1.133c0-.216-.14-.39-.312-.39-.173 0-.313.174-.313.39v1.524c0 .215.14.39.313.39h1.286c.06 0 .086.05.096.08a.14.14 0 0 1-.023.14l-3.103 3.885c-.122.152-.321.152-.444 0l-3.142-3.886a.14.14 0 0 1-.022-.139c.01-.03.036-.08.097-.08h1.348c.173 0 .312-.175.312-.39v-1.524c0-.216-.14-.39-.312-.39-.173 0-.313.174-.313.39v1.133H6.73c-.297 0-.561.22-.674.563-.113.341-.051.73.158.992l3.143 3.885c.364.455.96.455 1.326-.002l3.104-3.884c.208-.262.27-.65.157-.992m-5.866-4c-.173 0-.313-.175-.313-.39S7.905 8 8.078 8s.312.175.312.39-.14.391-.312.391m0 1.367c-.173 0-.313-.174-.313-.39s.14-.39.313-.39.312.174.312.39-.14.39-.312.39m3.907-1.367c-.173 0-.313-.175-.313-.39s.14-.391.313-.391c.172 0 .312.175.312.39s-.14.391-.312.391m0 1.367c-.173 0-.313-.174-.313-.39s.14-.39.313-.39c.172 0 .312.174.312.39s-.14.39-.312.39'
      ></path>
    </svg>
  );
}

export default LessListenIcon;
