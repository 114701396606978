import React, { useEffect, useState } from 'react';
import MusicNoteLoading from "./MusicNoteLoading.jsx";

import { useBottomNavigation } from '../BottomNavigation/useBottomNavigation.js';

function FullPageNoteLoading({speed='2',showBottomNavigation=false}) {

  const computedStyle = getComputedStyle(document.querySelector('body'));
  const safeAreaInsetBottom = computedStyle.getPropertyValue('--safe-area-inset-bottom');
  const [scrollButtonBottom, setScrollButtonBottom] = useState(0)
  const [InvisibleBottomSheet] = useBottomNavigation();
  const calculateScrollTopBottom = () => {
    let bottom = 0
    bottom += parseInt(safeAreaInsetBottom)

    if (!InvisibleBottomSheet()) {
      bottom += 66
    }
    setScrollButtonBottom(bottom)
  }
  useEffect(()=>{
    calculateScrollTopBottom()
  },[InvisibleBottomSheet])
    return (
        <div

            style={{bottom:showBottomNavigation?scrollButtonBottom+'px':''}}
            className='fixed bg-secondary z-[5002] opacity-80 top-0 left-[50%] translate-x-[-50%] w-full bottom-0 flex justify-center items-center max-w-[520px] mx-auto'>
            <div className='w-[120px] h-[120px]'>
                <MusicNoteLoading speed={speed}/>
            </div>
        </div>
    );
}

export default FullPageNoteLoading;
