import React from 'react';
import CollectionGridShimmer from "../Shimmers/CollegtionGridShimmer.jsx";
import InfiniteScroll from "react-infinite-scroll-component";
import SongListLoading from "../loading/SongListLoading.jsx";
import CategoryCard from "../playlist/CategoryCard.jsx";
import {useNavigate} from "react-router-dom";

function CollectionsList({itemData,isLoading,hasNextPage,getNextPage,requestCollection,openCollection,pageId}) {

    return (
        <div className="mt-r40   lg-1440:-top-r160 left-0 right-0  relative z-[20] ">
            {itemData?.length === 0 && isLoading ? (
                <CollectionGridShimmer count={20} />
            ) : (
                <div className="px-4 ">
                    <InfiniteScroll
                        className="!overflow-visible"
                        dataLength={requestCollection?.length || 0}
                        hasMore={hasNextPage}
                        loader={
                            <div className="mt-4 flex w-full justify-center">
                                <SongListLoading />
                            </div>
                        }
                        scrollableTarget={pageId}
                        next={() => getNextPage()}
                    >
                        <div className="grid grid-cols-2 w-full gap-4 !gap-y-[min(10vw,52px)] pt-4 pb-4  ">
                            {itemData?.map((item) => (
                                <div
                                    onClick={() => {
                                        openCollection(item);
                                    }}
                                >
                                    <CategoryCard
                                        haveMarginTop={false}
                                        height={true}
                                        scale={true}
                                        item={item}
                                    />
                                </div>
                            ))}
                        </div>
                    </InfiniteScroll>
                </div>
            )}
        </div>
    );
}

export default CollectionsList;
