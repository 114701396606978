import { useMutation } from '@tanstack/react-query';
import { getArtistTracksWithExcludedTrackId } from '../../services/ApiClient.js';
import { setPlayerPaginationData, setPlayList, setPlayListstatic } from '../../redux/player/PlayMusicSlice.js';
import { useDispatch } from 'react-redux';

export const useGetMoreTracksById = () =>{
  const dispatch = useDispatch();
  const getArtistTracksWithExcludedTrackIdMutation = useMutation(
    data => getArtistTracksWithExcludedTrackId(data),
    {
      onSuccess: (res, variables) => {
        dispatch(setPlayList({ playList: res?.data?.result?.tracks }));
        dispatch(
          setPlayListstatic({ playListStatic: res?.data?.result?.tracks }),
        );
        dispatch(
          setPlayerPaginationData({
            playerPaginationData: {
              url: '/getMoreTracksByTrackId',
              payload: { track_id: variables?.track_id },
              end: res?.data?.result?.end,
            },
          }),
        );
      },
    },
  );
  const handleGetMoreTrackById = (trackId) =>{
    getArtistTracksWithExcludedTrackIdMutation.mutate({
      offset: 0,
      track_id: trackId,
    });
  }

  return {handleGetMoreTrackById}
}
