import React, { useState } from 'react';
import Container from '../../../PageParentContainer/Container';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getAvatars, setAvatar } from '../../../../services/ApiClient';
import CommonHeader from '../../../common/CommonHeader';
import UserAvatar from '../../../common/UserAvatar';
import { CardActionArea } from '@mui/material';
import { NoteMusicLoadingContainer } from '../../../loading/NoteMusicLoadingContainer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userData } from '../../../../redux/login/LoginSlice';
import { PremiumAvatarDialog } from './PremiumAvatarDialog';
import CssShimmer from '../../../common/CssShimmer';

export const UpdateAvatar = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, isLoading: getAvatarsLoading } = useQuery(['getAvatars'], () =>
    getAvatars(),
  );

  const { mutate, isLoading } = useMutation(avatar_id => setAvatar(avatar_id), {
    onSuccess: res => {
      if (res?.data?.done) {
        dispatchUserData(res?.data?.result?.user);
        navigate(-1);
      }
    },
  });
  const handleChooseAvatar = avatar_id => {
    mutate(avatar_id);
  };

  const dispatchUserData = Data => {
    dispatch(
      userData({
        user: Data,
      }),
    );
  };

  const handleOpenPremiumDialog = () => {
    setOpen(true);
  };
  return (
    <>
      <Container
        style="bg-background"
        header={<CommonHeader title="انتخاب آواتار" />}
      >
        {getAvatarsLoading ? (
          <div
            dir="rtl"
            className="w-full grid grid-cols-4 gap-4 justify-start items-end p-4 mb-8"
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 4, 5, 6, 7, 8, 4, 5, 6, 7, 8]?.map(
              item => (
                <CssShimmer
                  shimStart={'-250%'}
                  shimEnd={'250%'}
                  reverse={true}
                  classNames={'h-[110px] w-full rounded-full'}
                  duration={'0.7s'}
                />
              ),
            )}
          </div>
        ) : (
          <div
            dir="rtl"
            className="w-full grid grid-cols-4 gap-4 justify-start items-end p-4 mb-8"
          >
            {data?.data?.result?.avatars?.map((item, index) => (
              <CardActionArea>
                <div
                  onClick={() =>
                    item?.is_premium
                      ? handleOpenPremiumDialog()
                      : handleChooseAvatar(item?.id)
                  }
                  key={item?.id}
                  className="relative  "
                >
                  <UserAvatar
                    className="!w-full !h-full !min-w-[70px] !min-h-[70px]"
                    avatar={item?.image}
                    is_premium={false}
                  />
                  {item?.is_premium ? (
                    <div className="z-10 absolute -bottom-2  rounded-full flex flex-row justify-center items-center left-[50%] translate-x-[-50%] ">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2683_13174)">
                          <path
                            d="M15.8346 7.01805V5.83137C15.8346 2.60973 13.223 -0.00195312 10.0013 -0.00195312C6.77961 -0.00195312 4.16797 2.60973 4.16797 5.83137V7.01805C2.65133 7.67996 1.67016 9.1766 1.66797 10.8314V15.8314C1.6707 18.1314 3.53457 19.9953 5.83461 19.998H14.1679C16.468 19.9953 18.3318 18.1314 18.3346 15.8314V10.8314C18.3325 9.1766 17.3513 7.67996 15.8346 7.01805ZM10.8346 14.1647C10.8346 14.625 10.4615 14.998 10.0013 14.998C9.54106 14.998 9.16797 14.625 9.16797 14.1647V12.498C9.16797 12.0378 9.54106 11.6647 10.0013 11.6647C10.4615 11.6647 10.8346 12.0378 10.8346 12.498V14.1647ZM14.168 6.66473H5.83461V5.83141C5.83461 3.53023 7.70008 1.66473 10.0013 1.66473C12.3025 1.66473 14.168 3.5302 14.168 5.83141V6.66473Z"
                            fill="#BDBDBD"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2683_13174">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 -0.00195312)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  ) : null}
                </div>
              </CardActionArea>
            ))}
          </div>
        )}

        {isLoading ? <NoteMusicLoadingContainer /> : null}
      </Container>
      <PremiumAvatarDialog open={open} setOpen={setOpen} />
    </>
  );
};
