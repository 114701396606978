import React from 'react';
import CustomLazyLoadImage from '../../components/common/CustomLazyLoadImage.jsx';
import { useAction } from '../../hooks/useAction.js';
import PlaceHolderWithTitle from '../../components/common/PlaceHolderWithTitle.jsx';
import { CardActionArea } from '@mui/material';
import ClickReaction from '../../helper/ClickReaction';
import useWindowDimensions from '../../helper/ScreenSize.js';
import { HeightRatio } from '../../helper/HeightRatio.js';

function GeneralCollectionCardItem({ item }) {
  const action = useAction();
  const { width } = useWindowDimensions();
  const handleSelectItem = collection => {
    action({
      target_type: "collection_category",
      children:item,
      subtitle:item?.subtitle,
      subtitle_color:'#B0B0B5',
    });
  };

  return (
    <div
      onClick={() => {
        handleSelectItem(item);
      }}
      className=" rounded-[10px]  "
    >
      <ClickReaction>
        <CardActionArea className=" !rounded-[10px]">
          <div
            className="aspect-square  rounded-[10px]  flex items-center justify-center relative overflow-hidden min-w-[94px]  min-h-[94px]"
            style={{
              width: HeightRatio(width, 3.8),
              height: HeightRatio(width, 3.8),
            }}
          >
            <CustomLazyLoadImage image={item?.image} />
            <PlaceHolderWithTitle title={item?.title} />
          </div>
        </CardActionArea>
      </ClickReaction>
    </div>
  );
}

export default GeneralCollectionCardItem;
