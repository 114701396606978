import React from 'react';
import RenderShare from '../../common/RenderShare.jsx';
import { getInitialData } from '../../../helper/ReduxSelectorContext.js';
import ShareSvg from '../../svg/common/ShareSvg.jsx';

export const  SharePlaylistButton = ({ id,type }) => {
  const { setting } = getInitialData() || {};

  return (
    <div className="flex flex-col gap-[6px] ">
      <div class="flex justify-between items-center w-full gap-3">
        {setting?.enable_playlist_share &&
          <div className='flex h-[20px] aspect-square'>
            <RenderShare shareLoadingStyle='w-full h-full' id={id} type={type}>
              <div className='w-5 h-5'>
              <ShareSvg fill='#fff' />
              </div>
            </RenderShare>
          </div>
        }
      </div>
      <p className="text-gray_6 text-[10px] fa-format-500">ارسال</p>
    </div>
  );
}

