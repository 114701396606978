import React from 'react';
import { HeaderActionSection } from './HeaderActionSection.jsx';


export const HeaderDetailSection = ({ isMainTab,children }) => {
  return (

      isMainTab?
        <HeaderActionSection/>

        :
        children

  )
};

