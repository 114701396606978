import React from 'react';

function HeaderSettingIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      fill="none"
      viewBox="0 0 19 20"
    >
      <path
        fill="#fff"
        d="M9.306 6.429A3.575 3.575 0 0 0 5.734 10a3.575 3.575 0 0 0 3.572 3.571A3.575 3.575 0 0 0 12.877 10a3.575 3.575 0 0 0-3.571-3.571m0 5.714A2.146 2.146 0 0 1 7.163 10c0-1.181.961-2.143 2.143-2.143s2.143.962 2.143 2.143a2.146 2.146 0 0 1-2.143 2.143"
      ></path>
      <path
        fill="#fff"
        d="m17.539 11.454-.376-.217A1.41 1.41 0 0 1 16.448 10c0-.516.268-.98.715-1.237l.375-.217a2.14 2.14 0 0 0 .785-2.927l-.715-1.238a2.145 2.145 0 0 0-2.927-.784l-.375.217a1.41 1.41 0 0 1-1.429 0 1.42 1.42 0 0 1-.714-1.238v-.433A2.145 2.145 0 0 0 10.02 0H8.591A2.145 2.145 0 0 0 6.45 2.143v.434c0 .517-.268.979-.715 1.237a1.41 1.41 0 0 1-1.428 0l-.376-.217a2.146 2.146 0 0 0-2.928.784L.288 5.62a2.14 2.14 0 0 0 .784 2.927l.377.217c.447.258.714.72.714 1.237 0 .516-.267.98-.714 1.237l-.376.217a2.14 2.14 0 0 0-.784 2.927l.714 1.238a2.146 2.146 0 0 0 2.927.784l.376-.217c.447-.259.981-.257 1.428 0 .447.259.715.721.715 1.238v.433C6.449 19.04 7.41 20 8.59 20h1.429a2.145 2.145 0 0 0 2.143-2.143v-.434c0-.517.267-.979.714-1.237a1.41 1.41 0 0 1 1.429 0l.375.217a2.146 2.146 0 0 0 2.928-.784l.714-1.238a2.14 2.14 0 0 0-.785-2.927m-.453 2.213-.715 1.237a.714.714 0 0 1-.975.262l-.376-.217a2.83 2.83 0 0 0-2.857 0 2.83 2.83 0 0 0-1.429 2.474v.434c0 .394-.32.714-.714.714H8.591a.715.715 0 0 1-.714-.714v-.434a2.83 2.83 0 0 0-1.428-2.474 2.83 2.83 0 0 0-2.858 0l-.375.217a.713.713 0 0 1-.976-.262l-.714-1.237a.714.714 0 0 1 .261-.976l.376-.217A2.83 2.83 0 0 0 3.59 10a2.83 2.83 0 0 0-1.428-2.474l-.376-.217a.714.714 0 0 1-.261-.976l.714-1.237a.715.715 0 0 1 .976-.262l.375.217a2.83 2.83 0 0 0 2.858 0 2.83 2.83 0 0 0 1.428-2.474v-.434c0-.394.32-.714.714-.714h1.429c.394 0 .714.32.714.714v.434c0 1.033.534 1.958 1.429 2.474a2.83 2.83 0 0 0 2.857 0l.376-.217a.714.714 0 0 1 .975.262l.715 1.237c.2.346.075.781-.262.976l-.376.217A2.83 2.83 0 0 0 15.02 10c0 1.033.534 1.958 1.428 2.474l.376.217c.337.195.462.63.262.976"
      ></path>
    </svg>
  );
}

export default HeaderSettingIcon;
