import React from 'react';

function PremiumAccountCrown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="43"
      fill="none"
      viewBox="0 0 43 43"
    >
      <rect
        width="43"
        height="43"
        fill="#5B3A9F"
        fillOpacity="0.8"
        rx="21.5"
      ></rect>
      <path
        fill="#FFD961"
        d="M30.452 25.877H13.193a.86.86 0 0 1-.83-.638l-2.41-9.04a.859.859 0 0 1 1.214-.99l6.592 3.297 3.295-6.592a.859.859 0 0 1 1.537 0l3.296 6.592 6.592-3.296a.859.859 0 0 1 1.214.99l-2.41 9.04a.86.86 0 0 1-.83.637"
      ></path>
      <path
        fill="#FFB300"
        d="m31.282 25.24 2.41-9.04a.859.859 0 0 0-1.213-.99l-6.592 3.296-3.296-6.592a.86.86 0 0 0-.768-.475v14.438h8.63c.388 0 .729-.262.83-.638M29.174 29.63H14.471c-.388 0-.729-.148-.829-.36l-1.267-2.675H31.27l-1.268 2.676c-.1.211-.44.358-.829.358"
      ></path>
      <path
        fill="#FF8D00"
        d="m30.003 29.27 1.268-2.675h-9.448v3.034h7.351c.389 0 .729-.147.83-.358"
      ></path>
    </svg>
  );
}

export default PremiumAccountCrown;
