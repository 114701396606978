import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { IsIosDevice } from '../../helper/CheckDevice';
import { FreeMode } from 'swiper';
import 'swiper/css/free-mode';
import { SearchTabBarItem } from '../../consts/SearchTabBarItem';
import { useDispatch } from 'react-redux';
import { setSelectedTab } from '../../redux/search/SearchSlice';
import { geSearchSelectedTab } from '../../helper/ReduxSelectorContext';
import { useEffect } from 'react';
import { CardActionArea } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
function SearchTabBar() {
  const dispatch = useDispatch();
  const selectedTab = geSearchSelectedTab();
  const navigate = useNavigate();
  const location = useLocation();
  const tabBarItem = [
    {
      title: 'همه',
      Key: SearchTabBarItem.ALL,
    },
    {
      title: 'آهنگ',
      Key: SearchTabBarItem.TRACKS,
    },
    {
      title: 'پلی لیست',
      Key: SearchTabBarItem.PLAYLISTS,
    },
    {
      title: 'خواننده',
      Key: SearchTabBarItem.ARTISTS,
    },
    {
      title: 'آلبوم',
      Key: SearchTabBarItem.ALBUMS,
    },
    {
      icon: (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_6276_3623)">
            <path
              d="M8 16C5.86312 16 3.85416 15.1678 2.34313 13.6569C0.832156 12.1458 0 10.1369 0 8C0 5.86312 0.832156 3.85416 2.34313 2.34313C3.85416 0.832156 5.86312 0 8 0C10.1369 0 12.1458 0.832156 13.6569 2.34313C15.1678 3.85416 16 5.86312 16 8C16 10.1369 15.1678 12.1458 13.6569 13.6569C12.1458 15.1678 10.1369 16 8 16ZM8 1.25C4.27803 1.25 1.25 4.27803 1.25 8C1.25 11.722 4.27803 14.75 8 14.75C11.722 14.75 14.75 11.722 14.75 8C14.75 4.27803 11.722 1.25 8 1.25ZM7.96875 8.59375C6.31453 8.59375 4.96875 7.24797 4.96875 5.59375C4.96875 3.93953 6.31453 2.59375 7.96875 2.59375C9.62297 2.59375 10.9688 3.93953 10.9688 5.59375C10.9688 7.24797 9.62297 8.59375 7.96875 8.59375ZM7.96875 3.84375C7.00378 3.84375 6.21875 4.62881 6.21875 5.59375C6.21875 6.55869 7.00378 7.34375 7.96875 7.34375C8.93372 7.34375 9.71875 6.55869 9.71875 5.59375C9.71875 4.62881 8.93372 3.84375 7.96875 3.84375ZM11.9285 12.2335C12.1956 12.0148 12.2347 11.621 12.016 11.3539C11.1322 10.275 9.82638 9.65625 8.43328 9.65625H7.69172C6.29862 9.65625 4.99278 10.275 4.10903 11.354C3.89031 11.621 3.92947 12.0148 4.19653 12.2335C4.46356 12.4523 4.85734 12.4131 5.07609 12.1461C5.72141 11.3581 6.67478 10.9062 7.69172 10.9062H8.43328C9.45022 10.9062 10.4036 11.3581 11.0489 12.146C11.1725 12.2969 11.3519 12.375 11.5328 12.375C11.6722 12.375 11.8123 12.3287 11.9285 12.2335Z"
              fill="#BDBDBD"
            />
          </g>
          <defs>
            <clipPath id="clip0_6276_3623">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      active_icon: (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 16C5.86312 16 3.85416 15.1678 2.34313 13.6569C0.832156 12.1458 0 10.1369 0 8C0 5.86312 0.832156 3.85416 2.34313 2.34313C3.85416 0.832156 5.86312 0 8 0C10.1369 0 12.1458 0.832156 13.6569 2.34313C15.1678 3.85416 16 5.86312 16 8C16 10.1369 15.1678 12.1458 13.6569 13.6569C12.1458 15.1678 10.1369 16 8 16ZM8 1.25C4.27803 1.25 1.25 4.27803 1.25 8C1.25 11.722 4.27803 14.75 8 14.75C11.722 14.75 14.75 11.722 14.75 8C14.75 4.27803 11.722 1.25 8 1.25ZM7.96875 8.59375C6.31453 8.59375 4.96875 7.24797 4.96875 5.59375C4.96875 3.93953 6.31453 2.59375 7.96875 2.59375C9.62297 2.59375 10.9688 3.93953 10.9688 5.59375C10.9688 7.24797 9.62297 8.59375 7.96875 8.59375ZM7.96875 3.84375C7.00378 3.84375 6.21875 4.62881 6.21875 5.59375C6.21875 6.55869 7.00378 7.34375 7.96875 7.34375C8.93372 7.34375 9.71875 6.55869 9.71875 5.59375C9.71875 4.62881 8.93372 3.84375 7.96875 3.84375ZM11.9285 12.2335C12.1956 12.0148 12.2347 11.621 12.016 11.3539C11.1322 10.275 9.82638 9.65625 8.43328 9.65625H7.69172C6.29862 9.65625 4.99278 10.275 4.10903 11.354C3.89031 11.621 3.92947 12.0148 4.19653 12.2335C4.46356 12.4523 4.85734 12.4131 5.07609 12.1461C5.72141 11.3581 6.67478 10.9062 7.69172 10.9062H8.43328C9.45022 10.9062 10.4036 11.3581 11.0489 12.146C11.1725 12.2969 11.3519 12.375 11.5328 12.375C11.6722 12.375 11.8123 12.3287 11.9285 12.2335Z"
            fill="#303030"
          />
        </svg>
      ),
      title: 'کاربران',
      Key: SearchTabBarItem.USER,
    },
    {
      icon: (
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_6276_3650)">
            <path
              d="M8.50586 13.6212H2.41431C2.10976 13.6537 1.8355 13.3094 1.92443 13.0163C1.93547 12.98 1.94208 12.958 1.94208 12.958C2.55507 10.0482 5.00046 8.06897 8.00028 8.00004C8.05008 8.00187 8.12826 8.00004 8.17864 8.00004C10.3918 8.00004 12.1925 6.15398 12.1925 3.96671C12.1925 1.77944 10.3918 0 8.17864 0C5.96532 0 4.00028 1.77944 4.00028 3.96671C4.00028 5.26486 4.79906 6.41945 5.77752 7.14349C4.88114 7.43504 4.03988 7.8886 3.30376 8.48697C1.95407 9.58414 1.01562 11.1145 0.661337 12.7961C0.550314 13.3231 0.683357 13.8642 1.02623 14.281C1.36737 14.6956 1.87337 14.9334 2.41431 14.9334H8.50586C8.83178 14.9334 9.14314 14.608 9.14314 14.2858C9.14314 13.9636 8.83178 13.6212 8.50586 13.6212ZM5.3453 3.96671C5.3453 2.42281 6.61624 1.31683 8.17864 1.31683C9.74092 1.31683 10.8574 2.42281 10.8574 3.96671C10.8574 5.48851 9.77712 6.7306 8.24562 6.76591C8.2236 6.76341 8.20135 6.76181 8.17864 6.76181C8.13252 6.76181 8.08652 6.7625 8.04052 6.76329C6.54211 6.69197 5.3453 5.46492 5.3453 3.96671Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.8159 3.07567C17.923 3.59958 18.611 4.59988 18.611 5.68628V6.9705C18.611 7.30175 18.9222 7.57049 19.3062 7.57049C19.6902 7.57049 20.0014 7.30175 20.0014 6.9705V5.68628C20.0014 4.16145 19.0368 2.75802 17.4842 2.02333L15.4784 1.07443C15.0162 0.855839 14.4491 1.1449 14.4491 1.60052V8.22002H13.0564C11.5294 8.22002 10.2871 9.2922 10.2871 10.61C10.2871 11.9038 11.4961 13 13.0703 13C14.5972 13 15.8396 11.9278 15.8396 10.61V2.61364L16.8159 3.07567ZM17.2092 2.46251L15.2034 1.51361L17.2092 2.46251ZM14.449 9.42002V10.61C14.449 11.3493 14.0995 11.7993 13.0564 11.8C12.1933 11.7993 11.7167 11.3493 11.6775 10.61C11.7167 9.99941 12.1933 9.54944 13.0564 9.42002L14.449 9.42002Z"
              fill="#BDBDBD"
            />
          </g>
          <defs>
            <clipPath id="clip0_6276_3650">
              <rect
                width="19.4286"
                height="16"
                fill="white"
                transform="translate(0.572266)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      active_icon: (
        <svg
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_6276_3619)">
            <path
              d="M8.50586 13.6212H2.41431C2.10976 13.6537 1.8355 13.3094 1.92443 13.0163C1.93547 12.98 1.94208 12.958 1.94208 12.958C2.55507 10.0482 5.00046 8.06897 8.00028 8.00004C8.05008 8.00187 8.12826 8.00004 8.17864 8.00004C10.3918 8.00004 12.1925 6.15398 12.1925 3.96671C12.1925 1.77944 10.3918 0 8.17864 0C5.96532 0 4.00028 1.77944 4.00028 3.96671C4.00028 5.26486 4.79906 6.41945 5.77752 7.14349C4.88114 7.43504 4.03988 7.8886 3.30376 8.48697C1.95407 9.58414 1.01562 11.1145 0.661337 12.7961C0.550314 13.3231 0.683357 13.8642 1.02623 14.281C1.36737 14.6956 1.87337 14.9334 2.41431 14.9334H8.50586C8.83178 14.9334 9.14314 14.608 9.14314 14.2858C9.14314 13.9636 8.83178 13.6212 8.50586 13.6212ZM5.3453 3.96671C5.3453 2.42281 6.61624 1.31683 8.17864 1.31683C9.74092 1.31683 10.8574 2.42281 10.8574 3.96671C10.8574 5.48851 9.77712 6.7306 8.24562 6.76591C8.2236 6.76341 8.20135 6.76181 8.17864 6.76181C8.13252 6.76181 8.08652 6.7625 8.04052 6.76329C6.54211 6.69197 5.3453 5.46492 5.3453 3.96671Z"
              fill="#303030"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.8159 3.07567C17.923 3.59958 18.611 4.59988 18.611 5.68628V6.9705C18.611 7.30175 18.9222 7.57049 19.3062 7.57049C19.6902 7.57049 20.0014 7.30175 20.0014 6.9705V5.68628C20.0014 4.16145 19.0368 2.75802 17.4842 2.02333L15.4784 1.07443C15.0162 0.855839 14.4491 1.1449 14.4491 1.60052V8.22002H13.0564C11.5294 8.22002 10.2871 9.2922 10.2871 10.61C10.2871 11.9038 11.4961 13 13.0703 13C14.5972 13 15.8396 11.9278 15.8396 10.61V2.61364L16.8159 3.07567ZM17.2092 2.46251L15.2034 1.51361L17.2092 2.46251ZM14.449 9.42002V10.61C14.449 11.3493 14.0995 11.7993 13.0564 11.8C12.1933 11.7993 11.7167 11.3493 11.6775 10.61C11.7167 9.99941 12.1933 9.54944 13.0564 9.42002L14.449 9.42002Z"
              fill="#303030"
            />
          </g>
          <defs>
            <clipPath id="clip0_6276_3619">
              <rect
                width="19.4286"
                height="16"
                fill="white"
                transform="translate(0.572266)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      title: 'پلی‌لیست کاربران',
      Key: SearchTabBarItem.USER_COLLECTION,
    },
  ];
  const handleSelectedTab = item => {
    dispatch(setSelectedTab({ tab: item?.Key }));
    navigate('#' + item?.Key, { replace: true });
  };

  useEffect(() => {
    if (location?.hash)
      dispatch(setSelectedTab({ tab: location?.hash?.split('#')[1] }));
  }, [location]);

  return (
    <Swiper
      slidesPerView={'auto'}
      spaceBetween={8}
      modules={[FreeMode]}
      freeMode={true}
      className=" mb-4 !h-fit !w-full !select-none new-song-swiper  !overflow-visible "
      cssMode={IsIosDevice()}
    >
      {tabBarItem?.map((item, index) => (
        <div className="flex flex-row " key={index}>
          <SwiperSlide className="!w-fit last:!pl-4 first:!pr-4">
            <CardActionArea
              className="!rounded-[10px]"
              onClick={() => handleSelectedTab(item)}
            >
              <div
                className={`${
                  selectedTab === item.Key
                    ? 'bg-primary text-black_2'
                    : 'bg-secondary text-gray_4'
                } transition-colors duration-150 ease-in-out  whitespace-nowrap  font-dana text-sm font-[500] gap-2 items-center flex justify-center items-center py-[6px] px-4 rounded-[10px]`}
              >
                {selectedTab === item.Key ? item?.active_icon : item?.icon}
                {item?.title}
              </div>
            </CardActionArea>
          </SwiperSlide>
        </div>
      ))}
    </Swiper>
  );
}

export default SearchTabBar;
