import React, { useEffect, useMemo, useState } from 'react';
import PlayListCard from '../../components/playlist/PlayListCard';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {
  getCollectionHome,
  getCollectionsPageElements,
} from '../../services/ApiClient.js';
import { useQuery } from '@tanstack/react-query';
import {
  foreignCategory,
  persianCategory,
} from '../../consts/PlayListMainCategoryConst.jsx';
import RenderPlayListData from '../../components/playlist/RenderPlayListData.jsx';
import HomeCollectionWithCorner from '../../components/home/HomeCollectionWithCorner.jsx';
import { convertPlayListObject } from '../../helper/ConvertPlayListObject.js';
import PlaylistShimmer from './PlaylistShimmer.jsx';
import PlayListImageContainer from './PlayListImageContainer.jsx';
import Container from '../../components/PageParentContainer/Container';
import AbsoluteContainerBG from '../../components/PageParentContainer/AbsoluteContainerBG';
import CommonHeader from '../../components/common/CommonHeader';
import PlaylistCardsShimmer from '../../components/playlist/PlaylistCardsShimmer.jsx';
import { useScrollPosition } from '../../hooks/useScrollPosition.js';
import { useNavigate } from 'react-router-dom';
import RenderHomeData from '../../components/home/RenderHomeData.jsx';

function PlayList() {
  const [Error, setError] = useState(false);
  const navigate  = useNavigate()
  const { data: collectionData } = useQuery(
    ['getCollectionsPageElements'],
    () => getCollectionsPageElements(),
    {
      onSuccess: response => {},
      onError: Error => {
        setError(true);
      },
    },
  );


  useScrollPosition({ pageId: 'page-header' });
  const memoizedCollectionSections = useMemo(
    () =>
      collectionData?.data?.result?.elements?.map((item, index) => (
        <RenderHomeData
          key={`renderPlaylistData${index}${item?.id}`}
          item={item}
        />
      )),
    [collectionData?.data?.result?.elements],
  );
  const memoizedSpecialPlaylist = useMemo(() => {
    const specialPlaylist = collectionData?.data?.result?.special;
    return specialPlaylist ? (
      <div className="py-6">
        <HomeCollectionWithCorner
          cardStyle="!w-[120px] !h-[120px]"
          artistCollection={true}
          fromMyMelodify={true}
          key={'homeCollectionWithCorner-playlist'}
          item={convertPlayListObject(specialPlaylist, true)}
        />
      </div>
    ) : null;
  }, [collectionData?.data?.result?.special]);
  return (
    <Container
      header={
        <CommonHeader hasChevronNavigateBack={false} title={'پلی لیست ها'} />
      }
    >
      <AbsoluteContainerBG />

      <div className="flex flex-col pb-r48 z-20 pb-4">

        {!collectionData?.data?.result && !Error && <PlaylistShimmer />}
        {/* {memoizedSpecialPlaylist} */}
        <div className="flex flex-col ">{memoizedCollectionSections}</div>
      </div>
    </Container>
  );
}

export default PlayList;
