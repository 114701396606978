import React, { useState } from 'react';
import VisitCountIcon from '../../svg/playList/VisitCountIcon.jsx';
import CssShimmer from '../../common/CssShimmer.jsx';
import { SimpleYellowPlayPauseButtons } from './SimpleYellowPlayPauseButtons.jsx';
import ShuffleButton from './ShuffleButton.jsx';
import { FollowUnfollowArtistPlaylist } from './FollowUnfollowArtistPlaylist.jsx';
import { SharePlaylistButton } from './SharePlaylistButton';
import { PlaylistReels } from './PlaylistReels';
import { useFollowCollection } from '../../../helper/useFollowCollection.js';
import { shareTypes } from '../../../consts/shareTypes.js';


export const PlaylistInfoBox = ({ collectionObject,collectionData,apiUrl, list, endOfPagination, requestPayload, isLoading }) => {
  const [isFollow, toggleFollow,unfollowCollection,followCollectionLoading] = useFollowCollection(collectionObject?.id);
  const [isButtonChange, setIsButtonChange] = useState();
  return (
    <div
      dir="rtl"
      className="flex w-full px-4 absolute z-10 top-[-78px] left-0 right-0"
    >
      <div className="flex w-full flex-col bg-white bg-opacity-[15%] rounded-[10px] px-4 pt-2 pb-5 gap-[21px]  ">
        <div className="flex flex-col gap-1 ">
          <div className="flex gap-2 items-center  ">
            <VisitCountIcon />
            {collectionData?.visits_count ? (
              <div className="flex items-center gap-1">
                <p className="text-white fa-format-500 text-[14px]  ">
                  {collectionData?.visits_count}
                </p>
                <p className="text-white fa-format-500 text-[14px]  ">مشاهده</p>
              </div>
            ) : (
              <CssShimmer classNames={'w-20 rounded-[10px] h-6'} />
            )}
          </div>
          {collectionObject?.is_updated &&
            (collectionData ? (
              <div className="flex  items-center">
                <p className="text-xs  text-gray_6 font-dana font-normal ">
                  <span>آخرین بروزرسانی: </span>
                  {collectionData?.updated_days > 0 ? (
                    <span>{collectionData?.updated_days} روز قبل </span>
                  ) : (
                    <span>امروز</span>
                  )}{' '}
                  <span className="text-primary">
                    (<span> {collectionData?.updated_tracks} </span>آهنگ جدید)
                  </span>
                </p>
              </div>
            ) : (
              <CssShimmer classNames={'w-30 h-4 rounded-[10px]'} />
            ))}
        </div>
        <div className="flex justify-between items-center  ">
          <div className="gap-[14px] flex items-center ">
            <SimpleYellowPlayPauseButtons
              collectionObject={collectionObject}
              apiUrl={apiUrl}
              list={list}
              endOfPagination={endOfPagination}
              requestPayload={requestPayload}
              className="h-[56px] w-[56px]"
              isPlaying={false}
              setIsButtonChange={setIsButtonChange}
              changePlayPauseAnim={isButtonChange}
            />
            <ShuffleButton isShuffle={false} />
          </div>
          <div className="flex items-center gap-6">
            <FollowUnfollowArtistPlaylist
              id={collectionObject?.id}
              followLoading={followCollectionLoading}
              unFollow={unfollowCollection}
              isFollow={isFollow}
              type="playlist"
              toggleFollow={toggleFollow}
            />
            <SharePlaylistButton
              id={collectionData?.id}
              type={shareTypes?.COLLECTION}
            />
            <PlaylistReels
              model_id={collectionObject?.id}
              reelData={collectionData?.reel_data}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

