import React from 'react'

function NewPencilSvg({ fill = '#BDBDBD' }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2683_12446)">
        <path
          d="M2.61209 12.8074C2.61209 12.8074 2.67163 12.8074 2.69395 12.8074L4.87442 12.6065C5.17953 12.5767 5.46233 12.4428 5.67814 12.227L13.9088 3.99628C14.2958 3.6093 14.5116 3.09581 14.5116 2.55256C14.5116 2.0093 14.2958 1.49581 13.9088 1.10884L13.3805 0.580465C12.6065 -0.193488 11.2595 -0.193488 10.4856 0.580465L9.43628 1.62977L2.26233 8.80372C2.04651 9.01954 1.91256 9.30233 1.89023 9.60744L1.6893 11.7879C1.66698 12.0633 1.76372 12.3312 1.95721 12.5321C2.13581 12.7107 2.36651 12.8074 2.61209 12.8074ZM11.9367 1.09395C12.1749 1.09395 12.413 1.18326 12.5916 1.3693L13.12 1.89767C13.2986 2.07628 13.3953 2.30698 13.3953 2.55256C13.3953 2.79814 13.2986 3.03628 13.12 3.20744L12.4651 3.86233L10.627 2.02419L11.2819 1.3693C11.4605 1.1907 11.6986 1.09395 11.9367 1.09395ZM2.99907 9.71163C2.99907 9.66698 3.0214 9.62977 3.05116 9.6L9.8307 2.81302L11.6688 4.65116L4.8893 11.4307C4.8893 11.4307 4.81488 11.4828 4.77767 11.4828L2.82047 11.6614L2.99907 9.70419V9.71163ZM16 15.1814C16 15.4865 15.747 15.7395 15.4419 15.7395H0.55814C0.253023 15.7395 0 15.4865 0 15.1814C0 14.8763 0.253023 14.6233 0.55814 14.6233H15.4419C15.747 14.6233 16 14.8763 16 15.1814Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2683_12446">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default NewPencilSvg;