import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { getSocialProfile } from '../../services/ApiClient';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import CommonHeader from '../common/CommonHeader';
import Container from '../PageParentContainer/Container';
import { ProfileInfoSection } from './ProfileInfoSection';
import { UserNameSection } from './UserNameSection';
import { UserStatistics } from './UserStatistics';
import { FollowUnFollowButton } from './FollowUnFollowButton';
import { UserSocialData } from './UserSocialData';
import FloatButtonContainer from '../common/FloatButtonContainer';
import { CardActionArea } from '@mui/material';
import { HeaderThreeDot } from '../common/HeaderThreeDot';
import { ProfileOptionsBottomSheet } from './options/ProfileOptionsBottomSheet';
import { FloatCreateMelopostButton } from '../meloPost/FloatCreateMelopostButton.jsx';
import CssShimmer from '../common/CssShimmer.jsx';
import { ProfileLoadingShimmer } from './ProfileLoadingShimmer.jsx';
import { useSocialProfile } from '../../hooks/useSocialProfile.js';

export const UserProfile = () => {
  const [openProfileOption, setOpenProfileOption] = useState(false);
  const [slideIndex, setSlideIndex] = useState(1);
  const { social_token } = useParams();
  const { isUserSocialProfile } = useSocialProfile(social_token);

  const { data, refetch, isLoading } = useQuery(
    ['social-profile', social_token],
    () => getSocialProfile(social_token),
    {
      enabled: !!social_token,
    },
  );

  const profileData = data?.data?.result;

  const renderProfileContent = () => (
    <>
      <ProfileInfoSection data={profileData} />
      <UserStatistics data={profileData} />
      <FollowUnFollowButton
        follow_status={profileData?.follow_status}
        social_token={social_token}
      />
    </>
  );

  return (
    <Container
      shadow={false}
      header={
        <CommonHeader
          title="پروفایل"
          startAdornment={
            <HeaderThreeDot onClick={() => setOpenProfileOption(true)} />
          }
        />
      }
    >
      <div
        id="scrollbar-user-social"
        dir="rtl"
        className="w-full h-full flex flex-col !overflow-y-scroll !webkit-overflow-scrolling-touch"
      >
        {isLoading ? <ProfileLoadingShimmer /> : renderProfileContent()}

        <UserSocialData
          social_token={social_token}
          setSlideIndex={setSlideIndex}
          slideIndex={slideIndex}
          refetch={refetch}
        />
      </div>

      {slideIndex === 0 && isUserSocialProfile && <FloatCreateMelopostButton />}

      <ProfileOptionsBottomSheet
        open={openProfileOption}
        setOpen={setOpenProfileOption}
        data={profileData}
        refetchData={refetch}
      />
    </Container>
  );
};
