import React from 'react';

function ShareInstagramIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <circle
        cx="15"
        cy="15"
        r="15"
        fill="url(#paint0_radial_4244_20022)"
      ></circle>
      <circle
        cx="15"
        cy="15"
        r="15"
        fill="url(#paint1_radial_4244_20022)"
      ></circle>
      <g fill="#fff" clipPath="url(#clip0_4244_20022)">
        <path d="M14.995 26.996q-.481 0-.958-.038a.703.703 0 1 1 .11-1.402c.707.055 1.43.04 2.132-.044a.704.704 0 0 1 .168 1.397c-.478.057-.967.087-1.452.087M10.994 26.267a.7.7 0 0 1-.25-.046 12 12 0 0 1-2.159-1.078.703.703 0 1 1 .752-1.189c.601.38 1.243.7 1.906.952a.703.703 0 0 1-.25 1.361M19.43 26.092a.704.704 0 0 1-.276-1.35c.652-.279 1.28-.625 1.864-1.03a.703.703 0 1 1 .8 1.157c-.662.459-1.373.851-2.112 1.167a.7.7 0 0 1-.276.056M6.542 23.196a.7.7 0 0 1-.526-.237 12 12 0 0 1-1.413-1.955.703.703 0 1 1 1.217-.705c.356.614.776 1.195 1.248 1.726a.703.703 0 0 1-.526 1.17M23.75 22.84a.703.703 0 0 1-.544-1.149c.45-.55.845-1.148 1.175-1.776a.703.703 0 0 1 1.246.654 12 12 0 0 1-1.332 2.012.7.7 0 0 1-.545.259M4.022 18.41a.704.704 0 0 1-.682-.534A12 12 0 0 1 3 15.487a.703.703 0 0 1 1.406-.057c.03.712.13 1.422.3 2.109a.703.703 0 0 1-.684.872M26.072 17.955a.706.706 0 0 1-.69-.844c.14-.693.211-1.406.211-2.12v-.024a.703.703 0 0 1 .702-.705h.002c.387 0 .702.314.703.702v.027c0 .808-.08 1.615-.24 2.4a.704.704 0 0 1-.689.564M4.017 13.003a.705.705 0 0 1-.684-.87c.19-.782.462-1.548.807-2.276a.703.703 0 1 1 1.272.603 10.5 10.5 0 0 0-.713 2.006.704.704 0 0 1-.682.537M6.525 8.212a.703.703 0 0 1-.527-1.17A12 12 0 0 1 7.77 5.404a.703.703 0 1 1 .848 1.123c-.567.428-1.093.915-1.564 1.447a.7.7 0 0 1-.528.238M10.971 5.13a.704.704 0 0 1-.25-1.36 12 12 0 0 1 2.33-.627.703.703 0 1 1 .227 1.388c-.702.115-1.393.3-2.056.553a.7.7 0 0 1-.25.047"></path>
        <path d="M15.678 3.005A.704.704 0 0 0 15.6 4.41c5.15.289 9.273 4.237 9.908 9.235a.704.704 0 1 0 1.396-.178c-.72-5.662-5.391-10.134-11.226-10.462"></path>
      </g>
      <path
        fill="#fff"
        d="M18.954 14.38h-3.14c-.143 0-.194-.054-.194-.198v-3.155c0-.3-.296-.542-.595-.556-.32-.015-.644.24-.644.556v3.155c0 .144-.057.198-.2.198h-3.155c-.3 0-.543.293-.557.592-.015.32.24.648.557.648h3.155c.143 0 .2.053.2.197v3.154c0 .3.29.543.59.557.32.015.65-.24.65-.557v-3.154c0-.144.05-.197.194-.197h3.139c.317 0 .587-.327.572-.647-.014-.3-.272-.593-.572-.593"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_4244_20022"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-115.776 23.36 9.07)scale(40.9105 60.8497)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.244" stopColor="#FF1B90"></stop>
          <stop offset="0.437" stopColor="#F80261"></stop>
          <stop offset="0.688" stopColor="#ED00C0"></stop>
          <stop offset="0.777" stopColor="#C500E9"></stop>
          <stop offset="0.893" stopColor="#7017FF"></stop>
        </radialGradient>
        <radialGradient
          id="paint1_radial_4244_20022"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-57.44 31.868 4.559)scale(17.7261 18.4377)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD600"></stop>
          <stop offset="0.484" stopColor="#FF6930"></stop>
          <stop offset="0.734" stopColor="#FE3B36"></stop>
          <stop offset="1" stopColor="#FE3B36" stopOpacity="0"></stop>
        </radialGradient>
        <clipPath id="clip0_4244_20022">
          <path fill="#fff" d="M3 3h24v24H3z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ShareInstagramIcon;
