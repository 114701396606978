import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import { IsIosDevice } from '../../helper/CheckDevice';
import { FreeMode } from 'swiper';
import { FixedSizeList as List } from 'react-window';
import CollectionCardItem from '../../pages/playList/CollectionCardItem.jsx';
import GeneralCollectionCardItem from '../playlist/GeneralCollectionCardItem.jsx';

// const Column = ({ data, index, style }) => (
//   <div style={{ ...style, right: style.right + 7 + 'px' }} className=''>
//     <CollectionCardItem item={data[index]} queueList={data} />
//   </div>
// );

function HomeGeneralCollectionSlider({ item }) {
  return ( 
  // !IsIosDevice() ? (
  //   <div className="flex flex-col  ">
  //     <List
  //       direction="rtl"
  //       height={94}
  //       itemCount={item?.length}
  //       itemSize={94}
  //       layout="horizontal"
  //       width={400}
  //       style={{ width: '100% !important' }}
  //       itemData={item}
  //       className="!pr-[17px] "
  //     >
  //       {Column}
  //     </List>
  //   </div>
  // ) : (
    <div className="flex flex-col ">
      <div className="slider">
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={8}
          // lazy={true}
          modules={[FreeMode]}
          freeMode={true}
          className="!w-full !h-fit flex flex-col-reverse !select-none new-song-swiper  !overflow-visible"
          cssMode={IsIosDevice()}
        >
          {item?.map((collection, index) => ( 
            <div className="" key={index}>
              <SwiperSlide
                className="!w-fit last:!pl-6 "
                key={index}
              >
                <div className="flex flex-col ">
                  {/* <!-- persian --> */}
                  <GeneralCollectionCardItem
                    item={collection}
                    queueList={item}
                  />
                </div>
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default HomeGeneralCollectionSlider;
