import React from 'react';

function QueueSvg({className="w-[24] h-[24px]"}) {
    return (
      <svg
        className={className}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2.75"
          y="0.75"
          width="18.5"
          height="10.5"
          rx="2.25"
          stroke="#BDBDBD"
          stroke-width="1.5"
        />
        <line
          x1="2.75"
          y1="17.25"
          x2="21.25"
          y2="17.25"
          stroke="#BDBDBD"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <line
          x1="2.75"
          y1="23.25"
          x2="21.25"
          y2="23.25"
          stroke="#BDBDBD"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    );
}

export default QueueSvg;
