export const SOCIAL_REPORT_TYPES = {
  SOCIAL_PROFILE:'social_profile',
  MELOPOST:'melopost',
  REEL:'reel'
}
export const SOCIAL_REPORT_PAGE_DATA = {
  social_profile:{title:'گزارش خطا پروفایل',checkboxTitle:'کدام خطا را در پروفایل مشاهده میکنید؟'
  },
  melopost:{title:'گزارش خطا ملوپست',checkboxTitle:'کدام خطا را در ملوپست مشاهده میکنید؟'
  },
  reel:{title:'گزارش خطا ریلز',checkboxTitle:'کدام خطا را در ریلز مشاهده میکنید؟' }

}
