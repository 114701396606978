import * as Yup from "yup";

export const PHONE_VALIDATION_SCHEMA = () => {
  return Yup.object({
    phone: Yup.string().required("لطفا شماره خود را وارد کنید"),
  });
};

export const ADD_NEW_TICKET_VALIDATION_SCHEMA = () => {
  return Yup.object({
    description: Yup.string()
      .required("لطفا توضیحات پیام را وارد کنید ")
      .min(15, "حداقل 15 حرف را بنویسید."),
    subject: Yup.string()
      .required("لطفا موضوع   پیام را وارد کنید ")
      .min(3, "حداقل 3 حرف را بنویسید."),
    category_faq_id: Yup.string().required(""),
  });
};
export const DESCRIPTION_NEW_MESSAGE_VALIDATION_SCHEMA = () => {
  return Yup.object({
    description: Yup.string()
      .trim()
      .required("لطفا متن پیام را وارد کنید ")
      .min(3, "حداقل 3 حرف را بنویسید."),
  });
};
export const CREATE_COLLECTION_VALIDATION_SCHEMA = () => {
  return Yup.object({
    title: Yup.string().trim().required(" نام پلی لیست را بنویسید."),
  });
};
export const BUG_REPORT_VALIDATION_SCHEMA = () => {
  return Yup.object({
    description: Yup.string().required("لطفا متن را وارد کنید."),
  });
};

export const EDIT_PROFILE_VALIDATION_SCHEMA = () => {
  return Yup.object({
    full_name: Yup.string().trim().required("نام را وارد کنید"),
  });
};

export const EDIT_GIFT_VALIDATION_SCHEMA = () => {
  return Yup.object({
    name: Yup.string()
      .trim()
      .required("نام شخصی که می خواهید به او هدیه بدهید را وارد کنید"),
    description: Yup.string().trim().required("متن پیام هدیه را وارد کنید"),
  });
};

export const ADD_GIFT_VALIDATION_SCHEMA = () => {
  return Yup.object({
    name: Yup.string()
      .trim()
      .required("نام شخصی که می خواهید به او هدیه بدهید را وارد کنید"),
    description: Yup.string().trim().required("متن پیام هدیه را وارد کنید"),
  });
};
export const GIFT_CODE_VALIDATION_SCHEMA = () => {
  return Yup.object({
    code: Yup.string().trim().required("کد هدیه را وارد کنید"),
  });
};
export const ADD_HASHTAG_VALIDATION_SCHEMA = () => {
  return Yup.object({
    hashtag: Yup.string()
      .trim()
      .required("هشتگ موردنظر را وارد کنید.")
      .min(3, "هشتگ باید حداقل 3 کارکتر باشد.")
       .max(50, "هشتگ باید حداکثر 50 کارکتر باشد.")
      .test("no-space", "هشتگ نباید دارای space باشد", (value) => !/\s/.test(value))
      .matches(/^[\u0600-\u06FFa-zA-Z0-9_]+$/, "هشتگ باید شامل حروف فارسی یا انگلیسی، اعداد یا _ باشد."),
  })
};
export const ADD_NEW_MELOPOST_VALIDATION_SCHEMA = () => {
  return Yup.object({
    title: Yup.string()
      .required("برای ملوپست خود یک عنوان انتخاب کنید. ")
  });
};



export const EDIT_SOCIAL_PROFILE_VALIDATION_SCHEMA = () => {
  return Yup.object({
    name: Yup.string().trim().required("نام را وارد کنید"),
  });
};

export const EDIT_SOCIAL_PROFILE_USER_NAME_VALIDATION_SCHEMA = () => {
  return Yup.object({
    username: Yup.string().trim().required("نام کاربری را وارد کنید"),
  });
};
