import React from 'react';

function PlanTimeViewShape({ isPremium }) {
  const className='w-full h-[145px]'
  if(isPremium){
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio='none'

        className={className}
        fill="none"
        viewBox="0 0 328 145"
      >
        <path
          fill="url(#paint0_linear_1736_2376)"
          fillOpacity="0.45"
          fillRule="evenodd"
          d="M120.269 0c7.814 0 14.003 6.35 17.997 13.067C143.583 22.008 153.342 28 164.5 28s20.917-5.992 26.234-14.933C194.728 6.35 200.917 0 208.731 0H313c8.284 0 15 6.716 15 15v115c0 8.284-6.716 15-15 15H15c-8.284 0-15-6.716-15-15V15C0 6.716 6.716 0 15 0z"
          clipRule="evenodd"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_1736_2376"
            x1="23.5"
            x2="358.5"
            y1="127"
            y2="19.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C263FE"></stop>
            <stop offset="1" stopColor="#624ED0"></stop>
          </linearGradient>
        </defs>
      </svg>
    )

  }else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio='none'
        fill="none"
        viewBox="0 0 328 145"

        className={className}
      >
        <path
          fill="url(#paint0_linear_4627_3452)"
          fillRule="evenodd"
          d="M120.269 0c7.814 0 14.003 6.35 17.997 13.067C143.583 22.008 153.342 28 164.5 28s20.917-5.992 26.234-14.933C194.728 6.35 200.917 0 208.731 0H313c8.284 0 15 6.716 15 15v115c0 8.284-6.716 15-15 15H15c-8.284 0-15-6.716-15-15V15C0 6.716 6.716 0 15 0z"
          clipRule="evenodd"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_4627_3452"
            x1="23.5"
            x2="358.5"
            y1="127"
            y2="19.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4F4F4F"></stop>
            <stop offset="1" stopColor="#404040"></stop>
          </linearGradient>
        </defs>
      </svg>
    );

  }

}

export default PlanTimeViewShape;
