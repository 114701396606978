import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CollectionCategoryHeader from '../../components/playlist/CollectionCategoryHeader';
import AbsoluteBackground from '../../components/common/AbsoluteBackground';
import Container from '../../components/PageParentContainer/Container';
import { SelectSeenArtist } from '../../redux/RecentlyHeard/RecentlyHeardSlice';
import HomeArtistCard from '../../components/home/HomeArtistCard';
import { useNavigate } from 'react-router-dom';
import { getInitialData } from '../../helper/ReduxSelectorContext';
import { VirtuosoGrid } from 'react-virtuoso';
import ScrollTopButton from '../../components/common/ScrollTopButton';

function ShowAllRecentlyHeardSingers() {
  const {
    artists_header: {
      recently_visited_artists_image: image,
      recently_visited_artists_title: title,
    },
  } = getInitialData();

  const recentlySeenArtists = useSelector(SelectSeenArtist);
  const navigate = useNavigate();
  const [Loader, setLoader] = useState(false);
  const openArtist = item => {
    navigate(`/artist-profile/${item?.id}`, { state: { artistObject: item } });
  };

  useEffect(() => {
    setLoader(true);
  }, []);

  return (
    <Container style="bg-primary_dark !overflow-hidden">
      <CollectionCategoryHeader
        title={title}
        hasSearch={false}
        backgroundId="absolute-background"
        pageId="artists-page"
      />
      <AbsoluteBackground image={image} animation={true} />
      <ScrollTopButton id={'artists-page'} />
      <div
        id="artists-page"
        className=" relative overflow-scroll h-full hide-scroll-bar z-10 "
        dir="rtl"
      >
        <div className={`h-full  w-full mb-10  pt-[208px]`}>
          <p className="!z-50  absolute font-kalameh font-medium text-lg text-white top-[100px] w-full flex justify-center items-center mx-auto">
            {title}
          </p>
          <div className="flex gap-3  flex-col mt-2  p-6 max-[350px]:px-0">
            {recentlySeenArtists?.length !== 0 ? (
              <VirtuosoGrid
                data={recentlySeenArtists}
                customScrollParent={document.getElementById('artists-page')}
                listClassName="!grid !grid-cols-2 !gap-4  max-[350px]:!gap-x-[.1rem]"
                itemContent={(index, artist) => (
                  <div
                    onClick={() => openArtist(artist)}
                    className="flex justify-center items-center w-fit h-max mx-auto z-10 relative"
                  >
                    <HomeArtistCard item={artist} imageSize='w-[156px] h-[156px]' />
                  </div>
                )}
              />
            ) : (
              //  IF WANT HANDEL NO DATA CAN USE THIS BLOCK
              <div className="flex !flex-col !items-center !justify-center relative top-[20vh]  "></div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ShowAllRecentlyHeardSingers;
