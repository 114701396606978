import React from 'react';
import CustomLazyLoadImage from '../../components/common/CustomLazyLoadImage.jsx';
import PlayListPagePlaceHolderSvg from '../../components/svg/common/PlayListPagePlaceHolderSvg.jsx';

import PlaceHolderWithTitle from '../../components/common/PlaceHolderWithTitle.jsx';

function PlayListImageContainer({ image, title }) {
  return (
    <>
      <div className=" rounded-[10px]   relative max-h-full h-full  w-full  max-w-full overflow-hidden aspect-video">
        {image && (
          <CustomLazyLoadImage
            key={image}
            imageClass="!bottom-0 !top-[1px] !right-[1px] !rounded-[10px]  "
            image={image}
          />
        )}
        <PlaceHolderWithTitle
          iconStyle={'!w-[33%]'}
          textStyle={'!mt-2'}
          style={'xl:min-h-[6vw]'}
          title={title}
        />
      </div>
    </>
  );
}

export default PlayListImageContainer;
