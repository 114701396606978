import React from 'react';

function RetrySvgType2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="21"
      fill="none"
      viewBox="0 0 16 21"
    >
      <path
        fill="#F2C94C"
        d="m15.373 7.826-1.216.552c.347.884.523 1.824.523 2.793 0 3.961-2.996 7.184-6.68 7.184s-6.68-3.223-6.68-7.184c0-2.359 1.095-4.563 2.86-5.894l2.33 2.518V2.406H1.524l1.713 1.852a8.4 8.4 0 0 0-1.966 2.258A9.05 9.05 0 0 0 0 11.17c0 4.744 3.589 8.604 8 8.604s8-3.86 8-8.604a9.1 9.1 0 0 0-.627-3.345"
      ></path>
    </svg>
  );
}

export default RetrySvgType2;
