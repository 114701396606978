import React, { useEffect, useState } from "react";



export const  LikeMelopost =({
                               fill = "#A7A7A9",
                               id,
                               height = 'h-[18px]',
                               aspectRatio =20 / 18,
                               likeCount,
                               dir='ltr',
  isLiked
                             }) => {








  return (


      <svg

        style={{ aspectRatio: aspectRatio }}
        className={`cursor-pointer transition-all  duration-75 ease-linear ${height}  `}
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {isLiked ? (
          <path
            fill="#EB5757"
            d="M9.556 17.314a.621.6 21 0 00.89 0l7.996-8.101C21.776 5.835 19.407 0 14.665 0c-2.848 0-4.154 2.093-4.664 2.483C9.49 2.091 8.191 0 5.338 0 .61 0-1.788 5.82 1.562 9.213l7.994 8.101z"
          ></path>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className='w-full h-full'
            fill="none"
            viewBox="0 0 18 16"
          >
            <path
              fill={fill}
              d="M8.6 15.83a.555.555 0 0 0 .8 0l7.198-7.407c1.87-1.922 1.87-5.052 0-6.975A4.7 4.7 0 0 0 13.198 0 4.7 4.7 0 0 0 9.8 1.448L9 2.27l-.799-.822A4.7 4.7 0 0 0 4.801 0a4.7 4.7 0 0 0-3.4 1.448c-1.868 1.923-1.868 5.053 0 6.974zM2.201 2.25a3.598 3.598 0 0 1 5.197 0l1.2 1.236c.21.217.59.217.8 0l1.2-1.235a3.603 3.603 0 0 1 5.198 0c1.438 1.48 1.438 3.888 0 5.37L9 14.616 2.202 7.62C.764 6.14.764 3.73 2.202 2.25"
            ></path>
          </svg>
        )}
      </svg>

  );
}


///
