import React from 'react';
import UserAvatar from '../common/UserAvatar';
import { UserMedalSection } from './medals/UserMedalSection';
import { UserNameSection } from './UserNameSection';

export const ProfileInfoSection = ({ data }) => {
  return (
    <div className="flex flex-col gap-4">
      <div
        dir="rtl"
        className="flex flex-row justify-between items-center bg-profile-gradient px-4 py-2"
      >
        <UserAvatar
          className="w-[72px] h-[72px]  border border-gray_1 rounded-full relative "
          avatar={data?.social_profile?.avatar}
          is_premium={data?.social_profile?.is_premium}
          is_online={data?.social_profile?.is_online}
        />
        <UserMedalSection item={data} />
      </div>
      <UserNameSection social_profile={data?.social_profile} />
    </div>
  );
};
