import CommonHeader from '../../components/common/CommonHeader.jsx';
import Container from '../../components/PageParentContainer/Container.jsx';
import { useVoiceVisualizer, VoiceVisualizer } from 'react-voice-visualizer';
import { MeloPostRecordControls } from '../../components/meloPost/MeloPostRecordControls.jsx';
import { useEffect } from 'react';


export const RecordMeloPost = ()=>{

  const recorderControls = useVoiceVisualizer();
  useEffect(() => {
    console.log(recorderControls,'recorder control...');
  }, [recorderControls]);


  return (
    <Container
      style=" bg-primary_dark p-4"
      header={<CommonHeader title="پست جدید" />}
    >
      <div className='flex flex-col gap-[30px] pt-[80px]'>
        <VoiceVisualizer mainBarColor='#F2C94C' isDefaultUIShown={false}  controls={recorderControls} progressIndicatorTimeClassName='!bg-red' isControlPanelShown={false} />
        <MeloPostRecordControls {...recorderControls}
        />
      </div>

    </Container>
  )

}
