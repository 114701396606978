import React from 'react';

import { HomeUserPlayList } from './HomeUserPlayList';
import { HomeSectionBackgroundOverlay } from '../common/HomeSectionBackgroundOverlay';

export const HomeUserPlaylistContainer = ({ item, cardStyle }) => {
  return (
    <div className="w-full flex flex-col gap-4 relative">
      <HomeSectionBackgroundOverlay
        background_color={item?.element_meta?.background_color}
      />
      <div className="static z-20 w-full flex flex-col gap-4 ">
        <HomeUserPlayList  cardStyle={cardStyle} item={item?.element_data} elementViewType={item?.element_view_type}   />
      </div>
    </div>
  );
};
