import React from 'react';
import CommonHeader from '../../components/common/CommonHeader.jsx';
import { useLocation } from 'react-router-dom';
import Container from '../../components/PageParentContainer/Container.jsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import SongListLoading from '../../components/loading/SongListLoading.jsx';
import CssShimmer from '../../components/common/CssShimmer.jsx';
import NoUserPlaylists from '../../components/MyMelodify/NoUserPlaylists.jsx';
import { VirtuosoGrid } from 'react-virtuoso';
import { LikedReelsItem } from '../../components/reels/LikedReelsItem.jsx';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getReels, getUserCollectionsByType } from '../../services/ApiClient.js';
import { UsersCollectionItem } from './UsersCollectionItem.jsx';
import CollectionGridShimmer from '../../components/Shimmers/CollegtionGridShimmer.jsx';


function UsersCollectionsListByType(props) {
  const location = useLocation()
  const pageData = location?.state?.pageData
  const {type,title} =pageData || {}

  const {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: getUserCollections
  } = useInfiniteQuery([`getUserCollectionsByType`,type], ({pageParam = 0}) => getUserCollectionsByType({ offset: pageParam, type: type }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const allData = allPages?.flatMap((page) => page?.data?.result?.user_collections);
        return lastPage?.data?.result?.end ? false : allData?.length;
      },
      refetchOnWindowFocus: false
    })
  const usersCollectionsList = getUserCollections?.pages?.flatMap((page) => page?.data?.result?.user_collections);
  const handleLoadMore = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };
  const array = new Array(9).fill(0);
  const scrollableTarget = 'scrollbar-like-reel'
  return (
    <Container
      style="bg-background "
      header={
        <CommonHeader  title={title} />
      }
    >
      <div
        id={scrollableTarget}
        dir="rtl"
        className="w-full h-full flex flex-col !overflow-y-scroll !webkit-overflow-scrolling-touch"
      >
        <InfiniteScroll
          className="!overflow-hidden"
          next={handleLoadMore}
          hasMore={hasNextPage}
          dataLength={usersCollectionsList?.length || 0}
          loader={
            isFetchingNextPage && (
              <div className="w-full flex justify-center items-start h-[50px]">
                <SongListLoading />
              </div>
            )
          }
          scrollableTarget={scrollableTarget}
        >
          { isLoading ? (
            <div className="w-full h-full !overflow-hidden  pb-28">
              <CollectionGridShimmer count={20} />
            </div>


          ) : usersCollectionsList?.length === 0 ? (
            <div className="flex flex-col gap-4 justify-center items-center mt-8">
              <NoUserPlaylists />
            </div>
          ) :  (
            <div className="w-full h-full !overflow-hidden  pb-28 px-4">
              <VirtuosoGrid
                id="container"
                dir="rtl"
                style={{ overflowY: 'hidden !important' }}
                customScrollParent={document.getElementById(scrollableTarget)}
                data={usersCollectionsList}
                listClassName="!grid !grid-cols-2 !gap-2 !w-full !mt-4"
                itemContent={(index, item) => (
                  <div>
                    <UsersCollectionItem collectionItem={item} key={`users-collection-${item?.id}`} />
                  </div>
                )}
              />
            </div>
          )}
        </InfiniteScroll>

      </div>

    </Container>
  );
}

export default UsersCollectionsListByType;
