import React from 'react';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';
import { CardActionArea } from '@mui/material';
import { useAction } from '../../hooks/useAction';

export const HomeUserActivities = ({ item }) => {
  const action = useAction();

  const handleClickItem = item_action => {
    action(item_action);
  };

  return (
    <div dir="rtl" className="w-full grid grid-cols-2 px-4 gap-2">
      {item?.element_data?.map((activities_item, index) => (
        <CardActionArea
          onClick={() => handleClickItem(activities_item?.action)}
        >
          <div
            key={index + 'activities'}
            className="w-full bg-secondary rounded-[6px] gap-4 p-2 flex flex-row justify-start items-center"
          >
            <CustomLazyLoadImage
              imageClass="!static  !w-[32px] !h-[32px]"
              image={activities_item?.icon}
            />
            <p className="text-white font-[500] font-dana text-[10px] min-[361px]:text-xs">
              {activities_item?.text}
            </p>
          </div>
        </CardActionArea>
      ))}
    </div>
  );
};
