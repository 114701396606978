import { useNavigate } from 'react-router';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getFollowedArtists } from '../../services/ApiClient.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import Container from '../../components/PageParentContainer/Container.jsx';
import CollectionCategoryHeader from '../../components/playlist/CollectionCategoryHeader.jsx';
import AbsoluteBackground from '../../components/common/AbsoluteBackground.jsx';
import { getInitialData } from '../../helper/ReduxSelectorContext.js';
import NoneFollowedArtist from '../../assets/myMelodify/NoneFollowedArtist.webp';
import HomeArtistCard from '../../components/home/HomeArtistCard.jsx';
import CssShimmer from '../../components/common/CssShimmer.jsx';
import { VirtuosoGrid } from 'react-virtuoso';
import { useEffect, useMemo, useState } from 'react';
import ScrollTopButton from '../../components/common/ScrollTopButton.jsx';
import SongListLoading from '../../components/loading/SongListLoading.jsx';
import CustomImage from '../../components/common/CustomImage.jsx';

function FollowedArtists() {
  const navigate = useNavigate();

  const {
    artists_header: {
      followed_artists_image: image,
      followed_artists_title: title,
    },
  } = getInitialData();

  const getFollowedArtistInfiniteQuery = useInfiniteQuery(
    ['getFollowedArtistInfiniteQuery'],
    ({ pageParam = 0 }) => getFollowedArtists({ offset: pageParam }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.result?.end) return null;
        const allData = allPages?.flatMap(page => page.data.result.artists);
        return allData?.length;
      },
    },
  );
  const [HasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    setHasLoaded(true);
  }, []);

  const artists = useMemo(
    () =>
      getFollowedArtistInfiniteQuery?.data?.pages?.flatMap(
        page => page.data.result.artists,
      ),
    [getFollowedArtistInfiniteQuery?.data],
  );

  const handleLoadMore = () => {
    getFollowedArtistInfiniteQuery.fetchNextPage();
  };

  // const openArtist = (item) => {
  //   navigate(`/artist-profile/${item?.id}`, { state: { artistObject: item } });
  // };

  return (
    <Container style="bg-primary_dark !overflow-hidden">
      <CollectionCategoryHeader
        title={title}
        hasSearch={false}
        backgroundId="absolute-background"
        pageId="category-page"
      />
      <AbsoluteBackground image={image} animation={true} />
      <ScrollTopButton id={'category-page'} />
      <div
        id="category-page"
        className=" relative overflow-scroll h-full hide-scroll-bar z-10 "
        dir="rtl"
      >
        <div className={`h-full  w-full mb-10  pt-[208px]`}>
          <p className="!z-50 absolute font-kalameh font-medium text-lg text-white top-[100px] w-full flex justify-center items-center mx-auto">
            {title}
          </p>

          <div className="flex gap-3  flex-col mt-2  p-6 max-[350px]:px-0 ">
            {getFollowedArtistInfiniteQuery.isLoading ? (
              <div className="grid grid-cols-2 gap-4">
                {Array.from(
                  [1, 2, 3, 4, 5, 6, 7, 8, 9].map(item => (
                    <div className="w-full aspect-square flex flex-col gap-2 items-center">
                      <CssShimmer classNames={'w-[156px] h-[156px] rounded-full'} />
                      <CssShimmer classNames={'h-3 w-24 rounded-[6px]'} />
                    </div>
                  )),
                )}
              </div>
            ) : artists?.length !== 0 ? (
              <InfiniteScroll
                className="!overflow-visible"
                scrollableTarget="category-page"
                dataLength={artists?.length}
                next={handleLoadMore}
                hasMore={getFollowedArtistInfiniteQuery?.hasNextPage}
                loader={
                  getFollowedArtistInfiniteQuery?.isFetchingNextPage && (
                    <div className="w-full grid justify-center items-center mt-4">
                      <SongListLoading />
                    </div>
                  )
                }
              >
                <VirtuosoGrid
                  data={artists}
                  customScrollParent={document.getElementById('category-page')}
                  listClassName="!grid !grid-cols-2 !gap-4  max-[350px]:!gap-x-[.1rem]"
                  itemContent={(index, pageData) => (
                    <div
                      key={pageData.id + 'HomeArtistCard' + index}
                      // onClick={() => openArtist(pageData)}
                      className="flex justify-center items-center h-max mx-auto z-10"
                    >
                      <HomeArtistCard item={pageData} hasLongPress={true} imageSize='w-[156px] h-[156px]'  />
                    </div>
                  )}
                />
              </InfiniteScroll>
            ) : (
              <div className="flex !flex-col !items-center !justify-center relative top-[20vh]  ">
                <div className="h-[120px] ">
                  <CustomImage
                    appendBaseUrl={false}
                    src={NoneFollowedArtist}
                    className="w-[100px] h-[100px] fill-gray_2"
                  />
                </div>
                <p className="text-gray_3 bg-transparent text-center  font-dana font-normal text-lg">
                  هنوز هنرمندی را دنبال نکرده‌اید
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default FollowedArtists;
