import React from 'react';
import PlayListCard from '../../components/playlist/PlayListCard';
import PlayListImageContainer from './PlayListImageContainer';

export const CollectionCategoryContainer = ({ item }) => {
  console.log('ASfasfsafasfasff', item);

  return (
    <div dir='rtl' className="w-full grid grid-cols-2 gap-2 px-4">
      {item?.element_data?.map((item, index) => (
        <PlayListCard key={'playlistCardPersian' + item?.id} itemData={item}>
          <PlayListImageContainer title={item?.title} image={item.image} />
        </PlayListCard>
      ))}
    </div>
  );
};
