import React, { useEffect } from 'react';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';
import { setLanguageDirection } from '../../helper/LanguageDetection';
import axios from 'axios';
import { MeloPostPlayer } from './MeloPostPlayer';
import UserAvatar from '../common/UserAvatar';
import { MeloPostListenCount } from './MeloPostListenCount';
import { MeloPostLikeCound } from './MeloPostLikeCound';
import { Line } from '../common/Line';
import { LikeMelopost } from './LikeMelopost.jsx';

export const MeloPostHomeCardItem = ({
  item,
  index,
  onPlay,
  activePlayerIndex,
  playlist,
}) => {
  return (
    <div
      dir="ltr"
      className="bg-secondary w-[243px] h-[173px] rounded-b-[8px] rounded-tl-[8px] rounded-tr-[24px] flex flex-col gap-1 "
    >
      <div dir="rtl" className="flex flex-row gap-1 items-center  p-1">
        <div className="w-[40px] h-[40px] rounded-full  relative border border-gray_1 cursor-pointer ">
          <UserAvatar
            className={'!w-full !h-full !object-cover'}
            is_premium={item?.social_profile?.is_premium}
            avatar={item?.social_profile?.avatar}
            premium_crown
            social_token={item?.social_profile?.token}
          />
        </div>
        <span className=" text-white font-poppins text-[12px] font-normal ">
          {item?.social_profile?.name}
        </span>
      </div>
      <div className="flex flex-col gap-2 px-5 justify-between h-full pb-2">
        <div className=" flex flex-row gap-1 justify-end  items-center text-right">
          <span
            dir={setLanguageDirection(item?.title)}
            className=" text-white font-dana text-[12px] font-normal line-clamp-1"
          >
            {item?.title}
          </span>
          <div className="min-w-[12px] min-h-[12px]">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0201 7.09738C9.33719 6.41452 8.22607 6.41449 7.5432 7.09738L3.64891 10.9917C3.45042 11.1901 3.18654 11.2995 2.90584 11.2995C2.62515 11.2995 2.36129 11.1901 2.16279 10.9917L2.01341 10.8423C1.81494 10.6438 1.7056 10.3799 1.7056 10.0992C1.7056 9.81852 1.81494 9.55466 2.01341 9.35616L2.32995 9.03962L2.87994 9.58962L3.8996 8.65566L4.08198 8.83805L8.13912 5.12187C8.23314 5.13222 8.32826 5.13756 8.42421 5.13756C9.11035 5.13756 9.75541 4.87037 10.2406 4.38517C10.7257 3.90003 10.993 3.25494 10.993 2.5688C10.993 1.88267 10.7258 1.23758 10.2406 0.752436C9.75539 0.267198 9.11033 0 8.42421 0C7.7381 0 7.09299 0.267198 6.60784 0.752389C6.03501 1.32522 5.79021 2.10349 5.87266 2.85223L2.15497 6.91101L2.33735 7.09339L1.4034 8.11305L1.83458 8.54423L1.51801 8.86077C0.835142 9.54364 0.835142 10.6548 1.51801 11.3376L1.6674 11.487C1.99821 11.8178 2.43802 12 2.90582 12C3.37364 12 3.81345 11.8178 4.14426 11.487L8.03855 7.59273C8.44824 7.18304 9.11495 7.18299 9.52469 7.59273L9.59038 7.65842C9.78885 7.85692 9.89819 8.1208 9.89819 8.4015C9.89819 8.68219 9.78885 8.94605 9.59038 9.14454L8.71439 10.0205L9.20976 10.5159L10.0858 9.63992C10.7686 8.95705 10.7686 7.84592 10.0858 7.16305L10.0201 7.09738ZM9.74521 1.24776C10.0981 1.60062 10.2924 2.06977 10.2924 2.56876C10.2924 2.94889 10.1793 3.31153 9.96962 3.6188L7.37417 1.02335C7.68144 0.813642 8.04408 0.70057 8.42421 0.70057C8.9232 0.70057 9.39235 0.894885 9.74521 1.24776ZM6.87896 1.51891L9.47423 4.11417C9.16695 4.32387 8.80434 4.43695 8.42421 4.43695C8.34575 4.43695 8.26812 4.4316 8.19148 4.42212L6.57181 2.80244C6.51637 2.36001 6.61882 1.90156 6.87896 1.51891ZM6.25289 3.47429L7.51868 4.74006L4.10321 7.8685L3.12444 6.88974L6.25289 3.47429ZM3.40374 8.15978L2.90117 8.6201L2.37285 8.09177L2.83317 7.58921L3.40374 8.15978Z"
                fill="#F2C94C"
              />
              <path
                d="M5.50195 4.99414L5.99674 4.49935L6.49205 4.99465L5.99726 5.48944L5.50195 4.99414Z"
                fill="#F2C94C"
              />
            </svg>
          </div>
        </div>
        {/* track */}
        <div className="w-full flex flex-row justify-center items-center">
          <MeloPostPlayer
            key={`waveform-${index}`}
            item={item}
            index={index}
            onPlay={onPlay}
            activePlayerIndex={activePlayerIndex}
            playlist={playlist}
          />
        </div>
        {/* line break */}
        <Line />
        {/* like and listen */}
        <div className=" flex flex-row justify-between w-full items-center">
          <MeloPostListenCount
            play_count={item?.play_count}
            iconClass="w-[14px] h-[14px]"
            textClass="text-[12px] fa-format-500"
          />
          <LikeMelopost
            id={item?.id}
            likeCount={item?.like_count}
            dir="rtl"
            height="w-[15px] h-[13px]"
          />
        </div>
      </div>
    </div>
  );
};
