import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MeloPostFileIcon from '../svg/common/MeloPostFileIcon.jsx';
import MeloPostMicIcon from '../svg/common/MeloPostMicIcon.jsx';
import { getInitialData } from '../../helper/ReduxSelectorContext.js';
import SelectFileErrorDialog from './SelectFileErrorDialog.jsx';

export const CreateMeloPostOptions =(props) => {
  const inputRef = useRef(null)
  const dialogRef = useRef(null);
  const navigate = useNavigate()
  const initialData = getInitialData();
  const [dialogOpenState,setDialogOpenState] =useState(false)
  const [error,setError] =useState(null)
  const melopostSettings = initialData?.melopost_settings
  const openInput = () => {
    inputRef.current.click();
  };
  const navigateToRecording = ()=>{
    navigate('/melopost/record')
  }
  const handleSelectedFile = (item) => {
    const selectedItem = item[0]

    if (!selectedItem){
      return
    }

    if (selectedItem.size / 1024 > melopostSettings?.max_file_size_in_kb) {
             setError({title:'حجم فایل بیشتر از حد مجاز است.',subtitle:melopostSettings?.file_size_text})
             setDialogOpenState(true)
    }

    if (!melopostSettings?.allowed_formats?.includes(selectedItem?.name.substring(selectedItem?.name.lastIndexOf(".") + 1))) {
      setError({title:'فرمت فایل مجاز نیست.',subtitle:melopostSettings?.file_format_text})
      setDialogOpenState(true)

    }
    navigate('/melopost/upload',{state:{file:selectedItem}})

  }
  const handleSelectAgain = () => {
    setDialogOpenState(false)
    openInput()
  }


  // max_seconds(pin):600
  // allowed_formats(pin):"mp3,wav,m4a,M4A"
  // max_file_size_in_kb(pin):20480
  // max_melopost_permitted_daily(pin):10

  return (
    <>
      <SelectFileErrorDialog
        openState={dialogOpenState}
        handleCloseDialog={() => setDialogOpenState(false)}
        error={error}
        handleConfirmDialog={handleSelectAgain}
      />

      <div className="flex flex-col w-full gap-[18px] ">
        <div
          onClick={navigateToRecording}
          className="flex w-full gap-4 bg-primary_dark rounded-[8px] border-[1px] border-primary p-4 items-center "
        >
          <div>
            <MeloPostMicIcon />
          </div>
          <div className="flex flex-col gap-[9px] ">
            <p className="text-[14px] font-dana font-[500] text-white">
              {melopostSettings?.voice_title}
            </p>
            <p className="text-[12px] font-dana font-[400] text-gray_4">
              {melopostSettings?.voice_subtitle}
            </p>
          </div>
        </div>
        <div
          onClick={openInput}
          className="flex w-full gap-4 rounded-[8px] border-primary p-4 items-center bg-primary_dark border-[1px] border-primary "
        >
          <input
            ref={inputRef}
            onChange={e => handleSelectedFile(e.target.files)}
            accept=".mp3, .mp4, .m4a, .wav, audio/*"
            className="hidden"
            id="uploadFile"
            type={'file'}
          />
          <div>
            <MeloPostFileIcon />
          </div>
          <div className="flex flex-col gap-[9px] ">
            <p className="text-[14px] font-dana font-[500] text-white">
              {melopostSettings?.file_title}
            </p>
            <p className="text-[12px] font-dana font-[400] text-gray_4">
              {melopostSettings?.file_size_text}
              <br />
              {melopostSettings?.file_format_text}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}


