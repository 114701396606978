import React from 'react';
import CommonHeader from '../../../components/common/CommonHeader.jsx';
import Container from '../../../components/PageParentContainer/Container.jsx';
import ShareVideoPlayer from '../../../components/meloPost/share/ShareVideoPlayer.jsx';
import ShareTextSection from '../../../components/meloPost/share/ShareTextSection.jsx';
import ShareDownloadSection from '../../../components/meloPost/share/ShareDownloadSection.jsx';
import ShareCopyLinkSection from '../../../components/meloPost/share/ShareCopyLinkSection.jsx';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { generateVideoForMelopost, getSuggestedHashtags } from '../../../services/ApiClient.js';
import { FullPageLoader } from '../../../components/common/FullPageLoader.jsx';

function ShareMelopost(props) {
 const location = useLocation()
  const melopostId = location?.state?.melopostId
  const generateVideoForMelopostQuery = useQuery(
    ["generateVideoForMelopost",melopostId],
    () => generateVideoForMelopost({id:melopostId}),
    {
      onSuccess: (res) => {},
      cacheTime:0,
      gcTime:0,
      refetchOnWindowFocus: false,
    }
  );
 const videoData = generateVideoForMelopostQuery?.data?.data?.result

  return (
    <Container
      style=" bg-primary_dark p-4"
      header={<CommonHeader title="اشتراک‌ گذاری ملوپست" />}
    >
      {generateVideoForMelopostQuery?.isLoading?
        <FullPageLoader className='!bg-opacity-100'/>
        :null
      }
      <div dir='rtl' className='flex flex-col gap-5 pt-4'>
        <ShareVideoPlayer videoUrl={videoData?.video} cover={videoData?.cover} heightRelation={videoData?.height_relation}/>

        <div className='flex flex-col gap-[31px] '>
             <ShareTextSection title={videoData?.melopost?.title} creator={videoData?.melopost?.social_profile?.name}/>

          <div className='flex flex-col gap-5'>
                 <ShareDownloadSection melopostUrl={videoData?.video} downloadTitle={videoData?.melopost?.download_title} />
                 <ShareCopyLinkSection shareLink={videoData?.share_url}  />
          </div>
        </div>

      </div>

    </Container>
  );
}

export default ShareMelopost;
