import React, { useEffect, useState } from 'react';

import CustomLazyLoadImage from '../common/CustomLazyLoadImage';
import { CardActionArea } from '@mui/material';
import ClickReaction from '../../helper/ClickReaction';
import PlaceHolderWithTitle from '../common/PlaceHolderWithTitle.jsx';
import PlayListPlaySvg from './v9Components/PlayListPlaySvg.jsx';
import CollectionCardPlaceHolder from './v9Components/CollectionCardPlaceHolder.jsx';
import clsx from 'clsx';
function CategoryCard({ item, scale = false,haveMarginTop=true,fromPlaylist=false }) {
  return (
    <div
      className={clsx('relative   rounded-[8px]   justify-center items-center aspect-square cursor-pointer w-full h-full',haveMarginTop?'mt-4':'')}
    >
      {item?.is_updated && (
        <div className="flex items-center z-10 justify-center flex-row absolute bg-yellow  w-fit bg-primary  top-4 left-0  rounded-r-[10px]">
          <p className="text-secondary text-[10px] py-2 px-2 font-dana font-[600] leading-none">
            {item?.updated_tracks} جدید
          </p>
        </div>
      )}

      <CollectionCardPlaceHolder item={item} imageLoaded={true} scale={scale}>
        <ClickReaction>
          <CardActionArea
            className={` overflow-hidden !rounded-[10px] w-full h-full `}
          >
            <div
              className={`w-full h-full relative flex flex-col shadow-collection-shadow  rounded-[10px] overflow-hidden `}
            >
              <CustomLazyLoadImage
                hasEffect={!item?.is_updated}
                image={item?.image}
              />
              <PlaceHolderWithTitle title={item?.title} />
            </div>
          </CardActionArea>
        </ClickReaction>
      </CollectionCardPlaceHolder>

      <PlayListPlaySvg fromPlaylist={fromPlaylist} item={item} />
    </div>
  );
}

export default CategoryCard;
