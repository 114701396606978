import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottie-file/melodifyLoader.json';

function MusicNoteLoading({ speed = '1' ,width}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };
  return (
    <div>
      <Lottie
        width={width}
        speed={speed}
        isClickToPauseDisabled={true}
        options={defaultOptions}
      />
    </div>
  );
}

export default MusicNoteLoading;
