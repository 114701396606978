import { memo } from 'react';
import ChevronNavigateBack from './ChevronNavigateBack';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  HeaderRoutesWithActionSection,
  HeaderRoutesWithMelodifyIcon,
} from '../../consts/HeaderRoutesWithActionSection.js';
import { HeaderDetailSection } from './HeaderDetailSection';
import { HeaderTitleSection } from './HeaderTitleSection';

/**
 * A header component that displays a title and a back button
 *
 * @component
 * @param {object} props - The component props
 * @param {string} props.title - The title to display in the header
 * @param {ReactNode} [props.startAdornment=null] - An optional start adornment to display in the header
 * @param {boolean} [props.disableRipple=false] - Whether to disable the ripple effect on the back button
 * @param {boolean} [props.ChevronNavigateBackShadow=false] -have drop shadow effect on the back button
 * @param {boolean} [props.hasChevronNavigateBack=true] - Whether to display a back button with a chevron icon
 * @param {string | number} [props.backPath=-1] - The path to navigate back to when the back button is clicked
 * @param {object} [props.navigateBackOptions ={}] - Additional options for navigation when the back button is clicked
 * @param {string} [props.className=""] - An optional class name for the header container
 * @param {string} [props.TitleClassName=""] - An optional class name for the title container
 * @param {string} [props.navBtnClassName=""] - An optional class name for the back button container
 * @param {string} [props.navBtnIconClassName=""] - An optional class name for the back button icon
 * @param {string} [props.startAdornmentClassName=""] - An optional class name for start adornment container
 * @param {object} [props.TitleProps] - An optional object containing props to pass to the title container
 * @returns {JSX.Element} A header element with the provided title and back button
 *
 * @example
 * // Example usage:
 * import CommonHeader from './CommonHeader';
 *
 * function MyComponent() {
 *   return (
 *     <div>
 *       <CommonHeader title="My Title" />
 *     </div>
 *   );
 * }
 */

function CommonHeader({
  title,
  startAdornment = null,
  disableRipple = false,
  ChevronNavigateBackShadow = false,
  hasChevronNavigateBack = true,
  backPath = -1,
  navigateBackOptions = {},
  className = '',
  TitleClassName = '',
  navBtnClassName = '',
  navBtnIconClassName = '',
  startAdornmentClassName = '',
  TitleProps,
                        showCommonHeader=false,
  ...rest
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const isMainTab = showCommonHeader?false:HeaderRoutesWithActionSection?.includes(location?.pathname);
  const showMelodifyIcon =showCommonHeader?false:HeaderRoutesWithMelodifyIcon?.includes(location?.pathname)
  return (
    <div
      dir="rtl"
      className={`flex bg-background h-14 items-center justify-between w-full px-4 ${className}`}
      {...rest}
    >
      <HeaderTitleSection
        title={title}
        isMainTab={isMainTab}
        showMelodifyIcon={showMelodifyIcon}
        startAdornmentClassName={startAdornmentClassName}
        startAdornment={startAdornment}
        TitleClassName={TitleClassName}
        TitleProps={TitleProps}
      />
      <HeaderDetailSection isMainTab={isMainTab}>
        <div className=" flex items-center justify-center w-[46px] h-[46px]">
          {hasChevronNavigateBack && (
            <ChevronNavigateBack
              iconClassName={navBtnIconClassName}
              className={navBtnClassName}
              path={backPath}
              options={navigateBackOptions}
              disableRipple={disableRipple}
              shadow={ChevronNavigateBackShadow}
            />
          )}
        </div>
      </HeaderDetailSection>
    </div>
  );
}

CommonHeader.propTypes = {
  title: PropTypes.string,
  startAdornment: PropTypes.node,
  disableRipple: PropTypes.bool,
  hasChevronNavigateBack: PropTypes.bool,
  backPath: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  TitleClassName: PropTypes.string,
  startAdornmentClassName: PropTypes.string,
  navBtnClassName: PropTypes.string,
  navBtnIconClassName: PropTypes.string,
  TitleProps: PropTypes.object,
  navigateBackOptions: PropTypes.object,
};

export default memo(CommonHeader);
