import React from 'react';
import { ReelsCard } from './ReelsCard';
import { useInView } from 'react-intersection-observer';
import 'swiper/css';
import 'swiper/css/free-mode';
import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useQuery } from '@tanstack/react-query';
import { getReels } from '../../services/ApiClient';
import { GET_REEL_TYPES } from '../../consts/MeloReelsConst';
import { useReels } from '../../consts/useReels';
import { HeightRatio } from '../../helper/HeightRatio';
import useWindowDimensions from '../../helper/ScreenSize';
import { IsIosDevice } from '../../helper/CheckDevice';

export const ReelsContainer = ({ item }) => {
  const { playReel } = useReels();

  const { data } = useQuery(
    ['getHomeReelsQuery'],
    () => getReels({ offset: 0, model_type: GET_REEL_TYPES.HOME }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const openReelPlayer = (index, lists) => {
    playReel({ index, lists });
  };

  return (
    <div dir='rtl' className="flex flex-col gap-4 ">
      <div  className="slider">
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={12}
          freeMode={true}
          modules={[FreeMode]}
          cssMode={IsIosDevice()}
          className="!w-full !h-fit flex flex-col-reverse !select-none new-song-swiper  !overflow-visible "
        >
          {data?.data?.result?.reels?.map((reel_item, index) => (
            <SwiperSlide
              key={index + 'reel-swiper'}
              className="  !w-fit first:!pr-6 last:!pl-6"
              onClick={() => openReelPlayer(index, data?.data?.result?.reels)}
            >
              <div className="flex flex-col ">
                <ReelsCard index={index} reel_item={reel_item} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
