import { useMutation } from "@tanstack/react-query";
import { seenInAppNotification } from "../services/ApiClient";

export const useInAppNotification = () => {
    const seenInAppNotificationMutate = useMutation((id) =>

        seenInAppNotification(id)
    );

    const emitNotifSeen = (id) => {
        seenInAppNotificationMutate.mutate(id)
    }

    return { emitNotifSeen }
}