import { createSlice } from '@reduxjs/toolkit';

export const DialogSlice = createSlice({
  name: 'dialog',
  initialState: {
    customDialog: null,
    newTrackDialog: null,
    upkeepDialog: null,
    InactiveSessionDialog: null,
    bannedDialog: null,
    isShow: false,
    bannedDialogData: null,
    isBanned: false,
    updateDialog: false,
    banDialogIsOpened: null,
    intruductionDialog: null,
    premiumDialog: null,
    expireDialog: null,
    updateCollectionDialogData: null,
    giftDialog: null,
    disableDownloadDialogStatus: false,
    notifyPermisionDialogStatus: false,
    notifySettingPermisionIntroDialogStatus: false,
  },
  reducers: {
    setCustomDialog: (state, action) => {
      const { custom_dialog } = action.payload;
      state.customDialog = custom_dialog;
    },
    setNewTrackDialog: (state, action) => {
      const { new_track_dialog } = action.payload;
      state.newTrackDialog = new_track_dialog;
    },
    setIntroductionDialog: (state, action) => {
      const { introduction_dialog } = action.payload;
      state.intruductionDialog = introduction_dialog;
    },
    setPremiumDialog: (state, action) => {
      const { premium_dialog } = action.payload;
      state.premiumDialog = premium_dialog;
    },
    setExpireDialog: (state, action) => {
      const { expire_dialog } = action.payload;
      state.expireDialog = expire_dialog;
    },
    setUpKeepDialog: (state, action) => {
      const { upKeep_dialog } = action.payload;
      state.upkeepDialog = upKeep_dialog;
    },
    setUpdateDialog: (state, action) => {
      const { updateDialog } = action.payload;
      state.updateDialog = updateDialog;
    },
    setInactiveSessionDialog: (state, action) => {
      const { InactiveSession_dialog } = action.payload;
      state.InactiveSessionDialog = InactiveSession_dialog;
    },
    setBannedDialog: (state, action) => {
      const { Banned_dialog } = action.payload;
      state.bannedDialog = Banned_dialog;
    },
    setIsShow: (state, action) => {
      const { is_show } = action.payload;
      state.isShow = is_show;
    },
    setBannedDialogData: (state, action) => {
      const { Banned_dialogData } = action.payload;
      state.bannedDialogData = Banned_dialogData;
    },
    setIsBanned: (state, action) => {
      const { is_banned } = action.payload;
      state.isBanned = is_banned;
    },
    setBandialogOpen: (state, action) => {
      const { is_bannedOpen } = action.payload;
      state.banDialogIsOpened = is_bannedOpen;
    },
    setUpdateCollectionDialogData: (state, action) => {
      const { collectionData } = action.payload;
      state.updateCollectionDialogData = collectionData;
    },
    clearUpdateCollectionDialogData: state => {
      state.updateCollectionDialogData = null;
    },
    setGiftDialog: (state, action) => {
      const { gift_dialog } = action.payload;
      state.giftDialog = gift_dialog;
    },
    setDisableDownloadDialogStatus: (state, action) => {
      const { disableDownloadStatus } = action.payload;
      state.disableDownloadDialogStatus = disableDownloadStatus;
    }, 
      setNotifyPerimisionDialog: (state, action) => {
      const { permisionStatus } = action.payload;
        state.notifyPermisionDialogStatus = permisionStatus;
    },  
    setNotifySettingPerimisionIntroDialog: (state, action) => {
      const { permisionStatus } = action.payload;
      state.notifySettingPermisionIntroDialogStatus = permisionStatus;
    },
  },
});

export const {
  setCustomDialog,
  setUpKeepDialog,
  setInactiveSessionDialog,
  setBannedDialog,
  setIsShow,
  setBannedDialogData,
  setIsBanned,
  setBandialogOpen,
  setUpdateDialog,
  setIntroductionDialog,
  setPremiumDialog,
  setExpireDialog,
  setNewTrackDialog,
  clearUpdateCollectionDialogData,
  setUpdateCollectionDialogData,
  setDisableDownloadDialogStatus,
  setGiftDialog,
  setNotifyPerimisionDialog,
  setNotifySettingPerimisionIntroDialog
} = DialogSlice.actions;
export const selectIsShow = state => state.dialog.isShow;
export const selectCustomDialog = state => state.dialog.customDialog;
export const selectNewTrackDialog = state => state.dialog.newTrackDialog;
export const selectExpireDialog = state => state.dialog.expireDialog;
export const selectIntroductionDialog = state =>
  state.dialog.intruductionDialog;
export const selectPremiumDialog = state => state.dialog.premiumDialog;
export const selectupkeepDialog = state => state.dialog.upkeepDialog;
export const selectInactiveSessionDialog = state =>
  state.dialog.InactiveSessionDialog;
export const selectBannedDialog = state => state.dialog.bannedDialog;
export const selectBannedDialogData = state => state.dialog.bannedDialogData;
export const selectIsBanned = state => state.dialog.isBanned;
export const selectUpdateDialog = state => state.dialog.updateDialog;
export const selectBanIsOpened = state => state.dialog.banDialogIsOpened;
export const selectUpdateCollectionDialog = state =>
state.dialog.updateCollectionDialogData;
export const selectGiftDialog = state => state.dialog.giftDialog;
export const selectDisableDownloadStatus = state =>
state.dialog.disableDownloadDialogStatus;
export const selectNotifyPermisionDialogStatus = state => state.dialog.notifyPermisionDialogStatus;
export const selectNotifySettingPermisionIntroDialogStatus = state => state.dialog.notifySettingPermisionIntroDialogStatus;
export default DialogSlice.reducer;
