import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getCollectionsPageElements, getUserCollectionsPageElements } from '../../services/ApiClient.js';

import RenderHomeData from '../../components/home/RenderHomeData.jsx';
import Container from '../../components/PageParentContainer/Container.jsx';
import CommonHeader from '../../components/common/CommonHeader.jsx';
import AbsoluteContainerBG from '../../components/PageParentContainer/AbsoluteContainerBG.jsx';
import PlaylistShimmer from '../playList/PlaylistShimmer.jsx';
import UsersCollectionsShimmer from '../../components/UsersCollections/UsersCollectionsShimmer.jsx';

function UsersCollectionsPage(props) {
  const { data: collectionData,isLoading } = useQuery(
    ['getUserCollectionsPageElements'],
    () => getUserCollectionsPageElements(),
    {
      onSuccess: response => {},
      onError: Error => {
      },
    },
  );
  const memoizedCollectionSections = useMemo(
    () =>
      collectionData?.data?.result?.elements?.map((item, index) => (
        <RenderHomeData
          key={`renderUsersCollectionsData${index}${item?.id}`}
          item={item}
        />
      )),
    [collectionData?.data?.result?.elements],
  );

  return (
    <Container
      style="bg-background"
      header={
        <CommonHeader  title={'پلی لیست های کاربران'} />
      }
    >
      {/*<AbsoluteContainerBG />*/}

      <div className="flex flex-col pb-r48 z-20 pb-4">

        {isLoading ? <UsersCollectionsShimmer/> :

        <div className="flex flex-col ">{memoizedCollectionSections}</div>}
      </div>
    </Container>
  );
}

export default UsersCollectionsPage;
