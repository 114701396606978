
import { useInfiniteQuery } from '@tanstack/react-query';
import { getUserCollectionsV9 } from '../services/ApiClient.js';
import { useState } from 'react';

export const useUserPlaylists = socialToken => {
  const [isPrivate, setIsPrivate] = useState(false);

  const query = useInfiniteQuery(
    [`user-playlists`, socialToken],
    ({ pageParam = 0 }) =>
      getUserCollectionsV9({
        offset: pageParam,
        social_token: socialToken,
        hashtag: null,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const allData = allPages?.flatMap(
          page => page.data.result.user_collections,
        );
        return lastPage?.data?.result?.end ? false : allData?.length;
      },
      refetchOnWindowFocus: false,
      retry: 0,
      onError: error => {
        if (error?.response?.status === 403) setIsPrivate(true);
      },
    },
  );

  return { ...query, isPrivate };
};
