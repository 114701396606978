import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

function Test() {
  const item = {
    token: '518ebf481b538f1a6799401a7979ab5d',
    title: 'گپ گپ عربی',
    caption: 'گپ گپ عربی مگه عرب ها گ و پ دارن 🤔',
    amplitude: [
      130, 93, 140, 140, 134, 140, 140, 140, 140, 137, 139, 133, 140, 140, 130,
      137, 115, 70, 140, 140, 138, 126, 116, 132, 133, 133, 140, 132, 140, 140,
      140, 132, 140, 136, 140, 135, 140, 140, 138, 140, 140, 140, 140, 136, 138,
      140, 140, 140, 137, 135, 140, 140, 140, 140, 136, 140, 140, 140, 140, 137,
      140, 136, 140, 140, 138, 135, 140, 140, 140, 140, 134, 140, 140, 140, 140,
      138, 140, 140, 133, 140, 135, 140, 139, 140, 140, 132, 140, 140, 140, 140,
      140, 135, 140, 140, 140, 139, 138, 140, 140, 140, 140, 133, 140, 140, 140,
      140, 133, 140, 139, 140, 140, 138, 137, 140, 140, 140, 140, 140, 140, 140,
      140, 140, 140, 140, 140, 140, 139, 140, 140, 140, 140, 140, 140, 140, 137,
      140, 140, 140, 140, 140, 140, 139, 140, 140, 140, 140, 140, 140, 140, 140,
      140, 138, 140, 140, 140, 140, 140, 140, 139, 140, 139, 140, 140, 139, 140,
      140, 135, 134, 134, 135, 140, 138, 138, 140, 138, 140, 140, 139, 140, 140,
      140, 140, 140, 139, 140, 140, 139, 140, 140, 137, 140, 140, 140, 140, 140,
      140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140,
      140, 140, 140, 140, 140, 140, 140, 140, 140, 139, 140, 137, 138, 140, 140,
      140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140,
      140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 139, 140, 140, 140,
      140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140,
      140, 140, 140, 140, 140, 140, 140, 139, 140, 140, 139, 140, 140, 140, 140,
      140, 140, 140, 140, 136, 140, 140,
    ],
    duration: 146,
    url: 'https://reels.alvand.xyz/storage/melopost/518ebf481b538f1a6799401a7979ab5d/518ebf481b538f1a6799401a7979ab5d.mp3',
    date: 'امروز، 08:56',
    play_count: '0',
    like_count: '0',
    social_profile: {
      name: 'mostafa',
      is_premium: false,
      token: null,
      username: null,
      avatar: null,
    },
    hashtags: [
      {
        title: 'تم',
      },
    ],
  };
  const containerRef = useRef(null);
  // const { wavesurfer, isReady, isPlaying, currentTime } = useWavesurfer({
  //   container: containerRef,
  //   waveColor: 'rgb(200, 0, 200)',
  //   progressColor: 'rgb(100, 0, 100)',
  //   barWidth: 10,
  //   barRadius: 10,
  //   barGap: 2,
  //   url: 'https://reels.alvand.xyz/storage/melopost/518ebf481b538f1a6799401a7979ab5d/518ebf481b538f1a6799401a7979ab5d.mp3',
  //   peaks: [
  //     112, 100, 90, 31, 100, 85, 94, 83, 96, 136, 63, 59, 97, 95, 129, 138, 107,
  //     115, 114, 94, 73, 101, 88, 89, 89, 131, 100, 115, 90, 92, 128, 62, 95, 94,
  //     80, 76, 103, 82, 64, 88, 86, 88, 84, 114, 102, 100, 115, 72, 62, 75, 71,
  //     80, 87, 76, 93, 92, 84, 95, 65, 90, 89, 66, 82, 72, 118, 88, 73, 87, 93,
  //     76, 98, 88, 79, 126, 76, 76, 82, 64, 78, 76, 71, 78, 65, 76, 70, 80, 76,
  //     64, 63, 76,
  //   ],
  //   duration: 22,
  // });
  useEffect(() => {
    if (!containerRef) return;
    const wavesurfer = WaveSurfer.create({
      container: '#waveform',
      waveColor: '#F2C94C',
      progressColor: '#F2F2F2',
      barWidth: 1.3,

      barGap: 3.5,
      height:24,
      url: item?.url,
      peaks: [item?.amplitude],

      cursorWidth: '0',
      duration: 22,

      normalize: true,
      hideScrollbar: true,
    });

    wavesurfer.on('interaction', () => {
      wavesurfer.play();
    });

    wavesurfer.on('finish', () => {
      wavesurfer.setTime(0);
    });
  }, [containerRef]);

  // wavesurfer.on('interaction', () => {
  //   wavesurfer.play();
  // });

  // wavesurfer.on('finish', () => {
  //   wavesurfer.setTime(0);
  // });

  return (
    <div
      ref={containerRef}
      id="waveform"
      className="w-2/3 mx-auto h-full flex flex-col overflow-scroll "
    >
      <div className="bg-red">ss</div>
      {/* <button onClick={onPlayPause}>{isPlaying ? 'Pause' : 'Play'}</button> */}
      {/* <VoiceVisualizer
        audioSrc={audioSrc}
        mainBarColor="#ffffff"
        isDefaultUIShown={false}
        controls={recorderControls}
        progressIndicatorTimeClassName="!bg-red"
        isControlPanelShown={false}
      /> */}
      {/* <WavesurferPlayer

        ref={wavesurfer}
        height={100}
        waveColor="violet"
        url="https://reels.alvand.xyz/storage/melopost/518ebf481b538f1a6799401a7979ab5d/518ebf481b538f1a6799401a7979ab5d.mp3"
        // onReady={onReady}
        // onPlay={() => setIsPlaying(true)}
        // onPause={() => setIsPlaying(false)}
      /> */}
    </div>
  );
}

export default Test;
