import React, { useEffect } from 'react';
import PageContainer from './PageContainer';
import GlobalComponent from '../../components/GlobalComponent/GlobalComponent';
import InactiveSession from '../../components/inactiveDevice/InactiveSession.jsx';
import {
  contextMenuData,
  getInactiveSessionData
} from '../../helper/ReduxSelectorContext.js';
import ContextMenuContainer from '../../components/contextMenu/ContextMenuContainer.jsx';
import { Helmet } from 'react-helmet';
import { closeDropDownMenu } from '../../redux/player/DropDownSlice.js';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import NotifyPermision from '../../components/dialogs/NotifyPermision.jsx';
import NotifySettingPermisionIntro from '../../components/dialogs/NotifySettingPermisionIntro.jsx';

function Layout({ children }) {
  let inactiveSession = getInactiveSessionData();
  const { isContextMenuOpen } = contextMenuData();
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (isContextMenuOpen) {
      dispatch(closeDropDownMenu());
    }
  }, [location?.pathname]);
  return (
    <div className="w-full h-full  flex justify-center item z-[1] overflow-hidden  max-w-[520px]">
      <Helmet>
        <meta name="theme-color" content="#0E0E0E" />
      </Helmet>
      <GlobalComponent />
      {isContextMenuOpen && <ContextMenuContainer />}
      {inactiveSession && <InactiveSession />}
      {!inactiveSession && <NotifyPermision key={'Notif'} />}
      {!inactiveSession && (
        <NotifySettingPermisionIntro key={'Notif-setting-intro'} />
      )}
      <PageContainer>{children}</PageContainer>
    </div>
  );
}

export default React.memo(Layout);
