import React from 'react';
import HomeWeekNew from '../home/HomeWeekNew';
import { SelectSeenCollection } from '../../redux/RecentlyHeard/RecentlyHeardSlice';
import { useSelector } from 'react-redux';
import ProfileNoMusicCard from './ProfileRecentlyMp3Placeholder';
import ChevronLeft from '../svg/common/ChevronLeft';
import { useNavigate } from 'react-router';

function RecentlyHeardPlaylist() {
  const recentlySeenCollections = useSelector(SelectSeenCollection);
  const navigate = useNavigate();

  const showAll = () => {
    navigate('/my-melodify/recently-heard-playlist');
  };
  return (
    <div className="flex flex-col justify-between px-3 py-9 ">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="h-2 w-2 bg-gray_6 me-2 rounded-full"></div>
          <p className="font-dana font-medium text-base text-gray_6">
            پلی‌لیست‌هایی که اخیرا دیده شده
          </p>
        </div>
        {recentlySeenCollections?.length >= 3 && (
          <div
            onClick={showAll}
            className="flex items-center justify-end cursor-pointer px-1 -mx-1"
          >
            <p className="font-dana font-medium text-sm leading-6 text-gray_6">
              همه
            </p>
            <ChevronLeft className="stroke-gray_6 w-6 h-6 -me-[2px]" />
          </div>
        )}
      </div>
      <div>
        {recentlySeenCollections.length ? (
          <div className="-mx-4 pe-1 ">
            <HomeWeekNew
              item={recentlySeenCollections.slice(0, 15)}
              cardStyle="!h-[120px] !w-[120px]"
            />
          </div>
        ) : (
          <ProfileNoMusicCard
            title="هنوز پلی لیستی را ندیده‌ اید!"
            subtitle="پلی لیست محبوبتان را جستجو کنید "
          />
        )}
      </div>
    </div>
  );
}

export default RecentlyHeardPlaylist;
