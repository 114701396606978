import React from "react";
import CrownSvg from "../svg/playList/CrownSvg.jsx";
import { useAction } from "../../hooks/useAction.js";
import { CardActionArea } from "@mui/material";
import ClickReaction from "../../helper/ClickReaction.jsx";
import { useDispatch } from "react-redux";
import { setSwipePlayerStatus } from "../../redux/player/PlayMusicSlice.js";

function AdveBoxComponent({ advData }) {
  const action = useAction();
  const dispatch=useDispatch()
  const openPlans = () => {
    dispatch(setSwipePlayerStatus({ status: false }))
    action({...advData?.btn_action,openPlayerWhenBack:true});
  };
  return (
    <div
      dir="rtl"
      className=" rounded-[15px]  flex flex-col items-center justify-between text-center mt-2 gap-2 "
    >
      <div className="items-center max-w-[320px] mx-auto">
        <div className="flex flex-col gap-[6px] lg-1440:gap-r6 ">
          <p className=" text-white  text-[14px] font-[700] font-dana">
            {advData?.title}
          </p>

          <p className="font-dana font-[500] text-white text-[14px] leading-6">
            {advData?.description}
          </p>
        </div>
      </div>
      <ClickReaction>
        <CardActionArea className="!w-[195px] !h-[46px] !mt-4  !rounded-[6px]">
          <button
            onClick={() => {
              openPlans();
            }}
            className="bg-primary w-full h-full rounded-[6px] text-black font-dana font-[500] text-[16px]  cursor-pointer "
          >
            خرید اشتراک
          </button>
        </CardActionArea>
      </ClickReaction>
    </div>
  );
}

export default AdveBoxComponent;
