export const HeaderRoutesWithActionSection = [
  '/home',
  '/playlist',
  '/my-melodify',
  '/search',
  '/downloads',
]
export const HeaderRoutesWithMelodifyIcon = [
  '/home',
  '/meloposts',
  '/meloposts-hashtag-list'
]
