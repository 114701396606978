import { Slider } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { convertTime } from "../../../helper/ConvertPlayerTIme";
import { getTrackDuration } from "../../../helper/ReduxSelectorContext";
import { selectSeekTime } from "../../../redux/player/PlayMusicSlice";
function AdvertiseSeekBar({  }) {
  let seekBar = useSelector(selectSeekTime);
  const duration = getTrackDuration();
  return (
    <>
      <div
        dir="ltr"
        class="flex flex-col items-center w-full rounded-full gap-2 "
      >
        <Slider
          aria-label="time-indicator"
          className="!w-full !h-[3px] !m-0 !px-0 !py-[5px] "
          value={seekBar}
          min={0}
          step={0.01}
          max={duration}
          sx={{
            color: '#F2C94C',
            height: 4,
            '& .MuiSlider-thumb': {
              width: 0,
              height: 0,
              transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',

              '&:hover, &.Mui-focusVisible': {
                boxShadow: `0px 0px 0px 0px rgb(242, 201, 76 , 16%)`,
              },
              '&.Mui-active': {
                width: 0,
                height: 0,
                boxShadow: `0px 0px 0px 8px rgb(242, 201, 76 , 16%)`,
              },
            },
            '& .MuiSlider-rail': {
              backgroundColor: '#4F4F4F',
              opacity: 1,
            },
          }}
        />
        <div className="w-full flex flex-row justify-between items-center">
          <span class="font-dana font-[400] text-[12px] lg-1920:text-f10 text-white leading-[24px]  ">
            {convertTime(seekBar)}
          </span>

          <span class="font-dana font-[400] text-[12px] lg-1920:text-f10 text-gray_3 leading-[24px]  ">
            {convertTime(duration)}
          </span>
        </div>
      </div>
    </>
  );
}

export default AdvertiseSeekBar;
