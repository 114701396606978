import React from 'react';
import { StatisticsItem } from './StatisticsItem';

export const StatisticsList = ({ statistics,premiumAppAction }) => {
  return <div className='flex flex-col bg-background bg-opacity-60 rounded-[6px] p-4 gap-4 w-full'>
    {
      statistics?.map((statistic) => <StatisticsItem statistic={statistic} premiumAppAction={premiumAppAction}/>)
    }
  </div>;
};

