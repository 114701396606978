import React from 'react';

import { setLanguageDirection } from '../../../helper/LanguageDetection';
import PlaylistAndSongSearchResultAvatar from './PlaylistAndSongSearchResultAvatar';
import ArtistSearchResultAvatar from './ArtistSearchResultAvatar';
import { useNavigate } from 'react-router-dom';
import { useStoreSearchHistory } from '../../../hooks/useStoreSearchHistory';
import { CardActionArea } from '@mui/material';
import PlaceHolderWithTitle from '../../../components/common/PlaceHolderWithTitle';
import PlayListSearchIcon from '../../../components/svg/search/searchTypeIcon/PlayListSearchIcon';
import { textFontBasedLanguage } from '../../../helper/TextFontBasedLanguage.js';
import { SearchItemType } from '../../../consts/SearchItemType.js';
import { UserPlayListSearchIcon } from '../../../components/svg/search/searchTypeIcon/UserPlayListSearchIcon.jsx';
function SearchResultUserCollection({ item, fromHistory, index }) {
  const [, storeSearchItemWithType] = useStoreSearchHistory(fromHistory);
  const navigate = useNavigate();

  const storeInSearchHistory = item => {
    storeSearchItemWithType(
      { collection: item, id: item?.id },
      SearchItemType().user_collection,
    );
  };
  const openArtist = item => {
    navigate(`/user-playlist/${item?.id}`, {
      state: { collectionObject: item },
    });
    storeInSearchHistory(item);
  };
  return (
    <CardActionArea
      onClick={() => openArtist(item?.collection)}
      className={`${
        fromHistory ? '' : '!py-[10px]'
      } !my-2 !rounded-[5px] !px-3 ${
        fromHistory
          ? ''
          : index % 2 !== 0
          ? 'bg-song-detail-card-background-2'
          : 'bg-song-detail-card-background'
      }`}
    >
      <div dir="rtl" className="flex justify-end items-center">
        <div className="flex gap-2  items-center ">
          <div className="flex flex-col items-end gap-2 font-dana xl:ml-3">
            <p
              dir="ltr"
              className={`text-white text-sm font-medium line-clamp-1 max-w-[68vw] min-[520px]:max-w-sm ${textFontBasedLanguage(
                item?.collection?.title,
              )} `}
            >
              {item?.collection?.title}
            </p>
            <div className="w-full flex flex-row  justify-end items-center  gap-[6px]">
              <UserPlayListSearchIcon />
              <p className="text-gray_4 text-xs font-[500]   ">
                {item?.model_type_title}
              </p>
            </div>
          </div>
          <div className="w-[64px] h-[64px] relative">
            <PlaceHolderWithTitle style={'absolute'} />
            <PlaylistAndSongSearchResultAvatar
              image={item?.collection?.image}
            />
          </div>
        </div>
      </div>
    </CardActionArea>
  );
}

export default SearchResultUserCollection;
