import React from 'react';

function UserPlaylistShareLimit(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='59'
      height='41'
      fill='none'
      viewBox='0 0 59 41'
    >
      <path
        fill='#F2C94C'
        d='M47.385 30.574c-.384-.943-1.473-1.402-2.433-1.026a1.83 1.83 0 0 0-1.045 2.39c.488 1.198.328 2.567-.427 3.662-.756 1.095-1.965 1.723-3.319 1.723H7.776c-1.476 0-2.787-.757-3.507-2.024a3.85 3.85 0 0 1 .09-4.002L20.553 5.549a4.01 4.01 0 0 1 3.417-1.872c1.402 0 2.679.7 3.416 1.872l7.296 10.953a1.89 1.89 0 0 0 2.577.598 1.82 1.82 0 0 0 .609-2.53L30.571 3.617C29.146 1.352 26.678 0 23.969 0s-5.178 1.352-6.602 3.617L1.174 29.364C-.327 31.752-.392 34.64 1 37.09 2.39 39.538 4.923 41 7.776 41h32.385c2.616 0 4.955-1.217 6.418-3.338 1.453-2.105 1.754-4.755.806-7.088'
      ></path>
      <path
        fill='#F2C94C'
        d='M23.97 33.738c1.034 0 1.872-.823 1.872-1.839 0-1.015-.838-1.838-1.872-1.838h-.001c-1.034 0-1.872.823-1.872 1.838s.839 1.839 1.873 1.839M25.84 25.464V11.951c0-1.016-.837-1.839-1.871-1.839s-1.872.823-1.872 1.839v13.513c0 1.016.838 1.839 1.872 1.839s1.872-.823 1.872-1.839'
      ></path>
      <path
        fill='#fff'
        d='M53.32 17.398c-1.474-2.325-2.86-5.02-3.466-7.862l-.531-2.487a1.25 1.25 0 0 0-1.114-.972 1.25 1.25 0 0 0-1.269.757l-7.309 17.767a4.95 4.95 0 0 0-2.06-1.55c-2.557-1.015-5.472.19-6.5 2.689s.216 5.355 2.774 6.37 5.473-.192 6.501-2.69l7.384-17.948c.806 2.673 2.168 5.157 3.468 7.21a3.57 3.57 0 0 1 .297 3.307c-.77 1.873-2.958 2.778-4.876 2.017a1.254 1.254 0 0 0-1.625.672 1.217 1.217 0 0 0 .694 1.593c3.196 1.268 6.842-.24 8.126-3.362a6.03 6.03 0 0 0-.493-5.51'
      ></path>
    </svg>
  );
}

export default UserPlaylistShareLimit;
