export const USER_COLLECTION_ACTIONS = {
  EDIT_NAME:'edit_name',
  MOVE_ITEM:'move_item',
  CHANGE_PRIVATE:'change_private',
  DELETE_ITEM:'delete_item',
  SHARE_ITEM:'share_item'
}
const commonItems = {
  editName:{
    id: USER_COLLECTION_ACTIONS?.EDIT_NAME,
    title: 'ویرایش نام',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <g clipPath="url(#clip0_7053_22360)">
          <path
            fill="#BDBDBD"
            d="M3.265 16.01h.102l2.726-.252a1.64 1.64 0 0 0 1.005-.474L17.386 4.995a2.54 2.54 0 0 0 0-3.609l-.66-.66c-.968-.968-2.652-.968-3.619 0l-1.312 1.311-8.967 8.968c-.27.27-.437.623-.465 1.004l-.251 2.726c-.028.344.093.679.335.93.223.223.511.344.818.344M14.921 1.366c.298 0 .595.112.819.345l.66.66a1.15 1.15 0 0 1 0 1.637l-.819.819-2.297-2.298.818-.818c.224-.224.521-.345.819-.345M3.749 12.14c0-.056.028-.103.065-.14l8.474-8.484 2.298 2.298-8.474 8.474s-.093.066-.14.066l-2.446.223.223-2.447zM20 18.977a.703.703 0 0 1-.698.697H.698A.703.703 0 0 1 0 18.977c0-.382.316-.698.698-.698h18.604c.382 0 .698.316.698.698"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_7053_22360">
            <path fill="#fff" d="M0 0h20v20H0z"></path>
          </clipPath>
        </defs>
      </svg>
    ),
  },
  moveItem:{
    id: USER_COLLECTION_ACTIONS?.MOVE_ITEM,
    title: 'جابجایی',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <g
          fill="#BDBDBD"
          fillRule="evenodd"
          clipPath="url(#clip0_7053_22365)"
          clipRule="evenodd"
        >
          <path d="M6.968 16.254a.714.714 0 0 1 1.01 0L10 18.276l2.021-2.022a.714.714 0 1 1 1.01 1.01l-2.526 2.527a.714.714 0 0 1-1.01 0l-2.527-2.527a.714.714 0 0 1 0-1.01"></path>
          <path d="M10 12.143c.394 0 .714.32.714.714v6.429a.714.714 0 1 1-1.428 0v-6.429c0-.394.32-.714.714-.714M9.495.21a.714.714 0 0 1 1.01 0l2.527 2.526a.714.714 0 0 1-1.01 1.01L10 1.724 7.978 3.746a.714.714 0 1 1-1.01-1.01z"></path>
          <path d="M10 0c.394 0 .714.32.714.714v6.429a.714.714 0 1 1-1.428 0V.714C9.286.32 9.606 0 10 0M3.746 6.968a.714.714 0 0 1 0 1.01L1.724 10l2.022 2.022a.714.714 0 1 1-1.01 1.01L.209 10.505a.714.714 0 0 1 0-1.01l2.527-2.527a.714.714 0 0 1 1.01 0"></path>
          <path d="M0 10c0-.395.32-.714.714-.714h6.429a.714.714 0 1 1 0 1.428H.714A.714.714 0 0 1 0 10M16.254 6.968a.714.714 0 0 1 1.01 0l2.527 2.527a.714.714 0 0 1 0 1.01l-2.527 2.527a.714.714 0 1 1-1.01-1.01L18.275 10l-2.021-2.022a.714.714 0 0 1 0-1.01"></path>
          <path d="M12.143 10c0-.395.32-.714.714-.714h6.429a.714.714 0 1 1 0 1.428h-6.429a.714.714 0 0 1-.714-.714"></path>
        </g>
        <defs>
          <clipPath id="clip0_7053_22365">
            <path fill="#fff" d="M0 0h20v20H0z"></path>
          </clipPath>
        </defs>
      </svg>
    ),
  },
  changePrivateState:{
    id: USER_COLLECTION_ACTIONS?.CHANGE_PRIVATE,
    title: 'وضعیت پلی‌لیست',
    icon: (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.2764 8.52943C16.7613 8.01447 16.0765 7.73101 15.3482 7.73101H15.1509V5.02072C15.1509 2.25222 12.8987 0 10.1304 0H9.94475C7.17642 0 4.92404 2.25222 4.92404 5.02072V7.73085H4.72673C3.99852 7.73085 3.31371 8.01447 2.79859 8.52943C2.28362 9.04456 2 9.72937 2 10.4577V17.2733C2 18.0016 2.28362 18.6865 2.79859 19.2013C3.31355 19.7164 3.99836 20 4.72673 20H15.3482C16.0766 20 16.7614 19.7164 17.2766 19.2013C17.7915 18.6863 18.0751 18.0015 18.0751 17.2733V10.4577C18.0751 9.72953 17.7915 9.04472 17.2764 8.52943ZM6.23939 5.02072C6.23939 2.97764 7.90151 1.31536 9.94475 1.31536H10.1304C12.1735 1.31536 13.8356 2.97764 13.8356 5.02072V7.73085H6.23939V5.02072ZM16.7598 17.2733C16.7598 17.6447 16.609 18.0084 16.3464 18.2711C16.0797 18.5378 15.7253 18.6846 15.3482 18.6846H4.72673C4.34972 18.6846 3.9954 18.5378 3.72854 18.271C3.46202 18.0046 3.31519 17.6503 3.31519 17.2733V10.4577C3.31519 10.0807 3.46202 9.72608 3.72854 9.45955C3.99523 9.19303 4.34956 9.0462 4.72657 9.0462H15.3481C15.7251 9.0462 16.0796 9.19303 16.3461 9.45939C16.6128 9.72624 16.7596 10.0807 16.7596 10.4577L16.7598 17.2733Z" fill="#BDBDBD"/>
        <path d="M10.038 12.0488C9.67485 12.0488 9.38037 12.3433 9.38037 12.7065V15.0235C9.38037 15.3867 9.67485 15.6812 10.038 15.6812C10.4013 15.6812 10.6957 15.3867 10.6957 15.0235V12.7065C10.6957 12.3433 10.4013 12.0488 10.038 12.0488Z" fill="#BDBDBD"/>
      </svg>

    ),
  },
  deleteItem:  {
    id: USER_COLLECTION_ACTIONS?.DELETE_ITEM,
    title: 'حذف',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#BDBDBD"
          fillRule="evenodd"
          d="M8.42 0h-.045c-.211 0-.395 0-.569.028-.686.11-1.28.537-1.6 1.153-.082.156-.14.33-.206.531l-.015.043-.094.284-.029.083a1.22 1.22 0 0 1-1.203.805H1.732a.732.732 0 1 0 0 1.463h16.585a.732.732 0 1 0 0-1.463h-3.015a1.22 1.22 0 0 1-1.144-.888l-.094-.284-.015-.043a4 4 0 0 0-.205-.53A2.2 2.2 0 0 0 12.243.027 3.6 3.6 0 0 0 11.674 0H8.419M7.239 2.62a3 3 0 0 1-.133.307h5.838a3 3 0 0 1-.134-.307l-.037-.11-.098-.293a3 3 0 0 0-.13-.36.73.73 0 0 0-.533-.384 3 3 0 0 0-.383-.01H8.42c-.28 0-.339.003-.382.01a.73.73 0 0 0-.534.384 3 3 0 0 0-.13.36l-.097.293zM3.489 6.343a.73.73 0 0 1 .778.681l.45 6.731c.087 1.315.15 2.23.286 2.919.133.667.32 1.02.586 1.27.267.25.632.412 1.307.5.696.091 1.613.092 2.93.092h.755c1.318 0 2.235-.001 2.931-.092.675-.088 1.04-.25 1.307-.5s.453-.603.586-1.27c.137-.689.2-1.604.287-2.919l.449-6.73a.732.732 0 1 1 1.46.097l-.452 6.782c-.084 1.251-.151 2.262-.31 3.055-.163.825-.443 1.514-1.02 2.054s-1.283.773-2.117.882C12.9 20 11.887 20 10.632 20h-.857c-1.254 0-2.267 0-3.069-.105-.834-.11-1.54-.342-2.117-.882s-.856-1.229-1.02-2.054c-.159-.793-.226-1.804-.31-3.055l-.452-6.782a.73.73 0 0 1 .682-.779"
          clipRule="evenodd"
        ></path>
      </svg>
    ),
  },
  shareItem:{
    id: USER_COLLECTION_ACTIONS?.SHARE_ITEM,
    title: 'ارسال',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        fill="none"
        viewBox="0 0 21 21"
      >
        <g clipPath="url(#clip0_2113_68830)">
          <path
            fill="#fff"
            d="M15.532 13.953c-.958 0-1.821.414-2.42 1.072l-5.39-3.338a3.26 3.26 0 0 0 0-2.374l5.39-3.338a3.27 3.27 0 0 0 2.42 1.072 3.277 3.277 0 0 0 3.274-3.274A3.277 3.277 0 0 0 15.532.5a3.277 3.277 0 0 0-3.05 4.46L7.092 8.3a3.27 3.27 0 0 0-2.42-1.073A3.277 3.277 0 0 0 1.398 10.5a3.277 3.277 0 0 0 3.274 3.274c.958 0 1.822-.414 2.42-1.073l5.39 3.338a3.277 3.277 0 0 0 3.05 4.46 3.277 3.277 0 0 0 3.274-3.273 3.277 3.277 0 0 0-3.274-3.273m-2.08-10.18c0-1.146.933-2.08 2.08-2.08s2.08.934 2.08 2.08c0 1.147-.933 2.08-2.08 2.08a2.08 2.08 0 0 1-2.08-2.08m-8.78 8.807a2.08 2.08 0 0 1-2.08-2.08c0-1.147.933-2.08 2.08-2.08s2.08.933 2.08 2.08-.933 2.08-2.08 2.08m8.78 4.646c0-1.146.933-2.08 2.08-2.08s2.08.933 2.08 2.08-.933 2.08-2.08 2.08a2.08 2.08 0 0 1-2.08-2.08"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_2113_68830">
            <path fill="#fff" d="M.11.5h20v20h-20z"></path>
          </clipPath>
        </defs>
      </svg>
    ),
  },
}

export const USER_COLLECTION_OPTIONS = [
  commonItems?.editName,
  commonItems?.moveItem,
  commonItems?.changePrivateState,
  commonItems?.deleteItem,

];
export const USER_COLLECTION_IN_PLAYLIST_OPTIONS = [
  commonItems?.shareItem,
  commonItems?.editName,
  commonItems?.changePrivateState,
  commonItems?.deleteItem,
];
