import React from 'react';

import { setLanguageDirection } from '../../../helper/LanguageDetection';
import PlaylistAndSongSearchResultAvatar from './PlaylistAndSongSearchResultAvatar';
import ArtistSearchResultAvatar from './ArtistSearchResultAvatar';
import SearchResultPlaylist from './SearchResultPlaylist';
import { useNavigate } from 'react-router-dom';
import { useStoreSearchHistory } from '../../../hooks/useStoreSearchHistory';
import { CardActionArea } from '@mui/material';
import ArtistPlaceHolderSvg from '../../../components/svg/common/ArtistPlaceHolderSvg';
import AlbumCardPlaceHolder from '../../../components/svg/album/AlbumCardPlaceHolder';
import ArtistProfilePlaceHolderSvg from '../../../components/svg/common/ArtistProfilePlaceHolderSvg';
import ArtistSearchIcon from '../../../components/svg/search/searchTypeIcon/ArtistSearchIcon';
import { textFontBasedLanguage } from '../../../helper/TextFontBasedLanguage.js';
import UserAvatar from '../../../components/common/UserAvatar.jsx';
import ChevronLeft from '../../../components/svg/common/ChevronLeft.jsx';
import { SearchItemType } from '../../../consts/SearchItemType.js';
function SearchResultUser({ item, fromHistory, index, title }) {
  const navigate = useNavigate();
  const [, storeSearchItemWithType] = useStoreSearchHistory();
  const storeInSearchHistory = item => {
    storeSearchItemWithType(
      { social_profile: item, id: item?.token },
      SearchItemType()?.social_profile,
    );
  };

  const openUserProfile = () => {
    storeInSearchHistory(item);
    navigate(`/profile/${item?.token}`);
  };
  return (
    <CardActionArea
      onClick={openUserProfile}
      className={`${
        fromHistory ? '' : '!py-[10px]'
      }  !my-2 !rounded-[5px] !px-4 
        `}
    >
      <div
        dir={fromHistory ? 'rtl' : 'ltr'}
        className="flex justify-between items-center "
      >
        {fromHistory ? (
          <div></div>
        ) : (
          <ChevronLeft className={'stroke-gray_3'} />
        )}
        <div className="flex gap-2 items-center ">
          <div className="flex flex-col items-end gap-2 font-dana xl:ml-3">
            <p
              dir="ltr"
              className={`text-white text-sm font-medium line-clamp-1 max-w-[68vw] min-[520px]:max-w-sm ${textFontBasedLanguage(
                item?.name,
              )} `}
            >
              {item?.name}
            </p>
            {fromHistory ? (
              <div className="w-full flex flex-row items-center justify-end gap-[6px]">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_5962_88374)">
                    <path
                      d="M7 14C5.13023 14 3.37239 13.2719 2.05023 11.9498C0.728137 10.6276 0 8.86977 0 7C0 5.13023 0.728137 3.37239 2.05023 2.05023C3.37239 0.728137 5.13023 0 7 0C8.86977 0 10.6276 0.728137 11.9498 2.05023C13.2719 3.37239 14 5.13023 14 7C14 8.86977 13.2719 10.6276 11.9498 11.9498C10.6276 13.2719 8.86977 14 7 14ZM7 1.09375C3.74328 1.09375 1.09375 3.74328 1.09375 7C1.09375 10.2567 3.74328 12.9062 7 12.9062C10.2567 12.9062 12.9062 10.2567 12.9062 7C12.9062 3.74328 10.2567 1.09375 7 1.09375ZM6.97266 7.51953C5.52521 7.51953 4.34766 6.34197 4.34766 4.89453C4.34766 3.44709 5.52521 2.26953 6.97266 2.26953C8.4201 2.26953 9.59766 3.44709 9.59766 4.89453C9.59766 6.34197 8.4201 7.51953 6.97266 7.51953ZM6.97266 3.36328C6.12831 3.36328 5.44141 4.05021 5.44141 4.89453C5.44141 5.73885 6.12831 6.42578 6.97266 6.42578C7.817 6.42578 8.50391 5.73885 8.50391 4.89453C8.50391 4.05021 7.817 3.36328 6.97266 3.36328ZM10.4374 10.7043C10.6711 10.5129 10.7054 10.1684 10.514 9.9347C9.74069 8.99065 8.59808 8.44922 7.37912 8.44922H6.73025C5.5113 8.44922 4.36868 8.99065 3.5954 9.93472C3.40402 10.1684 3.43829 10.5129 3.67196 10.7043C3.90562 10.8957 4.25018 10.8614 4.44158 10.6278C5.00623 9.93836 5.84043 9.54297 6.73025 9.54297H7.37912C8.26894 9.54297 9.10314 9.93836 9.66782 10.6278C9.77596 10.7598 9.93292 10.8281 10.0912 10.8281C10.2131 10.8281 10.3358 10.7876 10.4374 10.7043Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5962_88374">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <p
                  dir="ltr"
                  className={`text-gray_4 text-xs font-normal line-clamp-1 max-w-[68vw] min-[520px]:max-w-sm ${textFontBasedLanguage(
                    title,
                  )} `}
                >
                  {title}{' '}
                </p>
              </div>
            ) : (
              <div className="w-full flex flex-row items-center justify-end gap-[6px]">
                <p
                  dir="ltr"
                  className={`text-gray_4 text-xs font-normal line-clamp-1 max-w-[68vw] min-[520px]:max-w-sm ${textFontBasedLanguage(
                    item?.username,
                  )} `}
                >
                  {item?.username}
                </p>
              </div>
            )}
          </div>
          <div className="w-[65px] h-[65px] relative">
            <div className="absolute w-full h-full">
              <ArtistPlaceHolderSvg />
            </div>

            <UserAvatar
              crownClassName="w-[24px] h-[24px] min-w-[16px]"
              premium_crown
              social_token={item?.token}
              is_premium={item?.is_premium}
              className="w-full h-full"
              avatar={item?.avatar}
            />
          </div>
        </div>
      </div>
    </CardActionArea>
  );
}

export default SearchResultUser;
