import React, {useEffect, useState} from "react";
import SortDropDownItem from "../common/SortDropDownItem";
import SongDetailCard from "../playlist/SongDetailCard";
import SortSvg from "../svg/playList/SortSvg";
import SongsDetailCardShimmer from "../Tickets/SongsDetailCardShimmer.jsx";
import PlayIconType2Svg from "../svg/common/PlayIconType2Svg.jsx";
import PauseIconType2 from "../common/PauseIconType2.jsx";
import ClickReaction from "../../helper/ClickReaction.jsx";
import {addToPlaylistData, getIsPlaying, getPlayedPlaylist,} from "../../helper/ReduxSelectorContext.js";
import {PlayListType} from "../../consts/PlayListType.js";
import {
    setIsPlaying,
    setPlayedPlaylist,
    setPlayerPaginationData,
    setPlayList,
} from "../../redux/player/PlayMusicSlice.js";
import {usePlayedTrack} from "../../hooks/usePlayedTrack.js";
import {useDispatch} from "react-redux";
import TrackLimit from "../playlist/TrackLimit.jsx";
import SwipeBottomSheet from "../bottomSheets/SwipeBottomSheet.jsx";
import BottomSheetHeader from "../bottomSheets/BottomSheetHeader.jsx";
import {Virtuoso} from "react-virtuoso";
import EmptySongListSvg from "../svg/playList/EmptySongListSvg.jsx";
import AddToPlaylistModeButton from "../playlist/AddToPlaylistModeButton.jsx";
import {disableListSelectMode} from "../../redux/adToPlaylist/AddToPlaylistSlice.js";
import { useLimitation } from '../../hooks/useLimitation.js';
import { mainToast } from '../../helper/ToastsEmitter.jsx';

function CompleteSongList({
                              list,
                              onChangeSort = () => console.log('onChangeSort'),
                              loading,
                              pageId,
                              sortTypes = [],
                              collectionObject,
                              updated = false,
                              updatedCount = 0,
                              scrollId,
                              sortButton = null,
                              trackCount = 0,
                              userPlaylist,
                              hasDetails = true,
                              apiUrl = null,
                              requestPayload = null,
                              endOfPagination = true,
                              hasPlayButton = true,
                              showTrackCount =true,
                               showSort=true,
                            isInUserPlaylist=false,
  selectedSort,
                          }) {
    const [activeSortId, setActiveSortId] = useState(sortTypes[0]?.id);
    const [activeSortText, setActiveSortText] = useState(sortTypes[0]?.title);
    const [sortMenuState, setSortMenuState] = useState(false);
    const {hasSortLimit} = useLimitation()
    const [openSortBottomSheet, setOpenSortBottomSheet] = useState();
    const dispatch = useDispatch();

    const addToPlaylistDataState = addToPlaylistData();

    const selectSort = (id, text, type,canLimit) => {
        if(canLimit && hasSortLimit()){
          mainToast("برای فعال سازی ویژه شوید.");
          return
        }
        dispatch(disableListSelectMode());
        setActiveSortId(id);
        setActiveSortText(text);
        onChangeSort(type);
        handleBottomSheetState(false);
    };


    const toggleSortTypeBottomSheet = (newOpen) => () => {
        setOpenSortBottomSheet(newOpen);
    };
    const handleBottomSheetState = (state) => {
        setOpenSortBottomSheet(state);
    };
    useEffect(() => {
        setActiveSortId(sortTypes[0]?.id);
        setActiveSortText(sortTypes[0]?.title);
    }, [pageId]);
    useEffect(() => {
        return () => {
            dispatch(disableListSelectMode());
        };
    }, []);

    return (
        <>
            <AddToPlaylistModeButton
                EnableEditMode={addToPlaylistDataState?.listSelectItems?.length > 0}
            />

            <div class="flex flex-col gap-4  h-full min-h-[55vh] ">
                {hasDetails && (
                    <div className="flex w-full justify-between items-center ">
                        <SwipeBottomSheet
                            open={openSortBottomSheet}
                            toggleDrawer={toggleSortTypeBottomSheet}
                        >
                            <BottomSheetHeader
                                dividerLine={true}
                                HeaderIcon={
                                    <div className="flex w-[22px] h-[16px]  ">
                                        <SortSvg fill={"#FFFFFF"}/>
                                    </div>
                                }
                                closeBottomSheet={() => {
                                    handleBottomSheetState(false);
                                }}
                                title="ترتیب نمایش "
                            />
                            <div>
                                {sortTypes?.map((item, index) => (
                                    <div
                                        className={`${index === 0 && "pt-[21px]  "}`}
                                    >
                                        <SortDropDownItem
                                            updated={updated}
                                            updatedCount={updatedCount}
                                            key={index}
                                            title={item.title}
                                            subtitle={item.subtitle}
                                            active={activeSortId}
                                            Icon={item.icon}
                                            index={item.id}
                                            type={item?.type}
                                            isLimited={item?.canLimit && hasSortLimit()}
                                            selectSort={(id, title) => {
                                                selectSort(id, title, item.type,item?.canLimit);
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </SwipeBottomSheet>
                        <div
                            onClick={() => {
                                handleBottomSheetState(true);
                            }}
                        >
                            {showSort && sortButton(activeSortText)}
                        </div>


                            <div>

                                    <p className="text-gray_4 text-xs font-dana font-normal ">
                                       {Number(trackCount)?.toLocaleString("en-us")} آهنگ
                                    </p>

                            </div>



                    </div>
                )}
                <div class="flex flex-col w-full  relative  ">
                    {loading && !list && (
                        <div className={"flex flex-col gap-3"}>
                            {Array.from(
                                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                                    <SongsDetailCardShimmer/>
                                ))
                            )}
                        </div>
                    )}
                    {list && list?.length > 0 && (
                        <Virtuoso
                            customScrollParent={document.getElementById(scrollId)}
                            data={list}
                            itemContent={(index, item) => (
                                <div style={{opacity: 1}}>
                                    <div>
                                        {item?.track_limit && item.track_limit.message && (
                                            <TrackLimit message={item.track_limit.message}/>
                                        )}
                                        <div
                                            className={`${
                                                item?.track_limit && "px-2 bg-black_2 "
                                            } pb-2`}
                                        >
                                            <SongDetailCard
                                                trackLimit={item?.track_limit}
                                                id={item}
                                                // number={true}
                                                isSelectMode={
                                                    addToPlaylistDataState?.listSelectItems?.length > 0
                                                }
                                                inPlayer={false}
                                                index={index}
                                                data={item}
                                                playList={list}
                                                collectionObject={collectionObject}
                                                apiUrl={apiUrl}
                                                requestPayload={requestPayload}
                                                endOfPagination={endOfPagination}
                                                isInUserPlaylist={isInUserPlaylist}
                                                pageSort={selectedSort}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                    )}
                    {!loading && list?.length === 0 && (
                        <div className="flex flex-col items-center mt-5   w-full h-[500px] ">
                            <EmptySongListSvg/>
                            <span
                                className={
                                    "text-gray_3 font-medium font-dana text-[18px] leading-6 "
                                }
                            >
                {userPlaylist
                    ? "آهنگی به این پلی‌لیست نیفزوده‌اید."
                    : "آهنگی در این پلی‌لیست وجود ندارد."}
              </span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default CompleteSongList;
