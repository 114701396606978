import React from 'react';
import TicketsSkeleton from '../../components/Tickets/TicketsSkeleton.jsx';
import PlaySkeleton from '../../components/home/PlaySkeleton.jsx';
import CssShimmer from '../../components/common/CssShimmer.jsx';

function PlaylistShimmer(props) {
  return (
    <div dir="rtl" className="w-full h-full flex flex-col gap-6 mt-6">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2 w-full justify-between items-start">
            <CssShimmer
              reverse={true}
              classNames={'w-3/12 h-8 rounded-l-[8px]'}
              duration={'0.7s'}
            />
          </div>
        </div>
        <div className=" grid grid-cols-2 overflow-x-auto  overflow-y-hidden  hide-scroll-bar w-full h-full px-4  gap-2  ">
          {Array.from(
            [1, 2, 3, 4].map(item => (
              <div className="col-span-1 w-full">
                <CssShimmer
                  shimStart={'-250%'}
                  shimEnd={'250%'}
                  reverse={true}
                  classNames={'h-32 w-full rounded-lg'}
                  duration={'0.7s'}
                />
              </div>
            )),
          )}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2 w-full justify-between items-start">
            <CssShimmer
              reverse={true}
              classNames={'w-3/12 h-8 rounded-l-[8px]'}
              duration={'0.7s'}
            />
          </div>
        </div>
        <div className=" grid grid-cols-2 overflow-x-auto  overflow-y-hidden  hide-scroll-bar w-full h-full px-4  gap-2  ">
          {Array.from(
            [1, 2, 3, 4].map(item => (
              <div className="col-span-1 w-full">
                <CssShimmer
                  shimStart={'-250%'}
                  shimEnd={'250%'}
                  reverse={true}
                  classNames={'h-32 w-full rounded-lg'}
                  duration={'0.7s'}
                />
              </div>
            )),
          )}
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex flex-col px-4 gap-2">
          <div className="flex flex-col gap-2 w-full justify-between items-start">
            <CssShimmer
              reverse={true}
              classNames={'w-5/12 h-7 rounded-full'}
              duration={'0.7s'}
            />
            <CssShimmer
              reverse={true}
              classNames={'w-full h-5 rounded-full'}
              duration={'0.7s'}
            />
          </div>
        </div>
        <div className="flex justify-start overflow-x-auto  overflow-y-hidden  hide-scroll-bar w-full h-full px-4 flex gap-2  ">
          {Array.from(
            [1, 2, 3, 4, 5].map(item => (
              <div>
                <CssShimmer
                  shimStart={'-250%'}
                  shimEnd={'250%'}
                  reverse={true}
                  classNames={'h-32 w-32 rounded-lg'}
                  duration={'0.7s'}
                />
              </div>
            )),
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(PlaylistShimmer);
