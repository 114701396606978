import React, { useState } from 'react';
import ThreeDotSvg from '../../svg/common/ThreeDotSvg.jsx';
import SwipeBottomSheet from '../../bottomSheets/SwipeBottomSheet.jsx';
import BottomSheetHeader from '../../bottomSheets/BottomSheetHeader.jsx';
import { CardActionArea } from '@mui/material';
import { useTrackOptions } from '../../../hooks/playerOptions/useTrackOptions.jsx';
import FullPageNoteLoading from '../../loading/FullPageNoteLoading.jsx';
import AddToPlaylistModeButton from '../../playlist/AddToPlaylistModeButton.jsx';
import GoToArtistBottomSheet from '../../player/GoToArtistBottomSheet.jsx';

export const TrackOptionsBottomSheet = ({trackItem,isInQueueList,isInUserPlaylist,pageSort,inPlayer}) => {

  const { trackOptions,handleClickItem,toggleBottomSheetState,handleCloseOptions,handleOpenOptions,openState,deleteTrackLoading,renderItemInfo,addToPlaylistButtonRef,goToArtistBottomSheetRef,multiSelect } = useTrackOptions({trackItem:trackItem,isInQueueList,isUserPlaylistPage:isInUserPlaylist,pageSort,inPlayer});
  const handleOnClick = (event) =>{
    event?.stopPropagation()
    event?.preventDefault()
  }

  return (
    <div onClick={handleOnClick}>
      <AddToPlaylistModeButton
        ref={addToPlaylistButtonRef}
        inPlayer={inPlayer}
        EnableEditMode={multiSelect}
        inPlayerSheet={inPlayer}
      />
      <GoToArtistBottomSheet

        ref={goToArtistBottomSheetRef}
        data={trackItem}
      />
      {deleteTrackLoading ? (
        <FullPageNoteLoading showBottomNavigation={true}/>
      ) : null}
      <div
        onClick={handleOpenOptions}
        className="flex items-center h-full pl-4 pr-2   relative "
      >
        <div
          className=" h-[40px] items-center flex "
          style={{ aspectRatio: 4 / 17 }}
        >
          <ThreeDotSvg class=" " fill={'#828282'} />
        </div>
      </div>
      <SwipeBottomSheet
        keepMount={false}
        open={openState}
        toggleDrawer={toggleBottomSheetState}
      >
        <BottomSheetHeader
          dividerLine={true}
          closeBottomSheet={handleCloseOptions}
          title="گزینه ها"
        />

        <div dir="rtl" className=" w-full h-full flex flex-col relative">

          {trackOptions?.map(item => (
            <CardActionArea
              onClick={(event) => handleClickItem(event,item)}
              key={item?.id}
              className="!p-4"
            >
              <div className=" flex flex-row justify-between items-center gap-2">
                <div className=" flex flex-row justify-between items-center gap-3">
                  <div className="w-5 h-5">
                    <item.icon fill={item?.iconColor} {...item?.iconProps} />
                  </div>
                  <span
                    style={{ color: item?.textColor }}
                    className="font-dana font-medium text-sm "
                  >
                    {item?.title}
                  </span>
                </div>
                {item?.defaultConfig && (
                  <div
                    style={{ color: item?.defaultConfigColor }}
                    className={'font-dana text-[16px]'}
                  >
                    {renderItemInfo(item?.key)}
                  </div>
                )}
              </div>
            </CardActionArea>
          ))}
        </div>
      </SwipeBottomSheet>
    </div>
  );
};

