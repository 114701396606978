import React from 'react';
import { Virtuoso } from 'react-virtuoso';
import TrackLimit from '../playlist/TrackLimit.jsx';
import SongDetailCard from '../playlist/SongDetailCard.jsx';
import {
  addToPlaylistData,
  getPlayerMusic,
} from '../../helper/ReduxSelectorContext.js';

function PlayerTrackList({ playList, className, parentId = 'playlist-item' }) {
  const addToPlaylistDataState = addToPlaylistData();
  let playedTrack = getPlayerMusic();
  return (
    <div
      className={`w-full overflow-hidden pb-20 flex flex-col gap-2 ${
        playedTrack?.lyric?.has_lyric && 'mt-4'
      } ${className}`}
    >
      <Virtuoso
        customScrollParent={document.getElementById(parentId)}
        data={playList}
        itemContent={(index, item) => (
          <div>
            {item?.track_limit && item.track_limit.message && (
              <TrackLimit
                inPlayer={true}
                hillFill={'#212121'}
                crownParentStyle={'bg-black_2'}
                style={'bg-primary_dark'}
                message={item.track_limit.message}
              />
            )}
            <div
              id={`playerTrackItem-${index}`}
              className={` ${item?.track_limit && 'px-2 bg-primary_dark'}`}
            >
              <SongDetailCard
                inPlayer={false}
                playList={playList}
                number={false}
                data={item}
                index={index}
                isSelectMode={
                  addToPlaylistDataState?.listSelectItems?.length > 0
                }
                disableSwipePlayerOpen
              />
            </div>
          </div>
        )}
      />
    </div>
  );
}

export default PlayerTrackList;
