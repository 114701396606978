import React, { useEffect, useRef, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  getArtistTracks,
  getCollectionData,
  getCollectionTracks,
} from '../../services/ApiClient.js';
import { useLocation, useParams } from 'react-router-dom';
import { playListSortTypes } from '../../consts/MenuItemsConsts.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import CompleteSongList from '../../components/ArtistProfile/CompleteSongList.jsx';

import CustomLazyLoadImage from '../../components/common/CustomLazyLoadImage.jsx';
import { useDispatch } from 'react-redux';
import { setSeenCollection } from '../../redux/RecentlyHeard/RecentlyHeardSlice';

import { getInitialData, globalData } from '../../helper/ReduxSelectorContext';
import PlaylistUpdateDialog from '../../components/playlist/PlaylistUpdateDialog';
import { setPlaylistUpdateDialogShowed } from '../../redux/globalData/globalDataSlice.js';
import { useAction } from '../../hooks/useAction.js';
import ScrollTopButton from '../../components/common/ScrollTopButton.jsx';
import Container from '../../components/PageParentContainer/Container.jsx';
import CollectionCategoryHeader from '../../components/playlist/CollectionCategoryHeader.jsx';
import SortSvg from '../../components/svg/playList/SortSvg.jsx';
import SongListLoading from '../../components/loading/SongListLoading.jsx';
import { PlaylistInfoBox } from '../../components/playlist/melodifyPlaylist/PlaylistInfoBox.jsx';
import { ImageGradientOverlay } from '../../components/common/ImageGradientOverlay.jsx';

function PlayListSongs() {
  const { playlistUpdateDialogShowed } = globalData();
  const { id: collectionId } = useParams();

  const location = useLocation();
  const collectionObject = location.state?.collectionObject;
  const dialogRef = useRef(null);
  const [sortType, setSortType] = useState(playListSortTypes[0].type);
  const [collectionData, setCollectionData] = useState('');
  const [collectionTrackList, setCollectionTrackList] = useState(null);
  const dispatch = useDispatch();
  const action = useAction();
  const [trackLimit, setTrackLimit] = useState();
  const collectionQueryData = {
    collection_id: collectionId,
    offset: collectionTrackList?.length || 0,
    sort: sortType,
  };
  const getCollectionDataQuery = useQuery(
    ['getCollectionData' + collectionId],
    () => getCollectionData(collectionId),
    {
      onSuccess: res => {
        setCollectionData(res?.data?.result?.collection);
        dispatch(
          setSeenCollection({ seen_Collection: res?.data?.result?.collection }),
        );
      },
      refetchOnWindowFocus: false,
    },
  );
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ['getPlaylistTracks' + sortType + collectionId],
      ({ pageParam = 0 }) =>
        getCollectionTracks({
          ...collectionQueryData,
          offset: pageParam,
        }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const allData = allPages?.flatMap(page => page?.data?.result?.tracks);
          return lastPage?.data?.result?.end ? false : allData?.length;
        },
        refetchOnWindowFocus: false,
        enabled: !getCollectionDataQuery?.isLoading,
      },
    );
  const tracksList = data?.pages?.flatMap(page => page?.data?.result?.tracks);
  const reFetchData = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };
  const changeSort = type => {
    setSortType(type);
  };
  useEffect(() => {
    setCollectionData(getCollectionDataQuery?.data?.data?.result?.collection);
  }, [getCollectionDataQuery?.data]);

  useEffect(() => {
    changeSort(playListSortTypes[0].type);
  }, [collectionId]);
  //todo:we dont have second title in collection data


  const hasLimit = () => {
    const result = collectionTrackList?.some(item => item?.track_limit != null);
    setTrackLimit(result);
  };


  useEffect(() => {
    hasLimit();
  }, [collectionTrackList]);
  useEffect(() => {
    if (playlistUpdateDialogShowed) {
      return;
    }
    if (collectionData?.is_updated) {
      dispatch(setPlaylistUpdateDialogShowed());
      dialogRef?.current?.openModal();
    }
  }, [collectionData]);
  useEffect(() => {
    console.log(collectionObject,'collection object...');
  }, []);


  return (
    <>
      <Container containerStyle={{background:collectionObject?.background_color}}>

        <div className='absolute top-0 left-0 right-0 bottom-0 bg-background bg-opacity-80 '>

        </div>
        <CollectionCategoryHeader
          hasSearch={false}
          title={collectionObject?.title}
          backgroundId="playlist-songs-background"
          pageId="playlist-song"
        />
        <div
          dir="rtl"
          id="playlist-song"
          className="w-full h-full overflow-scroll hide-scroll-bar pb-10"
        >
          <div
            id="playlist-songs-background"
            className=" w-full aspect-square relative  overflow-hidden xl:min-h-[30vw] "
          >
            <CustomLazyLoadImage
              imageClass={`object-cover block  `}
              image={
                collectionObject?.image_large
                  ? collectionObject?.image_large
                  : collectionObject?.image
              }
            />
            <ImageGradientOverlay backgroundColor={collectionObject?.background_color}/>

          </div>
          <ScrollTopButton id={'playlist-song'} />
          <PlaylistUpdateDialog ref={dialogRef} />

          <div
            id="page-infinite-scroll"
            className="relative px-4 flex flex-col gap-5 pt-[58px] w-full"
          >
            <PlaylistInfoBox collectionObject={collectionObject} collectionData={collectionData}
                             list={tracksList}
                             apiUrl="/getCollectionTracks"
                             requestPayload={{
                               collection_id: collectionId,
                               sort: sortType,
                             }}
                             endOfPagination={!hasNextPage}
                             isLoading={getCollectionDataQuery?.isLoading}
            />
            <div class="flex justify-between items-center w-full gap-3">


            </div>
            <InfiniteScroll
              className="!overflow-visible"
              next={reFetchData}
              hasMore={hasNextPage}
              loader={
                <div className="mt-4 flex w-full justify-center">
                  <SongListLoading />
                </div>
              }
              scrollableTarget="playlist-song"
              dataLength={tracksList?.length || 0}
            >
              <div>
                <CompleteSongList
                  scrollId={'playlist-song'}
                  updated={collectionData?.is_updated}
                  updatedCount={collectionData?.updated_tracks}
                  onChangeSort={changeSort}
                  list={tracksList}
                  pageId={collectionId}
                  titleUnderLine={false}
                  collectionObject={collectionObject}
                  trackCount={collectionData?.tracks_count}
                  sortTypes={playListSortTypes}
                  sortButton={activeSortText => (
                    <div className="flex items-center gap-1">
                      <div className="flex gap-2   items-center">
                        <div className="flex w-[22px] h-[16px]  ">
                          <SortSvg />
                        </div>
                        <p className="text-primary font-dana text-[14px] font-medium">
                          ترتیب نمایش:
                        </p>
                      </div>
                      <p className="text-gray_3 font-dana text-[12px] font-medium">
                        {activeSortText}
                      </p>
                    </div>
                  )}
                  loading={isLoading && !tracksList}
                  apiUrl="/getCollectionTracks"
                  requestPayload={{
                    collection_id: collectionId,
                    sort: sortType,
                  }}
                  endOfPagination={!hasNextPage}
                />
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </Container>
    </>

  );
}

export default PlayListSongs;
