import React from 'react';

function GrayPlayIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className='w-full h-full'
      fill="none"
      viewBox="0 0 32 32"
    >
      <circle cx="16" cy="16" r="16" fill="#404040"></circle>
      <path
        fill="#F2C94C"
        d="M21.2 14.96a1.2 1.2 0 0 1 0 2.08l-6.9 3.983a1.2 1.2 0 0 1-1.8-1.04v-7.967a1.2 1.2 0 0 1 1.8-1.039z"
      ></path>
    </svg>
  );
}

export default GrayPlayIcon;
