import React from "react";
import { CrownSvgType3 } from '../svg/common/CrownSvgType3';

function SortDropDownItem({
  title,
  subtitle,
  active,
  Icon,
  index,
  selectSort,
  updated = false,
  updatedCount = 0,
  type,
  isLimited
}) {
  return (
    <div
      dir="rtl"
      onClick={() => selectSort(index, title)}
      class="flex w-full justify-between pb-5 items-center px-[21px]  "
    >
      <div class="flex gap-3  items-center cursor-pointer">
        <div class="aspect-square h-[20px] ">
          {<Icon fill={active === index ? "#BDBDBD" : "#BDBDBD"} />}
        </div>
        <div className="flex flex-col ">
          <div className="flex items-center  gap-2">
            <p
              className={`${
                active == index ? "text-white" : "text-white"
              } font-dana text-[15px] font-normal leading-6`}
            >
              {title}
            </p>
            <div>
              {updated && type === "newly_added" && (
                <span className="text-primary font-dana font-normal    text-[14px] ">
                  (<span>{updatedCount} </span>آهنگ جدید)
                </span>
              )}
            </div>
          </div>
          <p
            className={`${
              active == index ? "text-gray_4" : "text-gray_4"
            } font-dana text-[12px] font-normal leading-6`}
          >
            {subtitle}
          </p>
        </div>
      </div>
      {Boolean(isLimited ) &&
        <div className='flex justify-center items-center border-[1px] border-primary gap-2 w-[93px] h-6 rounded-[22px]'>
          <CrownSvgType3/>
          <p className='font-dana text-[12px] font-[400] text-primary'>کاربران ویژه</p>

        </div>
      }
    </div>
  );
}

export default SortDropDownItem;
