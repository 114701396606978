import React from 'react';
import clsx from 'clsx';
import { Checkbox } from './Checkbox.jsx';

export const MelopostReportItem = ({ item,onClick,isSelected}) => {
  return (
    <div onClick={onClick} className={clsx(`flex bg-secondary w-full rounded-[8px] px-4 py-[14px] justify-between items-center border-[1px] `,isSelected?'border-primary':'border-gray_2')}>
    <div>
      <p className="text-gray_4 font-dana text-[14px] fa-format-400 ">{item?.title}</p>
    </div>
   <div>
     <Checkbox isSelect={isSelected}/>
   </div>
  </div>);
};

