import React from 'react';
import clsx from 'clsx';
import { USER_PROFILE_STATUS } from '../../consts/UserProfileStatus';


export const BooleanToggleButton = ({enableText,disableText,status, setStatus }) => {
  const handleToggle = value => {
    if (setStatus) if (value != status) setStatus(value);
  };
  return (
    <div className="relative rounded-[10px] bg-gray_2 h-full">
      <div className={`relative flex h-full items-center  `}>
        <div
          className="flex h-full w-full cursor-pointer select-none items-center justify-center *:select-none  "
          onClick={() => handleToggle(false)}
        >
          <span className="font-dana font-bold  text-[10px] text-gray_4">
            {disableText}
          </span>
        </div>
        <div
          className="flex h-full w-full cursor-pointer select-none items-center justify-center *:select-none "
          onClick={() => handleToggle(true)}
        >
          <span className="font-dana font-bold  text-[10px] text-gray_4">
            {enableText}
          </span>
        </div>
      </div>

      <div
        className={clsx(
          'shadow-toggle-button absolute top-0 flex h-full w-1/2 select-none items-center justify-center   rounded-[10px] transition-all duration-150 ease-linear *:select-none ',
          status
            ? `left-0  bg-white`
            : `left-1/2  bg-primary`,
        )}
        style={{ transition: 'all 0.15s ease-linear' }}
      >
        <span className="font-dana font-bold  text-[10px] text-black">
          {status  ? 'خصوصی' : 'عمومی'}
        </span>
      </div>
    </div>
  );
};
