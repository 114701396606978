import React, { useEffect, useRef, useState } from 'react';
import ThreeDotSvg from '../svg/common/ThreeDotSvg';
import {
  getPlayerMusic,
  getPlayerTimer,
  getSwipePlayerStatus,
  globalData,
} from '../../helper/ReduxSelectorContext';
import LyricContainer from './LyricContainer';
import HeartSvg from '../svg/common/HeartSvg';
import RenderDownloadStatus from '../download/RenderDownloadStatus';
import { useDownload } from '../../hooks/useDownload';
import SwipeBottomSheet from '../bottomSheets/SwipeBottomSheet';
import TimerBottomSheet from './TimerBottomSheet.jsx';
import EnableSleepTimerSvg from '../svg/player/EnableSleepTimerSvg.jsx';
import useCountdown from '../../hooks/useCountdown.jsx';
import PlayerOptionBottomSheet from './PlayerOptionBottomSheet.jsx';
import { IconButton } from '@mui/material';
import LyricDemoBox from './avertiseds/LyricDemoBox';
import { useInView } from 'react-intersection-observer';
import { useQueryClient } from '@tanstack/react-query';
import { useNativeBack } from '../../hooks/useNativeBack.js';
import { mainToast } from '../../helper/ToastsEmitter';
import QueueSvg from '../svg/common/QueueSvg.jsx';
import { useDispatch } from 'react-redux';
import { toggleQueuePage } from '../../redux/player/PlayMusicSlice.js';

function TrackActionsBottom({ type, start, clear }) {
  const [openTimerBottomSheet, setOpenTimerBottomSheet] = useState(false);
  const [openPlayerOptionBottomSheet, setOpenPlayerOptionBottomSheet] =
    useState(false);

  let playedTrack = getPlayerMusic();
  const dispatch = useDispatch();
  const playerSwipeStatus = getSwipePlayerStatus();
  const { sleepTimerEnable, sleepTimerTime } = getPlayerTimer();
  const queryClient = useQueryClient();
  const formatTime = timeInSeconds => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;

    return {
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0'),
    };
  };
  const [
    startDownloadTrack,
    isDownloaded,
    inQueue,
    inQueueItem,
    downloadedItem,
  ] = useDownload(playedTrack);
  const containerRef = useRef(null);
  const titleRef = useRef();
  const global = globalData();
  const openModalList = global?.openModalList;
  const lyricGradient = {
    '--gradient': `linear-gradient(180deg,${playedTrack?.background_color} ${
      type === 'FULL_SCREEN' ? '10%' : '20%'
    } ,rgba(51,51,51,0))`,
  };

  const goToLyric = () => {
    if (playedTrack?.lyric?.has_lyric)
      titleRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    else mainToast('این آهنگ متن ندارد');
  };
  const handleDownloadTrack = event => {
    event.stopPropagation();
    startDownloadTrack();
  };
  const stopPropagation = e => {
    e.stopPropagation();
    e.preventDefault();
  };
  const toggleDrawerSleepTimerBottomSheet = newOpen => () => {
    setOpenTimerBottomSheet(newOpen);
  };
  const toggleSleepBottomSheet = () => {
    setOpenTimerBottomSheet(prevState => !prevState);
  };
  const toggleDrawerPlayerOptionBottomSheet = newOpen => () => {
    setOpenPlayerOptionBottomSheet(newOpen);
  };
  const togglePlayerOptionBottomSheet = () => {
    setOpenPlayerOptionBottomSheet(prevState => !prevState);
  };
  const handleCloseSheet = () => {
    setOpenPlayerOptionBottomSheet(false);
  };
  const closeTimerBottomSheet = () => {
    setOpenTimerBottomSheet(false);
  };
  useNativeBack(
    openPlayerOptionBottomSheet,
    'player-options',
    handleCloseSheet,
  );
  useNativeBack(openTimerBottomSheet, 'timer-sheet', closeTimerBottomSheet);
  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView && playedTrack?.lyricData == null) {
      queryClient.fetchQuery(['getTrackLyricQuery']);
    }
  }, [inView, playedTrack?.cdn_url]);

  const openQueuePage = () => {
    dispatch(toggleQueuePage());
  };

  return (
    <div
      ref={containerRef}
      className={`w-full text-white flex flex-col justify-center items-center   overflow-hidden ${
        type === 'FULL_SCREEN' ? 'h-full' : 'full-screen-lyrics'
      }`}
    >
      <SwipeBottomSheet
        keepMount={true}
        open={openTimerBottomSheet && playerSwipeStatus}
        toggleDrawer={toggleDrawerSleepTimerBottomSheet}
      >
        <TimerBottomSheet
          closeBottomSheet={toggleSleepBottomSheet}
          start={start}
          clear={clear}
        />
      </SwipeBottomSheet>
      <SwipeBottomSheet
        open={openPlayerOptionBottomSheet && playerSwipeStatus}
        toggleDrawer={toggleDrawerPlayerOptionBottomSheet}
      >
        <PlayerOptionBottomSheet
          closeBottomSheet={togglePlayerOptionBottomSheet}
        />
      </SwipeBottomSheet>
      <div className="grid items-center justify-center   grid-cols-6 w-full  z-20 pointer-events-none ">
        {/* <div className="flex flex-row gap-5 items-center"> */}
        {/* Download */}
        <div
          onClick={stopPropagation}
          className=" flex flex-row justify-center items-center pointer-events-auto "
        >
          <div className=" cursor-pointer w-full flex justify-center">
            <RenderDownloadStatus
              fill={'#BDBDBD'}
              item={playedTrack}
              isDownloaded={isDownloaded}
              startDownloadTrack={handleDownloadTrack}
              inQueue={inQueue}
              downloadedItem={downloadedItem}
              inQueueItem={inQueueItem}
              iconParentStyle={`!w-full !h-full flex pt-6  items-center justify-center ${
                playedTrack?.lyric?.has_lyric ? 'pb-1' : 'pb-5'
              } `}
            />
          </div>
        </div>
        {/* clock */}
        {sleepTimerEnable ? (
          <div className="pointer-events-auto">
            <div
              onClick={toggleSleepBottomSheet}
              className={`w-full flex justify-center items-end relative cursor-pointer  pt-6  ${
                playedTrack?.lyric?.has_lyric ? 'pb-1' : 'pb-5'
              }`}
            >
              <EnableSleepTimerSvg className="min-w-[21px] min-h-[21px]" />
              <div
                className={`font-dana text-primary flex text-[10px] font-semibold absolute right-[-7px]  ${
                  playedTrack?.lyric?.has_lyric ? 'bottom-0' : 'bottom-4'
                }`}
              >
                <span>{formatTime(sleepTimerTime)?.minutes}</span>
                <span> : </span>
                <span className="w-[18px]">
                  {formatTime(sleepTimerTime)?.seconds}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="pointer-events-auto">
            <div
              onClick={toggleSleepBottomSheet}
              className={`w-full flex justify-center  cursor-pointer  pt-6  ${
                playedTrack?.lyric?.has_lyric ? 'pb-1' : 'pb-5'
              }`}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_257_4742)">
                  <path
                    d="M18.3524 2.69491L20.7919 4.72929C20.9599 4.8694 21.1639 4.93777 21.3668 4.93777C21.6242 4.93777 21.8796 4.82781 22.0573 4.61474C22.3751 4.23367 22.3238 3.66711 21.9427 3.34934L19.5032 1.31491C19.1222 0.997137 18.5556 1.04839 18.2377 1.42951C17.92 1.81058 17.9713 2.37709 18.3524 2.69491Z"
                    fill="#BDBDBD"
                  />
                  <path
                    d="M3.13335 4.9377C3.33622 4.9377 3.5403 4.86933 3.70826 4.72922L6.14774 2.69488C6.52881 2.37711 6.58016 1.81055 6.26238 1.42948C5.94465 1.04845 5.37805 0.997108 4.99698 1.31489L2.55745 3.34926C2.17638 3.66704 2.12503 4.23359 2.44281 4.61467C2.62057 4.82773 2.87599 4.9377 3.13335 4.9377Z"
                    fill="#BDBDBD"
                  />
                  <path
                    d="M19.7464 5.40201C17.9574 3.61295 15.6413 2.54278 13.1484 2.33492V1.39844C13.1484 0.902231 12.7462 0.5 12.25 0.5C11.7538 0.5 11.3516 0.902231 11.3516 1.39844V2.33492C8.85867 2.54278 6.54263 3.61291 4.75357 5.40196C2.75118 7.40436 1.64844 10.0667 1.64844 12.8984C1.64844 15.7302 2.75118 18.3925 4.75357 20.3949C6.75592 22.3973 9.41821 23.5 12.25 23.5C14.53 23.5 16.7026 22.7871 18.533 21.4384C18.9324 21.144 19.0176 20.5816 18.7233 20.1821C18.4289 19.7826 17.8664 19.6975 17.467 19.9918C15.9477 21.1114 14.1436 21.7031 12.25 21.7031C7.39507 21.7031 3.44531 17.7534 3.44531 12.8984C3.44531 8.04351 7.39507 4.09375 12.25 4.09375C17.1049 4.09375 21.0547 8.04351 21.0547 12.8984C21.0547 14.3813 20.6794 15.847 19.9693 17.1371C19.7301 17.5718 19.8885 18.1181 20.3232 18.3574C20.7578 18.5966 21.3042 18.4382 21.5435 18.0035C22.3992 16.4487 22.8516 14.6834 22.8516 12.8984C22.8516 10.0667 21.7488 7.40436 19.7464 5.40201Z"
                    fill="#BDBDBD"
                  />
                  <path
                    d="M16.6269 7.4901C16.2413 7.17803 15.6756 7.23755 15.3634 7.62329L12.5305 11.1238C12.4391 11.1094 12.3454 11.1018 12.25 11.1018C11.2592 11.1018 10.4531 11.9079 10.4531 12.8986C10.4531 13.8894 11.2592 14.6955 12.25 14.6955C13.2408 14.6955 14.0469 13.8894 14.0469 12.8986C14.0469 12.6717 14.0042 12.4546 13.927 12.2546L16.7601 8.75366C17.0723 8.36792 17.0127 7.80217 16.6269 7.4901Z"
                    fill="#BDBDBD"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_257_4742">
                    <rect
                      width="23"
                      height="23"
                      fill="white"
                      transform="translate(0.75 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        )}
        {/* </div> */}

        {/* lyric */}
        <div className="col-span-2 w-full justify-center items-center flex flex-col   relative">
          <div
            onClick={() => goToLyric()}
            className="flex flex-col justify-center  items-center absolute pointer-events-auto z-20"
          >
            <p
              ref={titleRef}
              className={`font-kalameh  font-[700] text-[14px] ${
                playedTrack?.lyric?.has_lyric ? 'text-white' : 'text-gray_2'
              } `}
            >
              متن
            </p>
          </div>
          <p
            className={`font-kalameh absolute top-2 z-30 font-[500] text-white opacity-50 text-[14px] ${
              playedTrack?.lyric?.type_title ? 'visible' : 'invisible'
            }`}
          >
            {playedTrack?.lyric?.type_title}
          </p>
        </div>
        <div
          onClick={openQueuePage}
          className={`w-full flex justify-center pointer-events-auto pt-6  ${
            playedTrack?.lyric?.has_lyric ? 'pb-1' : 'pb-5'
          }`}
        >
          <QueueSvg />
        </div>

        <div
          onClick={togglePlayerOptionBottomSheet}
          className={`flex items-center relative pointer-events-auto  justify-end cursor-pointer pr-4 pt-6  ${
            playedTrack?.lyric?.has_lyric ? 'pb-1' : 'pb-5'
          }`}
        >
          <div className=" px-3  h-[23px]  " style={{ aspectRatio: 4 / 23 }}>
            <ThreeDotSvg class=" " fill={'#BDBDBD'} />
          </div>
        </div>

        {/* </div> */}
      </div>

      {/* lyric absolute SVG */}
      {playedTrack?.lyric?.has_lyric && (
        <div
          ref={ref}
          style={{ backgroundColor: playedTrack?.background_color }}
          className={`${
            type === 'FULL_SCREEN'
              ? 'h-full  rounded-t-[20px]'
              : ` ${
                  playedTrack?.is_demo ? 'h-[325px]' : 'h-[250px]'
                }  rounded-[20px]`
          } w-full overflow-hidden mt-[3px] `}
        >
          <div
            onClick={() => goToLyric()}
            className=" absolute left-[50%] translate-x-[-50%] top-3 xl:top-[.8543vw]  "
          >
            <svg
              width="141"
              height="43"
              viewBox="0 0 141 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 43H140.542C127.318 43 115.854 34.054 108.483 23.0744C100.931 11.8261 88.6263 0 70.2712 0C51.9162 0 39.6118 11.8261 32.0598 23.0744C24.6884 34.054 13.2246 43 0 43Z"
                fill={playedTrack?.background_color}
              ></path>
            </svg>
          </div>
          <div
            className={`relative ${
              playedTrack?.is_demo && type !== 'FULL_SCREEN'
                ? 'h-[250px]'
                : 'h-full'
            } `}
          >
            <LyricContainer lyricGradient={lyricGradient} />
          </div>
          {playedTrack?.is_demo && (
            <LyricDemoBox fullScreenLyric={type === 'FULL_SCREEN'} />
          )}
        </div>
      )}
    </div>
  );
}

export default React.memo(TrackActionsBottom);
