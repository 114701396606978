import React from 'react'
import clsx from 'clsx';

export const MeloPostListenCount = ({ play_count,iconClass='h-[10px] w-[10px]', textClass = 'text-[10px] fa-format-500' }) => {
  return (
    <div dir="rtl" className=" flex flex-row gap-1 items-center">
      <span className={clsx(`text-gray_3`,textClass)}>
        {play_count}
      </span>
      <span>
        <svg
          className={iconClass}
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2113_112844)">
            <path
              d="M5 0.421875C2.24299 0.421875 0 2.60922 0 5.29786C0 5.57669 0 7.313 0 7.59244C0 8.0669 0.394277 8.45291 0.878906 8.45291H1.22227C1.3432 8.78675 1.66885 9.02655 2.05078 9.02655H2.63672C2.79852 9.02655 2.92969 8.89813 2.92969 8.73973V5.29786C2.92969 5.13946 2.79852 5.01104 2.63672 5.01104H2.05078C1.66885 5.01104 1.3432 5.25084 1.22227 5.58468H0.878906C0.776191 5.58468 0.677637 5.60218 0.585938 5.63401V5.29786C0.585938 2.92553 2.56607 0.99552 5 0.99552C7.43393 0.99552 9.41406 2.92553 9.41406 5.29786V5.63401C9.32236 5.60218 9.22381 5.58468 9.12109 5.58468H8.77773C8.6568 5.25084 8.33115 5.01104 7.94922 5.01104H7.36328C7.20148 5.01104 7.07031 5.13946 7.07031 5.29786V8.73973C7.07031 8.89813 7.20148 9.02655 7.36328 9.02655H7.94922C8.33115 9.02655 8.6568 8.78675 8.77773 8.45291H9.12109C9.60572 8.45291 10 8.0669 10 7.59244C10 7.31361 10 5.5773 10 5.29786C10 2.60924 7.75701 0.421875 5 0.421875ZM1.75781 5.8715C1.75781 5.71335 1.88924 5.58468 2.05078 5.58468H2.34375V8.45291H2.05078C1.88924 8.45291 1.75781 8.32424 1.75781 8.16608V5.8715ZM0.878906 6.15833H1.17188V7.87926H0.878906C0.717363 7.87926 0.585938 7.75059 0.585938 7.59244V6.44515C0.585938 6.28699 0.717363 6.15833 0.878906 6.15833ZM8.24219 8.16608C8.24219 8.32424 8.11076 8.45291 7.94922 8.45291H7.65625V5.58468H7.94922C8.11076 5.58468 8.24219 5.71335 8.24219 5.8715V8.16608ZM9.41406 7.59244C9.41406 7.75059 9.28264 7.87926 9.12109 7.87926H8.82812V6.15833H9.12109C9.28264 6.15833 9.41406 6.28699 9.41406 6.44515V7.59244Z"
              fill="#828282"
            />
          </g>
          <defs>
            <clipPath id="clip0_2113_112844">
              <rect width="10" height="9.09091" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </span>
    </div>
  );
};

