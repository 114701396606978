import React from 'react';
import HashtagSearchNotFoundSvg from '../../svg/common/HashtagSearchNotFoundSvg.jsx';

export const  NotFoundHashtags = (props) => {
  return (
    <div className='flex flex-col gap-6 items-center w-full'>
         <HashtagSearchNotFoundSvg/>
         <p className='fa-format-500 text-[18px] text-gray_3'>
           هشتگ موردنظر یافت نشد!
         </p>
    </div>
  );
}

