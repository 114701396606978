import {
  osVersion,
  osName,
  deviceType,
  mobileModel,
  browserName,
} from "react-device-detect";
import sha1 from "js-sha1";
import { getDeviceTokenFromGlobalStore } from "./redux/globalstore/GlobalStore";
export const APP_VERSION = "9.0.2";
export const APP_VERSION_NAME = "9.0.5";
export const DEVICE_ID = sha1(osName + "osVersion" + browserName);
export const DEVICE_NAME = osName + " " + osVersion + " (" + browserName + ")";
export const BASE_URL = "https://melodify.pw/api/pwa/v9";
// export const BASE_URL="https://dev.melodify.pw/api/pwa/v9"
// export const BASE_URL = "https://melodify.pw/api/pwa/v8";
export const os = osName;
// export const IMAGE_BASE_URL = "";
export const CDN_BASE_URL = "";
export const IMAGE_BASE_URL= "https://img.melodify.pw/"
// export const IMAGE_BASE_URL = "";
// export const CDN_BASE_URL = "https://cdn.melodify.pw/";
export const UPKEEP_DEFAULT_BASE_URL = "https://upkeep.melodify.pw/upkeep";
export const USER_DEVICE_INFO = `screen_w:${window?.innerWidth}-screen_w:${window?.innerHeight}  PWA_REACT`;

// export const IMAGE_BASE_URL= ""
// export const CDN_BASE_URL= ""
