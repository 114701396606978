import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import { LOOP_STATUS } from '../../consts/PlayListType';
import { IsIosDevice } from '../../helper/CheckDevice';
import {
  getHaveAdvertise,
  getIsPlaying,
  getMute,
  getPlayList,
  getPlayerLoop,
  getPlayerMusic,
  getTrackDuration,
  getUserToken,
  getVolume,
  useUserData,
  getPlayListStatic,
} from '../../helper/ReduxSelectorContext';
import { createMusicCdnLink } from '../../helper/createMusicCdnLink';
import { useAdvertise } from '../../hooks/useAdvertise';
import { useTimer } from '../../hooks/useTimer.js';
import { db } from '../../indexDB/DB';
import {
  changeTrackQuality,
  emitTrackEnd,
  resetPlayer,
  selectSeekTime,
  setSeekTime,
  setTrackDuration,
} from '../../redux/player/PlayMusicSlice';
import { listenTrack } from '../../services/ApiClient.js';
import PlayerController from './PlayerController';
import PlayerSeekBar from './PlayerSeekBar';
import { setEmtyAudioStatus } from '../../redux/player/PlayerSlice';
import { useSelector } from 'react-redux';
import { usePlayerPagination } from '../../hooks/usePlayerPagination.js';
import { playerPaginationKey } from '../../consts/PlayerPaginationKey.js';
import { CheckVideoPermision } from '../../helper/CheckVideoPermision.jsx';
import QueuePageDrawer from '../lyrics/QueuePageDrawer.jsx';

function ReactPlayerComponent() {
  const {
    startTimer,
    stopTimer,
    resetTimer,
    addTime,
    elapsedTime,
    isRunning: timerRunning,
  } = useTimer();
  const [listenRequestSent, setListenRequestSent] = useState(false);
  let playedMusic = getPlayerMusic();
  let playerVolume = getVolume();
  let isMute = getMute();
  let isPlaying = getIsPlaying();
  let loop = getPlayerLoop();
  let userData = useUserData();
  let userToken = getUserToken();
  let playList = getPlayList();
  let playListStatic = getPlayListStatic();
  let seekTime = useSelector(selectSeekTime);
  let havAdvertise = getHaveAdvertise();
  const trackDutation = getTrackDuration();
  const IsCanUseVideoForplay = CheckVideoPermision();
  const dispatch = useDispatch();
  let DurationTimeOut = null;
  const [AdvCountWeight, checkWeighCount, checkTrackAdvertises] =
    useAdvertise();
  const {
    playerPaginationLoader,
    enablePaginationLoader,
    playerPaginationEnd,
  } = usePlayerPagination(playerPaginationKey?.PLAY_PAGINATION);

  const EmtyAudioResource = document.getElementById('noPlayerReff');

  const playerRef = useRef(null);
  const listenRequestQuery = {
    track_id: playedMusic?.id,
    is_demo: playedMusic?.is_demo,
  };
  const listenTrackMutation = useMutation(() =>
    listenTrack(listenRequestQuery),
  );

  const handleDuration = duration => {
    if (playedMusic) dispatch(setTrackDuration({ duration: duration }));
  };

  const handleProgress = progress => {
    dispatch(setSeekTime({ seekTime: progress?.playedSeconds }));
    if (!timerRunning && isPlaying && !listenRequestSent && !havAdvertise) {
      startTimer();
    }
  };
  useEffect(() => {
    if (havAdvertise) return;
    if (playedMusic?.cdn_url) handleAdvertise();
    if (playerRef.current != null) {
      dispatch(setSeekTime({ seekTime: 0 }));
      playerRef?.current?.player?.seekTo(0);
      // DurationTimeOut = setTimeout(() => {
      // }, 50);
      // dispatch(setTrackDuration({ duration: 0 }));
    }
    return () => {
      clearTimeout(DurationTimeOut);
    };
  }, [playedMusic?.cdn_url]);

  const handleOnReadyPlay = player => {
    console.log('On Ready Player', player);
    if (EmtyAudioResource) EmtyAudioResource.src = null;

    dispatch(setEmtyAudioStatus({ status: false }));
    //  if (isPlaying)   if (isPlaying)
    if (playedMusic && isPlaying) playerRef?.current?.player?.player.play();

    // loadPlayListPart();
  };

  const handleEndTrack = () => {
    if (!havAdvertise) {
      dispatch(emitTrackEnd());
    }
  };

  useEffect(() => {
    const asyncSetCdnUrl = async () => {
      if (playedMusic?.isDownloaded) {
        let cdn_url = await handleDownloadedPlay(playedMusic);
        console.log('DSfsdfdsfdsffffff' + cdn_url);
        dispatch(changeTrackQuality({ cdn_url }));
      }
    };
    asyncSetCdnUrl();
  }, []);

  const handleDownloadedPlay = async item => {
    // getDownloadedFile(item?.id).then((response)  => URL.createObjectURL(response?.blob))
    const data = await db?.downloadedFiles?.get(item?.id);
    console.log(data, 'data received');
    if (data?.blob) return URL.createObjectURL(data?.blob);
    else return createMusicCdnLink(item, userData, userToken);
  };

  const handleError = e => {
    console.log('OnError Player ', e);
    stopTimer();
  };

  const handleAdvertise = () => {
    if (checkWeighCount()) {
    } else {
      if (!userData?.is_premium) {
        AdvCountWeight();
      }
      if (checkIsDownloadedFile(playedMusic?.cdn_url)) return;
      checkTrackAdvertises();
    }
  };

  const checkIsDownloadedFile = url => {
    const regex = /^blob:/i;
    const hasBlobScheme = regex.test(url);
    return hasBlobScheme;
  };
  //handle listen request...
  useEffect(() => {
    console.log(elapsedTime, 'elapsed time');
    if (elapsedTime >= 15) {
      listenTrackMutation.mutate();
      setListenRequestSent(true);
      resetTimer();
      stopTimer();
    }
  }, [elapsedTime]);
  useEffect(() => {
    console.log('clear  timer...elapsed time');
    resetTimer();
    stopTimer();
    setListenRequestSent(false);
  }, [playedMusic?.cdn_url]);
  useEffect(() => {
    if (listenRequestSent) return;
    if (!isPlaying) {
      stopTimer();
    }
  }, [isPlaying]);
  useEffect(() => {
    let index = playListStatic?.findIndex(item => item?.id === playedMusic?.id);
    if (
      index > 0 &&
      index >= playList?.length - 5 &&
      !playerPaginationLoader &&
      !playerPaginationEnd
    ) {
      enablePaginationLoader();
    }
  }, [playedMusic?.index, playList]);
  const clearPlayer = () => {
    dispatch(resetPlayer());
  };

  // const loadPlayListPart = async () => {
  //   let currentTrackIndex = playList.findIndex(
  //     (track) => track?.id === playedMusic?.id
  //   );

  //   const loadTrack = async (index) => {
  //     if (index >= playList.length) {
  //       return; // Stop recursion when all tracks have been loaded
  //     }

  //     // stop when load 3 track
  //     if (currentTrackIndex + 5 <= index) {
  //       return;
  //     }
  //     console.log("dsgsdgsdgasdfsdgsdg", index);
  //     const element = playList[index];
  //     const nextTrackUrl = createMusicCdnLink(element, userData, userToken);
  //     // await hls.loadSource(nextTrackUrl+"&loadedNexttrack=true");
  //     await hls.loadSource(nextTrackUrl);
  //     console.log("Loaded track:", nextTrackUrl);

  //     // Wait for a short delay before loading the next track (adjust as needed)
  //     await new Promise((resolve) => setTimeout(resolve, 2000));

  //     await loadTrack(index + 1); // Recursively load the next track
  //   };

  //   await loadTrack(currentTrackIndex + 1); // Start loading from the next track
  // };
  // useEffect(() => {
  //  if(playerRef){
  //   playerRef.current.src=playedMusic?playedMusic?.cdn_url:"/xx.mp3"
  //   playedMusic?"":
  //   playerRef.current.play()
  //  }
  // }, [playerRef])

  return (
    <div dir="ltr" class="w-full flex flex-col justify-center items-center">
      {/* <!-- seekbar --> */}
      <PlayerSeekBar
        duration={trackDutation}
        playerRef={playerRef}
        seekTime={seekTime}
      />

      <ReactPlayer
        id="playerDDD"
        ref={playerRef}
        url={'/empty.mp3'}
        style={{ display: 'none' }}
        onProgress={e => handleProgress(e)}
        onPause={() => console.log('On Pause player Event')}
        controls={true}
        onReady={e => {
          handleDuration(e?.getDuration());
          handleOnReadyPlay(e);
        }}
        onDuration={e => handleDuration(e)}
        //  volume={0.1}
        //  muted={true}

        // you neeeed delete this
        // muted={true}
        //  onError={handleError}
        //  onEnded={() => handleEndTrack()}
        loop={
          havAdvertise
            ? false
            : playedMusic
            ? loop === LOOP_STATUS.SINGLE
            : true
        }
        playsinline={true}
        preload="auto"
        onError={handleError}
        onEnded={() => handleEndTrack()}
        config={{
          uiWebview: {
            allowsInlineMediaPlayback: true,
            mediaPlaybackRequiresUserAction: false,
            backgroundMode: true,
          },
          attributes: {
            playsinline: true,
            playsInline: true,
            'webkit-playsinline': true,
          },
          file: {
            forceVideo: IsCanUseVideoForplay(),
            // hlsConfig,
            // forceHLS: isForceHls,
            // hlsOptions: {
            //   preload: 'auto',
            //   maxBufferLength: 60,
            // },
            // forceHLS: !IsIosDevice(),
          },
        }}
      />

      {/* <audio
      id="mainPlayer"
        // key={playedMusic?.cdn_url}
        // ref={playerRef}
        // style={{ display: "none" }}
        // url={playedMusic?.cdn_url}
        src={"/xx.mp3"}
        // url={createMusicCdnLink(playedMusic,userData,token)}
        // onProgress={(e) => handleProgress(e)}
        // onPause={() => console.log("Dgsdgsdgsdgsdgsdgsdg")}
        controls={true}
        // autoPlay
        // onReady={() => handleOnReadyPlay()}
        // onDuration={(e) => handleDuration(e)}
        // volume={isMute ? 0 : playerVolume / 100}
        // volume={playedMusic?.cdn_url?1:0}
        // volume={1}
        // muted={isMute}
        // muted={true}
        // muted={true}
        // muted={playedMusic?.cdn_url ? false : true}
        // playing
        // onError={handleError}
        // onEnded={() => handleEndTrack()}
        // autoPlay
        loop={true}
        playsinline={true}
        preload="auto"
        // config={{
        //   uiWebview: {
        //     allowsInlineMediaPlayback: true,
        //     mediaPlaybackRequiresUserAction: false,
        //     backgroundMode: true,
        //   },
        //   attributes: {
        //     playsinline: true,
        //     playsInline: true,
        //     "webkit-playsinline": true,
        //   },
        //   file: {
        //     // hlsConfig,
        //     // forceHLS: isForceHls,
        //     // hlsOptions: {
        //     //   preload: 'auto',
        //     //   maxBufferLength: 60,
        //     // },

        //     // forceHLS: !IsIosDevice(),
        //   },
        // }}
      ></audio> */}

      {/* <!-- player controller --> */}
      <PlayerController playerRef={playerRef} />

      {/* Emty Player */}
      {
        <audio
          id="noPlayerReff"
          src="/empty.mp3"
          autoPlay="true"
          playsInline="true"
          loop
          volume="1"
          // onPause={console.log("onPause")}
          // onPlay={console.log("onPlay")}
        ></audio>
      }

      <QueuePageDrawer
        playerRef={playerRef}
        
      />
    </div>

    // {/* <audio src="@/assets/empty.mp3" :volume="0.1" onplay="console.log('empty is playing')" onpause="console.log('empty is paused')"
    // ref="emptyAudio" style="display:none;" loop v-if="$device.isIOS">

    // </audio> */}
    // <audio src="@/assets/empty.mp3"
    // ref="playingElement"
    // :loop="repeatOne"
    // :autoplay="$device.isIOS || $device.isOsx">
    // </audio>
  );
}

export default ReactPlayerComponent;
