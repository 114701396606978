import React from "react";
import Container from "../../components/PageParentContainer/Container";
import CommonHeader from "../../components/common/CommonHeader";
import TelegramSvg from "../../components/svg/setting/TelegramSvg";
import { CardActionArea } from "@mui/material";
import { useTelegram } from "../../hooks/useTelegram.jsx";
import { selectInitialData } from "../../redux/initialize/InitializeSlice.js";
import { userData } from "../../redux/login/LoginSlice.js";
import { useSelector } from "react-redux";
import { useUserData } from "../../helper/ReduxSelectorContext.js";
import Loading from "../../components/loading/Loading.jsx";

function ConnectToTelegram() {
  const { telegram_data } = useSelector(selectInitialData)||{};
  const { streaming_quality } = useUserData() || {};
  const telegramRequestData = {
    track_id: null,
    quality: streaming_quality,
  };
  const { connectToTelegram, ChangeTelegramAccount, connectToTelegramLoading } =
    useTelegram();
  return (
    <Container
      style="bg-primary_dark"
      header={<CommonHeader title="اتصال به تلگرام من" />}
    >
      <div dir="rtl" className="w-full px-4 mt-4">
        <div className="w-full   bg-secondary rounded-[20px]  px-4 pt-6 pb-6 flex flex-col items-center justify-center   ">
          <div id="logo">
            <TelegramSvg className=" h-8 w-8 mt-4" />
          </div>
          <div
            id="title"
            className="font-dana text-white text-sm font-semibold mt-8"
          >
            <p>
              اکانت تلگرام خود را به ملودیفای متصل کنید و آهنگ‌ها را در تلگرام
              دریافت کنید.
            </p>
          </div>
          {!telegram_data?.has_connected && (
            <div
              id="sub-title"
              className="font-dana font-normal text-xs text-gray_5 mt-[12px] leading-7  "
            >
              <p> ۱- vpn خود را روشن کنید.</p>
              <p>
                {" "}
                ۲- روی لینک زیر بزنید و ربات ملودیفای را به اکانت خود متصل کنید.
              </p>
            </div>
          )}
          {telegram_data?.has_connected ? (
            <div className="flex flex-col justify-between w-full !mt-4 gap-5">
              <div className="flex justify-between w-full">
                <p className="font-dana text-white text-sm font-semibold ">
                  اکانت متصل:
                </p>
                <p className="font-poppins text-[#169de2] text-[16px] font-medium ">
                  {telegram_data?.connected_title}
                </p>
              </div>
              <CardActionArea className="!rounded-3xl " id="button">
                <button
                  onClick={() => ChangeTelegramAccount(telegramRequestData)}
                  type="submit"
                  className=" text-title_box text-base font-medium w-full  h-[46px]  flex items-center justify-center rounded-3xl font-dana  bg-primary "
                >
                  {connectToTelegramLoading ? (
                    <Loading bgColor="#333333" />
                  ) : (
                    "تغییر اکانت"
                  )}
                </button>
              </CardActionArea>
            </div>
          ) : (
            <CardActionArea className="!rounded-3xl  !mt-4" id="button">
              <button
                onClick={() => connectToTelegram(telegramRequestData)}
                type="submit"
                className=" text-title_box text-base font-medium w-full  h-[46px]  flex items-center justify-center rounded-3xl font-dana  bg-primary "
              >
                {connectToTelegramLoading ? (
                  <Loading bgColor="#333333" />
                ) : (
                  "اتصال به تلگرام"
                )}
              </button>
            </CardActionArea>
          )}
        </div>
      </div>
    </Container>
  );
}

export default ConnectToTelegram;
