import React, { useEffect, useRef, useState } from 'react';
import {
  getInitialData,
  getPlayerMusic,
} from '../../helper/ReduxSelectorContext';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';

import { useDownload } from '../../hooks/useDownload.js';
import { useAction } from '../../hooks/useAction';
import TextMarquee from '../common/TextMarquee.jsx';
import { setLanguageDirection } from '../../helper/LanguageDetection.js';
import { useDispatch } from 'react-redux';
import { setSwipePlayerStatus } from '../../redux/player/PlayMusicSlice';
import { CardActionArea } from '@mui/material';
import ClickReaction from '../../helper/ClickReaction';
import { useLocation, useNavigate } from 'react-router-dom';
import GoToArtistBottomSheet from './GoToArtistBottomSheet.jsx';
import { textFontBasedLanguage } from '../../helper/TextFontBasedLanguage.js';
import RenderShare from '../common/RenderShare.jsx';
import { shareTypes } from '../../consts/shareTypes.js';
import ShareAnimation from '../common/ShareAnimation.jsx';
import HeartSvg from '../svg/common/HeartSvg.jsx';

function MusicDetail() {
  const [isLiked, setIsLiked] = useState(false);
  const goToArtistBottomSheetRef = useRef(null);
  const { setting } = getInitialData() || {};
  const initialData = getInitialData();
  const action = useAction();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let playedTrackData = getPlayerMusic();
  const handleDownloadTrack = event => {
    event.stopPropagation();
    startDownloadTrack();
  };
  const [
    startDownloadTrack,
    isDownloaded,
    inQueue,
    inQueueItem,
    downloadedItem,
  ] = useDownload(playedTrackData);

  function handleLikeMusic() {
    setIsLiked(!isLiked);
  }
  const openGoToArtistBottomSheet = () => {
    goToArtistBottomSheetRef?.current?.openGoToArtistBottomSheet();
  };
  // const handleGoToArtist = (artist) => {
  //   const artistObject = {
  //     target_type: "artist",
  //     artist: artist,
  //   };
  //   action(artistObject);
  // };
  const openPlans = item => {
    dispatch(setSwipePlayerStatus({ status: false }));
    if (location?.pathname !== '/plans')
      navigate('/plans', { state: { openPlayerWhenBack: true } });
  };

  return (
    <>
      <GoToArtistBottomSheet
        ref={goToArtistBottomSheetRef}
        data={playedTrackData}
      />
      <div className="  short-screen: flex flex-col items-center select-none w-full mb-6">
        <div
          key={playedTrackData?.image_large}
          className=" relative shadow-playlist-shadow  overflow-hidden rounded-[10px]  w-full h-full min-h-[300px] max-w-[300px] max-h-[300px] aspect-square"
        >
          <CustomLazyLoadImage
            image={playedTrackData?.image_large}
            loadStrategy={'lazy'}
          />

          {playedTrackData?.is_demo && (
            <div
              className="w-full  absolute  flex flex-col justify-start items-center z-30 pt-2"
              style={{ background: '#00000080' }}
            >
              <span className="text-white text-base font-kalameh font-[500]">
                {initialData?.demo_track_box?.title}
              </span>
              <div className="flex flex-col mt-2 gap-[2px]">
                {initialData?.demo_track_box?.subtitle
                  ?.split('\n')
                  ?.map(item => (
                    <span className="text-white text-xs font-dana font-[400] text-center">
                      {item}
                    </span>
                  ))}
              </div>

              <ClickReaction>
                <CardActionArea className="!w-[28vh] !h-[36px] !my-4  !rounded-[23px]">
                  <button
                    onClick={() => {
                      openPlans(playedTrackData);
                    }}
                    className="bg-primary w-full h-full rounded-[6px] text-black font-dana font-[500] text-[16px]  cursor-pointer "
                  >
                    خرید اشتراک
                  </button>
                </CardActionArea>
              </ClickReaction>
            </div>
          )}
        </div>

        <div className="w-full flex flex-row justify-between  items-center mt-[12vh]">
          <div className="flex w-full  flex-col gap-1  ">
            <div
              dir="rtl"
              className={`font-dana font-[700]   w-full  text-[20px]  text-white leading-6 overflow-hidden flex flex-row gap-2 items-center ${textFontBasedLanguage(
                playedTrackData?.title,
              )} `}
            >
              <span dir={'ltr'} className=" w-[64vw] max-w-[380px] ">
                <TextMarquee
                  noMarqueeParentStyle={'justify-end'}
                  direction={setLanguageDirection(playedTrackData?.title)}
                  key={playedTrackData?.id}
                >
                  {playedTrackData?.title}
                </TextMarquee>
              </span>
            </div>
            <div
              onClick={openGoToArtistBottomSheet}
              dir="rtl"
              className=" w-full flex flex-row justify-between items-center gap-2 "
            >
              <span
                dir={'ltr'}
                className="font-dana font-[500] w-[64vw] max-w-[350px]  text-[16px]    text-gray_4"
              >
                <TextMarquee
                  noMarqueeParentStyle={'justify-end'}
                  direction={setLanguageDirection(playedTrackData?.title)}
                  key={`${playedTrackData?.id} artist `}
                >
                  {playedTrackData?.artists?.map((artist, index) => {
                    return (
                      <span
                        className={`${textFontBasedLanguage(artist?.name)}`}
                        dir={setLanguageDirection(playedTrackData?.title)}
                        key={index}
                      >
                        {artist?.name}{' '}
                        {index !== playedTrackData?.artists?.length - 1 &&
                          (setLanguageDirection(playedTrackData?.title) ===
                          'rtl'
                            ? '، '
                            : ', ')}
                      </span>
                    );
                  })}
                </TextMarquee>
              </span>
            </div>
            {/* <!-- player --> */}
          </div>
          <div className="  h-full w-[100px] flex  justify-end gap-5 items-center">
            <HeartSvg height={'h-[23px]'} id={playedTrackData?.id} />

            {setting?.enable_track_share && (
              <div className="h-[40px] w-[40px] max-w-[40px] max-h-[40px] aspect-square">
                <RenderShare
                  type={shareTypes?.TRACK}
                  id={playedTrackData?.id}
                  shareLoadingStyle="w-[70%] h-[70%]"
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="20"
                      cy="20"
                      r="20"
                      fill="white"
                      fill-opacity="0.1"
                    />
                    <path
                      d="M25.4228 23.4529C24.4645 23.4529 23.6012 23.8667 23.002 24.525L17.6124 21.187C17.7563 20.8186 17.8361 20.4185 17.8361 20C17.8361 19.5813 17.7563 19.1812 17.6124 18.813L23.002 15.4748C23.6012 16.1331 24.4645 16.5471 25.4228 16.5471C27.2279 16.5471 28.6964 15.0786 28.6964 13.2735C28.6964 11.4684 27.2279 10 25.4228 10C23.6177 10 22.1492 11.4685 22.1492 13.2736C22.1492 13.6922 22.2291 14.0922 22.3729 14.4606L16.9835 17.7986C16.3843 17.1403 15.5209 16.7264 14.5627 16.7264C12.7576 16.7264 11.2891 18.195 11.2891 20C11.2891 21.8051 12.7576 23.2736 14.5627 23.2736C15.5209 23.2736 16.3843 22.8598 16.9835 22.2014L22.3729 25.5394C22.2291 25.9077 22.1492 26.3078 22.1492 26.7265C22.1492 28.5315 23.6177 30 25.4228 30C27.2279 30 28.6964 28.5315 28.6964 26.7265C28.6964 24.9214 27.2279 23.4529 25.4228 23.4529ZM23.3429 13.2736C23.3429 12.1268 24.2759 11.1937 25.4228 11.1937C26.5696 11.1937 27.5027 12.1268 27.5027 13.2736C27.5027 14.4205 26.5696 15.3535 25.4228 15.3535C24.2759 15.3535 23.3429 14.4205 23.3429 13.2736ZM14.5627 22.0799C13.4157 22.0799 12.4826 21.1468 12.4826 20C12.4826 18.8531 13.4157 17.9201 14.5627 17.9201C15.7095 17.9201 16.6424 18.8531 16.6424 20C16.6424 21.1468 15.7095 22.0799 14.5627 22.0799ZM23.3429 26.7263C23.3429 25.5795 24.2759 24.6464 25.4228 24.6464C26.5696 24.6464 27.5027 25.5795 27.5027 26.7263C27.5027 27.8732 26.5696 28.8063 25.4228 28.8063C24.2759 28.8063 23.3429 27.8732 23.3429 26.7263Z"
                      fill="white"
                    />
                  </svg>

                  {/* <ShareAnimation /> */}
                </RenderShare>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(MusicDetail);
