import * as React from "react";

function VisitCountIcon({ fill='#F2F2F2',...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={21}
      fill="none"
      viewBox="0 0 14 15"
      {...props}
    >
      <g clipPath="url(#clip0_2113_68702)">
        <path
          fill={fill}
          d="M13.92 7.265c-1.171-2.464-3.833-4.687-6.947-4.687C3.469 2.578.95 5.32.026 7.265a.55.55 0 0 0 0 .47c1.17 2.465 3.832 4.687 6.947 4.687 3.504 0 6.023-2.741 6.947-4.687a.55.55 0 0 0 0-.47m-6.947 4.063c-2.52 0-4.609-1.66-5.834-3.828 1.22-2.159 3.308-3.828 5.834-3.828 2.519 0 4.608 1.66 5.833 3.828-1.22 2.159-3.307 3.828-5.833 3.828m0-6.562A2.737 2.737 0 0 0 4.238 7.5a2.737 2.737 0 0 0 2.735 2.734A2.737 2.737 0 0 0 9.707 7.5a2.737 2.737 0 0 0-2.734-2.734m0 4.375c-.905 0-1.641-.736-1.641-1.641s.736-1.64 1.64-1.64c.905 0 1.641.735 1.641 1.64s-.736 1.64-1.64 1.64"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2113_68702">
          <path fill="#fff" d="M0 .5h14v14H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default VisitCountIcon;
