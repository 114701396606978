import React from 'react';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage.jsx';
import { setLanguageDirection } from '../../helper/LanguageDetection.js';

import { useAction } from "../../hooks/useAction.js";
import UserPlaylistContextMenu from "../playlist/UserPlaylistContextMenu.jsx";
import PlaceHolderWithTitle from "../common/PlaceHolderWithTitle.jsx";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addTrackToUserCollection } from "../../services/ApiClient.js";
import { addToPlaylistData } from "../../helper/ReduxSelectorContext.js";
import { useDispatch } from "react-redux";
import {
  disableListSelectMode,
  disablePlayerListSelectMode,
  toggleAddToPlaylistLoader,
} from "../../redux/adToPlaylist/AddToPlaylistSlice.js";
import { mainToast } from "../../helper/ToastsEmitter.jsx";
import { useLimitation } from "../../hooks/useLimitation.js";
import { UserPlaylistBottomSheetEditOptions } from '../userPlaylist/UserPlaylistBottomSheetEditOptions.jsx';
import PlaylistGrayLockIcon from '../svg/userPlaylist/PlaylistGrayLockIcon.jsx';

function UserPlaylistItem({
  collection,
  inBottomSheet,
  setLoading,
  closeSheet,
}) {
  const queryClient = useQueryClient();
  const action = useAction();
  const dispatch = useDispatch();
  const addToPlaylistDataState = addToPlaylistData();
  const { hasPlaylistCountLimit, hasPlayListTrackCountLimit } = useLimitation();
  const trackList =
    addToPlaylistDataState?.playerListSelectedItems?.length > 0
      ? addToPlaylistDataState?.playerListSelectedItems
      : addToPlaylistDataState?.listSelectItems;
  const tracksIds = [];
  trackList.map((track, index) => tracksIds.push(track?.id));

  const handleClickAction = (e) => {
    e?.stopPropagation()
    e?.preventDefault()
    if (inBottomSheet) {
      if (hasPlayListTrackCountLimit()) {
        mainToast(
          "شما کاربر عادی هستید برای افزودن ترک های بیشتر به یک پلی لیست ویژه شوید."
        );
        return;
      }
      dispatch(toggleAddToPlaylistLoader());
      addToCollectionMutation
        .mutateAsync({
          user_collection_id: collection?.id,
          added_tracks: tracksIds,
        })
        .then(() => {
          dispatch(toggleAddToPlaylistLoader());
        });
    } else {
      openUserPlaylist(e);
    }
  };
  const addToCollectionMutation = useMutation(
    (addToCollectionData) => addTrackToUserCollection(addToCollectionData),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["getUserCollectionQuery"]);
        closeSheet();
        if (data?.data?.done)
          mainToast(
            <div dir="rtl" className="text-center text-black_2">
              به
              <span className="!text-[#5081a0] mx-[2px]">
                {" "}
                {collection?.title}{" "}
              </span>
              در پلی‌لیست‌های من اضافه شد.
            </div>
          );
        else mainToast(data?.data?.error?.message);
      },
    }
  );

  const openUserPlaylist = (e) => {
    const actionData = {
      target_type: "open_user_playlist",
      playlistData: collection,
    };
    action(actionData);
  };

  return (
    <div
      dir='ltr'
      onClick={handleClickAction}
      className="w-full  flex justify-between   rounded-[5px] items-center cursor-pointer "
    >
      <div className="flex gap-2 w-[60%] max-w-[60%]">
        <div className="flex w-[72px] h-[72px] min-h-[72px] min-w-[70px] rounded-[5px] relative overflow-hidden">
          <span className="relative top-0 left-0 w-full !p-0 !m-0  flex justify-center items-center">
            <PlaceHolderWithTitle style={"!rounded-[5px]"} title={""} />
          </span>
            <CustomLazyLoadImage image={collection?.image} />
        </div>
        <div className="flex flex-col w-full items-start justify-center  ">
          <p
            dir={setLanguageDirection(collection?.title)}
            className={` text-[14px] max-w-full font-dana font-[500] text-gray_5  leading-6 whitespace-nowrap  truncate `}
          >
            {collection?.title}
          </p>
          <p className="text-gray_3 text-[12px] font-medium font-dana leading-6  whitespace-nowrap truncate max-w-full gap-1 flex">
            <span>آهنگ</span>
            <span>{collection?.tracks_count}</span>
          </p>
          { Boolean(collection?.is_private) &&  <div>
            <PlaylistGrayLockIcon/>
          </div>}
        </div>
      </div>
      {!inBottomSheet && (
        <div className='pl-4' onClick={(e) => e.stopPropagation()}>
          <UserPlaylistBottomSheetEditOptions  isPrivate={collection?.is_private} userCollectionData={collection} handleUpdateCollectionData={()=>{}} inCollectionList={true}/>

        </div>
      )}
    </div>
  );
}

export default UserPlaylistItem;
