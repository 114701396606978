import React from 'react';
import CustomLazyLoadImage from '../../common/CustomLazyLoadImage.jsx';
import UserPlaylistContextMenu from '../../playlist/UserPlaylistContextMenu.jsx';
import CssShimmer from '../../common/CssShimmer.jsx';
import VisitCountIcon from '../playList/VisitCountIcon.jsx';
import { PrivateProfileIcon } from '../profile/PrivateProfileIcon.jsx';
import UserAvatar from '../../common/UserAvatar.jsx';
import { useNavigate } from 'react-router-dom';
import { UserPlaylistBottomSheetEditOptions } from '../../userPlaylist/UserPlaylistBottomSheetEditOptions.jsx';
import PlaceHolderWithTitle from '../../common/PlaceHolderWithTitle.jsx';
import { useSocialProfile } from '../../../hooks/useSocialProfile.js';

const ProfileSection = ({ userSocialProfile, openProfile }) => (
  <div onClick={openProfile} className="flex items-center gap-1 cursor-pointer">
    <UserAvatar
      avatar={userSocialProfile?.avatar}
      social_token={userSocialProfile?.token}
      is_premium={userSocialProfile?.is_premium}
      className="w-4 h-4 min-w-4"
   
    />
    <p className="flex text-primary text-sm font-dana font-medium">
      {userSocialProfile?.name}
    </p>
  </div>
);

export const UserPlaylistDataSection = ({ userCollectionData, isLoading,handleUpdateCollectionData,isDragModeActive }) => {
  const { social_profile: userSocialProfile, image_large, title, is_private, updated_days_text } = userCollectionData || {};
  const navigate = useNavigate();
  const  {isUserSocialProfile}= useSocialProfile(userSocialProfile?.token)

  const openProfile = () => {
    if (userSocialProfile?.token) navigate(`/profile/${userSocialProfile.token}`);
  };

  return (
    <div className="flex gap-3">
      <div className="w-[120px] min-w-[120px] h-[120px] min-h-[120px] rounded-lg overflow-hidden relative">
        <span className="relative top-0 left-0 w-full bottom-0 h-full !p-0 !m-0  flex justify-center items-center">
            <PlaceHolderWithTitle style={"!rounded-[5px]"} title={""} />
          </span>
        {Boolean(!isLoading) && <CustomLazyLoadImage image={image_large} />}

      </div>
      <div className="flex flex-col justify-between w-full overflow-hidden">
        {isLoading?
          <CssShimmer classNames="w-20 rounded-lg h-6" />
        :
          <div className="flex w-full justify-between items-center overflow-hidden gap-3">
            <div className="flex gap-2  h-[36px] items-center overflow-hidden">
              <p className="text-white text-[22px] font-dana font-[700] leading-6 whitespace-nowrap truncate">
                {title}
              </p>
              {Boolean(is_private) && <PrivateProfileIcon fill="#F2C94C" width="21" height="21" className='min-w-[21px]'  />}
            </div>
            {Boolean(!isDragModeActive && isUserSocialProfile) && <UserPlaylistBottomSheetEditOptions isPrivate={userCollectionData?.is_private} userCollectionData={userCollectionData} handleUpdateCollectionData={handleUpdateCollectionData}/> }
          </div>
        }



        {isLoading?

          <CssShimmer classNames="w-20 rounded-lg h-6" />
          :
        <ProfileSection userSocialProfile={userSocialProfile} openProfile={openProfile} />

        }

        <div className="flex gap-1 items-center">
          <VisitCountIcon fill="#BDBDBD" width="18" height="18" />
          {!isLoading ? (
            userCollectionData?.audience_views_count && (
              <div className="flex items-center gap-1">
                <p className="text-gray_4 text-[12px] font-[500] font-dana">
                  {userCollectionData?.audience_views_count}
                </p>
                <p className="text-gray_4 text-[12px] font-[500] font-dana">
                  مشاهده</p>
              </div>
            )
          ) : (
            <CssShimmer classNames="w-20 rounded-lg h-6" />
          )}
        </div>

        {updated_days_text && (
          <div className="flex items-center">
            <p className="text-[12px] text-gray_4 font-dana font-[500]">{updated_days_text}</p>
          </div>
        )}
      </div>
    </div>
  );
};
