import React from 'react';

import { useLocation } from 'react-router-dom';
import CommonHeader from '../../common/CommonHeader';
import Container from '../../PageParentContainer/Container';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getFollowings } from '../../../services/ApiClient';
import { useInfiniteQuery } from '@tanstack/react-query';
import SongListLoading from '../../loading/SongListLoading';
import { FollowingCartItem } from './FollowingCartItem';
import { NoFollowingSvg } from '../../svg/profile/NoFollowingSvg';
import { NoteMusicLoadingContainer } from '../../loading/NoteMusicLoadingContainer';

export const ProfileFollowing = () => {
  const location = useLocation();
  console.log('awsrafasrfsfasf', location?.state?.social_token);
  const social_token = location?.state?.social_token;
  const {
    isLoading: getFollowinggIsLoading,
    isFetchingNextPage: getFollowingIsFetchingNextPage,
    fetchNextPage: getFollowingIsFetchNextPage,
    hasNextPage: getFollowinggHasNextPage,
    data: getMelopostsData,
    isFetching,
  } = useInfiniteQuery(
    [`user-Following`, social_token],
    ({ pageParam = 0 }) =>
      getFollowings({
        offset: pageParam,
        social_token: social_token,
        hashtag: null,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const allData = allPages?.flatMap(page => page.data.result.followings);
        return lastPage?.data?.result?.end ? false : allData?.length;
      },
      refetchOnWindowFocus: false,
    },
  );

  const getFollowingFlatMap = getMelopostsData?.pages?.flatMap(
    page => page.data.result.followings,
  );
  const getMeloPostNextPage = () => {
    if (getFollowinggHasNextPage && !getFollowingIsFetchingNextPage) {
      getFollowingIsFetchNextPage();
    }
  };
  // getFollowings;
  return (
    <Container shadow={false} header={<CommonHeader title="دنبال شونده‌" />}>
      <div
        id="following-page"
        dir="rtl"
        class="w-full h-full bg-black overflow-scroll hide-scroll-bar"
      >
        {/* <ScrollTopButton show={slideIndex === 0} id={'scrollbarWeekNew'} /> */}
        {isFetching ? (
          <NoteMusicLoadingContainer />
        ) : getFollowingFlatMap ? (
          <InfiniteScroll
            className={'!overflow-hidden'}
            next={getMeloPostNextPage}
            hasMore={getFollowinggHasNextPage}
            dataLength={getFollowingFlatMap?.length || 0}
            loader={
              getFollowingIsFetchingNextPage && (
                <div className="w-full flex justify-center items-start h-[50px]   ">
                  <SongListLoading />
                </div>
              )
            }
            scrollableTarget="following-page"
          >
            {/* <div className={'w-full h-full !overflow-hidden  mt-3'}>
            <Virtuoso
              style={{ overflowY: 'hidden !important' }}
              customScrollParent={document.getElementById(
                'scrollbar-user-social',
              )}
              data={getMelopostsFlatMap}
              itemContent={(index, item) => (
                <div className={'flex flex-col  pb-5 '}>
                  <MeloPostFullCard
                    index={index}
                    item={item}
                    key={'melofullcard' + index}
                    handlePlay={handlePlay}
                    activePlayerIndex={activePlayerIndex}
                  />
                
                </div>
              )}
            />
          </div> */}

            <div className={'w-full h-full !overflow-hidden  mt-3 gap-5'}>
              {getFollowingFlatMap?.map((item, index) => (
                <div className={'flex flex-col  pb-5  text-primary'}>
                  <FollowingCartItem item={item} />
                  {/* <MeloPostFullCard
                        index={index}
                        item={item}
                        key={'melofullcard' + index}
                        handlePlay={handlePlay}
                        activePlayerIndex={activePlayerIndex}
                    /> */}
                </div>
              ))}
            </div>
          </InfiniteScroll>
        ) : (
          <div className="flex w-full h-full  flex-col justify-center items-center gap-4   mt-8">
            <NoFollowingSvg />
            <span
              className={
                'text-gray_2 font-[600] font-dana text-[20px] leading-6  '
              }
            >
              دنبال شونده‌ای وجود ندارد
            </span>
          </div>
        )}
      </div>
    </Container>
  );
};
