export const USER_PROFILE_STATUS = {
  Private: 'private',
  PUBLIC: 'public',
};

export const PROFILE_OPTIONS = [
  {
    id: 1,
    title: 'ویرایش پروفایل',
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2113_184573)">
          <path
            d="M16.0938 20H3.90625C1.7523 20 0 18.2477 0 16.0938V3.90625C0 1.75234 1.7523 0 3.90625 0H11.4856C11.9171 0 12.2668 0.349766 12.2668 0.78125C12.2668 1.21273 11.9171 1.5625 11.4856 1.5625H3.90625C2.61391 1.5625 1.5625 2.61391 1.5625 3.90625V16.0938C1.5625 17.3861 2.61391 18.4375 3.90625 18.4375H16.0938C17.3861 18.4375 18.4375 17.3861 18.4375 16.0938V8.51441C18.4375 8.08293 18.7873 7.73316 19.2188 7.73316C19.6502 7.73316 20 8.08293 20 8.51441V16.0938C20 18.2477 18.2477 20 16.0938 20Z"
            fill="#BDBDBD"
          />
          <path
            d="M18.7623 1.23854C17.5438 0.0200976 15.5613 0.0200976 14.3428 1.23854L5.83857 9.74283C4.40622 11.1751 3.78826 13.2161 4.18552 15.2024C4.24736 15.5117 4.48908 15.7534 4.79833 15.8153C6.77509 16.2106 8.8188 15.6014 10.258 14.1622L18.7623 5.65791C19.9807 4.43951 19.9807 2.45697 18.7623 1.23854ZM17.6574 4.55311L9.1531 13.0574C8.22029 13.9903 6.93958 14.4571 5.64329 14.3575C5.54349 13.0615 6.01048 11.7806 6.94341 10.8477L13.5616 4.22947L14.1141 4.78193C14.4192 5.08701 14.9138 5.08709 15.2189 4.78193C15.524 4.47686 15.5241 3.98217 15.219 3.67709L14.6665 3.12463L15.4477 2.34338C16.057 1.73416 17.0483 1.73416 17.6574 2.34338C18.2667 2.9526 18.2667 3.94389 17.6574 4.55311Z"
            fill="#BDBDBD"
          />
        </g>
        <defs>
          <clipPath id="clip0_2113_184573">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    id: 2,
    title: 'وضعیت پروفایل',
    icon: (
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2113_184583)">
          <path
            d="M9.96033 4.44531C8.45261 4.44531 7.22595 5.64969 7.22595 7.13025V8.215C6.27838 8.34866 5.54688 9.16455 5.54688 10.1484V14.6016C5.54688 15.0331 5.89661 15.3828 6.32812 15.3828H13.5938C14.0253 15.3828 14.375 15.0331 14.375 14.6016V10.1484C14.375 9.16409 13.6429 8.3479 12.6947 8.21469V7.13025C12.6947 5.64969 11.468 4.44531 9.96033 4.44531ZM9.96033 6.00781C10.6064 6.00781 11.1322 6.51135 11.1322 7.13025V8.19531H8.78845V7.13025C8.78845 6.51135 9.31412 6.00781 9.96033 6.00781ZM12.8125 10.1484V13.8203H7.10938V10.1484C7.10938 9.93298 7.28455 9.75781 7.5 9.75781H12.4219C12.6373 9.75781 12.8125 9.93298 12.8125 10.1484ZM10.7422 11.7891C10.7422 12.2206 10.3925 12.5703 9.96094 12.5703C9.52942 12.5703 9.17969 12.2206 9.17969 11.7891C9.17969 11.3575 9.52942 11.0078 9.96094 11.0078C10.3925 11.0078 10.7422 11.3575 10.7422 11.7891ZM20 10.5C20 12.4739 19.4141 14.3936 18.3057 16.0515C18.0659 16.41 17.5807 16.5065 17.222 16.2668C16.8633 16.0269 16.767 15.5417 17.0067 15.1831C17.9428 13.7829 18.4375 12.1637 18.4375 10.5C18.4375 5.8476 14.6524 2.0625 10 2.0625C5.3476 2.0625 1.5625 5.8476 1.5625 10.5C1.5625 15.1524 5.3476 18.9375 10 18.9375C11.5431 18.9375 13.0525 18.5173 14.3648 17.7224C14.7337 17.4989 15.2142 17.6169 15.4378 17.986C15.6613 18.3549 15.5434 18.8353 15.1743 19.0588C13.6179 20.0016 11.8286 20.5 10 20.5C7.32895 20.5 4.81766 19.4598 2.92892 17.5711C1.04019 15.6823 0 13.1711 0 10.5C0 7.82895 1.04019 5.31766 2.92892 3.42892C4.81766 1.54019 7.32895 0.5 10 0.5C12.6711 0.5 15.1823 1.54019 17.0711 3.42892C18.9598 5.31766 20 7.82895 20 10.5Z"
            fill="#BDBDBD"
          />
        </g>
        <defs>
          <clipPath id="clip0_2113_184583">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    id: 3,
    title: 'پشتیبانی',
    icon: (
      <svg
        width="26"
        height="28"
        viewBox="0 0 26 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          clip-path="url(#clip0_2113_184590)"
          filter="url(#filter0_d_2113_184590)"
        >
          <g filter="url(#filter1_d_2113_184590)">
            <path
              d="M13 20C13.7629 20 14.4072 19.5087 14.6499 18.8281H16.5156C19.2229 18.8281 21.4932 16.7701 21.7891 14.1406V8.20312C21.4854 3.63 17.6488 0 13 0C8.35121 0 4.51465 3.63 4.21094 8.20312V12.3828C4.21094 13.3536 4.99794 14.1406 5.96875 14.1406C6.93956 14.1406 7.72656 13.3536 7.72656 12.3828V10.5172C7.72656 9.23917 6.69052 8.20312 5.4125 8.20312C5.71363 4.27672 8.99777 1.17188 13 1.17188C17.0022 1.17188 20.2864 4.27672 20.5875 8.20312C19.3095 8.20312 18.2734 9.23917 18.2734 10.5172V11.8561C18.2734 13.1178 19.2963 14.1406 20.558 14.1406C20.2706 16.123 18.5764 17.6562 16.5156 17.6562H14.6499C14.4072 16.9757 13.7629 16.4844 13 16.4844C12.0307 16.4844 11.2422 17.2729 11.2422 18.2422C11.2422 19.2115 12.0307 20 13 20Z"
              fill="#BDBDBD"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_2113_184590"
            x="-1"
            y="0"
            width="28"
            height="28"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2113_184590"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2113_184590"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_2113_184590"
            x="0.210938"
            y="-4"
            width="25.5781"
            height="28"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2113_184590"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2113_184590"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_2113_184590">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(3)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
];
