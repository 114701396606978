import React, { useEffect } from 'react';
import { usePlayedTrack } from '../../hooks/usePlayedTrack';
import { getIsPlaying } from '../../helper/ReduxSelectorContext';
import {
  setPlayedPlaylist,
  setPlayerPaginationData,
} from '../../redux/player/PlayMusicSlice';
import { useDispatch } from 'react-redux';
import { useDownload } from '../../hooks/useDownload.js';
import CardActionArea from '@mui/material/CardActionArea';
import { closeDropDownMenu } from '../../redux/player/DropDownSlice.js';
import AddsLimit from './AddsLimit.jsx';
import { useSelectTrackItem } from '../../hooks/useSelectTrackItem.js';
import SongCardActionSection from '../SongCard/SongCardActionSection.jsx';
import SongCardInfoSection from '../SongCard/SongCardInfoSection.jsx';
import clsx from 'clsx';

function SongDetailCard({
  index,
  number = false,
  data: item,
  playList = [],
  ShowItemIsPlaying = true,
  type = 'playlist',
  collectionObject = null,
  turncating = false,
  isPlayListFromQueue = false,
  isInQueue = false,
  inPlayer = false,
  isSelectMode = false,
  fromSearch = false,
  apiUrl = null,
  requestPayload = null,
  endOfPagination = true,
  disableSwipePlayerOpen = false,
  isInUserPlaylist=false,
  pageSort
}) {
  const [currentTrack, setPlayerTrack] = usePlayedTrack(
    true,
    disableSwipePlayerOpen,
  );
  const [, , , , downloadedItem] = useDownload(item);
  const { isSelected } = useSelectTrackItem(inPlayer, item);
  const dispatch = useDispatch();
  const isPlaying = getIsPlaying();
  const handlePlayMusic = (event,item) => {
    console.log(event?.target,'target...');
   if(event?.target?.classList?.contains('MuiBackdrop-root')){
     return
   }
    setPlayerTrack(
      downloadedItem ? downloadedItem : item,
      playList,
      type,
      isPlayListFromQueue,
    );
    if (!inPlayer && apiUrl) {
      dispatch(
        setPlayerPaginationData({
          playerPaginationData: {
            url: apiUrl,
            payload: requestPayload,
            end: endOfPagination,
          },
        }),
      );
    }
    if (!inPlayer && !apiUrl) {
      dispatch(
        setPlayerPaginationData({
          playerPaginationData: {
            url: null,
            payload: null,
            end: true,
          },
        }),
      );
    }
    // set playlist if playing from playlist play
    if (collectionObject)
      dispatch(setPlayedPlaylist({ playlist: collectionObject }));
  };
  const styleOfCard = () => {
    if (isSelected) {
      return 'bg-primary bg-opacity-20';
    }
    return `${
      index % 2 !== 0
        ? 'bg-song-detail-card-background-2'
        : 'bg-song-detail-card-background'
    }`;
  };
  useEffect(() => {
    return () => {
      dispatch(closeDropDownMenu());
    };
  }, [closeDropDownMenu]);

  return (
    <>
      {item?.add_on && <AddsLimit inPlayer={inPlayer} item={item} />}
      <CardActionArea className=" !rounded-[5px]">
        <div
          dir="ltr"
          onClick={(event) => handlePlayMusic(event,item)}
          className={`p-[1px]  cursor-pointer group  relative overflow-visible`}
        >
          {/* {currentTrack?.id == item?.id && (
            <div className="absolute flex w-[55%] top-[1px] bottom-[1px] bg-queue-border  opacity-20 rounded-[5px]"></div>
          )} */}
          <div
            className={clsx(
              isInQueue
                ? currentTrack?.Qid == item?.Qid
                : currentTrack?.id == item?.id && 'bg-white bg-opacity-10',
              number ? 'pl-0' : 'pl-4',
              item?.is_demo && 'pr-4',
              `py-2 flex justify-between   w-full items-center`,
            )}
          >
            <SongCardInfoSection
              item={item}
              ShowItemIsPlaying={ShowItemIsPlaying}
              index={index}
              number={number}
            />
            <SongCardActionSection
              item={item}
              inPlayer={inPlayer}
              currentTrack={currentTrack}
              isInQueue={isInQueue}
              handlePlayMusic={handlePlayMusic}
              isPlaying={isPlaying}
              isSelectMode={isSelectMode}
              isSelected={isSelected}
              isInUserPlaylist={isInUserPlaylist}
              pageSort={pageSort}
            />
          </div>
        </div>
      </CardActionArea>
    </>
  );
}

export default React.memo(SongDetailCard);
