import React, { useEffect, useRef, useState } from 'react';
import { createReelCdnLink } from '../../../helper/createMusicCdnLink';
import {
  getReelsPlayerStatus,
  getSwipePlayerStatus,
  getUserToken,
  useUserData,
} from '../../../helper/ReduxSelectorContext';
import ReelPlayerSeekbar from './ReelPlayerSeekbar';
import ReactPlayer from 'react-player';
import CustomLazyLoadImage from '../../common/CustomLazyLoadImage';
import TextMarquee from '../../common/TextMarquee';
import { ReelTrackInfo } from './ReelTrackInfo';
import { ReelActions } from './ReelActions';
import MusicNoteLoading from '../../loading/MusicNoteLoading';
import { IsIosDevice } from '../../../helper/CheckDevice';
import { useSwiper } from 'swiper/react';

import { useReelsAdvertise } from '../../../hooks/useReelsAdversite';
import clsx from 'clsx';
import { useAction } from '../../../hooks/useAction';
import ClickReaction from '../../../helper/ClickReaction';
import { CardActionArea } from '@mui/material';
import { useDispatch } from 'react-redux';
import { pauseReelPlayer } from '../../../redux/reelPlayer/ReelsPlayerSlice';
import { useReels } from '../../../consts/useReels';
import CustomImage from '../../common/CustomImage';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import ReelPlayerSeekbar from './ReelPlayerSeekbar';

export const ReelPlayer = ({ item, setMute, mute }) => {
  let userData = useUserData();
  let userToken = getUserToken();
  const action = useAction();
  const dispatch = useDispatch();
  const playerSwipeStatus = getSwipePlayerStatus();
  const { handleCloseReels } = useReels();
  const video_url = createReelCdnLink(item?.video, userData, userToken);
  const [duration, setDuration] = useState(0);
  const [seekTime, setSeekTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const swiper = useSwiper();
  const [pausePlayer, setPausePlayer] = useState(false);
  const {
    checkReelsAdvertises,
    advData,
    hasReelAdvertise,
    setHasReelAdvertise,
    isAdvertiseActive,
    increaseWeightCount,
    checkReelWeighCount,
    handleReseteAdvertise,
  } = useReelsAdvertise();

  const reelPlayerRef = useRef(null);

  const handleProgress = progress => {
    setSeekTime(progress?.playedSeconds);
  };

  const handleDuration = duration => {
    setDuration(duration);
  };

  const handleOnRady = () => {
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
  }, [item]);

  const handleEndReel = () => {
    if (hasReelAdvertise) {
      setHasReelAdvertise();
      setSeekTime(0);
      swiper.enable();
      swiper.allowTouchMove = true;
      handleReseteAdvertise();
    } else {
      swiper.slideNext();
    }
  };

  const touchTimerRef = useRef(null);

  const handleTouchStart = e => {
    if (hasReelAdvertise) return;
    touchTimerRef.current = setTimeout(() => {
      reelPlayerRef?.current?.player?.player?.pause();
      setPausePlayer(true);
    }, 300);
  };
  const handleTouchEnd = () => {
    if (touchTimerRef.current) {
      clearTimeout(touchTimerRef.current);
      touchTimerRef.current = null;
    }
    if (hasReelAdvertise) return;
    reelPlayerRef?.current?.player?.player?.play();
    setPausePlayer(false);
  };
  const handleTouchStartInside = e => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (reelPlayerRef) {
      if (playerSwipeStatus) {
        reelPlayerRef?.current?.player?.player?.pause();
      } else {
        reelPlayerRef?.current?.player?.player?.play();
      }
    }
  }, [playerSwipeStatus, reelPlayerRef]);

  useEffect(() => {
    if (isAdvertiseActive()) {
      if (checkReelWeighCount()) {
      } else {
        if (!userData?.is_premium) {
          increaseWeightCount();
        }
      }
      checkReelsAdvertises(video_url);
    }
  }, [video_url]);

  const handleClickAdvButtom = () => {
    // action(advData?.[0]?.btn_action);
    action({ ...advData?.[0]?.btn_action, openReelWhenBack: true });
    handleCloseReels();
  };

  return (
    <div
      id="reel-player"
      className=" w-full mx-auto h-full relative flex flex-col"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {loading ? (
        <div className="absolute z-30 top-0 left-[50%] translate-x-[-50%] w-full h-full flex flex-row justify-center items-center">
          <MusicNoteLoading width={120} />
        </div>
      ) : null}
      {loading&&!hasReelAdvertise ? (
        <div className="absolute z-20 top-0 left-[50%] translate-x-[-50%] w-full h-full flex flex-row justify-center items-center">
          <LazyLoadImage className="!w-full !h-full !object-contain" src={item?.image} />
        </div>
      ) : null}

      <ReactPlayer
        muted={hasReelAdvertise ? false : mute}
        // muted={true}
        ref={reelPlayerRef}
        volume={IsIosDevice() ? 0 : 1}
        playsinline={true}
        playing={!playerSwipeStatus}
        preload="auto"
        wrapper={'span'}
        url={
          hasReelAdvertise
            ? createReelCdnLink(advData?.[0]?.video, userData, userToken)
            : video_url
        }
        onProgress={handleProgress}
        onDuration={handleDuration}
        onReady={handleOnRady}
        onEnded={handleEndReel}
        // onError={handleError}
        config={{
          uiWebview: {
            allowsInlineMediaPlayback: true,
            mediaPlaybackRequiresUserAction: false,
            backgroundMode: true,
          },
          attributes: {
            playsinline: true,
            playsInline: true,
            'webkit-playsinline': true,
          },
          file: {
            forceVideo: true,
          },
        }}
      />
      {/* <div className="w-full h-full">
        <video
          id="reel_player"
          // controls
          //   url={'/empty.mp3'}
          preload="auto"
          onDurationChange={handleChangeDuration}
          onTimeUpdate={e => handleProgress(e)}
          onLoadedData={e => handleLoadedData(e)}
          src={isActive ? video_url : ''}
          className="absolute bottom-0 left-0  right-0 top-0 h-full w-full object-cover object-center "
          autoPlay
          playsInline
          muted
        ></video>
      </div> */}
      <div
        key={item?.id}
        className={clsx(
          'w-full h-full flex  flex-col justify-end items-end px-4 static full-screen-lyrics z-30  gap-4 transition-opacity duration-300',
          pausePlayer && !hasReelAdvertise ? 'opacity-0' : 'opacity-100',
          hasReelAdvertise ? 'pb-0' : 'pb-4',
        )}
      >
        {hasReelAdvertise ? null : (
          <div className="w-full flex flex-row  items-end justify-between">
            <ReelTrackInfo
              handleTouchStartInside={handleTouchStartInside}
              item={item}
            />

            <ReelActions
              handleTouchStartInside={handleTouchStartInside}
              item={item}
              setMute={setMute}
              mute={mute}
              reelPlayerRef={reelPlayerRef}
            />
          </div>
        )}
        {hasReelAdvertise ? (
          <div className="w-full flex flex-col justify-center items-center gap-4">
            <div className=" flex flex-col  gap-1 items-center justify-center">
              <span className="font-kalameh  font-medium size-[20px]  text-white">
                {advData?.[0]?.title}
              </span>
              <span className="font-kalameh  font-medium size-[14px]  text-white text-center">
                {advData?.[0]?.description}
              </span>
            </div>
            {advData?.[0]?.btn_text ? (
              <ClickReaction>
                <CardActionArea className=" !h-[45px]  !rounded-[100px]">
                  <button
                    onClick={() => {
                      handleClickAdvButtom();
                    }}
                    className="bg-primary !px-20  h-full rounded-[100px] text-black font-dana font-[500] text-[16px]  cursor-pointer "
                  >
                    {advData?.[0]?.btn_text}
                  </button>
                </CardActionArea>
              </ClickReaction>
            ) : null}
          </div>
        ) : null}
        <div onTouchStart={handleTouchStartInside} className="w-full">
          <ReelPlayerSeekbar
            hasReelAdvertise={hasReelAdvertise}
            duration={duration}
            playerRef={reelPlayerRef}
            seekTime={seekTime}
            setSeekTime={setSeekTime}
          />
        </div>
      </div>
    </div>
  );
};
