import React from 'react'
import { HomeSectionBackgroundOverlay } from './HomeSectionBackgroundOverlay';

export const GapSection = ({ item }) => {

  return (
    <div
    data-identifire="gap"
      style={{
        height: item?.element_meta?.height,
        
      }}
      className='relative'
    >
      <HomeSectionBackgroundOverlay
           background_color={item?.element_meta?.background_color}
         />
    </div>
  );
};

 