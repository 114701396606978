import React, { useState } from 'react';
import { ShareInstagramSection } from './ShareInstagramSection';
import { DownloadMelopostSection } from './DownloadMelopostSection';


function ShareDownloadSection({ melopostUrl,downloadTitle }) {


  return (
    <div className='flex items-center gap-4 w-full justify-center'>
       <ShareInstagramSection melopostUrl={melopostUrl} downloadTitle={downloadTitle}/>
       <DownloadMelopostSection melopostUrl={melopostUrl} downloadTitle={downloadTitle}/>

  </div>);
}

export default ShareDownloadSection;
