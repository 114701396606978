import React, { useRef } from 'react';
import { Tab } from '@headlessui/react';
import { MelopostsTabsItems } from '../../../consts/MelopostsTabsItems.js';

export const ListTabs = ({ handleTabChange, slideIndex,className=''}) => {

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <div className="!sticky !top-[0px] z-[20] bg-black pb-5" slot="container-start">
      <div className={'w-[calc(100%_-_32px)] mx-auto'}>
        <Tab.Group selectedIndex={slideIndex} onChange={handleTabChange} dir="rtl">
          <Tab.List
            className={`flex  items-center min-h-[42px] gap-6  text-white w-full mx-auto  pt-[18px] z-[20]  rounded-[5px] ${className}`}
          >
            {
              MelopostsTabsItems?.map((item,index)=>{
                return (
                  <Tab
                    key={item?.value}
                    className={({}) =>
                      classNames(
                        'flex flex-col justify-center relative items-center w-fit font-dana text-[14px] font-[400] text-gray_4 focus-visible:!outline-none focus-visible:!ring-none py-[10px]',
                       slideIndex === index
                          ? 'text-white text-[16px] font-[500] after:flex after:h-[2px] after:w-[30px] after:absolute after:mt-[39.5px] after:left-[50%] after:translate-x-[-50%] after:bg-primary  after:rounded-full'
                          : '',
                      )
                    }
                  >
                    {item?.title}
                  </Tab>
                )
              })
            }


          </Tab.List>
        </Tab.Group>
      </div>
    </div>
  );
};

