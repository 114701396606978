import React, { useState } from 'react';
import ShareDownloadIcon from '../../svg/meloPost/ShareDownloadIcon.jsx';
import ShareLoading from '../../svg/common/ShareLoading.jsx';
import useSingleFileDownload from '../../../hooks/useSingleFileDownload.js';


export const DownloadMelopostSection = ({ melopostUrl,downloadTitle}) => {
  const {startDownload,error,loading,progress} = useSingleFileDownload();
  const handleDownloadFile = async () => {
    if(loading){
      return
    }
    await startDownload(melopostUrl,downloadTitle)
  }

  return (
    <div onClick={handleDownloadFile} className="flex rounded-[100px] border-[1px] border-gray_2 h-[37px] gap-[6px] w-[82px] items-center pr-[2px]">
      <div className="flex items-center justify-center w-[30px] h-[30px]">
        {loading ? (
          <div className="w-full h-full">
            <ShareLoading />
          </div>
        ) : (
          <ShareDownloadIcon />
        )}
      </div>

      <p className="font-dana text-gray_5 text-[12px] font-[500]">دانلود</p>
    </div>
  );
};

