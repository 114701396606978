import React from 'react';
import { StatisticsList } from './StatisticsList';

export const UserPlaylistStatisticsInfoSection = ({ collectionStatistics }) => {
  return (
    <div className='flex flex-col gap-4 w-full items-center'>
      <div className='flex flex-col gap-2'>
            <p className='text-white text-[20px] font-[700] font-dana'>
              {collectionStatistics?.title}
            </p>
        {
          Boolean(collectionStatistics?.updated_days_text) &&
          <div className='flex items-center gap-1'>
            <p className='text-gray_4 text-[12px] font-[500] font-dana'>
              آخرین بروزرسانی:
            </p>
            <p className='text-white text-[12px] font-[500] font-dana'>
              {collectionStatistics?.updated_days_text}
            </p>
          </div>



        }
      </div>
      <StatisticsList statistics={collectionStatistics?.statistics} premiumAppAction={collectionStatistics?.premium_app_action} />


    </div>
  );
}


