import { useMutation } from "@tanstack/react-query";
import { checkAdvertise, getReelAdvertise } from "../services/ApiClient";
import { useState } from "react";
import { useSwiper } from "swiper/react";
import { getReelSettingData, getReelWeightCount } from "../helper/ReduxSelectorContext";
import { resetReelWeightCount, selectReelWeightCount, setReelWeightCount } from "../redux/reelPlayer/ReelsPlayerSlice";
import { useDispatch } from "react-redux";

let controller = new AbortController();
export const useReelsAdvertise = () => {
    const dispatch = useDispatch()
    const reelSetting = getReelSettingData()
    const reelWeighCount = getReelWeightCount()

    const swiper = useSwiper();
    const [hasReelAdvertise, setHasReelAdvertise] = useState(false)
    const [advData, setAdvData] = useState(null)
    const getReelAdvMutation = useMutation(() => getReelAdvertise(), {
        onSuccess: (res) => {
            if (res?.data?.done) {
                swiper.allowTouchMove = false
                swiper.disable()
                setHasReelAdvertise(true)
                setAdvData(res?.data?.result?.reel_advertises)
            }
        }, onError: err => {
            setHasReelAdvertise(false)
            // handleReseteAdvertise()

        }
    });

    const checkReelAdvertiseMutation = useMutation(({ reel_url, signal }) => checkAdvertise(reel_url, signal), {
        onError: (err) => {
            if (err?.response && (err.response.status >= 460 && err.response.status <= 462)) {

                getReelAdvMutation.mutate()

            }


        }
    })

    const checkReelsAdvertises = (reel_url) => {
        controller.abort()
        controller = new AbortController()
        checkReelAdvertiseMutation.mutate({ reel_url: reel_url, signal: controller.signal })

    }

    const increaseWeightCount = () => {
        dispatch(setReelWeightCount())
    }

    const isAdvertiseActive = () => reelSetting?.is_advertise_active

    const checkReelWeighCount = () => {

        if (Number(reelWeighCount) >= reelSetting?.advertise_show_count - 1) {
            getReelAdvMutation.mutate()

            return true
        }

    }
    const handleReseteAdvertise = () => {
        dispatch(resetReelWeightCount())
    }


    return { checkReelsAdvertises, hasReelAdvertise, setHasReelAdvertise, advData, isAdvertiseActive, increaseWeightCount, checkReelWeighCount, handleReseteAdvertise }
}