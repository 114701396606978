import React, { useRef, useState } from "react";
import UploadFile from "./UploadFile";
import { useFormik } from "formik";
import {
  ADD_NEW_TICKET_VALIDATION_SCHEMA,
  DESCRIPTION_NEW_MESSAGE_VALIDATION_SCHEMA,
} from "../../consts/Validation.js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  addMessageReply,
  sendCompleteRequest,
} from "../../services/ApiClient.js";
import Loading from "../loading/Loading.jsx";

import { CardActionArea } from "@mui/material";
import AttachmentSvg from "../svg/support/AttachmentSvg.jsx";

let replyId;

function NewAnswer({ ticket, onShowNewAnswer, onCompleteRequestEvent }) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [UploadFileIsOpen, setUploadFileIsOpen] = useState(false);
  const uploadFileRef = useRef(null);
  const formik = useFormik({
    initialValues: {
      description: "",
    },
    validationSchema: DESCRIPTION_NEW_MESSAGE_VALIDATION_SCHEMA,
    validateOnChange: false,
    onSubmit: (data) => {
      data.ticket_id = ticket?.id;
      setLoading(true);
      addMessageReplyMutation.mutate(data);
    },
  });
  const addMessageReplyMutation = useMutation((data) => addMessageReply(data), {
    onSuccess: (res) => {
      replyId = res?.data?.result?.ticket_reply?.id;
      uploadFiles(res?.data?.result?.ticket_reply?.id);
    },
  });
  const sendCompleteRequestMutation = useMutation(
    (replyId) => sendCompleteRequest(replyId),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["getTicketMessageQuery"]);
        formik.resetForm();
        uploadFileRef.current.resetData();
        setLoading(false);
        onShowNewAnswer();
      },
    }
  );
  const CompleteRequestEvent = () => {
    sendCompleteRequestMutation.mutate(replyId);
    onCompleteRequestEvent();
  };
  const uploadFiles = (replyID) => {
    uploadFileRef.current.uploadFiles(replyID);
  };

  return (
    <div className="flex flex-col bg-primary_dark px-4 py-3 gap-1">
      <div className="flex flex-col mb-2">
        <CardActionArea
          className={"!absolute !mt-3 !ml-3 !rounded-full !w-[46px] z-10"}
        >
          <div
            onClick={() => setUploadFileIsOpen(true)}
            className="flex cursor-pointer items-center justify-evenly bg-gray_1 rounded-full py-3 !w-[46px] focus:outline-none"
          >
            <div className="h-5 !w-[46px]">
              <AttachmentSvg />
            </div>
          </div>
        </CardActionArea>
        <div className="flex flex-col font-dana">
          <textarea
            id="description"
            onChange={formik.handleChange}
            value={formik.values.description}
            className={`w-full bg-transparent min-h-[120px] mb-5  border-[1px] border-white rounded-[5px] p-4 pl-16 text-right text-white text-18 focus:outline-none resize-none overflow-scroll hide-scroll-bar ${
              formik.errors.description && "border !border-1 !border-red"
            }`}
          ></textarea>
          {formik?.errors?.description && (
            <p className="text-[14px] text-red -mt-3 text-right">
              {formik?.errors?.description}
            </p>
          )}
        </div>
        <UploadFile
          ref={uploadFileRef}
          sendCompleteRequest={() => CompleteRequestEvent()}
          setIsOpen={setUploadFileIsOpen}
          isOpen={UploadFileIsOpen}
        />
      </div>
      <button
        onClick={!loading && formik.handleSubmit}
        type="submit"
        className="inline-flex font-dana font-[600] text-primary_dark text-base bg-primary w-full items-center  justify-center items-center rounded-full focus:outline-none gap-4 h-[48px]"
      >
        {loading ? (
          <div>
            <Loading bgColor={"#303030"} />
          </div>
        ) : (
          <span>ارسال</span>
        )}
      </button>
    </div>
  );
}

export default NewAnswer;
