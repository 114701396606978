import React from 'react';

function OpenArtistIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.2,18.337C15.017,18.373 15.815,18.084 16.42,17.533C17.025,16.982 17.388,16.214 17.429,15.397V8.689L19.077,9.514C19.23,9.586 19.405,9.596 19.565,9.541C19.724,9.486 19.856,9.37 19.932,9.219C20.007,9.067 20.021,8.893 19.969,8.731C19.917,8.57 19.804,8.436 19.654,8.358L17.072,7.065C16.973,7.017 16.864,6.995 16.755,7.001C16.645,7.006 16.539,7.04 16.446,7.097C16.353,7.155 16.276,7.235 16.222,7.331C16.168,7.426 16.139,7.534 16.138,7.643V13.06C15.568,12.666 14.892,12.456 14.2,12.457C9.935,12.582 9.931,18.21 14.2,18.337ZM14.2,13.749C16.756,13.809 16.756,16.984 14.2,17.045C11.644,16.984 11.645,13.809 14.2,13.749Z" fill="#BDBDBD"/>
      <path d="M7.694,9.231C8.606,9.231 9.499,8.96 10.258,8.453C11.017,7.946 11.608,7.225 11.958,6.382C12.307,5.538 12.398,4.61 12.22,3.715C12.042,2.82 11.602,1.997 10.957,1.352C10.312,0.706 9.489,0.267 8.594,0.089C7.699,-0.089 6.771,0.002 5.927,0.351C5.084,0.701 4.363,1.292 3.856,2.051C3.349,2.81 3.078,3.703 3.078,4.615C3.079,5.839 3.566,7.012 4.431,7.878C5.297,8.743 6.47,9.23 7.694,9.231ZM7.694,1.538C8.302,1.538 8.897,1.719 9.403,2.057C9.909,2.395 10.303,2.876 10.536,3.438C10.769,4 10.83,4.619 10.711,5.216C10.593,5.813 10.3,6.361 9.869,6.791C9.439,7.221 8.891,7.514 8.294,7.633C7.697,7.752 7.078,7.691 6.516,7.458C5.954,7.225 5.473,6.831 5.135,6.325C4.797,5.819 4.617,5.224 4.617,4.615C4.617,3.799 4.941,3.017 5.518,2.44C6.095,1.863 6.877,1.538 7.694,1.538ZM9.91,10.769H5.475C4.023,10.771 2.632,11.349 1.605,12.375C0.579,13.402 0.002,14.793 0,16.245C0.001,17.241 0.397,18.195 1.101,18.899C1.805,19.603 2.76,19.999 3.755,20H11.628C11.931,20 12.23,19.963 12.519,19.892C11.569,19.681 10.732,19.169 10.112,18.462H3.755C3.168,18.461 2.604,18.227 2.188,17.811C1.773,17.396 1.539,16.832 1.538,16.244C1.539,15.2 1.955,14.2 2.692,13.462C3.43,12.724 4.431,12.309 5.475,12.308H9.91C10.043,12.308 10.176,12.315 10.307,12.329C10.801,11.832 11.41,11.449 12.091,11.225C11.409,10.928 10.668,10.77 9.91,10.769Z" fill="#BDBDBD" fill-rule="evenodd"/>
    </svg>
  );
}

export default OpenArtistIcon;
