
import ProfileDetailCard from './ProfileDetailCard';
import { memo } from 'react';
import { useAction } from '../../hooks/useAction.js';
import CssShimmer from '../common/CssShimmer.jsx';


const ProfileCardList = ({
  isLoading,
  list,
                           user_published_tracks_banners
}) => {

  const  action  = useAction();

  const handleNavigate = (card) => {
    const actionData = card?.action
    action({ ...actionData,bannerData: user_published_tracks_banners });
  };

  return (
    <div className="flex flex-col  px-4 gap-2 pt-1  ">
      {isLoading?
        [...Array(5).keys()].map(item => (
          <CssShimmer
            classNames={
              'h-[48px] rounded-[10px] w-full'
            }

          />
        ))

      :
        list?.map((card, index) =>

          <div key={index + 'card-detail'} >
            <ProfileDetailCard
              isLoading={isLoading}
              onClick={() => handleNavigate(card)}
              avatar={card.icon}
              subtitle={card.subtitle}
              title={card.title}
              bannerData={card?.bannerData}
            />
          </div>

        )
      }
    </div>
  );
};

export default memo(ProfileCardList);
