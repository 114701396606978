import React, { useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getMeloposts } from '../../services/ApiClient';
import SongListLoading from '../loading/SongListLoading';
import { MeloPostFullCard } from '../meloPost/MeloPostFullCard';
import { MelopostListEmptyView } from '../meloPost/list/MelopostListEmptyView';
import { PrivateProfileView } from './PrivateProfileView';

const useMelopostsQuery = (socialToken) => {
  const [isPrivate, setIsPrivate] = useState(false);

  const query = useInfiniteQuery(
    [`user-meloPosts`, socialToken],
    ({ pageParam = 0 }) => getMeloposts({
      offset: pageParam,
      social_token: socialToken,
      hashtag: null,
    }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const allData = allPages?.flatMap(page => page.data.result.meloposts);
        return lastPage?.data?.result?.end ? false : allData?.length;
      },
      refetchOnWindowFocus: false,
      retry: 0,
      onError: (error) => {
        if (error?.response?.status === 403) setIsPrivate(true);
      }
    }
  );

  const flattenedPosts = query.data?.pages?.flatMap(
    page => page.data.result.meloposts
  ) ?? [];

  return { ...query, isPrivate, flattenedPosts };
};

export const UserMeloPosts = ({ social_token, handleOpenEdit }) => {
  const [activePlayerIndex, setActivePlayerIndex] = useState(null);
  
  const {
    isPrivate,
    flattenedPosts,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = useMelopostsQuery(social_token);

  const handlePlay = index => {
    setActivePlayerIndex(activePlayerIndex === index ? null : index);
  };

  if (isPrivate) {
    return <PrivateProfileView className="mt-8" />;
  }

  return (
    <InfiniteScroll
      className="!overflow-hidden"
      next={fetchNextPage}
      hasMore={hasNextPage}
      dataLength={flattenedPosts.length}
      loader={
        isFetchingNextPage && (
          <div className="w-full flex justify-center items-start h-[50px]">
            <SongListLoading />
          </div>
        )
      }
      scrollableTarget="scrollbar-user-social"
    >
      {flattenedPosts.length === 0 ? (
        <MelopostListEmptyView
          className="mt-8"
          title="شما هنوز هیچ ملوپستی ایجاد نکرده‌اید!<br/>همین حالا اولین ملوپست خود را بسازید."
        />
      ) : (
        <div className="w-full h-full !overflow-hidden mt-3 gap-5">
          {flattenedPosts.map((item, index) => (
            <div key={`post-${index}`} className="flex flex-col pb-5">
              <MeloPostFullCard
                index={index}
                item={item}
                handlePlay={handlePlay}
                activePlayerIndex={activePlayerIndex}
                handleOpenEdit={handleOpenEdit}
                meloPostItems={flattenedPosts}
              />
            </div>
          ))}
        </div>
      )}
    </InfiniteScroll>
  );
};
