import { Slider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { convertTime } from '../../../helper/ConvertPlayerTIme';
import { IsIosDevice } from '../../../helper/CheckDevice';
import clsx from 'clsx';

function ReelPlayerSeekbar({
  duration,
  playerRef,
  seekTime,
  setSeekTime,
  hasReelAdvertise,
}) {
  const [canChangeSeek, setCanChangeSeek] = useState(true);
  const [playerSeekBar, setPlayerSeekBar] = useState(0);
  const handleOnChangeSeekCommitted = (event, value) => {
    if (hasReelAdvertise) return;
    if (IsIosDevice() && event?.type === 'mousedown') {
      return;
    }
    event.preventDefault();
    setSeekTime(value);
    playerRef?.current?.player?.seekTo(value);
    playerRef?.current?.player?.player?.play();
    setCanChangeSeek(true);
  };

  const handleOnChangeSeek = value => {
    if (hasReelAdvertise) return;
    setPlayerSeekBar(value);
    setCanChangeSeek(false);
  };

  useEffect(() => {
    if (canChangeSeek) {
      setPlayerSeekBar(seekTime);
    }
  }, [seekTime, canChangeSeek]);

  return (
    <>
      <div class="flex flex-col items-center w-full rounded-full gap-2 ">
        {hasReelAdvertise ? (
          <div className="w-full flex flex-row justify-between items-center">
            <span
              class={clsx(
                `fa-format-500 text-[12px] lg-1920:text-f10  leading-[18px]  `,
                hasReelAdvertise ? 'text-primary' : 'text-gray_6',
              )}
            >
              {convertTime(playerSeekBar)}
            </span>

            <span
              class={clsx(
                `fa-format-500 text-[12px] lg-1920:text-f10  leading-[18px]  `,
                hasReelAdvertise ? 'text-primary' : 'text-gray_5',
              )}
            >
              {convertTime(duration)}
            </span>
          </div>
        ) : null}
        <Slider
          aria-label="time-indicator"
          className="!w-full !h-[3px] !m-0 !px-0 !py-[5px] !fix-renge"
          value={playerSeekBar}
          min={0}
          // step={0.001}
          disableSwap
          max={duration}
          onChangeCommitted={(_, value) =>
            handleOnChangeSeekCommitted(_, value)
          }
          onChange={(_, value) => handleOnChangeSeek(value)}
          sx={{
            color: hasReelAdvertise ? '#F2C94C' : '#FFFFFF',
            height: 4,
            '& .MuiSlider-thumb': {
              width: hasReelAdvertise ? 0 : 10,
              height: hasReelAdvertise ? 0 : 10,
              transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
              '&:hover, &.Mui-focusVisible': {
                boxShadow: hasReelAdvertise
                  ? ''
                  : `0px 0px 0px 8px rgb(242, 201, 76 , 16%)`,
              },
              '&.Mui-active': {
                width: hasReelAdvertise ? 0 : 16,
                height: hasReelAdvertise ? 0 : 16,
                boxShadow: hasReelAdvertise
                  ? `0px 0px 0px 0px rgb(242, 201, 76 , 16%)`
                  : `0px 0px 0px 8px rgb(242, 201, 76 , 16%)`,
              },
            },
            '& .MuiSlider-rail': {
              backgroundColor: '#FFFFFF33',
              height: 4,
              opacity: 1,
            },
          }}
        />
        {hasReelAdvertise ? null : (
          <div className="w-full flex flex-row justify-between items-center">
            <span
              class={clsx(
                `fa-format-500 text-[12px] lg-1920:text-f10  leading-[18px]  `,
                hasReelAdvertise ? 'text-primary' : 'text-gray_6',
              )}
            >
              {convertTime(playerSeekBar)}
            </span>

            <span
              class={clsx(
                `fa-format-500 text-[12px] lg-1920:text-f10  leading-[18px]  `,
                hasReelAdvertise ? 'text-primary' : 'text-gray_5',
              )}
            >
              {convertTime(duration)}
            </span>
          </div>
        )}
      </div>
    </>
  );
}

export default React.memo(ReelPlayerSeekbar);
