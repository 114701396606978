
import React,{ memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getInitialData, useUserData } from '../../helper/ReduxSelectorContext';
import CustomImage from '../common/CustomImage';
import PlanTimeViewShape from '../svg/plans/PlanTimeViewShape.jsx';
import { CardActionArea } from '@mui/material';
import UserPremiumSvg from '../../assets/plan/PlanPremiumSvg.svg';
import FreeAccountCrown from '../svg/plans/FreeAccountCrown.jsx';
import PremiumAccountCrown from '../svg/plans/PremiumAccountCrown.jsx';
import ProfileDetailCard from './ProfileDetailCard';
import vipSupportAvatar from '../../assets/myMelodify/vip-support.webp';
import noneVipSupportAvatar from '../../assets/myMelodify/nonevip-support.webp';
import TicketCardDetails from './TicketCardDetails';
import clsx from 'clsx';


const ProfileHeader = () => {
  // ANCHOR HOOK
  const navigate = useNavigate();
  const initialData = getInitialData();
  const user = useUserData();

  // ANCHOR HANDELER
  const handleClickSpecialUser = () => {
    navigate('/plans');
  };
  const handleClickSuport = () => {
    navigate('/tickets');
  };
  const textClassName =user?.premium_days_left>3?'text-gray_6':'text-red'

  return (
    <>
      <div onClick={handleClickSpecialUser} className='w-full px-4 pt-10 '>
        <div className='w-full h-[145px]   relative '>
          <div className='absolute top-[-24px] left-[50%] translate-x-[-50%]'>
            {
              user?.is_premium ?

                <PremiumAccountCrown/>
                :
                <FreeAccountCrown/>
            }
          </div>
          <div className='absolute w-full h-full'>
            {user?.is_premium?
              <div className='flex justify-between items-center h-full pr-4 '>
                <div className='flex flex-col gap-[6px] '>
                  <p className='flex items-center fa-format-400 text-white text-[18px] gap-1'>
                    شما کاربر
                    <span className='font-dana font-[700] text-primary'>
                  ویژه
                 </span>
                    هستید.
                  </p>
                  <div className='flex gap-[6px] items-center'>
                    <p className='text-[14px] font-[400] font-dana text-gray_6'>
                      اعتبار:
                    </p>
                    <div className='flex  gap-1 items-center'>
                      <p className={clsx(`text-[22px] font-[700] font-dana`,textClassName)}>
                        {user?.premium_days_left}
                      </p>
                      <p className='text-[14px] font-[700] font-dana text-gray_6'>
                        روز
                      </p>
                    </div>
                  </div>

                </div>
                <div className='mt-[-24px]'>
                  <CustomImage
                    alt={''}
                    className="h-[99px] w-[110px] "
                    src={UserPremiumSvg}
                    appendBaseUrl={false}
                  />
                </div>
              </div>

              :
              <div className='flex flex-col items-center justify-center gap-[10px] w-full h-full'>
                <p className='flex items-center fa-format-400 text-white text-[20px]'>
                  شما کاربر
                  <span className='font-dana font-[700]'>
                 عادی
                 </span>
                  هستید.
                </p>
                <p className='flex items-center fa-format-400 text-white text-[12px] drop-shadow-plan-free-user-shadow' >
                  برای دسترسی به همه امکانات دکمه خرید اشتراک را بزنید.
                </p>
              </div>
            }


          </div>
          <PlanTimeViewShape isPremium={user?.is_premium}/>
          <div className='flex bg-background rounded-[60px] p-[14px] absolute bottom-[-30px] left-[50%] translate-x-[-50%] '>
            <CardActionArea
              className={
                '!h-[40px] !rounded-[100px] !w-[168px] !overflow-hidden   !flex !justify-center  '
              }
              onClick={handleClickSpecialUser}

            >
              <button


                className="!w-[168px] bg-primary text-gray_1 font-[700] font-dana flex items-center justify-center text-[14px] !rounded-[100px] h-[40px]"
              >
                {user?.is_premium?

                  ' تمدید اشتراک'

                  :

                  ' خرید اشتراک'

                }
              </button>
            </CardActionArea>
          </div>
        </div>
      </div>
      <div className=" px-4 mt-8 pb-3 ">
        <TicketCardDetails
          avatar={user?.is_premium ? vipSupportAvatar : noneVipSupportAvatar}
          title={
            <span
              style={{
                color:
                initialData?.ticket_page?.my_melodify_ticket_title_color,
              }}
              className="text-xs font-dana font-medium"
            >
                {initialData?.ticket_page?.my_melodify_ticket_title}
              </span>
          }
          subtitle={
            <span
              style={{
                color:
                initialData?.ticket_page?.my_melodify_ticket_subtitle_color,
              }}
              className="text-[10px] font-dana font-medium"
            >
                {initialData?.ticket_page?.my_melodify_ticket_subtitle}
              </span>
          }
          className="border border-secondary"
          titleClassName={user?.is_premium ? '!text-primary' : '!text-gray_5'}
          badge={
            initialData?.unread_ticket_count === 0
              ? null
              : initialData?.unread_ticket_count
          }
          onClick={handleClickSuport}
        ></TicketCardDetails>
      </div>
    </>



  );
};

export default memo(ProfileHeader);

