import React from 'react';
import SongsDetailCardShimmer from "../Tickets/SongsDetailCardShimmer.jsx";

function UserPlaylistShimmerContainer(props) {
    return (
        <div className='flex flex-col gap-4 w-full' >
            {Array.from(
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                    <SongsDetailCardShimmer dir='ltr' reverse={true}/>
                ))
            )}
        </div>
    );
}

export default UserPlaylistShimmerContainer;
