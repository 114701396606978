import React from 'react';

import clsx from 'clsx';
import { CardActionArea } from '@mui/material';

export const IconWrapper = (props) => {
  const { children, onClick, containerScale, buttonPadding, hasRipple = true, ...rest } = props;
  const containerClass = clsx('!relative  !flex justify-center !items-center  ', containerScale);
  const buttonClass = clsx('!absolute  !box-content !rounded-full ', buttonPadding);
  const ButtonComponent = hasRipple ? CardActionArea : 'button';
  return (
    <div className={containerClass}>
      <ButtonComponent    onClick={onClick} className={buttonClass}>
        {children}
      </ButtonComponent>
    </div>
  );
};
