import React, { useState } from 'react';
import UserAvatar from '../common/UserAvatar';
import ClickReaction from '../../helper/ClickReaction';
import { CardActionArea } from '@mui/material';
import { setLanguageDirection } from '../../helper/LanguageDetection';
import { MeloPostListenCount } from './MeloPostListenCount';
import { Line } from '../common/Line';
import { MeloPostPlayer } from './MeloPostPlayer';
import { LikeMelopost } from './LikeMelopost.jsx';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const MeloPostFullCard = ({
  item,
  index,
  handlePlay,
  activePlayerIndex,
  handleOpenEdit,
  meloPostItems,
}) => {
  const navigate = useNavigate();
  const { hashtag } = useParams();
  const handleOpenMelopostOption = () => {
    if (handleOpenEdit) {
      handleOpenEdit(item);
    }
  };
  const handleNavigateToShare = () => {
    navigate('/melopost/share', { state: { melopostId: item?.id } });
  };
  const navigateToHashtag = hashtagTitle => {
    const newPath = `/meloposts-hashtag-list/${hashtagTitle}`;
    if (hashtag && hashtag === hashtagTitle) {
      return;
    }
    navigate(newPath);
  };
  return (
    <div
      dir="rtl"
      className="bg-primary_dark w-full  p-4  flex flex-col gap-[15px]"
    >
      {/* uerinfo */}
      <div className="flex flex-row justify-between items-center ">
        <div className=" flex flex-row gap-1 items-center cursor-pointer">
          <UserAvatar
            avatar={item?.social_profile?.avatar}
            is_premium={item?.is_premium}
            social_token={item?.social_profile?.token}
            className="w-[40px] h-[40px]  border border-gray_1 rounded-full "
          />
          <span className="text-white font-poppins font-medium text-xs">
            {item?.social_profile?.username}
          </span>
        </div>
        <ClickReaction>
          <CardActionArea onClick={handleOpenMelopostOption}>
            <span className="min-w-[20px]">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2113_68198)">
                  <path
                    d="M4.00003 18C4.00003 16.8954 3.10459 16 2.00001 16C0.895437 16 3.70219e-08 16.8954 2.38499e-08 18C1.0678e-08 19.1046 0.895436 20 2.00001 20C3.10459 20 4.00003 19.1046 4.00003 18Z"
                    fill="#F2F2F2"
                  />
                  <path
                    d="M4.00003 9.99999C4.00003 8.89541 3.10459 7.99997 2.00001 7.99997C0.895437 7.99997 3.70219e-08 8.89541 2.38499e-08 9.99999C1.0678e-08 11.1046 0.895436 12 2.00001 12C3.10459 12 4.00003 11.1046 4.00003 9.99999Z"
                    fill="#F2F2F2"
                  />
                  <path
                    d="M4.00003 1.99999C4.00003 0.895409 3.10459 -2.76195e-05 2.00001 -2.76327e-05C0.895437 -2.76459e-05 3.70219e-08 0.895409 2.38499e-08 1.99999C1.0678e-08 3.10456 0.895436 4 2.00001 4C3.10459 4 4.00003 3.10456 4.00003 1.99999Z"
                    fill="#F2F2F2"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2113_68198">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 20) rotate(-90)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>{' '}
          </CardActionArea>
        </ClickReaction>
      </div>

      {/* player */}
      <div className="bg-secondary p-4 rounded-lg gap-2 flex flex-col">
        <div className=" flex flex-row justify-between items-center">
          <div className=" flex flex-row gap-1 justify-end  items-center text-right">
            <div className="min-w-[12px] min-h-[12px]">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0201 7.09738C9.33719 6.41452 8.22607 6.41449 7.5432 7.09738L3.64891 10.9917C3.45042 11.1901 3.18654 11.2995 2.90584 11.2995C2.62515 11.2995 2.36129 11.1901 2.16279 10.9917L2.01341 10.8423C1.81494 10.6438 1.7056 10.3799 1.7056 10.0992C1.7056 9.81852 1.81494 9.55466 2.01341 9.35616L2.32995 9.03962L2.87994 9.58962L3.8996 8.65566L4.08198 8.83805L8.13912 5.12187C8.23314 5.13222 8.32826 5.13756 8.42421 5.13756C9.11035 5.13756 9.75541 4.87037 10.2406 4.38517C10.7257 3.90003 10.993 3.25494 10.993 2.5688C10.993 1.88267 10.7258 1.23758 10.2406 0.752436C9.75539 0.267198 9.11033 0 8.42421 0C7.7381 0 7.09299 0.267198 6.60784 0.752389C6.03501 1.32522 5.79021 2.10349 5.87266 2.85223L2.15497 6.91101L2.33735 7.09339L1.4034 8.11305L1.83458 8.54423L1.51801 8.86077C0.835142 9.54364 0.835142 10.6548 1.51801 11.3376L1.6674 11.487C1.99821 11.8178 2.43802 12 2.90582 12C3.37364 12 3.81345 11.8178 4.14426 11.487L8.03855 7.59273C8.44824 7.18304 9.11495 7.18299 9.52469 7.59273L9.59038 7.65842C9.78885 7.85692 9.89819 8.1208 9.89819 8.4015C9.89819 8.68219 9.78885 8.94605 9.59038 9.14454L8.71439 10.0205L9.20976 10.5159L10.0858 9.63992C10.7686 8.95705 10.7686 7.84592 10.0858 7.16305L10.0201 7.09738ZM9.74521 1.24776C10.0981 1.60062 10.2924 2.06977 10.2924 2.56876C10.2924 2.94889 10.1793 3.31153 9.96962 3.6188L7.37417 1.02335C7.68144 0.813642 8.04408 0.70057 8.42421 0.70057C8.9232 0.70057 9.39235 0.894885 9.74521 1.24776ZM6.87896 1.51891L9.47423 4.11417C9.16695 4.32387 8.80434 4.43695 8.42421 4.43695C8.34575 4.43695 8.26812 4.4316 8.19148 4.42212L6.57181 2.80244C6.51637 2.36001 6.61882 1.90156 6.87896 1.51891ZM6.25289 3.47429L7.51868 4.74006L4.10321 7.8685L3.12444 6.88974L6.25289 3.47429ZM3.40374 8.15978L2.90117 8.6201L2.37285 8.09177L2.83317 7.58921L3.40374 8.15978Z"
                  fill="#F2C94C"
                />
                <path
                  d="M5.50195 4.99414L5.99674 4.49935L6.49205 4.99465L5.99726 5.48944L5.50195 4.99414Z"
                  fill="#F2C94C"
                />
              </svg>
            </div>
            <span
              dir={setLanguageDirection(item?.title)}
              className=" text-gray_5 font-dana text-sm font-medium  line-clamp-1"
            >
              {item?.title}
            </span>
          </div>
          <MeloPostListenCount play_count={item?.play_count} />
        </div>
        <Line />
        <div dir="ltr">
          <MeloPostPlayer
            key={`waveform-full-${index}`}
            item={item}
            index={index}
            onPlay={handlePlay}
            activePlayerIndex={activePlayerIndex}
            hasDuration
            playlist={meloPostItems}
          />
        </div>
      </div>
      {item?.caption ? (
        <div className="w-full flex">
          <span className=" font-dana  text-sm text-white">
            {item?.caption}
          </span>
        </div>
      ) : null}
      {item?.hashtags?.length != 0 ? (
        <div className="w-full flex gap-1 flex-wrap">
          {item?.hashtags?.map(hash => (
            <span
              onClick={() => navigateToHashtag(hash?.title)}
              className=" font-dana  text-[10px] text-gray_4 "
            >
              {'#' + hash?.title}
            </span>
          ))}
        </div>
      ) : null}

      <div className="flex flex-col gap-3">
        <Line />
        <div className="flex flex-row justify-end w-full items-center gap-8">
          {/* likeCount */}

          <LikeMelopost id={item?.id} likeCount={item?.like_count} />
          {/* Share */}
          <div onClick={handleNavigateToShare}>
            <svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2113_66999)">
                <path
                  d="M13.8805 12.2326C13.0181 12.2326 12.2411 12.605 11.7018 13.1975L6.8512 10.1933C6.9807 9.86175 7.05252 9.50167 7.05252 9.12498C7.05252 8.74815 6.9807 8.38807 6.8512 8.0567L11.7018 5.05236C12.2411 5.64479 13.0181 6.01737 13.8805 6.01737C15.5051 6.01737 16.8268 4.69571 16.8268 3.07112C16.8268 1.44652 15.5051 0.125 13.8805 0.125C12.2559 0.125 10.9343 1.44665 10.9343 3.07125C10.9343 3.44795 11.0062 3.80802 11.1356 4.13953L6.28513 7.14374C5.74584 6.5513 4.96883 6.17873 4.10641 6.17873C2.48181 6.17873 1.16016 7.50052 1.16016 9.12498C1.16016 10.7496 2.48181 12.0712 4.10641 12.0712C4.96883 12.0712 5.74584 11.6988 6.28513 11.1062L11.1356 14.1104C11.0062 14.4419 10.9343 14.802 10.9343 15.1788C10.9343 16.8033 12.2559 18.125 13.8805 18.125C15.5051 18.125 16.8268 16.8033 16.8268 15.1788C16.8268 13.5542 15.5051 12.2326 13.8805 12.2326ZM12.0086 3.07125C12.0086 2.03909 12.8483 1.19932 13.8805 1.19932C14.9127 1.19932 15.7524 2.03909 15.7524 3.07125C15.7524 4.10342 14.9127 4.94318 13.8805 4.94318C12.8483 4.94318 12.0086 4.10342 12.0086 3.07125ZM4.10641 10.9969C3.07411 10.9969 2.23434 10.1571 2.23434 9.12498C2.23434 8.09282 3.07411 7.25305 4.10641 7.25305C5.13857 7.25305 5.9782 8.09282 5.9782 9.12498C5.9782 10.1571 5.13857 10.9969 4.10641 10.9969ZM12.0086 15.1787C12.0086 14.1465 12.8483 13.3068 13.8805 13.3068C14.9127 13.3068 15.7524 14.1465 15.7524 15.1787C15.7524 16.2109 14.9127 17.0506 13.8805 17.0506C12.8483 17.0506 12.0086 16.2109 12.0086 15.1787Z"
                  fill="#BDBDBD"
                />
              </g>
              <defs>
                <clipPath id="clip0_2113_66999">
                  <rect
                    width="18"
                    height="18"
                    fill="white"
                    transform="translate(0 0.125)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
