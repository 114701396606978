import React from 'react';

function GrayPauseIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className='w-full h-full'
      fill="none"
      viewBox="0 0 32 32"
    >
      <circle cx="16" cy="16" r="16" fill="#404040"></circle>
      <path
        fill="#F2C94C"
        d="M13.257 9c-.841 0-1.523.682-1.523 1.523v10.954a1.522 1.522 0 1 0 3.046 0V10.523c0-.841-.682-1.523-1.523-1.523M18.737 9c-.84 0-1.522.682-1.522 1.523v10.954a1.522 1.522 0 1 0 3.045 0V10.523c0-.841-.682-1.523-1.523-1.523"
      ></path>
    </svg>
  );
}

export default GrayPauseIcon;
