import { IMAGE_BASE_URL } from '../BASE.js';
import { getImageBaseUrl } from '../redux/globalstore/GlobalStore.js';

export function parseDynamicObject(obj) {
  try {
    // Attempt to parse the object as JSON
    const parsedObj = JSON.parse(obj);

    // If successful, return the parsed object
    return parsedObj;
  } catch (error) {
    // If parsing as JSON fails, return the original string
    return obj;
  }
}

// export const appendBaseUrl = url => {
//   if (url?.trim()?.startsWith(IMAGE_BASE_URL)) {
//     return url;
//   } else {
//     return IMAGE_BASE_URL + url;
//   }
// };
export const appendBaseUrl = url => {
  if (url?.startsWith("https://") || url?.startsWith("http://"))
    return url;
  else {
    return getImageBaseUrl() + url
  }
};

export function formatTextWithStyles(text, decorators) {
  let formattedText = text;

  decorators?.forEach(decorator => {
    const { text: decoratorText, ...styles } = decorator;
    const regex = new RegExp(
      decoratorText?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
      'g',
    );
    formattedText = formattedText.replace(
      regex,
      `<span style="${stylesToString(styles)}">${decoratorText}</span>`,
    );
  });

  formattedText = formattedText?.replace(/\n/g, '<br />');
  formattedText = formattedText?.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');

  return formattedText;
}

function stylesToString(styles) {
  let style = {
    ...styles,
    font_size: styles.font_size + 'rem',
    font_weight:
      styles.bold !== null ? (styles.bold === true ? 'bold' : '400') : '',
  };
  return Object.entries(style)
    .map(([key, value]) =>
      key !== 'bold' ? `${key.replace(/_/g, '-')}:${value}` : null,
    )
    .join(';');
}



export function convertAndroidARGBtoWebRGBA(androidARGB) {
  if (androidARGB.charAt(0) !== '#') {
    androidARGB = '#' + androidARGB;
  }

  const alpha = androidARGB.substring(1, 3);
  const red = androidARGB.substring(3, 5);
  const green = androidARGB.substring(5, 7);
  const blue = androidARGB.substring(7, 9);

  return `#${red}${green}${blue}${alpha}`;
}
