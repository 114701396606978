import React from 'react';
import CustomLazyLoadImage from '../../common/CustomLazyLoadImage';
import TextMarquee from '../../common/TextMarquee';
import { setLanguageDirection } from '../../../helper/LanguageDetection';

export const ReelTrackInfo = ({ item }) => {
  console.log('Asfasfasfasf', item);
  
  return (
    <div className="flex flex-row gap-2  w-[calc(100%-125px)] overflow-hidden">
      <div class=" relative  shadow-playlist-shadow rounded-[5px]   overflow-hidden min-h-[56px] min-w-[56px]  w-[56px] h-[56px] ">
        <CustomLazyLoadImage image={item?.track?.image} imageClass="!object-cover " />
      </div>
      <div className="flex w-full  flex-col justify-center gap-1 h-full items-center">
        <div class="font-dana w-full font-[700]  text-[18px]  text-white leading-6 overflow-hidden flex flex-row gap-2 items-center">
          <div dir={'ltr'} className="w-full">
            <TextMarquee
              noMarqueeParentStyle={'justify-start'}
              direction={'rtl'}
              key={item?.track?.id + 'reels-track-marquee'}
            >
              {item?.track?.title}
            </TextMarquee>
          </div>
        </div>
        <div class=" w-full flex flex-row  items-center gap-2 ">
          <div
            dir={'ltr'}
            className="font-dana font-[400]   text-[12px] w-full  text-gray_5 "
          >
            <TextMarquee
              noMarqueeParentStyle={'justify-start'}
              direction={'rtl'}
              key={item?.track?.id + 'reels-track-marquee'}
            >
              {item?.track?.artists?.map((artist, index) => {
                return (
                  <span key={index}>
                    {artist?.name}{' '}
                    {index !== item?.track?.artists?.length - 1 &&
                      (setLanguageDirection(item?.track?.title) === 'rtl'
                        ? '، '
                        : ', ')}
                  </span>
                );
              })}
            </TextMarquee>
          </div>
        </div>
      </div>
    </div>
  );
};
