import React, { useEffect, useState } from 'react';
import SwipeBottomSheet from '../../bottomSheets/SwipeBottomSheet';
import BottomSheetHeader from '../../bottomSheets/BottomSheetHeader';
import { CardActionArea } from '@mui/material';
import { ToggleButton } from '../../common/ToggleButton';
import {
  PROFILE_OPTIONS,
  USER_PROFILE_STATUS,
} from '../../../consts/UserProfileStatus';
import { PublicProfileDialog } from './ProfileStauts/PublicProfileDialog';
import { PrivateProfileDialog } from './ProfileStauts/PrivateProfileDialog';
import { useNavigate } from 'react-router-dom';
import { useSocialProfile } from '../../../hooks/useSocialProfile';
import InfoIcon from '../../svg/artists/InfoIcon';
import { useNativeBack } from '../../../hooks/useNativeBack';
import { SOCIAL_REPORT_TYPES } from '../../../consts/SocialReportTypes.js';

export const ProfileOptionsBottomSheet = ({
  open,
  setOpen,
  data,
  refetchData,
}) => {
  const { isUserSocialProfile } = useSocialProfile(data?.social_profile?.token);
  const [openPublicProfileDialog, setOpenPublicProfileDialog] = useState(false);
  const [openPrivateProfileDialog, setOpenPrivateProfileDialog] =
    useState(false);
  const [toggleAccountStatus, setToggleAccountStatus] = useState(
    data?.is_private ? USER_PROFILE_STATUS.Private : USER_PROFILE_STATUS.PUBLIC,
  );
  const navigate = useNavigate();

  const handleCloseBottomSheet = () => {
    setOpen(false);
  };

  const toggleBottomSheet = newOpen => () => {
    setOpen(newOpen);
  };

  const handleChangeProfileStatus = stauts => {
    if (stauts === USER_PROFILE_STATUS.PUBLIC) {
      setOpenPublicProfileDialog(true);
    } else {
      setOpenPrivateProfileDialog(true);
    }
    handleCloseBottomSheet();
  };
  useEffect(() => {
    setToggleAccountStatus(
      data?.is_private
        ? USER_PROFILE_STATUS.Private
        : USER_PROFILE_STATUS.PUBLIC,
    );
  }, [data]);

  const handleClickOptions = item => {
    if (item?.id === 1) {
      navigate(`/profile/edit`, { state: { data: data } });
    } else if (item?.id == 2) {
      handleChangeProfileStatus(
        !data?.is_private
          ? USER_PROFILE_STATUS.Private
          : USER_PROFILE_STATUS.PUBLIC,
      );
    } else if (item?.id == 3) {
      navigate('/tickets');
    }
    handleCloseBottomSheet();
  };

  const handleReportProfile = () => {
    navigate('/melopost-report',{state:{modelToken:data?.social_profile?.token,modelType:SOCIAL_REPORT_TYPES.SOCIAL_PROFILE}})
    handleCloseBottomSheet();
  };
  useNativeBack(open, 'profile-options', handleCloseBottomSheet);
  return (
    <>
      <PublicProfileDialog
        open={openPublicProfileDialog}
        setOpen={setOpenPublicProfileDialog}
        refetchData={refetchData}
      />

      <PrivateProfileDialog
        open={openPrivateProfileDialog}
        setOpen={setOpenPrivateProfileDialog}
        refetchData={refetchData}
      />
      <SwipeBottomSheet
        keepMount={false}
        open={open}
        toggleDrawer={toggleBottomSheet}
      >
        <BottomSheetHeader
          dividerLine={true}
          closeBottomSheet={handleCloseBottomSheet}
          title="گزینه ها"
        />

        <div dir="rtl" className=" w-full h-full flex flex-col">
          {isUserSocialProfile ? (
            PROFILE_OPTIONS?.map(item => (
              <CardActionArea
                onClick={() => handleClickOptions(item)}
                key={item?.id}
                className="!p-4"
              >
                <div className=" flex flex-row justify-between items-center gap-2">
                  <div className=" flex flex-row justify-between items-center gap-2">
                    {item?.icon}
                    <span className="font-dana font-medium text-sm text-white">
                      {item?.title}
                    </span>
                  </div>
                  {item?.id == 2 ? (
                    <div className=" relative w-[108px] h-[24px]">
                      <ToggleButton
                        // setStatus={handleChangeProfileStatus}
                        status={toggleAccountStatus}
                      />
                    </div>
                  ) : null}
                </div>
              </CardActionArea>
            ))
          ) : (
            <CardActionArea
              onClick={handleReportProfile}
              key={'report Item'}
              className="!p-4  !mb-6"
            >
              <div className=" flex flex-row justify-between items-center gap-2">
                <div className=" flex flex-row justify-between items-center gap-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 20C4.47293 20 0 15.5275 0 10C0 4.47293 4.47254 0 10 0C15.5271 0 20 4.47254 20 10C20 15.5271 15.5275 20 10 20ZM10 1.5625C5.33652 1.5625 1.5625 5.33621 1.5625 10C1.5625 14.6635 5.33621 18.4375 10 18.4375C14.6635 18.4375 18.4375 14.6638 18.4375 10C18.4375 5.33652 14.6638 1.5625 10 1.5625ZM10.7812 10.8465V5.81551C10.7812 5.38402 10.4315 5.03426 10 5.03426C9.56852 5.03426 9.21875 5.38402 9.21875 5.81551V10.8465C9.21875 11.278 9.56852 11.6277 10 11.6277C10.4315 11.6277 10.7812 11.278 10.7812 10.8465ZM10 12.5843C9.4175 12.5843 8.94531 13.0565 8.94531 13.639C8.94531 14.2215 9.4175 14.6937 10 14.6937C10.5825 14.6937 11.0547 14.2215 11.0547 13.639C11.0547 13.0565 10.5825 12.5843 10 12.5843Z"
                      fill="#EB5757"
                    />
                  </svg>

                  <span className="font-dana font-medium text-sm text-red">
                    گزارش خطا
                  </span>
                </div>
              </div>
            </CardActionArea>
          )}
        </div>
      </SwipeBottomSheet>
    </>
  );
};
