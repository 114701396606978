import { CardActionArea } from '@mui/material';
import React from 'react';
import CustomImage from '../../common/CustomImage';
import { BoldText } from '../../svg/common/BoldText';
import UserAvatar from '../../common/UserAvatar';
import ClickReaction from '../../../helper/ClickReaction';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  acceptFollowRequest,
  rejectFollowRequest,
} from '../../../services/ApiClient';
import { NoteMusicLoadingContainer } from '../../loading/NoteMusicLoadingContainer';

export const FollowRequestViewType = ({ item, callBack }) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(data => acceptFollowRequest(data), {
    onSuccess: data => {
      queryClient.invalidateQueries(['getInAppNotificationsQuery']);
      callBack();
    },
  });

  const { mutate: rejectMutate, isLoading: rejectIsLoading } = useMutation(
    data => rejectFollowRequest(data),
    {
      onSuccess: data => {
        queryClient.invalidateQueries(['getInAppNotificationsQuery']);
        callBack();
      },
    },
  );

  const handleAcceptFollowRequest = () => {
    mutate(item?.token);
  };

  const handleRejectFollowRequest = () => {
    rejectMutate(item?.token);
  };
  return (
    <div
      dir="rtl"
      className="bg-notifiy-granient w-full h-[40px] flex flex-row  justify-between items-center "
    >
      {isLoading || rejectIsLoading ? <NoteMusicLoadingContainer /> : null}
      <div className="flex flex-row justify-start gap-1 items-center">
        <div className="w-[34px] h-[34px] border border-gray_2 rounded-full flex justify-center items-center">
          <UserAvatar
            avatar={item?.social_profiles?.[0]?.avatar}
            is_premium={item?.social_profiles?.[0]?.is_premium}
            social_token={item?.social_profiles?.[0]?.token}
          />
        </div>
        <div className=" flex flex-row items-center gap-1 flex-wrap">
          <div className="text-gray_4 font-dana text-xs ">
            {item?.bold ? (
              <BoldText keyword={item?.bold} text={item?.message} />
            ) : (
              item?.message
            )}
          </div>
        </div>
      </div>
      <div className=" flex flex-row gap-1 h-full">
        <ClickReaction>
          <div
            onClick={handleAcceptFollowRequest}
            className=" w-[58px] cursor-pointer h-full  bg-primary rounded-md  text-gray_1 font-medium text-[10px] font-dana flex flex-row justify-center items-center"
          >
            قبول
          </div>
        </ClickReaction>

        <ClickReaction>
          <div
            onClick={handleRejectFollowRequest}
            className=" w-[58px] cursor-pointer h-full border border-primary rounded-md bg-transparent text-primary font-medium text-[10px] font-dana flex flex-row justify-center items-center"
          >
            رد
          </div>
        </ClickReaction>
      </div>
    </div>
  );
};
