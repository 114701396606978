import React from 'react';

function TrashType2Svg({style,stroke='#fff'}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="22"
            fill="none"
            viewBox="0 0 20 22"
            className={style}
        >
            <path
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.7"
                d="M1 5h2m0 0h16M3 5v14a2 2 0 002 2h10a2 2 0 002-2V5H3zm3 0V3a2 2 0 012-2h4a2 2 0 012 2v2m-6 5v6m4-6v6"
            ></path>
        </svg>
    );
}

export default TrashType2Svg;
