import HomeTrackCollection from '../components/home/HomeTrackCollection';
import HomePlayList from '../components/home/HomePlayList';
import HomeSectionOne from '../components/home/HomeSectionOne';
import HomeCustomBanner from '../components/home/HomeCustomBanner';
import HomeArtistsView from '../components/home/HomeArtistsView';
import HomeCollectionWithCorner from '../components/home/HomeCollectionWithCorner';
import { Suspense, lazy } from 'react';
import HomeExclusiveTracksSection from '../components/home/HomeExclusiveTracksSection';
import HomeGeneralCollectionSection from '../components/home/HomeGeneralCollectionSection.jsx';
import { HOME_TYPES } from '../types/HomeTypes.js';
import { GapSection } from '../components/common/GapSection.jsx';
import { ATitle } from '../components/common/ATitle.jsx';
import { BTitile } from '../components/common/BTitile.jsx';
import { CTtitle } from '../components/common/CTtitle.jsx';
import { HomeUserActivities } from '../components/home/HomeUserActivities.jsx';
import HomeVerticalTracks from '../components/home/HomeVerticalTracks.jsx';
import { HomeVerticalTracksContainer } from '../components/home/HomeVerticalTracksContainer.jsx';
import { DTitle } from '../components/common/DTitle.jsx';
import { ETitle } from '../components/common/ETitle.jsx';
import { CollectionCategoryContainer } from '../pages/playList/CollectionCategoryContainer.jsx';
import { ReelsContainer } from '../components/reels/ReelsContainer.jsx';
import { HomeMelopostContainer } from '../components/common/HomeMelopostContainer.jsx';
import { HomeUserPlaylistContainer } from '../components/home/HomeUserPlaylistContainer.jsx';
import BigHorizontalHomeCollection from '../components/playlist/BigHorizontalHomeCollection.jsx';

export const HomeItem = {
  [HOME_TYPES.BANNERS]: {
    component: item => <HomeSectionOne item={item} />,
  },
  [HOME_TYPES.GAP]: {
    component: item => <GapSection item={item} />,
  },

  [HOME_TYPES.ARTISTS]: {
    component: item => <HomeArtistsView item={item} />,
  },
  [HOME_TYPES.A_TITLE]: {
    component: item => <ATitle item={item} />,
  },
  [HOME_TYPES.B_TITLE]: {
    component: item => <BTitile item={item} />,
  },
  [HOME_TYPES.C_TITLE]: {
    component: item => <CTtitle item={item} />,
  },
  [HOME_TYPES.HORIZONTAL_TRACKS]: {
    component: item => <HomeTrackCollection item={item} />,
  },
  [HOME_TYPES.EXCLUSIVE_TRACKS]: {
    component: item => <HomeExclusiveTracksSection item={item} />,
  },
  [HOME_TYPES.HORIZONTAL_COLLECTIONS]: {
    component: item => (
      <HomeCollectionWithCorner
        cardStyle={`!w-[130px] !h-[130px]`}
        item={item}
      />
    ),
  },
  [HOME_TYPES.HORIZONTAL_COLLECTION_CATEGORIES]: {
    component: item => <HomeGeneralCollectionSection item={item} />,
  },
  [HOME_TYPES.USER_ACTIVITIES]: {
    component: item => <HomeUserActivities item={item} />,
  },
  [HOME_TYPES.VERTICAL_TRACKS_WITH_DURATION]: {
    component: item => <HomeVerticalTracksContainer item={item} />,
  },
  [HOME_TYPES.D_TITLE]: {
    component: item => <DTitle item={item} />,
  },
  [HOME_TYPES.E_TITLE]: {
    component: item => <ETitle item={item} />,
  },
  [HOME_TYPES.ROOT_COLLECTION_CATEGORIES]: {
    component: item => <CollectionCategoryContainer item={item} />,
  },
  [HOME_TYPES.HORIZONTAL_REELS]: {
    component: item => <ReelsContainer item={item} />,
  },
  [HOME_TYPES.HORIZONTAL_MELOPOSTS]: {
    component: item => <HomeMelopostContainer item={item} />,
  },
  [HOME_TYPES.HORIZONTAL_USER_COLLECTIONS]: {
    component: item => (
      <HomeUserPlaylistContainer
        cardStyle={`!w-[130px] !h-[130px]`}
        item={item}
      />
    ),
  },
  [HOME_TYPES.BIG_HORIZONTAL_COLLECTION_CATEGORIES]: {
    component: item => <BigHorizontalHomeCollection item={item} />
  },
  // general_collections: {
  //   component: item => (
  // <HomeGeneralCollectionSection
  //   cardStyle={`!w-[120px] !h-[120px]`}
  //   item={item}
  // />
  //   ),
  // },

  // collection_category: {
  //   component: item => <HomePlayList item={item} />,
  // },

  // track: {
  //   component: item => <HomeTrackCollection item={item} />,
  // },

  // exclusive_tracks: {
  //   component: item => <HomeExclusiveTracksSection item={item} />,
  // },

  // custom_banner: {
  //   component: item => <HomeCustomBanner item={item} />,
  // },

  // collection: {
  //   component: item => (
  //     <HomeCollectionWithCorner
  //       cardStyle={`!w-[120px] !h-[120px]`}
  //       item={item}
  //     />
  //   ),
  // },
};
