import React from 'react';
import MusicNoteLoading from '../loading/MusicNoteLoading.jsx';
import clsx from 'clsx';

export const FullPageLoader = ({ className}) => {
  return (
    <div className={clsx('fixed flex justify-center items-center w-full h-full bg-primary_dark bg-opacity-50  left-0 top-0 z-10',className)}>
      <div className="h-[120px]  w-[120px] left-0 top-0 mt-[56px]">
        <MusicNoteLoading />
      </div>
    </div>
  );
};

