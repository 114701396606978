import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { CardActionArea } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  selectDisableDownloadStatus,
  setDisableDownloadDialogStatus,
} from '../../redux/dialog/DialogSlice';
import { useDispatch } from 'react-redux';
import DialogHeader from './DialogHeader';
import { setSwipePlayerStatus } from '../../redux/player/PlayMusicSlice';
import { getSwipePlayerStatus } from '../../helper/ReduxSelectorContext';
import DownloadSvg from '../svg/common/DownloadSvg';

function DisableDownloadDialog() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const playerSwipeStatus = getSwipePlayerStatus();

  const dialogIsOpen = useSelector(selectDisableDownloadStatus);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (dialogIsOpen) setOpen(true);
  }, [dialogIsOpen]);
  useEffect(() => {
    handleCloseDialog();
  }, [location]);

  const handleCloseDialog = useCallback(() => {
    setOpen(false);
    dispatch(setDisableDownloadDialogStatus({ disableDownloadStatus: false }));
  }, []);
  const goToPanelRoute = useCallback(() => {
    handleCloseDialog();
    dispatch(setSwipePlayerStatus({ status: false }));
    navigate('/plans', {
      state: { openPlayerWhenBack: playerSwipeStatus },
    });
  }, [playerSwipeStatus]);

  return (
    <Transition appear show={open} as={Fragment} onClose={handleCloseDialog}>
      <Dialog as="div" className="relative z-[99]">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 " />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center font-dana">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className=" w-[80vw] max-w-md  shadow-dialog-Fragmentshadow  transform overflow-hidden rounded-[20px]  bg-primary_dark  text-left align-middle shadow-xl transition-all"
              >
                <DialogHeader
                  header_text="محدودیت دانلود (کاربران عادی)"
                  onCloseDialog={handleCloseDialog}
                />
                <div className="flex flex-col justify-center gap-8 mt-8">
                  <div className="flex w-full justify-center">
                    <div className="w-8 h-8">
                      <DownloadSvg fill="#A7A7A9" />
                    </div>
                  </div>
                  <div className="flex flex-col w-full justify-center">
                    <p className="text-center text-base text-white font-medium leading-6 font-dana">
                      برای کاربران عادی این آهنگ فقط قابلیت پخش دارد.
                    </p>

                    <p className="text-center text-base text-white font-medium leading-6 font-dana">
                      برای دانلود این آهنگ روی خرید اشتراک بزنید.
                    </p>
                  </div>
                  <CardActionArea
                    onClick={goToPanelRoute}
                    className="!p-1 !rounded-3xl !bg-primary !w-full !h-11 !flex !justify-center !items-center !text-base !text-title_box !font-medium font-dana text-center "
                  >
                    خرید اشتراک
                  </CardActionArea>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default DisableDownloadDialog;
