import React from 'react';

function DragDropSvg(props) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="8"
        fill="none"
        viewBox="0 0 20 8"
      >
          <path fill="#828282" d="M0 0h20v2H0zM0 6h20v2H0z"></path>
      </svg>
    );
}

export default DragDropSvg;
