import React, {useEffect, useRef} from 'react';
import Container from "../../components/PageParentContainer/Container.jsx";
import CollectionCategoryHeader from "../../components/playlist/CollectionCategoryHeader.jsx";
import ScrollTopButton from "../../components/common/ScrollTopButton.jsx";
import PlaylistUpdateDialog from "../../components/playlist/PlaylistUpdateDialog.jsx";
import {useLocation} from "react-router-dom";
import AddToPlaylistModeButton from "../../components/playlist/AddToPlaylistModeButton";
import {addToPlaylistData} from "../../helper/ReduxSelectorContext.js";
import {disableListSelectMode} from "../../redux/adToPlaylist/AddToPlaylistSlice.js";
import {useDispatch} from "react-redux";
import AbsoluteBackground from "../../components/common/AbsoluteBackground.jsx";
import HomeSlider from '../../components/home/HomeSlider.jsx';
import { RenderExclusiveTracksList } from '../../components/Exclusive/RenderExclusiveTracksList';

function ExclusiveTracks(props) {
    const location = useLocation();
    const dispatch = useDispatch()
    const trackTypes = location.state?.trackTypes;
    const pageData = location.state?.pageData;
    const addToPlaylistDataState = addToPlaylistData()
    const dialogRef = useRef(null);


    useEffect(() => {
        return () => {
            dispatch(disableListSelectMode())
        };
    }, []);

    return (
        <Container style="bg-primary_dark !overflow-hidden">
            <CollectionCategoryHeader hasSearch={false} title={pageData?.title}
                                      backgroundId='absolute-background'
                                      pageId='exclusive-playlist-song'/>
            <ScrollTopButton id={"exclusive-playlist-song"}/>
            <div id="exclusive-playlist-song" className=" relative overflow-scroll h-full hide-scroll-bar  ">
                <AddToPlaylistModeButton EnableEditMode={addToPlaylistDataState?.listSelectItems?.length > 0}/>
                <PlaylistUpdateDialog ref={dialogRef}/>
                <div
                    id="collection-page-exclusive"
                    dir="rtl"
                    className="w-full h-full  relative z-[20]  ">
                    <div id='playlist-songs-background'
                         className=' w-full aspect-square relative  overflow-hidden flex justify-center items-start   '>


                        <p className="font-kalameh font-[600] text-[18px] text-white relative mt-[19%] ">
                            {pageData?.title}
                        </p>
                        <div className='w-full absolute  bottom-[24%] px-2'>
                            <HomeSlider
                                        heightRelation={pageData?.banner_setting?.height_relation}
                                        plan={true}
                                        data={pageData?.banners}
                                        metaData={{auto_slide_time: 5}}
                                        slideItemContainerClass={'w-full'}
                                        slideItemContentClass={'rounded-[10px] '}
                                        slideItemActionAreaClass={'!rounded-[10px]'}
                            />

                        </div>

                    </div>

                    <RenderExclusiveTracksList  trackTypes={trackTypes} />
                </div>
            </div>
            <AbsoluteBackground
                image={pageData?.background}
                animation={true}
            ></AbsoluteBackground>
        </Container>
    );
}

export default ExclusiveTracks;
