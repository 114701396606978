import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  getCategoryCollections,
  getSectionCollections,
} from "../../services/ApiClient.js";
import RenderCollectionsPage from "../../components/collections/RenderCollectionsPage.jsx";
import { useScrollPosition } from '../../hooks/useScrollPosition.js';

function CollectionCategory() {
  //todo:background for items
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const title = searchParams.get("title");
  let itemData = location?.state?.categoryData;
  const subtitle = location?.state?.subtitle;
  const dataObject = location?.state?.dataObject;
  const pageTitleText = dataObject?.header
    ? dataObject?.header
    : dataObject?.title;
  const makeRequest = (data) => {
    console.log(dataObject, "data object ...");
    if (dataObject?.id) {
      return getCategoryCollections(data);
    }
    if (dataObject?.type) {
      return getSectionCollections(data);
    }
  };
  const requestData = () => {
    if (dataObject?.id) {
      return {
        requestData: {
          collection_category_id: dataObject?.id,
        },
        requestKey: `getCollectionInfiniteQuery${dataObject?.id}`,
      };
    } else if (dataObject?.type) {
      return {
        requestData: {
          type: dataObject?.type,
        },
        requestKey: `getCollectionInfiniteQuery${dataObject?.type}`,
      };
    }
  };
  const navigate = useNavigate();
  const openCollection = (item) => {
    navigate(`/playlist-songs/${item?.id}`, {
      state: { collectionObject: item },
    });
  };
  useScrollPosition({pageId:'collection-category-page'})
  return (
    <>
      <RenderCollectionsPage apiDataKey={'collections'} pageParams={location?.state?.categoryData?.length??0} requestData={requestData().requestData} requestKey={requestData()?.requestKey} itemData={itemData} pageId={'collection-category-page'} image={dataObject?.background} title={title} pageTitleText={pageTitleText} apiFunction={makeRequest} openCollection={openCollection} subtitle={subtitle}/>
    </>
  );
}

export default CollectionCategory;
