import React from 'react';
import UserAvatar from '../../common/UserAvatar';
import ChevronNavigateBack from '../../common/ChevronNavigateBack';
import { CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const FollowingCartItem = ({ item }) => {
  const navigate=useNavigate()
  console.log('ASfasfsafasfsa', item);

  const handleNavigate=()=>{
    navigate(`/profile/${item?.token}`)
  }
  return (
    <CardActionArea onClick={handleNavigate}>
      <div className="w-full flex flex-row item-center justify-between gap-2 px-6 py-1  ">
        <div className="flex w-full flex-row justify-start items-center h-full gap-1">
          <UserAvatar
            avatar={item?.avatar}
            is_premium={item?.is_premium}
            social_token={item?.social_token}
            className="w-[40px] h-[40px]  border border-gray_1 rounded-full "
          />
          <span className=" text-sm font-dana text-white font-medium ">
            {item?.name}
          </span>
        </div>
        <ChevronNavigateBack />
      </div>
    </CardActionArea>
  );
};
