import React from 'react'

export const UserPlayListSearchIcon = () => {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5962_88390)">
        <path
          d="M6.81102 12.0458H1.61227C1.39175 12.0458 1.2588 11.9242 1.19908 11.8517C1.09608 11.7265 1.05624 11.5636 1.08973 11.4046C1.62609 8.85852 3.89978 6.99929 6.52462 6.93897C6.5682 6.94057 6.61198 6.94167 6.65607 6.94167C8.59262 6.94167 10.1682 5.3847 10.1682 3.47083C10.1682 1.55697 8.59262 0 6.65607 0C4.71941 0 3.1439 1.55697 3.1439 3.47083C3.1439 4.60671 3.69893 5.61698 4.55508 6.25051C3.77076 6.50562 3.03465 6.90249 2.39055 7.42606C1.20957 8.38609 0.388424 9.72513 0.0784259 11.1966C-0.0187195 11.6576 0.0976936 12.1312 0.397704 12.4958C0.696202 12.8586 1.13895 13.0667 1.61227 13.0667H6.81102C7.0962 13.0667 7.32751 12.8382 7.32751 12.5562C7.32751 12.2743 7.0962 12.0458 6.81102 12.0458ZM4.17689 3.47083C4.17689 2.11992 5.28897 1.02083 6.65607 1.02083C8.02306 1.02083 9.13524 2.11992 9.13524 3.47083C9.13524 4.8024 8.05474 5.88923 6.71468 5.92014C6.69541 5.91794 6.67594 5.91655 6.65607 5.91655C6.61572 5.91655 6.57547 5.91714 6.53522 5.91784C5.22411 5.85544 4.17689 4.78177 4.17689 3.47083Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.2127 3.07567C15.1814 3.59958 15.7834 4.59988 15.7834 5.68628V6.9705C15.7834 7.30175 16.0557 7.57049 16.3917 7.57049C16.7277 7.57049 17 7.30175 17 6.9705V5.68628C17 4.16145 16.156 2.75802 14.7974 2.02333L13.0424 1.07443C12.6379 0.855839 12.1418 1.1449 12.1418 1.60052V8.22002H10.9231C9.58703 8.22002 8.5 9.2922 8.5 10.61C8.5 11.9038 9.55789 13 10.9353 13C12.2714 13 13.3584 11.9278 13.3584 10.61V2.61364L14.2127 3.07567ZM14.5568 2.46251L12.8018 1.51361L14.5568 2.46251ZM12.1416 9.42002V10.61C12.1416 11.3493 11.8358 11.7993 10.9231 11.8C10.1679 11.7993 9.75092 11.3493 9.71662 10.61C9.75092 9.99941 10.1679 9.54944 10.9231 9.42002L12.1416 9.42002Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5962_88390">
          <rect width="17" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

