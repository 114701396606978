import React from 'react';
import CloseBottomSheetIconSvg from '../svg/common/CloseBottomSheetIconSvg.jsx';
import { Icon, IconButton } from '@mui/material';

function BottomSheetHeader({
  title,
  hasCloseButton = true,
  closeBottomSheet,
  HeaderIcon,
  dividerLine,
  titleElement

}) {
  return (
    <div
      onClick={e => e.stopPropagation()}
      className="flex flex-col  w-full justify-center bg-secondary"
    >
      <div
        dir="rtl"
        className="flex w-full justify-between items-center px-4 py-4"
      >
        {
          Boolean(titleElement)?titleElement:
            <div className="flex gap-4 items-center">
              {HeaderIcon && HeaderIcon}

              <p
                className={`text-primary text-base font-dana font-medium  leading-6  pb-0 ${
                  HeaderIcon && 'pr-0'
                }`}
              >
                {title}
              </p>
            </div>

        }
        {hasCloseButton && (
          <div onClick={closeBottomSheet} className=" relative">
            <IconButton className="!p-4 !-m-4">
              <Icon className="!w-[16px] !h-[16px] ">
                <CloseBottomSheetIconSvg />
              </Icon>
            </IconButton>
          </div>
        )}
      </div>
      {dividerLine && (
        <div className="flex w-[calc(100%_-_32px)] h-[1.5px] bg-secondary mx-auto"></div>
      )}
    </div>
  );
}

export default BottomSheetHeader;
