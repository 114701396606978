import React from 'react';
import CommonHeader from '../../../components/common/CommonHeader.jsx';
import Container from '../../../components/PageParentContainer/Container.jsx';
import { useQuery } from '@tanstack/react-query';
import { getUserCollectionStatistics } from '../../../services/ApiClient.js';
import { useLocation } from 'react-router-dom';
import FullPageNoteLoading from '../../../components/loading/FullPageNoteLoading.jsx';
import PlaceHolderWithTitle from '../../../components/common/PlaceHolderWithTitle.jsx';
import CustomLazyLoadImage from '../../../components/common/CustomLazyLoadImage.jsx';
import {
  UserPlaylistStatisticsInfoSection
} from '../../../components/userPlaylist/UserPlaylistStatisticsInfoSection.jsx';

function UserPlaylistStatistics(props) {
  const location = useLocation();

  const collectionData = location?.state?.collectionData;
  const userCollectionId = collectionData?.id;

  const getUserCollectionStatisticsQuery = useQuery(
    ['getUserCollectionStatisticsQuery' + userCollectionId],
    () => getUserCollectionStatistics(userCollectionId),
    {
      onSuccess: res => {},
      refetchOnWindowFocus: false,
    },
  );
  const collectionStatistics = getUserCollectionStatisticsQuery?.data?.data?.result?.user_collection_statistic
  return (
    <Container
      style="p-4"
      containerStyle={{ background: collectionData?.background_color }}
      shadow={false}
      header={<CommonHeader  title="آمار پلی‌لیست" style={{background: collectionData?.background_color + '33'}}   />}
    >
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-background bg-opacity-80 "></div>
      {getUserCollectionStatisticsQuery?.isLoading ? (
        <FullPageNoteLoading />
      ) : (
        <div dir='rtl' className="flex flex-col gap-6 w-full items-center relative pt-4">
          <div className="w-[180px] min-w-[180px] h-[180px] min-h-[180px] rounded-[10px] overflow-hidden relative">
            <span className="relative top-0 left-0 w-full bottom-0 h-full !p-0 !m-0  flex justify-center items-center">
              <PlaceHolderWithTitle style={'!rounded-[5px]'} title={''} />
            </span>
            <CustomLazyLoadImage image={collectionStatistics?.image} />
          </div>
          <UserPlaylistStatisticsInfoSection collectionStatistics={collectionStatistics}/>
        </div>
      )}
    </Container>
  );
}

export default UserPlaylistStatistics;
