import React, { useEffect, useState } from 'react';
import CommonHeader from '../../../components/common/CommonHeader.jsx';
import Container from '../../../components/PageParentContainer/Container.jsx';
import PlusIconSvg from '../../../components/svg/common/PlusIconSvg.jsx';
import SearchHashtags from '../../../components/meloPost/hashtag/SearchHashtags';
import { SuggestedHashtagsList } from '../../../components/meloPost/hashtag/SuggestedHashtagsList.jsx';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getSuggestedHashtags, searchHashtags, sendReport } from '../../../services/ApiClient.js';
import { ShowSelectedHashtag } from '../../../components/meloPost/hashtag/ShowSelectedHashtag.jsx';
import { CreateNewHashtag } from '../../../components/meloPost/hashtag/CreateNewHashtag.jsx';
import CreateHashtagLimitationDialog from '../../../components/meloPost/hashtag/CreateHashtagLimitationDialog.jsx';
import FullPageNoteLoading from '../../../components/loading/FullPageNoteLoading.jsx';
import MusicNoteLoading from '../../../components/loading/MusicNoteLoading.jsx';
import { mainToast } from '../../../helper/ToastsEmitter.jsx';
import { NotFoundHashtags } from '../../../components/meloPost/hashtag/NotFoundHashtags.jsx';
import clsx from 'clsx';

export const MeloPostHashtag = ({ defaultSelectedHashtags,setSelectedHashtags,hashtags,selectedHashtags,handleSelectHashtags,setFinalHashtagsList,createHashtagLimitStatus,queryLoading,setCreateHashtagLimitStatus }) => {

  const [openCreateHashtag,setOpenCreateHashtag] =useState(false)
  const [isLoading,setIsLoading] = useState(false)
  const [isSearchActive,setIsSearchActive] = useState(false)
  const [searchResults,setSearchResults] = useState([])
  const searchHashtagMutation = useMutation((data) => searchHashtags(data),{
    onSuccess:(res) => {
      if(res?.data?.done){
        setSearchResults(res?.data?.result?.hashtags)
      }
    },
    onSettled:(res) => {
      setIsLoading(false)
    }
  });
  const handleCreateNewHashtag =()=>{

    if(selectedHashtags && selectedHashtags?.length>2){
      setCreateHashtagLimitStatus(true)
      return
    }
    setOpenCreateHashtag(true)

  }
  const searchHashtag = (query)=>{
    setIsLoading(true)
    searchHashtagMutation.mutate({query:query})

  }
  useEffect(() => {
    setSelectedHashtags(defaultSelectedHashtags);
    return () => {
      setSelectedHashtags([])
    };
  }, []);

   const showNotfound =  !isLoading && isSearchActive && (!searchResults || searchResults && searchResults?.length<1)
  return (
    <Container
      style=" bg-background p-4 h-screen"
      header={<CommonHeader title="هشتگ" />}
    >
      {isLoading | queryLoading?
        <div className="fixed flex justify-center items-center w-full h-full bg-secondary  left-0 top-0 z-10">
          <div className="h-[120px]  w-[120px] left-0 top-0 mt-[56px]">
            <MusicNoteLoading />
          </div>
        </div>
        :null}

      <div dir='rtl' className="flex flex flex-col gap-4 pb-56 ">
        <CreateNewHashtag open={openCreateHashtag} setOpen={setOpenCreateHashtag} handleSelectHashtags={handleSelectHashtags}/>
        <CreateHashtagLimitationDialog open={createHashtagLimitStatus} setOpen={setCreateHashtagLimitStatus}/>
        <SearchHashtags  searchHashtag={searchHashtag} setIsSearchActive={setIsSearchActive} />
        {
          showNotfound?
            <div className='pt-[80px]' >
              <NotFoundHashtags/>
            </div>
            :
          null
        }
        <SuggestedHashtagsList hashtags={isSearchActive?searchResults:hashtags} handleSelectHashtags={handleSelectHashtags} selectedHashtags={selectedHashtags}  />
        <div onClick={handleCreateNewHashtag} className={clsx('flex h-[34px] w-[151px]  rounded-[30px] bg-primary gap-3 items-center justify-center',showNotfound?'mx-auto':'')}>
          <div className="w-[12px] h-[12px]">
            <PlusIconSvg fillColor="#303030" />
          </div>
          <p className="text-[12px] fa-format-500 text-secondary ">
            افزودن هشتگ جدید
          </p>
        </div>
      </div>
      <ShowSelectedHashtag setFinalHashtagsList={setFinalHashtagsList} selectedHashtags={selectedHashtags} handleSelectHashtags={handleSelectHashtags}/>
    </Container>
  );
};

