
import TrashSVG from '../../svg/common/TrashSVG.jsx';
import SaveInDeviceSvgIcon from '../../svg/common/SaveInDeviceSvgIcon.jsx';
import HeartSvg from '../../svg/common/HeartSvg.jsx';
import TelegramSvgIcon from '../../svg/common/TelegramSvgIcon.jsx';
import SendToTelegramLoader from '../../loading/SendToTelegramLoader.jsx';
import AddToPlayListSvg from '../../svg/queue/AddToPlayListSvg.jsx';
import QueueSvg from '../../svg/common/QueueSvg.jsx';
import { ContextMenuKeys } from '../../../consts/MenuItemsConsts.js';
import ShareSvg from '../../svg/common/ShareSvg.jsx';
import { LikeMelopost } from '../../svg/common/LikeSvg.jsx';
import MoveIcon from '../../svg/common/MoveIcon.jsx';
import AddGroupPlaylistIcon from '../../svg/common/AddGroupPlaylistIcon.jsx';
import OpenArtistIcon from '../../svg/common/OpenArtistIcon.jsx';
import RemovePlaylistIteIcon from '../../svg/common/RemovePlaylistIteIcon.jsx';
import AddToPlaylistIcon from '../../svg/common/AddToPlaylistIcon.jsx';

export const PLAYER_OPTIONS_KEYS = {
  REMOVE_FROM_DOWNLOAD: "remove_from_download",
  SAVE_IN_DEVICE: "save_in_device",
  LIKE_TRACK: "like_track",
  DISLIKE_TRACK: "dislike_track",
  SEND_TO_TELEGRAM: "send_to_telegram",
  ADD_TO_PLAYLIST: "add_to_playlist",
  ADD_TO_QUEUE: "add_to_queue",
  REMOVE_FROM_QUEUE: "remove_from_queue",
  ADD_GROUP_TO_PLAYLIST: "add_group_to_playlist",
  DRAG_AND_DROP: "drag_and_drop",
  SHARE: "share",
  GO_TO_ARTIST: "go_to_artist",
  REMOVE_FROM_PLAYLIST: "remove_from_playlist",

}
export const TrackOptionsItems = [
  {
    key: PLAYER_OPTIONS_KEYS.REMOVE_FROM_QUEUE,
    title: "حذف از لیست‌ پخش",
    icon: TrashSVG,
    iconColor: '#BDBDBD',
    textColor: '#E0E0E0',
  },
  {
    key: PLAYER_OPTIONS_KEYS.DRAG_AND_DROP,
    title: "جابجایی",
    icon: MoveIcon,
    iconColor: '#BDBDBD',
    textColor: '#E0E0E0',
  },
  {
    key: PLAYER_OPTIONS_KEYS.REMOVE_FROM_PLAYLIST,
    title: "حذف از پلی‌لیست",
    icon: RemovePlaylistIteIcon,
    iconColor: '#BDBDBD',
    textColor: '#E0E0E0',
  },
  {
    key: PLAYER_OPTIONS_KEYS.LIKE_TRACK,
    title: "افزودن به علاقه‌مندی‌ها",
    icon: LikeMelopost,
    iconColor: '#BDBDBD',
    textColor: '#E0E0E0',
    iconProps:{
      isLiked:false,
    },
    active: false,
  },
  {
    key: PLAYER_OPTIONS_KEYS.DISLIKE_TRACK,
    title: "حذف از علاقه‌مندی ها",
    icon: LikeMelopost,
    iconColor: '#BDBDBD',
    textColor: '#E0E0E0',
    iconProps:{
      isLiked:true,
    },
    active: false,
  },
  {
    key: PLAYER_OPTIONS_KEYS.ADD_TO_QUEUE,
    title: " افزودن به لیست پخش",
    icon: QueueSvg,
    iconColor: '#BDBDBD',
    textColor: '#E0E0E0',
    iconProps: {
      className:"w-[20] h-[20px]"
    },
  },
  {

    key: PLAYER_OPTIONS_KEYS.ADD_TO_PLAYLIST,
    title: "افزودن به پلی لیست",
    icon: AddToPlaylistIcon,
    iconColor: '#BDBDBD',
    textColor: '#E0E0E0',
  },
  {

    key: PLAYER_OPTIONS_KEYS.ADD_GROUP_TO_PLAYLIST,
    title: "افزودن گروهی به پلی‌لیست",
    icon: AddGroupPlaylistIcon,
    iconColor: '#BDBDBD',
    textColor: '#E0E0E0',
    active: true,
    submenu: null,
    defaultConfig: null,
  },
  {
    key: PLAYER_OPTIONS_KEYS.SHARE,
    title: "اشتراک گذاری",
    icon: ShareSvg,
    iconColor: '#BDBDBD',
    textColor: '#E0E0E0',
    active: false,

  },
  {
    key: PLAYER_OPTIONS_KEYS.GO_TO_ARTIST,
    title: "رفتن به خواننده",
    icon: OpenArtistIcon,
    iconColor: '#BDBDBD',
    textColor: '#E0E0E0',

  },
  {
    key: PLAYER_OPTIONS_KEYS.SEND_TO_TELEGRAM,
    title: "ارسال به تلگرام من",
    iconColor: '#BDBDBD',
    textColor: '#E0E0E0',
    icon: TelegramSvgIcon,
    defaultConfig: true,
    defaultConfigColor: '#169de2',
    paddingClass: 'py-5 px-4'
  },
  {
    key: PLAYER_OPTIONS_KEYS.SAVE_IN_DEVICE,
    title: "ذخیره در دستگاه",
    icon: SaveInDeviceSvgIcon,
    iconColor: '#BDBDBD',
    textColor: '#E0E0E0',
    active: false,
  },
  {
    key: PLAYER_OPTIONS_KEYS.REMOVE_FROM_DOWNLOAD,
    title: "حذف از لیست دانلود ها",
    icon: TrashSVG,
    iconColor: '#EB5757',
    textColor: '#E0E0E0',
    active: false,

  },

]
