import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmDialogs from '../../../dialogs/ConfirmDialogs';
import { dispatchUnauthenticatedUser } from '../../../../redux/globalstore/GlobalStore';
import LogOutSVG from '../../../svg/common/LogOutSVG';
import CommonHeader from '../../../common/CommonHeader';
import Container from '../../../PageParentContainer/Container';
import ExitSvg from '../../../setting/ExitSvg';
import { useFormik } from 'formik';
import { ADD_NEW_MELOPOST_VALIDATION_SCHEMA } from '../../../../consts/Validation';
import clsx from 'clsx';
import { CardActionArea } from '@mui/material';
import ChevronLeft from '../../../svg/common/ChevronLeft';
import PencilSvg from '../../../setting/PencilSvg';
import { useUserData } from '../../../../helper/ReduxSelectorContext';
import UserAvatar from '../../../common/UserAvatar';
import PlusIconSvg from '../../../svg/common/PlusIconSvg';
import SwipeBottomSheet from '../../../bottomSheets/SwipeBottomSheet';
import BottomSheetHeader from '../../../bottomSheets/BottomSheetHeader';
import NewPencilSvg from '../../../setting/NewPencilSvg';
import { useMutation } from '@tanstack/react-query';
import { removeAvatar } from '../../../../services/ApiClient';
import { NoteMusicLoadingContainer } from '../../../loading/NoteMusicLoadingContainer';
import TrashSVG from '../../../svg/common/TrashSVG';
import { useDispatch } from 'react-redux';
import { userData } from '../../../../redux/login/LoginSlice';

export const EditSocialProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const deleteSelectedDeviceDialogRef = useRef();
  const user = useUserData();
  const dispatch = useDispatch();
  const [openAvatarBottomSheet, setOpenAvatarBottomSheet] = useState(false);

  const { social_username, social_name, social_avatar, is_premium } = user;

  const { mutate, isLoading } = useMutation(() => removeAvatar(), {
    onSuccess: res => {
      if (res?.data?.done) dispatchUserData(res?.data?.result?.user);
    },
  });

  const handleChangeName = () => {
    navigate('name');
  };
  const handleChangeUserName = () => {
    navigate('usename');
  };

  const handleChangeAvatar = () => {
    if (user?.social_avatar) {
      setOpenAvatarBottomSheet(true);
    } else {
      navigateToChooseAvatar();
    }
  };

  const toggleBottomSheet = newOpen => () => {
    setOpenAvatarBottomSheet(newOpen);
  };
  const handleCloseBottomSheet = () => {
    setOpenAvatarBottomSheet(false);
  };

  const handleEditAvatar = () => {
    navigateToChooseAvatar();
  };

  const navigateToChooseAvatar = () => {
    navigate('update-profile');
  };

  const handleRemoveAvatar = () => {
    deleteSelectedDeviceDialogRef.current.openDialog();
    setOpenAvatarBottomSheet(false);
  };
  const confirmDeleteAvatar = () => {
    mutate();
    deleteSelectedDeviceDialogRef.current.closeDialog();
  };

  const dispatchUserData = Data => {
    dispatch(
      userData({
        user: Data,
      }),
    );
  };

  return (
    <Container
      style="bg-background"
      header={<CommonHeader title="ویرایش اطلاعات" />}
    >
      <div dir="rtl" className=" w-full h-full flex flex-col gap-4 p-4">
        <div
          onClick={handleChangeAvatar}
          className=" w-full flex flex-col justify-center items-center gap-5"
        >
          <UserAvatar
            // is_premium={is_premium}
            avatar={social_avatar}
            className={
              '!w-[120px] !h-[120px] !object-cover !justify-center !items-center border-2 border-gray_2 rounded-full'
            }
          />
          <div className=" flex flex-row gap-1">
            <div className="w-[16px] h-[16px]">
              <PlusIconSvg fillColor="#BDBDBD" />
            </div>
            <span className="font-dana text-sm  text-white">انتخاب آواتار</span>
          </div>
        </div>
        <div className="flex flex-col gap-[6px]">
          <p className="text-gray_4 text-[12px] fa-format-400 ">نام:</p>
          <CardActionArea className="!rounded-lg">
            <div
              className="bg-secondary h-[48px] rounded-lg border border-gray_2 w-full flex justify-between items-center py-4 ps-4 pe-4"
              onClick={handleChangeName}
            >
              <div className="flex flex-col justify-center items-start font-dana">
                <p className=" font-medium   text-white  text-base leading-6 ">
                  {social_name}
                </p>
              </div>
              <div className="h-[16px]  w-[16px] ">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2683_12446)">
                    <path
                      d="M2.61209 12.8074C2.61209 12.8074 2.67163 12.8074 2.69395 12.8074L4.87442 12.6065C5.17953 12.5767 5.46233 12.4428 5.67814 12.227L13.9088 3.99628C14.2958 3.6093 14.5116 3.09581 14.5116 2.55256C14.5116 2.0093 14.2958 1.49581 13.9088 1.10884L13.3805 0.580465C12.6065 -0.193488 11.2595 -0.193488 10.4856 0.580465L9.43628 1.62977L2.26233 8.80372C2.04651 9.01954 1.91256 9.30233 1.89023 9.60744L1.6893 11.7879C1.66698 12.0633 1.76372 12.3312 1.95721 12.5321C2.13581 12.7107 2.36651 12.8074 2.61209 12.8074ZM11.9367 1.09395C12.1749 1.09395 12.413 1.18326 12.5916 1.3693L13.12 1.89767C13.2986 2.07628 13.3953 2.30698 13.3953 2.55256C13.3953 2.79814 13.2986 3.03628 13.12 3.20744L12.4651 3.86233L10.627 2.02419L11.2819 1.3693C11.4605 1.1907 11.6986 1.09395 11.9367 1.09395ZM2.99907 9.71163C2.99907 9.66698 3.0214 9.62977 3.05116 9.6L9.8307 2.81302L11.6688 4.65116L4.8893 11.4307C4.8893 11.4307 4.81488 11.4828 4.77767 11.4828L2.82047 11.6614L2.99907 9.70419V9.71163ZM16 15.1814C16 15.4865 15.747 15.7395 15.4419 15.7395H0.55814C0.253023 15.7395 0 15.4865 0 15.1814C0 14.8763 0.253023 14.6233 0.55814 14.6233H15.4419C15.747 14.6233 16 14.8763 16 15.1814Z"
                      fill="#BDBDBD"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2683_12446">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </CardActionArea>
        </div>

        <div className="flex flex-col gap-[6px]">
          <p className="text-gray_4 text-[12px] fa-format-400 ">
            شناسه کاربری:
          </p>
          <CardActionArea className="!rounded-lg">
            <div
              className="bg-secondary h-[48px] rounded-lg border border-gray_2 w-full flex justify-between items-center py-4 ps-4 pe-4"
              onClick={handleChangeUserName}
            >
              <div className="flex flex-col justify-center items-start font-dana">
                <p className=" font-medium   text-white  text-base leading-6 ">
                  {social_username}
                </p>
              </div>
              <div className="h-[16px]  w-[16px] ">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2683_12446)">
                    <path
                      d="M2.61209 12.8074C2.61209 12.8074 2.67163 12.8074 2.69395 12.8074L4.87442 12.6065C5.17953 12.5767 5.46233 12.4428 5.67814 12.227L13.9088 3.99628C14.2958 3.6093 14.5116 3.09581 14.5116 2.55256C14.5116 2.0093 14.2958 1.49581 13.9088 1.10884L13.3805 0.580465C12.6065 -0.193488 11.2595 -0.193488 10.4856 0.580465L9.43628 1.62977L2.26233 8.80372C2.04651 9.01954 1.91256 9.30233 1.89023 9.60744L1.6893 11.7879C1.66698 12.0633 1.76372 12.3312 1.95721 12.5321C2.13581 12.7107 2.36651 12.8074 2.61209 12.8074ZM11.9367 1.09395C12.1749 1.09395 12.413 1.18326 12.5916 1.3693L13.12 1.89767C13.2986 2.07628 13.3953 2.30698 13.3953 2.55256C13.3953 2.79814 13.2986 3.03628 13.12 3.20744L12.4651 3.86233L10.627 2.02419L11.2819 1.3693C11.4605 1.1907 11.6986 1.09395 11.9367 1.09395ZM2.99907 9.71163C2.99907 9.66698 3.0214 9.62977 3.05116 9.6L9.8307 2.81302L11.6688 4.65116L4.8893 11.4307C4.8893 11.4307 4.81488 11.4828 4.77767 11.4828L2.82047 11.6614L2.99907 9.70419V9.71163ZM16 15.1814C16 15.4865 15.747 15.7395 15.4419 15.7395H0.55814C0.253023 15.7395 0 15.4865 0 15.1814C0 14.8763 0.253023 14.6233 0.55814 14.6233H15.4419C15.747 14.6233 16 14.8763 16 15.1814Z"
                      fill="#BDBDBD"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2683_12446">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </CardActionArea>
        </div>
      </div>
      <SwipeBottomSheet
        keepMount={false}
        open={openAvatarBottomSheet}
        toggleDrawer={toggleBottomSheet}
      >
        <BottomSheetHeader
          dividerLine={true}
          closeBottomSheet={handleCloseBottomSheet}
          title="ویرایش آواتار"
        />
        <div dir="rtl" className=" w-full h-full flex flex-col">
          <CardActionArea
            onClick={handleEditAvatar}
            key={'report Item'}
            className="!p-4 "
          >
            <div className=" flex flex-row justify-between items-center gap-2">
              <div className=" flex flex-row justify-between items-center gap-2">
                <NewPencilSvg />
                <span className="font-dana font-medium text-sm text-white">
                  ویرایش
                </span>
              </div>
            </div>
          </CardActionArea>

          <CardActionArea
            onClick={handleRemoveAvatar}
            key={'report Item'}
            className="!p-4  !mb-4"
          >
            <div className=" flex flex-row justify-between items-center gap-2">
              <div className=" flex flex-row justify-between items-center gap-2">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.41985 3.83903e-05L8.37498 1.88516e-05C8.16387 -0.000117734 7.97995 -0.000234817 7.80627 0.0275018C7.12013 0.137063 6.52637 0.565024 6.20545 1.18131C6.12423 1.33731 6.06617 1.51184 5.99953 1.71216L5.98537 1.75471L5.89065 2.03887C5.87213 2.09445 5.86696 2.10977 5.86247 2.12218C5.69162 2.5945 5.24867 2.91375 4.74658 2.92648C4.73337 2.92681 4.7172 2.92687 4.65862 2.92687H1.73171C1.3276 2.92687 1 3.25446 1 3.65858C1 4.06268 1.3276 4.39028 1.73171 4.39028H4.66698H4.68331H15.3656H15.382H18.3171C18.7213 4.39028 19.0488 4.06268 19.0488 3.65858C19.0488 3.25446 18.7213 2.92687 18.3171 2.92687H15.3903C15.3318 2.92687 15.3156 2.92681 15.3024 2.92648C14.8002 2.91375 14.3573 2.59447 14.1865 2.12217C14.182 2.10983 14.1767 2.09418 14.1583 2.03887L14.0636 1.75471L14.0494 1.71214C13.9828 1.51181 13.9247 1.3373 13.8435 1.18131C13.5226 0.565024 12.9288 0.137063 12.2427 0.0275018C12.069 -0.000234817 11.8851 -0.000117734 11.674 1.88516e-05L11.6291 3.83903e-05H8.41985ZM7.23862 2.61998C7.2003 2.72589 7.15577 2.82831 7.10551 2.92687H12.9435C12.8932 2.82831 12.8486 2.7259 12.8104 2.62L12.7725 2.50942L12.6753 2.21748C12.5863 1.95063 12.5658 1.89622 12.5455 1.85721C12.4386 1.65178 12.2406 1.50913 12.012 1.4726C11.9684 1.46567 11.9104 1.46345 11.6291 1.46345H8.41985C8.13857 1.46345 8.08048 1.46567 8.03703 1.4726C7.80832 1.50913 7.6104 1.65178 7.50342 1.85721C7.48311 1.89622 7.46263 1.95065 7.37369 2.21748L7.27631 2.5096C7.26166 2.55358 7.25024 2.58781 7.23862 2.61998Z"
                    fill="#BDBDBD"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.48775 6.34296C3.89097 6.31608 4.23963 6.62115 4.26651 7.02437L4.71523 13.7553C4.8029 15.0702 4.86537 15.9851 5.0025 16.6735C5.13553 17.3413 5.32122 17.6948 5.58796 17.9443C5.85471 18.1939 6.21974 18.3556 6.89486 18.4439C7.59087 18.535 8.50791 18.5364 9.82586 18.5364H10.5803C11.8982 18.5364 12.8153 18.535 13.5113 18.4439C14.1864 18.3556 14.5515 18.1939 14.8182 17.9443C15.085 17.6948 15.2706 17.3413 15.4037 16.6735C15.5408 15.9851 15.6033 15.0702 15.6909 13.7553L16.1397 7.02437C16.1665 6.62115 16.5152 6.31608 16.9184 6.34296C17.3217 6.36983 17.6267 6.7185 17.5998 7.12172L17.1477 13.9039C17.0643 15.1554 16.9969 16.1662 16.8389 16.9595C16.6746 17.7841 16.3951 18.473 15.818 19.013C15.2409 19.5529 14.5349 19.7859 13.7012 19.895C12.8991 19.9999 11.8861 19.9998 10.6318 19.9998H9.77435C8.5202 19.9998 7.50705 19.9999 6.70506 19.895C5.87125 19.7859 5.16535 19.5529 4.58818 19.013C4.01102 18.473 3.73158 17.7841 3.56729 16.9595C3.40927 16.1662 3.3419 15.1554 3.25848 13.9039L2.80634 7.12172C2.77946 6.7185 3.08454 6.36983 3.48775 6.34296Z"
                    fill="#BDBDBD"
                  />
                </svg>

                <span className="font-dana font-medium text-sm text-white">
                  حذف
                </span>
              </div>
            </div>
          </CardActionArea>
        </div>
      </SwipeBottomSheet>
      {isLoading ? <NoteMusicLoadingContainer /> : null}
      <ConfirmDialogs
        dialogIcon={
          <div className="w-full flex flex-row justify-center items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.41985 3.83903e-05L8.37498 1.88516e-05C8.16387 -0.000117734 7.97995 -0.000234817 7.80627 0.0275018C7.12013 0.137063 6.52637 0.565024 6.20545 1.18131C6.12423 1.33731 6.06617 1.51184 5.99953 1.71216L5.98537 1.75471L5.89065 2.03887C5.87213 2.09445 5.86696 2.10977 5.86247 2.12218C5.69162 2.5945 5.24867 2.91375 4.74658 2.92648C4.73337 2.92681 4.7172 2.92687 4.65862 2.92687H1.73171C1.3276 2.92687 1 3.25446 1 3.65858C1 4.06268 1.3276 4.39028 1.73171 4.39028H4.66698H4.68331H15.3656H15.382H18.3171C18.7213 4.39028 19.0488 4.06268 19.0488 3.65858C19.0488 3.25446 18.7213 2.92687 18.3171 2.92687H15.3903C15.3318 2.92687 15.3156 2.92681 15.3024 2.92648C14.8002 2.91375 14.3573 2.59447 14.1865 2.12217C14.182 2.10983 14.1767 2.09418 14.1583 2.03887L14.0636 1.75471L14.0494 1.71214C13.9828 1.51181 13.9247 1.3373 13.8435 1.18131C13.5226 0.565024 12.9288 0.137063 12.2427 0.0275018C12.069 -0.000234817 11.8851 -0.000117734 11.674 1.88516e-05L11.6291 3.83903e-05H8.41985ZM7.23862 2.61998C7.2003 2.72589 7.15577 2.82831 7.10551 2.92687H12.9435C12.8932 2.82831 12.8486 2.7259 12.8104 2.62L12.7725 2.50942L12.6753 2.21748C12.5863 1.95063 12.5658 1.89622 12.5455 1.85721C12.4386 1.65178 12.2406 1.50913 12.012 1.4726C11.9684 1.46567 11.9104 1.46345 11.6291 1.46345H8.41985C8.13857 1.46345 8.08048 1.46567 8.03703 1.4726C7.80832 1.50913 7.6104 1.65178 7.50342 1.85721C7.48311 1.89622 7.46263 1.95065 7.37369 2.21748L7.27631 2.5096C7.26166 2.55358 7.25024 2.58781 7.23862 2.61998Z"
                fill="#EB5757"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.48775 6.34296C3.89097 6.31608 4.23963 6.62115 4.26651 7.02437L4.71523 13.7553C4.8029 15.0702 4.86537 15.9851 5.0025 16.6735C5.13553 17.3413 5.32122 17.6948 5.58796 17.9443C5.85471 18.1939 6.21974 18.3556 6.89486 18.4439C7.59087 18.535 8.50791 18.5364 9.82586 18.5364H10.5803C11.8982 18.5364 12.8153 18.535 13.5113 18.4439C14.1864 18.3556 14.5515 18.1939 14.8182 17.9443C15.085 17.6948 15.2706 17.3413 15.4037 16.6735C15.5408 15.9851 15.6033 15.0702 15.6909 13.7553L16.1397 7.02437C16.1665 6.62115 16.5152 6.31608 16.9184 6.34296C17.3217 6.36983 17.6267 6.7185 17.5998 7.12172L17.1477 13.9039C17.0643 15.1554 16.9969 16.1662 16.8389 16.9595C16.6746 17.7841 16.3951 18.473 15.818 19.013C15.2409 19.5529 14.5349 19.7859 13.7012 19.895C12.8991 19.9999 11.8861 19.9998 10.6318 19.9998H9.77435C8.5202 19.9998 7.50705 19.9999 6.70506 19.895C5.87125 19.7859 5.16535 19.5529 4.58818 19.013C4.01102 18.473 3.73158 17.7841 3.56729 16.9595C3.40927 16.1662 3.3419 15.1554 3.25848 13.9039L2.80634 7.12172C2.77946 6.7185 3.08454 6.36983 3.48775 6.34296Z"
                fill="#EB5757"
              />
            </svg>
          </div>
        }
        title={'حذف آواتار'}
        text={'مطمئن هستید که می خواهید  آواتار را حذف کنید؟'}
        buttonDirection={'dir-ltr'}
        confirmText={'حذف'}
        confirmTextColor={'text-primary'}
        confirmBGColor={'bg-transparent'}
        confirmBorderColor={'border-primary'}
        confirmAction={confirmDeleteAvatar}
        cancelText={'لغو'}
        cancelTextColor={'text-secondary'}
        cancelBGColor={'bg-primary'}
        cancelBorderColor={'transparent'}
        ref={deleteSelectedDeviceDialogRef}
      />
    </Container>
  );
};
