import React from 'react';
import HomeVerticalTracks from './HomeVerticalTracks';
import { HomeSectionBackgroundOverlay } from '../common/HomeSectionBackgroundOverlay';

export const HomeVerticalTracksContainer = ({ item }) => {
  console.log('ASfasfsafasf', item);
  
  return (
    <div className="w-full flex flex-col gap-4  relative">
      <HomeSectionBackgroundOverlay
        background_color={item?.element_meta?.background_color}
      />
      <div className="static z-20 w-full flex flex-col gap-4 ">
        {item?.element_data?.map((track_item, index) => (
          <HomeVerticalTracks
            queueList={item?.element_data}
            item={track_item}
          />
        ))}
      </div>
    </div>
  );
};
