import React from 'react';
import FloatButtonContainer from '../common/FloatButtonContainer.jsx';
import { CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PlusIconSvg from '../svg/common/PlusIconSvg.jsx';
import { getPlayerMusic } from '../../helper/ReduxSelectorContext.js';
import { useBottomNavigation } from '../BottomNavigation/useBottomNavigation.js';

export const FloatCreateMelopostButton = props => {
  const navigate = useNavigate();
  const handleNavigateToCreateMeloPost = () => {
    navigate('/melopost');
  };
  let playedTrackData = getPlayerMusic();
  const handleContainerPadding = () => {
    if (playedTrackData == null) {
      return ' bottom-container-float-button';
    } else {
      return 'create-melopost-button ';
    }
  };
  return (
    <div className={`z-50 !absolute !right-4   ${handleContainerPadding()} `}>
      <CardActionArea
        onClick={() => handleNavigateToCreateMeloPost()}
        dir="rtl"
        className=" !h-12 !w-[156px] !rounded-3xl"
      >
        <div className=" rounded-3xl bg-primary h-[50px] w-[156px] flex justify-center gap-2 items-center p-4 cursor-pointer">
          <div className="w-[20px] h-[20px]">
            <PlusIconSvg fillColor="#404040" />
          </div>
          <p className="text-gray_1 text-base font-dana font-medium">
            ملوپست جدید
          </p>
        </div>
      </CardActionArea>
    </div>
  );
};
