import React from 'react';
import HeaderMelodifySvg from '../svg/common/HeaderMelodifySvg.jsx';

export const HeaderTitleSection = ({isMainTab,showMelodifyIcon, startAdornmentClassName, startAdornment,TitleClassName,TitleProps,title}) => {

  return (
    <>
      {!isMainTab ?<div className={`w-6 h-6  ${startAdornmentClassName}`}>
        {startAdornment}
      </div>:null}

      {!showMelodifyIcon ?
        <div className={` text-center  ${TitleClassName}`} {...TitleProps}>
          <h1 className="text-white text-lg font-dana font-normal pointer-events-none">
            {title}
          </h1>
        </div>
        :
        <HeaderMelodifySvg/>
      }

    </>
  );
};

