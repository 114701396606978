import React from 'react';

function HashtagSvg(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='53'
      height='49'
      fill='none'
      viewBox='0 0 53 49'
    >
      <path
        fill='#F2C94C'
        d='M50.056 17.5C51.682 17.5 53 16.618 53 15s-1.318-2.278-2.944-2.278h-5.35l2.292-7.986c.447-1.556.066-3.178-1.498-3.622-1.563-.445-2.553.456-3 2.012l-2.843 9.596H20l2.5-7.986c.447-1.556.564-3.291-1-3.736-1.563-.444-3.273.57-3.72 2.126l-2.755 9.596H2.945C1.317 12.722 0 13.382 0 15s1.318 2 2.944 2H13.5L9.977 30.303H2.944C1.318 30.303 0 30.882 0 32.5S1.318 35 2.944 35H8.5l-2.498 9.148c-.447 1.557-.066 3.178 1.498 3.623 1.572.447 3.056-.723 3.5-2.27L14 35h18l-2.442 9.148c-.447 1.556-.122 3.177 1.442 3.622 1.572.447 3.056-.723 3.5-2.27l3-10.5h12.556C51.682 35 53 34.117 53 32.5c0-1.62-1.318-2.198-2.944-2.198H39.658L43.5 17.5zM34.5 30.303H15.025L18.5 17h20.08z'
      ></path>
      <path
        fill='#EB5757'
        stroke='#212121'
        strokeWidth='2'
        d='M40 48c1.653 0 3-1.336 3-2.992a2.996 2.996 0 0 0-2.849-2.99A2.996 2.996 0 0 0 43 39.03v-9.038A2.996 2.996 0 0 0 40 27c-1.653 0-3 1.336-3 2.992v9.038a2.996 2.996 0 0 0 2.849 2.989A2.996 2.996 0 0 0 37 45.008 2.996 2.996 0 0 0 40 48Z'
      ></path>
    </svg>
  );
}

export default HashtagSvg;
