import React from 'react';

function UploadErrorDialogIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='56'
      height='60'
      fill='none'
      viewBox='0 0 56 60'
    >
      <g fill='#F2C94C' clipPath='url(#clip0_2048_50441)'>
        <path
          d='M35.03 1.172A4 4 0 0 0 32.202 0H11a4 4 0 0 0-4 4v45a4 4 0 0 0 4 4h32.043a4 4 0 0 0 4-4V14.841a4 4 0 0 0-1.172-2.828zM33.781 3 43.94 13.261H35.78a2 2 0 0 1-2-2zM10.5 50.5a1 1 0 0 1-1-1v-46a1 1 0 0 1 1-1h21v9a4 4 0 0 0 4 4h9v34a1 1 0 0 1-1 1z'></path>
        <path
          d='M21.61 33.819c.244.121.512.181.778.181.33 0 .66-.093.938-.274l8.71-4.975q.047-.027.092-.059c.401-.286.632-.72.632-1.192a1.46 1.46 0 0 0-.724-1.251l-8.71-4.975a1.75 1.75 0 0 0-1.716-.093c-.525.262-.85.77-.85 1.324v9.99c0 .555.326 1.062.85 1.324m.778-11.314 8.611 4.995-8.611 4.995z'></path>
      </g>
      <g clipPath='url(#clip1_2048_50441)'>
        <rect width='28' height='28' x='28' y='32' fill='#212121' rx='14'></rect>
        <path
          fill='#212121'
          stroke='#EB5757'
          strokeWidth='2.5'
          d='M42 30.75c-8.428 0-15.25 6.821-15.25 15.25S33.571 61.25 42 61.25 57.25 54.429 57.25 46 50.429 30.75 42 30.75Zm0 25.813A10.556 10.556 0 0 1 31.438 46 10.556 10.556 0 0 1 42 35.438 10.556 10.556 0 0 1 52.563 46 10.556 10.556 0 0 1 42 56.563Z'
        ></path>
        <path
          fill='#EB5757'
          d='M43.094 50.121a1.094 1.094 0 1 1-2.188 0 1.094 1.094 0 0 1 2.188 0M42 40.785c.604 0 1.094.49 1.094 1.094v4.96a1.094 1.094 0 1 1-2.188 0v-4.96c0-.604.49-1.094 1.094-1.094'
        ></path>
      </g>
      <defs>
        <clipPath id='clip0_2048_50441'>
          <path fill='#fff' d='M0 0h53v53H0z'></path>
        </clipPath>
        <clipPath id='clip1_2048_50441'>
          <rect width='28' height='28' x='28' y='32' fill='#fff' rx='14'></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default UploadErrorDialogIcon;
