import React from 'react';

import { setLanguageDirection } from '../../../helper/LanguageDetection';
import PlaylistAndSongSearchResultAvatar from './PlaylistAndSongSearchResultAvatar';
import { usePlayedTrack } from '../../../hooks/usePlayedTrack';
import { useDownload } from '../../../hooks/useDownload';
import { useStoreSearchHistory } from '../../../hooks/useStoreSearchHistory';
import { CardActionArea } from '@mui/material';
import MusicPlaceholderSvg from '../../../components/svg/common/MusicPlaceholderSvg';
import { useMutation } from '@tanstack/react-query';
import { getArtistTracksWithExcludedTrackId } from '../../../services/ApiClient';
import { useDispatch } from 'react-redux';
import {
  setPlayerPaginationData,
  setPlayList,
  setPlayListstatic,
} from '../../../redux/player/PlayMusicSlice';
import TrackSearchIcon from '../../../components/svg/search/searchTypeIcon/TrackSearchIcon';
import { textFontBasedLanguage } from '../../../helper/TextFontBasedLanguage.js';
import { useGetMoreTracksById } from '../../../hooks/getMoreTracks/useGetMoreTracksById.js';
function SearchResultTrack({ item, fromHistory, index }) {
  const [currentTrack, setPlayerTrack] = usePlayedTrack();
  const {handleGetMoreTrackById}  = useGetMoreTracksById()


  const [
    startDownloadTrack,
    isDownloaded,
    inQueue,
    inQueueItem,
    downloadedItem,
  ] = useDownload(item?.track);
  console.log('dsgsdghsdsdfsdhg', item);
  const [storeSearchItem] = useStoreSearchHistory(fromHistory);
  const handlePlayMusic = trackItem => {
    console.log('dgsdgsdgdsg', trackItem);
    handleGetMoreTrackById(trackItem?.id)
    setPlayerTrack(downloadedItem ? downloadedItem : trackItem, [], 'playlist');
    storeSearchItem({ ...item, id: trackItem?.id }, fromHistory);
  };
  return (
    <CardActionArea
      onClick={() => handlePlayMusic(item?.track)}
      className={`${
        fromHistory ? '' : '!py-[10px]'
      } !my-2 !rounded-[5px] !px-3 ${
        fromHistory
          ? ''
          : index % 2 !== 0
          ? 'bg-song-detail-card-background-2'
          : 'bg-song-detail-card-background'
      }`}
    >
      <div dir="rtl" className="flex justify-end items-center">
        <div className="flex gap-2 items-center shrink-0  ">
          <div className="flex flex-col items-end gap-[6px] font-dana xl:ml-3">
            <p
              dir={setLanguageDirection(item?.track?.title)}
              className={`text-white text-sm font-medium line-clamp-1 max-w-[68vw] min-[520px]:max-w-sm ${textFontBasedLanguage(item?.track?.title)} `}
            >
              {item?.track?.title}
            </p>
            <div className="w-full text-gray_4 flex flex-row items-center justify-end gap-1 ">
              <span className="pl-[2px]  mt-1">
                <TrackSearchIcon />
              </span>
              <p className="text-gray_4 text-xs font-[500]  mt-[4px]">
                {item?.model_type_title}
              </p>
              <p className="text-gray_4 text-xs font-[500] mt-[4px]"> -</p>
              <div
                dir="ltr"
                className="max-w-[40vw] min-[520px]:max-w-xs flex flex-row items-center line-clamp-1  justify-end "
              >
                {/* {item?.track?.artists?.map((artistName, index) => (
                  <span  className="text-gray_4 text-xs font-[500]   ">
                    {artistName?.name}
                    {index !== item?.track?.artists?.length - 1 &&
                      (setLanguageDirection(item?.track?.title) === "rtl"
                        ? "، "
                        : ", ")}
                  </span>
                ))} */}
                <p
                  dir={'rtl'}
                  className="text-gray_3 text-[12px] font-medium font-dana leading-6  whitespace-nowrap truncate max-w-full"
                >
                  {' '}
                  {item?.track?.artists.map((artist, index) => {
                    return (
                      <span className={` ${textFontBasedLanguage(artist?.name)} `} key={index}>
                        {artist?.name}{' '}
                        {index !== item?.track?.artists?.length - 1 &&
                          (setLanguageDirection(item?.title) === 'rtl'
                            ? '، '
                            : ', ')}
                      </span>
                    );
                  })}
                </p>
              </div>
            </div>
          </div>
          <div className="w-[64px] h-[64px] relative overflow-hidden rounded-[5px]">
            <MusicPlaceholderSvg className="absolute top-0 left-0" />
            <div>
              <PlaylistAndSongSearchResultAvatar image={item?.track?.image} />
            </div>
          </div>
        </div>
      </div>
    </CardActionArea>
  );
}

export default SearchResultTrack;
