import React, { useEffect, useState } from 'react';
import { useMutation } from "@tanstack/react-query";
import { getPlayerPaginationTrack } from "../services/ApiClient.js";
import {
    disablePlayerPaginationLoader,
    enablePlayerPaginationLoader,
    setPlayerPaginationData,
    setPlayList,
    setPlayListstatic
} from "../redux/player/PlayMusicSlice.js";
import {
    getPlayerMusic,
    getPlayerPaginationData,
    getPlayList,
    getPlayListStatic,
    getSelectPlayerPaginationLoader
} from "../helper/ReduxSelectorContext.js";
import { useDispatch } from "react-redux";
import { playerPaginationKey } from "../consts/PlayerPaginationKey.js";

export const usePlayerPagination = (key) => {
    const dispatch = useDispatch();
    const playerPaginationData = getPlayerPaginationData();
    const playerPaginationLoader = getSelectPlayerPaginationLoader();
    const playerPaginationEnd = playerPaginationData?.end
    let playList = getPlayList();
    let playListStatic = getPlayListStatic();
    let playedMusic = getPlayerMusic();
    const getListTracksMutation = useMutation(() => getPlayerPaginationTrack(playerPaginationData?.url, {
        ...playerPaginationData?.payload,
        offset: playListStatic.length
    }),
        {
            onSuccess: (response) => {
                dispatchNewPlaylist(response)
                dispatchNewPaginationData(response)
            }
        }
    )
    const dispatchNewPlaylist = (response) => {
        const newPlaylistTrack = [...playList, ...response?.data?.result?.tracks]
        dispatch(setPlayList({ playList: newPlaylistTrack }))

        // set data and paginate data for tatic player
        const newPlaylistTrackStatic = [...playListStatic, ...response?.data?.result?.tracks]
        dispatch(setPlayListstatic({ playListStatic: newPlaylistTrackStatic }))
    }
    const isInViewport = (el) => {

        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight + 50 || document.documentElement.clientHeight + 50) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
    const reachEndOfList = () => {
        const element = document.getElementById(`playerTrackItem-${playListStatic?.length - 5}`)
        console.log(element,`playerTrackItem${playListStatic?.length - 5}`,'log element...');
        if (element) {
            return isInViewport(element)
        }
        return false

    };
    const mutateGetNextPage = () => {
        console.log('scrolll...');
        if (playerPaginationData?.end) {
            return
        }
        if (playerPaginationLoader) {
            return;
        }
        if (!reachEndOfList()) {
            return;
        }

        enablePaginationLoader()



    };
    const enablePaginationLoader = () => {
        dispatch(enablePlayerPaginationLoader())
    }
    const dispatchNewPaginationData = (response) => {
        const newPaginationData = { ...playerPaginationData, end: response?.data?.result?.end }
        console.log(newPaginationData, 'newPaginationData', newPaginationData?.end)
        dispatch(setPlayerPaginationData({ playerPaginationData: newPaginationData }))
    };
    useEffect(() => {
        const playerPage = document.getElementById('queue-list')
        console.log(playerPage,'player...page..');
        playerPage?.addEventListener('scroll', mutateGetNextPage)
        return () => {
            playerPage?.removeEventListener('scroll', mutateGetNextPage)
        }
    }, [playListStatic, playerPaginationData, getListTracksMutation]);
    useEffect(() => {
        const paginationTypeCondition = () => {
            let index = playListStatic?.findIndex((item) => item?.id === playedMusic?.id);
            if (key === playerPaginationKey?.PLAY_PAGINATION) {
                return index>0 && index >= playListStatic?.length - 5
            }
            if (key === playerPaginationKey?.SCROLL_PAGINATION) {
                return reachEndOfList()
            }

        }
        if (playerPaginationLoader && paginationTypeCondition()) {
            getListTracksMutation.mutateAsync().then(() => {
                dispatch(disablePlayerPaginationLoader())
            })
        }
    }, [playerPaginationLoader]);

    return { playerPaginationLoader, enablePaginationLoader, playerPaginationEnd }
}
