import React, { useEffect } from 'react';
import { PlayerHeader } from '../player/PlayerHeader';
import { CardActionArea, Drawer, SwipeableDrawer } from '@mui/material';
import {
  addToPlaylistData,
  getPlayerMusic,
  getPlayList,
  getPlayListStatic,
  getQueueList,
  getQueueStatus,
} from '../../helper/ReduxSelectorContext';
import { useNativeBack } from '../../hooks/useNativeBack';
import { toggleQueuePage } from '../../redux/player/PlayMusicSlice';
import { useDispatch } from 'react-redux';
import SongDetailCard from '../playlist/SongDetailCard';
import PlayerTrackList from '../player/PlayerTrackList';
import SongListLoading from '../loading/SongListLoading';
import { usePlayerPagination } from '../../hooks/usePlayerPagination';
import { playerPaginationKey } from '../../consts/PlayerPaginationKey';
import { useQueue } from '../../hooks/useQueue';
import PlayerController from '../player/PlayerController';
import PlayerSeekBar from '../player/PlayerSeekBar';
import { disablePlayerListSelectMode } from '../../redux/adToPlaylist/AddToPlaylistSlice.js';
import AddToPlaylistModeButton from '../playlist/AddToPlaylistModeButton.jsx';
import PlayerSwipeContainer from '../player/PlayerSwipeContainer';
import FullLyricSeekbar from './FullLyricSeekbar';

const QueuePageDrawer = ({ playerRef }) => {
  const QueueStatus = getQueueStatus();
  const addToPlaylistDataState = addToPlaylistData();
  let playedTrackData = getPlayerMusic();
  let queueList = getQueueList();
  let playList = getPlayList();
  let playListStatic = getPlayListStatic();
  const { clearAllQueueItems } = useQueue();
  const { playerPaginationLoader } = usePlayerPagination(
    playerPaginationKey?.SCROLL_PAGINATION,
  );
  const dispatch = useDispatch();
  const handleOpenQueue = newOpen => {
    dispatch(toggleQueuePage());
  };

  useNativeBack(QueueStatus, 'queue-drawer', handleOpenQueue);
  useEffect(() => {
    if (!QueueStatus) {
      dispatch(disablePlayerListSelectMode());
    }
  }, [QueueStatus]);
  return (
    // <SwipeableDrawer
    //   dir="ltr"
    //   open={QueueStatus}
    //   onClose={handleOpenQueue}
    //   onOpen={handleOpenQueue}
    //   swipeAreaWidth={70}
    //   className="!relative !overflow-hidden"
    //   anchor="bottom"
    //   sx={{
    //     '& .MuiPaper-root': {
    //       maxWidth: '520px',
    //       height: '100%',
    //       margin: 'auto',
    //       borderRadius: 0,
    //       backgroundColor: '#171717',
    //       // borderRadius: "20px",
    //     },
    //   }}
    //   transitionDuration={{
    //     enter: 200,
    //     exit: 200,
    //     appear: 1000,
    //   }}
    //   disableSwipeToOpen={true}
    //   ModalProps={{
    //     keepMounted: true,
    //   }}
    //   // disablePortal={true}
    // >
    <PlayerSwipeContainer
      openBottomSheet={QueueStatus}
      toggleSheet={handleOpenQueue}
      zIndex="z-[102]"
    >
      <AddToPlaylistModeButton
        EnableEditMode={addToPlaylistDataState?.listSelectItems?.length > 0}
      />
      <div
        id="queue-playlist-item"
        className=" !h-full  flex flex-col relative overflow-scroll "
      >
        <div
          className="absolute top-0 z-10 w-full h-full"
          style={{
            background:
              'linear-gradient(180deg, rgba(14, 14, 14, 0.8) 0%, rgba(14, 14, 14, 0.9) 100%)',
          }}
        ></div>
        <div
          className="absolute top-0 z-0 w-full h-full "
          style={{ background: playedTrackData?.background_color }}
        ></div>

        <PlayerHeader
          handleBack={handleOpenQueue}
          className=" px-4 absolute top-0 z-20"
        />
        <div
          id="queue-list"
          className=" !h-full  flex flex-col relative overflow-scroll z-20  mt-[56px] "
        >
          <div className="w-full h-full  top-0 z-40 flex flex-col  py-3  ">
            <div className="w-full flex flex-col  hide-scroll-bar relative ">
              <div className="flex flex-col  w-full  relative hide-scroll-bar gap-6">
                <div className="flex flex-col  items-end  w-full gap-3  relative hide-scroll-bar">
                  <span className="font-dana text-[16px] font-medium text-white px-4">
                    در حال پخش
                  </span>
                  {[playedTrackData].map((item, index) => (
                    <div className="w-full  ">
                      <SongDetailCard
                        data={item}
                        index={index}
                        playList={playList}
                        inPlayer={false}
                        isSelectMode={
                          addToPlaylistDataState?.listSelectItems?.length > 0
                        }
                      />
                    </div>
                  ))}
                </div>
                {queueList.length != 0 && (
                  <div className="flex flex-col  items-end  w-full   relative hide-scroll-bar gap-3">
                    <div className="w-full flex justify-between items-center  px-4 ">
                      <CardActionArea className="!w-fit !rounded-[10px]">
                        <div
                          onClick={() => clearAllQueueItems()}
                          className={`flex justify-center  py-1 gap-2 relative border-0 items-center duration-200 ease-linear    transition-colors cursor-pointer  
                            
                              `}
                          //   onMouseEnter={() => setIsHovered(true)}
                          //   onMouseLeave={() => setIsHovered(false)}
                        >
                          <span className="font-dana text-[12px] font-medium text-gray_4">
                            پاک کردن صف
                          </span>
                          <div className="w-[16px]">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_12516_14461)">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M6.73666 3.07122e-05L6.70076 1.50813e-05C6.53188 -9.41871e-05 6.38474 -0.000187854 6.2458 0.0220014C5.69688 0.10965 5.22188 0.452019 4.96514 0.945045C4.90016 1.06985 4.85371 1.20947 4.80041 1.36972L4.78907 1.40377L4.7133 1.63109C4.69848 1.67556 4.69435 1.68781 4.69076 1.69775C4.55408 2.0756 4.19972 2.331 3.79804 2.34118C3.78747 2.34145 3.77454 2.34149 3.72768 2.34149H1.38615C1.06286 2.34149 0.800781 2.60357 0.800781 2.92686C0.800781 3.25015 1.06286 3.51223 1.38615 3.51223H3.73436H3.74743H12.2933H12.3064H14.6545C14.9778 3.51223 15.2398 3.25015 15.2398 2.92686C15.2398 2.60357 14.9778 2.34149 14.6545 2.34149H12.313C12.2662 2.34149 12.2532 2.34145 12.2427 2.34118C11.841 2.331 11.4866 2.07557 11.35 1.69773C11.3464 1.68787 11.3422 1.67534 11.3274 1.63109L11.2516 1.40377L11.2403 1.36971C11.187 1.20945 11.1406 1.06984 11.0756 0.945045C10.8188 0.452019 10.3438 0.10965 9.79493 0.0220014C9.656 -0.000187854 9.50888 -9.41871e-05 9.33998 1.50813e-05L9.30408 3.07122e-05H6.73666ZM5.79167 2.09598C5.76102 2.18071 5.72539 2.26265 5.68519 2.34149H10.3556C10.3154 2.26265 10.2797 2.18072 10.2491 2.096L10.2188 2.00754L10.141 1.77399C10.0698 1.56051 10.0534 1.51698 10.0372 1.48577C9.95165 1.32142 9.79329 1.2073 9.61034 1.17808C9.57553 1.17253 9.52909 1.17076 9.30408 1.17076H6.73666C6.51163 1.17076 6.46516 1.17253 6.43041 1.17808C6.24744 1.2073 6.0891 1.32142 6.00352 1.48577C5.98727 1.51698 5.97089 1.56052 5.89973 1.77399L5.82183 2.00768C5.81011 2.04286 5.80098 2.07025 5.79167 2.09598Z"
                                  fill="#BDBDBD"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.79254 5.07456C3.11512 5.05306 3.39405 5.29712 3.41555 5.61969L3.77453 11.0044C3.84467 12.0563 3.89464 12.7883 4.00434 13.339C4.11076 13.8732 4.25932 14.156 4.47272 14.3557C4.68611 14.5553 4.97814 14.6847 5.51823 14.7554C6.07504 14.8282 6.80867 14.8293 7.86303 14.8293H8.46658C9.52094 14.8293 10.2546 14.8282 10.8114 14.7554C11.3515 14.6847 11.6436 14.5553 11.8569 14.3557C12.0703 14.156 12.2189 13.8732 12.3253 13.339C12.435 12.7883 12.485 12.0563 12.5551 11.0044L12.9141 5.61969C12.9356 5.29712 13.2145 5.05306 13.5371 5.07456C13.8597 5.09606 14.1037 5.37499 14.0822 5.69757L13.7205 11.1234C13.6538 12.1245 13.5998 12.9331 13.4735 13.5678C13.3421 14.2275 13.1184 14.7786 12.6567 15.2106C12.195 15.6425 11.6303 15.8289 10.9633 15.9162C10.3216 16.0002 9.51119 16.0001 8.50779 16.0001H7.82182C6.8185 16.0001 6.00798 16.0002 5.36639 15.9162C4.69935 15.8289 4.13462 15.6425 3.67289 15.2106C3.21116 14.7786 2.98761 14.2275 2.85618 13.5678C2.72976 12.9331 2.67586 12.1245 2.60913 11.1234L2.24741 5.69757C2.22591 5.37499 2.46998 5.09606 2.79254 5.07456Z"
                                  fill="#BDBDBD"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_12516_14461">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </CardActionArea>
                      <span className="font-dana text-[16px] font-medium text-white">
                        بعدی در صف پخش
                      </span>
                    </div>
                    {queueList?.map((item, index) => (
                      <div className="w-full">
                        <SongDetailCard
                          ShowItemIsPlaying={false}
                          data={item}
                          index={index}
                          playList={playList}
                          type="queue"
                          isPlayListFromQueue={true}
                          isInQueue={true}
                          inPlayer={false}
                          isSelectMode={
                            addToPlaylistDataState?.listSelectItems?.length > 0
                          }
                        />
                      </div>
                    ))}
                  </div>
                )}
                <div className="flex flex-col  items-end  w-full relative hide-scroll-bar gap-3 pb-28">
                  <span className="font-dana text-[16px] font-medium text-white px-4">
                    لیست پخش
                  </span>
                  {/* {playList.map(
                  (item, index) =>
                    playedTrackData?.Qid != item?.Qid && (
                      <div className="w-full">
                        <SongDetailCard
                          ShowItemIsPlaying={false}
                          data={item}
                          index={index}
                          playList={playList}
                          isPlayListFromQueue={true}
                        />
                      </div>
                    )
                )} */}
                  <PlayerTrackList
                    playList={playListStatic}
                    className={'!mt-0'}
                    parentId="queue-list"
                    //   playList={playListStatic?.filter(item => item?.Qid!= playedTrackData?.Qid)}
                  />
                  {playerPaginationLoader && (
                    <div className="w-full flex justify-center items-center -mt-8">
                      <SongListLoading />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-full h-[160px] absolute bottom-0 translate-x-[-50%] left-[50%]   bg-queue-gradient z-20 full-screen-lyrics"></div>
        <div className="w-full h-[160px] absolute bottom-0 translate-x-[-50%] left-[50%]   bg-queue-gradient z-20 full-screen-lyrics"></div>
        <div className="absolute bottom-0 z-40  w-full p-4  translate-x-[-50%] left-[50%] bg-queue-gradient  full-screen-lyrics ">
          <FullLyricSeekbar color="#F2C94C" backgroundColor="#303030" />
          <PlayerController key={'queue-actions'} playerRef={playerRef} />
        </div>
      </div>
    </PlayerSwipeContainer>
  );
};

export default React.memo(QueuePageDrawer);
