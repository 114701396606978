import React from 'react';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage.jsx';
import AlbumSvg from '../svg/album/AlbumSvg.jsx';
import AlbumCardPlaceHolder from '../svg/album/AlbumCardPlaceHolder.jsx';

import TextMarquee from '../common/TextMarquee.jsx';
import { setLanguageDirection } from '../../helper/LanguageDetection.js';

function AlbumCardItem({ item }) {
  return (
    <div className="flex flex-col  items-start gap-3  cursor-pointer overflow-hidden">
      <div className="flex flex-row items-center">
        <div style={{ aspectRatio: 45 / 93 }} className="flex h-[60px] ">
          <AlbumSvg />
        </div>
        <div className="aspect-square rounded-[5px] lg-1440:rounded-br5  relative h-[72px]   w-[72px]  ">
          <div className="w-full h-full overflow-hidden absolute rounded-[5px] lg-1440:rounded-br59 ">
            <div className="absolute w-full h-full aspect-square top-0 z-10">
              <CustomLazyLoadImage image={item?.image} />
            </div>
            <div className="absolute w-full h-full aspect-square top-0">
              <AlbumCardPlaceHolder />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col   w-[82px] items-center ">
        <p
          dir={setLanguageDirection(item?.title)}
          className=" font-dana font-[500] text-center text-white text-[12px]  line-clamp-1 overflow-hidden "
        >
          {item?.title}
        </p>
        <p
          dir={setLanguageDirection(item?.artists[0]?.name)}
          className="text-gray_4 font-dana font-[400] text-[14px] w-[82px] text-center 4 overflow-hidden line-clamp-1"
        >
          {item?.artists?.map((artist, index) => {
            return (
              <span key={index}>
                {artist?.name} {index !== item?.artists?.length - 1 && '، '}
              </span>
            );
          })}
        </p>
      </div>
    </div>
  );
}

export default AlbumCardItem;
