import React from 'react'
import HeaderMelodifySvg from '../svg/common/HeaderMelodifySvg';
import clsx from 'clsx';

export const PlayerHeader = ({ className = 'py-3 px-4', handleBack }) => {
  return (
    <div
      className={clsx(
        className,
        'w-full flex flex-row justify-between items-center  h-[56px]',
      )}
    >
      <span className="cursor-pointer" onClick={handleBack}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.35798 14.6195C9.70777 14.9693 10.2922 14.9693 10.6428 14.6195L18.7343 6.54874C19.0886 6.19448 19.0886 5.61995 18.7343 5.26657C18.38 4.91231 17.8046 4.91231 17.4503 5.26657L10 12.6962L2.55056 5.26568C2.1954 4.91141 1.62087 4.91141 1.2657 5.26568C0.911431 5.61995 0.911432 6.19448 1.2657 6.54785L9.35798 14.6195Z"
            fill="white"
          />
        </svg>
      </span>
      <HeaderMelodifySvg />
    </div>
  );
};
