import React, { useRef, useState } from 'react';
import CommonHeader from '../../components/common/CommonHeader.jsx';
import Container from '../../components/PageParentContainer/Container.jsx';
import { useInfiniteData } from '../../hooks/useInfiniteArtists.js';
import { useSelector } from 'react-redux';
import { selectInitialData } from '../../redux/initialize/InitializeSlice.js';
import { SOCIAL_REPORT_PAGE_DATA, SOCIAL_REPORT_TYPES } from '../../consts/SocialReportTypes.js';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { submitSocialReport } from '../../services/ApiClient.js';
import { CardActionArea } from '@mui/material';
import { MelopostReportList } from '../../components/meloPost/report/MelopostReportList';
import { mainToast } from '../../helper/ToastsEmitter.jsx';
import { FullPageLoader } from '../../components/common/FullPageLoader.jsx';

function MelopostReport({ itemId,itemType,showCommonHeader=true }) {
  const initialDataSelector = useSelector(selectInitialData);
  const location = useLocation();
  const navigate = useNavigate();
  const modelId = location?.state?.modelId??itemId;
  const modelToken = location?.state?.modelToken;
  const modelType = location?.state?.modelType??itemType;
  const [selectedReports, setSelectedReports] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const descriptionRef = useRef(null);
  const preventClick = (event)=>{
    event?.stopPropagation()
  }
  const requestData = {
    model_id: modelId,
    model_token: modelToken,
    model_type: modelType,
    social_report_category_ids: selectedReports,
    description: descriptionRef?.current?.value ?? '',
  };
  const melopostReportList =
    initialDataSelector?.social_report_categories?.filter(
      item => item?.type === modelType,
    );
  const reportMelopostMutation = useMutation(
    () => submitSocialReport(requestData),
    {
      onSettled: () => {
        setRequestLoading(false);
      },
      onSuccess: response => {
        if (response?.data?.done) {
          mainToast(response?.data?.result?.message);
          navigate(-1);
        }
      },
    },
  );

  const handleSelectItem = id => {
    const isItemSelected = selectedReports?.find(
      selectedId => selectedId === id,
    );
    if (isItemSelected) {
      const list = selectedReports;
      const newList = list?.filter(selectedId => selectedId !== id);
      setSelectedReports(newList);
    } else {
      setSelectedReports(prevState => {
        return [...prevState, id];
      });
    }
  };

  const handleSubmitRequest = () => {
    if (
      descriptionRef?.current?.value?.trim()?.length < 1 &&
      selectedReports?.length < 1
    ) {
      mainToast('یکی از گزینه ها را انتخاب کنید یا متن توضیحات را پر کنید.');
      return;
    }
    setRequestLoading(true);
    reportMelopostMutation.mutate();
  };
  const pageData = SOCIAL_REPORT_PAGE_DATA[modelType];
  return (
    <Container
      style="bg-black"
      shadow={false}
      onClick={preventClick}
      header={<CommonHeader title={pageData?.title} showCommonHeader={showCommonHeader} />}
    >
      {requestLoading ? <FullPageLoader /> : null}
      <div dir="rtl" className="flex flex-col gap-[26px] px-4 pt-4 pb-20">
        {Boolean(melopostReportList && melopostReportList?.length > 0) && (
          <div className="flex flex-col gap-[10px]">
            <p className="text-gray_4 font-dana text-[14px] font-[600] ">
              {pageData?.checkboxTitle}
            </p>
            <MelopostReportList
              list={melopostReportList}
              selectedList={selectedReports}
              handleSelectItem={handleSelectItem}
            />
          </div>
        )}
        <div className="flex flex-col gap-[10px]">
          <div className="flex gap-2 items-center  ">
            <p className="text-gray_4 font-dana text-[14px] font-[600] ">
              توضیحات:
            </p>
          </div>

          <textarea
            disabled={requestLoading}
            id="description"
            ref={descriptionRef}
            placeholder="متن گزارش را بنویسید."
            className={`h-[103px] w-full text-white text-[14px] border-[1px] border-gray_2 font-format-400  bg-primary_dark rounded-[8px] placeholder:text-gray_3 resize-none  px-4 focus:outline-none px-4 py-2 placeholder:text-[14px] placeholder:font-400 placeholder:text-gray_4 font-dana `}
          />
        </div>
      </div>
      <div className="flex flex-col gap-[18px] !fixed bottom-[30px] max-w-[520px] !w-full   left-[50%] translate-x-[-50%] ">
        <CardActionArea
          className={
            '!h-[45px] !rounded-[8px] !overflow-hidden   !flex !justify-center !px-4 '
          }
          onClick={handleSubmitRequest}
          disabled={requestLoading}
        >
          <button
            disabled={requestLoading}
            type="submit"
            className="w-full bg-primary text-title_box fa-format-500 flex items-center justify-center text-[16px] !rounded-[8px] h-[45px]"
          >
            <span>ارسال گزارش</span>
          </button>
        </CardActionArea>
      </div>
    </Container>
  );
}

export default MelopostReport;
