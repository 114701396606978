import React from 'react';
import CssShimmer from '../../common/CssShimmer.jsx';

export const  HashtagItemShimmer = (props) => {
  return (
    <CssShimmer classNames={`flex items-center  w-[100px]  rounded-[100px]  h-[32px]`}
                duration={'0.9s'}
                reverse={true} />
  );
}

