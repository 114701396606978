import React from 'react';

function MelopostEmptyIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      fill="none"
      viewBox="0 0 128 128"
    >
      <g fill="#4F4F4F" clipPath="url(#clip0_2138_81492)">
        <path d="M108.183 0H19.817C8.89 0 0 8.89 0 19.817v88.367C0 119.11 8.89 128 19.817 128h88.367C119.11 128 128 119.11 128 108.183V19.817C128 8.89 119.11 0 108.183 0M120.5 108.183c0 6.792-5.525 12.317-12.317 12.317H19.817c-6.792 0-12.317-5.525-12.317-12.317v-68.55h113zm0-76.05H7.5V19.817C7.5 13.025 13.025 7.5 19.817 7.5h88.367c6.791 0 12.316 5.525 12.316 12.317z"></path>
        <path d="M23.833 16.067h-4.017c-4.968.183-4.968 7.316 0 7.5h4.017c4.968-.184 4.968-7.317 0-7.5M41.909 16.067h-4.017c-4.968.183-4.968 7.316 0 7.5h4.017c4.968-.184 4.968-7.317 0-7.5M59.983 16.067h-4.017c-4.968.183-4.968 7.316 0 7.5h4.017c4.968-.184 4.969-7.317 0-7.5M60.626 79.948a4.78 4.78 0 0 1 6.748 0 3.75 3.75 0 1 0 5.302-5.305c-4.784-4.781-12.568-4.781-17.352 0-3.383 3.642 1.657 8.687 5.302 5.305M92.117 67.75a3.75 3.75 0 0 0 3.75-3.75v-4.017c-.183-4.968-7.316-4.968-7.5 0V64a3.75 3.75 0 0 0 3.75 3.75M35.883 67.75a3.75 3.75 0 0 0 3.75-3.75v-4.017c-.183-4.968-7.317-4.968-7.5 0V64a3.75 3.75 0 0 0 3.75 3.75"></path>
      </g>
      <defs>
        <clipPath id="clip0_2138_81492">
          <path fill="#fff" d="M0 0h128v128H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default MelopostEmptyIcon;
