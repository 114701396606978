import React from 'react';

function CloseDialogButton({ stroke = '#F2C94C' }) {
  return (
    <svg
      className="w-full h-full"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={'#828282'}
        d="M0.781391 16C0.626854 16 0.47578 15.9542 0.347278 15.8684C0.218776 15.7825 0.11862 15.6605 0.0594773 15.5177C0.000334659 15.375 -0.0151368 15.2179 0.0150197 15.0663C0.0451763 14.9147 0.119606 14.7755 0.228895 14.6663L14.6663 0.228852C14.8128 0.0823204 15.0116 0 15.2188 0C15.426 0 15.6248 0.0823204 15.7713 0.228852C15.9178 0.375383 16.0001 0.574122 16.0001 0.781348C16.0001 0.988574 15.9178 1.18731 15.7713 1.33384L1.33389 15.7713C1.2614 15.8439 1.17527 15.9015 1.08046 15.9407C0.985646 15.98 0.884011 16.0001 0.781391 16Z"
      />
      <path
        d="M15.2188 16C15.1161 16.0001 15.0145 15.98 14.9197 15.9407C14.8249 15.9015 14.7387 15.8439 14.6663 15.7713L0.228852 1.33384C0.0823204 1.18731 0 0.988574 0 0.781348C0 0.574122 0.0823204 0.375383 0.228852 0.228852C0.375383 0.0823204 0.574122 0 0.781348 0C0.988574 0 1.18731 0.0823204 1.33384 0.228852L15.7713 14.6663C15.8805 14.7755 15.955 14.9147 15.9851 15.0663C16.0153 15.2179 15.9998 15.375 15.9407 15.5177C15.8815 15.6605 15.7814 15.7825 15.6529 15.8684C15.5244 15.9542 15.3733 16 15.2188 16Z"
        fill={'#828282'}
      />
    </svg>
  );
}

export default CloseDialogButton;
