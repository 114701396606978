import React from 'react';
import { CardActionArea } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FreeMode } from 'swiper';
import { HashtagItem } from './HashtagItem.jsx';

export const ShowSelectedHashtag = ({ selectedHashtags,handleSelectHashtags,setFinalHashtagsList}) => {

  return (
    selectedHashtags?.length>0 ?
      <div dir='rtl' className="flex border-t-[1px] border-gray_2 bg-primary_dark py-[28px] flex-col gap-8 absolute bottom-0 w-full left-0 right-0 ">
        <div className="flex flex-col pr-4  gap-4">
          <p className="text-[12px] fa-format-600 text-gray_5 ">
            هشتگ های انتخاب شده
          </p>
          <Swiper
            dir="rtl"
            className="flex     !w-full !select-none  last:pl-2"
            freeMode
            modules={[FreeMode]}
            slidesPerView={'auto'}
            spaceBetween={12}
          >
            {selectedHashtags?.map((hashtag, index) => (
              <SwiperSlide
                key={hashtag?.title}
                className='w-fit'

              >
                <HashtagItem title={hashtag?.title} isSelected={true} viewCount={hashtag?.used_count} hasDeleteIcon={true} onDelete={()=>handleSelectHashtags(hashtag)} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <CardActionArea
          className={
            '!h-[45px] !rounded-full !overflow-hidden   !w-full   left-[50%] translate-x-[-50%] !flex !justify-center !px-4 '
          }
        >
          <button
            onClick={setFinalHashtagsList}
            className="w-full bg-primary text-gray_1 fa-format-500 flex items-center justify-center text-[16px] rounded-[100px] h-[45px]"
          >
            انتخاب
          </button>
        </CardActionArea>
      </div>

    :
      null


  );
};

