import React, { useEffect, useState } from 'react';
import ClickReaction from '../../helper/ClickReaction';
import { useMutation } from '@tanstack/react-query';
import {
  followUser,
  removeFollowRequest,
  unfollowUser,
} from '../../services/ApiClient';
import Loading from '../loading/Loading';
import { USER_FOLLOW_STATUS } from '../../consts/FollowStatusConst';
import { useFollowUserSocial } from '../../hooks/useFollowUserSocial';
import { useNavigate, useParams } from 'react-router-dom';
import { useSocialProfile } from '../../hooks/useSocialProfile';
import NewPencilSvg from '../setting/NewPencilSvg';

export const FollowUnFollowButton = ({ social_token, follow_status }) => {
  const navigate = useNavigate();
  const { isUserSocialProfile } = useSocialProfile(social_token);

  const { followStatus, loading, handleFollowUser } = useFollowUserSocial({
    follow_status,
    social_token,
  });

  const renderButtonTitle = () => {
    if (followStatus === USER_FOLLOW_STATUS.follow_request) {
      return 'درخواست داده شده';
    } else if (followStatus === USER_FOLLOW_STATUS.followed) {
      return 'دنبال می‌کنید';
    }

    return 'دنبال کردن';
  };

  const handleUserAction = () => {
    if (isUserSocialProfile) {
      navigate('/profile/edit');
    } else {
      handleFollowUser();
    }
  };
  return (
    <div className="w-full mt-3 px-4">
      <ClickReaction style={`w-full `}>
        <div
          onClick={handleUserAction}
          className={` ${
            followStatus === USER_FOLLOW_STATUS.not_followed
              ? ` text-primary_dark ${
                  loading ? 'bg-transparent border-primary' : 'bg-primary '
                } `
              : ' border-primary text-primary  bg-transparent'
          } border-[1px] font-dana text-xs font-[600] w-full rounded-[30px] h-[46px] flex justify-center items-center gap-4  `}
        >
          {loading ? (
            <Loading bgColor={'#F2C94C'} />
          ) : isUserSocialProfile ? (
            <div className="flex flex-row gap-2">
              <NewPencilSvg fill="#F2C94C" />
              ویرایش پروفایل
            </div>
          ) : (
            <span>{!loading && renderButtonTitle()}</span>
          )}
        </div>
      </ClickReaction>
    </div>
  );
};
