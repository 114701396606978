import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getArtistsGroup } from '../../services/ApiClient.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import Container from '../../components/PageParentContainer/Container';
import CollectionCategoryHeader from '../../components/playlist/CollectionCategoryHeader';
import AbsoluteBackground from '../../components/common/AbsoluteBackground';
import ScrollTopButton from '../../components/common/ScrollTopButton';
import CssShimmer from '../../components/common/CssShimmer';
import SongListLoading from '../../components/loading/SongListLoading';
import { VirtuosoGrid } from 'react-virtuoso';
import HomeArtistCard from '../../components/home/HomeArtistCard';

function ArtistsGroup() {
  const location = useLocation();
  const navigate = useNavigate();

  const { id: artist_group_id, title, image } = location.state;

  const [Loader, setLoader] = useState(false);

  const { isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, data } =
    useInfiniteQuery(
      ['getArtistGroupQuery'],
      ({ pageParam = 0 }) =>
        getArtistsGroup({ offset: pageParam, artist_group_id }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const allData = allPages?.flatMap(page => page.data.result.artists);
          return lastPage?.data?.result?.end ? false : allData?.length;
        },
        refetchOnWindowFocus: false,
      },
    );
  const artists = data?.pages?.flatMap(page => page.data.result.artists);
  const getNextPage = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };
  const openArtist = item => {
    navigate(`/artist-profile/${item?.id}`, { state: { artistObject: item } });
  };
  useEffect(() => {
    setLoader(true);
  }, []);

  return (
    <Container style="bg-primary_dark !overflow-hidden">
      <CollectionCategoryHeader
        title={title}
        hasSearch={false}
        backgroundId="absolute-background"
        pageId="artists-group-page"
      />
      <AbsoluteBackground image={image} animation={true} />
      <ScrollTopButton id={'artists-group-page'} />
      <div
        id="artists-group-page"
        className=" relative overflow-scroll h-full hide-scroll-bar z-10 "
        dir="rtl"
      >
        <div className={`h-full  w-full mb-10  pt-[208px]`}>
          <p className="!z-50 absolute font-kalameh font-medium text-lg text-white top-[100px] w-full flex justify-center items-center mx-auto">
            {title}
          </p>
          <div className="flex gap-3  flex-col mt-2  p-6 max-[350px]:px-0">
            {isLoading ? (
              <div className="grid grid-cols-2 gap-4">
                {Array.from(
                  [1, 2, 3, 4, 5, 6, 7, 8, 9].map(item => (
                    <div className="w-full aspect-square flex flex-col gap-2 items-center">
                      <CssShimmer classNames={'w-[156px] h-[156px] rounded-full'} />
                      <CssShimmer classNames={'h-3 w-24 rounded-[6px]'} />
                    </div>
                  )),
                )}
              </div>
            ) : artists?.length !== 0 ? (
              <InfiniteScroll
                className="!overflow-visible"
                scrollableTarget="artists-group-page"
                loader={
                  <div className="w-full grid justify-center items-center mt-4">
                    <SongListLoading />
                  </div>
                }
                next={getNextPage}
                hasMore={hasNextPage}
                dataLength={artists?.length || 0}
              >
                <VirtuosoGrid
                  data={artists}
                  customScrollParent={document.getElementById(
                    'artists-group-page',
                  )}
                  listClassName="!grid !grid-cols-2 !gap-4  max-[350px]:!gap-x-[.1rem]"
                  itemContent={(index, pageData) => (
                    <div
                      key={pageData.id + 'HomeArtistCard' + index}
                      onClick={() => openArtist(pageData)}
                      className="flex justify-center items-center h-max mx-auto z-10"
                    >
                      <HomeArtistCard item={pageData} imageSize='w-[156px] h-[156px]' />
                    </div>
                  )}
                />
              </InfiniteScroll>
            ) : (
              //  IF WANT HANDEL NO DATA CAN USE THIS BLOCK
              <div className="flex !flex-col !items-center !justify-center relative top-[20vh]  "></div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ArtistsGroup;
