import React, { useEffect } from 'react';
import AlbumMetaDataIcon from '../../../components/svg/search/AlbumMetaDataIcon';
import AlbumCardItem from '../../../components/album/AlbumCardItem';
import {
  getAlbumResultData,
  getCommitSearch,
  getCommitedSearchQuery,
  getSearchQuery,
} from '../../../helper/ReduxSelectorContext';
import AlbumFullRowCardItem from '../../../components/album/AlbumFullRowCardItem';
import { CardActionArea } from '@mui/material';
import { useAction } from '../../../hooks/useAction';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getSearchResultV8 } from '../../../services/ApiClient';
import InfiniteScroll from 'react-infinite-scroll-component';
import SongListLoading from '../../../components/loading/SongListLoading';
import { useMemo } from 'react';
import SearchResultAlbum from '../allResultItem/SearchResultAlbum';
import CssShimmer from '../../../components/common/CssShimmer';
import SearchResultAlbumSingleTab from '../allResultItem/SearchResultAlbumSingleTab';
import { useStoreSearchHistory } from '../../../hooks/useStoreSearchHistory';
import { SearchItemType } from '../../../consts/SearchItemType';
import SearchNoResultView from '../SearchNoResultView';
import SearchResultUser from '../allResultItem/SearchResultUser';
import { useNavigate } from 'react-router-dom';

function UserSearchResultTab({ scrollTop }) {
  const shimmerArray = [1, 2, 3, 4, 5, 6, 6, 7, 8, 9];
  const [, storeSearchItemWithType] = useStoreSearchHistory();
  const searchCommit = getCommitSearch();
  const navigate = useNavigate();
  const action = useAction();

  const searchparam = getCommitedSearchQuery();
  const getSearchUsersDataQuery = useInfiniteQuery(
    [`getSearcUserDataQuery` + searchparam],
    ({ pageParam = 0 }) =>
      getSearchResultV8({
        q: searchparam,
        type: 8,
        offset: pageParam,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.result?.end) return null;
        const allData = allPages?.flatMap(
          page => page?.data?.result?.social_profiles,
        );
        return allData?.length;
      },
      onSuccess: res => {
        console.log('dgsdjgkjhsfsdfsdjkgh', res);
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );
  const userLists = useMemo(
    () =>
      getSearchUsersDataQuery?.data?.pages?.flatMap(page => {
        if (page?.data?.error) return [];
        return page?.data?.result?.social_profiles;
      }),
    [getSearchUsersDataQuery?.data],
  );
  const getUserListsNextPage = () => {
    if (
      getSearchUsersDataQuery?.hasNextPage &&
      !getSearchUsersDataQuery?.isFetchingNextPage
    ) {
      getSearchUsersDataQuery.fetchNextPage();
    }
  };
  useEffect(() => {
    getSearchUsersDataQuery.refetch();
  }, [searchCommit]);
  useEffect(() => {
    scrollTop();
  }, []);
  return (
    <div className="w-full">
      {getSearchUsersDataQuery.isLoading ? (
        <div className="flex w-full flex-col hide-scroll-bar overflow-scroll  gap-4 pb-8 mt-4">
          {shimmerArray?.map(item => (
            <div dir="ltr" className="flex flex-row w-full  gap-4 px-5 ">
              <CssShimmer
                classNames={`h-16 w-16 min-[375px]:h-16 min-[375px]:w-16 rounded-[10px]`}
              />
              <div className="flex flex-1 flex-col justify-center items-start gap-1.5 min-[375px]:gap-2">
                <CssShimmer classNames="w-full rounded-full h-6 " />
                <CssShimmer classNames="w-1/2 rounded-full h-6 " />
              </div>
            </div>
          ))}
        </div>
      ) : userLists?.length == 0 ? (
        <div className="w-full h-full flex justify-center items-center mt-14">
          <SearchNoResultView />
        </div>
      ) : (
        <InfiniteScroll
          className="!overflow-hidden pb-8"
          scrollableTarget="search-result-parent"
          dataLength={userLists?.length || 0}
          next={getUserListsNextPage}
          hasMore={getSearchUsersDataQuery?.hasNextPage}
          loader={
            <div className="w-full grid justify-center items-center mt-4">
              <SongListLoading />
            </div>
          }
        >
          <div className="grid grid-cols-1 mt-1 w-full px-4">
            {userLists?.map((item, index) => (
              <div key={index + 'user-item'}>
                <SearchResultUser item={item} index={index} />
              </div>
            ))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
}

export default React.memo(UserSearchResultTab);
