
import ClickReaction from '../../helper/ClickReaction.jsx';
import RetrySVG from '../svg/common/RetrySVG.jsx';
import RetrySvgType2 from '../svg/common/RetrySvgType2.jsx';
import { useNavigate } from 'react-router-dom';

export const MeloPostPlaybackControl = ({togglePauseResume,isPausedRecordedAudio,clearCanvas,recordedBlob})=>{
  const navigate = useNavigate()
  const handleUpload = ()=>{
       navigate('/melopost/upload',{state:{file:recordedBlob}})
  }


  return (
    <div className="flex flex-col gap-[30px] w-full items-center">
      <div className="w-[104px] h-[104px]" onClick={togglePauseResume}>
        {isPausedRecordedAudio ? (
          <svg
            // onClick={() => handlePlay()}
            className="w-full h-full"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            key={'record-melopost-play-buttom'}
          >
            <g clipPath="url(#clip0_4009_14011)">
              <path
                d="M20 0C8.95445 0 0 8.9543 0 20C0 31.0457 8.95445 40 20 40C31.0455 40 40 31.0457 40 20C40 8.9543 31.0455 0 20 0ZM26.9125 21.0602L16.9125 27.3102C16.7102 27.4365 16.4801 27.5 16.25 27.5C16.0416 27.5 15.8328 27.4481 15.6439 27.3431C15.2466 27.1228 15 26.7047 15 26.25V13.75C15 13.2953 15.2466 12.8772 15.6439 12.6569C16.0413 12.4353 16.5271 12.4487 16.9125 12.6898L26.9125 18.9398C27.2778 19.1687 27.5 19.5691 27.5 20C27.5 20.4309 27.2778 20.8313 26.9125 21.0602Z"
                fill="#F2C94C"
              />
            </g>
            <defs>
              <clipPath id="clip0_4009_14011">
                <rect width="40" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            // onClick={() => handlePause()}
            className="w-full  h-full"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            key={'record-melopost-pause-buttom'}
          >
            <g clipPath="url(#clip0_1031_3710)">
              <path
                d="M20 0C8.95 0 0 8.95 0 20C0 31.05 8.95 40 20 40C31.05 40 40 31.05 40 20C40 8.95 31.05 0 20 0ZM17.8 25C17.8 26.25 16.8 27.2 15.6 27.2C14.35 27.2 13.4 26.2 13.4 25V15C13.35 13.8 14.35 12.8 15.55 12.8C16.8 12.8 17.8 13.8 17.8 15V25ZM26.65 25C26.65 26.25 25.65 27.2 24.45 27.2C23.2 27.2 22.25 26.2 22.25 25V15C22.2 13.8 23.2 12.8 24.4 12.8C25.65 12.8 26.65 13.8 26.65 15V25Z"
                fill="#F2C94C"
              />
            </g>
            <defs>
              <clipPath id="clip0_1031_3710">
                <rect width="40" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
      </div>
      <div dir="rtl" className="flex gap-[18px] w-full">
        <ClickReaction style={`w-[50%]`}>
          <div
            onClick={handleUpload}
            className={`text-gray_1 font-dana bg-primary text-[16px] font-[500] w-full rounded-[100px] h-[44px] flex justify-center items-center gap-[6px]  `}
          >
            بارگذاری
          </div>
        </ClickReaction>
        <ClickReaction style={`w-[50%]`}>
          <div
            onClick={clearCanvas}
            className={`text-primary bg-transparent border-[1px] border-primary font-dana text-[16px] font-[500] w-full rounded-[100px] h-[44px] flex justify-center items-center gap-[6px]  `}
          >
            <div className={`aspect-square w-[20px]`}>
              <RetrySvgType2 />
            </div>
            <span>ضبط مجدد</span>
          </div>
        </ClickReaction>
      </div>
    </div>
  );
}
