import React from 'react'

function TrackSearchIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.40797 12.7741H3.40811C4.82629 12.7727 6.01403 11.7392 6.01563 10.4187V10.4184V6.59546L14.2246 5.10666V8.15198V9.7944V10.9967C13.3711 10.3753 12.1578 10.2952 11.2031 10.8316C10.1038 11.4491 9.65969 12.7125 10.1963 13.7998C10.7277 14.8768 12.0458 15.4227 13.2815 15.1143C14.5152 14.8066 15.3408 13.7258 15.1802 12.5487V2.07803C15.1802 1.68582 14.9836 1.3215 14.6568 1.08226C14.331 0.843605 13.9074 0.751157 13.5018 0.824631L13.5018 0.824638L6.18925 2.15085L6.1888 2.15094C5.54904 2.26848 5.06195 2.77979 5.06019 3.40335V3.40392V8.59676C4.27847 8.01779 3.18019 7.894 2.2536 8.30683L2.25359 8.30684C1.17538 8.78732 0.58685 9.88284 0.870777 10.9633C1.15296 12.0378 2.21352 12.7744 3.40797 12.7741ZM14.2246 12.7977L14.2246 12.7989C14.2249 12.8379 14.2305 12.8766 14.241 12.9137C14.1928 13.6655 13.4899 14.2898 12.5918 14.2908C11.6905 14.2909 10.9828 13.6609 10.9418 12.9022C10.9009 12.1453 11.5346 11.4594 12.4305 11.3815C13.3221 11.3042 14.0936 11.8629 14.2246 12.61L14.2246 12.7977ZM14.2246 4.19265L6.01581 5.68144L6.01581 3.4046C6.01581 3.40455 6.01581 3.40449 6.01581 3.40444C6.01633 3.23988 6.14877 3.07298 6.37125 3.03195C6.37132 3.03194 6.37139 3.03193 6.37146 3.03191L13.6835 1.70595L13.6836 1.70594C13.8225 1.68068 13.9642 1.71354 14.0686 1.78999L14.0686 1.79006C14.1721 1.86576 14.2246 1.97263 14.2246 2.07803V4.19265ZM3.40828 8.96027C4.34194 8.96036 5.06019 9.63331 5.06019 10.4184C5.06019 11.2035 4.34182 11.8766 3.40809 11.8766C2.47425 11.8766 1.75608 11.2036 1.756 10.4186C1.75699 9.63369 2.47502 8.96116 3.40828 8.96027Z"
        fill="white"
        stroke="white"
        stroke-width="0.4"
      />
    </svg>
  );
}

export default TrackSearchIcon