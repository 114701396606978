import { CardActionArea } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ClickReaction from '../../../helper/ClickReaction';
import HeartSvg from '../../svg/common/HeartSvg';
import RenderShare from '../../common/RenderShare';
import { shareTypes } from '../../../consts/shareTypes';
import ShareAnimation from '../../common/ShareAnimation';
import { usePlayedTrack } from '../../../hooks/usePlayedTrack';
import { useDownload } from '../../../hooks/useDownload';
import { useReels } from '../../../consts/useReels';
import { useGetMoreTracksById } from '../../../hooks/getMoreTracks/useGetMoreTracksById.js';
import { RenderReelLike } from '../RenderReelLike';
import ReelsReportIcon from '../../svg/common/ReelsReportIcon.jsx';
import { SOCIAL_REPORT_TYPES } from '../../../consts/SocialReportTypes.js';
import { useNavigate } from 'react-router-dom';
import { closeReelPlayer, pauseReelPlayer } from '../../../redux/reelPlayer/ReelsPlayerSlice.js';
import { useDispatch } from 'react-redux';
import ReelReportComponent from '../ReelReportComponent';

export const ReelActions = ({
  item,
  setMute,
  mute,
  handleTouchStartInside,
                              reelPlayerRef
}) => {
  const [, setPlayerTrack] = usePlayedTrack();
  const [, , , , downloadedItem] = useDownload(item?.track);
  const [reportDrawerState,setReportDrawerState] = useState(false)
  const {handleGetMoreTrackById} = useGetMoreTracksById()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleMutePlayer = e => {
    setMute(!mute);
  };

  const handlePlayTrackFromReel = () => {
    setPlayerTrack(
      downloadedItem ? downloadedItem : item?.track,
      [],
      'playlist',
    );
    handleGetMoreTrackById(item?.track?.id)
  };
  const handleReport = ()=>{
    reelPlayerRef?.current?.player?.player?.pause();
    setReportDrawerState(true)


  }
  useEffect(()=>{
    if(!reportDrawerState){
      reelPlayerRef?.current?.player?.player?.play();
    }

  },[reportDrawerState])

  return (
    <>

    <ReelReportComponent modelId={item?.id} modelType={SOCIAL_REPORT_TYPES.REEL} openState={reportDrawerState} setOpenState={setReportDrawerState} />
      <div className="flex flex-col gap-5 items-end  col-span-4 min-w-[117px] ">
        {/* view */}
        <div
          onTouchStart={handleTouchStartInside}
          className="flex flex-col justify-center items-center gap-1 "
        >
          <div className="bg-white bg-opacity-20  rounded-[100px] flex flex-row justify-center items-center p-2 gap-2 !w-[40px] !h-[40px]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9993 16.419C6.16689 16.419 6.16689 7.58203 11.9993 7.58203C17.7655 7.58203 18.0147 16.419 11.9993 16.419ZM11.9993 9.34943C8.46452 9.34943 8.46452 14.6516 11.9993 14.6516C15.5341 14.6516 15.5341 9.34943 11.9993 9.34943Z"
                fill="white"
              />
              <path
                d="M12 19.9535C7.62656 19.9535 3.4387 17.6302 0.511886 13.5785C0.17916 13.1195 0 12.5671 0 12.0002C0 11.4333 0.17916 10.8809 0.511886 10.4219C3.4387 6.37012 7.62656 4.04688 12 4.04688C16.3734 4.04688 20.5613 6.37012 23.4881 10.4219C23.8208 10.8809 24 11.4333 24 12.0002C24 12.5671 23.8208 13.1195 23.4881 13.5785C20.5613 17.6302 16.3734 19.9535 12 19.9535ZM1.94083 12.5428C4.57249 16.1819 8.14441 18.1861 12 18.1861C15.8556 18.1861 19.4275 16.1819 22.0592 12.5428C22.1736 12.3849 22.2353 12.1948 22.2353 11.9997C22.2353 11.8047 22.1736 11.6146 22.0592 11.4567C19.4275 7.81851 15.8556 5.81428 12 5.81428C8.14441 5.81428 4.57249 7.81851 1.94083 11.4576C1.82635 11.6155 1.76471 11.8056 1.76471 12.0006C1.76471 12.1957 1.82635 12.3849 1.94083 12.5428Z"
                fill="white"
              />
            </svg>
          </div>

          <p className="fa-format-500 text-[14px] text-white">
            {item?.views_count}
          </p>
        </div>
        <div className='flex flex-col gap-[10px]'>
          <ClickReaction>
            <div
              onTouchStart={handleTouchStartInside}
              onClick={handleReport}
              className="bg-white bg-opacity-20 !w-[40px] !h-[40px]  rounded-[100px] flex flex-row justify-center items-center p-2 gap-2 "
            >
              <ReelsReportIcon/>
            </div>
          </ClickReaction>
          <ClickReaction>
            <div
              onTouchStart={handleTouchStartInside}
              onClick={handleMutePlayer}
              className="bg-white bg-opacity-20 !w-[40px] !h-[40px]  rounded-[100px] flex flex-row justify-center items-center p-2 gap-2 "
            >
              {mute ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_554_77117)">
                    <path
                      d="M21.4789 12.5153L23.794 10.2002C24.0686 9.92562 24.0686 9.48049 23.794 9.20595C23.5195 8.93135 23.0743 8.93135 22.7998 9.20595L20.4846 11.5211L18.1695 9.20595C17.8949 8.93135 17.4497 8.93135 17.1752 9.20595C16.9006 9.48054 16.9006 9.92567 17.1752 10.2002L19.4903 12.5153L17.1752 14.8304C16.9006 15.105 16.9006 15.5502 17.1752 15.8247C17.4497 16.0993 17.8949 16.0993 18.1695 15.8247L20.4846 13.5095L22.7998 15.8247C23.0743 16.0993 23.5195 16.0993 23.794 15.8247C24.0686 15.5501 24.0686 15.105 23.794 14.8304L21.4789 12.5153Z"
                      fill="white"
                    />
                    <path
                      d="M13.444 2.226C12.5699 1.82463 11.5734 1.96471 10.8438 2.59184L5.55896 7.13322H1.70312C1.31476 7.13322 1 7.44798 1 7.83635V16.7395C1 17.1277 1.31476 17.4426 1.70312 17.4426H5.55896L10.8438 21.984C11.2977 22.3742 11.8549 22.5758 12.4218 22.5758C12.766 22.5758 13.1137 22.5014 13.444 22.3498C14.3184 21.9483 14.8615 21.1012 14.8615 20.1392V4.43663C14.8615 3.47441 14.3184 2.62755 13.444 2.226ZM13.4552 20.1392C13.4552 20.5512 13.2316 20.8998 12.8572 21.0718C12.4827 21.2437 12.0728 21.186 11.7604 20.9174L6.52264 16.4167V8.15916L11.7604 3.65825C12.0728 3.38982 12.4829 3.33214 12.8572 3.50407C13.2316 3.67601 13.4552 4.02464 13.4552 4.43663V20.1392ZM2.40625 8.53947H5.11639V16.0364H2.40625V8.53947Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_554_77117">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2113_121616)">
                    <path
                      d="M16.2176 8.21139C15.9431 8.48586 15.9431 8.93118 16.2176 9.20565C16.4047 9.39278 16.5708 9.59512 16.7149 9.81008C17.1503 10.4594 17.3843 11.2229 17.3843 12.0224C17.3843 12.8218 17.1503 13.5854 16.7149 14.2348C16.5708 14.4496 16.4047 14.6519 16.2176 14.8391C15.9431 15.1137 15.9431 15.5589 16.2176 15.8333C16.3549 15.9707 16.5349 16.0393 16.7149 16.0393C16.8947 16.0393 17.0747 15.9707 17.212 15.8333C18.2299 14.8155 18.7906 13.4619 18.7906 12.0224C18.7906 10.5828 18.2299 9.22927 17.212 8.2112C16.9375 7.93673 16.4922 7.93673 16.2176 8.21139Z"
                      fill="white"
                    />
                    <path
                      d="M20.8956 4.52777C20.6211 4.25311 20.1758 4.25311 19.9013 4.52777C19.6266 4.80225 19.6266 5.24738 19.9013 5.52203C20.0754 5.69635 20.2411 5.87616 20.3984 6.06055C23.3193 9.48389 23.3193 14.5605 20.3984 17.9838C20.2411 18.1682 20.0754 18.348 19.9013 18.5222C19.6266 18.7968 19.6266 19.2419 19.9013 19.5166C20.0385 19.6539 20.2184 19.7224 20.3984 19.7224C20.5782 19.7224 20.7582 19.6539 20.8956 19.5166C22.8975 17.5147 23.9999 14.8531 23.9999 12.0221C23.9999 9.1911 22.8975 6.52948 20.8956 4.52777Z"
                      fill="white"
                    />
                    <path
                      d="M12.444 1.96037C11.5699 1.559 10.5734 1.69908 9.84375 2.32622L4.55896 6.8676H0.703125C0.314758 6.8676 0 7.18236 0 7.57072V16.4739C0 16.862 0.314758 17.177 0.703125 17.177H4.55896L9.84375 21.7184C10.2977 22.1086 10.8549 22.3102 11.4218 22.3102C11.766 22.3102 12.1137 22.2358 12.444 22.0842C13.3184 21.6827 13.8615 20.8356 13.8615 19.8736V4.171C13.8615 3.20879 13.3184 2.36192 12.444 1.96037ZM12.4552 19.8736C12.4552 20.2856 12.2316 20.6342 11.8572 20.8061C11.4827 20.9781 11.0728 20.9204 10.7604 20.6518L5.52264 16.151V7.89354L10.7604 3.39262C11.0728 3.12419 11.4829 3.06651 11.8572 3.23845C12.2316 3.41038 12.4552 3.75902 12.4552 4.171V19.8736ZM1.40625 8.27385H4.11639V15.7707H1.40625V8.27385Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2113_121616">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              )}
            </div>
          </ClickReaction>
          {/* mute */}

          {/* share */}
          <ClickReaction>
            <div
              onTouchStart={handleTouchStartInside}
              className="bg-white bg-opacity-20 !w-[40px] !h-[40px]  rounded-[100px] flex flex-row justify-center items-center p-2 gap-2 "
            >
              <RenderShare
                type={shareTypes?.REEL}
                id={item?.id}
                shareLoadingStyle="w-[24px] h-[24px]"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.5074 16.1435C17.3575 16.1435 16.3214 16.64 15.6024 17.43L9.13493 13.4243C9.3076 12.9823 9.40337 12.5022 9.40337 12C9.40337 11.4975 9.3076 11.0174 9.13493 10.5756L15.6024 6.56981C16.3214 7.35973 17.3575 7.85649 18.5074 7.85649C20.6735 7.85649 22.4357 6.09429 22.4357 3.92815C22.4357 1.76202 20.6735 0 18.5074 0C16.3412 0 14.579 1.7622 14.579 3.92834C14.579 4.43059 14.675 4.9107 14.8474 5.35271L8.38017 9.35832C7.66112 8.5684 6.62511 8.07164 5.47521 8.07164C3.30908 8.07164 1.54688 9.83403 1.54688 12C1.54688 14.1661 3.30908 15.9283 5.47521 15.9283C6.62511 15.9283 7.66112 15.4317 8.38017 14.6416L14.8474 18.6472C14.675 19.0893 14.579 19.5694 14.579 20.0718C14.579 22.2377 16.3412 24 18.5074 24C20.6735 24 22.4357 22.2377 22.4357 20.0718C22.4357 17.9057 20.6735 16.1435 18.5074 16.1435ZM16.0114 3.92834C16.0114 2.55212 17.1311 1.43243 18.5074 1.43243C19.8836 1.43243 21.0033 2.55212 21.0033 3.92834C21.0033 5.30455 19.8836 6.42424 18.5074 6.42424C17.1311 6.42424 16.0114 5.30455 16.0114 3.92834ZM5.47521 14.4959C4.09881 14.4959 2.97912 13.3762 2.97912 12C2.97912 10.6238 4.09881 9.50407 5.47521 9.50407C6.85143 9.50407 7.97093 10.6238 7.97093 12C7.97093 13.3762 6.85143 14.4959 5.47521 14.4959ZM16.0114 20.0716C16.0114 18.6954 17.1311 17.5757 18.5074 17.5757C19.8836 17.5757 21.0033 18.6954 21.0033 20.0716C21.0033 21.4478 19.8836 22.5675 18.5074 22.5675C17.1311 22.5675 16.0114 21.4478 16.0114 20.0716Z"
                    fill="white"
                  />
                </svg>
              </RenderShare>
            </div>
          </ClickReaction>
          {/* like  */}
          <div
            onTouchStart={handleTouchStartInside}
            className="flex flex-col justify-center items-center gap-1 "
          >
            <RenderReelLike fill="#ffffff" height={'h-[24px]'} likedFill='#F2C94C' hasBackground={true} showCount={true} id={item?.id} likeCount={item?.likes_count} />
          </div>

        </div>



        {/* download */}
        <ClickReaction>
          <div
            onClick={handlePlayTrackFromReel}
            onTouchStart={handleTouchStartInside}
            className="bg-white bg-opacity-20 rounded-[100px] flex flex-row justify-center items-center p-2 gap-2"
          >
            <p className="font-dana font-[500] text-[14px]  text-white">
              پخش آهنگ
            </p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2495_15145)">
                <path
                  d="M20.4853 3.51469C18.2188 1.24825 15.2053 0 12 0C8.79469 0 5.78123 1.24825 3.51469 3.51469C1.24825 5.78123 0 8.79469 0 12C0 15.2053 1.24825 18.2188 3.51469 20.4853C5.78123 22.7518 8.79469 24 12 24C15.2053 24 18.2188 22.7518 20.4853 20.4853C22.7518 18.2188 24 15.2053 24 12C24 8.79469 22.7518 5.78123 20.4853 3.51469ZM12 22.5155C6.20175 22.5155 1.48454 17.7983 1.48454 12C1.48454 6.20175 6.20175 1.48454 12 1.48454C17.7983 1.48454 22.5155 6.20175 22.5155 12C22.5155 17.7983 17.7983 22.5155 12 22.5155Z"
                  fill="white"
                />
                <path
                  d="M8.95898 16.6552L17.0188 12L8.95898 7.34473V16.6552Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2495_15145">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </ClickReaction>
      </div>
    </>

  );
};
