import React from 'react';

function UnFollowIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        fill="#ff0"
        d="M.759 2h16.482a.76.76 0 0 1 .759.759.76.76 0 0 1-.759.759H.759A.76.76 0 0 1 0 2.759.76.76 0 0 1 .759 2M.759 7.059h10.482a.76.76 0 0 1 .759.758.76.76 0 0 1-.759.759H.759A.76.76 0 0 1 0 7.817a.76.76 0 0 1 .759-.758M.759 12.117h6.482a.76.76 0 0 1 .759.759.76.76 0 0 1-.759.759H.759A.76.76 0 0 1 0 12.876a.76.76 0 0 1 .759-.759M.759 17.176h4.552a.76.76 0 0 1 .759.759.76.76 0 0 1-.759.758H.759A.76.76 0 0 1 0 17.935a.76.76 0 0 1 .759-.759"
      ></path>
      <path
        fill="none"
        stroke="#ff0"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="m11 15 2.561 2.561a.5.5 0 0 0 .774-.083L18.5 11"
      ></path>
    </svg>
  );
}

export default UnFollowIcon;
