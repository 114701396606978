import React from 'react';

function ShareSvg({ fill='#A7A7A9' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className="w-full h-full"
      viewBox="0 0 20 20"
    >
      <path
        fill={fill}
        d="M15.714 11.429a4.278 4.278 0 00-3.453 1.757l-3.877-1.933a4.273 4.273 0 000-2.506l3.877-1.933a4.279 4.279 0 003.453 1.757A4.29 4.29 0 0020 4.286 4.29 4.29 0 0015.714 0a4.29 4.29 0 00-4.098 5.539L7.74 7.472a4.279 4.279 0 00-3.453-1.758A4.29 4.29 0 000 10a4.29 4.29 0 004.286 4.286 4.279 4.279 0 003.453-1.758l3.877 1.933A4.29 4.29 0 0015.714 20 4.29 4.29 0 0020 15.714a4.29 4.29 0 00-4.286-4.285zm0-10a2.86 2.86 0 012.857 2.857 2.86 2.86 0 01-2.857 2.857 2.86 2.86 0 01-2.857-2.857 2.86 2.86 0 012.857-2.857zM4.286 12.857A2.86 2.86 0 011.429 10a2.86 2.86 0 012.857-2.857A2.86 2.86 0 017.143 10a2.86 2.86 0 01-2.857 2.857zm11.428 5.714a2.86 2.86 0 01-2.857-2.857 2.86 2.86 0 012.857-2.857 2.86 2.86 0 012.857 2.857 2.86 2.86 0 01-2.857 2.857z"
      ></path>
    </svg>
  );
}

export default ShareSvg;
