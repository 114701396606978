import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getArtists } from "../../services/ApiClient.js";
import InfiniteScroll from "react-infinite-scroll-component";
import HomeArtistCard from "../../components/home/HomeArtistCard.jsx";
import { useNavigate } from "react-router-dom";
import Container from "../../components/PageParentContainer/Container.jsx";
import { getInitialData } from "../../helper/ReduxSelectorContext.js";
import CollectionCategoryHeader from "../../components/playlist/CollectionCategoryHeader.jsx";
import AbsoluteBackground from "../../components/common/AbsoluteBackground.jsx";
import ScrollTopButton from "../../components/common/ScrollTopButton.jsx";
import CssShimmer from "../../components/common/CssShimmer.jsx";
import SongListLoading from "../../components/loading/SongListLoading.jsx";
import { VirtuosoGrid } from "react-virtuoso";

function Artist() {
  //todo:add title to artists app action
  const [loaded,setLoaded] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();
  const type = location?.state?.type;
  const {
    artists_header: {
      persian_artists_title: persianTitle,
      persian_artists_image: persianImage,
      foreign_artists_title: foreignTitle,
      foreign_artists_image: foreignImage,
    },
  } = getInitialData();

  const openArtist = (item) => {
    navigate(`/artist-profile/${item?.id}`, { state: { artistObject: item } });
  };
  const queryKey =
    type === "persian"
      ? "getPersianArtistListQuery"
      : "getForeignArtistListQuery";
  const { isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, data } =
    useInfiniteQuery(
      [queryKey],
      ({ pageParam = 0 }) => getArtists({ offset: pageParam, language: type }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const allData = allPages?.flatMap((page) => page.data.result.artists);
          return lastPage?.data?.result?.end ? false : allData?.length;
        },
        refetchOnWindowFocus: false,
      }
    );
  const artists = data?.pages?.flatMap((page) => page.data.result.artists);
  const getNextPage = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };
  useEffect(() => {
   setLoaded(true)
  }, []);

  return (
    <Container style="bg-primary_dark !overflow-hidden">
      <CollectionCategoryHeader
        title={type === "persian" ? persianTitle : foreignTitle}
        hasSearch={false}
        backgroundId="absolute-background"
        pageId="artists-page"
      />
      <AbsoluteBackground
        image={type === "persian" ? persianImage : foreignImage}
        animation={true}
      />
      <ScrollTopButton id={"artists-page"} />
      <div
        id="artists-page"
        className=" relative overflow-scroll h-full hide-scroll-bar z-10 "
        dir="rtl"
      >
        <div className={`h-full  w-full mb-10  pt-[208px]`}>
          <p className="!z-50 absolute font-kalameh font-medium text-lg text-white top-[100px] w-full flex justify-center items-center mx-auto">
            {type === "persian" ? persianTitle : foreignTitle}
          </p>
          <div className="flex gap-3  flex-col mt-2  p-6  max-[350px]:px-0">
            {isLoading ? (
              <div className="grid grid-cols-2 gap-4">
                {Array.from(
                  [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                    <div className="w-full aspect-square flex flex-col gap-2 items-center">
                      <CssShimmer classNames={"w-[156px] h-[156px] rounded-full"} />
                      <CssShimmer classNames={"h-3 w-24 rounded-[6px]"} />
                    </div>
                  ))
                )}
              </div>
            ) : artists?.length !== 0 ? (
              <InfiniteScroll
                className="!overflow-visible"
                scrollableTarget="artists-page"
                loader={
                  isFetchingNextPage &&
                  <div className="w-full grid justify-center items-center mt-4">
                    <SongListLoading />
                  </div>
                }
                next={getNextPage}
                hasMore={hasNextPage}
                dataLength={artists?.length || 0}
              >
                <VirtuosoGrid
                  data={artists}
                  customScrollParent={document.getElementById("artists-page")}
                  listClassName="!grid !grid-cols-2 !gap-4  max-[350px]:!gap-x-[0.1rem]"
                  itemContent={(index, pageData) => (
                    <div
                      key={pageData.id + "HomeArtistCard" + index}
                      onClick={() => openArtist(pageData)}
                      className="flex justify-center items-center h-max mx-auto z-10"
                    >
                      <HomeArtistCard item={pageData} imageSize='w-[156px] h-[156px]' />
                    </div>
                  )}
                />
              </InfiniteScroll>
            ) : (
              //  IF WANT HANDEL NO DATA CAN USE THIS BLOCK
              <div className="flex !flex-col !items-center !justify-center relative top-[20vh]  "></div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Artist;
