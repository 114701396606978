import React, { useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { getHomeData, getHomePageElements } from '../../services/ApiClient.js';
import RenderHomeData from '../../components/home/RenderHomeData.jsx';
import * as Sentry from '@sentry/react';
import {
  BrowserTypes,
  browserName,
  browserVersion,
  fullBrowserVersion,
  isChrome,
  mobileModel,
  osName,
  osVersion,
} from 'react-device-detect';
import Container from '../../components/PageParentContainer/Container.jsx';
import AbsoluteContainerBG from '../../components/PageParentContainer/AbsoluteContainerBG.jsx';
import HomeCssShimmer from '../../components/home/HomeCssShimmer.jsx';
import { FireBaseConf } from '../../helper/FireBaseConf.js';

import { useScrollPosition } from '../../hooks/useScrollPosition.js';
import CommonHeader from '../../components/common/CommonHeader.jsx';


function Home() {
  const { getFcmToken } = FireBaseConf();

  // const getHomeDataQuery = useQuery(['getHomeDataQuery'], () => getHomeData(), {
  //   refetchOnWindowFocus: false,
  // });

  const getHomePageElementsQuery = useQuery(
    ['getHomePageElements'],
    () => getHomePageElements(),
    {
      refetchOnWindowFocus: false,
    },
  );


  const memorizefunction = useMemo(
    () =>
      getHomePageElementsQuery?.data?.data?.result?.elements?.map(
        (item, index) => (
          <div className="z-[1]">
            <RenderHomeData key={index + 'RenderHomeData'} item={item} />
          </div>
        ),
      ),
    [getHomePageElementsQuery?.data],
  );
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('homeR', 1);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  const emmitVersionToSentry = () => {
    Sentry.captureMessage(
      `Version_${osName}_${osVersion} / Broswer_version${
        browserName + '_' + fullBrowserVersion
      }`,
      {
        level: 'log',
      },
    );
  };

  useEffect(() => {
    emmitVersionToSentry();
    if (navigator.storage && navigator.storage.estimate) {
      // Sentry.captureMessage(`estimate Storage:${navigator.storage.estimate}`, {
      //   level: 'log',
      // });
      navigator.storage.estimate().then(estimate => {
        // Sentry.captureMessage(
        //   `estimated Storage promise estimate.quota:${estimate.quota}`,
        //   {
        //     level: 'log',
        //   },
        // );
        // Sentry.captureMessage(
        //   `estimated Storage promise estimate.usage:${estimate.usage}`,
        //   {
        //     level: 'log',
        //   },
        // );
        // Sentry.captureMessage(
        //   `estimated Storage promise percent:${(
        //     (estimate.usage * 100) /
        //     estimate.quota
        //   ).toFixed(0)}`,
        //   {
        //     level: 'log',
        //   },
        // );
      });
    }

    if (navigator.storage && navigator.storage.persist) {
      navigator.storage.persist().then(persistent => {
        if (persistent) {
          // Sentry.captureMessage(`persist Storage:${persistent}`, {
          //   level: 'log',
          // });
          // console.log(
          //   'Storage will not be cleared except by explicit user action',
          //   persistent,
          // );
        } else {
          // console.log(
          //   'Storage may be cleared by the UA under storage pressure.',
          // );
          // Sentry.captureMessage(`persist Storage:${persistent}`, {
          //   level: 'log',
          // });
        }
      });
    } else {
      // Sentry.captureMessage(`persist Storage: Not Supported`, { level: 'log' });
    }
  }, []);

  useEffect(() => {
    if (window.Notification && Notification.permission === 'granted') {
      getFcmToken();
    }
  }, []);

  useScrollPosition({ pageId: 'page-header' });
  return (
    <Container
      shadow={false}
      header={<CommonHeader title="ملودیفای" />}
    >
      {getHomePageElementsQuery.isLoading ? (
        <HomeCssShimmer />
      ) : (
        <div class="w-full h-full flex flex-col relative    ">
          <AbsoluteContainerBG />
          {memorizefunction}
        </div>
      )}
    </Container>
  );
}

export default Home;
