import React from 'react';

function MeloPostFileIcon(props) {
  return (
    <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25.5224 0H8.95623C6.2664 0 4.07805 2.15538 4.07805 4.80469V36.1953C4.07805 38.8446 6.2664 41 8.95623 41H32.1999C34.8897 41 37.0781 38.8446 37.0781 36.1953V11.4396L25.5224 0ZM26.4273 3.5L33.5781 10.4991H28.0533C27.1567 10.4991 26.4273 9.78066 26.4273 8.89756V3.5ZM32.1999 39H9.07805C8.18144 39 6.07805 37.0784 6.07805 36.1953V4.80469C6.07805 3.92159 8.05962 2 8.95623 2H24.5781V5.5V8.89756C24.5781 11.5469 25.3635 12.5 28.0533 12.5H31.5404H35.0781V36.1953C35.078 37.0784 33.0965 39 32.1999 39ZM26.6379 22.8378C26.9882 23.1829 26.9546 23.7574 26.5665 24.0593C26.2427 24.3111 25.7821 24.2824 25.4921 23.9924L23.9998 22.5L23.4998 22L21.4998 20V32.2324C21.4998 32.7847 21.052 33.2324 20.4998 33.2324C19.9475 33.2324 19.4998 32.7847 19.4998 32.2324V20L17.9998 21.5L16.4998 23L15.5073 23.9924C15.2174 24.2823 14.757 24.3107 14.4336 24.0587C14.0466 23.757 14.0134 23.1837 14.363 22.8394L20.0114 17.2761C20.2831 17.0085 20.7194 17.0085 20.9911 17.2761L26.6379 22.8378Z'
        fill='#F2C94C' />
    </svg>

  );
}

export default MeloPostFileIcon;
