import React from 'react';

function FreeAccountCrown(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='43'
      height='43'
      fill='none'
      viewBox='0 0 43 43'
    >
      <rect width='43' height='43' fill='#303030' rx='21.5'></rect>
      <g clipPath='url(#clip0_2113_183458)'>
        <path
          fill='#E0E0E0'
          d='M16.454 29.994a.94.94 0 0 1-.946-.931V16.029c0-.514.423-.93.946-.93s.945.415.945.93v13.034a.94.94 0 0 1-.945.931'
        ></path>
        <path
          fill='#BDBDBD'
          d='M29.814 26.27a.94.94 0 0 1-.945-.931V12.305c0-.514.422-.93.945-.93s.945.415.945.93V25.34a.94.94 0 0 1-.945.931'
        ></path>
        <path
          fill='#E0E0E0'
          d='m29.55 8.556-6.416 1.788-6.945 1.936a.94.94 0 0 0-.68.894v6.815l7.625-2.123 7.625-2.123V9.45c0-.614-.605-1.061-1.21-.894M13.618 32.787c-2.086 0-3.782-1.67-3.782-3.724s1.696-3.724 3.782-3.724c2.085 0 3.78 1.67 3.78 3.724s-1.695 3.724-3.78 3.724'
        ></path>
        <path
          fill='#BDBDBD'
          d='M26.915 29.063c-2.085 0-3.781-1.67-3.781-3.724s1.696-3.724 3.781-3.724 3.844 1.67 3.844 3.724-1.759 3.724-3.844 3.724M30.76 9.45v6.294l-7.626 2.122v-7.522l6.415-1.788c.605-.167 1.21.28 1.21.894'
        ></path>
      </g>
      <defs>
        <clipPath id='clip0_2113_183458'>
          <path fill='#fff' d='M9 8h22v26H9z'></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default FreeAccountCrown;
