export const SearchTabBarItem = {
  ALL: "all", //redirect ticket page Done
  TRACKS: "tracks",
  PLAYLISTS: "playlists",
  ARTISTS: "artists",
  ALBUMS: "albums",
  USER_COLLECTION: "user_collection'",
  USER: "user"
};


