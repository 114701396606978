import React from 'react';

function CopySecondTypeIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='16'
      fill='none'
      viewBox='0 0 17 16'
    >
      <g clipPath='url(#clip0_4244_20060)'>
        <path
          fill='#F2C94C'
          d='M14.43 15.492H6.46a1.76 1.76 0 0 1-1.757-1.758V5.765c0-.969.789-1.757 1.758-1.757h7.969c.969 0 1.758.788 1.758 1.757v7.97a1.76 1.76 0 0 1-1.758 1.757M6.46 5.179a.587.587 0 0 0-.585.586v7.97c0 .322.263.585.586.585h7.969a.587.587 0 0 0 .586-.586V5.765a.587.587 0 0 0-.586-.586zm-2.929 5.625h-.586a.587.587 0 0 1-.586-.585v-7.97c0-.322.263-.585.586-.585h7.97c.322 0 .585.263.585.586v.556h1.172V2.25A1.76 1.76 0 0 0 10.914.492H2.945A1.76 1.76 0 0 0 1.188 2.25v7.969c0 .969.788 1.757 1.757 1.757h.586z'
        ></path>
      </g>
      <defs>
        <clipPath id='clip0_4244_20060'>
          <path fill='#fff' d='M.695 0h16v16h-16z'></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CopySecondTypeIcon;
