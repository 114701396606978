import { Drawer } from '@mui/material';
import React, { useEffect } from 'react';
import TrackActionsBottom from '../player/TrackActionsBottom';
import ChevronLeft from '../svg/common/ChevronLeft';
import {
  getLyricFullScreen,
  getPlayerMusic,
} from '../../helper/ReduxSelectorContext';
import { useDispatch } from 'react-redux';
import {
  setLyricFullScreenStatus,
  toggleLyricFullScreen,
} from '../../redux/player/PlayMusicSlice';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';

import OpenLyricFullScreen from '../player/OpenLyricFullScreen';
import LyricReportBox from './LyricReportBox';
import FullLyricSeekbar from './FullLyricSeekbar';
import WhitePlayPauseButton from '../player/WhitePlayPauseButton';
import { setLanguageDirection } from '../../helper/LanguageDetection.js';
import TextMarquee from '../common/TextMarquee.jsx';
import { useNativeBack } from '../../hooks/useNativeBack.js';
import { PlayerHeader } from '../player/PlayerHeader.jsx';
import HeartSvg from '../svg/common/HeartSvg.jsx';

function LyricFullScreenDrawer() {
  const isLyricFullScreen = getLyricFullScreen();
  let playedTrackData = getPlayerMusic();
  const dispatch = useDispatch();
  const handleOpenFullScreen = newOpen => {
    dispatch(toggleLyricFullScreen());

    // setOpenFullScreen(newOpen);
  };

  //close lyric full screen when track dont have lyric
  useEffect(() => {
    if (playedTrackData?.lyric?.has_lyric == false) {
      dispatch(setLyricFullScreenStatus({ status: false }));
    }
  }, [playedTrackData]);
  useNativeBack(
    isLyricFullScreen,
    'lyric-full-screen-drawer',
    handleOpenFullScreen,
  );
  const handleCloseButtonSheet = () => {
    dispatch(setLyricFullScreenStatus({ status: false }));
  };
  return (
    <Drawer
      open={isLyricFullScreen}
      onClose={handleOpenFullScreen}
      onOpen={handleOpenFullScreen}
      swipeAreaWidth={70}
      className="!relative !overflow-hidden !z-[101]"
      anchor="bottom"
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '520px',
          height: '100%',
          margin: 'auto',
          borderRadius: 0,
          backgroundColor: '#171717',
          // borderRadius: "20px",
        },
      }}
      transitionDuration={{
        enter: 200,
        exit: 200,
        appear: 1000,
      }}
      disableSwipeToOpen={true}
      ModalProps={{
        keepMounted: false,
      }}
    >
      <div
        className=" !h-full  flex flex-col "
        style={{
          background:
            'linear-gradient(180deg, rgba(14, 14, 14, 0.8) 0%, rgba(14, 14, 14, 0.9) 100%)',
        }}
      >
        <div
          className="absolute top-0 z-10 w-full h-full"
          style={{
            background:
              // "linear-gradient(180deg,rgba(33,33,33,.3) .5%,rgba(33,33,33,.3) 20%,rgba(33,33,33,.7) 30%,rgba(33,33,33,.9) 40%,#212121 49%)",
              // ' linear-gradient(180deg, rgba(14, 14, 14, 0.2) 0%, rgba(14, 14, 14, 0.85) 100%)',
              'linear-gradient(180deg, rgba(14, 14, 14, 0.8) 0%, rgba(14, 14, 14, 0.9) 100%)',
          }}
        ></div>
        <div
          className="absolute top-0 z-0 w-full h-full "
          style={{ background: playedTrackData?.background_color }}
        ></div>

        <div className="w-full h-full absolute top-0 z-40 flex flex-col p-4 full-screen-lyrics ">
          <PlayerHeader handleBack={handleCloseButtonSheet} className=" mb-3" />
          <div className="w-full h-[56px] flex flex-row justify-between">
            {/* title */}
            <div className="w-full flex flex-row justify-between items-center">
              <div>
                <HeartSvg height={'h-[23px]'} id={playedTrackData?.id} />
              </div>
              <div className="grid grid-cols-12 gap-3 w-full items-center  ">
                <div
                  dir="rtl"
                  className="flex col-span-10 flex-col justify-between h-full items-center "
                >
                  <div
                    dir="rtl"
                    class="font-dana w-full font-[700]  text-[20px]  text-white leading-6 overflow-hidden flex flex-row gap-2 items-center"
                  >
                    <div dir={'ltr'} className="w-[calc(100%-32px)] ">
                      <TextMarquee
                        noMarqueeParentStyle={'justify-end'}
                        direction={'rtl'}
                        key={playedTrackData?.id + 'lyric-marquee'}
                      >
                        {playedTrackData?.title}
                      </TextMarquee>
                    </div>
                  </div>
                  <div class=" w-full flex flex-row  items-center gap-2 ">
                    <div
                      dir={'ltr'}
                      className="font-dana font-[500]   text-[16px] w-[calc(100%-32px)] text-gray_4 "
                    >
                      <TextMarquee
                        noMarqueeParentStyle={'justify-end'}
                        direction={'rtl'}
                        key={playedTrackData?.id + 'lyric-marquee'}
                      >
                        {playedTrackData?.artists?.map((artist, index) => {
                          return (
                            <span key={index}>
                              {artist?.name}{' '}
                              {index !== playedTrackData?.artists?.length - 1 &&
                                (setLanguageDirection(
                                  playedTrackData?.title,
                                ) === 'rtl'
                                  ? '، '
                                  : ', ')}
                            </span>
                          );
                        })}
                      </TextMarquee>
                    </div>
                  </div>
                </div>

                <div class=" relative col-span-2 shadow-playlist-shadow rounded-[5px]   overflow-hidden w-[56px] h-[56px] ">
                  <CustomLazyLoadImage image={playedTrackData?.image_large} />
                </div>
              </div>
            </div>
          </div>
          <div className="relative !overflow-hidden  h-full z-30">
            <TrackActionsBottom type="FULL_SCREEN" />
          </div>
          <div
            className="w-full relative flex flex-col justify-between rounded-b-[20px]"
            style={{ background: playedTrackData?.background_color }}
          >
            <div className=" px-6 pb-2">
              <FullLyricSeekbar />
            </div>
            {/* report item */}
            <div className="w-full px-4 pb-4 mx-auto flex flex-row h-[60px] items-center justify-between relative">
              <LyricReportBox style />
              <div className="absolute z-30 bottom-3 left-[50%] translate-x-[-50%] ">
                <WhitePlayPauseButton />
              </div>
              {/* full screen lyric icon */}

              <OpenLyricFullScreen style close />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default React.memo(LyricFullScreenDrawer);
