import React, { useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DraggableSongDetailCard from '../../playlist/DraggableSongDetailCard.jsx';
import { updateUserCollectionTrackSort } from '../../../services/ApiClient.js';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import FullPageNoteLoading from '../../loading/FullPageNoteLoading.jsx';

function DragDropContainer({ list, CollectionId,setTrackListQueryData }) {
  const [dragList, setDragList] = useState(list);
  const navigate = useNavigate();
  const updateUserCollectionTrackSortMutation = useMutation(
    data => updateUserCollectionTrackSort(data),
    {
      onSuccess: response => {
        console.log(response);
        if (response?.data?.done) {
          setTrackListQueryData(dragList)
          navigate(-1);
        }
      },
    },
  );
  const reorderTracks = (tracks, sourceIndex, destinationIndex) => {
    // Ensure the sourceIndex and destinationIndex are within the bounds of the array
    if (
      sourceIndex < 0 ||
      sourceIndex >= tracks.length ||
      destinationIndex < 0 ||
      destinationIndex >= tracks.length
    ) {
      throw new Error('Invalid source or destination index');
    }

    // Remove the track from the sourceIndex
    let trackList = [...tracks];
    const [trackToMove] = trackList.splice(sourceIndex, 1);

    // Insert the track at the destinationIndex
    trackList.splice(destinationIndex, 0, trackToMove);

    // Return the updated array of tracks
    return trackList;
  };
  const onDragEnd = result => {
    let newList = dragList;
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination?.droppableId === source?.droppableId &&
      destination?.index === source?.index
    ) {
      return;
    }
    newList = reorderTracks(newList, source?.index, destination?.index);
    setDragList(newList);
    updateUserCollectionTrackSortMutation.mutate({
      user_collection_id: CollectionId,
      target_sort: destination?.index + 1,
      track_id: list?.[source?.index]?.id,
    });
  };
  const onDragStart = () => {
    console.log('log start drag...');
  };
  return (
    <>
        {updateUserCollectionTrackSortMutation?.isLoading &&
          <FullPageNoteLoading/>
        }
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Droppable droppableId="list">
                {provided => (
                  <div
                    className="flex flex-col gap-2"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                      {dragList?.map((item, index) => (
                        <DraggableSongDetailCard
                          key={`item-${item?.id}`}
                          item={item}
                          index={index}
                        />
                      ))}
                      {provided?.placeholder}
                  </div>
                )}
            </Droppable>
        </DragDropContext>
    </>

  );
}

export default DragDropContainer;
