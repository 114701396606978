import React from 'react';

function ShareDownloadIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      fill='none'
      viewBox='0 0 30 30'
    >
      <circle cx='15' cy='15' r='15' fill='#F2C94C'></circle>
      <g clipPath='url(#clip0_4244_20005)'>
        <g clipPath='url(#clip1_4244_20005)'>
          <g clipPath='url(#clip2_4244_20005)'>
            <g clipPath='url(#clip3_4244_20005)'>
              <g fill='#212121' clipPath='url(#clip4_4244_20005)'>
                <path
                  d='M15.677 21.059c-.571 0-1.062-.524-1.46-.876l-3.889-3.5c-.397-.352-.486-.808-.486-1.313 0-.438.436-1.313 1.603-1.313h1.314V9.68c0-1.033.341-1.75 1.508-1.75h2.868c1.167 0 1.459.438 1.459 1.75v4.377h.972c.122-.01 1.114-.003 1.699.836.203.292.271.663.2 1.011-.061.292-.189.556-.44.779l-3.89 3.5c-.397.352-.888.876-1.459.876m-4.232-6.127a.705.705 0 0 0-.705.706v.044c0 .172.072.33.202.446l4.233 3.75c.13.116.31.18.503.18a.75.75 0 0 0 .503-.18l4.233-3.75a.59.59 0 0 0 .202-.446c0-.344-.174-.75-.563-.75h-1.725a.707.707 0 0 1-.706-.707V9.292a.487.487 0 0 0-.487-.486h-2.868a.535.535 0 0 0-.535.536v5.153a.44.44 0 0 1-.438.438h-1.849'></path>
                <path
                  d='M21.32 23.684H9.84c-1.167 0-1.944-.717-1.944-1.75v-1.251c0-.085.03-.159.074-.221.192-.27.654-.306.84-.032a.33.33 0 0 1 .059.19v1.313c0 .345.582.876.97.876h11.48c.39 0 1.163-.532 1.163-.876v-1.25q0-.057.016-.106c.122-.395.818-.395.94 0q.015.05.016.105v1.252c0 1.033-.967 1.75-2.134 1.75'></path>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_4244_20005'>
          <path fill='#fff' d='M3 3h24v24H3z'></path>
        </clipPath>
        <clipPath id='clip1_4244_20005'>
          <path fill='#fff' d='M3 3h24v24H3z'></path>
        </clipPath>
        <clipPath id='clip2_4244_20005'>
          <path fill='#fff' d='M3.424 3h24.615v24H3.424z'></path>
        </clipPath>
        <clipPath id='clip3_4244_20005'>
          <path fill='#fff' d='M6.924 6.807h18v18h-18z'></path>
        </clipPath>
        <clipPath id='clip4_4244_20005'>
          <path fill='#fff' d='M6.924 7.807h18.06v16H6.924z'></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ShareDownloadIcon;
