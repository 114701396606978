import React,{ Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import DialogHeader from '../dialogs/DialogHeader.jsx';

function UnfollowDialog({openState,confirmFunction,type,closeDialog}) {
  return (
    <Transition appear show={openState} as={Fragment}>
      <Dialog as="div" className="relative z-[91]" onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-black bg-opacity-50">
          <div
            id={'dialog-transition'}
            className="flex min-h-full items-center justify-center p-4 text-center"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className=" w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-primary_dark  text-left align-middle shadow-xl transition-all"
              >
                <DialogHeader
                  header_text={'لغو دنبال کردن'}
                  onCloseDialog={closeDialog}
                />

                <div className="px-6 flex flex-col gap-3 pt-4 ">
                  <p className="text-primary leading-8 text-gray_6 text-[16px] font-medium text-center font-dana ">
                    مطمئن هستید
                    <br />
                    که میخواهید دنبال نکنید؟
                  </p>
                  {type === 'artist' ? (
                    <p className="text-gray_6 text-[14px] font-medium text-center font-dana leading-8  ">
                      دیگر از انتشار آهنگ های جدید این خواننده باخبر نخواهید شد!
                    </p>
                  ) : (
                    <p className="text-gray_6  text-[14px] font-medium text-center font-dana leading-8  ">
                      دیگر از به روزرسانی این پلی لیست با خبر نمی شوید!
                    </p>
                  )}
                </div>

                <div
                  onClick={() => {
                    confirmFunction();
                  }}
                  className=" mt-6 flex items-center justify-center px-4 pb-4  "
                >
                  <button
                    type="button"
                    className="inline-flex bg-primary text-[16px] w-full text-title_box font-dana font-medium     justify-center rounded-[23px] border border-transparent  px-4  py-3  focus:outline-none"
                  >
                    مطمئن هستم
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default UnfollowDialog;
