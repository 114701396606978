import React from 'react';
import UserPlaylistItem from "./UserPlaylistItem.jsx";
import {Virtuoso} from "react-virtuoso";

function UserPlaylistList({collections,inBottomSheet=false,onCloseBottomSheet,targetId='#user-playlist-page-sheet'}) {
  console.log(collections,'collections...');
    return (

            <Virtuoso

                customScrollParent={document.getElementById(targetId)}
                data={collections}
                itemContent={(index, collection) => (
                    <div className='pb-4'>
                    <UserPlaylistItem inBottomSheet={inBottomSheet} closeSheet={onCloseBottomSheet} collection={collection} key={`${collection?.id}user-playlist`}/>

                    </div>
                )}
            />


    );
}

export default UserPlaylistList;
