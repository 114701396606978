import React from 'react';
import CustomLazyLoadImage from '../../common/CustomLazyLoadImage';
import { MedilDoneSvg } from '../../svg/profile/MedilDoneSvg';
import { Slider } from '@mui/material';
import clsx from 'clsx';

export const MedalCardItem = ({ item }) => {
  console.log('asfasfsafasfasf', item);

  return (
    <div
      dir="rtl"
      className={clsx(
        'flex flex-col justify-between items-center p-3 border border-gray_1 rounded-[10px] ',
        item?.is_completed ? 'bg-medal-card-gradient' : 'bg-gray_1',
      )}
    >
      <div className="w-full h-full flex flex-row justify-between items-center">
        <div className="flex flex-row  gap-2 relative">
          <div className="min-w-[47px]  w-[47px] h-[47px] relative">
            <CustomLazyLoadImage
              imageClass="!static !w-full !h-full"
              image={item?.image}
            />
          </div>
          <div className=" flex flex-col gap-2 items-start ">
            <span className="font-dana font-bold text-sm text-primary">
              {item?.title}
            </span>

            <span className="font-dana font-medium text-[10px] text-gray_4">
              {item?.description}
            </span>
          </div>
        </div>
        {item?.is_completed ? (
          <span className="min-w-[30px]">
            <MedilDoneSvg />
          </span>
        ) : null}
      </div>
      {item?.progress ? (
        <div className="flex flex-col items-end  w-full gap-1">
          <span className="fa-format-500  font-medium text-[12px] text-white ">
            <span className="text-primary">{item?.progress?.numerator}</span> از{' '}
            {item?.progress?.denominator}
          </span>
          <Slider
            className="!w-full !h-[4px] !m-0 !p-0 border-none   !cursor-default "
            value={item?.progress?.numerator}
            aria-label="Small"
            max={item?.progress?.denominator}
            disableSwap
            sx={{
              color: '#F2C94C',
              height: 4,
              '& .MuiSlider-thumb': {
                width: 0,
                height: 0,

                boxShadow: 'none',
                '&:hover, &.Mui-focusVisible': {
                  boxShadow: 'none',
                },
              },
              '& .MuiSlider-rail': {
                backgroundColor: '#828282',
                opacity: 1,
                height: 4,
              },
              transform: 'scaleX(-1)',
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
