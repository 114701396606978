import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAction } from '../../hooks/useAction.js';
import useWindowDimensions from '../../helper/ScreenSize.js';

function PlayListCard({ children, itemData }) {
  const action = useAction();
  const openSubCategory = () => {
    if (!itemData) {
      return;
    }
    action({ target_type: 'collection_category', children: itemData });
  };
  return (
    <div
      onClick={openSubCategory}
      className={`z-30  overflow-hidden w-full shadow-collection-shadow  relative rounded-[10px] `}
    >
      {children}
    </div>
  );
}

export default PlayListCard;
