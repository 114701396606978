import React from "react";
import HomeMetaDataWithTitle from "../common/HomeMetaDataWithTitle";
import HomeWeekNew from "./HomeWeekNew";
import PlaylistMetaDataWithTitle from "../playlist/PlaylistMetaDataWithTitle.jsx";
import { HomeSectionBackgroundOverlay } from "../common/HomeSectionBackgroundOverlay.jsx";

function HomeCollectionWithCorner({
  item,
  artistCollection = false,
  fromMyMelodify,
  inPlaylistPage = false,
  cardStyle,
}) {
  return (
    <div
      class={`  ${
        item?.collection_meta_data?.has_background && 'bg-background  '
      }  relative z-20`}
    >
      {/* <div
        className={`w-full pr-3 py-3 z-10 relative ${
          fromMyMelodify && "!px-4 !py-0"
        }`}
      >
        {fromMyMelodify ? (
          <PlaylistMetaDataWithTitle
            item={item?.collection_items}
            artistCollection={artistCollection}
            metaData={item?.collection_meta_data}
            eventClassName={{
              prev: "prev-button" + item?.collection_meta_data?.type,
              next: "next-button" + item?.collection_meta_data?.type,
            }}
            fromMyMelodify={fromMyMelodify}
          />
        ) : (
          <HomeMetaDataWithTitle
            item={item?.collection_items}
            artistCollection={artistCollection}
            metaData={item?.collection_meta_data}
            eventClassName={{
              prev: "prev-button" + item?.collection_meta_data?.type,
              next: "next-button" + item?.collection_meta_data?.type,
            }}
            fromMyMelodify={fromMyMelodify}
          />
        )}
      </div> */}
        <HomeSectionBackgroundOverlay
             background_color={item?.element_meta?.background_color}
           />
      <HomeWeekNew
        key={'HomeWeekNew'}
        cardStyle={cardStyle}
        fromMyMelodify={fromMyMelodify}
        artistCollection={artistCollection}
        type={item?.collection_meta_data?.type}
        item={item?.element_data}
        metaData={item?.collection_meta_data}
      />
    </div>
  );
}

export default HomeCollectionWithCorner;
