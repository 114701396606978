export const MelopostsTabsItems = [
  {
    title:'محبوب‌ترین‌ها',
    value:'trend'
  },
  {
    title:'جدیدترین‌ها',
    value:'new'
  },
  {
    title:'دنبال می‌کنید',
    value:'followed'
  }
]
