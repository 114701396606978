import { CardActionArea } from '@mui/material';
import React from 'react';
import CustomImage from '../../common/CustomImage';
import { BoldText } from '../../svg/common/BoldText';
import { useNavigate } from 'react-router-dom';
import { SYSTEM_NOTIFY_TYPE } from '../../../types/SystemNotifyType';
import { useAction } from '../../../hooks/useAction';
import { useInAppNotification } from '../../../consts/useInAppNotification';

export const LikedMelopostViewType = ({ item }) => {
  const navigate = useNavigate();
  const action = useAction();
  const { emitNotifSeen } = useInAppNotification();
  const handleClickItem = () => {
    action(item?.action);

    emitNotifSeen(item?.id);
  };
  return (
    <CardActionArea>
      <div
        dir="rtl"
        className="bg-notifiy-granient w-full h-[40px] flex flex-row  justify-between items-center "
        onClick={handleClickItem}
      >
        <div className="flex flex-row justify-start gap-1 items-center">
          <div className="w-[34px] h-[34px] border border-gray_2 rounded-full flex justify-center items-center">
            <CustomImage src={item?.image} />
          </div>
          <div className=" flex flex-row items-center gap-1 flex-wrap">
            <div className="text-gray_4 font-dana text-xs ">
              <BoldText keyword={item?.bold} text={item?.message} />
            </div>

            <span className="text-gray_3 font-dana text-[10px] font-[300]  ">
              {item?.date}
            </span>
          </div>
        </div>
      </div>
    </CardActionArea>
  );
};
