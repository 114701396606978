import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from 'swiper/react';
import HomeArtistCard from './HomeArtistCard';
import { useNavigate } from 'react-router-dom';
import HomeChildMetaData from '../common/HomeChildMetaData';
import { FreeMode } from 'swiper';
import { IsIosDevice } from '../../helper/CheckDevice';
import { FixedSizeList as List } from 'react-window';
import { memo } from 'react';
import { useInView } from 'react-intersection-observer';

const Column = ({ data, index, style }) => {
  const navigate = useNavigate();
  const openArtist = item => {
    navigate(`/artist-profile/${item?.id}`, { state: { artistObject: item } });
  };
  return (
    <div onClick={() => openArtist(data[index])} style={style}>
      <HomeArtistCard item={data[index]} />
    </div>
  );
};
function HomeArtistSlider({ item, disabledTitle = false }) {
  console.log('Afasfasfas', item);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const navigate = useNavigate();
  const openArtist = item => {
    navigate(`/artist-profile/${item?.id}`, { state: { artistObject: item } });
  };
  return (
    <div className="w-full flex flex-row-reverse justify-between h-full items-center relative ">
      <div class=" w-full  h-full ">
        <div ref={ref} class="flex flex-col w-full h-fit z-20">
          {IsIosDevice() ? (
            <div
              dir="rtl"
              className="!w-full !h-fit flex flex-col pr-r10 !select-none  playlist-swiper new-song-swiper artist-swiper "
            >
              {inView ? (
                <List
                  direction="rtl"
                  height={163}
                  itemCount={item?.length}
                  itemSize={146}
                  layout="horizontal"
                  width={400}
                  style={{ width: '100% !important' }}
                  itemData={item}
                >
                  {Column}
                </List>
              ) : (
                <div className="h-[133px] w-full"></div>
              )}
            </div>
          ) : (
            <Swiper
              // lazy={true}
              freeMode={true}
              cssMode={IsIosDevice()}
              slidesPerView={'auto'}
              spaceBetween={16}
              dir="rtl"
              className="!w-full !h-fit flex flex-col-reverse pr-r10 !select-none  playlist-swiper new-song-swiper artist-swiper "
              modules={[FreeMode]}
            >
              {item?.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className=" last:!pl-3 first:!pr-3 !w-fit mt-3"
                >
                  <div
                    onClick={() => openArtist(item)}
                    class="flex flex-col gap-[32px] lg-1440:gap-r32  first:!ml-r24"
                  >
                    {/* <!-- persian --> */}

                    <HomeArtistCard item={item} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(HomeArtistSlider);
