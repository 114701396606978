import React from 'react'

function PlayListSearchIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5924 1.19389L9.674 0.0868366C9.23194 -0.168188 8.68962 0.16905 8.68962 0.70061V8.42336H7.35755C5.89717 8.42336 4.70898 9.67424 4.70898 11.2117C4.70898 12.721 5.86531 14 7.37088 14C8.83126 14 10.0194 12.7491 10.0194 11.2117C10.0194 10.7241 10.0194 2.34433 10.0194 1.88258L10.9533 2.42162C12.0121 3.03284 12.6701 4.19986 12.6701 5.46733V6.96558C12.6701 7.35204 12.9677 7.66558 13.335 7.66558C13.7023 7.66558 13.9999 7.35204 13.9999 6.96558V5.46733C13.9999 3.68836 13.0773 2.05102 11.5924 1.19389ZM8.68945 11.2117C8.68945 11.9567 8.12237 12.6 7.35755 12.6C6.63047 12.6 6.03881 11.9771 6.03881 11.2117C6.03881 10.4462 6.63047 9.82335 7.35755 9.82335H8.68945V11.2117Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9533 2.42162C12.0121 3.03284 12.6701 4.19986 12.6701 5.46733V6.96558C12.6701 7.35204 12.9677 7.66558 13.335 7.66558C13.7023 7.66558 13.9999 7.35204 13.9999 6.96558V5.46733C13.9999 3.68836 13.0773 2.05102 11.5924 1.19389L9.674 0.0868366C9.23194 -0.168188 8.68962 0.16905 8.68962 0.70061V8.42336H7.35755C5.89717 8.42336 4.70898 9.67424 4.70898 11.2117C4.70898 12.721 5.86531 14 7.37088 14C8.83126 14 10.0194 12.7491 10.0194 11.2117V1.88258L10.9533 2.42162ZM9.41103 0.599208C9.41101 0.599195 9.41106 0.59922 9.41103 0.599208C9.32909 0.551996 9.23974 0.619363 9.23974 0.70061V9.00669H7.35755C6.19725 9.00669 5.2591 10.0004 5.2591 11.2117C5.2591 12.4014 6.1715 13.4167 7.37088 13.4167C8.53118 13.4167 9.46932 12.423 9.46932 11.2117V0.900869L11.2163 1.90928C12.4501 2.62151 13.2202 3.98385 13.2202 5.46733V6.96558C13.2202 7.02602 13.2679 7.08224 13.335 7.08224C13.4021 7.08224 13.4498 7.02602 13.4498 6.96558V5.46733C13.4498 3.90415 12.6392 2.46228 11.3294 1.70626M11.3294 1.70626L9.41103 0.599208L11.3294 1.70626ZM9.23957 9.24002V11.2117C9.23957 12.274 8.4308 13.1833 7.35755 13.1833C6.33039 13.1833 5.48869 12.3032 5.48869 11.2117C5.48869 10.1201 6.33039 9.24002 7.35755 9.24002H9.23957ZM7.35755 12.6C8.12237 12.6 8.68945 11.9567 8.68945 11.2117V9.82335H7.35755C6.63047 9.82335 6.03881 10.4462 6.03881 11.2117C6.03881 11.9771 6.63047 12.6 7.35755 12.6Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-4.85695e-08 2.23958C-2.17453e-08 1.91742 0.246297 1.65625 0.550121 1.65625L6.88751 1.65625C7.19133 1.65625 7.43763 1.91742 7.43763 2.23958C7.43763 2.56175 7.19133 2.82292 6.88751 2.82292L0.55012 2.82292C0.246297 2.82292 -7.53937e-08 2.56175 -4.85695e-08 2.23958Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-4.85695e-08 5.03841C-2.17453e-08 4.71625 0.246297 4.45508 0.550121 4.45508L6.88751 4.45508C7.19133 4.45508 7.43763 4.71625 7.43763 5.03841C7.43763 5.36058 7.19133 5.62175 6.88751 5.62175L0.55012 5.62174C0.246297 5.62174 -7.53937e-08 5.36058 -4.85695e-08 5.03841Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-4.85696e-08 7.84115C-2.17454e-08 7.51898 0.246297 7.25781 0.550121 7.25781L4.77505 7.25781C5.07887 7.25781 5.32517 7.51898 5.32517 7.84115C5.32517 8.16331 5.07887 8.42448 4.77505 8.42448L0.55012 8.42448C0.246297 8.42448 -7.53939e-08 8.16331 -4.85696e-08 7.84115Z"
        fill="white"
      />
    </svg>
  );
}

export default PlayListSearchIcon