import React from 'react';
import { useAction } from '../../hooks/useAction';
import CustomLazyLoadImage from './CustomLazyLoadImage';
import { HomeSectionBackgroundOverlay } from './HomeSectionBackgroundOverlay';

export const DTitle = ({ item }) => {
  const action = useAction();

  const handleAction = () => {
    action(item?.element_meta?.show_more_action);
  };

  return (
    <div className="flex flex-col justify-end items-end px-4 w-full relative">
      <HomeSectionBackgroundOverlay
        background_color={item?.element_meta?.background_color}
      />

      <div className="flex flex-row justify-between items-center w-full static z-20">
        <div>
          {item?.element_meta?.show_more_visibility ? (
            <div
              onClick={() =>
                item?.element_meta?.show_more_visibility && handleAction()
              }
              className="flex flex-row items-center justify-center gap-1 cursor-pointer"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.174892 5.57199C-0.0582977 5.80518 -0.0582977 6.19477 0.174892 6.42856L5.5554 11.8229C5.79157 12.059 6.17459 12.059 6.41017 11.8229C6.64635 11.5867 6.64635 11.2031 6.41017 10.9669L1.45709 6L6.41077 1.03371C6.64695 0.79693 6.64695 0.413911 6.41077 0.177133C6.17459 -0.0590461 5.79157 -0.059046 5.55599 0.177133L0.174892 5.57199Z"
                  fill="#BDBDBD"
                />
              </svg>

              <p
                style={{
                  color: item?.element_meta?.show_more_color,
                  fontSize: item?.element_meta?.show_more_font_size,
                }}
                className=" font-dana font-[500] "
              >
                {item?.element_meta?.show_more_text}
              </p>
            </div>
          ) : null}
        </div>

        <div className="  flex flex-row justify-center items-center py-2 gap-2 w-fit">
          <p
            style={{
              color: item?.element_meta?.title_color,
              fontSize: item?.element_meta?.title_font_size,
            }}
            className=" font-kalameh font-[500] "
          >
            {item?.element_meta?.title}
          </p>
          <CustomLazyLoadImage
            imageClass="!static !w-[24px] h-[24px]"
            image={item?.element_meta?.icon}
          />
        </div>
      </div>
      <p
        style={{
          color: item?.element_meta?.subtitle_color,
          fontSize: item?.element_meta?.subtitle_font_size,
        }}
        className=" font-dana font-[500] text-right static z-20"
      >
        {item?.element_meta?.subtitle}
      </p>
    </div>
  );
};
