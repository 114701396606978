import React from 'react';

function RecentlyTrendIcon(props) {
  return (
    <svg  class="w-full h-full" viewBox='0 0 14 20' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.293,5.194C11.157,4.969 10.888,4.862 10.634,4.932C10.381,5.003 10.206,5.234 10.206,5.497C10.206,6.212 9.624,6.794 8.909,6.794C8.194,6.794 7.612,6.212 7.612,5.497V0.586C7.612,0.349 7.469,0.135 7.25,0.045C7.031,-0.046 6.779,0.004 6.612,0.172C6.546,0.237 4.983,1.809 3.4,4.184C2.467,5.584 1.722,6.973 1.187,8.311C0.508,10.008 0.164,11.632 0.164,13.138C0.164,16.922 3.242,20 7.026,20C10.81,20 13.888,16.922 13.888,13.138C13.888,10.716 13.015,8.043 11.293,5.194ZM7.026,18.828C3.889,18.828 1.336,16.275 1.336,13.138C1.336,10.05 2.982,6.928 4.363,4.853C5.111,3.728 5.866,2.78 6.44,2.106V5.497C6.44,6.858 7.548,7.965 8.909,7.965C9.741,7.965 10.478,7.552 10.926,6.919C12.115,9.157 12.716,11.245 12.716,13.138C12.716,16.275 10.164,18.828 7.026,18.828Z'
        fill='#BDBDBD' />
      <path
        d='M10.351,9.889H7.84C7.517,9.889 7.254,10.152 7.254,10.475C7.254,10.799 7.517,11.061 7.84,11.061H8.936L6.433,13.564L5.072,12.204C4.843,11.975 4.472,11.975 4.243,12.204L2.912,13.535C2.683,13.764 2.683,14.135 2.912,14.364C3.141,14.593 3.512,14.592 3.74,14.364L4.658,13.447L6.018,14.807C6.128,14.917 6.277,14.979 6.433,14.979C6.588,14.979 6.737,14.917 6.847,14.807L9.765,11.89V12.986C9.765,13.309 10.027,13.572 10.351,13.572C10.674,13.572 10.936,13.309 10.936,12.986V10.475C10.936,10.152 10.674,9.889 10.351,9.889Z'
        fill='#BDBDBD' />
    </svg>

  );
}

export default RecentlyTrendIcon;
