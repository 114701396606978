import React from 'react';
import CategoryCard from '../../components/playlist/CategoryCard.jsx';
import UserAvatar from '../../components/common/UserAvatar.jsx';
import { useNavigate } from 'react-router-dom';
import { useAction } from '../../hooks/useAction.js';
import { HOME_TYPES } from '../../types/HomeTypes.js';

export const UsersCollectionItem = ({ collectionItem,cardStyle }) => {

  const action = useAction()

  const handleOpenUserPlaylist = (collection) => {

    const actionData = {
      target_type: "open_user_playlist",
      playlistData: collection,
    };
    action(actionData);
  }
  return (
    <div className="flex flex-col gap-6">
      <div
        className={`w-full h-full ${cardStyle}`}
        onClick={() => handleOpenUserPlaylist(collectionItem)}
      >
        <CategoryCard
          fromMyMelodify
          item={collectionItem}
          scale={true}
          fromPlaylist
        />{' '}
      </div>
      <div className="flex flex-col gap-1">
        <span className="text-white font-normal font-dana text-xs line-clamp-1 max-w-[130px]">
          {collectionItem?.title}
        </span>
        <div className=" flex flex-row gap-1 items-center">
          <UserAvatar
            avatar={collectionItem?.social_profile?.avatar}
            is_premium={collectionItem?.social_profile?.is_premium}
            className="!w-[20px] !h-[20px]"
          />
          <span className="text-gray_4 font-normal font-dana text-[10px] line-clamp-1 max-w-[110px]">
            {collectionItem?.social_profile?.name}
          </span>
        </div>
      </div>
    </div>
  );
};

