import React, { useEffect, useRef, useState } from 'react';
import {
  getLyricFullScreen,
  getPlayerMusic,
  getPlayerSeekTime,
} from '../../helper/ReduxSelectorContext';
import LyricItem from '../lyrics/LyricItem';
import CssShimmer from '../common/CssShimmer';
import ShareSvg from '../lyrics/svg/ShareSvg';
import ReportFlagSvg from '../lyrics/svg/ReportFlagSvg';
import OpenLyricFullScreen from './OpenLyricFullScreen';
import { toggleLyricFullScreen } from '../../redux/player/PlayMusicSlice';
import { useDispatch } from 'react-redux';

import LyricReportBox from '../lyrics/LyricReportBox';
import { setLanguageDirection } from '../../helper/LanguageDetection.js';

function LyricContainer({ lyricGradient }) {
  let playedTrack = getPlayerMusic();
  const { lyricData } = playedTrack;
  let PlayerSeekTime = getPlayerSeekTime();
  const isLyricFullScreen = getLyricFullScreen();
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState(true);
  const containerRef = useRef();

  useEffect(() => {
    handleScrollTopInLoad();
  }, [playedTrack]);

  const handleScrollTopInLoad = () => {
    containerRef?.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const shareTrackText = e => {
    e.stopPropagation();
    let toShare = '';
    if (lyricData?.text) {
      toShare = lyricData?.text;
    } else {
      for (let line in lyricData?.sync) {
        toShare = toShare + lyricData?.sync[line]?.text + '\n';
      }
    }
    window.navigator
      .share({
        title: 'اشتراک متن آهنگ',
        text: playedTrack?.title + '\n' + toShare,
      })
      .then(() => console.log('Yay, you shared it :)'))
      .catch(error =>
        console.log("Oh noh! You couldn't share it! :'(\n", error),
      );
  };
  //when  don't have time for first mybr just music played scroll lyric to first of page
  useEffect(() => {
    if (!scroll) return;
    if (!playedTrack?.lyricData?.sync) return;
    // if (PlayerSeekTime > playedTrack?.lyricData?.sync[0]?.time) return;
    const lyrItem = document.querySelectorAll('.played-sync');
    containerRef.current?.scrollTo({
      top:
        lyrItem[lyrItem.length - 1]?.offsetTop -
        (isLyricFullScreen ? 250 : 100),
      behavior: 'smooth',
      left: 0,
    });

    // titleRef.current.scrollIntoView({
    //   behavior: "smooth",
    //   block: "center",
    //   inline: "center",
    // });
  }, [PlayerSeekTime, isLyricFullScreen]);
  useEffect(() => {
    let scrollTime = setTimeout(() => {
      setScroll(true);
    }, 3000);

    return () => {
      clearTimeout(scrollTime);
    };
  }, [scroll]);
  useEffect(() => {
    const handleDisableScroll = e => {
      setScroll(false);
    };
    window.addEventListener('wheel', handleDisableScroll);
    window.addEventListener('touchmove', handleDisableScroll);
    return () => {
      window.removeEventListener('wheel', handleDisableScroll);
      window.removeEventListener('touchmove', handleDisableScroll);
    };
  }, []);
  const handleOpenLyricFullScreen = () => {
    if (isLyricFullScreen) return;
    dispatch(toggleLyricFullScreen());
  };
  return (
    <div
      onClick={() => handleOpenLyricFullScreen()}
      className={`w-full   lyric-gradient flex flex-col before:w-full before:h-[60px]  before:content-normal before:z-10 before:absolute before:top-3 relative justify-center items-center h-full
                        after:w-full after:h-[60px] after:rotate-180 after:content-normal after:absolute  ${
                          isLyricFullScreen
                            ? 'after:bottom-0'
                            : ' after:bottom-8'
                        }`}
      style={lyricGradient}
    >
      {/* share svg */}
      <div
        onClick={e => shareTrackText(e)}
        className="absolute top-3  left-3 z-30"
      >
        <ShareSvg />
      </div>
      {!isLyricFullScreen && (
        <>
          {/* report lyric */}
          <LyricReportBox />

          {/* full screen lyric icon */}
          <OpenLyricFullScreen />
        </>
      )}

      {playedTrack?.lyricData ? (
        <div
          ref={containerRef}
          className={`flex flex-col text-center  mt-4   h-full pt-12 ${
            isLyricFullScreen
              ? `${
                  playedTrack?.is_demo
                    ? 'overflow-visible '
                    : 'overflow-scroll   '
                } w-[71%] pb-36  `
              : 'overflow-hidden pb-8 mb-12 w-[70%]'
          }    `}
          //   disable-Bounce-ios
        >
          {playedTrack?.lyricData?.sync
            ? playedTrack?.lyricData?.sync?.map((item, index) => (
                <LyricItem
                  item={item}
                  index={index}
                  lyric={playedTrack?.lyricData}
                  scroll={scroll}
                />
              ))
            : playedTrack?.lyricData?.text.split('\n').map(item => (
                <div
                  dir={setLanguageDirection(item)}
                  className="text-[16px] transition-all duration-200 ease-linear cursor-pointer  font-kalameh font-[500] py-1 text-white"
                >
                  {item}
                </div>
              ))}
        </div>
      ) : (
        <div class="w-[80%] mx-auto flex flex-col h-full justify-center items-center gap-7">
          {[1, 2, 3].map(item =>
            item % 2 == 0 ? (
              //    true ? (
              <CssShimmer
                backColor={'rgba(0,0,0,0.1)'}
                shimColor={'rgba(0,0,0,0.5)'}
                classNames="h-[25px] w-[95%] rounded-[10px]"
              />
            ) : (
              <CssShimmer
                backColor={'rgba(0,0,0,0.1)'}
                shimColor={'rgba(0,0,0,0.5)'}
                classNames="h-[25px] w-[95%] rounded-[10px]"
              />
            ),
          )}
        </div>
      )}
    </div>
  );
}

export default React.memo(LyricContainer);
