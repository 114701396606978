import React from 'react';

export const MedilDoneSvg = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="#2ACB6B" />
      <path
        d="M20.4 10.9541L13.3541 18L10 14.6459"
        stroke="white"
        strokeWidth="1.5625"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
