import  React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
  dislikeReel,
   likeReel,
  selectGlobalData,

} from '../redux/globalData/globalDataSlice.js';
import {useMutation} from "@tanstack/react-query";
import { reelDislike, reelLike } from '../services/ApiClient.js';

export const useLikeReel = (id) => {
  const dispatch=useDispatch()
  const {likedReels} = useSelector(selectGlobalData);
  const requestData = {reel_id:id}
  const findReelIndex = () => {
    return likedReels?.findIndex((itemId) => itemId === id);
  };
  const reelIndex = findReelIndex();
  const likeReelMutation = useMutation(() => reelLike(requestData), {});
  const disLikeReelMutation = useMutation(() => reelDislike(requestData), {});
  const handleLikeReel=()=>{
    dispatch(likeReel({id}))
    likeReelMutation.mutate()
  };
  const handleDisLikeReel=()=>{
    dispatch(dislikeReel({reelIndex}));
    disLikeReelMutation.mutate()
  };
  const toggleLike=()=>{
    if (reelIndex < 0) {
      handleLikeReel()
    } else {
      handleDisLikeReel()
    }
  };
  const isLiked = likedReels?.find((itemId) => Number(itemId) === Number(id));

  return {isLiked,toggleLike}
}
