import React from 'react';
import CollectionCategoryHeader from '../playlist/CollectionCategoryHeader.jsx';
import AbsoluteBackground from '../common/AbsoluteBackground.jsx';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage.jsx';
import PremiumImage from '../../assets/exclusive/premium-image.svg'
import ClickReaction from '../../helper/ClickReaction.jsx';
import { CardActionArea } from '@mui/material';
import { useAction } from '../../hooks/useAction.js';
export const ExclusiveTracksHeader = ({ pageData}) => {
  const action = useAction()

  const handleAction = () =>{
    action(pageData?.action)
  }

    return  (
      <div dir='rtl' id='absolute-background' onClick={handleAction} className='flex flex-col gap-4 px-4 items-center mt-[56px] pb-[24px] mb-[24px] w-full border-t-[1px] border-b-[1px] '>
        <div className='flex flex-col gap-2 w-full  '>
          <div className='flex w-full justify-between items-center'>
            <p dangerouslySetInnerHTML={{__html:pageData?.title?.convertBackSlashNToBr()}} className='text-[16px] font-dana font-[700] text-primary'>

            </p>
            <div className='w-[127px] h-[84px] relative'>
              <CustomLazyLoadImage
                image={PremiumImage}
                appendBaseUrl={false}
                imageClass=" h-full w-full"
              />
            </div>

          </div>

          <p dangerouslySetInnerHTML={{__html:pageData?.description?.convertBackSlashNToBr()}} className='text-[12px] font-dana font-[400] text-gray_6 text-center'>

          </p>
        </div>
        <ClickReaction>
          <CardActionArea className="!w-[152px] !h-[29px]   !rounded-[100px]">
            <button
              style={{color:pageData?.btn_color,background:pageData?.btn_background_color}}
              className=" w-full h-full rounded-[100px]  font-dana font-[600] text-[12px]  cursor-pointer "
            >
              {pageData?.btn_text}
            </button>
          </CardActionArea>
        </ClickReaction>

      </div>
    )
}

