import { useState,useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import { getUserCollectionData, getUserCollectionTracks } from '../services/ApiClient.js';
import { setSeenCollection } from '../redux/RecentlyHeard/RecentlyHeardSlice.js';
import { useDispatch } from 'react-redux';
import {
  userPlaylistSongOwnerSortTypes,
  userPlaylistSongViewerSortTypes,
  userPlaylistSortTypes,
} from '../consts/MenuItemsConsts.js';
import { useSocialProfile } from './useSocialProfile.js';

export const useUserPlaylist = ()=>{

  const dispatch = useDispatch();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { id: userCollectionId } = useParams();
  const [userCollectionData,setUserCollectionData] =useState()
  const playlistData = location?.state?.playlistData
  const  {isUserSocialProfile}= useSocialProfile(playlistData?.social_profile?.token)
  const sortList =isUserSocialProfile?userPlaylistSongOwnerSortTypes:userPlaylistSongViewerSortTypes
  const [isDragModeActive, setIsDragModeActive] = useState(false)
  const [sortType, setSortType] = useState(sortList[0]?.type);

  const collectionQueryData = {
    user_collection_id: userCollectionId,
    sort: sortType,
  };

  const handleUpdateCollectionData = newCollection => {
    setUserCollectionData(newCollection);
  };
  const getCollectionDataQuery = useQuery(
    ['getUserCollectionData' + userCollectionId],
    () => getUserCollectionData(userCollectionId),
    {
      onSuccess: res => {
        handleUpdateCollectionData(res?.data?.result?.user_collection)
      },
      refetchOnWindowFocus: false,
    },
  );

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ['getUserPlaylistTracks' + sortType + userCollectionId],
      ({ pageParam = 0 }) =>
        getUserCollectionTracks({
          user_collection_id: userCollectionId,
          sort: sortType,
          offset: pageParam,
        }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const allData = allPages?.flatMap(page => page?.data?.result?.tracks);
          return lastPage?.data?.result?.end ? false : allData?.length;
        },
        refetchOnWindowFocus: false,
      },
    );

  const userCollectionTrackList = data?.pages?.flatMap(page => page?.data?.result?.tracks);
  const reFetchData = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };
  const changeSort = type => {
    setSortType(type);
  };

  useEffect(() => {
    const hashEditMode = location.hash === '#edit';
    setIsDragModeActive(hashEditMode)
  }, [location]);
  useEffect(() => {
    setUserCollectionData(location?.state?.playlistData);
  }, [location?.state]);


  const setTrackListQueryData = (newList) => {
    const queryKey = ['getUserPlaylistTracks' + sortType + userCollectionId];
    const queryData = queryClient.getQueryData(queryKey);

    if (!queryData) return;

    let remainingTracks = [...newList]; // Copy the new track list

    const updatedPages = queryData.pages.map((page) => {
      if (remainingTracks.length === 0) return page; // Stop updating if no more tracks left

      const trackLimit = 40; // Each page holds 40 songs
      const newPageTracks = remainingTracks.slice(0, trackLimit); // Take 40 tracks for this page
      remainingTracks = remainingTracks.slice(trackLimit); // Remove the assigned tracks

      return {
        ...page,
        data: {
          ...page.data,
          result: {
            ...page.data.result,
            tracks: newPageTracks, // Replace tracks in the current page
          },
        },
      };
    });

    queryClient.setQueryData(queryKey, {
      ...queryData,
      pages: updatedPages,
    });
  };



  return {
  userCollectionData,
  getUserCollectionDataLoading:getCollectionDataQuery?.isLoading,
  isDragModeActive,
  userCollectionId,
  userCollectionTrackList,
  changeSort,
  reFetchData,
  hasNextPage,
  collectionQueryData,
  getCollectionTracksLoading:isLoading,
  handleUpdateCollectionData,
  setTrackListQueryData,
    sortType,
    sortList,
    isUserSocialProfile


}
}
