import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import ExpireSoonSvg from '../../../svg/common/ExpireSoonSvg';
import { CardActionArea } from '@mui/material';
import DialogHeader from '../../../dialogs/DialogHeader';
import { useMutation } from '@tanstack/react-query';
import { changePrivacy } from '../../../../services/ApiClient';
import { USER_PROFILE_STATUS } from '../../../../consts/UserProfileStatus';
import { mainToast } from '../../../../helper/ToastsEmitter';

export const PrivateProfileDialog = ({ open, setOpen, refetchData }) => {
  const { mutate } = useMutation(
    () => changePrivacy({ type: USER_PROFILE_STATUS.Private }),
    {
      onSuccess: res => {
        if (res?.data?.done) {
          mainToast('.حساب شما با موفقیت خصوصی شد');

          refetchData();
        }
      },
    },
  );
  const CloseCustomDialog = () => {
    setOpen(false);
  };

  const handlePublicProfile = () => {
    mutate();
    setOpen(false);
  };
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[20]" onClose={CloseCustomDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto  font-dana">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className="w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-primary_dark   text-left align-middle shadow-xl transition-all"
              >
                <DialogHeader
                  header_text={'عمومی کردن'}
                  onCloseDialog={CloseCustomDialog}
                />
                <div className="px-6 flex flex-col items-center gap-6">
                  <svg
                    width="52"
                    height="52"
                    viewBox="0 0 52 52"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M34.7812 21.1328H33.6875V17.793C33.6875 13.4929 30.2697 10 26.0312 10C21.8096 10 18.375 13.4959 18.375 17.793V21.1328H17.2812C15.472 21.1328 14 22.6311 14 24.4727V37.832C14 39.6736 15.472 41.1719 17.2812 41.1719H34.7812C36.5905 41.1719 38.0625 39.6736 38.0625 37.832V24.4727C38.0625 22.6311 36.5905 21.1328 34.7812 21.1328ZM20.5625 17.793C20.5625 14.7237 23.0158 12.2266 26.0312 12.2266C29.0608 12.2266 31.5 14.7232 31.5 17.793V21.1328H20.5625V17.793ZM36 37.832C36 38.4459 35.3843 39.215 34.7812 39.215H17.2812C16.6782 39.215 16 38.4459 16 37.832V24.5C16 23.8861 16.6782 23 17.2812 23C19.0469 23 32.1386 23 34.7812 23C35.3843 23 36 23.8588 36 24.4727V37.832Z"
                      fill="#F2C94C"
                    />
                    <circle cx="26" cy="31" r="2" fill="#F2C94C" />
                    <path
                      d="M45.1822 42.0958C45.5881 42.4364 46.1949 42.3844 46.5203 41.9662C50.2256 37.2043 52.1657 31.2918 51.9889 25.2408C51.8028 18.8696 49.2828 12.7887 44.9076 8.1534C40.5325 3.51814 34.6071 0.651514 28.2572 0.0981625C21.9073 -0.455189 15.5753 1.34329 10.4643 5.1519C5.35337 8.9605 1.81951 14.5139 0.534145 20.7569C-0.751217 26.9999 0.301487 33.4976 3.49226 39.0154C6.68303 44.5333 11.7895 48.6868 17.8416 50.6868C23.5894 52.5864 29.8098 52.4178 35.4323 50.2287C35.9261 50.0365 36.1494 49.4699 35.9391 48.9836C35.7288 48.4973 35.1646 48.2755 34.6702 48.4662C29.48 50.4694 23.7448 50.6169 18.4437 48.865C12.8383 47.0125 8.10862 43.1655 5.15333 38.0549C2.19804 32.9443 1.22302 26.9261 2.41352 21.1438C3.60403 15.3615 6.87709 10.218 11.6109 6.69049C16.3447 3.16295 22.2093 1.4972 28.0906 2.00972C33.9719 2.52223 39.46 5.1773 43.5123 9.47048C47.5645 13.7637 49.8986 19.3958 50.0709 25.2969C50.234 30.8776 48.4529 36.3312 45.0492 40.732C44.7251 41.1511 44.7763 41.7552 45.1822 42.0958Z"
                      fill="#F2C94C"
                    />
                  </svg>

                  <p className="text-gray_6 text-base text-center w-[273px] mx-auto">
                    با خصوصی کردن پروفایل، فقط دنبال کننده های شما میتوانند
                    محتوای شما را ببینند.
                  </p>
                </div>

                <div
                  className={
                    'flex items-center justify-center mt-6   mb-4 gap-1 px-4'
                  }
                >
                  <CardActionArea className="mt-4 !h-10 !rounded-[10px] !w-[180px]">
                    <div className="w-full flex items-center justify-center">
                      <button
                        onClick={handlePublicProfile}
                        type="button"
                        className="inline-flex justify-center items-center !h-10 text-gray_1 text-base bg-primary w-full 
                        rounded-[10px] border border-transparent bg-blue-100 px-4 focus:outline-none"
                      >
                        <p className="font-dana font-medium"> خصوصی کردن</p>
                      </button>
                    </div>
                  </CardActionArea>
                  <CardActionArea className=" !h-10 !rounded-[10px] !w-[180px]">
                    <div className="w-full flex items-center justify-center">
                      <button
                        onClick={CloseCustomDialog}
                        type="button"
                        className="inline-flex justify-center items-center !h-10  text-base bg-transparent text-primary !border !border-primary w-full rounded-[10px]
                          border-transparent bg-blue-100 px-4 focus:outline-none"
                      >
                        <p className="font-medium">انصراف</p>
                      </button>
                    </div>
                  </CardActionArea>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
