import { useEffect, useState } from "react";
import { USER_FOLLOW_STATUS } from "../consts/FollowStatusConst";
import { followUser, removeFollowRequest, unfollowUser } from "../services/ApiClient";
import { useMutation } from "@tanstack/react-query";

export const useFollowUserSocial = ({ follow_status, social_token }) => {
    const [loading, setLoading] = useState(false);
    const [followStatus, setFollowStatus] = useState(follow_status);

    const { mutate: followUserMutate } = useMutation(
        () => followUser(social_token),
        {
            onSuccess: res => {
                setLoading(false);
                setFollowStatus(res?.data?.result?.follow_status);
            },
        },
    );


    const { mutate: unfollowUserMutate } = useMutation(
        () => unfollowUser(social_token),
        {
            onSuccess: res => {
                setLoading(false);
                setFollowStatus(res?.data?.result?.follow_status);
            },
        },
    );

    const { mutate: removeFollowRequestMutate } = useMutation(
        () => removeFollowRequest(social_token),
        {
            onSuccess: res => {
                setLoading(false);
                setFollowStatus(res?.data?.result?.follow_status);
            },
        },
    );

    useEffect(() => {
        setFollowStatus(follow_status);
    }, [follow_status]);

    const handleFollowUser = () => {
        setLoading(true);
        if (followStatus === USER_FOLLOW_STATUS.followed) {
            unfollowUserMutate();
        } else if (followStatus === USER_FOLLOW_STATUS.follow_request) {
            removeFollowRequestMutate();
        } else {
            followUserMutate();
        }
    };


    return { loading, followStatus, handleFollowUser }
}