import React from 'react';
import {useQuery} from "@tanstack/react-query";
import { getUserCollections, getUserCollectionsV9 } from '../../services/ApiClient.js';
import {setUserCollections} from "../../redux/globalData/globalDataSlice.js";
import {useDispatch} from "react-redux";
import { useUserData } from '../../helper/ReduxSelectorContext.js';

function GlobalUserCollection(props) {
    const dispatch=useDispatch()
    const userData = useUserData();
    const socialToken = userData?.social_token
    const getUserCollectionQuery = useQuery(['getUserCollectionQuery'], () => getUserCollectionsV9({
        offset: 0,
        social_token: socialToken,

    }), {
        onSuccess: (res) => {
            dispatch(
                setUserCollections({collections: res?.data?.result?.user_collections})
            );
        },
        refetchOnWindowFocus: false,
    })
    return (
        <></>
    );
}

export default GlobalUserCollection;
