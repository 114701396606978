import React from 'react';
import CopyIconSvg from '../../svg/common/CopyIconSvg.jsx';
import ClickReaction from '../../../helper/ClickReaction';
import CopySecondTypeIcon from '../../common/CopySecondTypeIcon.jsx';
import TwoColorAttachIcon from '../../common/TwoColorAttachIcon.jsx';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard.js';

function ShareCopyLinkSection({ shareLink }) {
  const {copyToClipboard,shareContent} = useCopyToClipboard({text:shareLink})


  return (
    <div className='flex flex-col gap-[6px]'>
      <p className='flex text-gray_4 text-[12px] font-dana font-[400]'>
        لینک ملوپست (برای استوری اینستاگرام)
      </p>
      <ClickReaction>
      <div onClick={copyToClipboard} className='flex !h-[36px] items-center border-[1px] border-gray_2 gap-2 justify-between rounded-[8px] px-3 overflow-hidden'>
              <div className='flex h-[28px] gap-[6px] items-center justify-center rounded-[6px] w-[56px] min-w-[56px] bg-secondary border-[1px] border-gray_1  '>

                <CopySecondTypeIcon/>
                <p className='flex text-gray_4 text-[12px] font-dana font-[400] text-primary'>
                  کپی
                </p>

              </div>

              <div dir='ltr' className='flex items-center gap-2 overflow-hidden'>
                <div className='w-[18px] h-[14px] min-w-[18px] '>
                  <TwoColorAttachIcon/>
                </div>
                <p className=' text-gray_4 text-[12px] font-dana font-[400] leading-[22px] truncate '>
                  {shareLink}
                </p>

              </div>
      </div>
      </ClickReaction>
    </div>
  );
}

export default ShareCopyLinkSection;
