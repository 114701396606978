import React, { useState } from 'react';
import { useEffect } from 'react';
import {
  getEmitTrackEnd,
  getEmtyAudioStatus,
  getHaveAdvertise,
  getIsPlaying,
  getPlayList,
  getPlayListClone,
  getPlayListStatic,
  getPlayedFrom,
  getPlayerLoop,
  getPlayerMusic,
  getPlayerShuffle,
  getQueueList,
  useUserData,
} from '../../helper/ReduxSelectorContext';
import { dispatchIsPlaying } from '../../redux/globalstore/GlobalStore';
import { usePlayedTrack } from '../../hooks/usePlayedTrack';
import Hls from 'hls.js';
import { list } from 'postcss';
import { useDispatch } from 'react-redux';
import {
  setPlayFrom,
  setPlayList,
  setPlayListShuffle,
  setPlayerLoop,
  setPlayerShuffle,
  setSeekTime,
} from '../../redux/player/PlayMusicSlice';
import { LOOP_STATUS, PlayListType } from '../../consts/PlayListType';
import ShuffleList from '../../helper/ShuffleList';
import { userData } from '../../redux/login/LoginSlice';
import ClickReaction from '../../helper/ClickReaction';
import useMediaSession from '../../hooks/useMediaSession';
import { CardActionArea } from '@mui/material';
import { setEmtyAudioStatus } from '../../redux/player/PlayerSlice';

function PlayerController({ playerRef }) {
  let canShuffle = true;
  let disableSwipePlayer = true;
  const [currentTrack, setPlayerTrack] = usePlayedTrack(
    canShuffle,
    disableSwipePlayer,
  );
  const [changePlayPauseAnim, setIsButtonChange] = useState();
  const dispatch = useDispatch();
  let playList = getPlayList();
  let playListStatic = getPlayListStatic();
  let queueList = getQueueList();
  let playedTrackData = getPlayerMusic();
  let playListClone = getPlayListClone();
  let loop = getPlayerLoop();
  let isShuffle = getPlayerShuffle();
  let emitData = getEmitTrackEnd();
  const [onMount, setonMount] = useState(true);
  const EmtyAudioResource = document.getElementById('noPlayerReff');
  const isPlaying = getIsPlaying();
  const handleShuffle = () => {
    if (!isShuffle) {
      let playListWithQid = ShuffleList(playList);
      dispatch(setPlayListShuffle({ playList: playListWithQid, clone: true }));
    } else {
      dispatch(setPlayListShuffle({ playList: playListClone, clone: false }));
    }
    dispatch(setPlayerShuffle());
  };
  const handlePlay = () => {
    playerRef?.current?.player?.player.play();
    dispatchIsPlaying(true);
    EmtyAudioResource.src = null;
    // EmtyAudioResource?.pause();
  };
  const handlePause = () => {
    EmtyAudioResource.src = '/empty.mp3';
    EmtyAudioResource?.play();
    dispatch(setEmtyAudioStatus({ status: false }));

    playerRef?.current?.player?.player.pause();
    dispatchIsPlaying(false);
  };
  const handleLoop = () => {
    if (loop == LOOP_STATUS.OFF) {
      dispatch(setPlayerLoop({ loop: LOOP_STATUS.SINGLE }));
    } else if (loop == LOOP_STATUS.SINGLE) {
      dispatch(setPlayerLoop({ loop: LOOP_STATUS.MULTI }));
    } else {
      dispatch(setPlayerLoop({ loop: LOOP_STATUS.OFF }));
    }
  };

  useEffect(() => {
    // handle play next track when track end
    if (onMount) {
      setonMount(false);
    } else {
      playNextTrack();
    }
  }, [emitData]);

  useEffect(() => {
    if (playedTrackData?.type != PlayListType.PLAYLIST) return;
    let index = playList?.findIndex(item => item?.id === playedTrackData?.id);
    localStorage.setItem('index', index);
  }, [playedTrackData]);

  useEffect(() => {
    if (isPlaying) playerRef?.current?.player?.player.play();
    else playerRef?.current?.player?.player.pause();
  }, [isPlaying]);

  const playNextTrack = () => {
    if (!haveNextTrack()) {
      dispatchIsPlaying(false);
      return;
    }

    // handle Loop
    let index = playList?.findIndex(item => item?.id === playedTrackData?.id);
    if (
      loop == LOOP_STATUS.MULTI &&
      playList.length === index + 1 &&
      queueList.length == 0
    ) {
      setPlayerTrack(playList[0], playListStatic, PlayListType.PLAYLIST);

      return;
    }

    if (queueList.length == 0) {
      if (playedTrackData?.type === PlayListType.QUEUE) {
        setPlayerTrack(playList[0], playListStatic, PlayListType.PLAYLIST);
      } else {
        let index = playList?.findIndex(
          item => item?.id === playedTrackData?.id,
        );
        // added for infitit shuffle
        if (playList.length === index + 1 && isShuffle) {
          setPlayerTrack(playList[0], playListStatic, PlayListType.PLAYLIST);
        } else {
          setPlayerTrack(
            playList[index + 1],
            playListStatic,
            PlayListType.PLAYLIST,
          );
        }
      }
    } else {
      setPlayerTrack(queueList[0], playListStatic, PlayListType.QUEUE, true);
    }
  };

  const haveNextTrack = () => {
    if (queueList.length == 0) {
      if (loop == LOOP_STATUS.MULTI) {
        let index = playList?.findIndex(
          item => item?.id === playedTrackData?.id,
        );
        if (playList.length === index + 1) return playList[0];
      }
      if (playedTrackData?.type === PlayListType.QUEUE) return playList[0];
      else {
        let index = playList?.findIndex(
          item => item?.id === playedTrackData?.id,
        );
        // added for infitit shuffle
        if (playList.length === index + 1 && isShuffle) return playList[0];
        return playList[index + 1];
      }
    } else return queueList[0];
  };

  const havePreviousTrack = () => {
    if (loop == LOOP_STATUS.MULTI) {
      let index = playList?.findIndex(item => item?.id === playedTrackData?.id);
      if (playList.length === index + 1) return playList[0];
    }
    if (playedTrackData?.type === PlayListType.QUEUE) {
      return playList[0];
    } else {
      let index = playList?.findIndex(item => item?.id === playedTrackData?.id);
      return playList[index - 1];
    }
  };

  const playPreviousTrack = () => {
    if (playMusicAtFirst()) return;
    if (!havePreviousTrack()) return;

    if (playedTrackData?.type === PlayListType.QUEUE) {
      //active Index
      let previousIndexFromPlaylist = localStorage.getItem('index')
        ? localStorage.getItem('index')
        : 0;

      setPlayerTrack(
        playList[previousIndexFromPlaylist],
        playListStatic,
        PlayListType.PLAYLIST,
      );
    } else {
      let index = playList?.findIndex(item => item?.id === playedTrackData?.id);
      setPlayerTrack(
        playList[index - 1],
        playListStatic,
        PlayListType.PLAYLIST,
      );
    }
  };

  const loadSomePartNextTrack = () => {
    const hls = new Hls();
    let nextTrackUrl = `https://cdn.melodify.pw/${
      haveNextTrack()?.mp3s[0]?.name
    }?type=pwa&melodify_token=1785068&download_token=39ff42b249325f0e9cf5dd5aaf9148c8e7396d491e5db4ecf898224f7eaa6defbee692def48c7eaf046a1735d71f6570b63c4a85e21d2e14bddb2bcc0d1a6709&hls=1`;
    hls.loadSource(nextTrackUrl);
  };

  const playMusicAtFirst = () => {
    if (playerRef?.current?.player?.getCurrentTime()?.toFixed() > 10) {
      dispatch(setSeekTime({ seekTime: 0 }));
      playerRef?.current?.player?.seekTo(0);
      return true;
    }
  };

  const handlers = {
    play: () => {
      handlePlay();
    },
    pause: () => {
      handlePause();
      // Logic to handle the pause event
    },
    nextTrack: () => {
      console.log('next track...');
      if (haveNextTrack()) playNextTrack();
    },
    previousTrack: () => {
      playPreviousTrack();
    },
    seekBar: e => {
      dispatch(setSeekTime({ seekTime: e?.seekTime }));
      playerRef?.current?.player?.seekTo(e?.seekTime);
    },
  };

  useMediaSession(handlers);
  return (
    <>
      {/* <!-- player controller --> */}
      <div class="flex flex-row items-center justify-between select-none  w-full px-4">
        {/* <!-- repeat button --> */}
        <span
          onClick={() => handleLoop()}
          class="aspect-square h-[20px] xl:h-[1.56vw] xl:w-[1.56vw] cursor-pointer  "
        >
          <ClickReaction>
            {/* <!-- off repeat --> */}
            {loop != LOOP_STATUS.SINGLE ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 19"
                class={`w-full h-full ${
                  loop == LOOP_STATUS.OFF ? 'fill-gray_6' : 'fill-primary'
                }`}
              >
                <path d="M14.32 3.651a.716.716 0 100 1.432 4.253 4.253 0 014.248 4.249 4.253 4.253 0 01-4.248 4.248H9.07a.716.716 0 100 1.432h5.25A5.687 5.687 0 0020 9.332a5.687 5.687 0 00-5.68-5.68z"></path>
                <path d="M10.014 14.295l2.75-3.208a.716.716 0 10-1.087-.932l-3.151 3.676a.716.716 0 00.001.934l3.15 3.651a.714.714 0 001.01.074c.3-.258.333-.71.075-1.01l-2.748-3.185zM10.93 3.651H5.68A5.687 5.687 0 000 9.331a5.687 5.687 0 005.68 5.681.716.716 0 000-1.432 4.253 4.253 0 01-4.248-4.248A4.253 4.253 0 015.68 5.083h5.25a.716.716 0 100-1.432z"></path>
                <path d="M11.473 3.9L8.323.248a.716.716 0 00-1.085.936l2.748 3.185-2.75 3.208a.716.716 0 101.088.932l3.15-3.676a.716.716 0 00-.001-.933z"></path>
              </svg>
            ) : (
              <div className="w-full h-full relative">
                <svg
                  className="w-full h-full"
                  viewBox="0 0 20 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.3201 3.65137C13.9246 3.65137 13.6041 3.97187 13.6041 4.36734C13.6041 4.76281 13.9246 5.08332 14.3201 5.08332C16.6626 5.08332 18.5683 6.98906 18.5683 9.33152C18.5683 11.6743 16.6623 13.58 14.3201 13.58H9.06949C8.67402 13.58 8.35352 13.9005 8.35352 14.2959C8.35352 14.6914 8.67402 15.0119 9.06949 15.0119H14.3201C17.4521 15.0119 20.0003 12.4637 20.0003 9.33148C20.0003 6.19957 17.4521 3.65137 14.3201 3.65137Z"
                    fill="#F2C94C"
                  />
                  <path
                    d="M10.0138 14.295L12.7635 11.0871C13.021 10.7871 12.9861 10.3351 12.6859 10.0778C12.3861 9.82056 11.9338 9.85467 11.6766 10.1554L8.52598 13.8308C8.29544 14.0996 8.29614 14.4965 8.52743 14.7645L11.6778 18.416C11.8193 18.58 12.0191 18.6642 12.22 18.6642C12.3859 18.6642 12.5522 18.6072 12.6876 18.4903C12.9869 18.232 13.0203 17.78 12.762 17.4804L10.0138 14.295Z"
                    fill="#F2C94C"
                  />
                  <path
                    d="M10.9308 3.65137H5.6802C2.5482 3.65137 0 6.19957 0 9.33152C0 12.4637 2.5482 15.012 5.6802 15.012C6.07566 15.012 6.39617 14.6914 6.39617 14.296C6.39617 13.9005 6.07566 13.58 5.6802 13.58C3.3377 13.58 1.43199 11.6743 1.43199 9.33156C1.43199 6.98906 3.33773 5.08336 5.6802 5.08336H10.9308C11.3263 5.08336 11.6468 4.76285 11.6468 4.36738C11.6468 3.97191 11.3263 3.65137 10.9308 3.65137Z"
                    fill="#F2C94C"
                  />
                  <path
                    d="M11.473 3.89982L8.32269 0.248257C8.06468 -0.0510402 7.61218 -0.0844386 7.31288 0.173804C7.01335 0.43228 6.97995 0.884311 7.23843 1.18361L9.9864 4.36904L7.23675 7.57716C6.97949 7.8774 7.01433 8.32943 7.31456 8.58669C7.44964 8.70244 7.61527 8.759 7.78019 8.759C7.98187 8.759 8.18257 8.67404 8.32433 8.50888L11.4744 4.83345C11.705 4.56474 11.7043 4.16783 11.473 3.89982Z"
                    fill="#F2C94C"
                  />
                </svg>
                <div className="absolute bottom-0 -right-1 font-poppins  w-[10px] h-[10px] flex flex-row justify-center items-center bg-primary p-[1px] rounded-full z-20 text-[8px]">
                  <svg
                    width="10"
                    height="24"
                    viewBox="0 0 10 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="5" cy="12" r="5" fill="#F2C94C" />
                    <path
                      d="M4.39569 8.664H5.27569V14H4.40369V9.488L3.48369 9.792L3.45969 9.12L4.39569 8.664Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>

              //               <svg  class="w-full h-full" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              // <path d="M14.3201 3.65137C13.9246 3.65137 13.6041 3.97187 13.6041 4.36734C13.6041 4.76281 13.9246 5.08332 14.3201 5.08332C16.6626 5.08332 18.5683 6.98906 18.5683 9.33152C18.5683 11.6743 16.6623 13.58 14.3201 13.58H9.06949C8.67402 13.58 8.35352 13.9005 8.35352 14.2959C8.35352 14.6914 8.67402 15.0119 9.06949 15.0119H14.3201C17.4521 15.0119 20.0003 12.4637 20.0003 9.33148C20.0003 6.19957 17.4521 3.65137 14.3201 3.65137Z" fill="#F2C94C"/>
              // <path d="M10.0138 14.295L12.7635 11.0871C13.021 10.7871 12.9861 10.3351 12.6859 10.0778C12.3861 9.82057 11.9338 9.85468 11.6766 10.1554L8.52598 13.8308C8.29544 14.0996 8.29614 14.4965 8.52743 14.7645L11.6778 18.416C11.8193 18.58 12.0191 18.6642 12.22 18.6642C12.3859 18.6642 12.5522 18.6072 12.6876 18.4903C12.9869 18.232 13.0203 17.78 12.762 17.4804L10.0138 14.295Z" fill="#F2C94C"/>
              // <path d="M10.9308 3.65137H5.6802C2.5482 3.65137 0 6.19957 0 9.33152C0 12.4637 2.5482 15.012 5.6802 15.012C6.07566 15.012 6.39617 14.6914 6.39617 14.296C6.39617 13.9005 6.07566 13.58 5.6802 13.58C3.3377 13.58 1.43199 11.6743 1.43199 9.33156C1.43199 6.98906 3.33773 5.08336 5.6802 5.08336H10.9308C11.3263 5.08336 11.6468 4.76285 11.6468 4.36738C11.6468 3.97191 11.3263 3.65137 10.9308 3.65137Z" fill="#F2C94C"/>
              // <path d="M11.473 3.89982L8.32269 0.248257C8.06468 -0.0510403 7.61218 -0.0844387 7.31288 0.173804C7.01335 0.43228 6.97995 0.884311 7.23843 1.18361L9.9864 4.36904L7.23675 7.57716C6.97949 7.8774 7.01433 8.32943 7.31456 8.58669C7.44964 8.70244 7.61527 8.759 7.78019 8.759C7.98187 8.759 8.18257 8.67404 8.32433 8.50888L11.4744 4.83345C11.705 4.56474 11.7043 4.16783 11.473 3.89982Z" fill="#F2C94C"/>
              // <path d="M19 18C21.7614 18 24 15.7614 24 13C24 10.2386 21.7614 8 19 8C16.2386 8 14 10.2386 14 13C14 15.7614 16.2386 18 19 18Z" fill="#F2C94C"/>
              // <path d="M18.3957 9.664H19.2757V15H18.4037V10.488L17.4837 10.792L17.4597 10.12L18.3957 9.664Z" fill="black"/>
              // </svg>
            )}
          </ClickReaction>
        </span>
        {/* <!-- previous item --> */}

        <span
          onClick={() => playPreviousTrack()}
          class="h-[24px] w-[24px] xl:h-[1.87vw] xl:w-[1.87vw] cursor-pointer  "
        >
          <ClickReaction>
            <svg
              viewBox="0 0 17 18"
              fill="none"
              // className={`${
              //   havePreviousTrack() ? "opacity-100" : "opacity-50"
              // }`}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 2.46206C16 1.87284 15.3184 1.54526 14.8583 1.91334L6.6859 8.45128C6.33426 8.73259 6.33426 9.26741 6.6859 9.54872L14.8583 16.0867C15.3184 16.4547 16 16.1272 16 15.5379V2.46206Z"
                fill={'#F2C94C'}
                stroke={'#F2C94C'}
                strokeWidth="1.40541"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 2V16"
                stroke={'#F2C94C'}
                strokeWidth="2.81081"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ClickReaction>
        </span>
        {/* <!-- player button --> */}
        <div
          onMouseDown={() => setIsButtonChange(true)}
          onTouchStart={() => setIsButtonChange(true)}
          onTouchEnd={() => setIsButtonChange(false)}
          onMouseLeave={() => setIsButtonChange(false)}
          onMouseUp={() => setIsButtonChange(false)}
          className={`select-none aspect-square ease-linear transition-all duration-75 h-[70px] w-[70px]  ${
            changePlayPauseAnim ? 'scale-95' : 'scale-100'
          }`}
        >
          {!isPlaying ? (
            <svg
              onClick={() => handlePlay()}
              class="w-full h-full"
              key={'play-b'}
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4009_14011)">
                <path
                  d="M20 0C8.95445 0 0 8.9543 0 20C0 31.0457 8.95445 40 20 40C31.0455 40 40 31.0457 40 20C40 8.9543 31.0455 0 20 0ZM26.9125 21.0602L16.9125 27.3102C16.7102 27.4365 16.4801 27.5 16.25 27.5C16.0416 27.5 15.8328 27.4481 15.6439 27.3431C15.2466 27.1228 15 26.7047 15 26.25V13.75C15 13.2953 15.2466 12.8772 15.6439 12.6569C16.0413 12.4353 16.5271 12.4487 16.9125 12.6898L26.9125 18.9398C27.2778 19.1687 27.5 19.5691 27.5 20C27.5 20.4309 27.2778 20.8313 26.9125 21.0602Z"
                  fill="#F2C94C"
                />
              </g>
              <defs>
                <clipPath id="clip0_4009_14011">
                  <rect width="40" height="40" fill="white" />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              onClick={() => handlePause()}
              class="w-full  h-full"
              key={'pause-b'}
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1031_3710)">
                <path
                  d="M20 0C8.95 0 0 8.95 0 20C0 31.05 8.95 40 20 40C31.05 40 40 31.05 40 20C40 8.95 31.05 0 20 0ZM17.8 25C17.8 26.25 16.8 27.2 15.6 27.2C14.35 27.2 13.4 26.2 13.4 25V15C13.35 13.8 14.35 12.8 15.55 12.8C16.8 12.8 17.8 13.8 17.8 15V25ZM26.65 25C26.65 26.25 25.65 27.2 24.45 27.2C23.2 27.2 22.25 26.2 22.25 25V15C22.2 13.8 23.2 12.8 24.4 12.8C25.65 12.8 26.65 13.8 26.65 15V25Z"
                  fill="#F2C94C"
                />
              </g>
              <defs>
                <clipPath id="clip0_1031_3710">
                  <rect width="40" height="40" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
        </div>

        {/* <!-- next button --> */}
        <span
          onClick={() => haveNextTrack() && playNextTrack()}
          class="  h-[24px] w-[24px]  xl:h-[1.87vw] xl:w-[1.87vw]  cursor-pointer"
          style={{
            aspectRatio: 17 / 18,
          }}
        >
          <ClickReaction>
            <svg
              viewBox="0 0 17 18"
              fill="none"
              className={`${haveNextTrack() ? 'opacity-100' : 'opacity-50'}`}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 2.46206C1 1.87284 1.68157 1.54526 2.14168 1.91334L10.3141 8.45128C10.6657 8.73259 10.6657 9.26741 10.3141 9.54872L2.14168 16.0867C1.68157 16.4547 1 16.1272 1 15.5379V2.46206Z"
                fill={'#F2C94C'}
                stroke={'#F2C94C'}
                strokeWidth="1.40541"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15 2V16"
                stroke={'#F2C94C'}
                strokeWidth="2.81081"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ClickReaction>
        </span>

        {/* <!-- shuffle button --> */}

        <span
          onClick={() => handleShuffle()}
          class="select-none h-[20px]  xl:h-[1.56vw] xl:w-[1.56vw] aspect-square  cursor-pointer  "
        >
          <ClickReaction>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isShuffle ? 'fill-primary' : 'fill-gray_6'
              } w-full h-full`}
              fill="none"
              viewBox="0 0 20 20"
            >
              <path d="M19.533 13.176l-3.084-1.78a.936.936 0 00-1.403.81v1.057h-1.393a3.343 3.343 0 01-2.994-1.836L8.876 7.918a4.783 4.783 0 00-4.283-2.627H.723a.723.723 0 100 1.446h3.87c1.27 0 2.418.704 2.994 1.836L8.312 10c-.7 1.378-1.367 3.263-3.72 3.263a.723.723 0 100 1.446 4.783 4.783 0 004.284-2.627l.247-.487.247.487a4.783 4.783 0 004.283 2.627h1.393v1.057c0 .72.78 1.17 1.403.81l3.084-1.78a.936.936 0 000-1.62zM2.09 13.263H.723a.723.723 0 000 1.446H2.09a.723.723 0 000-1.446zM16.448 8.604l3.084-1.78a.936.936 0 000-1.62l-3.084-1.78a.936.936 0 00-1.403.81v3.56c0 .719.78 1.17 1.403.81z"></path>
              <path d="M14.082 6.737V5.29c-1.813 0-3.32.511-4.396 2.096.059.111-.058-.117.79 1.55 1.045-2.057 2.287-2.2 3.606-2.2z"></path>
            </svg>
          </ClickReaction>
        </span>
      </div>
    </>
  );
}

export default PlayerController;
