import React, { Fragment,useCallback } from 'react';
import {Dialog, Transition} from "@headlessui/react";
import { useFormik } from 'formik';
import { ADD_HASHTAG_VALIDATION_SCHEMA } from '../../../consts/Validation.js';
import { CardActionArea } from '@mui/material';

import CloseDialogButton from '../../svg/common/CloseDialogButton.jsx';
import clsx from 'clsx';
import InfoIcon from '../../svg/artists/InfoIcon.jsx';
import { useQueryClient } from '@tanstack/react-query';

export const CreateNewHashtag = ({ setOpen,open,handleSelectHashtags}) => {
  const queryClient = useQueryClient()
  const formik = useFormik({
    initialValues: {
      hashtag: '',
    },
    onSubmit: data => {
      const queryKey = ["getSuggestHashtags"];
      const queryData = queryClient.getQueryData(queryKey);
      const existingHashtags = queryData?.data?.result?.hashtags || [];
      const updatedHashtags = [{ title: data?.hashtag },...existingHashtags];

      queryClient.setQueryData(queryKey, {
        data: {
          result: {
            hashtags: updatedHashtags,
          },
        },
      });
      handleSelectHashtags({title:data?.hashtag})
      setOpen(false)
    },
    validationSchema: ADD_HASHTAG_VALIDATION_SCHEMA,
  });

  const closeDialog = useCallback(async () => {
    formik.resetForm();
    setOpen(false);
  }, []);

  return (
    <Transition dir="rtl" appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[1300]" onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 " />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto font-dana">
          <div className="flex min-h-full items-center justify-center p-4 text-center pb-12">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className=" w-[80vw]  max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-background   text-left align-middle  transition-all"
              >
                <Dialog.Title
                  as="div"
                  className="w-full flex justify-between   items-center h-[60px] bg-primary-dark px-4 bg-primary_dark "
                >
                  <p className="text-[16px] font-dana font-[500] text-primary">
                   افزودن هشتگ دلخواه
                  </p>
                  <div className="cursor-pointer w-4 h-4" onClick={closeDialog}>
                    <CloseDialogButton stroke='#828282' />
                  </div>
                </Dialog.Title>
                <form
                  dir='rtl'
                  onSubmit={formik.handleSubmit}
                  className="flex flex-col items-center flex flex-col gap-6 px-[22px] py-4"
                >

                  <div dir='rtl' className="flex flex-col gap-[10px]  w-full">
                    <label
                      htmlFor="hashtag-input"
                      className="text-gray_3 text-[14px] fa-format-600 text-start"
                    >
                     عنوان هشتگ:
                    </label>
                    <div className='flex h-[46px] items-center bg-primary_dark rounded-[8px] overflow-hidden pr-[10px]'>
                      <p className='fa-format-400 text-[20px] text-primary bg-primary_dark '>
                        #
                      </p>
                      <input
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.hashtag}
                        type="text"
                        name="hashtag"
                        id="hashtag-input"
                        className={clsx(`text-white text-[14px] font-format-400 w-full bg-primary_dark focus:outline-none h-[46px] px-4 rounded-[8px]`,formik?.errors?.title?'border border-[1px] border-red':'')}
                      />
                    </div>

                    {formik?.errors?.hashtag && (
                      <p className={`text-red text-[14px] fa-format-400 text-start `}>
                        {formik?.errors?.hashtag}
                      </p>
                    )}
                  </div>
                  <div dir='rtl' className='flex flex-col gap-2  p-[18px] rounded-[8px] border-[1px] border-secondary bg-background text-start'>
                       <div className='flex gap-1 items-center '>
                           <InfoIcon/>
                         <p className={`text-primary text-[12px] fa-format-600 `}>
                          راهنما
                         </p>
                       </div>
                    <p className={`text-gray_3 text-[12px] fa-format-400 `}>
                      عنوان هشتگ فارسی باشد و بجای فاصله بین کلمات از آندرلاین “_” استفاده شود.
                    </p>
                  </div>
                  <div
                    className={`rtl  flex items-center justify-center gap-6 w-full`}
                  >
                    <CardActionArea className={`!rounded-[100px] !w-[50%]  `}>
                      <button

                        type="submit"
                        className={`bg-primary  text-center inline-flex text-[16px] font-semibold w-full justify-center rounded-[10px]  px-4 py-3 focus:outline-none`}
                      >
                       تایید
                      </button>
                    </CardActionArea>
                    <CardActionArea className="!rounded-[100px] !w-[50%]">
                      <button
                        onClick={closeDialog}
                        type="button"
                        className={` text-center inline-flex text-[16px] text-primary font-semibold w-full border border-primary justify-center rounded-[10px] px-4 py-3 focus:outline-none`}
                      >
                        انصراف
                      </button>
                    </CardActionArea>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

