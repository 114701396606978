import React from "react";

function ArtistSearchIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8186 7.60943C11.8186 7.35801 11.5856 7.15417 11.2983 7.15417C11.011 7.15417 10.778 7.35801 10.778 7.60943C10.778 9.43216 9.08323 10.9151 7.00011 10.9151C4.917 10.9151 3.22223 9.43216 3.22223 7.60943C3.22223 7.35801 2.98928 7.15417 2.70193 7.15417C2.41459 7.15417 2.18164 7.35801 2.18164 7.60943C2.18164 9.7804 4.06677 11.5728 6.47982 11.8006V13.0895H5.19858C4.91124 13.0895 4.67829 13.2933 4.67829 13.5447C4.67829 13.7961 4.91124 14 5.19858 14H8.80172C9.08906 14 9.32201 13.7961 9.32201 13.5447C9.32201 13.2933 9.08906 13.0895 8.80172 13.0895H7.52048V11.8006C9.93346 11.5728 11.8186 9.7804 11.8186 7.60943Z"
        fill="white"
      />
      <path
        d="M6.99962 10.3867C8.62349 10.3867 9.94455 9.23073 9.94455 7.80984V2.57681C9.94455 1.15593 8.62349 0 6.99962 0C5.37575 0 4.05469 1.15593 4.05469 2.57681V7.80984C4.05469 9.23073 5.37575 10.3867 6.99962 10.3867ZM5.09527 2.57681C5.09527 1.65798 5.94953 0.910514 6.99962 0.910514C8.04971 0.910514 8.90396 1.65798 8.90396 2.57681V7.80984C8.90396 8.72867 8.04971 9.47614 6.99962 9.47614C5.94953 9.47614 5.09527 8.72867 5.09527 7.80984V2.57681Z"
        fill="white"
      />
    </svg>
  );
}

export default ArtistSearchIcon;
