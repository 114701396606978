import React from 'react'

export const BellSvg = () => {
  return (
    <svg
      width="99"
      height="110"
      viewBox="0 0 99 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2495_13916)">
        <path
          d="M88.283 84.631H86.4667L81.472 76.4992C80.2451 74.5018 79.3965 72.3229 78.9494 70.0232C78.7796 69.1494 77.9305 68.5783 77.0555 68.7476C76.1795 68.9169 75.6068 69.7626 75.7766 70.6361C76.2973 73.3159 77.2863 75.8549 78.7158 78.1823L82.6768 84.631H39.6298C38.7374 84.631 38.0139 85.3525 38.0139 86.2424C38.0139 87.1322 38.7374 87.8537 39.6298 87.8537H88.283C89.2928 87.8537 90.1143 88.6729 90.1143 89.6799V92.6097C90.1143 93.6167 89.2928 94.4359 88.283 94.4359H6.00931C4.99946 94.4359 4.17792 93.6167 4.17792 92.6097V89.6799C4.17792 88.6729 4.99946 87.8537 6.00931 87.8537H30.9351C31.8275 87.8537 32.551 87.1322 32.551 86.2424C32.551 85.3525 31.8275 84.631 30.9351 84.631H11.6155L15.5764 78.1823C17.7715 74.6084 18.932 70.5038 18.932 66.3126V42.8355C18.932 27.3223 31.589 14.7015 47.1462 14.7015C55.3463 14.7015 63.1282 18.2521 68.4969 24.4428C69.0806 25.1159 70.101 25.1898 70.7762 24.6076C71.4513 24.0255 71.5254 23.008 70.9417 22.3347C66.4158 17.116 60.3503 13.5824 53.7086 12.17V3.75977C53.7086 1.68674 52.0171 0 49.9381 0H44.3548C42.2758 0 40.5843 1.68674 40.5843 3.75977V12.1677C26.3844 15.1858 15.7003 27.789 15.7003 42.8355V66.3126C15.7003 69.9095 14.7045 73.4319 12.8205 76.4992L7.82582 84.631H6.00952C3.21763 84.631 0.946289 86.8959 0.946289 89.6799V92.6097C0.946289 95.3936 3.21763 97.6585 6.00952 97.6585H33.2536C34.0575 104.595 39.9776 110 47.146 110C54.3145 110 60.2346 104.595 61.0387 97.6585H88.2828C91.0746 97.6585 93.346 95.3936 93.346 92.6097V89.6799C93.3462 86.8959 91.0749 84.631 88.283 84.631ZM43.8157 3.75977C43.8157 3.46371 44.0575 3.22266 44.3544 3.22266H49.9377C50.2346 3.22266 50.4763 3.46371 50.4763 3.75977V11.6555C49.3758 11.5388 48.2645 11.4787 47.146 11.4787C46.0211 11.4787 44.9104 11.539 43.8157 11.6546V3.75977ZM47.146 106.777C41.7622 106.777 37.2949 102.813 36.5126 97.6585H57.7793C56.9974 102.813 52.5301 106.777 47.146 106.777Z"
          fill="#4F4F4F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M84.2277 26.4726C83.5772 26.3229 82.9281 26.7266 82.7776 27.3752C82.627 28.0239 83.0328 28.6716 83.6833 28.8217C86.6905 29.5147 89.4393 31.0372 91.6318 33.2234C98.0776 39.6509 98.0776 50.11 91.6318 56.5374C85.186 62.9654 74.697 62.9654 68.2512 56.5374C61.805 50.11 61.805 39.6509 68.2512 33.2234C70.1019 31.378 72.292 30.0321 74.7609 29.222C75.3949 29.0143 75.74 28.3331 75.5317 27.7005C75.3229 27.0679 74.6393 26.7237 74.0053 26.9319C71.1733 27.8605 68.6622 29.4038 66.5417 31.5187C59.1528 38.8866 59.1528 50.8747 66.5417 58.2426C70.2359 61.9263 75.0886 63.7684 79.9417 63.7684C84.7944 63.7679 89.6471 61.9263 93.3418 58.2426C100.73 50.8747 100.73 38.8866 93.3418 31.5187C90.8283 29.0129 87.6772 27.2676 84.2277 26.4726Z"
          fill="#4F4F4F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M87.2351 37.602C86.7631 37.1314 85.998 37.1314 85.5255 37.602L79.9371 43.1745L74.3482 37.602C73.8762 37.1314 73.1107 37.1314 72.6387 37.602C72.1667 38.0727 72.1667 38.8361 72.6387 39.3067L78.2271 44.8797L72.6387 50.4522C72.1667 50.9229 72.1667 51.6863 72.6387 52.1569C72.8744 52.3925 73.1841 52.5101 73.4932 52.5101C73.8029 52.5101 74.112 52.3925 74.3482 52.1569L79.9367 46.5844L85.5251 52.1569C85.7613 52.3925 86.0709 52.5101 86.3801 52.5101C86.6897 52.5101 86.9989 52.3925 87.2351 52.1569C87.7071 51.6863 87.7071 50.9229 87.2351 50.4522L81.6462 44.8797L87.2351 39.3067C87.7071 38.8361 87.7071 38.0727 87.2351 37.602Z"
          fill="#4F4F4F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M79.1359 28.4109C79.8016 28.4109 80.3446 27.8695 80.3446 27.2052C80.3446 26.5415 79.8016 26 79.1359 26C78.4698 26 77.9268 26.5415 77.9268 27.2052C77.9268 27.8695 78.4698 28.4109 79.1359 28.4109Z"
          fill="#4F4F4F"
        />
      </g>
      <defs>
        <clipPath id="clip0_2495_13916">
          <rect width="99" height="110" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

