export const HOME_TYPES = {
    ARTISTS: "artists",
    A_TITLE: "a_title",
    BANNERS: "banners",
    B_TITLE: "b_title",
    C_TITLE: "c_title",
    D_TITLE: "d_title",
    E_TITLE: "e_title",
    EXCLUSIVE_TRACKS: "exclusive_tracks",
    GAP: "gap",
    HORIZONTAL_ALBUMS: "horizontal_albums",
    HORIZONTAL_COLLECTION_CATEGORIES: "horizontal_collection_categories",
    HORIZONTAL_COLLECTIONS: "horizontal_collections",
    HORIZONTAL_REELS: "horizontal_reels",
    HORIZONTAL_TRACKS: "horizontal_tracks",
    HORIZONTAL_USER_COLLECTIONS: "horizontal_user_collections",
    ROOT_COLLECTION_CATEGORIES: "root_collection_categories",
    USER_ACTIVITIES: "user_activities",
    VERTICAL_TRACKS_WITH_BUTTON: "vertical_tracks_with_button",
    VERTICAL_TRACKS_WITH_DURATION: "vertical_tracks_with_duration",
    VERTICAL_USER_COLLECTIONS: "vertical_user_collections",
    HORIZONTAL_MELOPOSTS: "horizontal_meloposts",
    BIG_HORIZONTAL_COLLECTION_CATEGORIES: "big_horizontal_collection_categories"
};
