import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectExpireDialog } from "../../redux/dialog/DialogSlice";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useAction } from "../../hooks/useAction";
import DialogHeader from "./DialogHeader.jsx";
import {CardActionArea} from "@mui/material";
import ExpireSoonSvg from "../svg/common/ExpireSoonSvg.jsx";
import {ACTIONS} from "../../consts/ActionsConstants.js";

function ExpireDialog() {
  const [open, setOpen] = useState(false);
  const dialogData = useSelector(selectExpireDialog);
  const action = useAction();
  const expiredDialogData = {
    headerText: "اتمام اشتراک",
    iconSize: "",
    styleObject: {
      backColor: "linear-gradient(180deg, #3D3D3D 0%, #282828 100%)",
      height: "auto",
    },
    bodyContent: {
      icon: "expired.svg",
      title: "کاربر ویژه ملودیفای",
      text: "اشتراک شما به پایان رسیده‌است برای استفاده از امکانات ویژه‌ی ملودیفای اشتراک خود را تمدید کنید.",
      creditRemaining: null,
      surveyBtn: false,
      updatePlaylist: false,
    },
    Buttons: {
      text: "تمدید اشتراک",
      type: "filled",
      action: {
        target_type: ACTIONS.PLANS_TYPE,
      },
    },
  };

  const handleCloseLogout = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (dialogData) setOpen(true);
  }, [dialogData]);

  const handleButtonAction = () => {
    action(expiredDialogData?.Buttons?.action);
    handleCloseLogout();
  };
  const CloseCustomDialog = () => {
    setOpen(false);
  };
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={CloseCustomDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto  font-dana">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                dir="rtl"
                className="w-[80vw] max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-primary_dark  text-left align-middle shadow-xl transition-all"
              >
                <DialogHeader
                  header_text={expiredDialogData?.headerText}
                  onCloseDialog={CloseCustomDialog}
                />
                <div className="px-6 flex flex-col items-center gap-2">
                  <ExpireSoonSvg classNames={'!w-12'} />
                  <p className="text-primary text-[22px]">
                    {expiredDialogData?.bodyContent.title}
                  </p>
                  <p className="text-white text-base text-center">
                    {expiredDialogData?.bodyContent.text}
                  </p>
                </div>

                <div className={'flex items-center justify-center mt-6   mb-4'}>
                  <CardActionArea className="mt-4 !h-10 !rounded-full !w-[180px]">
                    <div className="w-[180px] flex items-center justify-center">
                      <button
                        onClick={handleButtonAction}
                        type="button"
                        className="inline-flex justify-center items-center !h-10 text-black text-base bg-primary w-full justify-center rounded-full border border-transparent bg-blue-100 px-4 focus:outline-none"
                      >
                        <p className="font-semibold">
                          {expiredDialogData?.Buttons?.text}
                        </p>
                      </button>
                    </div>
                  </CardActionArea>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ExpireDialog;
