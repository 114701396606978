import React, { useEffect, useRef, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import ThreeDotSvg from '../svg/common/ThreeDotSvg.jsx';
import {
  ContextMenuKeys,
  menuItemsGroups,
} from '../../consts/MenuItemsConsts.js';
import DropDownItem from '../queue/DropDownItem.jsx';
import { useOnClickOutside } from '../../helper/useOnClickOutside.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDropDown,
  setActiveIndex,
} from '../../redux/player/DropDownSlice.js';
import { contextMenuData } from '../../helper/ReduxSelectorContext.js';
import { useDownload } from '../../hooks/useDownload.js';
import { useTelegram } from '../../hooks/useTelegram.jsx';

//todo:queue and add to queue didnt exist
function ContextMenu({
  openContext,
  closeContext,
  position,
  button = true,
  top = false,
  menuItems,
}) {
  const dropDownParent = useRef(null);

  const {
    isMenuOpen,
    showingStreamQualityDialog,
    showingBugReportDialog,
    createPlayListDialog,
  } = useSelector(selectDropDown);
  const openDropDown = e => {
    e?.preventDefault();
    e?.stopPropagation();
    openContext();
  };
  const dispatch = useDispatch();
  const closeDropDown = () => {
    if (
      showingStreamQualityDialog ||
      showingBugReportDialog ||
      createPlayListDialog
    ) {
      return;
    }
    dispatch(setActiveIndex({ index: null }));
    closeContext();
  };
  const {
    isContextMenuOpen: openState,
    vertical,
    horizontal,
    trackData: data,
  } = contextMenuData();
  const [, isDownloaded, , , ,] = useDownload(data);

  // useOnClickOutside(dropDownParent, closeDropDown, !openState);
  const renderDropDownItems = item => {
    console.log(item);
    return (
      <Menu.Item as="div">
        <DropDownItem
          data={data}
          isDownloaded={isDownloaded}
          title={item.title}
          secondTitle={item?.secondTitle}
          active={item.active}
          subItem={item.submenu}
          defaultConfig={item.defaultConfig}
          Icon={item.svg}
          textColor={item.textColor}
          iconColor={item.iconColor}
          SecondIcon={item.secondSvg}
          index={item.id}
          position={horizontal?.left}
          closeDropDown={closeDropDown}
          itemKey={item?.key}
        ></DropDownItem>
      </Menu.Item>
    );
  };

  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
      ref={dropDownParent}
      className={`fixed opacity-100 ${button ? 'h-[20px]  ' : 'h-[0px]'}`}
      style={{ aspectRatio: 4 / 20 }}
    >
      <Menu>
        {({ open }) => (
          <>
            {button && (
              <div
                className="hidden"
                onContextMenu={e => {
                  openDropDown(e);
                }}
                onClick={e => openDropDown(e)}
              >
                <Menu.Button class="inline-flex w-[40px] h-full justify-center rounded-md bg-black bg-opacity-20 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none ">
                  <ThreeDotSvg fill="#A7A7A9" />
                </Menu.Button>
              </div>
            )}

            <Transition
              className="relative !z-[40] "
              show={openState}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Menu.Items
                static
                as="div"
                style={{
                  ...(vertical?.top
                    ? { top: vertical?.top + 'px' }
                    : { bottom: vertical?.bottom + 'px' }),
                  ...(horizontal?.left
                    ? { left: horizontal?.left + 'px' }
                    : { right: horizontal?.right + 'px' }),
                }}
                className={`fixed w-[210px]    mt-2 !z-[60]  origin-top-right divide-y divide-gray-1 bg-[#444] rounded-[10px]    focus:outline-none    `}
              >
                <div>
                  {menuItems.map((item, index) => renderDropDownItems(item))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}

export default ContextMenu;
