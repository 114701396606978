import React, { useState } from 'react';
import { SimpleYellowPlayPauseButtons } from '../playlist/melodifyPlaylist/SimpleYellowPlayPauseButtons.jsx';
import { SharePlaylistButton } from '../playlist/melodifyPlaylist/SharePlaylistButton.jsx';
import { shareTypes } from '../../consts/shareTypes.js';
import { PlaylistReels } from '../playlist/melodifyPlaylist/PlaylistReels.jsx';

import { UserPlaylistStatisticsButton } from './UserPlaylistStatisticsButton.jsx';
import { UserPlaylistDataSection } from '../svg/userPlaylist/UserPlaylistDataSection.jsx';
import { useSocialProfile } from '../../hooks/useSocialProfile.js';
import { FollowUnfollowArtistPlaylist } from '../playlist/melodifyPlaylist/FollowUnfollowArtistPlaylist';
import { useFollowUserCollection } from '../../hooks/useFollowUserCollection.js';
import CssShimmer from '../common/CssShimmer.jsx';

export const UserPlaylistInfoBox = ({ userCollectionData, list, endOfPagination, requestPayload, getUserCollectionDataLoading,handleUpdateCollectionData, isDragModeActive  }) => {
  const [isButtonChange, setIsButtonChange] = useState();
  const userPlaylistTrackUrl = '/getUserCollectionTracks'
  const  {isUserSocialProfile}= useSocialProfile(userCollectionData?.social_profile?.token)
  const [isFollow, toggleFollow,unfollowCollection,followCollectionLoading] = useFollowUserCollection(userCollectionData?.id)
  return (
    <div id='user-playlist-background' className="flex flex-col gap-5 relative px-4 pt-[68px]">
      <UserPlaylistDataSection userCollectionData={userCollectionData}  isLoading={getUserCollectionDataLoading} isDragModeActive={isDragModeActive}  handleUpdateCollectionData={handleUpdateCollectionData} />

      <div className="flex justify-between items-center  ">
        <div className="gap-[14px] flex items-center ">
          <SimpleYellowPlayPauseButtons
            userCollectionData={userCollectionData}
            apiUrl={userPlaylistTrackUrl}
            list={list}
            endOfPagination={endOfPagination}
            requestPayload={requestPayload}
            className="h-[56px] w-[56px]"
            isPlaying={false}
            setIsButtonChange={setIsButtonChange}
            changePlayPauseAnim={isButtonChange}
            collectionObject={userCollectionData}
          />
        </div>
        <div className="flex items-center gap-6">

          {
            getUserCollectionDataLoading?
              <CssShimmer classNames="h-[46px] w-[30px] rounded-[6px]" />:
              isUserSocialProfile?
                <UserPlaylistStatisticsButton collectionData={userCollectionData}/>
                :
                <FollowUnfollowArtistPlaylist
                  id={userCollectionData?.id}
                  followLoading={followCollectionLoading}
                  unFollow={unfollowCollection}
                  isFollow={isFollow}
                  type="playlist"
                  toggleFollow={toggleFollow}
                />

          }

          {
            getUserCollectionDataLoading?
              <CssShimmer classNames="h-[46px] w-[30px] rounded-[6px]" />
              :
              <SharePlaylistButton
                id={userCollectionData?.id}
                type={shareTypes?.USER_COLLECTION}

              />

          }

          <PlaylistReels
            model_id={userCollectionData?.id}
            reelData={userCollectionData?.reel_data}
            isLoading={getUserCollectionDataLoading}
          />
        </div>
      </div>
    </div>
  );
};

