import React from 'react';
import { HashtagItem } from './HashtagItem';

export const SuggestedHashtagsList = ({ hashtags,handleSelectHashtags,selectedHashtags }) => {
  console.log(handleSelectHashtags,'handle hashtags...');
  const isSelected = (item)=>{
   return  selectedHashtags?.find(selectedItem =>selectedItem?.title ===item?.title)
  }
  return (
    <div className="flex flex-wrap gap-3 ">
      {hashtags?.map(hashtag => {
        return <HashtagItem title={hashtag?.title} isSelected={isSelected(hashtag)} viewCount={hashtag?.used_count} hasDeleteIcon={false}   onSelect={()=>handleSelectHashtags(hashtag)}   />;
      })}
    </div>
  );
};

