import React from 'react';

function ShareTextSection({title,creator}) {
  return (
    <div className='flex flex-col '>
      <p className='flex text-white text-[18px] font-dana font-[500]'>{title}</p>
      <div className='flex gap-[5px]'>
        <p className='flex text-gray_4 text-[12px] font-dana font-[500]'>توسط</p>
        <p className='flex text-white text-[12px] font-dana font-[500]'>{creator}</p>

      </div>
    </div>
  );
}

export default ShareTextSection;
