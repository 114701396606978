import { useDispatch } from 'react-redux';
import { followedUserCollections } from '../helper/ReduxSelectorContext.js';
import { followUserCollectionApi, unFollowUserCollectionApi } from '../services/ApiClient.js';
import {useMutation} from "@tanstack/react-query";
import {
  followUserCollection,
  hideUnfollowDialog,
  showUnfollowDialog,
  unFollowUserCollection,
} from '../redux/globalData/globalDataSlice.js';
import { useState } from 'react';

export const useFollowUserCollection = (id) => {

  const dispatch = useDispatch();
  const followingCollections = followedUserCollections();
  const [followCollectionLoading,setFollowCollectionLoading] = useState(false)
  const enableFollowCollectionLoading = () =>{
    setFollowCollectionLoading(true)
  }
  const disableFollowCollectionLoading = () =>{
    setFollowCollectionLoading(false)
  }
  const requestData = {user_collection_id: id};
  const findIndex = () => {
    return followingCollections?.findIndex((itemId) => Number(itemId) === Number(id));
  };
  const collectionIndex = findIndex();
  const followCollectionMutation = useMutation(() => followUserCollectionApi(requestData), {
    onSuccess:()=>{
      dispatch(followUserCollection({id}))
    },
    onSettled:()=>{
      disableFollowCollectionLoading()
    },
  });
  const unFollowCollectionMutation = useMutation(() => unFollowUserCollectionApi(requestData), {
    onSuccess:()=>{
      dispatch(unFollowUserCollection({collectionIndex}));
    },
    onSettled:()=>{
      disableFollowCollectionLoading()

    },
  });
  const handleFollowCollection = () => {
    enableFollowCollectionLoading()
    followCollectionMutation.mutate()
    dispatch(hideUnfollowDialog())
  };
  const handleUnFollowCollection = () => {

    dispatch(hideUnfollowDialog())
    enableFollowCollectionLoading()
    unFollowCollectionMutation.mutate()
  };
  const toggleFollow = () => {
    if(followCollectionLoading){
      return
    }
    if (collectionIndex < 0) {
      handleFollowCollection()
    } else {
      dispatch(showUnfollowDialog({itemObject:{id, key:'playlist'}}))

    }
  };
  const unfollowCollection=()=>{
    handleUnFollowCollection()
  }

  const isFollow = followingCollections?.find((itemId) => Number(itemId) === Number(id));

  return [isFollow, toggleFollow,unfollowCollection,followCollectionLoading]
}
