import React from 'react';
import CustomLazyLoadImage from '../common/CustomLazyLoadImage';
import { CardActionArea } from '@mui/material';
import ClickReaction from '../../helper/ClickReaction';
import { useDispatch } from 'react-redux';
import { settPlayingReel } from '../../redux/reelPlayer/ReelsPlayerSlice';
import { HeightRatio } from '../../helper/HeightRatio';
import useWindowDimensions from '../../helper/ScreenSize';

export const ReelsCard = ({ reel_item, index }) => {
    const { width } = useWindowDimensions();
  return (
    <ClickReaction>
      <div
        className="relative   bg-gray_2 rounded-md overflow-hidden"
        style={{
          height: HeightRatio(width, 4),
          width: HeightRatio(width, 5.1),
        }}
      >
        <CustomLazyLoadImage
          imageClass="static object-cover w-full h-full" // Ensure image fills container
          image={reel_item?.image}
        />
        <div className="absolute inset-0 flex flex-row justify-center items-center z-20 bg-[#0E0E0E4D]">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="30"
              height="30"
              rx="15"
              fill="white"
              fillOpacity="0.2"
            />
            <g clipPath="url(#clip0_1623_48198)">
              <path
                d="M8 18.5C8 20.4289 9.57111 22 11.5 22H18.5C20.4289 22 22 20.4289 22 18.5V12.2778H8V18.5ZM13.0556 15C13.0556 14.8639 13.1294 14.7356 13.2461 14.6656C13.3667 14.5956 13.5144 14.5917 13.635 14.6617L17.135 16.6061C17.2556 16.6722 17.3333 16.8044 17.3333 16.9444C17.3333 17.0844 17.2556 17.2167 17.135 17.2828L13.635 19.2272C13.5728 19.2622 13.5106 19.2778 13.4444 19.2778C13.3744 19.2778 13.3083 19.2583 13.2461 19.2233C13.1294 19.1533 13.0556 19.025 13.0556 18.8889V15ZM22 11.5C22 9.57111 20.4289 8 18.5 8H16.3728L18.7061 11.5H22ZM17.7767 11.5L15.4394 8H11.5C11.4378 8 11.3794 8 11.3211 8.00389L13.6506 11.5H17.7767ZM10.4889 8.15167C9.05 8.58333 8 9.92111 8 11.5H12.7211L10.4889 8.15167Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1623_48198">
                <rect
                  width="14"
                  height="14"
                  fill="white"
                  transform="translate(8 8)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </ClickReaction>
  );
};
