import React, { useCallback, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { CardActionArea } from '@mui/material';
import { memo } from 'react';
import CloseDialogButton from '../svg/common/CloseDialogButton';
import giftSvg from '../../assets/gift/gift.svg';
import { useFormik } from 'formik';
import { GIFT_CODE_VALIDATION_SCHEMA } from '../../consts/Validation';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { activateGiftApi } from '../../services/ApiClient';
import { mainToast } from '../../helper/ToastsEmitter';
import LoaderContainer from '../loading/LoaderContainer';

function GiftDialog({ open = false, setOpen }) {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: data => {
      submitActiveGiftCode.mutate(data);
    },
    validateOnChange: false,
    validationSchema: GIFT_CODE_VALIDATION_SCHEMA,
  });

  const submitActiveGiftCode = useMutation(activateGiftApi, {
    onSuccess: res => {
      if (res?.data?.done) navigate('/', { replace: true });
      else if (res?.data?.error?.message)
        formik.setErrors({ code: res?.data?.error?.message });
    },
  });

  const closeDialog = useCallback(async () => {
    formik.resetForm();
    setOpen(false);
  }, []);

  return (
    <Transition dir="rtl" appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 " />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto font-dana">
          <div className="flex min-h-full items-center justify-center p-4 text-center pb-12">
            <LoaderContainer loading={submitActiveGiftCode.isLoading}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  dir="rtl"
                  className=" w-[80vw] !pb-0 max-w-md  shadow-dialog-shadow  transform overflow-hidden rounded-[20px]  bg-secondary p-4  text-left align-middle  transition-all"
                >
                  <Dialog.Title
                    as="div"
                    className="w-full text-lg leading-6 text-right flex  items-center justify-end"
                  >
                    <div className="cursor-pointer w-4 h-4" onClick={closeDialog}>
                      <CloseDialogButton />
                    </div>
                  </Dialog.Title>
                  <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col items-center -mt-4"
                  >
                    <div className=" h-[72px] z-10">
                      <img className=" mx-auto h-full" src={giftSvg} />
                    </div>
                    <p className="text-primary font-semibold text-lg font-kalameh mt-4 mb-6">
                      فعال سازی اشتراک هدیه
                    </p>
                    <div className="flex flex-col gap-[2px]  w-full">
                      <label
                        htmlFor="code-input"
                        className="text-gray_4 font-dana font-normal text-xs leading-6 text-start"
                      >
                        کد هدیه را وارد کنید
                      </label>
                      <input
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.code}
                        type="text"
                        name="code"
                        id="code-input"
                        className="bg-transparent outline-none h-12 p-3 border border-gray_4 border-solid rounded-[5px] text-sm text-gray_6 font-dana font-normal"
                      />
                      {formik?.errors?.code && (
                        <p className="font-dana text-xs text-red mt-[6px] text-start">
                          {formik?.errors?.code}
                        </p>
                      )}
                    </div>
                    <div
                      className={'flex items-center justify-center w-full mb-4'}
                    >
                      <CardActionArea
                        type="submit"
                        className="!mt-3 !text-center !h-[46px] !font-medium !text-gray_1 !text-base !bg-primary !w-full !rounded-[23px] !p-3 focus:!outline-none"
                      >
                        اعمال
                      </CardActionArea>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </LoaderContainer>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default memo(GiftDialog);
