import React from 'react';
import CommonHeader from '../../components/common/CommonHeader.jsx';
import Container from '../../components/PageParentContainer/Container.jsx';
import { CardActionArea } from '@mui/material';
import { VoiceVisualizer } from 'react-voice-visualizer';
import clsx from 'clsx';
import PlusIconSvg from '../../components/svg/common/PlusIconSvg.jsx';
import { TogglePlayGrayButton } from '../../components/common/TogglePlayGrayButton.jsx';
import { convertTime } from '../../helper/ConvertPlayerTIme.js';
import SwipeBottomSheet from '../../components/bottomSheets/SwipeBottomSheet.jsx';
import { MeloPostHashtag } from './hashtag/MeloPostHashtag.jsx';

import { HashtagItem } from '../../components/meloPost/hashtag/HashtagItem.jsx';
import { useSubmitMeloPost } from '../../hooks/useSubmitMelopost.js';
import { SubmitMelopostProgressDialog } from '../../components/meloPost/SubmitMelopostProgressDialog.jsx';
import TriangleSvg from '../../components/svg/tickets/TriangleSvg.jsx';
import { MelopostFilePlaySection } from '../../components/meloPost/MelopostFilePlaySection.jsx';
import { NoteMusicLoadingContainer } from '../../components/loading/NoteMusicLoadingContainer.jsx';
import MusicNoteLoading from '../../components/loading/MusicNoteLoading.jsx';
import { FullPageLoader } from '../../components/common/FullPageLoader.jsx';

export const SubmitMeloPost = () => {
  const {
    loading,
    formik,
    openHashtag,
    setOpenHashtag,
    selectedHashtags,
    createHashtagLimitStatus,
    recorderControls,
    duration,
    currentAudioTime,
    isPausedRecordedAudio,
    togglePauseResume,
    toggleBugReportBottomSheet,
    handleSelectHashtags,
    getSuggestedHashtagsQuery,
    removeHashtagOfList,
    setFinalHashtagsList,
    setCreateHashtagLimitStatus,
    setSelectedHashtags,
    setProgressDialog,
    progressDialog,
    percentage,
    uploadError,
    handleCloseUploadDialog,
    isProcessingRecordedAudio,
    isEditMelopost,
    melopostItem,
    editLoading

  } = useSubmitMeloPost();

  return (
    <Container
      style=" bg-background p-4"
      header={<CommonHeader title="ملوپست جدید" />}
    >
      {editLoading?
      <FullPageLoader/>
        :null
      }
      <SubmitMelopostProgressDialog
        progressPercent={percentage}
        open={progressDialog}
        handleCLoseDialog={handleCloseUploadDialog}
      />
      <SwipeBottomSheet
        open={openHashtag}
        toggleDrawer={toggleBugReportBottomSheet}
      >
        <MeloPostHashtag
          setFinalHashtagsList={setFinalHashtagsList}
          selectedHashtags={selectedHashtags}
          handleSelectHashtags={handleSelectHashtags}
          hashtags={getSuggestedHashtagsQuery?.data?.data?.result?.hashtags}
          setCreateHashtagLimitStatus={setCreateHashtagLimitStatus}
          createHashtagLimitStatus={createHashtagLimitStatus}
          queryLoading={getSuggestedHashtagsQuery?.isFetching}
          setSelectedHashtags={setSelectedHashtags}
          defaultSelectedHashtags={formik?.values?.hashtags}
        />
      </SwipeBottomSheet>
     <div className='font font-dana pt-8 pb-[88px]' dir='rtl'>
       <form
         onSubmit={formik.handleSubmit}
         className="w-full h-full flex flex-col gap-6   hide-scroll-bar"
       >

         <div className='flex flex-col gap-[10px] submit-melopost'>
           <p className="text-gray_3 text-[16px] fa-format-500 ">فایل صوتی:</p>
          <MelopostFilePlaySection duration={duration} currentAudioTime={currentAudioTime} isEdit={isEditMelopost} isPausedRecordedAudio={isPausedRecordedAudio} isProcessingRecordedAudio={isProcessingRecordedAudio} melopostItem={melopostItem} recorderControls={recorderControls} togglePauseResume={togglePauseResume}/>
         </div>

          <div className="flex flex-col gap-[10px]">
            <p className="text-gray_3 text-[16px] fa-format-500 ">عنوان:</p>

            <input
              disabled={loading}
              autoComplete="off"
              id="title"
              onChange={formik.handleChange}
              value={formik.values.title}
              className={clsx(
                `text-white text-[14px] font-format-400 w-full bg-primary_dark focus:outline-none h-[46px] px-4 rounded-[8px]`,
                formik?.errors?.title ? 'border border-[1px] border-red' : '',
              )}
            />
            {formik?.errors?.title && (
              <p className={`text-red text-[14px] fa-format-400 `}>
                {formik?.errors?.title}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-[10px]">
            <div className='flex gap-2 items-center  '>
           <p className="text-gray_3 text-[16px] fa-format-500 ">توضیحات:</p>
           <p className="text-gray_2 text-[12px] font-[600] ">(اختیاری)</p>

           </div>

            <textarea
              disabled={loading}
              id="caption"
              onChange={formik.handleChange}
              value={formik.values.caption}
              placeholder="بنویسد..."
              className={`h-[112px] w-full text-white text-[14px] font-format-400  bg-primary_dark rounded-[8px] placeholder:text-gray_3 resize-none  px-4 focus:outline-none p-4 ${
                formik?.errors.caption && 'border !border-1 !border-red '
              } ${
                loading ? 'pointer-events-none opacity-75  cursor-auto' : ''
              }`}
            />
            <div
              onClick={() => setOpenHashtag(true)}
              className="flex h-[32px] w-[86px] border-[px] border-[1px] border-gray_4 rounded-[100px] bg-primary_dark gap-2 items-center justify-center"
            >
              <div className="w-[12px] h-[12px]">
                <PlusIconSvg fillColor="#E0E0E0" />
              </div>
              <p className="text-[12px] fa-format-400 text-gray_4 ">هشتگ</p>
            </div>
            <div className="flex flex-wrap gap-3 ">
              {formik?.values?.hashtags?.map(hashtag => {
                return (
                  <HashtagItem
                    title={hashtag?.title}
                    isSelected={true}
                    viewCount={hashtag?.used_count}
                    hasDeleteIcon={true}
                    onDelete={() => removeHashtagOfList(hashtag)}
                  />
                );
              })}
            </div>
          </div>

          <div className="flex flex-col gap-[18px] !fixed bottom-[30px] max-w-[520px] !w-full   left-[50%] translate-x-[-50%] ">
            {uploadError ? (
              <div className="flex gap-2 items-center px-4 ">
                <div className="w-4 h-4 min-w-4">
                  <TriangleSvg fill={'#EB5757'} />
                </div>
                <p className="text-[14px] fa-format-600 text-red ">
                  خطایی رخ داده است
                </p>
              </div>
            ) : null}
            <CardActionArea
              className={
                '!h-[45px] !rounded-full !overflow-hidden   !flex !justify-center !px-4 '
              }
              disabled={loading}
            >
              <button
                disabled={loading}
                type="submit"
                className="w-full bg-primary text-gray_1 fa-format-500 flex items-center justify-center text-[16px] rounded-[100px] h-[45px]"
              >
                <span>ارسال</span>
              </button>
            </CardActionArea>
          </div>
        </form>
      </div>
    </Container>
  );
};
