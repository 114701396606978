import React from 'react';

function ReelsReportIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='#fff'
        d='M12 24C5.368 24 0 18.633 0 12 0 5.368 5.367 0 12 0s12 5.367 12 12-5.367 12-12 12m0-22.125A10.12 10.12 0 0 0 1.875 12 10.12 10.12 0 0 0 12 22.125 10.12 10.12 0 0 0 22.125 12 10.12 10.12 0 0 0 12 1.875m.938 11.14V6.98a.937.937 0 1 0-1.876 0v6.037a.937.937 0 1 0 1.876 0M12 15.102a1.266 1.266 0 1 0 0 2.531 1.266 1.266 0 0 0 0-2.53'
      ></path>
    </svg>
  );
}

export default ReelsReportIcon;
