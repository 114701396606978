import { createSlice } from "@reduxjs/toolkit";

export const ReelsPlayerSlice = createSlice({
    name: "reelPlayer",
    initialState: {
        reelPlayerStatus: false,
        currentReelIndex: 0,
        reelsList: [],
        reelWeightCount: 0
    },
    reducers: {
        settPlayingReel: (state, action) => {
            const { reelPlayerStatus, reelsList, currentReelIndex } = action.payload
            state.reelPlayerStatus = reelPlayerStatus;
            state.reelsList = reelsList;
            state.currentReelIndex = currentReelIndex;
        },
        closeReelPlayer: (state, action) => {
            state.reelPlayerStatus = false;
            state.currentReelIndex = 0;
            state.reelsList = [];
        },
        openReelPlayer:(state,action) =>{
            state.reelPlayerStatus = true;
        },
        setReelWeightCount: (state, action) => {

            state.reelWeightCount = (
                Number(state.reelWeightCount) + Number(1)
            ).toFixed(2);
        },
        resetReelWeightCount: (state, action) => {
            state.reelWeightCount = 0;
        },
        pauseReelPlayer: (state, action) => {
            const { status} = action.payload
            state.reelPlayerStatus = !status;

        },
    }
})

export const {
    settPlayingReel,
    closeReelPlayer,
    setReelWeightCount,
    resetReelWeightCount,
    pauseReelPlayer,
    openReelPlayer
} = ReelsPlayerSlice.actions;

export const selectReelPlayerStatus = (state) => state.reelsPlayer.reelPlayerStatus;
export const selectReelsList = (state) => state.reelsPlayer.reelsList;
export const selectCurrentReelIndex = (state) => state.reelsPlayer.currentReelIndex;
export const selectReelWeightCount = (state) => state.reelsPlayer.reelWeightCount;

export default ReelsPlayerSlice.reducer;


