import { useMutation } from '@tanstack/react-query';
import { generateShareLink } from '../services/ApiClient.js';
import { mainToast } from '../helper/ToastsEmitter.jsx';
import { CopyText } from '../helper/CopyText.js';
import { setCustomDialog } from '../redux/dialog/DialogSlice.js';
import { useDispatch } from 'react-redux';


export const useShare = (id, type,errorDialogIcon,onSettle) => {
  const dispatch = useDispatch();
  const requestPayload = {
    id,
    type,
  };
  const generateShareLinkMutation = useMutation(
    () => generateShareLink(requestPayload),
    {
      onSuccess: res => {
        if (res?.data?.error) {
          dispatch(
            setCustomDialog({
              custom_dialog: {
                btn_text: 'متوجه شدم',
                description: res?.data?.error?.message,
                localImage: errorDialogIcon,
              },
            }),
          );
        } else {
          handleShare(res?.data?.result?.link);
        }
      },
      onSettled:()=>{
        if(onSettle){
          onSettle();
        }
      }
    },
  );
  const handleGetShareLink = () => {
    if (generateShareLinkMutation.isLoading) {
      return;
    }
    generateShareLinkMutation.mutate();
  };
  const handleShare = link => {
    const shareData = {
      title: 'اشتراک گذاری',
      text: link,
    };
    if (window.navigator.share) {
      window.navigator
        .share(shareData)
        .then(() => console.log('Yay, you shared it :)'))
        .catch(error => console.log("Oh noh! You couldn't", error));
    } else {
      mainToast('.با موفقیت کپی شد ');
      CopyText(link);
    }
  };

  return {
    handleGetShareLink,
    generateLinkLoading: generateShareLinkMutation?.isLoading,
  };
};
