import React from 'react';

export const EmptyReelsSvg = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="88"
      height="86"
      fill="none"
      viewBox="0 0 88 86"
    >
      <path
        fill="#4F4F4F"
        d="M1.13 64.217c0 11.711 9.54 21.25 21.25 21.25h42.5c11.712 0 21.25-9.539 21.25-21.25V26.44h-85zm30.695-21.25c0-.826.449-1.605 1.157-2.03.732-.425 1.63-.449 2.361-.024l21.25 11.806a2.36 2.36 0 0 1 1.204 2.054c0 .85-.472 1.652-1.204 2.054l-21.25 11.805a2.3 2.3 0 0 1-1.157.307c-.425 0-.826-.118-1.204-.33a2.39 2.39 0 0 1-1.157-2.03zm54.306-21.25c0-11.71-9.539-21.25-21.25-21.25H51.966l14.166 21.25zm-25.642 0L46.3.467H22.38c-.378 0-.732 0-1.086.024l14.143 21.226zM16.242 1.388C7.506 4.008 1.131 12.131 1.131 21.718h28.664z"
      ></path>
      <path
        fill="#4F4F4F"
        d="M.593 83.975a2.1 2.1 0 0 1 .045-2.971L83.508.594a2.101 2.101 0 0 1 2.926 3.015L3.564 84.02a2.1 2.1 0 0 1-2.97-.044"
      ></path>
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M86.37 7.851a5.1 5.1 0 0 0 .11-7.213 2.1 2.1 0 0 1-.046 2.971L3.564 84.02a2.1 2.1 0 0 1-2.97-.044 5.1 5.1 0 0 0 7.212.109z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

