import React from 'react'
import HomeMetaDataWithTitle from '../common/HomeMetaDataWithTitle';
import HomeTrackCardItem from './HomeTrackCardItem';

function HomeTrackCollection({item}) {
console.log('ASfasfasfasdasd', item?.element_data);

  return (
    <>
      <div dir="rtl" className="flex flex-col">
        {/* <div className="w-full px-3">
          <HomeMetaDataWithTitle metaData={item?.track_meta_data} />
        </div> */}
        <div className="flex flex-col">
          <HomeTrackCardItem item={item?.element_data} />
        </div>
      </div>
    </>
  );
}

export default React.memo(HomeTrackCollection)
