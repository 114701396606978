import  React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
  likeMelopost,
  selectGlobalData,
  unLikeMelopost,
} from '../redux/globalData/globalDataSlice.js';
import {useMutation} from "@tanstack/react-query";
import { melopostLike, melopostUnlike } from '../services/ApiClient.js';

export const useLikeMelopost = (id) => {
  const dispatch=useDispatch()
  const {likedMeloposts} = useSelector(selectGlobalData);
  const requestData = {id}
  const findMelopostIndex = () => {
    return likedMeloposts?.findIndex((itemId) => itemId === id);
  };
  const melopostIndex = findMelopostIndex();
  const likeMelopostMutation = useMutation(() => melopostLike(requestData), {});
  const disLikeMelopostMutation = useMutation(() => melopostUnlike(requestData), {});
  const handleLikeMelopost=()=>{
    dispatch(likeMelopost({id}))
    likeMelopostMutation.mutate()
  };
  const handleDisLikeMelopost=()=>{
    dispatch(unLikeMelopost({melopostIndex}));
    disLikeMelopostMutation.mutate()
  };
  const toggleLike=()=>{
    if (melopostIndex < 0) {
      handleLikeMelopost()
    } else {
      handleDisLikeMelopost()
    }
  };
  const isLiked = likedMeloposts?.find((itemId) => Number(itemId) === Number(id));

  return {isLiked,toggleLike}
}
