import { useRef, useState } from 'react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { getMeloposts, getSuggestedHashtags } from '../services/ApiClient.js';
import { MelopostsTabsItems } from '../consts/MelopostsTabsItems.js';

export const useMeloposts = ()=>{

  const [selectedHashtags,setSelectedHashtags] =useState(
    [
      [],
      [],
      [],
    ]
  )
  const [slideIndex,setSlideIndex] = useState(0)
  const swiperRef = useRef(null);



  const getSuggestedHashtagsQuery = useQuery(
    ["getSuggestHashtags"],
    () => getSuggestedHashtags(),
    {
      onSuccess: (res) => {},
      refetchOnWindowFocus: false,
    }
  );
  const requestParams = {
    hashtag: selectedHashtags[slideIndex]?.[0]?.title??'',
    type: MelopostsTabsItems[slideIndex].value,
  }
  const {
    isLoading: getMeloPostgIsLoading,
    isFetchingNextPage: getMeloPostIsFetchingNextPage,
    fetchNextPage: getMeloPostIsFetchNextPage,
    hasNextPage: getMeloPostgHasNextPage,
    data: getMelopostsData,
    refetch,
  } = useInfiniteQuery(
    [`getMelopostList`,requestParams?.hashtag,requestParams?.type],
    ({ pageParam = 0 }) =>
      getMeloposts({
        offset: pageParam,
        hashtag: requestParams?.hashtag,
        type: requestParams?.type,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const allData = allPages?.flatMap(page => page.data.result.meloposts);
        console.log('asfsafasfasfsaf', allData);
        return lastPage?.data?.result?.end ? false : allData?.length;
      },
      refetchOnWindowFocus: false,
    },
  );
  const handleTabChange = value => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(value);
    }
    setSlideIndex(value);
  };
  const handleSwiperChange = swiper => {
    setSlideIndex(swiper.activeIndex);
  };
  const checkIsHashtagSelected = (clickedItem) => {
      return   selectedHashtags[slideIndex].find(item =>clickedItem?.title ===item?.title)
  }

  const handleSelectHashtags = (newItem) => {
    setSelectedHashtags((prevSelected) =>
      prevSelected.map((item, index) =>
        index === slideIndex ? (checkIsHashtagSelected(newItem) ? [] : [newItem]) : item
      )
    );
  };
  const clearSelectedHashtags = ()=>{
    setSelectedHashtags((prevSelected) =>
      prevSelected.map((item, index) =>
        index === slideIndex ? []  : item
      )
    );
  }
  const getMelopostsFlatMap = getMelopostsData?.pages?.flatMap(
    page => page.data.result.meloposts,
  );
  const getMeloPostNextPage = () => {
    if (getMeloPostgHasNextPage && !getMeloPostIsFetchingNextPage) {
      getMeloPostIsFetchNextPage();
    }
  };
  const [activePlayerIndex, setActivePlayerIndex] = useState(null);

  const handlePlay = index => {
    if (activePlayerIndex === index) {
      setActivePlayerIndex(null); // If the same item is clicked again, stop it
    } else {
      setActivePlayerIndex(index); // Set active player to the clicked item
    }
  };
  return {
    slideIndex,setSlideIndex,
    handleTabChange,
    handleSwiperChange,
    hashtags:getSuggestedHashtagsQuery?.data?.data?.result?.hashtags,
    isLoadingHashtags:getSuggestedHashtagsQuery?.isLoading,
    checkIsHashtagSelected,
    handleSelectHashtags,
    clearSelectedHashtags,
    getMeloPostgIsLoading,
    getMeloPostIsFetchingNextPage,
    getMeloPostIsFetchNextPage,
    getMeloPostgHasNextPage,
    getMelopostsFlatMap,
    getMeloPostNextPage,
    handlePlay,
    activePlayerIndex,
    refetch
  }

}
