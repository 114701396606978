import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getMeloposts } from '../../../services/ApiClient.js';
import CommonHeader from '../../../components/common/CommonHeader.jsx';
import Container from '../../../components/PageParentContainer/Container.jsx';
import { RenderMelopostList } from '../../../components/meloPost/list/RenderMelopostList.jsx';
import { HashtagItem } from '../../../components/meloPost/hashtag/HashtagItem.jsx';


function HashtagMelopostList(props) {


  const { hashtag } = useParams();
  const [activePlayerIndex, setActivePlayerIndex] = useState(null);
  const {
    isLoading: getMeloPostgIsLoading,
    isFetchingNextPage: getMeloPostIsFetchingNextPage,
    fetchNextPage: getMeloPostIsFetchNextPage,
    hasNextPage: getMeloPostgHasNextPage,
    data: getMelopostsData,
    refetch,
  } = useInfiniteQuery(
    [`getHashtagMelopostList`,hashtag],
    ({ pageParam = 0 }) =>
      getMeloposts({
        offset: pageParam,
        hashtag: hashtag,
        type:'',
      }),

    {
      getNextPageParam: (lastPage, allPages) => {
        const allData = allPages?.flatMap(page => page.data.result.meloposts);
        return lastPage?.data?.result?.end ? false : allData?.length;
      },
      cacheTime:0,
      refetchOnWindowFocus: false,
    },
  );
  const getMelopostsFlatMap = getMelopostsData?.pages?.flatMap(
    page => page.data.result.meloposts,
  );
  const getMeloPostNextPage = () => {
    if (getMeloPostgHasNextPage && !getMeloPostIsFetchingNextPage) {
      getMeloPostIsFetchNextPage();
    }
  };
  const handlePlay = index => {
    if (activePlayerIndex === index) {
      setActivePlayerIndex(null); // If the same item is clicked again, stop it
    } else {
      setActivePlayerIndex(index); // Set active player to the clicked item
    }
  };
  return (
    <Container
      style='bg-black'
      shadow={false}
      header={<CommonHeader title="ملودیفای" startAdornmentClassName='hidden' />}

    >

      <div dir='rtl' className='flex flex-col  '>
        <div className='flex flex-col gap-5 pt-4'>
          <div className='px-4'>
            <HashtagItem
              title={hashtag}
              isSelected={true}
              hasDeleteIcon={false}
            />
          </div>
          <RenderMelopostList refetch={refetch} handlePlay={handlePlay} getMeloPostgIsLoading={getMeloPostgIsLoading} activePlayerIndex={activePlayerIndex} getMeloPostgHasNextPage={getMeloPostgHasNextPage} getMeloPostIsFetchingNextPage={getMeloPostIsFetchingNextPage} getMeloPostNextPage={getMeloPostNextPage} getMelopostsFlatMap={getMelopostsFlatMap}  />
        </div>
      </div>

    </Container>
  );
}

export default HashtagMelopostList;
