import { useRef } from "react";
import ExitSvg from "../../components/setting/ExitSvg";
import PencilSvg from "../../components/setting/PencilSvg";
import { useUserData } from "../../helper/ReduxSelectorContext";
import { useMutation } from "@tanstack/react-query";
import { updateName } from "../../services/ApiClient";
import ConfirmDialogs from "../../components/dialogs/ConfirmDialogs";
import { userData } from "../../redux/login/LoginSlice";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { EDIT_PROFILE_VALIDATION_SCHEMA } from "../../consts/Validation";
import { useNavigate } from "react-router-dom";
import { dispatchUnauthenticatedUser } from "../../redux/globalstore/GlobalStore";
import Container from "../../components/PageParentContainer/Container";
import CommonHeader from "../../components/common/CommonHeader";
import ChevronLeft from "../../components/svg/common/ChevronLeft";
import { CardActionArea } from "@mui/material";
import UserNameSvg from "../../components/svg/setting/UserNameSvg";
import { mainToast } from "../../helper/ToastsEmitter";
import LoaderContainer from "../../components/loading/LoaderContainer";
function UserAccount() {
  const user = useUserData();

  const logOutDialogRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const editNameMutation = useMutation((editedData) => updateName(editedData), {
    onSuccess: (res) => {
      const data = { ...user, full_name: res?.data?.result?.user?.full_name };
      dispatchUserData(data);
      mainToast("نام با موفقیت تغییر کرد ", "");
      navigate("/setting");
    },
  });

  const formik = useFormik({
    initialValues: {
      full_name: user?.full_name != null ? user?.full_name : "",
    },
    enableReinitialize: true,
    validationSchema: EDIT_PROFILE_VALIDATION_SCHEMA,
    onSubmit: (data) => {
      if (editNameMutation.isLoading) {
        return;
      }
      editNameMutation.mutate({ full_name: data.full_name });
    },
  });

  const dispatchUserData = (Data) => {
    dispatch(
      userData({
        user: Data,
      })
    );
  };

  const handleLogout = () => {
    logOutDialogRef.current.openDialog();
  };

  const handleConfirmLogOut = () => {
    dispatchUnauthenticatedUser();
  };

  const handleEditPhoneNumber = () => {
    navigate("/merge-number");
  };
  const onSubmitForm = () => {
    if (formik?.values?.full_name?.trim()) {
      if (user?.full_name === formik?.values?.full_name)
        mainToast("! تغییری اعمال نشده است", "");
      else formik.handleSubmit();
    } else mainToast("نام را وارد کنید", "");
  };

  return (
    <Container
      style="bg-primary_dark"
      header={<CommonHeader title=" حساب کاربری" />}
    >
      <LoaderContainer loading={editNameMutation.isLoading}>
        <div
          dir="rtl"
          className="flex flex-col  items-center justify-between px-4 py-4  w-full h-full "
        >
          <div className="w-full px-4">
            <div>
              <p className="text-xs font-medium font-dana text-gray_4 mb-2 ">
                نام:
              </p>
              <div className="bg-transparent px-6 py-3  rounded-[100px] h-12 w-full flex items-center justify-between border border-gray_4">
                <div className="h-[25px]  w-[25px] ">
                  <UserNameSvg />
                </div>
                <input
                  autoComplete="off"
                  name="full_name"
                  value={formik.values?.full_name}
                  onChange={formik.handleChange}
                  className="bg-transparent w-full  py-3 px-6 text-sm text-gray_6 font-dana font-medium  outline-none focus:outline-none autofill:bg-transparent"
                />
                <div className="h-[25px]  w-[25px] ">
                  <PencilSvg />
                </div>
              </div>
            </div>
            <CardActionArea className="!mt-4 !rounded-[20px]">
              <div
                className="bg-secondary h-[86px] rounded-[20px] w-full flex justify-between items-center py-4 ps-4 pe-6"
                onClick={handleEditPhoneNumber}
              >
                <div className="flex flex-col justify-center items-start font-dana">
                  <p className=" font-medium  text-gray_6 text-sm mb-2 ">
                    ویرایش شماره تلفن
                  </p>
                  <p className=" font-medium  text-gray_4 text-xs leading-6 ">
                    {user?.phone}
                  </p>
                </div>
                <div className="flex">
                  <ChevronLeft className="stroke-primary" />
                </div>
              </div>
            </CardActionArea>
          </div>

          <CardActionArea className="!mb-[18px] ! rounded-3xl">
            <button
              type="submit"
              onClick={onSubmitForm}
              className="text-title_box text-base font-medium w-full  h-[46px] flex items-center justify-center rounded-3xl  bg-primary  font-dana "
            >
              ذخیره
            </button>
          </CardActionArea>
        </div>
      </LoaderContainer>

      <ConfirmDialogs
        title={
          <span className="font-medium  text-lg">خروج از حساب کاربری</span>
        }
        text={"آیا میخواهید از حساب خود خارج شوید؟"}
        buttonDirection={"dir-ltr"}
        confirmText={"بله"}
        confirmTextColor={"text-primary"}
        confirmBGColor={"bg-transparent"}
        confirmBorderColor={"border-primary"}
        confirmAction={handleConfirmLogOut}
        cancelText={"خیر"}
        cancelTextColor={"text-secondary"}
        cancelBGColor={"bg-primary"}
        cancelBorderColor={"transparent"}
        ref={logOutDialogRef}
      />
    </Container>
  );
}

export default UserAccount;
