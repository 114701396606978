import React, {useEffect, useRef} from 'react';
import Container from "../../components/PageParentContainer/Container.jsx";
import CollectionCategoryHeader from "../../components/playlist/CollectionCategoryHeader.jsx";
import ScrollTopButton from "../../components/common/ScrollTopButton.jsx";
import PlaylistUpdateDialog from "../../components/playlist/PlaylistUpdateDialog.jsx";
import {useLocation} from "react-router-dom";
import AddToPlaylistModeButton from "../../components/playlist/AddToPlaylistModeButton";
import { addToPlaylistData, useUserData } from '../../helper/ReduxSelectorContext.js';
import {disableListSelectMode} from "../../redux/adToPlaylist/AddToPlaylistSlice.js";
import {useDispatch} from "react-redux";
import AbsoluteBackground from "../../components/common/AbsoluteBackground.jsx";
import HomeSlider from '../../components/home/HomeSlider.jsx';
import { RenderExclusiveTracksList } from '../../components/Exclusive/RenderExclusiveTracksList';
import { ExclusiveTracksHeader } from '../../components/Exclusive/ExclusiveTracksHeader';
import CustomLazyLoadImage from '../../components/common/CustomLazyLoadImage.jsx';
import { useAction } from '../../hooks/useAction.js';

function DjExclusiveTracks(props) {
  const location = useLocation();
  const dispatch = useDispatch()
  const action = useAction()
  const trackTypes = location.state?.trackTypes;
  const pageData = location.state?.pageData;
  const addToPlaylistDataState = addToPlaylistData()
  const dialogRef = useRef(null);
  const userData = useUserData();
  console.log(pageData,'page data...');

  useEffect(() => {
    return () => {
      dispatch(disableListSelectMode())
    };
  }, []);
  const handleAction = ()=>{
    action(pageData?.action)
  }
  const isPremiumUser = userData?.is_premium
  return (
    <Container style="bg-primary_dark !overflow-hidden">



      <CollectionCategoryHeader hasSearch={false} title={'دیجی میکس‌ها'}
                                backgroundId='absolute-background'
                                pageId='exclusive-playlist-song'/>

      <div id="exclusive-playlist-song" className=" relative overflow-scroll h-full hide-scroll-bar  ">
        <AddToPlaylistModeButton EnableEditMode={addToPlaylistDataState?.listSelectItems?.length > 0}/>
        <PlaylistUpdateDialog ref={dialogRef}/>
        {Boolean(isPremiumUser) ?
          <AbsoluteBackground
            image={pageData?.background}
            animation={true}
          ></AbsoluteBackground>
          :
          <ExclusiveTracksHeader pageData={pageData} />}
        <div
          id="collection-page-exclusive"
          dir="rtl"
          className="w-full h-full  relative z-[20]  ">
          {Boolean(isPremiumUser) &&
            <div id='playlist-songs-background' className=' w-full aspect-square relative  overflow-hidden flex justify-center items-start   '>
              <div onClick={handleAction} className='w-full absolute  bottom-[24%] px-2  '>
                <div className='w-full  relative h-[145px]'>
                  <CustomLazyLoadImage
                    hasEffect={true}
                    image={pageData?.foreground}
                    imageClass=" rounded-[5px] "
                  />
                </div>
              </div>
            </div>
          }


          <RenderExclusiveTracksList  trackTypes={trackTypes} hasSort={true} hasMarginClass={false} sortMargin={isPremiumUser} />
        </div>
      </div>
      <ScrollTopButton id={"exclusive-playlist-song"}/>

    </Container>
  );
}

export default DjExclusiveTracks;
