import React from 'react';
import {
  getAdvertiseData,
  getHaveAdvertise,
  getIsPlaying,
  getPlayerMusic,
  getTrackDuration,
} from '../../helper/ReduxSelectorContext';
import {
  resetPlayer,
  selectSeekTime,
  setSwipePlayerStatus,
} from '../../redux/player/PlayMusicSlice';
import { useDispatch, useSelector } from 'react-redux';

import CustomLazyLoadImage from '../common/CustomLazyLoadImage';
import { IconButton, Slider } from '@mui/material';
import { dispatchIsPlaying } from '../../redux/globalstore/GlobalStore';
import TextMarquee from '../common/TextMarquee';
import { setLanguageDirection } from '../../helper/LanguageDetection';
import MiniAdsBox from './avertiseds/MiniAdsBox';
import SwipeProvider from '../common/SwipeProvider';
import ClickReaction from '../../helper/ClickReaction';
import { textFontBasedLanguage } from '../../helper/TextFontBasedLanguage.js';

function MiniBottomPlayer() {
  let playedTrackData = getPlayerMusic();
  let trackDuration = getTrackDuration();
  let seekTime = useSelector(selectSeekTime);
  let isPlaying = getIsPlaying();
  let havAdvertise = getHaveAdvertise();
  let advertiseData = getAdvertiseData();
  const dispatch = useDispatch();
  const handleOpenSwipePlayer = () => {
    if (playedTrackData?.amplitude) return;
    dispatch(setSwipePlayerStatus({ status: true }));
  };

  const handlePlayPause = e => {
    e.stopPropagation();
    dispatchIsPlaying(!isPlaying);
  };

  const clearPlayer = () => {
    dispatch(resetPlayer());
  };

  return (
    <>
      {playedTrackData && (
        <>
          {havAdvertise && advertiseData ? (
            <MiniAdsBox />
          ) : (
            <div
              onClick={() => handleOpenSwipePlayer()}
              className="w-full fixed flex flex-col max-w-[520px]  h-[48px]  z-20 min-player-position  "
            >
              <SwipeProvider
                isShow={Boolean(playedTrackData)}
                swipeHandler={clearPlayer}
              >
                <div
                  dir="ltr"
                  style={{
                    background: playedTrackData?.background_color,
                  }}
                  className="  flex flex-col w-full h-full relative  "
                >
                  <div className="absolute bottom-0 right-0 top-0 left-0 bg-bottom-player-overlay "></div>
                  <div className="flex flex-row justify-between items-center w-full">
                    <div className="w-[48px] h-[48px] relative">
                      <CustomLazyLoadImage image={playedTrackData?.image} />
                    </div>
                    <div className="flex flex-col max-w-[4/6] w-4/6 h-full justify-center items-center">
                      <p
                        className={` font-[500] text-center relative w-full text-[16px] text-white line-clamp-1 ${textFontBasedLanguage(
                          playedTrackData?.title,
                        )}`}
                      >
                        <TextMarquee
                          parentClass="justify-center"
                          direction={setLanguageDirection(
                            playedTrackData?.title,
                          )}
                        >
                          {playedTrackData?.title}
                        </TextMarquee>
                      </p>
                      <p className="font-dana font-[500] text-[12px] text-gray_4 relative w-full flex justify-center max-w-full">
                        <TextMarquee parentClass="justify-center">
                          {playedTrackData?.artists?.map((artist, index) => {
                            return (
                              <span
                                className={`${textFontBasedLanguage(
                                  artist?.name,
                                )}`}
                                key={index}
                              >
                                {artist?.name}{' '}
                                {index !==
                                  playedTrackData?.artists?.length - 1 &&
                                  (setLanguageDirection(
                                    playedTrackData?.title,
                                  ) === 'rtl'
                                    ? '، '
                                    : ', ')}
                              </span>
                            );
                          })}
                        </TextMarquee>
                      </p>
                    </div>
                    <div
                      onClick={e => handlePlayPause(e)}
                      className="pr-[18px]"
                    >
                      {isPlaying ? (
                        //pause SVG
                        <ClickReaction>
                          <IconButton
                            className="!p-3 !-mr-3 "
                            disableRipple={false}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              fill="none"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fill="#F2C94C"
                                d="M4.492 0H3.125A3.129 3.129 0 000 3.125v13.75A3.128 3.128 0 003.125 20h1.367a3.128 3.128 0 003.125-3.125V3.125A3.129 3.129 0 004.492 0zm1.563 16.875c0 .862-.701 1.563-1.563 1.563H3.125a1.564 1.564 0 01-1.563-1.563V3.125c0-.861.701-1.562 1.563-1.562h1.367c.862 0 1.563.7 1.563 1.562v13.75zM18.477 13.75c.431 0 .78-.35.78-.781V3.125A3.129 3.129 0 0016.134 0h-1.328a3.129 3.129 0 00-3.125 3.125v13.75A3.129 3.129 0 0014.805 20h1.328a3.129 3.129 0 003.125-3.125.781.781 0 00-1.563 0c0 .861-.7 1.562-1.562 1.562h-1.328a1.564 1.564 0 01-1.563-1.562V3.125c0-.862.701-1.563 1.563-1.563h1.328c.861 0 1.562.701 1.562 1.563v9.844c0 .431.35.78.782.78z"
                              ></path>
                            </svg>
                          </IconButton>
                        </ClickReaction>
                      ) : (
                        // Play Svg
                        <ClickReaction>
                          <IconButton
                            className="!p-3 !-mr-3  "
                            disableRipple={false}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-full w-[18px]"
                              fill="none"
                              viewBox="0 0 15 17"
                            >
                              <path
                                fill="#F2C94C"
                                d="M13.364 6.622L3.75.696A2.196 2.196 0 001.5.648a2.196 2.196 0 00-1.139 1.94v11.8c0 1.235.995 2.245 2.219 2.252h.01c.382 0 .78-.12 1.152-.347a.635.635 0 10-.661-1.084.973.973 0 01-.495.161.986.986 0 01-.955-.982v-11.8c0-.35.182-.66.488-.832a.942.942 0 01.965.02l9.614 5.927a.935.935 0 01.454.814c0 .34-.167.635-.458.813l-6.951 4.256a.635.635 0 10.663 1.082l6.95-4.255a2.208 2.208 0 001.066-1.893 2.208 2.208 0 00-1.058-1.898z"
                              ></path>
                              <path
                                fill="#F2C94C"
                                fillRule="evenodd"
                                d="M2.58 16.64C1.355 16.631.36 15.621.36 14.386V2.588c0-.817.427-1.542 1.14-1.94a2.196 2.196 0 012.25.047l9.614 5.927a2.208 2.208 0 011.057 1.897c-.002.78-.4 1.487-1.065 1.893l-6.95 4.256a.635.635 0 11-.664-1.083l6.952-4.256a.936.936 0 00.457-.813.935.935 0 00-.453-.814L3.083 1.776a.942.942 0 00-.964-.02.942.942 0 00-.489.832v11.8c0 .584.494.98.956.982.15 0 .32-.055.494-.161a.635.635 0 01.662 1.083 2.23 2.23 0 01-1.153.347h-.01zm1.35-.04c-.421.257-.884.4-1.34.4H2.576C1.152 16.992 0 15.818 0 14.387V2.588C0 1.641.496.795 1.324.332M3.93 16.6a.996.996 0 10-1.037-1.7.626.626 0 01-.306.109.626.626 0 01-.596-.622V2.588c0-.22.112-.41.304-.517a.581.581 0 01.6.012l9.614 5.927a.575.575 0 01.282.506.575.575 0 01-.285.505l-6.952 4.256a.996.996 0 101.04 1.699l6.95-4.256a2.569 2.569 0 001.238-2.2 2.568 2.568 0 00-1.23-2.206L3.94.388A2.557 2.557 0 001.324.332"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </IconButton>
                        </ClickReaction>
                      )}
                    </div>
                  </div>
                  <div className="w-full h-[2px] ">
                    <Slider
                      aria-label="time-indicator"
                      className="!w-full !h-[2px] !absolute bottom-0 !m-0 !p-0 "
                      value={seekTime}
                      min={0}
                      // step={0.01}
                      max={trackDuration}
                      sx={{
                        color: '#F2C94C',
                        height: 4,
                        '& .MuiSlider-thumb': {
                          display: 'none',
                        },
                        '& .MuiSlider-rail': {
                          backgroundColor: 'transparent',
                          opacity: 1,
                        },
                      }}
                    />
                  </div>
                </div>
              </SwipeProvider>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default MiniBottomPlayer;
