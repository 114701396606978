import React, { useState } from 'react';

import UnFollowIcon from '../../svg/common/UnFollowIcon.jsx';
import FollowIcon from '../../svg/common/FollowIcon.jsx';
import clsx from 'clsx';
import UnfollowDialog from '../../common/UnfollowDialog.jsx';

import { useFollowCollection } from '../../../helper/useFollowCollection.js';
import ShareLoading from '../../svg/common/ShareLoading.jsx';

export const FollowUnfollowArtistPlaylist = ({ id,type,isFollow,toggleFollow,unFollow,followLoading }) => {
  const [followDialogState, setFollowDialogState] = useState(false);


  const closeDialog = e => {
    console.log(e);
    if (typeof e === 'object') {
      e?.stopPropagation();
    }
    setFollowDialogState(false);
  };
  const openDialog = () => {
    setFollowDialogState(true);
  };
  const unfollowCollectionHandler = (e) => {
    closeDialog(e)
    unFollow()
  };
  const handleFollowCollection = () => {
    if(followLoading){
      return
    }
    if(isFollow){
      openDialog()
    }else{
      toggleFollow()
    }
  }
  return (
    <>
      <UnfollowDialog closeDialog={closeDialog} openState={followDialogState} confirmFunction={unfollowCollectionHandler} type={type}/>
      {
        followLoading?
          <div className='h-[30px] w-[30px]'>
            <ShareLoading/>
          </div>
          :
          <div onClick={handleFollowCollection} className="flex flex-col gap-[6px] items-center ">
            {
              isFollow?
                <UnFollowIcon/>
                :
                <FollowIcon/>
            }
            <p className={clsx(` text-[10px] fa-format-500`,isFollow?'text-primary':'text-gray_6')}>{isFollow ? 'دنبال میکنید' : 'دنبال‌کردن'}</p>
          </div>


      }

    </>

  );
};


