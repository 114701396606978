import React from 'react'
import MusicNoteLoading from './MusicNoteLoading';
import clsx from 'clsx';

export const NoteMusicLoadingContainer = ({className}) => {
  return (
    <div className={clsx(`absolute z-30 top-0 left-[50%] translate-x-[-50%] w-full h-full flex flex-row justify-center items-center  bg-black bg-opacity-50`,className)}>
      <MusicNoteLoading width={120} />
    </div>
  );
}

