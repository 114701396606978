import React from 'react';
import CommonHeader from '../../components/common/CommonHeader.jsx';
import Container from '../../components/PageParentContainer/Container.jsx';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getReels } from '../../services/ApiClient.js';
import { GET_REEL_TYPES } from '../../consts/MeloReelsConst.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import SongListLoading from '../../components/loading/SongListLoading.jsx';
import { VirtuosoGrid } from 'react-virtuoso';
import { LikedReelsItem } from '../../components/reels/LikedReelsItem';
import CssShimmer from '../../components/common/CssShimmer.jsx';
import { useReels } from '../../consts/useReels.js';
import { EmptyReelsSvg } from '../../components/svg/reels/EmptyReelsSvg.jsx';
import ClickReaction from '../../helper/ClickReaction.jsx';
import { CardActionArea } from '@mui/material';
import { useAction } from '../../hooks/useAction.js';

function LikedReels(props) {
  const { playReel } = useReels();
  const action  = useAction()
  const {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: getLikedReels,
  } = useInfiniteQuery(
    [`getLikedReels`],
    ({ pageParam = 0 }) =>
      getReels({
        offset: Number(pageParam),
        model_type: GET_REEL_TYPES.LIKED,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const allData = allPages?.flatMap(page => page?.data?.result?.reels);
        return lastPage?.data?.result?.end ? false : allData?.length;
      },
      refetchOnWindowFocus: false,
    },
  );
  const likedReelsList = getLikedReels?.pages?.flatMap(
    page => page?.data?.result?.reels,
  );
  const handleOpenReels =()=>{
    const actionData = {
      target_type:'reels',
      model_type:GET_REEL_TYPES.HOME

    }
    action(actionData)
  }
  const handleLoadMore = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };
  const scrollableTarget = 'scrollbar-like-reel';
  const handlePlayReel = index => {
    playReel({
      index,
      lists: likedReelsList,
      model_type: GET_REEL_TYPES.LIKED,
    });
  };
  const array = new Array(9).fill(0);
  return (
    <Container
      style=" bg-background p-4"
      header={<CommonHeader title="ریلزهای لایک شده" />}
    >
      <div
        id={scrollableTarget}
        dir="rtl"
        className="w-full h-full flex flex-col !overflow-y-scroll !webkit-overflow-scrolling-touch"
      >
        <InfiniteScroll
          className="!overflow-hidden"
          next={handleLoadMore}
          hasMore={hasNextPage}
          dataLength={likedReelsList?.length || 0}
          loader={
            isFetchingNextPage && (
              <div className="w-full flex justify-center items-start h-[50px]">
                <SongListLoading />
              </div>
            )
          }
          scrollableTarget={scrollableTarget}
        >
          {isLoading ? (
            <div className="w-full h-full !overflow-hidden  pb-28">
              <div className="!grid !grid-cols-3 !gap-2 !w-full ">
                {array?.map(item => {
                  return (
                    <CssShimmer classNames="rounded-[5px] rounded-bl-[13px] aspect-[11/20] w-full" />
                  );
                })}
              </div>
            </div>
          ) : likedReelsList?.length === 0 ? (
            <div className="flex flex-col gap-4 justify-center items-center mt-32">
              <div className='flex flex-col gap-[50px] items-center justify-center'>
                <EmptyReelsSvg />
                <span className="text-gray_3 font-medium font-dana text-[16px] leading-6">
                هنوز ریلزی لایک نکرده‌اید!
              </span>
              </div>
              <ClickReaction>
                <CardActionArea onClick={handleOpenReels} className="!w-[198px] !h-[31px]   !rounded-[100px]">
                  <button

                    className=" w-full h-full rounded-[100px] text-gray_1 bg-primary  font-dana font-[600] text-[12px]  cursor-pointer "
                  >
                    ریلزهای جذاب را کشف کنید
                  </button>
                </CardActionArea>
              </ClickReaction>
            </div>
          ) : (
            <div className="w-full h-full !overflow-hidden  pb-28">
              <VirtuosoGrid
                id="container"
                dir="rtl"
                style={{ overflowY: 'hidden !important' }}
                customScrollParent={document.getElementById(scrollableTarget)}
                data={likedReelsList}
                listClassName="!grid !grid-cols-3 !gap-2 !w-full !mt-4"
                itemContent={(index, item) => (
                  <div onClick={() => handlePlayReel(index)}>
                    <LikedReelsItem item={item} />
                  </div>
                )}
              />
            </div>
          )}
        </InfiniteScroll>
      </div>
    </Container>
  );
}

export default LikedReels;
