import React from 'react';

function HashtagSearchNotFoundSvg(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='96'
      height='96'
      fill='none'
      viewBox='0 0 96 96'
    >
      <g fill='#4F4F4F' clipPath='url(#clip0_2113_184980)'>
        <path
          d='M64.532 0c-17.35 0-31.467 14.116-31.467 31.468 0 8.17 3.13 15.623 8.255 21.223l-5.808 5.808-.069-.068a4.38 4.38 0 0 0-3.12-1.292c-1.178 0-2.286.459-3.12 1.292L1.73 85.905a5.92 5.92 0 0 0 0 8.365A5.9 5.9 0 0 0 5.913 96c1.514 0 3.03-.576 4.182-1.73L37.57 66.797a4.38 4.38 0 0 0 1.292-3.12c0-1.179-.459-2.287-1.292-3.12l-.069-.069 5.809-5.808c5.6 5.124 13.053 8.255 21.223 8.255C81.884 62.935 96 48.82 96 31.468 96 14.116 81.884 0 64.532 0M8.107 92.281a3.106 3.106 0 0 1-4.388 0 3.107 3.107 0 0 1 0-4.388l23.077-23.077 4.388 4.388zM35.58 64.808l-2.408 2.408-4.388-4.388 2.408-2.408a1.6 1.6 0 0 1 1.131-.469c.428 0 .83.167 1.131.469l2.126 2.126c.302.302.468.703.468 1.13 0 .428-.166.83-.468 1.132m28.952-4.685c-15.8 0-28.655-12.855-28.655-28.655S48.732 2.813 64.532 2.813s28.655 12.854 28.655 28.655c0 15.8-12.854 28.655-28.655 28.655'></path>
        <path
          d='M82.531 13.468c-9.05-9.051-23.494-9.951-33.595-2.094a1.406 1.406 0 1 0 1.726 2.22c8.985-6.989 21.83-6.187 29.88 1.863s8.852 20.896 1.864 29.88a1.406 1.406 0 0 0 2.22 1.727c7.857-10.102 6.957-24.545-2.095-33.596M78.402 49.342a22.54 22.54 0 0 1-13.871 4.752c-5.826 0-11.628-2.235-16.009-6.616-8.05-8.05-8.851-20.896-1.863-29.88a1.406 1.406 0 0 0-2.22-1.727c-7.857 10.102-6.957 24.545 2.094 33.596 4.926 4.926 11.448 7.438 18 7.438a25.35 25.35 0 0 0 15.596-5.343 1.406 1.406 0 1 0-1.727-2.22'></path>
        <path
          d='M53.017 30.959c.275.275.635.412.995.412s.72-.137.994-.412l2.012-2.012 2.011 2.012a1.4 1.4 0 0 0 1.99 0c.548-.55.548-1.44 0-1.989l-2.012-2.011 2.011-2.012a1.406 1.406 0 1 0-1.989-1.989l-2.011 2.012-2.012-2.012a1.406 1.406 0 1 0-1.989 1.989l2.012 2.012-2.012 2.011c-.549.55-.549 1.44 0 1.989M68.047 30.959a1.401 1.401 0 0 0 1.989 0l2.011-2.012 2.012 2.012a1.401 1.401 0 0 0 1.989 0c.549-.55.549-1.44 0-1.989l-2.012-2.011 2.012-2.012a1.406 1.406 0 1 0-1.989-1.989l-2.012 2.012-2.011-2.012a1.406 1.406 0 1 0-1.99 1.989l2.013 2.012-2.012 2.011c-.55.55-.55 1.44 0 1.989M56.022 40.996a1.407 1.407 0 0 0 1.99 1.987c1.646-1.648 4.023-2.594 6.52-2.594 2.5 0 4.876.946 6.52 2.594a1.406 1.406 0 1 0 1.991-1.987c-2.169-2.173-5.27-3.419-8.51-3.419s-6.342 1.246-8.51 3.419'></path>
      </g>
      <defs>
        <clipPath id='clip0_2113_184980'>
          <path fill='#fff' d='M0 0h96v96H0z'></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default HashtagSearchNotFoundSvg;
