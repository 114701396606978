import React from 'react';
import { Circle } from 'rc-progress';

export const CircleProgress = ({ percent,trailColor="#828282",strokeColor='#F2C94C',showPercent=true }) => {
  return (
    <>
      <div className="w-full relative">
        <div className='absolute w-full h-full flex justify-center items-center z-[100] '>
          <p className="text-gray_5 text-[20px] fa-format-700    ">
            <span className='w-[30px]'>
              {percent}
            </span>
            <span className='w-[30px]'>
        %
            </span>
          </p>
        </div>
        <Circle

          percent={percent}
          strokeWidth={10}
          trailWidth={9}
          trailColor={trailColor}
          strokeColor={strokeColor}
        />
      </div>
    </>
  );
};
