import { useEffect } from 'react';
import { MeloPostRecordingControl } from './MeloPostRecordingControl';
import { MeloPostPlaybackControl } from './MeloPostPlaybackControl';
import { convertTime } from '../../helper/ConvertPlayerTIme.js';



export const MeloPostRecordControls = ({
  startRecording,
  stopRecording,
  isRecordingInProgress,
  isPausedRecording,
  isPausedRecordedAudio,
  isProcessingRecordedAudio,
                                         duration,
  recordingTime,
                                         formattedDuration,
                                        togglePauseResume,
                                        audioRef,
                                        isAvailableRecordedAudio,
                                         formattedRecordingTime,
                                         clearCanvas,
                                         recordedBlob,
                                         bufferFromRecordedBlob
}) => {


  return (
    <div className="flex flex-col gap-[30px] items-center w-full  ">
      <div className='flex justify-center w-full  '>
        <p className="text-[32px] font-en-dana font-[500] text-gray_6">
          {isAvailableRecordedAudio?convertTime(duration):formattedRecordingTime}
        </p>
      </div>
      {isAvailableRecordedAudio?
          <MeloPostPlaybackControl  togglePauseResume={togglePauseResume} isPausedRecordedAudio={isPausedRecordedAudio} clearCanvas={clearCanvas} recordedBlob={recordedBlob} />
        :
          <MeloPostRecordingControl isRecordingInProgress={isRecordingInProgress} stopRecording={stopRecording} startRecording={startRecording} recordingTime={recordingTime} formattedRecordingTime={formattedRecordingTime}/>
      }
    </div>
  );
};
