import React, { useState } from 'react';


;
import { setLanguageDirection } from '../../helper/LanguageDetection.js';
import CssShimmer from '../common/CssShimmer.jsx';
import { SimpleYellowPlayPauseButtons } from '../playlist/melodifyPlaylist/SimpleYellowPlayPauseButtons';
import { FollowUnfollowArtistPlaylist } from '../playlist/melodifyPlaylist/FollowUnfollowArtistPlaylist.jsx';
import { SharePlaylistButton } from '../playlist/melodifyPlaylist/SharePlaylistButton';
import { PlaylistReels } from '../playlist/melodifyPlaylist/PlaylistReels';
import { useFollow } from '../../helper/useFollow.js';
import { shareTypes } from '../../consts/shareTypes.js';



export const ArtistInfoBox = ({ artistObject,artistData,apiUrl, list, endOfPagination, requestPayload,isLoading }) => {

  const [isButtonChange, setIsButtonChange] = useState();
  const [isFollow, toggleFollow, unfollowArtist, followArtistLoading] =
    useFollow(artistObject?.id);
  return (
    <div
      dir="rtl"
      className="flex w-full px-4 absolute z-10 top-[-100px] z-[10]"
    >
      <div className="flex w-full flex-col bg-white bg-opacity-[15%] rounded-[10px] px-4 pt-2 pb-5 gap-[21px]  ">
        <div className="flex flex-col gap-3 items-center  ">
          <p
            dir={setLanguageDirection(artistObject?.name)}
            className={` text-[24px]   fa-format-700 text-white`}
          >
            {artistObject?.name}
          </p>
          <div className="flex gap-[10px] items-center">
            <div className="flex flex-row h-full justify-between min-w-14 gap-1">
              {artistData?.play_count ? (
                <p className=" fa-format-700 leading-6 font-dana text-[16px] text-white">
                  {artistData?.play_count}
                </p>
              ) : (
                <CssShimmer classNames={'w-14 h-6 rounded-[8px]'} />
              )}
              <p className=" fa-format-500 text-[12px] leading-6   text-gray_5">
                پخش
              </p>
            </div>
            <div className="rounded-full bg-white w-[3px] h-[3px]  "></div>

            <div className="flex flex-row h-full justify-between min-w-14 gap-1">
              {artistData?.followers_count ? (
                <p className=" fa-format-700 leading-6 font-dana text-[16px] text-white">
                  {artistData?.followers_count}
                </p>
              ) : (
                <CssShimmer classNames={'w-14 h-6 rounded-[8px]'} />
              )}
              <p className=" fa-format-500 text-[12px] leading-6   text-gray_5">
                دنبال کننده
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center  ">
          <div className="gap-[14px] flex items-center ">
            <SimpleYellowPlayPauseButtons
              collectionObject={artistObject}
              apiUrl={apiUrl}
              list={list}
              endOfPagination={endOfPagination}
              requestPayload={requestPayload}
              className="h-[56px] w-[56px]"
              isPlaying={false}
              setIsButtonChange={setIsButtonChange}
              changePlayPauseAnim={isButtonChange}
            />
          </div>
          <div className="flex items-center gap-6">
            <FollowUnfollowArtistPlaylist
              id={artistObject?.id}
              isFollow={isFollow}
              toggleFollow={toggleFollow}
              unFollow={unfollowArtist}
              type="artist"
              followLoading={followArtistLoading}
            />
            <SharePlaylistButton
              id={artistObject?.id}
              type={shareTypes?.ARTIST}
            />
            <PlaylistReels
              model_id={artistObject?.id}
              reelData={artistData?.reel_data}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

