import React from 'react';

function MoveIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
    >
      <g
        fill='#BDBDBD'
        fillRule='evenodd'
        clipPath='url(#clip0_7053_22365)'
        clipRule='evenodd'
      >
        <path
          d='M6.968 16.254a.714.714 0 0 1 1.01 0L10 18.276l2.021-2.022a.714.714 0 1 1 1.01 1.01l-2.526 2.527a.714.714 0 0 1-1.01 0l-2.527-2.527a.714.714 0 0 1 0-1.01'></path>
        <path
          d='M10 12.143c.394 0 .714.32.714.714v6.429a.714.714 0 1 1-1.428 0v-6.429c0-.394.32-.714.714-.714M9.495.21a.714.714 0 0 1 1.01 0l2.527 2.526a.714.714 0 0 1-1.01 1.01L10 1.724 7.978 3.746a.714.714 0 1 1-1.01-1.01z'></path>
        <path
          d='M10 0c.394 0 .714.32.714.714v6.429a.714.714 0 1 1-1.428 0V.714C9.286.32 9.606 0 10 0M3.746 6.968a.714.714 0 0 1 0 1.01L1.724 10l2.022 2.022a.714.714 0 1 1-1.01 1.01L.209 10.505a.714.714 0 0 1 0-1.01l2.527-2.527a.714.714 0 0 1 1.01 0'></path>
        <path
          d='M0 10c0-.395.32-.714.714-.714h6.429a.714.714 0 1 1 0 1.428H.714A.714.714 0 0 1 0 10M16.254 6.968a.714.714 0 0 1 1.01 0l2.527 2.527a.714.714 0 0 1 0 1.01l-2.527 2.527a.714.714 0 1 1-1.01-1.01L18.275 10l-2.021-2.022a.714.714 0 0 1 0-1.01'></path>
        <path
          d='M12.143 10c0-.395.32-.714.714-.714h6.429a.714.714 0 1 1 0 1.428h-6.429a.714.714 0 0 1-.714-.714'></path>
      </g>
      <defs>
        <clipPath id='clip0_7053_22365'>
          <path fill='#fff' d='M0 0h20v20H0z'></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default MoveIcon;
