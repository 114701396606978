import React from 'react';
import ShareLoading from "../svg/common/ShareLoading.jsx";
import {useShare} from "../../hooks/useShare.js";
import UserPlaylistShareLimit from '../svg/userPlaylist/UserPlaylistShareLimit.jsx';

function RenderShare({id, type,children,shareLoadingStyle='w-[60%] h-[60%]'}) {
    const errorDialogIcon = <div className='w-full flex items-center justify-center'>
        <UserPlaylistShareLimit/>
    </div>
    const {generateLinkLoading,handleGetShareLink} = useShare(id, type,errorDialogIcon)
    return (
        <div className='w-full h-full max-w-full max-w-full flex justify-center items-center'>
            {generateLinkLoading ?
                <div className={shareLoadingStyle}>
                <ShareLoading/>
                </div>
                :
                <div onClick={handleGetShareLink}>
                    {children}
                </div>
            }
        </div>
    );
}

export default RenderShare;
