import React from 'react'

function ExitSvg({stroke="#FAFAFA"}) {
  return (

    <svg
      class="w-full h-full"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.33203 1H2.77648C2.30498 1 1.8528 1.1873 1.5194 1.5207C1.186 1.8541 0.998698 2.30628 0.998698 2.77778L0.998698 15.2222C0.998698 15.6937 1.186 16.1459 1.5194 16.4793C1.8528 16.8127 2.30498 17 2.77648 17H6.33203"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M12.5556 4.55642L17 9.00087L12.5556 13.4453"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M17 9H6.33333"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>

  )
}

export default ExitSvg
