import React from 'react';
import MelopostEmptyIcon from '../../svg/meloPost/MelopostEmptyIcon.jsx';
import clsx from 'clsx';

export const MelopostListEmptyView = ({
  title = 'هیچ ملوپستی برای مشاهده وجود ندارد!',
  className = 'mt-[140px]',
}) => {
  return (
    <div
      dir="rtl"
      className={clsx(
        'flex flex-col  gap-[28px] w-full items-center justify-center ',
        className,
      )}
    >
      <div>
        <MelopostEmptyIcon />
      </div>
      <p
        className="text-[14px] font-[800] text-gray_3 font-dana"
        dangerouslySetInnerHTML={{ __html: title }}
      ></p>
    </div>
  );
};

