import { useSelector } from "react-redux";
import Container from "../../components/PageParentContainer/Container";
import CommonHeader from "../../components/common/CommonHeader";
import CategoryCard from "../../components/playlist/CategoryCard";
import { SelectSeenCollection } from "../../redux/RecentlyHeard/RecentlyHeardSlice";
import { useNavigate } from "react-router-dom";
import { VirtuosoGrid } from "react-virtuoso";
import ScrollTopButton from "../../components/common/ScrollTopButton";

function ShowAllRecentlyHeardPlaylist() {
  const recentlySeenCollections = useSelector(SelectSeenCollection);
  const navigate = useNavigate();

  const openCollection = (item) => {
    navigate(`/playlist-songs/${item?.id}`, {
      state: { collectionObject: item },
    });
  };
  return (
    <Container
      style="overflow-hidden p-6 "
      header={<CommonHeader title=" پلی لیست هایی که اخیراً دیده اید " />}
    >
      <ScrollTopButton id={'container'} />
      <VirtuosoGrid
        id="container"
        dir="rtl"
        data={recentlySeenCollections}
        listClassName="!grid !grid-cols-2 !gap-4 !w-full  "
        itemContent={(index, collection) => (
          <div
            key={'collection' + index + collection?.id}
            onClick={() => openCollection(collection)}
            className=" mt-[min(4.5vw,26px)] "
            // className={`flex justify-center items-center w-full h-full mx-auto z-50  ${
            //   index <= 1 ? 'pt-6' : ''
            // }`}
          >
            <CategoryCard scale height={true} item={collection} />
          </div>
        )}
      />
    </Container>
  );
}

export default ShowAllRecentlyHeardPlaylist;
