import React from 'react';

import UserPlaylistStatisticsIcon from '../svg/userPlaylist/UserPlaylistStatisticsIcon.jsx';
import { useNavigate } from 'react-router-dom';

export const  UserPlaylistStatisticsButton = ({collectionData}) => {
  const navigate = useNavigate()
  const handleOpenStatistics = ()=>{
    navigate('/user-playlist-statistics',{state:{ collectionData }})
  }
  return (
    <div onClick={handleOpenStatistics} className="flex flex-col gap-[6px] ">
      <div className="flex justify-between items-center w-full gap-3">
              <UserPlaylistStatisticsIcon/>
      </div>
      <p className="text-gray_6 text-[10px] fa-format-500">آمار</p>
    </div>
  );
}

