import React from 'react';

export const ImageGradientOverlay = ({ backgroundColor}) => {
  return (
    <>
      <div className="absolute top-0 left-0 right-0 bottom-0    ">
        <div
          className="w-full h-full "
          style={{
            background: `linear-gradient(
      to bottom, 
      #0E0E0E00 69.8%, 
      ${backgroundColor} 100%
    )`,
          }}
        ></div>
      </div>
      <div className="absolute top-0 left-0 right-0 bottom-0    ">
        <div
          className="w-full h-full "
          style={{
            background: `linear-gradient(
      to bottom, 
      #0E0E0E00 69.8%, 
      #0E0E0ECC 100%
    )`,
          }}
        ></div>
      </div>
    </>
  );
};

