import React from 'react';
import ClickReaction from '../../../helper/ClickReaction.jsx';

export const  ShuffleButton = ({ isShuffle,handleShuffle }) => {
  return (
    <span
      onClick={() => handleShuffle()}
      className="select-none h-[26px] w-[26px]  aspect-square  cursor-pointer  "
    >
          <ClickReaction>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isShuffle ? 'fill-primary' : 'fill-gray_6'
              } w-full h-full`}
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                d="M19.533 13.176l-3.084-1.78a.936.936 0 00-1.403.81v1.057h-1.393a3.343 3.343 0 01-2.994-1.836L8.876 7.918a4.783 4.783 0 00-4.283-2.627H.723a.723.723 0 100 1.446h3.87c1.27 0 2.418.704 2.994 1.836L8.312 10c-.7 1.378-1.367 3.263-3.72 3.263a.723.723 0 100 1.446 4.783 4.783 0 004.284-2.627l.247-.487.247.487a4.783 4.783 0 004.283 2.627h1.393v1.057c0 .72.78 1.17 1.403.81l3.084-1.78a.936.936 0 000-1.62zM2.09 13.263H.723a.723.723 0 000 1.446H2.09a.723.723 0 000-1.446zM16.448 8.604l3.084-1.78a.936.936 0 000-1.62l-3.084-1.78a.936.936 0 00-1.403.81v3.56c0 .719.78 1.17 1.403.81z"></path>
              <path
                d="M14.082 6.737V5.29c-1.813 0-3.32.511-4.396 2.096.059.111-.058-.117.79 1.55 1.045-2.057 2.287-2.2 3.606-2.2z"></path>
            </svg>
          </ClickReaction>
        </span>
  );
}

export default ShuffleButton;
