import React from 'react';
import ScrollTopButton from '../common/ScrollTopButton.jsx';
import Container from '../PageParentContainer/Container.jsx';
import CollectionCategoryHeader from '../playlist/CollectionCategoryHeader.jsx';
import { setLanguageDirection } from '../../helper/LanguageDetection.js';
import TextMarquee from '../common/TextMarquee.jsx';
import AbsoluteBackground from '../common/AbsoluteBackground.jsx';
import { useInfiniteQuery } from '@tanstack/react-query';
import CollectionsList from './CollectionsList.jsx';
import CollectionsListTitleSection from './CollectionsListTitleSection.jsx';

function RenderCollectionsPage({
  pageId,
  pageTitleText,
  title,
  subtitle,
  image,
  itemData = [],
  requestKey,
  apiFunction,
  requestData,
  openCollection,
  apiDataKey,
  pageParams,
}) {
  const { isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, data } =
    useInfiniteQuery(
      [requestKey],
      ({ pageParam = pageParams }) =>
        apiFunction({
          ...requestData,
          offset: pageParam,
        }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const allData = allPages?.flatMap(
            page => page?.data?.result[apiDataKey],
          );
          return lastPage?.data?.result?.end
            ? false
            : allData?.length + pageParams;
        },
        refetchOnWindowFocus: false,
      },
    );
  const requestCollection = data?.pages?.flatMap(
    page => page?.data?.result?.[apiDataKey],
  );
  if (requestCollection) {
    itemData = [...itemData, ...requestCollection];
  }

  const getNextPage = () => {
    console.log(hasNextPage, isFetchingNextPage);
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  return (
    <>
      <ScrollTopButton id={pageId} />
      <Container>
        <CollectionCategoryHeader
          title={pageTitleText}
          hasSearch={false}
          backgroundId="absolute-background"
          pageId={pageId}
        />

        <div
          id={pageId}
          className=" relative h-full overflow-scroll hide-scroll-bar  bg-primary_dark"
        >
          <div
            id={pageId}
            dir="rtl"
            className="w-full h-auto   relative pt-[101px]"
          >
            <div
              dir="rtl"
              className="flex items-center z-[11]   relative w-full px-4 mb-4 "
            >
              <CollectionsListTitleSection title={title} subtitle={subtitle} />
            </div>
            <CollectionsList
              isLoading={isLoading}
              itemData={itemData}
              getNextPage={getNextPage}
              hasNextPage={hasNextPage}
              requestCollection={requestCollection}
              openCollection={openCollection}
              pageId={pageId}
            />
          </div>
        </div>
        <AbsoluteBackground image={image} animation={true}></AbsoluteBackground>
      </Container>
    </>
  );
}

export default RenderCollectionsPage;
