import React from 'react';
import AddToPlaylistYellowBox from "../../components/MyMelodify/AddToPlaylistYellowBox.jsx";
import {useQuery} from "@tanstack/react-query";
import {getUserCollections} from "../../services/ApiClient.js";
import CommonHeader from "../../components/common/CommonHeader.jsx";
import Container from "../../components/PageParentContainer/Container.jsx";
import NoUserPlaylists from "../../components/MyMelodify/NoUserPlaylists.jsx";
import UserPlaylistList from "../../components/MyMelodify/UserPlaylistList.jsx";
import {useDispatch} from "react-redux";
import {setUserCollections} from "../../redux/globalData/globalDataSlice.js";
import { globalData, useUserData } from '../../helper/ReduxSelectorContext.js';
import UserPlaylistShimmerContainer from "../../components/MyMelodify/UserPlaylistShimmerContainer.jsx";
import { UserPlaylistRender } from '../../components/common/user/UserPlaylistRender.jsx';

function UserPlaylists(props) {
    const user = useUserData();


    const globalDataObject = globalData()
    const userCollections = globalDataObject?.userCollections

    return (
        <Container
           style='!bg-background'
            header={<CommonHeader hasChevronNavigateBack={true} title={'پلی‌لیست‌های من '}/>}
        >
            <div dir='rtl'
                 id='user-playlist-page'
                 className='flex w-full h-full flex flex-col overflow-scroll hide-scroll-bar gap-6  p-4'>

                <AddToPlaylistYellowBox/>

                <UserPlaylistRender isGrid={false} social_token={user?.social_token} scrollableTarget='user-playlist-page' shimmer={<UserPlaylistShimmerContainer/>} />
            </div>
        </Container>
    );
}

export default UserPlaylists;
