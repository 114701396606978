import React from 'react';
import CloseDialogButton from '../svg/common/CloseDialogButton.jsx';
import { Dialog } from '@headlessui/react';
import clsx from 'clsx';

function DialogHeader({ header_text, header_icon, onCloseDialog,isDeleteDialog=false }) {
  return (
    <>
      <Dialog.Title
        as="h3"
        className={clsx(`w-full text-lg leading-6 text-right flex items-center justify-between p-4   mb-4 `,isDeleteDialog?'bg-transparent':'bg-secondary')}
      >
        <div className="flex items-center gap-4 ">
          {header_icon}
          <p className=" font-dana text-primary text-[16px] font-normal leading-normal">
            {header_text}
          </p>
        </div>
        {onCloseDialog && (
          <div className="cursor-pointer w-4 h-4" onClick={onCloseDialog}>
            <CloseDialogButton />
          </div>
        )}
      </Dialog.Title>
    </>
  );
}

export default DialogHeader;
