import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { AboutUsItems } from '../../consts/AboutUs.jsx';
import Container from '../../components/PageParentContainer/Container.jsx';
import CommonHeader from '../../components/common/CommonHeader.jsx';
import InstagramLogoSvg from '../../components/svg/aboutUs/InstagramLogoSvg.jsx';
import ChevronLeft from '../../components/svg/common/ChevronLeft.jsx';
import CardActionArea from '@mui/material/CardActionArea';
import { APP_VERSION, APP_VERSION_NAME } from '../../BASE.js';
import SwipeBottomSheet from '../../components/bottomSheets/SwipeBottomSheet.jsx';
import BottomSheetHeader from '../../components/bottomSheets/BottomSheetHeader.jsx';
import SingleAbout from './SingleAbout.jsx';
import logo from '/src/assets/logo/melodify-logo-by-text.webp';
import CustomImage from '../../components/common/CustomImage.jsx';

function AboutUs() {
  const navigate = useNavigate();

  const [BottomSheetItem, setBottomSheetItem] = useState();
  const [bugReportDialogOpen, setBugReportDialogOpen] = useState(false);

  const doAction = item => {
    if (item.action === 'LINK') {
      openLink(item.link);
    }
    if (item.action === 'ROUTE') {
      routeToPage(item);
    }
    if (item.action === 'BOTTOM-SHEET') {
      setBottomSheetItem(item);
      setBugReportDialogOpen(true);
    }
  };
  const openLink = link => {
    const linkEl = document.createElement('a');
    linkEl.setAttribute('href', link);
    linkEl.setAttribute('target', '_blank');
    linkEl.click();
  };
  const routeToPage = item => {
    navigate(`p/${item.key}`);
  };

  const toggleBugReportBottomSheet = newOpen => () => {
    setBugReportDialogOpen(newOpen);
  };
  const closeBottomSheet = () => {
    setBugReportDialogOpen(false);
  };
  const handleCopyItem = async () => {
    let tok = localStorage.getItem('tok');

    try {
      await navigator.clipboard.writeText(tok);
      console.log('Text copied to clipboard!');
    } catch (err) {
      console.error('Unable to copy text to clipboard', err);
    }
  };
  return (
    <Container
      style=" bg-primary_dark"
      header={<CommonHeader title="درباره " />}
    >
      <div
        dir="rtl"
        className="w-full flex flex-col justify-center items-center px-12 pb-[70px] pt-[29px] font-dana "
      >
        <div className="bg-black_2  w-full rounded-[20px] h-[202px]  flex flex-col justify-center items-center">
          <div className="flex h-[114px] justify-center items-center  w-[60px]">
            <CustomImage appendBaseUrl={false} src={logo} alt="melodify" />
          </div>
          <span className="text-primary  text-xs font-medium flex h-[40px] justify-center items-center border-y-4 border-primary_dark w-full">
            ملودیفای | مرجع پخش و دانلود آهنگ
          </span>
          <span
            onClick={handleCopyItem}
            className="text-primary  text-xs font-medium flex h-[40px] justify-center items-center  w-full"
          >
            نسخه {APP_VERSION_NAME}
          </span>
        </div>

        <div className="w-full flex flex-col  mt-8 gap-1">
          {AboutUsItems?.map((item, index) => (
            <CardActionArea className="!rounded-[20px] " key={index}>
              <div
                onClick={() => {
                  doAction(item);
                }}
                className="w-full flex justify-start items-center flex-row h-14 bg-black_2 rounded-[20px]  "
              >
                <div className="mx-[18px]">{item.icon}</div>
                <span className="text-white  text-sm font-medium  ">
                  {item.title}
                </span>
              </div>
            </CardActionArea>
          ))}
        </div>
        <div className="w-full flex flex-col  mt-8 ">
          <CardActionArea className="!rounded-[20px] !mt-4">
            <div
              onClick={() =>
                openLink('https://www.instagram.com/melodify.official')
              }
              dir="rtl"
              className="w-full flex justify-between items-center flex-row  h-[81.5px] bg-black_2 rounded-[20px] first:pt-0 pt-1 "
            >
              <div className="flex ms-4">
                <div className="mx-[18px]">{<InstagramLogoSvg />}</div>
                <div className="flex flex-col">
                  <span className="text-primary text-xs font-medium  font-dana ">
                    melodify.official
                  </span>
                  <span className="text-primary max-[355px]:text-[8.5px]  text-xs leading-6 font-medium font-dana ">
                    ملودیفای در اینستاگرام...
                  </span>
                </div>
              </div>
              <div className="me-6">
                <ChevronLeft className="stroke-primary w-6 h-6" />
              </div>
            </div>
          </CardActionArea>
          {/* <span className="e w-full h-full">
            <textarea
              dir="ltr"
              className="w-full h-[155px]"
              value={localStorage.getItem("fr_id")}
            />
          </span> */}
        </div>
      </div>
      {BottomSheetItem && (
        <SwipeBottomSheet
          open={bugReportDialogOpen}
          toggleDrawer={toggleBugReportBottomSheet}
        >
          <BottomSheetHeader
            HeaderIcon={BottomSheetItem.icon}
            dividerLine={true}
            closeBottomSheet={closeBottomSheet}
            title={BottomSheetItem?.title}
          />
          <SingleAbout itemKey={BottomSheetItem?.key} />
        </SwipeBottomSheet>
      )}
    </Container>
  );
}

export default AboutUs;
