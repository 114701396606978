import { Drawer } from '@mui/material';
import React, { useEffect } from 'react';
import { useNativeBack } from '../../hooks/useNativeBack.js';
import MelopostReport from '../../pages/meloPost/MelopostReport.jsx';


function ReelReportComponent({openState,setOpenState,modelId,modelType}) {



  const handleOpenReport= newOpen => {
    setOpenState(newOpen)
  };


  useNativeBack(
    openState,
    'report-drawer-state',
    ()=>setOpenState(false),
  );



  return (
    <Drawer
      open={openState}
      onClose={handleOpenReport}
      onOpen={handleOpenReport}
      swipeAreaWidth={70}
      className="!relative !overflow-hidden !z-[102]"
      anchor="bottom"
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '520px',
          height: '100%',
          margin: 'auto',
          borderRadius: 0,
          backgroundColor: '#171717',
          // borderRadius: "20px",
        },
      }}
      transitionDuration={{
        enter: 200,
        exit: 200,
        appear: 1000,
      }}
      disableSwipeToOpen={true}
      ModalProps={{
        keepMounted: false,
      }}
    >
      <MelopostReport  itemId={modelId} itemType={modelType}/>
    </Drawer>
  );
}

export default React.memo(ReelReportComponent);
